import React from 'react'
import { Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { useSupplyRequests } from '../../../hooks'
import { PageHeader } from '../../../components'

import { SupplyTable } from './@components'

const SupplyRequest = () => {
  const supplyRequests = useSupplyRequests()

  const headerActions = (
    <Button onClick={supplyRequests.refetch} icon={<ReloadOutlined />} />
  )

  return (
    <>
      <PageHeader title='Admin - Supply Requests' actions={headerActions} />
      <Box mt={2}>
        <SupplyTable
          data={supplyRequests.data}
          loading={supplyRequests.isFetching}
          error={supplyRequests.error}
          onRefetch={supplyRequests.refetch}
        />
      </Box>
    </>
  )
}

export default SupplyRequest
