import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useFeaturedDeals = ({ params } = {}) => {
  return useQuery({
    queryKey: ['featured-deals', params],
    queryFn: async () => {
      const payload = getRequestPayload(params)
      const res = await api.deal.library.featuredList(payload)
      return res.data
    },
    staleTime: 60000,
  })
}

const getRequestPayload = ({ mediaType, platform, timezone, searchQuery }) => {
  const tableFilter = {}

  if (mediaType) {
    tableFilter.mediaType = {
      operation: 'EQUAL',
      value: mediaType,
    }
  }

  if (platform) {
    tableFilter.platform = {
      operation: 'EQUAL',
      value: platform,
    }
  }

  return {
    data: {
      pagination: { number: 100, numberOfPages: 0, start: 0 },
      sort: {},
      tableFilter: tableFilter,
    },
    params: {
      q: searchQuery,
      timezone: timezone,
    },
  }
}
