import React from 'react'
import { Box, ScrollArea } from '@beachfront/ui'

import { ExpressionGroup, ExpressionSet, ExpressionUnion } from '..'
import { useBooleanSegment } from '../../boolean-segment-context'

const ExpressionDragItem = ({ item, isContent }) => {
  const { setActiveItem } = useBooleanSegment()
  const componentToRender = () => {
    if (item.type === 'GROUP') {
      return <ExpressionGroup group={item} />
    }

    if (item.type === 'SET') {
      return <ExpressionSet set={item} isContent={isContent} />
    }

    if (item.type === 'UNION') {
      return <ExpressionUnion union={item} />
    }
  }

  return (
    <Box
      onClick={(evt) => {
        evt.stopPropagation()
        setActiveItem(item)
      }}
      className='expression-item'
      style={{ cursor: 'pointer', flexShrink: 0, height: '100%' }}
    >
      <ScrollArea height='100%'>{componentToRender()}</ScrollArea>
    </Box>
  )
}

export default ExpressionDragItem
