import React, { useState } from 'react'
import {
  Flex,
  Grid,
  Box,
  Card,
  Text,
  Tag,
  Label,
  Input,
  Button,
  Divider,
  Alert,
  Empty,
  Popconfirm,
  useToast,
} from '@beachfront/ui'
import { KeyOutlined, DeleteOutlined } from '@beachfront/ui/icons'
import moment from 'moment'

import { api } from '../../client-api'
import { useApiKeys } from '../../hooks'
import { PageHeader, CommonSpinner } from '../../components'
import { isNotEmptyArray, isNotEmptyObject, getErrorMessage } from '../../utils'

const ApiKey = () => {
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [pendingApiKey, setPendingApiKey] = useState(null)

  const apiKeys = useApiKeys()
  const canDelete = apiKeys.data?.data.length > 1
  const isMaxKeys = apiKeys.data?.data.length >= 5

  const generateApiKey = () => {
    setLoading(true)
    return api.apiKey.createApiKey().then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'API key generated.' })
          if (res.data.clientId && res.data.clientSecret) {
            setPendingApiKey({
              clientId: res.data.clientId,
              clientSecret: res.data.clientSecret,
            })
          } else {
            apiKeys.refetch()
          }
        } else {
          toast.error({ title: 'Something went wrong. Please try again.' })
        }
        setLoading(false)
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
        setLoading(false)
      }
    )
  }

  const saveApiKey = () => {
    setLoading(true)
    return api.apiKey.saveApiKey({ ...pendingApiKey }).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'API key saved.' })
          setPendingApiKey(null)
          apiKeys.refetch()
        } else {
          toast.error({ title: 'Something went wrong. Please try again.' })
        }
        setLoading(false)
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
        setLoading(false)
      }
    )
  }

  const deleteApiKey = (id) => {
    return api.apiKey.deleteApiKey({ id }).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'API key deleted.' })
          apiKeys.refetch()
        } else {
          toast.error({ title: 'Something went wrong. Please try again.' })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const clearApiKey = () => {
    setPendingApiKey(null)
  }

  const getDateString = (date) => {
    const m = moment(date)
    return `${m.format('DD-MM-YYYY HH:mm')} (${m.fromNow()})`
  }

  if (apiKeys.isFetching || loading) {
    return <CommonSpinner />
  }

  return (
    <>
      <PageHeader title='API Keys' />
      {isNotEmptyObject(pendingApiKey) ? (
        <Flex mt={3} gap={3} width={[1, 1 / 2]} flexDirection='column'>
          <Box>
            <Label>Client ID</Label>
            <Input.Copyable value={pendingApiKey.clientId} />
          </Box>
          <Box>
            <Label>Client Secret</Label>
            <Input.Copyable value={pendingApiKey.clientSecret} />
          </Box>
          <Flex gap={2}>
            <Button type='primary' onClick={saveApiKey}>
              Save
            </Button>
            <Button onClick={clearApiKey}>Cancel</Button>
          </Flex>
        </Flex>
      ) : (
        <Box mt={3}>
          {isNotEmptyArray(apiKeys.data?.data) ? (
            <>
              <Grid gap={3} columns={[1, 2]}>
                <Box>
                  <Label>Client ID</Label>
                  <Input.Copyable value={apiKeys.data?.clientId} />
                </Box>
              </Grid>
              <Divider />
              <Flex justifyContent='space-between' alignItems='flex-end'>
                <Label m={0}>Client Secrets</Label>
                <Button
                  type='primary'
                  onClick={generateApiKey}
                  disabled={isMaxKeys}
                >
                  Generate New Client Secret
                </Button>
              </Flex>
              <Grid mt={2} gap={3} columns={[1, 1, 2]}>
                {apiKeys.data?.data.map((item, i) => (
                  <Card key={i} size='small'>
                    <Flex
                      gap={3}
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Flex
                        flexDirection='column'
                        alignItems='center'
                        flex='none'
                      >
                        <Box as='span' fontSize={6} lineHeight='1'>
                          <KeyOutlined />
                        </Box>
                        <Tag mt={2}>Client Secret</Tag>
                      </Flex>
                      <Box flexGrow={1}>
                        <Text fontSize={4} strong>
                          {item.clientSecret}
                        </Text>
                        <Box>
                          {'Added by '}
                          <Text strong>{item.userName}</Text>
                          {' on '}
                          <Text strong>{getDateString(item.creationDate)}</Text>
                        </Box>
                        {!canDelete ? (
                          <Text>
                            You cannot delete the only client secret. Generate a
                            new client secret first.
                          </Text>
                        ) : null}
                      </Box>
                      <Box flex='none'>
                        <Popconfirm
                          title='Delete this client secret?'
                          onConfirm={() => deleteApiKey(item?.id)}
                          disabled={!canDelete}
                          okButtonProps={{ danger: true }}
                          okText='Delete'
                          placement='topRight'
                          arrowPointAtCenter
                        >
                          <Button
                            danger
                            size='small'
                            icon={<DeleteOutlined />}
                            disabled={!canDelete}
                          />
                        </Popconfirm>
                      </Box>
                    </Flex>
                  </Card>
                ))}
              </Grid>
            </>
          ) : (
            <Flex
              height='calc(100vh - 130px)'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <Empty description=''>
                <Button type='primary' onClick={generateApiKey}>
                  Generate New API Key
                </Button>
              </Empty>
              {apiKeys.data?.msg ? (
                <Alert mt={3} type='error' message={apiKeys.data.msg}></Alert>
              ) : null}
            </Flex>
          )}
        </Box>
      )}
    </>
  )
}

export default ApiKey
