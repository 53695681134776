import React from 'react'
import { Grid, Box, Card, Input, Switch } from '@beachfront/ui'
import { Field, useFormState } from '@beachfront/ui/forms'

import { DSP_STATUS, DSP_TYPE } from '../../../../enums'
import { SelectField, LabelTooltip } from '../../../../components'
import { getDspTooltip } from '../@utils'

const DspForm = () => {
  const { values } = useFormState()

  return (
    <Card overflow='initial'>
      <Grid columnGap={3} columns={[1, 3]}>
        <Box columnSpan={[1, 2]}>
          <Field
            name='name'
            label='Name'
            extra={<LabelTooltip content={getDspTooltip('title')} />}
          >
            <Input placeholder='Enter Buyer Name' maxLength={100} />
          </Field>
        </Box>
        <Field
          name='status'
          label='Status'
          extra={<LabelTooltip content={getDspTooltip('status')} />}
        >
          <SelectField
            name='status'
            keyField='key'
            textField='value'
            placeholder='Select DSP Status'
            data={DSP_STATUS.values().map(({ key, name }) => ({
              key,
              value: name,
            }))}
          />
        </Field>
        <Field
          name='type'
          label='Type'
          extra={<LabelTooltip content={getDspTooltip('type')} />}
        >
          <SelectField
            name='type'
            keyField='key'
            textField='value'
            placeholder='Select DSP Type'
            data={DSP_TYPE.values().map(({ key, name }) => ({
              key,
              value: name,
            }))}
          />
        </Field>
        {values.type === DSP_TYPE.INDIRECT.key ? (
          <>
            <Field
              name='source'
              label='Source'
              extra={<LabelTooltip content={getDspTooltip('source')} />}
            >
              <Input placeholder='Enter Source' maxLength={100} />
            </Field>
            <Field
              name='sourceSeat'
              label='Source Seat'
              extra={<LabelTooltip content={getDspTooltip('sourceSeat')} />}
            >
              <Input placeholder='Enter Source Seat' maxLength={100} />
            </Field>
          </>
        ) : null}
        <Box columnSpan={[1, 3]}>
          <Field
            name='seat'
            label='Seat'
            extra={<LabelTooltip content={getDspTooltip('seat')} />}
          >
            <Switch />
          </Field>
        </Box>
      </Grid>
    </Card>
  )
}

export default DspForm
