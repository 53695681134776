import React, { useState, useMemo } from 'react'
import { Flex, Card, Result, Radio, Button, Tooltip } from '@beachfront/ui'
import {
  DownloadOutlined,
  LineChartOutlined,
  BarChartOutlined,
} from '@beachfront/ui/icons'
import { CSVLink } from 'react-csv'

import { api } from '../../../client-api'
import { useFetch } from '../../../hooks'
import { isNotEmptyArray } from '../../../utils'
import { NetworkError, CommonSpinner } from '../../../components'

import Detail from './detail'

const ChartDetail = ({ requestPayload }) => {
  const [chartView, setChartView] = useState('LINE')

  const response = useFetch({
    request: api.reach.platformChart,
    payload: requestPayload,
  })

  const csvData = useMemo(() => {
    let csvCol = [{ label: 'Date', key: 'date' }]
    let csvRow = []

    if (isNotEmptyArray(response.data?.data)) {
      let totalData = []
      response.data.data.forEach((item) => {
        csvCol.push({ key: item.key, label: item.title })
      })
      response.data.data.forEach((el) => {
        totalData = [...totalData, ...el.chartData]
      })
      totalData = totalData.map((item) => ({
        date: `${item.label}/${new Date().getFullYear()}`,
        [item.key]: item.value,
      }))
      let obj = {}
      totalData.forEach((el) => {
        if (obj[el.date]) {
          obj[el.date] = { ...obj[el.date], ...el }
        } else {
          obj[el.date] = el
        }
      })
      csvRow = Object.values(obj)
    }

    return { csvCol, csvRow }
  }, [response.data])

  if (response.error) {
    return (
      <NetworkError
        description={response.error || 'Something went wrong.'}
        onAction={response.refresh}
      />
    )
  }

  const extra = (
    <Flex gap={2}>
      <CSVLink
        data={csvData?.csvRow}
        headers={csvData?.csvCol}
        uFEFF={false}
        filename='chart.csv'
      >
        <Button
          icon={<DownloadOutlined />}
          disabled={response.loading || !isNotEmptyArray(csvData?.csvRow)}
        >
          Export
        </Button>
      </CSVLink>
      <Radio.Group
        value={chartView}
        onChange={(e) => setChartView(e.target.value)}
      >
        <Tooltip title='Line Chart'>
          <Radio.Button value='LINE'>
            <LineChartOutlined />
          </Radio.Button>
        </Tooltip>
        <Tooltip title='Bar Chart'>
          <Radio.Button value='STACKED'>
            <BarChartOutlined />
          </Radio.Button>
        </Tooltip>
      </Radio.Group>
    </Flex>
  )

  return (
    <Card
      title='FTrackHHID Metrics'
      extra={extra}
      bodyStyle={{ height: 334 }}
      compact
    >
      {response.loading ? (
        <CommonSpinner />
      ) : (
        <>
          {response.data?.success && isNotEmptyArray(response.data.data) ? (
            <Detail
              data={response.data.data}
              view={chartView}
              showDiffChart={true}
              onMetricSelect={() => setChartView('LINE')}
            />
          ) : (
            <Result
              height='100%'
              status='warning'
              description={
                response.data.ed || response.data.msg || 'No Data Found'
              }
            />
          )}
        </>
      )}
    </Card>
  )
}

export default ChartDetail
