import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Flex } from '@beachfront/ui'
import {
  BarChart,
  CartesianGrid,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import { formatCurrency } from '../../utils'
import { CustomTooltip } from '../widget/custom-tooltip'
import { formatTick } from '../widget/widget-util'

import AxisLabel from './axis-label'
import style from './band-chart.module.scss'

const BandChart = ({
  data,
  plotProps,
  tooltipLabel,
  xDataKey,
  yDataKey,
  yScale,
  xDomain,
  yDomain,
  xAxisLabel,
  yAxisLabel,
  barColor,
  onClick,
}) => {
  const theme = useTheme()

  const calcTicks = () => {
    const key = yDataKey ?? 'y'
    const vals = data.map((dt) => dt[key])
    const min = Math.min(...vals)
    const max = Math.max(...vals)

    if (max === 0) {
      return [1, 100, 1e4, 1e6]
    }

    let y = 0.1
    let ticks = []

    if (min <= 1) {
      ticks.push(y)
    }

    do {
      y *= 10
      ticks.push(y)
    } while (y < max)

    return ticks
  }

  const yTicks = yScale === 'log' ? calcTicks() : undefined

  if (yScale === 'log') {
    yDomain = () => [yTicks[0], yTicks[yTicks.length - 1]]
  }

  return (
    <Flex
      width='100%'
      fontFamily='secondary'
      fontSize={0}
      style={{ height: plotProps.height }}
    >
      <ResponsiveContainer>
        <BarChart
          data={data}
          barCategoryGap='8%'
          margin={{ top: 10, right: 25, left: 10 }}
          className={onClick ? style.clickable : ''}
          onClick={onClick}
        >
          <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
          <XAxis
            dataKey={xDataKey ?? 'x'}
            padding={{ left: 8, right: 8 }}
            hide
          />
          <XAxis
            xAxisId='ticks'
            type='number'
            domain={xDomain}
            tickCount={data.length + 1}
            interval='equidistantPreserveStart'
            axisLine={{ stroke: theme.colors.gray[4] }}
            tickLine={{ stroke: theme.colors.gray[4] }}
            tick={{ fill: theme.colors.gray[6] }}
            tickFormatter={(val) => formatCurrency(val)}
            label={{
              value: xAxisLabel,
              position: 'insideBottom',
              fill: theme.colors.text,
            }}
            tickMargin={8}
            height={50}
            padding={{ left: 8, right: 8 }}
            allowDataOverflow={true}
          />
          <YAxis
            scale={yScale}
            domain={yDomain ?? ['auto', 'auto']}
            axisLine={false}
            tickLine={false}
            tick={{ fill: theme.colors.gray[6] }}
            tickFormatter={(val) => formatTick(val)}
            ticks={yTicks}
            label={
              yAxisLabel ? (
                <AxisLabel
                  axisType='y'
                  x={10}
                  y={120}
                  width={0}
                  height={0}
                  fill={theme.colors.text}
                >
                  {yAxisLabel}
                </AxisLabel>
              ) : undefined
            }
          />
          <Tooltip
            cursor={{ fill: theme.colors.gray[3] }}
            wrapperStyle={{ zIndex: 1 }}
            content={<CustomTooltip />}
            isAnimationActive={false}
          />
          <Bar
            dataKey={yDataKey ?? 'y'}
            name={tooltipLabel}
            fill={barColor ?? theme.colors.primary.base}
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  )
}

BandChart.propTypes = {
  data: PropTypes.array,
  plotProps: PropTypes.object,
  tooltipLabel: PropTypes.string,
  xDataKey: PropTypes.any,
  yDataKey: PropTypes.any,
  yScale: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  xDomain: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  yDomain: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  xAxisLabel: PropTypes.node,
  yAxisLabel: PropTypes.node,
  barColor: PropTypes.string,
  onClick: PropTypes.func,
}

export default BandChart
