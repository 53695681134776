import React, { useMemo } from 'react'
import { Box, Flex, Tag, Tooltip, Skeleton } from '@beachfront/ui'
import { TrafficOutlined, UserOutlined } from '@beachfront/ui/icons'
import { useDrag } from 'react-dnd'

import { useSegmentProviders } from '../../../../../../hooks'
import { useBooleanSegment } from '../../../booleanSegment/boolean-segment-context'
import { getParentProvider } from '../../../../@utils/boolean-segment.util'
import { numberFormatter, formatCurrency } from '../../../../../../utils'

const SegmentItem = ({ activeTab, segment = {} }) => {
  const { activeParentProvider } = useBooleanSegment()
  const { providers } = useSegmentProviders()

  const isDraggable = useMemo(() => {
    const parentProvider = getParentProvider(segment, providers)
    return (
      !activeParentProvider ||
      activeParentProvider.toLowerCase() === parentProvider?.toLowerCase()
    )
  }, [activeParentProvider])

  const [, drag] = useDrag(
    () => ({
      type: 'SEGMENT',
      item: {
        type: 'SEGMENT',
        ...segment,
      },
      canDrag: () => isDraggable,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [isDraggable]
  )

  const cpm = segment.price ? Math.round(segment.price * 100) / 100 : 0

  return (
    <Flex
      ref={drag}
      mb={2}
      flexDirection='column'
      border='base'
      borderRadius='base'
      bg='gray.1'
      cursor={isDraggable ? 'grab' : 'not-allowed'}
      opacity={isDraggable ? 1 : 0.6}
    >
      <Flex
        p={2}
        gap={2}
        justifyContent='space-between'
        flexDirection={['column', 'column', 'row']}
      >
        <Flex flexDirection='column'>
          <Box fontSize={0} fontWeight='bold'>
            {segment.id}
          </Box>
          <Box fontSize={1} color='primary.base' title={segment.title}>
            {segment.title}
          </Box>
        </Flex>
        <Flex
          gap={1}
          flexDirection='column'
          justifyContent='flex-start'
          flex='none'
        >
          <Flex gap={1} justifyContent='flex-end'>
            {segment.provider ? (
              <Tag fontWeight='bold'>{segment.provider}</Tag>
            ) : null}
            {segment.dataType ? <Tag>{segment.dataType}</Tag> : null}
          </Flex>
          <Flex gap={1} justifyContent='flex-end'>
            <Tooltip title='Avails' placement='bottom'>
              <Tag>
                <TrafficOutlined />
                {segment.dailyAvails === null ? (
                  <Skeleton width={25} />
                ) : (
                  numberFormatter(segment.dailyAvails ?? 0)
                )}
              </Tag>
            </Tooltip>
            <Tooltip title='Users' placement='bottom'>
              <Tag>
                <UserOutlined />
                {numberFormatter(segment.users ?? 0)}
              </Tag>
            </Tooltip>
            {activeTab === 'shared' ? (
              <Tag color='green'>{`${formatCurrency(cpm)} CPM`}</Tag>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SegmentItem
