import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { Card, Flex, Box, Input, Button, Empty, Alert } from '@beachfront/ui'
import { SendOutlined } from '@beachfront/ui/icons'
import { usePrevious } from '@beachfront/ui/hooks'

import { api } from '../../../client-api'
import { getErrorMessage } from '../../../utils'
import { NetworkError, MessageBubble } from '../../../components'

const DealCommentsWidget = ({ dealId, user, width, height }) => {
  const queryClient = useQueryClient()
  const scrollRef = useRef()
  const [message, setMessage] = useState('')

  const comments = useQuery({
    queryKey: ['deal-comments', dealId],
    queryFn: async () => {
      const res = await api.deal.getComments({ id: dealId })
      return res.data ?? []
    },
    refetchInterval: 10000,
  })

  const prevComments = usePrevious(comments.data)
  const postMutation = useMutation({
    mutationFn: (text) => {
      return api.deal.postComment({ id: dealId, data: { text: text.trim() } })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal-comments', dealId] })
    },
    onError: (error, text) => {
      setMessage(text)
    },
  })

  const onPost = () => {
    postMutation.mutate(message)
    setMessage('')
  }

  const isEqualUser = (a, b) => {
    if (!a || !b) {
      return false
    }
    return a.accountId === b.accountId && a.userName === b.userName
  }

  const isAnimated = (i) => {
    if (!prevComments || !comments.data) {
      return false
    }
    return (
      comments.data.length > prevComments.length && i >= prevComments.length
    )
  }

  useEffect(() => {
    if (comments.data?.length) {
      scrollRef.current?.scroll({
        top: scrollRef.current?.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [comments.data])

  return (
    <Card
      title='Comments'
      size='small'
      loading={comments.isLoading}
      minWidth={width}
      minHeight={400}
      height={height}
      bodyStyle={{ height: '100%' }}
      compact
    >
      <Flex flexDirection='column' height='100%'>
        <Box
          ref={scrollRef}
          px={2}
          flex='1 1 auto'
          overflowY='scroll'
          overflowX='hidden'
          width='100%'
          height={100}
        >
          <Flex py={3} gap={1} flexDirection='column' width='100%'>
            {comments.error ? (
              <NetworkError
                description={getErrorMessage(comments.error)}
                onAction={comments.refetch}
              />
            ) : comments.data?.length ? (
              comments.data.map((c, i, arr) => (
                <MessageBubble
                  key={c.id}
                  text={c.text}
                  date={c.createDate}
                  name={c.userName}
                  isSender={c.accountId === user.accountId}
                  isAnimated={isAnimated(i)}
                  hasPrevious={isEqualUser(c, arr[i - 1])}
                  hasNext={isEqualUser(c, arr[i + 1])}
                />
              ))
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='No Comments'
              />
            )}
          </Flex>
        </Box>
        {postMutation.error ? (
          <Alert
            type='error'
            message='Failed to post comment. Please try again.'
            showIcon
            banner
          />
        ) : null}
        <Flex p={2} gap={2} flex='none' alignItems='flex-end' borderTop='split'>
          <Input.TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder='Enter Comment'
            rows={1}
            maxLength={250}
            autoSize={{ minRows: 1, maxRows: 6 }}
            disabled={postMutation.isPending || !!comments.error}
            showCount
          />
          <Box mb={21} flex='none'>
            <Button
              type='primary'
              htmlType='button'
              onClick={onPost}
              loading={postMutation.isPending}
              disabled={!message.trim()}
              icon={<SendOutlined />}
              aria-label='Post Comment'
            />
          </Box>
        </Flex>
      </Flex>
    </Card>
  )
}

DealCommentsWidget.propTypes = {
  dealId: PropTypes.string,
  user: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default DealCommentsWidget
