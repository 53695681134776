export default [
  {
    path: 'settings',
    title: 'Settings',
    key: 'settings',
  },
  {
    path: 'targeting',
    title: 'Targeting',
    key: 'targeting',
  },
  {
    path: 'history',
    title: 'History',
    key: 'history',
  },
]
