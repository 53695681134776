import { isNotEmptyArray } from '../../../utils'

export default function (platformKey, platformValue) {
  let value = [...platformValue]
  const keyIndex = value.findIndex((item) => item === platformKey)
  if (keyIndex >= 0) {
    value.splice(keyIndex, 1)
  } else {
    if (platformKey === 'ALL') {
      value = [platformKey]
    } else {
      if (value.includes('ALL')) {
        const allIndex = value.findIndex((item) => item === 'ALL')
        if (allIndex >= 0) {
          value.splice(allIndex, 1)
        }
      }
      value = [...value, platformKey]
    }
  }
  const isAllSelected = value.find((el) => el === 'ALL')
  if (!isNotEmptyArray(value) || (!isAllSelected && value.length === 5)) {
    value = ['ALL']
  }
  return value
}
