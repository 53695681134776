import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, Flex, Heading } from '@beachfront/ui'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const HelpCenterCard = ({
  id,
  heading,
  description,
  icon,
  color,
  subColor,
}) => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()

  const handleCardClick = () => {
    if (id === 'policy') {
      window.open('http://beachfrontmedia.com/privacy-policy', '_blank')
    } else if (id === 'tos') {
      window.open('http://beachfrontmedia.com/terms/', '_blank')
    } else {
      navigate(`${location.pathname}/${id}`)
    }
  }

  return (
    <Card
      onClick={handleCardClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = `${theme.colors[color][subColor]} 0px 0px 0px 4px inset`
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = 'none'
      }}
      style={{
        background: theme.colors[color].light,
        borderColor: theme.colors[color][subColor],
        borderWidth: 2,
        cursor: 'pointer',
      }}
    >
      <Flex alignItems='center' gap={3}>
        <Flex
          size='70px'
          alignItems='center'
          justifyContent='center'
          flex='none'
          fontSize={40}
          color={theme.colors[color][subColor]}
        >
          {icon}
        </Flex>
        <Flex flexDirection='column'>
          <Heading level={4} m={0}>
            {heading}
          </Heading>
          <span>{description}</span>
        </Flex>
      </Flex>
    </Card>
  )
}

HelpCenterCard.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.string,
  subColor: PropTypes.number,
}

export default HelpCenterCard
