import { createEnum } from '../utils'

export const USER_ROLE = createEnum({
  SUPER: {
    name: 'Super',
  },
  ADMIN: {
    name: 'Admin',
  },
  AGENCY: {
    name: 'Agency',
  },
  PUBLISHER: {
    name: 'Publisher',
  },
})
