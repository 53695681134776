/* eslint-disable filenames/match-exported */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Progress, Upload, useToast } from '@beachfront/ui'
import { UploadOutlined } from '@beachfront/ui/icons'

import { api } from '../../client-api'
import { useUserData } from '../../hooks'
import {
  getFileArrayBuffer,
  isArray,
  isString,
  getErrorMessage,
} from '../../utils'

const UploadS3Input = ({
  value,
  onChange,
  disabled,
  editable,
  single,
  uploadType,
  uploadFolder,
  uploadState,
  progressHide,
  uploadAPI,
  params,
  ...rest
}) => {
  const toast = useToast()
  const [user] = useUserData()
  const [upload, setUpload] = useState({
    uploading: false,
    loaded: false,
    percent: 0,
  })

  if (editable === undefined) {
    editable = true
  }

  let uploadButton = (
    <Button type='primary' icon={<UploadOutlined />}>
      Upload File
    </Button>
  )

  if (upload.uploading) {
    uploadButton = progressHide ? (
      <></>
    ) : (
      <Progress.Circle value={upload.percent} status='active' />
    )
  }

  const onUploadDone = (response) => {
    onChange(response)
  }

  const onUploadError = (error) => {
    toast.error({
      title: error || 'Unable to upload file. Please try again.',
    })
  }

  const convertFile = (file) => {
    let fileName = 'file.csv'
    let fileType = 'text/csv'
    let extension = file.name.substring(
      file.name.lastIndexOf('.') + 1,
      file.name.length
    )
    if (uploadType === 'csv' && extension === 'csv') {
      getFileArrayBuffer(file).then((response) => {
        if (file.size > 50000000) {
          return toast.error({
            title: 'Please select a file less than 50 MB.',
          })
        }
        getUploadData(response, fileName, fileType).then(multiPartUpload)
      }, onUploadError)
    } else {
      return toast.error({
        title: 'Please select a valid file.',
      })
    }
    return false
  }

  const buildUploadPath = () => {
    if (uploadType === 'xls' || uploadType === 'csv') {
      return uploadType + '/' + uploadFolder + '/' + user.userId
    }
    return null
  }

  const onUpload = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    let percent = 0
    let apiParams = { ...params, type: uploadType, path: buildUploadPath() }
    let apiRequest = uploadAPI ? uploadAPI : api.segment.uploadFile
    const onUploadProgress = (progressEvent) => {
      const { loaded, total } = progressEvent
      percent = Math.floor((loaded * 100) / total)
      uploadState((prevState) => ({ ...prevState, processPer: percent }))
    }
    return apiRequest(formData, apiParams, onUploadProgress)
  }

  const multiPartUpload = (file) => {
    setUpload({ uploading: true, percent: 0 })
    uploadState({ state: 'uploading', p: 0, processPer: 0 })
    return onUpload(file).then(
      (response) => {
        let data = response.data
        if (data.success) {
          setTimeout(() => {
            setUpload({ uploading: false, percent: 100 })
            uploadState({ state: 'uploading', p: 40 })
          }, 100)
          setTimeout(() => {
            setUpload({ uploading: false, percent: 100 })
            uploadState({
              state: data.status || 'success',
              p: 100,
              url: data.fileUrl,
              data: data,
            })
          }, 100)
          onUploadDone(data.fileUrl)
        } else {
          setUpload({ uploading: false, percent: 0, ed: data.ed })
          uploadState({ state: 'never', p: 0, processPer: 0 })
        }
      },
      (error) => {
        uploadState({ state: 'never', p: 0, processPer: 0 })
        setTimeout(() => {
          setUpload({ uploading: false, percent: 0, ed: error })
          uploadState({ state: 'failed', p: 0, ed: getErrorMessage(error) })
        })
        return toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  let valueProps = {
    fileList: [],
  }

  if (single) {
    if (isString(value) && value.length) {
      valueProps = {
        fileList: [
          {
            uid: '-1',
            url: value,
            href: value,
          },
        ],
      }
    } else if (value?.url?.length) {
      valueProps = {
        fileList: [
          {
            uid: '-1',
            url: value.url,
            href: value.url,
          },
        ],
      }
    }
  } else {
    if (isArray(value) && value.length) {
      valueProps = {
        fileList: value.map((v, i) => {
          return {
            uid: -1 - i,
            url: v,
            href: v,
          }
        }),
      }
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        cursor: disabled ? 'not-allowed' : 'default',
      }}
    >
      <Upload
        accept='.csv'
        action='http://localhost:8080/api/uploadFile'
        showUploadList={{ showPreviewIcon: true, showRemoveIcon: !disabled }}
        beforeUpload={convertFile}
        {...valueProps}
        {...rest}
      >
        {uploadButton}
      </Upload>
    </div>
  )
}

const getUploadData = (fileURL, fileName, type) => {
  return new Promise((resolve, reject) => {
    try {
      const blob = new Blob([fileURL], {
        type: type,
      })
      if (!blob) {
        reject(new Error('File is empty'))
        return
      }
      blob.name = fileName
      window.URL.revokeObjectURL(window.fileUrl)
      window.fileUrl = window.URL.createObjectURL(blob)
      resolve(blob)
    } catch (error) {
      reject(error)
    }
  })
}

UploadS3Input.defaultProps = {
  max: 8,
  params: {},
  onChange: () => {},
}

UploadS3Input.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  single: PropTypes.bool,
  max: PropTypes.number,
  uploadType: PropTypes.string,
  uploadFolder: PropTypes.string,
  imgWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imgHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uploadState: PropTypes.func,
  progressHide: PropTypes.bool,
  uploadAPI: PropTypes.func,
  params: PropTypes.object,
}

export default UploadS3Input
