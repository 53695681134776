import React from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Backdrop, Box, Button, BrandCard, Divider } from '@beachfront/ui'

import BeachfrontHorizontalWhiteLogo from '../../assets/bf-logo-horizontal-white.png'

const ResetPasswordSuccess = () => {
  const navigate = useNavigate()
  const location = useLocation()

  if (!location.state || !location.state.msg) {
    return <Navigate to='/login' />
  }

  return (
    <Backdrop preset='prism'>
      <Box width={450}>
        <BrandCard
          logo={BeachfrontHorizontalWhiteLogo}
          fontSize={3}
          mode='dark'
        >
          <Box mb={3} textAlign='center' fontSize={2}>
            Your password has been reset successfully!
          </Box>
          <Box>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              onClick={() => navigate('/login')}
              block
            >
              Go To Login
            </Button>
          </Box>
          <Divider />
        </BrandCard>
      </Box>
      <Box pt={3} color='white'>
        &copy; {new Date().getFullYear()} Beachfront Media LLC
      </Box>
    </Backdrop>
  )
}

export default ResetPasswordSuccess
