import React from 'react'
import { Flex, Box } from '@beachfront/ui'
import { Field, useField } from '@beachfront/ui/forms'
import PropTypes from 'prop-types'

import { TIMEZONE } from '../../enums'
import { LabelTooltip } from '../../components'

import { SelectField } from '.'

const customOffsets = []

for (let i = -12; i <= 12; i++) {
  customOffsets.push({
    offset: String(i),
    name: i === 0 ? 'UTC' : 'UTC ' + (i > 0 ? '+' + i : i),
  })
}

const TimezoneField = ({ disabled, tooltip }) => {
  const { input } = useField('timezone')
  const showOffset = input.value === TIMEZONE.CUSTOM.key

  return (
    <Flex width={1}>
      <Box width={showOffset ? '50%' : '100%'} mr={showOffset ? 3 : 0}>
        <Field
          name='timezone'
          label='Timezone'
          extra={<LabelTooltip content={tooltip} />}
        >
          <SelectField
            keyField='key'
            textField='name'
            disabled={disabled}
            placeholder='Select Timezone'
            data={TIMEZONE.values()}
          />
        </Field>
      </Box>
      {showOffset ? (
        <Box mt={24} alignItems='center' width='50%'>
          <Field name='offset'>
            <SelectField
              placeholder='Select Timezone Offset'
              keyField='offset'
              textField='name'
              data={customOffsets}
              disabled={disabled}
            />
          </Field>
        </Box>
      ) : null}
    </Flex>
  )
}

TimezoneField.defaultProps = {
  disabled: false,
}

TimezoneField.propTypes = {
  disabled: PropTypes.bool,
  tooltip: PropTypes.node,
}

export default TimezoneField
