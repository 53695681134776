import React from 'react'
import { Link } from 'react-router-dom'

import { META_TYPE } from '../../../../enums'
import { formatDate } from '../../../../utils'

export const metaColumns = [
  {
    dataIndex: 'metaId',
    title: 'Meta ID',
    fixed: 'left',
    width: 200,
    sorter: true,
    filter: true,
    render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
  },
  {
    dataIndex: 'type',
    title: 'Type',
    width: 150,
    sorter: true,
    filter: true,
    render: (key) => META_TYPE.fromKey(key)?.name,
  },
  {
    dataIndex: 'smallIcon',
    title: 'Small Icon',
    width: 180,
    render: (src) =>
      src ? (
        <img
          src={src}
          style={{ height: '35px', width: '70px' }}
          alt='Small Icon'
        />
      ) : null,
  },
  {
    dataIndex: 'bigIcon',
    title: 'Big Icon',
    width: 180,
    render: (src) =>
      src ? (
        <img
          src={src}
          style={{ height: '35px', width: '70px' }}
          alt='Big Icon'
        />
      ) : null,
  },
  {
    dataIndex: 'createdBy',
    title: 'Created By',
    width: 180,
    render: (val) => val,
  },
  {
    dataIndex: 'created',
    title: 'Create Time',
    width: 150,
    sorter: true,
    render: (val) => formatDate(val),
  },
]
