import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CSVLink } from 'react-csv'
import { Flex, Box, Card, Text, Button, Input, Table } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import { PlusCircleOutlined, DownloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { NetworkError } from '../../../components'
import { activeSupplyColumn, inactiveSupplyColumn } from '../@constants'

const SupplyTable = ({ requestPayload, active }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const response = useTransparencySupply({ ...requestPayload, active })

  const matchesQuery = (dt) => {
    return String(dt.name).toLowerCase().includes(searchQuery.toLowerCase())
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const table = useTableQuery({
    data: applyQuery(response.data?.rows ?? []),
  })

  const addPublisher = (rec) => {
    api.realtime.transparency.supply
      .add({ id: rec.userid })
      .then(() => response.refetch())
  }

  const getCsvHeader = (data) => {
    return data.map((dt) => ({ key: dt.dataIndex, label: dt.title }))
  }

  const columns = active
    ? activeSupplyColumn
    : [
        ...inactiveSupplyColumn,
        {
          key: 'action',
          width: 200,
          render: (rec) => (
            <PublisherRequest
              isRequested={rec.isRequested}
              onRequest={() => addPublisher(rec)}
            />
          ),
        },
      ]

  if (!response.isFetching && response.error) {
    return (
      <NetworkError
        description='Something went wrong.'
        onAction={response.refetch}
      />
    )
  }

  return (
    <>
      <Flex
        mt='-48px'
        mb={3}
        gap={2}
        alignItems='center'
        justifyContent='flex-end'
      >
        <Box>
          <Input.Search
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={`Search ${active ? 'Active' : 'Inactive'} Supply`}
            allowClear
          />
        </Box>
        <CSVLink
          data={response.data?.rows ?? []}
          filename={`${active ? 'Active' : 'Inactive'} Supply.csv`}
          headers={getCsvHeader(
            active ? activeSupplyColumn : inactiveSupplyColumn
          )}
          uFEFF={false}
        >
          <Button icon={<DownloadOutlined />}>Export</Button>
        </CSVLink>
      </Flex>
      <Card size='small'>
        <Table
          rowKey='userid'
          emptyText={`No ${active ? 'Active' : 'Inactive'} Supply Found`}
          columns={columns}
          dataSource={table.data}
          loading={response.isFetching}
          scroll={{ y: 350 }}
          pagination={{
            ...table.query.pagination,
            hideOnSinglePage: false,
            total: table.total,
          }}
          onChange={(e) => {
            table.setPagination(e.pagination)
            table.setSorter(e.sorter)
          }}
        />
      </Card>
    </>
  )
}

const PublisherRequest = ({ isRequested, onRequest }) => {
  return (
    <>
      {isRequested ? (
        <Text ml={2} fontSize={1} color='positive.base'>
          Request Pending
        </Text>
      ) : (
        <Button
          type='link'
          size='small'
          icon={<PlusCircleOutlined />}
          onClick={onRequest}
        >
          Add Publisher
        </Button>
      )}
    </>
  )
}

const useTransparencySupply = ({ dateRange, active }) => {
  return useQuery({
    queryKey: ['transparency-supply', { dateRange, active }],
    queryFn: async () => {
      const payload = { dateRange }
      const { data } = active
        ? await api.realtime.transparency.supply.active(payload)
        : await api.realtime.transparency.supply.inactive(payload)
      return data
    },
    staleTime: 30000,
  })
}

export default SupplyTable
