import { createEnum } from '../utils'

export const MARGIN_TYPE = createEnum({
  FLAT: {
    key: 'FLATCPM',
    name: 'Flat CPM',
  },
  PERCENT: {
    key: 'PERCENTAGE',
    name: 'Percentage',
  },
})
