import React from 'react'
import PropTypes from 'prop-types'
import { Filter, Radio } from '@beachfront/ui'
import {
  VideoCameraOutlined,
  NumberOutlined,
  DollarCircleOutlined,
} from '@beachfront/ui/icons'

import { MEDIA_TYPE } from '../../../enums'
import { CompareFilterPanel, formatNumberTag } from '../../../components/filter'

const DealLibraryFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='mediaType'
        label='Media Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<VideoCameraOutlined />}
        formatTag={renderMediaTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {MEDIA_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='dailyAvails'
        label='Daily Avails'
        defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
        defaultValue={0}
        icon={<NumberOutlined />}
        formatTag={formatNumberTag}
        renderPanel={(props) => (
          <CompareFilterPanel {...props} min={0} step={1} precision={0} />
        )}
      />
      {/* <Filter.Option
        dataIndex='cpm'
        label='CPM'
        defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
        defaultValue={0}
        icon={<DollarCircleOutlined />}
        formatTag={formatNumberTag}
        renderPanel={(props) => (
          <CompareFilterPanel
            {...props}
            allowEquals={false}
            min={0}
            step={0.01}
          />
        )}
      /> */}
    </Filter>
  )
}

const renderMediaTypeTag = (desc) => {
  const name = MEDIA_TYPE.fromKey(desc.value)?.name
  return name ? `Media Type is ${name}` : 'All Media Types'
}

DealLibraryFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default DealLibraryFilter
