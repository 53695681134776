import { createEnum } from '../utils'

export const PLATFORM = createEnum({
  ALL: {
    name: 'All',
  },
  APP: {
    name: 'In-App',
  },
  MOBILE: {
    name: 'Mobile',
  },
  DESKTOP: {
    name: 'Desktop',
  },
  CTV: {
    name: 'CTV',
  },
  STB: {
    name: 'Cable TV',
  },
})
