import { useQuery } from '@tanstack/react-query'

import { http } from '../../client-api'

export const useSegmentInsight = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['segment', 'insight', params],
    queryFn: async () => {
      const { widget, segmentId } = params
      const url = widget.apiUrl
      const requestData = {
        segmentIds: segmentId ? [String(segmentId)] : undefined,
      }

      const request =
        widget.methodType === 'POST'
          ? http.post(url, requestData)
          : http.get(url)

      const res = await request
      return res.data?.data
    },
    staleTime: 60000,
    enabled,
  })
}
