import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Grid, Card, Input, Radio } from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'

import { META_TYPE } from '../../../../enums'
import { UploadInput } from '../../../../components'

const MetaForm = ({ disable }) => {
  return (
    <Card overflow='initial'>
      <Grid columnGap={3} columns={[1, 2]}>
        <Field name='metaId' label='Meta ID'>
          <Input
            disabled={disable}
            placeholder='Enter Meta ID'
            maxLength={255}
          />
        </Field>
        <Field name='type' label='Type'>
          <Radio.Group disabled={disable}>
            {META_TYPE.values().map(({ key, name }) => (
              <Radio.Button value={key}>{name}</Radio.Button>
            ))}
          </Radio.Group>
        </Field>
      </Grid>
      <Flex gap={3}>
        <Field name='smallIcon' label='Small Icon'>
          <UploadInput
            accept='image/png'
            single={true}
            aspectRatio={{ height: 150, width: 150 }}
          />
        </Field>
        <Field name='bigIcon' label='Big Icon'>
          <UploadInput
            accept='image/png'
            single={true}
            aspectRatio={{ height: 150, width: 150 }}
          />
        </Field>
      </Flex>
    </Card>
  )
}

MetaForm.propTypes = {
  disable: PropTypes.bool,
}

export default MetaForm
