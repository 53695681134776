import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useMediaAttachList = (mediaType) => {
  return useQuery({
    queryKey: ['media-attach-list', mediaType],
    queryFn: async () => {
      const { data } = await api.mediaList.getAttachList({ type: mediaType })
      return data
    },
    staleTime: 60000,
  })
}
