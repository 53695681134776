import React from 'react'
import moment from 'moment'
import { Filter } from '@beachfront/ui'
import { CalendarOutlined } from '@beachfront/ui/icons'

import {
  DateRangeFilterPanel,
  formatDateFilterTag,
} from '../../../components/filter'

const DashboardFilter = ({ filterRef, filters, setFilters }) => {
  const validateDateRange = ([start, end]) => {
    if (
      end.diff(start, 'days') > 92 ||
      moment().diff(start, 'month', true) > 12
    ) {
      return 'Dashboard date range is limited to 92 days within the last 12 months. Please select a valid date range.'
    }
  }

  const disabledDate = (date) => {
    return date.isAfter(moment()) || date < moment().add(-12, 'months')
  }

  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='date'
        icon={<CalendarOutlined />}
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        removable={false}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => (
          <DateRangeFilterPanel
            {...props}
            validate={validateDateRange}
            disabledDate={disabledDate}
          />
        )}
      />
    </Filter>
  )
}

export default DashboardFilter
