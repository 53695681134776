import React from 'react'
import PropTypes from 'prop-types'
import { Box, Spinner } from '@beachfront/ui'

const CommonSpinner = ({ display, width, height, minHeight, ...rest }) => {
  return (
    <Box
      style={{
        display: display ?? 'flex',
        width: width ?? '100%',
        height: height ?? '100%',
        minHeight: minHeight ?? '200px',
      }}
    >
      <Spinner m='auto' {...rest} />
    </Box>
  )
}

CommonSpinner.propTypes = {
  display: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
}

export default CommonSpinner
