import { useQuery } from '@tanstack/react-query'

import { http } from '../../client-api'

export const useReportFilterValues = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['report-filter-values', params],
    queryFn: async () => {
      const { url, ...rest } = params
      const res = await http.get(url, { params: rest })
      return res.data
    },
    staleTime: 60000,
    enabled,
  })
}
