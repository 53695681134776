import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  Flex,
  Grid,
  Input,
  Select,
  Upload,
  Box,
} from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'
import { UploadOutlined } from '@beachfront/ui/icons'

import { LabelTooltip } from '../../../components'

import style from './request-details.module.scss'

const RequestDetails = ({ issueTypes, loading }) => {
  return (
    <Card height='100%' loading={loading} title='Request Details'>
      <Grid gap={3} columns={1}>
        <Field name='type' label='Request Type'>
          <Select options={issueTypes} />
        </Field>
        <Field
          name='summary'
          label='Summary'
          extra={
            <LabelTooltip content='Condensed summary of the issue your are submitting' />
          }
        >
          <Input placeholder='Enter summary' />
        </Field>
        <Field
          name='description'
          label='Description'
          extra={
            <LabelTooltip content='Provide as much information related to your request here. The more information provided, the easier our team will be able to assist you.' />
          }
        >
          <Input.TextArea
            rows={5}
            placeholder='Enter the details of your request'
          />
        </Field>
        <Flex alignItems='center'>
          <Field
            name='attachment'
            label='Attachment(s)'
            extra={
              <LabelTooltip content="Send up a screenshot or small video clip of the issue you're having" />
            }
          >
            {({ input }) => (
              <Box width='400px'>
                <Upload
                  onChange={input.onChange}
                  accept='image/jpg,image/png'
                  beforeUpload={() => false}
                  className={style['delete-icon']}
                >
                  <Button>
                    <UploadOutlined />
                    Upload
                  </Button>
                </Upload>
              </Box>
            )}
          </Field>
        </Flex>
      </Grid>
    </Card>
  )
}

RequestDetails.propTypes = {
  issueTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool,
}

export default RequestDetails
