import React from 'react'
import { Filter, Radio } from '@beachfront/ui'
import { VideoCameraOutlined, DesktopOutlined } from '@beachfront/ui/icons'

import { MEDIA_TYPE, PLATFORM } from '../../../enums'

const DealFeatureFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='mediaType'
        label='Media Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<VideoCameraOutlined />}
        formatTag={renderMediaTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {MEDIA_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='platform'
        label='Platform'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<DesktopOutlined />}
        formatTag={renderPlatformTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {PLATFORM.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
    </Filter>
  )
}

function renderMediaTypeTag(desc) {
  const name = MEDIA_TYPE.fromKey(desc.value)?.name
  return name ? `Media Type is ${name}` : 'All Media Types'
}

function renderPlatformTag(desc) {
  const name = PLATFORM.fromKey(desc.value)?.name
  return name ? `Platform is ${name}` : 'All Platforms'
}

export default DealFeatureFilter
