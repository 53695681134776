import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Text, Tooltip } from '@beachfront/ui'

const isPositiveBase = (value, inverted) => {
  if (value === 0 || value === 1) return !inverted
  if (value === 2 || value === 3) return inverted
  return false
}

const isBackgroundColorRequired = (value) => value === 1 || value === 2

const DynamicBadge = ({ data, inverted }) => {
  const badgeProps = {}
  const baseColor = isPositiveBase(data.value, inverted)
    ? 'positive.base'
    : 'negative.base'

  badgeProps.borderColor = baseColor

  if (isBackgroundColorRequired(data.value)) {
    badgeProps.backgroundColor = baseColor
  }

  if (typeof data?.value === 'string' && data?.color) {
    const status =
      data.value.charAt(0).toUpperCase() + data.value.slice(1).toLowerCase()

    return (
      <Flex gap={1} alignItems='center'>
        <Box
          size={10}
          borderRadius='50%'
          borderWidth='2px'
          borderStyle='solid'
          borderColor={data.color}
          backgroundColor={data.color}
        />
        <Text>{status}</Text>
      </Flex>
    )
  }

  return (
    <Tooltip title={data.hint} placement='right'>
      <Box
        size={10}
        borderRadius='50%'
        borderWidth='2px'
        borderStyle='solid'
        {...badgeProps}
      />
    </Tooltip>
  )
}

DynamicBadge.propTypes = {
  data: PropTypes.object,
  inverted: PropTypes.bool,
}

export default DynamicBadge
