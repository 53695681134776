import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import {
  Box,
  Button,
  Card,
  Flex,
  Label,
  ScrollArea,
  Text,
} from '@beachfront/ui'
import {
  CloseOutlined,
  InfoCircleOutlined,
  QuestionOutlined,
} from '@beachfront/ui/icons'

import { ExpressionControls, ExpressionDragItem } from '..'
import { useBooleanSegment } from '../../boolean-segment-context'

const ExpressionBuilder = ({ isContent = false }) => {
  const {
    activeParentProvider,
    expressionV2,
    isHelpShown,
    setActiveItem,
    setIsHelpShown,
  } = useBooleanSegment()

  const extra = (
    <Flex alignItems='center' gap={2}>
      <Label>Need Help?</Label>
      <Button
        icon={isHelpShown ? <CloseOutlined /> : <QuestionOutlined />}
        onClick={() => {
          ReactGA.event({
            category: 'audience_boolean_logic',
            action: 'help_open',
          })
          setIsHelpShown((currState) => !currState)
        }}
        shape='circle'
        size='small'
      />
    </Flex>
  )
  useEffect(() => {
    const onBuilderClick = (evt) => {
      const { target } = evt

      const isClickedOutside =
        !target.closest('.expression-item') &&
        !target.closest('.expression-controls') &&
        !target.closest('.expression-options')

      if (isClickedOutside) {
        setActiveItem(null)
      }
    }

    window.addEventListener('click', onBuilderClick)

    return () => {
      window.removeEventListener('click', onBuilderClick)
    }
  })

  return (
    <Card
      title='Expression Builder'
      extra={extra}
      style={{ position: 'relative' }}
      height='100%'
      bodyStyle={{
        height: '494px',
      }}
    >
      <ExpressionControls />
      <Box mx={-3} px={3}>
        <ScrollArea height='384px'>
          <Flex gap={3} pb={3} pt='45px' height='100%'>
            {expressionV2.map((exp, index) => {
              return (
                <ExpressionDragItem
                  key={exp.id}
                  item={exp}
                  index={index}
                  isContent={isContent}
                />
              )
            })}
          </Flex>
        </ScrollArea>
        {activeParentProvider ? (
          <Flex
            alignItems='center'
            bg='warning.1'
            gap={2}
            px={3}
            py={2}
            style={{
              top: '122px',
              left: 0,
              position: 'absolute',
              width: '100%',
            }}
          >
            <InfoCircleOutlined />
            <Text>
              You may only use segments from the current active Parent provider:
            </Text>
            <Text strong>{activeParentProvider}</Text>
          </Flex>
        ) : null}

        <Flex
          alignItems='center'
          bg='gray.1'
          borderTop='split'
          gap={4}
          height='50px'
          px={3}
          style={{
            bottom: 0,
            left: 0,
            position: 'absolute',
            width: '100%',
          }}
        >
          <Flex alignItems='center' gap={2}>
            <Box bg='purple.3' height='8px' width='16px' />
            Group
          </Flex>
          <Flex alignItems='center' gap={2}>
            <Box bg='positive.4' height='8px' width='16px' />
            Set
          </Flex>
          <Flex alignItems='center' gap={2}>
            <Box bg='magenta.4' height='8px' width='16px' />
            {isContent ? 'Option' : 'Segment'}
          </Flex>
          <Flex alignItems='center' gap={2}>
            <Box bg='primary.4' height='8px' width='16px' />
            Active Item
          </Flex>
        </Flex>
      </Box>
    </Card>
  )
}

export default ExpressionBuilder
