import React from 'react'
import PropTypes from 'prop-types'
import { Box, Accordion } from '@beachfront/ui'
import { useForm } from '@beachfront/ui/forms'

import { api } from '../../../../client-api'
import { useUserData, useCountryStates } from '../../../../hooks'
import { isNotEmptyArray } from '../../../../utils'
import {
  VirtualSelectField,
  StaticSelectField,
  MediaListField,
  IncludeExcludeField,
} from '..'

import GeoPreviewTags from './geo-preview-tags'

const GeoPanel = ({
  expandKeys,
  disabled,
  values,
  initialValues,
  params,
  adminCreate,
  id,
  isPmp,
  isDsp,
  getPopupContainer,
}) => {
  const [user] = useUserData()
  const form = useForm()

  const stateQuery = useCountryStates()
  const stateList = stateQuery.data
    ? stateQuery.data.filter((el) => values.country?.includes(el.countryCode))
    : []

  const onCountryChange = (val) => {
    if (values.state) {
      const filteredStates = values.state.filter((key) => {
        const code = stateQuery.data?.find((el) => el.key === key)?.countryCode
        return val?.includes(code)
      })
      form.change('state', filteredStates)
    }
  }

  const extra = (
    <GeoPreviewTags visible={!expandKeys.includes('geo')} values={values} />
  )

  return (
    <Accordion.Panel value='geo' header='Geo' extra={extra}>
      <Box position='relative'>
        {!user.mediaPlanExclude ? (
          <Box
            position='absolute'
            height='32px'
            right='-7px'
            top='-7px'
            className='customize-radio'
          >
            <IncludeExcludeField name='excludeCountry' disabled={disabled} />
          </Box>
        ) : null}
        <VirtualSelectField
          mode='multiple'
          allowClear
          onChange={onCountryChange}
          request={api.common.countries}
          apiParams={params}
          pagination={true}
          detail={id}
          values={values}
          initialValues={initialValues}
          disabled={disabled}
          getPopupContainer={getPopupContainer}
          emptyMsg='No Country Available'
          tooltip='Select United States to unlock Metro Code, State, and Zip Code targeting'
          form={{
            name: 'country',
            textField: 'name',
            keyField: 'key',
            label: 'Country',
            placeholder: 'All Countries',
            selectionName: 'countryObj',
          }}
        />
      </Box>
      {values.country?.includes('US') || values.country?.includes('CA') ? (
        <>
          <Box position='relative'>
            {!user.mediaPlanExclude ? (
              <Box
                position='absolute'
                height='32px'
                right='-7px'
                top='-7px'
                className='customize-radio'
              >
                <IncludeExcludeField name='excludeState' disabled={disabled} />
              </Box>
            ) : null}
            <StaticSelectField
              mode='multiple'
              allowClear
              data={stateList}
              loading={stateQuery.isLoading}
              disabled={disabled}
              getPopupContainer={getPopupContainer}
              emptyMsg='No State Available'
              form={{
                name: 'state',
                textField: 'name',
                keyField: 'key',
                label: 'State',
                placeholder: 'All States',
                selectionName: 'stateObj',
              }}
            />
          </Box>
          {!isDsp ? (
            <>
              {isPmp && !adminCreate && initialValues.zip ? (
                <Box position='relative'>
                  {!user.mediaPlanExclude ? (
                    <Box
                      position='absolute'
                      height='32px'
                      right='-7px'
                      top='-7px'
                      className='customize-radio'
                    >
                      <IncludeExcludeField
                        name='excludezip'
                        disabled={disabled}
                      />
                    </Box>
                  ) : null}
                  <VirtualSelectField
                    key={values.country.join('-')}
                    mode='multiple'
                    allowClear
                    request={api.common.zipCode}
                    apiParams={{
                      ...params,
                      stateCode: isNotEmptyArray(values?.state)
                        ? values.state
                        : 'AL',
                    }}
                    pagination={true}
                    detail={id}
                    values={values}
                    disabled={disabled}
                    initialValues={initialValues}
                    getPopupContainer={getPopupContainer}
                    emptyMsg='No Zip Code Available'
                    form={{
                      name: 'zip',
                      textField: 'name',
                      keyField: 'key',
                      label: 'Zip Code',
                      placeholder: 'Select Zip Code',
                      selectionName: 'zipObj',
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <MediaListField
                    values={values}
                    initialValues={initialValues}
                    disabled={disabled}
                    data={{
                      fieldName: 'zipMedia',
                      key: 'zipcode',
                      label: 'Zip Code',
                    }}
                  />
                </Box>
              )}
            </>
          ) : null}
          {!isDsp && values.country?.includes('US') ? (
            <>
              {isNotEmptyArray(initialValues.dma) ? (
                <Box position='relative'>
                  {!user.mediaPlanExclude ? (
                    <Box
                      position='absolute'
                      height='32px'
                      right='-7px'
                      top='-7px'
                      className='customize-radio'
                    >
                      <IncludeExcludeField
                        name='excludeDma'
                        disabled={disabled}
                      />
                    </Box>
                  ) : null}
                  <VirtualSelectField
                    mode='multiple'
                    allowClear
                    request={api.common.dmaSearch}
                    apiParams={params}
                    detail={id}
                    values={values}
                    initialValues={initialValues}
                    disabled={disabled}
                    getPopupContainer={getPopupContainer}
                    emptyMsg='No Metro Code Available'
                    form={{
                      name: 'dma',
                      textField: 'name',
                      keyField: 'dmaCode',
                      label: 'Metro Code',
                      placeholder: 'All Metro Codes',
                      selectionName: 'dmaObj',
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <MediaListField
                    values={values}
                    initialValues={initialValues}
                    disabled={disabled}
                    data={{
                      fieldName: 'dmaMedia',
                      key: 'dma',
                      label: 'Metro Code',
                    }}
                  />
                </Box>
              )}
            </>
          ) : null}
        </>
      ) : null}
    </Accordion.Panel>
  )
}

GeoPanel.defaultProps = {
  expandKeys: [],
  values: {},
  initialValues: {},
  params: {},
}

GeoPanel.propTypes = {
  expandKeys: PropTypes.array,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  adminCreate: PropTypes.bool,
  initialValues: PropTypes.object,
  params: PropTypes.object,
  id: PropTypes.string,
  isPmp: PropTypes.bool,
  isDsp: PropTypes.bool,
  getPopupContainer: PropTypes.func,
}

export default GeoPanel
