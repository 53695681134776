import http from '../http'
import { IS_DEALS_ENGINE } from '../../constants'

export default {
  session() {
    return http.get('user/session')
  },
  userList(data) {
    return http.post('user/all/account', data)
  },
  switchAccount({ params }) {
    return http.get('user/switch/account', { params })
  },
  login(data) {
    const url = IS_DEALS_ENGINE ? 'publisher/login' : 'login'
    return http.post(url, data)
  },
  logout() {
    return http.get('user/logout')
  },
  verifyEmail({ data }) {
    return http.post('verify/email', data)
  },
  verifyOtp({ data }) {
    return http.post('verify/otp', data)
  },
  resendOtp({ data }) {
    return http.post('resend/otp', data)
  },
  resetPassword({ data }) {
    return http.post('reset/password', data)
  },
  delete(id) {
    return http.get(`user/delete/${id}`)
  },
}
