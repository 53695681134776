import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useCountryStates = () => {
  return useQuery({
    queryKey: ['country-states'],
    queryFn: async () => {
      const res = await api.common.states({
        countryCode: ['US', 'CA'],
        p: 0,
        s: 100,
        q: '',
      })
      return res.data?.data
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })
}
