import React, { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  Accordion,
  Empty,
  Flex,
  Input,
  Menu,
  ScrollArea,
  Spinner,
  useToast,
  Tooltip,
} from '@beachfront/ui'
import { RightOutlined } from '@beachfront/ui/icons'

import { useBooleanSegment } from '../../../booleanSegment/boolean-segment-context'
import { addSegmentToSet } from '../../../../@utils/boolean-segment.util'
import { api } from '../../../../../../client-api'
import { isNotEmptyArray, isValidMongoId } from '../../../../../../utils'

const OptionPanel = ({ params, type }) => {
  const toast = useToast()
  const {
    data: apiData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [type],
    queryFn: async () => {
      const { data } = await api.common[type](params)
      return data
    },
    retry: 0,
  })

  const [searchValue, setSearchValue] = useState('')
  const { activeItem, expressionV2, setExpressionV2, stateHistory } =
    useBooleanSegment()
  // const optHash = useMemo(() => {
  //   try {
  //     const hash = {}

  //     apiData.data.forEach((item) => {
  //       hash[item.key] = item.name
  //     })

  //     return hash
  //   } catch (err) {
  //     console.log(err)
  //     return {}
  //   }
  // }, [apiData?.data])

  const options = useMemo(() => {
    try {
      let listData = apiData.data
        .filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        .map((item) => ({
          key: String(item.key),
          label: item.name,
          title: item.name,
        }))

      listData.forEach((items) => {
        // Find the corresponding item in apiData.data based on the key
        const matchingItem = apiData.data.find((item) => item.key === items.key)
        // If a match is found, add the 'subValues' property to listData
        if (matchingItem) {
          items.subValues = matchingItem.subValues
        }
      })
      return listData
    } catch (err) {
      return []
    }
  }, [apiData?.data, searchValue])

  const getCurrentSet = (expression) => {
    for (let i = 0; i < expression.length; i += 1) {
      const current = expression[i]

      if (current.id === activeItem.id) {
        return current
      }

      if (current.children?.length > 0) {
        const found = getCurrentSet(current.children)
        if (found) {
          // if found in the children, return it
          return found
        }
      }
    }

    return null // return null only after checking all items
  }

  const selectedKeys = useMemo(() => {
    if (activeItem?.type !== 'SET') {
      return []
    }

    const currentSet = getCurrentSet(expressionV2)

    if (!currentSet) {
      return []
    }

    return currentSet.children
      .filter((item) => item.clientId.startsWith(type))
      .map((item) => item.key)
  }, [activeItem, expressionV2])

  const onOptionSelect = (val) => {
    if (activeItem?.type !== 'SET') {
      toast.info({
        title: 'A set must be active in order to add to the expression',
      })
      return null
    }
    const filteredOptions = options.find(
      (item) => item.key === val.selectedKeys[0]
    )
    setExpressionV2((prevState) => {
      const updatedState = addSegmentToSet(
        {
          key: val.key,
          label: filteredOptions.title,
          subType: isValidMongoId(val.key) ? 'MasterGenres' : type,
        },
        activeItem,
        prevState,
        type
      )
      stateHistory.current.push(updatedState)
      return updatedState
    })
  }

  const recursiveDelete = (stateItems, key, activeItemId) => {
    return stateItems.map((stateItem) => {
      if (stateItem.type === 'GROUP' && stateItem.children) {
        const updatedChildren = recursiveDelete(
          stateItem.children,
          key,
          activeItemId
        )
        stateItem.children = updatedChildren.filter(Boolean)

        return stateItem
      } else if (
        stateItem.type === 'SET' &&
        stateItem.children &&
        stateItem.id === activeItemId
      ) {
        const updatedChildren = stateItem.children.filter(
          (child) => child.type !== 'SEGMENT' || child.key !== key
        )
        stateItem.children = updatedChildren

        return stateItem
      } else {
        return stateItem
      }
    })
  }

  const onOptionDeselect = (val) => {
    const { key } = val

    const activeItemId = activeItem.id // Get the active item's ID from your current state

    setExpressionV2((prevState) => {
      const updatedState = recursiveDelete(prevState, key, activeItemId)
      stateHistory.current.push(updatedState)
      return updatedState
    })
  }

  return (
    <Accordion.Panel header={type.toUpperCase()} value={type}>
      {!isLoading && !isError && (
        <Input
          onChange={(evt) => setSearchValue(evt.target.value)}
          placeholder={`search ${type}`}
          value={searchValue}
          size='small'
        />
      )}
      {isLoading && !isError && (
        <Flex alignItems='center' height={150} justifyContent='center'>
          <Spinner />
        </Flex>
      )}
      {!isLoading && (isError || options.length === 0) && (
        <Flex alignItems='center' height={150} justifyContent='center'>
          <Empty description='No options available' />
        </Flex>
      )}
      {!isLoading && !isError && options.length > 0 && (
        <ScrollArea overScroll='contain' height={280} mt={3}>
          <Menu
            onDeselect={onOptionDeselect}
            onSelect={onOptionSelect}
            selectedKeys={selectedKeys}
          >
            {options.map(({ key, title, subValues }) =>
              isNotEmptyArray(subValues) ? (
                <Menu.Item key={key}>
                  <Flex justifyContent='space-between'>
                    <span>{title}</span>
                    <Tooltip
                      title={subValues.join(', ')}
                      placement='right'
                      overlayInnerStyle={{
                        backgroundColor: 'lightblue',
                        color: 'black',
                      }}
                    >
                      <RightOutlined style={{ paddingTop: '12px' }} />
                    </Tooltip>
                  </Flex>
                </Menu.Item>
              ) : (
                <Menu.Item key={key}>{title}</Menu.Item>
              )
            )}
          </Menu>
        </ScrollArea>
      )}
    </Accordion.Panel>
  )
}

export default OptionPanel
