import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Flex, Text, Button, Modal, useToast } from '@beachfront/ui'
import { LoginOutlined } from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { ACCOUNT_TYPE } from '../../../enums'
import { useContentSize, useFilters, useInfiniteTable } from '../../../hooks'
import { getErrorMessage } from '../../../utils'
import { localFilterKey } from '../../../components/filter'
import { InfiniteTable, NetworkError } from '../../../components'

import { AccountFilter } from '.'

const AccountTable = ({ user }) => {
  const { contentHeight } = useContentSize()
  const toast = useToast()
  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.LOGIN_ACCOUNT)

  const table = useInfiniteTable({
    queryKey: ['user-accounts'],
    queryFn: api.user.userList,
    filters: filters.populated,
  })

  const scroll = {
    y: contentHeight - 280,
  }

  const onSwitch = (rec) => {
    Modal.confirm({
      title: 'Switch Account',
      content: `Log in as "${rec.name}"?`,
      okText: 'Yes',
      cancelText: 'No',
      maskClosable: true,
      onOk() {
        const params = { accountId: rec.accountId }

        return api.user.switchAccount({ params }).then(
          (res) => {
            if (res.data?.success) {
              window.location.replace('/')
            } else {
              toast.error({
                title:
                  res.data?.errorDetails ||
                  res.data.msg ||
                  'Unable to process request.',
              })
            }
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
            throw error
          }
        )
      },
    })
  }

  const columns = [
    {
      dataIndex: 'name',
      title: 'Account Name',
      fixed: 'left',
      width: 210,
      sorter: true,
      filter: true,
    },
    {
      dataIndex: 'type',
      title: 'Account Type',
      width: 120,
      sorter: true,
      render: (key) => ACCOUNT_TYPE.fromKey(key)?.name,
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 100,
      render: (val, rec) =>
        rec.accountId === user?.accountId ? (
          <Text color='primary.base' strong>
            Logged In
          </Text>
        ) : (
          <Button
            size='small'
            icon={<LoginOutlined />}
            onClick={() => onSwitch(rec)}
          >
            Switch
          </Button>
        ),
    },
  ]

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <AccountFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </InfiniteTable.Toolbar>
  )

  if (!table.isFetching && table.error) {
    return (
      <NetworkError
        description={getErrorMessage(table.error)}
        onAction={table.refetch}
      />
    )
  }

  return (
    <InfiniteTable
      rowKey='accountId'
      emptyText='No Accounts'
      columns={columns}
      toolbar={toolbar}
      scroll={scroll}
      filters={filters.populated}
      onFilter={(key) => filterRef.current?.addFilter(key)}
      {...table.props}
    />
  )
}

AccountTable.propTypes = {
  user: PropTypes.object,
}

export default AccountTable
