import React from 'react'
import { Flex, Text, Modal } from '@beachfront/ui'
import { DownOutlined } from '@beachfront/ui/icons'
import { useDisclosure, useBreakpoints } from '@beachfront/ui/hooks'
import styled from 'styled-components'

import { useUserData } from '../../../hooks'

import { AccountTable } from '.'

const AccountName = styled(Text)`
  display: inline-block;
  max-width: 125px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UserType = styled(Text)`
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const AccountSwitcher = () => {
  const [user] = useUserData()
  const accountModal = useDisclosure()
  const breakpoints = useBreakpoints()

  return (
    <>
      <Flex
        px={2}
        minWidth='100px'
        height='32px'
        bg='dark.selected'
        color='dark.hover'
        borderRadius='base'
        alignItems='center'
        whiteSpace='nowrap'
        cursor='pointer'
        onClick={() => accountModal.open()}
      >
        <Flex flexDirection='column' flexGrow={1}>
          <AccountName>{user?.accountName}</AccountName>
          <UserType color='dark.text'>{user?.accountType?.key}</UserType>
        </Flex>
        <DownOutlined style={{ marginLeft: '12px' }} />
      </Flex>
      <Modal
        title='Login Accounts'
        visible={accountModal.isOpen}
        onCancel={() => accountModal.close()}
        width={breakpoints.md ? 550 : '100%'}
        footer={null}
        bodyStyle={{ overflow: 'auto', height: '100%' }}
        centered
      >
        <AccountTable user={user} />
      </Modal>
    </>
  )
}

export default AccountSwitcher
