import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Flex } from '@beachfront/ui'
import {
  ResponsiveContainer,
  AreaChart as BaseAreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'

import { CustomTooltip } from '../widget/custom-tooltip'
import { formatTick } from '../widget/widget-util'

import AxisLabel from './axis-label'

const AreaChart = ({
  data,
  plotProps,
  tooltipLabel,
  xDataKey,
  yDataKey,
  xAxisLabel,
  yAxisLabel,
}) => {
  const theme = useTheme()

  return (
    <Flex
      width='100%'
      fontFamily='secondary'
      fontSize={0}
      style={{ height: plotProps.height }}
    >
      <ResponsiveContainer>
        <BaseAreaChart data={data} margin={{ top: 10, right: 25, left: 10 }}>
          <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
          <XAxis
            dataKey={xDataKey ?? 'x'}
            interval='preserveStartEnd'
            axisLine={{ stroke: theme.colors.gray[4] }}
            tickLine={{ stroke: theme.colors.gray[4] }}
            tick={{ fill: theme.colors.gray[6] }}
            tickMargin={8}
            padding={{ left: 8, right: 8 }}
            height={50}
            label={{
              value: xAxisLabel,
              position: 'insideBottom',
              fill: theme.colors.text,
            }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: theme.colors.gray[6] }}
            tickFormatter={(val) => formatTick(val)}
            label={
              yAxisLabel ? (
                <AxisLabel
                  axisType='y'
                  x={10}
                  y={120}
                  width={0}
                  height={0}
                  fill={theme.colors.text}
                >
                  {yAxisLabel}
                </AxisLabel>
              ) : undefined
            }
          />
          <Tooltip
            cursor={{ stroke: theme.colors.gray[3] }}
            wrapperStyle={{ zIndex: 1 }}
            content={<CustomTooltip />}
            isAnimationActive={false}
          />
          <Area
            dataKey={yDataKey ?? 'y'}
            type='monotone'
            name={tooltipLabel}
            strokeWidth={2}
            stroke={theme.colors.primary.base}
            fill={theme.colors.primary[1]}
            dot={{
              fill: theme.colors.bg.component,
              fillOpacity: 1,
              strokeDasharray: '',
            }}
            activeDot={{ stroke: theme.colors.bg.component }}
            isAnimationActive={false}
          />
        </BaseAreaChart>
      </ResponsiveContainer>
    </Flex>
  )
}

AreaChart.propTypes = {
  data: PropTypes.array,
  plotProps: PropTypes.object,
  tooltipLabel: PropTypes.string,
  xDataKey: PropTypes.any,
  yDataKey: PropTypes.any,
  xAxisLabel: PropTypes.node,
  yAxisLabel: PropTypes.node,
}

export default AreaChart
