export const HOST_NAME = 'Beachfront'

export const IS_DEALS_ENGINE = import.meta.env.MODE === 'deals'

export const USER_KEY = IS_DEALS_ENGINE
  ? 'beachfront-deals-user'
  : 'beachfront-access-user'

export const TOKEN_KEY = IS_DEALS_ENGINE
  ? 'beachfront-deals-token'
  : 'beachfront-access-token'
