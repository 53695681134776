import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import arrayMutators from 'final-form-arrays'

import { api } from '../../../client-api'
import { useReportConfig } from '../../../hooks'
import { getErrorMessage } from '../../../utils'
import { TIMEZONE } from '../../../enums'
import {
  PageHeader,
  DirtyPrompt,
  NetworkError,
  CommonSpinner,
} from '../../../components'
import { validateReportForm } from '../@utils'

import { ReportForm } from '.'

const CreateReport = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const toast = useToast()
  const configQuery = useReportConfig()

  const onSubmit = (values, form) => {
    const payload = { ...values }

    if (values.timezone !== TIMEZONE.CUSTOM.key) {
      delete payload.offset
    }

    if (values.startDate) {
      payload.startDate = values.startDate.format('YYYY-MM-DD')
    }

    if (values.endDate) {
      payload.endDate = values.endDate.format('YYYY-MM-DD')
    }

    return api.report.create(payload).then(
      () => {
        queryClient.invalidateQueries({ queryKey: ['reports'] })
        toast.success({ title: 'Report created.' })
        form.initialize(values)
        setTimeout(() => navigate('..', { relative: 'path' }), 300)
      },
      (error) => {
        // TODO Validate report title before form is submitted
        if (error.response?.status === 400) {
          toast.error({ title: 'Report title already exists.' })
        } else {
          toast.error({ title: getErrorMessage(error) })
        }
      }
    )
  }

  if (configQuery.isFetching) {
    return <CommonSpinner />
  }

  if (configQuery.error) {
    return (
      <NetworkError
        description={getErrorMessage(configQuery.error)}
        onAction={configQuery.refetch}
      />
    )
  }

  return (
    <Form
      validate={validateReportForm}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
    >
      {({ handleSubmit, submitting, dirty }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Create Report'
            actions={
              <>
                <Button onClick={() => navigate('/reports/owned')}>
                  Cancel
                </Button>
                <Button
                  type='primary'
                  loading={submitting}
                  disabled={submitting || !dirty}
                  onClick={handleSubmit}
                >
                  Create
                </Button>
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3}>
            <ReportForm config={configQuery.data} />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default CreateReport
