import React, { useMemo } from 'react'

import {
  AreaChartWidget,
  LineChartWidget,
  StackedBarChartWidget,
} from '../../../components/widget'

function Chart({ view, data, selectedKey, colorOffset }) {
  const graphData = useMemo(() => {
    const areaData = { xAxis: [], values: [] }
    const graphLabel = {}
    const lineData =
      data?.map((item) => {
        areaData.xAxis.push(item.label)
        areaData.values.push(item.value)

        return {
          label: item.label,
          chartData: [
            {
              name: item.name,
              value: item.value,
              key: item.key,
            },
          ],
        }
      }) ?? []

    return { lineData, areaData, graphLabel }
  }, [data])

  const prefix = selectedKey === 'revenue' ? { prefix: '$' } : null

  return (
    <>
      {view === 'AREA' && (
        <AreaChartWidget
          data={graphData.areaData}
          cardBodyHeight={350}
          bordered={false}
          colorOffset={colorOffset}
          {...graphData.graphLabel}
          {...prefix}
        />
      )}
      {view === 'LINE' && (
        <LineChartWidget
          data={graphData.lineData}
          cardBodyHeight={350}
          bordered={false}
          colorOffset={colorOffset}
          {...prefix}
        />
      )}
      {view === 'STACKED' && (
        <StackedBarChartWidget
          data={graphData.lineData}
          cardBodyHeight={350}
          bordered={false}
          {...prefix}
        />
      )}
    </>
  )
}

export default Chart
