import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Layout, Flex } from '@beachfront/ui'

import { useSiderCollapsed, useOfflineNotification } from '../../../hooks'

import { BrandLogo, MenuBar, TopNavBar, MenuFooter } from '.'

const AppLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useSiderCollapsed()
  const siderRef = useRef()

  useOfflineNotification()

  return (
    <Layout>
      <Layout.Sider
        ref={siderRef}
        width={230}
        bg='dark.bg'
        collapsed={collapsed}
        onCollapseToggle={setCollapsed}
        collapsible
      >
        <Flex flexDirection='column' height='100%'>
          <BrandLogo />
          <MenuBar getPopupContainer={() => siderRef.current} />
          <MenuFooter />
        </Flex>
      </Layout.Sider>
      <Layout.Header height='40px' fixed>
        <TopNavBar />
      </Layout.Header>
      <Layout.Content px={4} pb={3} mt='40px'>
        {children}
      </Layout.Content>
    </Layout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node,
}

export default AppLayout
