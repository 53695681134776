import moment from 'moment'

export const DATE_RANGES = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
  'Last 14 Days': [moment().subtract(14, 'days'), moment().subtract(1, 'days')],
  'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
  'Month to Date': [moment().startOf('month').startOf('day'), moment()],
  'Previous Month': [
    moment().subtract(1, 'month').startOf('month').startOf('day'),
    moment().subtract(1, 'month').endOf('month').endOf('day'),
  ],
}
