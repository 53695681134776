import React, { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../client-api'
import { formatDate, getErrorMessage } from '../../utils'
import { localFilterKey } from '../../components/filter'
import { useContentSize, useFilters, useInfiniteTable } from '../../hooks'
import { PageHeader, InfiniteTable, NetworkError } from '../../components'

import AvailsFilter from './avails-filter'

const AvailsExplorer = () => {
  const { contentWidth, contentHeight } = useContentSize()
  const navigate = useNavigate()

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.EXISTING_AVAILS, {
    schema: {
      createDate: 'date',
    },
  })

  const table = useInfiniteTable({
    queryKey: ['all-avails'],
    queryFn: api.common.getAllAvails,
    filters: filters.populated,
    params: { q: '' },
  })

  const scroll = {
    x: contentWidth - 300,
    y: contentHeight - 260,
  }

  const columns = [
    {
      dataIndex: 'title',
      title: 'Name',
      fixed: 'left',
      sorter: true,
      filter: true,
      width: 250,
      render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
    },
    {
      dataIndex: 'creatorName',
      title: 'Creator Name',
      sorter: true,
      filter: true,
      width: 150,
    },
    {
      dataIndex: 'createDate',
      title: 'Created',
      sorter: true,
      filter: true,
      width: 150,
      render: (val) => formatDate(val),
    },
  ]

  const headerActions = (
    <>
      <Button onClick={() => table.refetch()} icon={<ReloadOutlined />} />
      <Button
        type='primary'
        onClick={() => navigate('/admin/avails-explorer/create')}
      >
        Create Avails
      </Button>
    </>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <AvailsFilter
        filterRef={filterRef}
        filters={filters.all}
        setFilters={setFilters}
      />
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title='Avails Explorer' actions={headerActions} />
      <Box mt={2}>
        {!table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <InfiniteTable
            rowKey='id'
            emptyText='No Data Found'
            columns={columns}
            toolbar={toolbar}
            scroll={scroll}
            filters={filters.populated}
            onFilter={(key) => filterRef.current?.addFilter(key)}
            {...table.props}
          />
        )}
      </Box>
    </>
  )
}

export default AvailsExplorer
