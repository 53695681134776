import { useDebounce, useEventListener } from '@beachfront/ui/hooks'

const getElementSize = () => [
  document.documentElement.clientWidth,
  document.documentElement.clientHeight,
]

export const useContentSize = () => {
  const [[w, h], setSize] = useDebounce(getElementSize, 300)
  useEventListener('resize', () => setSize(getElementSize), window)
  return { contentWidth: w, contentHeight: h }
}
