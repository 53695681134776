import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  Grid,
  Flex,
  Box,
  Card,
  Avatar,
  Button,
  Heading,
  Tag,
  Tooltip,
} from '@beachfront/ui'
import { AuditOutlined, TrafficOutlined } from '@beachfront/ui/icons'

const DealFeatureCard = ({ deal }) => {
  const theme = useTheme()

  return (
    <Card inner size='small' style={{ background: theme.colors.gray[1] }}>
      <Flex gap={3} width='100%' height='100%' flexDirection='column'>
        <Flex gap={3}>
          <Avatar
            src={deal.icon}
            fallback={<AuditOutlined />}
            alt='Featured Deal Icon'
            bg='transparent'
            color='gray.5'
            shape='square'
            size={105}
          />
          <Flex gap={1} flexDirection='column'>
            {/* <Box mb={1}>
              <Tag color='green'>{`${deal.cpm} CPM`}</Tag>
            </Box> */}
            {deal.avails?.map((avail) => (
              <Box key={avail.key}>
                <Tooltip title={`${avail.key} Avails`} placement='bottom'>
                  <Tag>
                    <TrafficOutlined />
                    {avail.key}
                    {': '}
                    {avail.value}
                  </Tag>
                </Tooltip>
              </Box>
            ))}
          </Flex>
        </Flex>
        <Box flexGrow={1}>
          <Heading level={5}>{deal.title}</Heading>
          <ExpandableText lines={3} fontSize={0}>
            {deal.desc}
          </ExpandableText>
        </Box>
        <Grid columns={2} gap={2}>
          <Link to={`/deals/library/${deal.id}`}>
            <Button block>View Details</Button>
          </Link>
          <Link to={`/deals/self/template/${deal.id}`}>
            <Button type='primary' block>
              Use Template
            </Button>
          </Link>
        </Grid>
      </Flex>
    </Card>
  )
}

DealFeatureCard.propTypes = {
  deal: PropTypes.object,
}

// TODO consider moving this component to the beachfront-ui package
const ExpandableText = ({ lines = 2, children, ...rest }) => {
  const textRef = useRef()
  const [expand, setExpand] = useState(false)
  const [truncated, setTruncated] = useState(false)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0]
      const trunc = entry.target.scrollHeight > entry.contentRect.height
      setTruncated(trunc)
    })
    observer.observe(textRef.current)
    return () => observer.disconnect()
  }, [])

  const style = {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: expand ? 'unset' : String(lines),
  }

  const onExpand = (event) => {
    event.preventDefault()
    setExpand(true)
  }

  return (
    <Box {...rest}>
      <Box ref={textRef} style={style}>
        {children}
      </Box>
      {truncated && !expand ? (
        <Box as='a' onClick={onExpand}>
          Read More
        </Box>
      ) : null}
    </Box>
  )
}

ExpandableText.propTypes = {
  lines: PropTypes.number,
  children: PropTypes.node,
}

export default DealFeatureCard
