import React from 'react'
import PropTypes from 'prop-types'

const TiltedAxisTick = ({ x, y, payload, fill, rotate, tickFormatter }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={8}
        textAnchor='end'
        transform={`rotate(${rotate ?? -40})`}
        fill={fill}
      >
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    </g>
  )
}

TiltedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  fill: PropTypes.string,
  rotate: PropTypes.string,
  tickFormatter: PropTypes.func,
}

export default TiltedAxisTick
