import React from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from '@beachfront/ui'
import { useBreakpoints } from '@beachfront/ui/hooks'

const ChangeLogModal = ({ data = [], title, isOpen, onCancel }) => {
  const breakpoints = useBreakpoints()
  const action = data[0]?.action?.toLowerCase()

  const renderValue = (val) => {
    if (!val) {
      return '--'
    }
    if (String(val).toLowerCase() === 'truncate') {
      return 'Truncated'
    }
    return <div style={{ overflowWrap: 'anywhere' }}>{val}</div>
  }

  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
      width: 120,
    },
    {
      dataIndex: 'action',
      title: 'Action',
      width: 120,
      render: (val) => val || '--',
    },
  ]

  if (action === 'removed' || action === 'remove') {
    columns.push({
      dataIndex: 'newValue',
      title: 'Removed Value',
      width: 120,
      render: renderValue,
    })
  } else if (action === 'updated' || action === 'update') {
    columns.push({
      dataIndex: 'newValue',
      title: 'Updated Value',
      width: 120,
      render: renderValue,
    })
  } else {
    columns.push(
      {
        dataIndex: 'oldValue',
        title: 'Old Value',
        width: 120,
        render: renderValue,
      },
      {
        dataIndex: 'newValue',
        title: 'New Value',
        width: 120,
        render: renderValue,
      }
    )
  }

  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      width={breakpoints.md ? '60%' : '100%'}
      bodyStyle={{ minHeight: 200 }}
      centered
    >
      <Table
        rowKey={(r, i) => i}
        dataSource={data}
        columns={columns}
        pagination={{
          pageSize: data.length,
          hideOnSinglePage: true,
        }}
      />
    </Modal>
  )
}

ChangeLogModal.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default ChangeLogModal
