import { api } from '../../client-api'
import { useServerTable } from '../tables/use-server-table'

export const useUsers = ({ admin, filters } = {}) => {
  const table = useServerTable({
    queryKey: admin ? 'admin-users' : 'users',
    queryFn: admin ? api.admin.user.getAll : api.users.getAll,
    filters: filters,
    schema: {
      data: 'data',
      total: 'total',
      fields: {
        createDate: 'date',
        lastLogin: 'date',
      },
    },
    defaultPageSize: 50,
  })
  if (Array.isArray(table.data)) {
    table.data.forEach((dt) => {
      dt.accounts = dt.accountAccess?.length ?? 0
    })
  }
  return table
}
