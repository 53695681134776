import React from 'react'
import PropTypes from 'prop-types'
import { Filter, Input, Select, Debouncer } from '@beachfront/ui'

const ContainsFilterPanel = ({
  focusRef,
  value,
  setValue,
  operator,
  setOperator,
  close,
}) => {
  return (
    <Input.Group compact>
      <Select style={{ width: 150 }} value={operator} onChange={setOperator}>
        <Select.Option value={Filter.Operator.String.CONTAINS.key}>
          Contains
        </Select.Option>
        <Select.Option value={Filter.Operator.String.STARTS_WITH.key}>
          Starts With
        </Select.Option>
      </Select>
      <Debouncer value={value} onChange={setValue}>
        {({ value, onChange }) => (
          <Input
            ref={focusRef}
            style={{ width: 150 }}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onPressEnter={close}
          />
        )}
      </Debouncer>
    </Input.Group>
  )
}

ContainsFilterPanel.propTypes = {
  focusRef: PropTypes.object,
  value: PropTypes.any,
  setValue: PropTypes.func,
  operator: PropTypes.any,
  setOperator: PropTypes.func,
  close: PropTypes.func,
}

export default ContainsFilterPanel
