import React, { useMemo, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as BaseThemeProvider } from 'styled-components'
import { createTheme } from '@beachfront/ui'
import { setTwoToneColor } from '@beachfront/ui/icons'
import { useLocalStorage } from '@beachfront/ui/hooks'

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [baseTheme, setBaseTheme] = useLocalStorage('baseTheme', 'light')
  const theme = useMemo(() => createTheme({ base: baseTheme }), [baseTheme])

  setTwoToneColor([theme.colors.primary.base, theme.colors.primary.light])

  return (
    <ThemeContext.Provider value={{ baseTheme, setBaseTheme }}>
      <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export const useThemeContext = () => useContext(ThemeContext)
