import { PLATFORM, MEDIA_TYPE, MEDIA_PLAN_STATUS } from '../../../enums'

export default {
  audienceBooleanLogic: {
    client: [],
    enabled: false,
    value: '',
  },
  contentBooleanLogic: {
    client: [],
    enabled: false,
    value: '',
  },
  includeLivestream: false,
  platform: [PLATFORM.ALL.key],
  mediaType: [MEDIA_TYPE.VIDEO.key],
  status: MEDIA_PLAN_STATUS.ENABLE.key,
  includeLivestream: false,
  excludeCountry: 'INCLUDE',
  excludeDma: 'INCLUDE',
  excludeState: 'INCLUDE',
  excludeiabCat: 'INCLUDE',
  excludeChannel: 'INCLUDE',
  excludeNetwork: 'INCLUDE',
  excludeGenres: 'INCLUDE',
  excludeRating: 'INCLUDE',
  excludeApp: 'INCLUDE',
  excludeDomain: 'INCLUDE',
  excludeBundle: 'INCLUDE',
  excludezip: 'INCLUDE',
  includeSegmentAnd: 'OR',
  excludeSegmentAnd: 'AND',
  excludeUserId: 'INCLUDE',
}
