import React, { useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../client-api'
import { TIMEZONE } from '../../enums'
import { getErrorMessage } from '../../utils'
import { PageHeader } from '../../components'
import {
  DownloadButton,
  TargetingForm,
  formInitialValues,
  validateForm,
} from '../media-plan'

import { createAvailsPostPayload } from './avails-util'

const CreateAvails = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)
  const [availsDsp, setAvailsDsp] = useState('')
  const [dataCost, setDataCost] = useState(0)
  const [downloadEnabled, setDownloadEnabled] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const initialValues = useMemo(() => {
    return { ...formInitialValues, excludeAccIds: 'INCLUDE' }
  }, [])

  const onSubmit = (values, form) => {
    const payload = createAvailsPostPayload(values)

    return api.common.saveAvails(payload).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'Avails explore created.' })
          form.initialize(values)
          setTimeout(() => navigate('/admin/avails-explorer'))
        } else {
          toast.error({
            title:
              res.data?.msg ||
              res.data.errorDetails ||
              'Unable to create avails explore. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validate={(values) => validateForm({ values })}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, dirty, submitting }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Create Avails'
            actions={
              <>
                {/* <DownloadButton
                  values={values}
                  dataCost={dataCost}
                  timezone={timezone}
                  disabled={!downloadEnabled}
                  onDownloadStart={() => setIsDownloading(true)}
                  onDownloadComplete={() => setIsDownloading(false)}
                /> */}
                <Link to='/admin/avails-explorer'>
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={!dirty || isDownloading}
                  loading={submitting}
                >
                  Create
                </Button>
              </>
            }
          />
          <Box mt={3} overflow='auto'>
            <TargetingForm
              availsDsp={availsDsp}
              adminCreate={true}
              pmp={true}
              params={{ pmp: true }}
              values={values}
              timezone={timezone}
              onTimezoneChange={setTimezone}
              onCostChange={setDataCost}
              onChartFetch={setDownloadEnabled}
              onAvailsDspChange={setAvailsDsp}
            />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default CreateAvails
