import { createEnum } from '../utils'

export const DEAL_TYPE = createEnum({
  FIXED: {
    name: 'Fixed',
  },
  AUCTION: {
    name: 'Auction',
  },
})
