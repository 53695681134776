import React from 'react'
import { Box, Grid, Radio, Modal, useToast } from '@beachfront/ui'
import { Form, Field } from '@beachfront/ui/forms'
import { useBreakpoints } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { MEDIA_LIST_TYPE, LIST_INPUT_TYPE } from '../../../enums'
import { getErrorMessage } from '../../../utils'
import { SelectField } from '../../../components'

import { MediaListUploadForm } from '.'

const MediaListItemModal = ({
  listId,
  mediaType,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const toast = useToast()
  const breakpoints = useBreakpoints()
  const mediaTypeName = MEDIA_LIST_TYPE.fromKey(mediaType)?.name ?? mediaType

  const initialValues = {
    mediaType: mediaType,
    inputType: LIST_INPUT_TYPE.MANUAL.key,
  }

  const validate = (values) => {
    const errors = {}

    if (
      values.inputType === LIST_INPUT_TYPE.MANUAL.key &&
      !values.items?.length
    ) {
      errors.items = 'Please enter at least one item.'
    }

    if (values.inputType === LIST_INPUT_TYPE.UPLOAD.key) {
      if (values.uploadState?.state !== 'success') {
        errors.file = 'Please upload a valid CSV file.'
      } else if (!values.uploadState?.data?.data?.length) {
        errors.file = 'Please upload a valid CSV file with at least one item.'
      }
    }

    return errors
  }

  const onSubmit = (values, form) => {
    const payload = {}

    if (values.inputType === LIST_INPUT_TYPE.MANUAL.key) {
      payload.input = values.items || []
    }

    if (values.inputType === LIST_INPUT_TYPE.UPLOAD.key) {
      payload.input = values.uploadState.data.data
      payload.type = 'uploaded'
    }

    return api.mediaList.update(values.mediaType, listId, payload).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'Media list updated.' })
          form.restart()
          setTimeout(() => onSuccess?.())
          setTimeout(() => onClose?.())
        } else {
          toast.error({
            title:
              res.data?.msg ||
              res.data.errorDetails ||
              'Unable to update this media list. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const onSelect = (val, form, values) => {
    const vals = val?.split(',') || []
    const oldVal = values.items || []
    let newVal = [...oldVal, ...vals]
    newVal = [...new Set(newVal)]
    form.change('items', newVal)
  }

  return (
    <Form initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, form, values }) => (
        <Modal
          title={`Add ${mediaTypeName}s`}
          visible={isOpen}
          width={breakpoints.md ? '50%' : '100%'}
          cancelButtonProps={{ disabled: submitting }}
          okButtonProps={{ loading: submitting }}
          okText='Submit'
          afterClose={() => form.restart()}
          onCancel={onClose}
          onOk={handleSubmit}
        >
          <form onSubmit={handleSubmit}>
            <Grid columns={2}>
              <Field name='inputType' label='Input Type' type='text'>
                <Radio.Group>
                  {[LIST_INPUT_TYPE.MANUAL, LIST_INPUT_TYPE.UPLOAD].map(
                    ({ key, name }) => (
                      <Radio key={key} value={key}>
                        {name}
                      </Radio>
                    )
                  )}
                </Radio.Group>
              </Field>
            </Grid>
            {values.inputType === LIST_INPUT_TYPE.MANUAL.key ? (
              <Box className='hide-dropdown'>
                <Box my={2}>
                  Enter multiple values separated by a comma (,) or a single
                  value by pressing enter.
                </Box>
                <Field name='items'>
                  <SelectField
                    mode='tags'
                    keyField='id'
                    textField='name'
                    placeholder='Enter items'
                    data={[]}
                    onSelect={(val) => onSelect(val, form, values)}
                    disabled={submitting}
                    allowClear
                  />
                </Field>
              </Box>
            ) : null}
            {values.inputType === LIST_INPUT_TYPE.UPLOAD.key ? (
              <MediaListUploadForm />
            ) : null}
          </form>
        </Modal>
      )}
    </Form>
  )
}

MediaListItemModal.propTypes = {
  listId: PropTypes.string,
  mediaType: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default MediaListItemModal
