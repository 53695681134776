import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Result,
  Flex,
  Grid,
  Heading,
  Input,
  Modal,
  useToast,
} from '@beachfront/ui'
import { Link } from 'react-router-dom'
import {
  FrownFilled,
  ReloadOutlined,
  FileSearchOutlined,
} from '@beachfront/ui/icons'
import { useDisclosure } from '@beachfront/ui/hooks'
import { useTheme } from 'styled-components'

import { api } from '../../../client-api'
import { usePdfDocs, useUserData } from '../../../hooks'
import { getErrorMessage, isNotEmptyArray } from '../../../utils'
import { CommonSpinner, NetworkError } from '../../../components'

import HelpCenterHeader from './help-center-header'
import DocumentationLayout from './documentation-layout'

import { UploadDocsModal } from '.'

const Documentation = () => {
  const pdfList = usePdfDocs()
  const uploadModal = useDisclosure()
  const toast = useToast()
  const [users] = useUserData()
  const theme = useTheme()
  const [selectedKeyList, setSelectedKeyList] = useState([])
  const [pdfDocs, setPdfDocs] = useState([])
  const [toggle, setToggle] = useState({ isChecked: false, countToggle: 0 })
  const [searchData, setSearchData] = useState('')

  useEffect(() => {
    if (pdfList.isFetching) {
      setSearchData('')
    }
    if (pdfList.data) {
      setSelectedKeyList([])
      setToggle((prevState) => ({
        ...prevState,
        countToggle: 0,
      }))
      setPdfDocs(pdfList.data)
    }
  }, [JSON.stringify(pdfList)])

  const handleDocSearch = (searchStr) => {
    let docs = []
    if (isNotEmptyArray(pdfList.data)) {
      docs = pdfList.data.filter((item) => {
        return item.title.toLowerCase().includes(searchStr.toLowerCase())
      })
    }
    if (isNotEmptyArray(docs)) {
      setPdfDocs(docs)
    } else {
      setPdfDocs([])
      setSearchData(searchStr)
    }
  }

  useEffect(() => {
    if (toggle.countToggle > 0 && !isNotEmptyArray(pdfDocs)) {
      setToggle((prevState) => ({
        ...prevState,
        countToggle: 0,
      }))
    } else if (isNotEmptyArray(selectedKeyList)) {
      setToggle((prevState) => ({
        ...prevState,
        countToggle: selectedKeyList.length - 1 + 1,
      }))
    }
  }, [pdfDocs, selectedKeyList])

  const onDelete = () => {
    Modal.confirm({
      title: 'Delete Document',
      content: 'Are you sure you want to delete selected document(s)?',
      okText: 'Delete',
      width: '450px',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.support.deleteDocs(selectedKeyList).then(
          (res) => {
            toast.success({
              title: res.data.msg || 'Document deleted successfully.',
            })
            pdfList.refetch()
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
            throw error
          }
        )
      },
    })
  }

  return (
    <>
      <HelpCenterHeader />
      <Flex justifyContent='space-between' mt={4}>
        <Heading level={2}>Documentation</Heading>
        <Flex gap={2}>
          <Box width={240}>
            <Input.Search
              onSearch={handleDocSearch}
              onChange={(e) => setSearchData(e.target.value)}
              disabled={pdfDocs.message}
              value={searchData}
              placeholder='Search for documents...'
              allowClear
            />
          </Box>
          {users.superUser ? (
            <Badge count={toggle.countToggle}>
              <Button
                danger
                disabled={toggle.countToggle === 0}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Badge>
          ) : null}
          {users.superUser ? (
            <Button onClick={() => uploadModal.open()}>Upload</Button>
          ) : null}
          <Link to='/helpcenter'>
            <Button>Back</Button>
          </Link>
          <Button onClick={pdfList.refetch} icon={<ReloadOutlined />} />
        </Flex>
      </Flex>
      {!pdfList.isFetching && pdfList.error ? (
        <NetworkError
          description={getErrorMessage(pdfList.error)}
          onAction={pdfList.refetch}
        />
      ) : null}
      {!pdfList.isFetching && !isNotEmptyArray(pdfDocs) && !pdfDocs.message ? (
        <Box pt={5}>
          <Result
            icon={<FileSearchOutlined />}
            title={`We have not found any document related to your search '${searchData}'`}
            description='For a perfect search check the spelling or search for another document'
          />
        </Box>
      ) : null}
      {pdfList.isFetching ? (
        <CommonSpinner />
      ) : (
        <Grid mt={3} gap={3} columns='repeat(auto-fill, minmax(308px, 1fr))'>
          {isNotEmptyArray(pdfDocs)
            ? pdfDocs.map((item) => (
                <DocumentationLayout
                  key={item.key}
                  pdf={item}
                  users={users}
                  refetch={pdfList.refetch}
                  setSelectedKeyList={setSelectedKeyList}
                  setToggle={setToggle}
                  isSelected={selectedKeyList.includes(item.key)}
                />
              ))
            : null}
          {pdfDocs.message ? (
            <Box
              margin='0'
              position='absolute'
              top='63%'
              left='49%'
              transform='translate(-50%, -50%)'
            >
              <Box>
                <FrownFilled
                  style={{
                    width: '155px',
                    height: '60px',
                    fontSize: '50px',
                    color: theme.colors.negative[3],
                  }}
                />
              </Box>
              <Box fontSize='large'>{pdfList?.data.message}</Box>
            </Box>
          ) : null}
        </Grid>
      )}
      {uploadModal.isOpen ? (
        <UploadDocsModal uploadModal={uploadModal} refetch={pdfList.refetch} />
      ) : null}
    </>
  )
}

export default Documentation
