import React from 'react'
import { Flex, Box, Button, Popconfirm, Table } from '@beachfront/ui'
import PropTypes from 'prop-types'
import { useField } from '@beachfront/ui/forms'
import { PlusOutlined, DeleteOutlined } from '@beachfront/ui/icons'

import { ACCOUNT_TYPE } from '../../../../../enums'
import { getUniqueId } from '../../../../../utils'
import {
  advertiserIdCell,
  publisherIdCell,
  seatAgencyIdCell,
  adomainCell,
} from '../../@constants'
import { EditableCell } from '..'

const LinkDataField = ({ type, name }) => {
  let { input } = useField(name)

  if (!type) {
    return null
  }

  const actionCell = {
    title: 'Action',
    dataIndex: 'action',
    align: 'center',
    width: 100,
    render: (text, record) => {
      const disabled = input.value.length <= 1
      return (
        <Popconfirm
          title='Delete this data?'
          onConfirm={() => handleDelete(record.key)}
          disabled={disabled}
          okButtonProps={{ danger: true }}
          okText='Delete'
          placement='topRight'
          arrowPointAtCenter
        >
          <Button
            danger
            size='small'
            icon={<DeleteOutlined />}
            disabled={disabled}
          />
        </Popconfirm>
      )
    },
  }

  const handleDelete = (key) => {
    input.onChange(input.value.filter((item) => item.key !== key))
  }

  const handleAdd = () => {
    const newData = {
      key: getUniqueId(),
      advertiserId: '',
      seatAgencyId: '',
      seatOrAgency: 'seat',
      adomain: '',
      pubId: '',
    }
    input.onChange([...input.value, newData])
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  }
  let cols = []
  if (type === ACCOUNT_TYPE.DSP.key) {
    cols = [advertiserIdCell, actionCell]
  }
  if (type === ACCOUNT_TYPE.PUBLISHER.key) {
    cols = [publisherIdCell]
  }
  if (type === ACCOUNT_TYPE.AGENCY.key) {
    cols = [advertiserIdCell, seatAgencyIdCell, actionCell]
  }
  if (type === ACCOUNT_TYPE.BRAND.key) {
    cols = [adomainCell, actionCell]
  }
  const columns = cols.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
        }
      },
    }
  })

  return (
    <Flex style={{ width: '100%' }}>
      <Table
        size='small'
        bordered
        pagination={false}
        rowKey={(r, i) => i}
        components={components}
        dataSource={input.value}
        columns={columns}
      />
      {type !== ACCOUNT_TYPE.PUBLISHER.key ? (
        <Box ml={2}>
          <Button onClick={handleAdd} icon={<PlusOutlined />} />
        </Box>
      ) : null}
    </Flex>
  )
}

LinkDataField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default LinkDataField
