export default [
  {
    key: 'general',
    path: 'general',
    title: 'General',
    columnSpan: [24, 24, 20],
  },
  {
    key: 'delivery',
    path: 'delivery',
    title: 'Delivery',
    columnSpan: [24, 24, 20],
  },
  {
    key: 'configuration',
    path: 'configuration',
    title: 'Bidstream Data Configuration',
    columnSpan: [24, 24, 20],
  },
]
