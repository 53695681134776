import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useProviderTypes = (activeTab) => {
  const { data, ...rest } = useQuery({
    queryKey: ['provider-types', activeTab],
    queryFn: async () => {
      const { data } = await api.mediaPlan.getTypeList({
        params: {
          type: activeTab === 'uploaded' ? 'upload' : activeTab,
        },
      })
      return data
    },
  })

  return {
    ...rest,
    types: data?.data || [],
  }
}
