import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useIabCategories = ({ enabled } = {}) => {
  return useQuery({
    queryKey: ['iab-categories'],
    queryFn: async () => {
      const res = await api.common.iabCategory()
      return res.data?.data
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled,
  })
}
