import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Flex,
  Grid,
  Button,
  Input,
  Tooltip,
  FieldControl,
  useToast,
} from '@beachfront/ui'
import { Field, useField } from '@beachfront/ui/forms'
import { PlusOutlined, ReloadOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

import { api } from '../../../client-api'
import { useContentSize, useFetch, useUserData } from '../../../hooks'
import { DEAL_STATUS, DEAL_TYPE, MARGIN_TYPE } from '../../../enums'
import { SelectField, LabelTooltip } from '../../../components'
import {
  isArray,
  isFloatValue,
  isNotEmptyArray,
  isNotEmptyObject,
} from '../../../utils'
import { getDealTooltip } from '../@utils'

import { DealCommentsWidget } from '.'

const SettingForm = ({
  create,
  initialValues,
  values,
  setIsDealUpdate,
  superAdmin,
  form,
  libraryId,
  disableDeal,
  selectedDsp,
  setSelectedDsp,
  disableForm,
}) => {
  const toast = useToast()
  const { contentHeight } = useContentSize()
  const [user] = useUserData()
  const [filter, setFilter] = useState({})

  const formDisable = !!superAdmin || disableForm

  const [selectedMedia, setSelectedMedia] = useState(
    initialValues.selectedMedia || {}
  )
  const [dspList, setDspList] = useState([])
  const [mediaList, setMediaList] = useState([])
  const [generateIdLoader, setGenerateIdLoader] = useState(false)

  const baseLocation = superAdmin ? '/admin/mediaplan/' : '/mediaplan/'

  const { input: pubSeats } = useField('pubSeats')
  const { input: seat } = useField('seat')
  const { input: dealId } = useField('dealId')

  useEffect(() => {
    if (!isEqual(values, initialValues)) {
      setIsDealUpdate(true)
    } else {
      setIsDealUpdate(false)
    }
  }, [JSON.stringify(values)])

  const dspResponse = useFetch({
    request: api.deal.getPublisherDsp,
    payload: { buyer: true },
  })

  const mediaPlanResponse = useFetch({
    request: libraryId ? null : api.deal.getMediaPlans,
  })

  const selectChange = (id) => {
    const selectedField = dspResponse.data?.data.find((d) => d.key === id)
    setSelectedDsp(selectedField)
    pubSeats.onChange([])
    seat.onChange()
  }

  const generateDealId = () => {
    if (generateIdLoader) {
      return
    }

    setGenerateIdLoader(true)
    return api.deal
      .generateId()
      .then(
        (res) => {
          if (res.data?.value) {
            dealId.onChange(res.data.value)
          } else {
            toast.error({
              title: 'Unable to generate Deal ID. Please try again.',
            })
          }
        },
        () => {
          toast.error({
            title: 'Unable to generate Deal ID. Please try again.',
          })
        }
      )
      .finally(() => setGenerateIdLoader(false))
  }

  const onMediaPlanChange = (val) => {
    if (!val) {
      form.change('selectedMedia', null)
      return
    }

    let element = mediaPlanResponse.data?.find((el) => el.key === val)

    form.change('selectedMedia', element)
  }

  useEffect(() => {
    if (dspResponse.data) {
      let responseList = dspResponse.data?.data
      setDspList([...responseList])
    }
  }, [dspResponse.data])

  useEffect(() => {
    if (mediaPlanResponse.data) {
      let element = mediaPlanResponse.data.find(
        (el) => el.key === selectedMedia.key
      )
      if (element) {
        setMediaList([...mediaPlanResponse.data])
      } else if (selectedMedia && isNotEmptyObject(selectedMedia)) {
        setMediaList([...mediaPlanResponse.data, selectedMedia])
      } else {
        setMediaList([...mediaPlanResponse.data])
      }
    }
  }, [mediaPlanResponse.data])

  useEffect(() => {
    if (
      initialValues?.selectedMedia &&
      initialValues?.selectedMedia !== selectedMedia
    ) {
      setSelectedMedia(initialValues.selectedMedia)
    }
  }, [initialValues?.selectedMedia])

  const seatOnChange = (e, name) => {
    let rate = e.target.value
      .replace(/[^\d.]/g, '')
      .replace(/\./, 'x')
      .replace(/\./g, '')
      .replace(/x/, '.')
    if (isFloatValue(rate) && name !== 'rate') {
      rate = rate
        .toString()
        .split('.')
        .map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
        .join('.')
    }
    form.change(name, rate)
  }

  const onRefresh = () => {
    mediaPlanResponse.refresh()
    filter.cpmRefresh?.()
  }

  const getDSPList = () => {
    let arr = []
    if (isNotEmptyArray(dspList)) {
      arr = [...dspList]
      if (isNotEmptyObject(selectedDsp)) {
        const index = dspList.findIndex((item) => item.key === selectedDsp.key)
        if (index === -1) {
          let info = { ...selectedDsp }
          if (info.name) {
            info = { ...info, value: info.name }
          }
          arr = [...arr, { ...info }]
        }
      }
    } else {
      let info = { ...selectedDsp }
      if (info.name) {
        info = { ...info, value: info.name }
      }
      arr = [{ ...info }]
    }
    return arr
  }

  const getMediaPlanData = () => {
    if (
      !isNotEmptyArray(mediaList) &&
      !isNotEmptyObject(initialValues.selectedMedia)
    ) {
      return 'No Data Found'
    }
    if (isNotEmptyArray(mediaList)) {
      return mediaList
    }
    return [selectedMedia]
  }

  const disableDealType = () => {
    let bool = false
    if (
      values?.type === DEAL_TYPE.AUCTION.key ||
      formDisable ||
      !user.superLogin
    ) {
      bool = true
    }
    if (
      (user.superLogin && values?.type === DEAL_TYPE.FIXED.key) ||
      (!user.superLogin &&
        values?.dealStatus === DEAL_STATUS.DRAFT.key &&
        values?.type === DEAL_TYPE.FIXED.key)
    ) {
      bool = false
    }
    return bool
  }

  return (
    <Flex
      mt={2}
      gap={3}
      flexDirection={['column', 'column', 'row']}
      alignItems={[null, null, 'flex-start']}
    >
      <Grid columnGap={3} columns={[1, 2]} flexGrow={1}>
        <Box columnSpan={!create ? 1 : 2}>
          <Field label='Title' name='title'>
            <Input disabled={formDisable} placeholder='Enter title' />
          </Field>
        </Box>
        {!create ? (
          <Field
            extra={<LabelTooltip content={getDealTooltip(values.dealStatus)} />}
            label='Status'
            name='dealStatus'
          >
            <SelectField
              name='dealStatus'
              keyField='key'
              textField='name'
              data={DEAL_STATUS.values()}
              disabled
            />
          </Field>
        ) : null}
        <Box position='relative'>
          <Field
            extra={<LabelTooltip content={getDealTooltip('dealId')} />}
            label='Deal ID'
            name='dealId'
          >
            <Input
              disabled={superAdmin || formDisable}
              maxLength={100}
              placeholder='Enter Deal ID'
            />
          </Field>
          {!disableDeal && create ? (
            <Box position='absolute' top={0} right={0}>
              <Tooltip placement='right' title='Use Auto-Generated Deal ID'>
                <Button
                  disabled={generateIdLoader || disableDeal}
                  onClick={generateDealId}
                  size='small'
                  type='link'
                  style={{ fontSize: 12 }}
                >
                  Generate Deal ID
                </Button>
              </Tooltip>
            </Box>
          ) : null}
        </Box>
        <Field
          extra={<LabelTooltip content={getDealTooltip('dealType')} />}
          label='Deal Type'
          name='type'
        >
          <SelectField
            name='type'
            keyField='key'
            textField='name'
            data={DEAL_TYPE.values()}
            disabled={disableDealType()}
          />
        </Field>
        <Field
          extra={<LabelTooltip content={getDealTooltip('dsp')} />}
          label='DSP'
          name='dealDspId'
        >
          <SelectField
            data={getDSPList()}
            disabled={formDisable || disableDeal}
            iconField='icon'
            keyField='key'
            name='dealDspId'
            onChange={selectChange}
            placeholder='Select DSP'
            showSearch
            textField='value'
          />
        </Field>
        <Field
          extra={<LabelTooltip content={getDealTooltip('floorCpm')} />}
          label='Floor CPM (USD)'
          name='rate'
        >
          <Input
            disabled={formDisable}
            min={0}
            placeholder='Enter Floor CPM'
            onChange={(e) => seatOnChange(e, 'rate')}
          />
        </Field>
        {selectedDsp?.avl ? (
          <>
            <Field
              extra={<LabelTooltip content={getDealTooltip('seat')} />}
              label='Seat'
              name='seat'
            >
              <Input disabled={disableDeal} placeholder='Enter seat' />
            </Field>
            <Box />
          </>
        ) : null}
        {values.dealDspId &&
        isNotEmptyObject(selectedDsp) &&
        selectedDsp.showSeat ? (
          <>
            {isNotEmptyArray(selectedDsp.seats) ? (
              <Field
                name='pubSeats'
                label='Seat'
                extra={<LabelTooltip content={getDealTooltip('seat')} />}
              >
                <SelectField
                  data={selectedDsp.seats}
                  disabled={formDisable}
                  keyField='key'
                  mode={
                    !create &&
                    isArray(values.pubSeats) &&
                    values.pubSeats.length > 1
                      ? 'multiple'
                      : undefined
                  }
                  name='pubSeats'
                  placeholder='All Seats Are Allowed'
                  showSearch
                  textField='value'
                />
              </Field>
            ) : (
              <Field
                name='allPubSeats'
                label='Seat'
                extra={<LabelTooltip content={getDealTooltip('seat')} />}
              >
                <SelectField
                  name='allPubSeats'
                  keyField='key'
                  textField='value'
                  placeholder='Select Seats'
                  disabled
                />
              </Field>
            )}
            <Box />
          </>
        ) : null}
        <Field
          name='marginType'
          label='Margin Type'
          extra={<LabelTooltip content={getDealTooltip('marginType')} />}
        >
          <SelectField
            name='marginType'
            keyField='key'
            textField='name'
            placeholder='Select a Margin Type'
            data={MARGIN_TYPE.values()}
            disabled={formDisable}
            allowClear
          />
        </Field>
        <Field
          name='marginValue'
          label={
            values.marginType === MARGIN_TYPE.FLAT.key
              ? 'Margin CPM'
              : 'Margin Percentage'
          }
          extra={
            <LabelTooltip
              content={getDealTooltip(
                values.marginType === MARGIN_TYPE.FLAT.key ? 'cpm' : 'margin'
              )}
            />
          }
        >
          <Input
            onChange={(e) => seatOnChange(e, 'marginValue')}
            placeholder={
              values.marginType === MARGIN_TYPE.FLAT.key
                ? 'Enter flat margin'
                : 'Enter margin in %'
            }
            disabled={formDisable}
            maxLength={100}
          />
        </Field>
        {!libraryId ? (
          <>
            <Box position='relative'>
              <Field
                name='mediaPlans'
                label='Media Plan'
                extra={<LabelTooltip content={getDealTooltip('mediaPlan')} />}
              >
                <SelectField
                  name='mediaPlans'
                  keyField='key'
                  textField='name'
                  data={getMediaPlanData()}
                  onChange={onMediaPlanChange}
                  placeholder='Select Media Plan'
                  loading={mediaPlanResponse.loading}
                  disabled={formDisable}
                  showSearch
                />
              </Field>
              <Flex
                mt={formDisable ? 0 : -1}
                gap={2}
                alignItems='center'
                position='absolute'
                top={0}
                right={0}
              >
                <Link
                  target='_blank'
                  to={`${baseLocation}${values.mediaPlans}`}
                  disabled={!values.mediaPlans}
                  style={{ pointerEvents: values.mediaPlans ? '' : 'none' }}
                >
                  <Box fontSize={0}>Go to Media Plan</Box>
                </Link>
                {!formDisable ? (
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={onRefresh}
                    size='small'
                  />
                ) : null}
                {!formDisable ? (
                  <Link target='_blank' to='/mediaplan/create'>
                    <Tooltip placement='right' title='Create Media Plan'>
                      <Button
                        type='primary'
                        size='small'
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>
                  </Link>
                ) : null}
              </Flex>
            </Box>
            <MediaPlanDataFees
              form={form}
              id={values.mediaPlans}
              setFilter={setFilter}
            />
          </>
        ) : null}
      </Grid>
      {values.commentsEnabled ? (
        <DealCommentsWidget
          dealId={values.id}
          user={user}
          width={360}
          height={contentHeight - 200}
        />
      ) : null}
    </Flex>
  )
}

SettingForm.defaultProps = {
  initialValues: {},
  values: {},
  create: false,
  disableDeal: false,
  setSelectedDsp: () => {},
  selectedDsp: {},
}

SettingForm.propTypes = {
  create: PropTypes.bool,
  initialValues: PropTypes.object,
  values: PropTypes.object,
  superAdmin: PropTypes.bool,
  form: PropTypes.object,
  libraryId: PropTypes.string,
  disableDeal: PropTypes.bool,
  selectedDsp: PropTypes.object,
  setSelectedDsp: PropTypes.func,
  disableForm: PropTypes.bool,
}

const MediaPlanDataFees = ({ id, form, setFilter }) => {
  const cost = useFetch({
    request: id && api.deal.getCostByMediaPlan,
    payload: { id },
  })

  useMemo(() => {
    form.change('mediaplanCPM', cost.data)
    setFilter((prevState) => ({ ...prevState, cpmRefresh: cost.refresh }))
  }, [JSON.stringify(cost)])

  return (
    <FieldControl
      extra={<LabelTooltip content={getDealTooltip('dataFees')} />}
      label='Media Plan Data Fee CPM (USD)'
    >
      <Input
        disabled
        value={cost.data ? Math.round(cost.data * 100) / 100 : 0}
      />
    </FieldControl>
  )
}

MediaPlanDataFees.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}
SettingForm.defaultProps = {
  setIsDealUpdate: () => {},
}
SettingForm.propTypes = {
  setIsDealUpdate: PropTypes.func,
}

export default SettingForm
