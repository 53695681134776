import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { authService } from '../../../context'
import { useUserData } from '../../../hooks'
import { getDefaultRoute } from '../@utils'

const AuthRoute = () => {
  const [user] = useUserData()

  if (!authService.isAuthenticated()) {
    return <Outlet />
  }

  const { path } = getDefaultRoute(user)

  return <Navigate to={path} />
}

export default AuthRoute
