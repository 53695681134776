import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from '@beachfront/ui'

import { formatDate } from '../../../utils'

export const reportColumns = [
  {
    dataIndex: 'name',
    title: 'Name',
    fixed: 'left',
    sorter: true,
    width: 250,
    render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
  },
  {
    dataIndex: 'range',
    title: 'Date Range',
    sorter: true,
    width: 150,
  },
  {
    dataIndex: 'shared',
    title: 'Shared',
    width: 200,
    render: (val) =>
      val === 'Yes' ? (
        <Badge status='success' text='Yes' />
      ) : (
        <Badge status='error' text='No' />
      ),
  },
  {
    dataIndex: 'owner',
    title: 'Owner',
    width: 200,
  },
  {
    dataIndex: 'lastRun',
    title: 'Last Run',
    sorter: true,
    filter: true,
    width: 200,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'createDate',
    title: 'Created',
    sorter: true,
    filter: true,
    width: 200,
    render: (val) => formatDate(val),
  },
]
