import { isNonEmptyString, isNotEmptyArray } from '../../../utils'

export default function validateForm({ values, tab }, checkDesc = false) {
  const errors = {}

  if (values.title) {
    values.title = values.title.trim()
  }

  if (
    checkDesc &&
    (!values.desc || !isNonEmptyString(values.desc)) &&
    !values.icon
  ) {
    errors.desc = 'Required'
    errors.icon = 'Required'
  }

  if (values.desc) {
    values.desc = values.desc.trim()
  }

  if ((!tab || tab === 'settings') && !values.title) {
    errors.title = 'Required'
  }

  if (
    (isNotEmptyArray(values.bundleMedia?.ids) &&
      isNotEmptyArray(values.domainMedia?.ids)) ||
    (isNotEmptyArray(values.bundle) && isNotEmptyArray(values.domain))
  ) {
    errors.domain = { value: 'Please select either Domain or Bundle.' }
  }

  if (
    (!tab || tab === 'targeting') &&
    values.audienceBooleanLogic?.enabled &&
    !values.audienceBooleanLogic.value
  ) {
    errors.audienceBooleanLogic = { value: 'Required' }
  }

  if (
    (!tab || tab === 'targeting') &&
    values.contentBooleanLogic?.enabled &&
    !values.contentBooleanLogic.value
  ) {
    errors.contentBooleanLogic = { value: 'Required' }
  }

  return errors
}
