export const reportDayRanges = [
  { key: 'today', value: 'Today' },
  { key: 'yesterday', value: 'Yesterday' },
  { key: 'last7day', value: 'Last 7 Days' },
  { key: 'last14day', value: 'Last 14 Days' },
  { key: 'last30day', value: 'Last 30 Days' },
  { key: 'month2date', value: 'Month to Date' },
  { key: 'custom', value: 'Custom' },
]

export const reportHourRanges = [
  { key: 'today', value: 'Today' },
  { key: 'yesterday', value: 'Yesterday' },
  { key: 'custom', value: 'Custom' },
]
