import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@beachfront/ui'
import { useDisclosure } from '@beachfront/ui/hooks'

import { isNotEmptyArray } from '../../utils'

import ChangeLogModal from './change-log-modal'

const ChangeLog = ({ data }) => {
  const modal = useDisclosure()

  if (!isNotEmptyArray(data)) {
    return '--'
  }

  return (
    <>
      <Button
        type='link'
        size='small'
        onClick={() => modal.open()}
        style={{ paddingLeft: 0, paddingRight: 0, fontSize: 'inherit' }}
      >
        Show Details
      </Button>
      <ChangeLogModal
        title='Change Log'
        isOpen={modal.isOpen}
        onCancel={() => modal.close()}
        data={data}
      />
    </>
  )
}

ChangeLog.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default ChangeLog
