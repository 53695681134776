import { formatCount } from '../../../utils'

export const attachMediaModalColumns = [
  {
    dataIndex: 'title',
    title: 'Title',
    sorter: true,
    filter: true,
    width: 250,
    render: (val) => val || 'NA',
  },

  {
    dataIndex: 'count',
    title: 'List Size',
    sorter: true,
    filter: true,
    width: 150,
    render: (val) => formatCount(val),
  },
]
