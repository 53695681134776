import React, { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom'
import { Box, Button, Tabs, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { TIMEZONE } from '../../../enums'
import { useFetch } from '../../../hooks'
import {
  isNotEmptyArray,
  getErrorMessage,
  isEmptyString,
  isValidMongoId,
  isString,
  isNumber,
} from '../../../utils'
import {
  getExcludeInitialValue,
  getKeys,
  getOrAndInitialValue,
  getSegmentKeys,
  TargetingForm,
  validateForm,
  SettingForm,
} from '../../media-plan'
import { PageHeader, NetworkError, CommonSpinner } from '../../../components'
import { dealTabs } from '../@constants'

const LibraryDetail = () => {
  const { type, id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)
  const [availsDsp, setAvailsDsp] = useState('')
  const adminDeal = location.pathname.includes('/deal')
  const adminFeatureDeal = location.pathname.includes('/featured')
  const pageName = adminFeatureDeal ? 'featured' : 'library'
  const baseLocation = `/deal/${pageName}/`
  const backUrl = `/deal/${pageName}/`

  const response = useFetch({
    request: adminDeal
      ? api.dspDeal.admin.getDealById
      : api.dspDeal.admin.getMediaById,
    payload: {
      id,
      params: { featured: !!(adminDeal && adminFeatureDeal) },
    },
  })

  const initialValues = useMemo(() => {
    let getData = {}
    if (response.data) {
      getData = {
        ...response.data,
        audienceBooleanLogic: response.data.audienceBooleanLogic ?? {
          enabled: false,
          client: [],
          value: '',
        },
        contentBooleanLogic: response.data.contentBooleanLogic ?? {
          enabled: false,
          client: [],
          value: '',
        },
        mediaType: response.data.mediaType ? [response.data.mediaType] : [],
        status: response.data.status ? 'ENABLE' : 'DISABLE',
        country: response.data.country ? getKeys(response.data.country) : [],
        countryObj: response.data.country ? response.data.country : [],
        genres: isNotEmptyArray(response.data.genres)
          ? getKeys(response.data.genres)
          : [],
        network: isNotEmptyArray(response.data.network)
          ? getKeys(response.data.network)
          : [],
        channel: isNotEmptyArray(response.data.channel)
          ? getKeys(response.data.channel)
          : [],
        rating: isNotEmptyArray(response.data.rating)
          ? getKeys(response.data.rating)
          : [],
        genresObj: isNotEmptyArray(response.data.genres)
          ? response.data.genres
          : [],
        networkObj: isNotEmptyArray(response.data.network)
          ? response.data.network
          : [],
        channelObj: isNotEmptyArray(response.data.channel)
          ? response.data.channel
          : [],
        ratingObj: isNotEmptyArray(response.data.rating)
          ? response.data.rating
          : [],
        iabCategory: response.data.iabCategory
          ? getKeys(response.data.iabCategory)
          : [],
        iabCategoryObj: response.data.iabCategory
          ? response.data.iabCategory
          : [],
        state: response.data.state ? getKeys(response.data.state) : [],
        stateObj: response.data.state ? response.data.state : [],
        buyers: response.data.buyer ? response.data.buyer.key : [],
        publishers: response.data.publishers
          ? getKeys(response.data.publishers)
          : [],
        publishersObj: response.data.publishers ? response.data.publishers : [],
        dma: response.data.dma ? getKeys(response.data.dma, 'dmaCode') : [],
        dmaObj: response.data.dma ? response.data.dma : [],
        excludeCountry: getExcludeInitialValue(response.data.excludeCountry),
        excludeDma: getExcludeInitialValue(response.data.excludeDma),
        excludeState: getExcludeInitialValue(response.data.excludeState),
        excludeiabCat: getExcludeInitialValue(response.data.excludeiabCat),
        excludeNetwork: getExcludeInitialValue(response.data.excludeNetwork),
        excludeChannel: getExcludeInitialValue(response.data.excludeChannel),
        excludeRating: getExcludeInitialValue(response.data.excludeRating),
        excludeGenres: getExcludeInitialValue(response.data.excludeGenres),
        excludeApp: getExcludeInitialValue(response.data.excludeApp),
        includeSegmentAnd: getOrAndInitialValue(
          response.data.includeSegmentAnd
        ),
        excludeSegmentAnd: getOrAndInitialValue(true),
        excludeUserId: getExcludeInitialValue(response.data.excludeUserId),
      }
    }
    return getData
  }, [response.data])

  const onSubmit = (values) => {
    if (
      values.title &&
      (!values.audienceBooleanLogic.enabled ||
        isNotEmptyArray(values?.audienceBooleanLogic.client)) &&
      (!values.contentBooleanLogic.enabled ||
        isNotEmptyArray(values?.contentBooleanLogic.client))
    ) {
      let targetData = {
        platform: values.platform.includes('ALL') ? ['ALL'] : values.platform,
        country: values.country || [],
        dma: [],
        state: [],
        includeLivestream: values.includeLivestream,
        excludeCountry: values.excludeCountry === 'EXCLUDE',
        excludeDma: values.excludeDma === 'EXCLUDE',
        excludeState: values.excludeState === 'EXCLUDE',
        excludeiabCat: values.excludeiabCat === 'EXCLUDE',
        excludeNetwork: values.excludeNetwork === 'EXCLUDE',
        excludeChannel: values.excludeChannel === 'EXCLUDE',
        excludeRating: values.excludeRating === 'EXCLUDE',
        excludeGenres: values.excludeGenres === 'EXCLUDE',
        excludeUserId: values.excludeUserId === 'EXCLUDE',
      }

      if (isNotEmptyArray(values.country) && values.country.includes('US')) {
        if (isNotEmptyArray(values.dma)) {
          targetData['dma'] = values.dma
        }
        if (isNotEmptyArray(values.state)) {
          targetData['state'] = values.state
        }
      }

      if (isNotEmptyArray(values.genres)) {
        targetData['masterListGenres'] = values.genres.filter(
          (item) => isString(item) && isValidMongoId(item)
        )
        targetData['genres'] = values.genres.filter((item) => isNumber(item))
      }
      if (isNotEmptyArray(values.channel)) {
        targetData['channel'] = values.channel
      }
      if (isNotEmptyArray(values.network)) {
        targetData['network'] = values.network
      }
      if (isNotEmptyArray(values.rating)) {
        targetData['rating'] = values.rating
      }
      if (isNotEmptyArray(values.iabCategory)) {
        targetData['iabCategory'] = values.iabCategory
      }

      if (isNotEmptyArray(values.publishers)) {
        targetData['userid'] = values.publishers
      }

      if (values.buyers) {
        targetData['buyerId'] = values.buyers
      }

      let postData = {
        title: values.title,
        audienceBooleanLogic: values.audienceBooleanLogic,
        contentBooleanLogic: values.contentBooleanLogic,
        status: values.status === 'ENABLE',
        mediaType: isNotEmptyArray(values.mediaType)
          ? values.mediaType[0]
          : null,
        targeting: targetData,
        includeSegments: isNotEmptyArray(values.includeSegments)
          ? getSegmentKeys(values.includeSegments)
          : [],
        excludeSegments: isNotEmptyArray(values.excludeSegments)
          ? getSegmentKeys(values.excludeSegments)
          : [],
        includeSegmentAnd: values.includeSegmentAnd === 'AND',
        domainMedia:
          values.domainMedia && isNotEmptyArray(values.domainMedia.ids)
            ? {
                ...values.domainMedia,
                ids: getKeys(values.domainMedia.ids, 'id'),
              }
            : null,
        bundleMedia:
          values.bundleMedia && isNotEmptyArray(values.bundleMedia.ids)
            ? {
                ...values.bundleMedia,
                ids: getKeys(values.bundleMedia.ids, 'id'),
              }
            : null,
        zipMedia: null,
        dmaMedia: null,
      }

      if (adminFeatureDeal) {
        postData = {
          ...postData,
          desc: values.desc,
          icon: values.icon,
          featured: true,
        }
      }

      const request = adminDeal
        ? api.dspDeal.admin.updateDeal
        : api.dspDeal.admin.updateMediaPlan

      return request(id, postData).then(
        (res) => {
          if (res.data?.success) {
            queryClient.invalidateQueries({ queryKey: ['deal-library'] })
            queryClient.invalidateQueries({ queryKey: ['featured-deals'] })
            toast.success({ title: 'Deal updated.' })
            setTimeout(() => response.refresh(), 500)
          } else {
            toast.error({
              title:
                res.data?.msg ||
                res.data.errorDetails ||
                'Unable to update this deal. Please try again.',
            })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
    } else {
      let field = ''
      let tab = ''
      if (
        values.title &&
        values.audienceBooleanLogic.enabled &&
        !isNotEmptyArray(values?.audienceBooleanLogic.client)
      ) {
        field = 'Advance Boolean Logic'
        tab = 'targeting'
      } else if (
        values.title &&
        values.contentBooleanLogic.enabled &&
        !isNotEmptyArray(values?.contentBooleanLogic.client)
      ) {
        field = 'Content Boolean Logic'
        tab = 'targeting'
      }
      toast.error({
        title: `Please fill ${isEmptyString(field) ? 'title' : field} in ${
          isEmptyString(tab) ? 'setting' : tab
        } tab`,
      })
    }
  }

  const onTabChange = (key) => {
    navigate(`${baseLocation}${id}/${key}`)
  }

  if (!type) {
    return <Navigate to={`${baseLocation}${id}/${dealTabs[0].key}`} replace />
  }

  if (response.error) {
    return (
      <NetworkError
        description={response.error || `Deal ${pageName} not found`}
        buttonLabel={`Back to Deal ${pageName} List`}
        onAction={() => navigate(backUrl)}
      />
    )
  }

  const pageTitle = `${
    pageName === 'library' ? 'Deal Library' : 'Featured Deals'
  } - ${type.charAt(0).toUpperCase() + type.slice(1)}`

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={(values) => validateForm({ values, tab: type })}
      >
        {({ initialValues, handleSubmit, submitting, values, dirty }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <PageHeader
              title={pageTitle}
              actions={
                <>
                  <Link to={backUrl}>
                    <Button>Cancel</Button>
                  </Link>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={submitting}
                    disabled={!dirty || submitting}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={response.refresh}
                    icon={<ReloadOutlined />}
                  />
                </>
              }
            />
            <Box mt={3} width={1} height='100%' overflow='auto'>
              <Tabs activeKey={type} onChange={onTabChange} type='card'>
                {dealTabs.map((tabData) => {
                  const FormComponent = getFormComponent(tabData.key)
                  const params = { id }
                  return (
                    <Tabs.TabPane tab={tabData.title} key={tabData.key}>
                      {response.loading ? (
                        <CommonSpinner />
                      ) : (
                        <FormComponent
                          availsDsp={availsDsp}
                          dspDeal={true}
                          showTitle={false}
                          values={values}
                          initialValues={initialValues}
                          id={id}
                          params={params}
                          timezone={timezone}
                          onTimezoneChange={setTimezone}
                          adminFeatureDeal={adminFeatureDeal}
                          onAvailsDspChange={setAvailsDsp}
                        />
                      )}
                    </Tabs.TabPane>
                  )
                })}
              </Tabs>
            </Box>
          </form>
        )}
      </Form>
    </>
  )
}

const getFormComponent = (type) => {
  switch (type) {
    case 'settings':
      return SettingForm
    case 'targeting':
      return TargetingForm
    default:
      return SettingForm
  }
}

export default LibraryDetail
