import { createEnum } from '../utils'

export const SEGMENT_STATUS = createEnum({
  ACTIVE: {
    name: 'Active',
  },
  PENDING: {
    name: 'Pending',
  },
  PROCESSING: {
    name: 'Processing',
  },
  VALIDATION_PENDING: {
    name: 'Validation Pending',
  },
  VALIDATION_PROCESSING: {
    name: 'Validation Processing',
  },
  VALIDATION_FAILED: {
    name: 'Validation Failed',
  },
})
