import { useSessionStorage } from '@beachfront/ui/hooks'

export const useColumnConfig = (initialColumns, storageKey) => {
  const [columnConfig, setColumnConfig] = useSessionStorage(
    storageKey,
    initialColumns
      .filter((col) => col.configurable)
      .map((col, i) => getColumnKey(col, String(i)))
  )

  return [columnConfig, setColumnConfig]
}

const getColumnKey = (column, defaultKey) => {
  if (column.key !== undefined && column.key !== null) {
    return column.key
  }
  if (column.dataIndex) {
    return Array.isArray(column.dataIndex)
      ? column.dataIndex.join('.')
      : column.dataIndex
  }
  return defaultKey
}
