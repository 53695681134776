import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useDashboardCharts = (params) => {
  return useQuery({
    queryKey: ['dashboard-charts', params],
    queryFn: async () => {
      const res = await api.dashboard.chart(params)
      return res.data
    },
  })
}
