export const validateMetaForm = (values) => {
  const errors = {}

  if (!values.metaId) {
    errors.metaId = 'Required'
  }

  if (!values.type) {
    errors.type = 'Required'
  }

  if (!values.smallIcon) {
    errors.smallIcon = 'Required'
  }

  if (!values.bigIcon) {
    errors.bigIcon = 'Required'
  }

  return errors
}
