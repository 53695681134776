import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@beachfront/ui'

import {
  CompanySetting,
  MarginSetting,
  OptionsSetting,
  SegmentSetting,
  ToggleSetting,
} from '.'

const AccountForm = ({
  create,
  account,
  onAccountTypeChange,
  setSegmentProviderList,
}) => {
  return (
    <Grid gap={3}>
      <Box columnSpan={[24, 14]}>
        <CompanySetting account={account} create={create} />
      </Box>
      <Box columnSpan={[24, 10]}>
        <ToggleSetting />
      </Box>
      <Box columnSpan={[24, 14]}>
        <SegmentSetting setSegmentProviderList={setSegmentProviderList} />
      </Box>
      <Box columnSpan={[24, 10]}>
        <MarginSetting />
      </Box>
      <Box columnSpan={24}>
        <OptionsSetting
          account={account}
          create={create}
          onAccountTypeChange={onAccountTypeChange}
        />
      </Box>
    </Grid>
  )
}

AccountForm.defaultProps = {
  create: false,
  account: {},
  dsps: {},
}

AccountForm.propTypes = {
  create: PropTypes.bool,
  account: PropTypes.object,
  setSegmentProviderList: PropTypes.func,
  onAccountTypeChange: PropTypes.func,
}

export default AccountForm
