import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useMediaPlanCost = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['media-plan', 'cost', params],
    queryFn: async () => {
      const {
        audienceBooleanLogic = {},
        includeSegmentAnd = false,
        includeSegments = [],
        excludeSegments = [],
      } = params

      if (!audienceBooleanLogic.enabled) {
        const res = await api.mediaPlan.segmentCost({
          data: [...includeSegments, ...excludeSegments],
          includeSegmentAnd,
        })
        return res.data
      }

      if (!audienceBooleanLogic.value) {
        return 0
      }

      const res = await api.booleanSegment.cost({
        expression: audienceBooleanLogic.value,
      })

      if (res.name === 'AxiosError') {
        throw new Error(res.message)
      }

      if (res.data) {
        return Math.round(res.data * 100) / 100
      }

      return 0
    },
    initialData: 0,
    enabled,
  })
}
