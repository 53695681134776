import { isArray, isString } from './type-util'

const urlRegex = new RegExp(
  /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/
)
const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
const mobileRegex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)
const alphaNumeric = /^[0-9a-zA-Z]+$/

/**
 * Returns whether a value is a valid URL.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isValidUrl(val) {
  return isString(val) && urlRegex.test(val)
}

/**
 * Returns whether a value is a valid Email format.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isValidEmail(val) {
  return isString(val) && emailRegex.test(val)
}

/**
 * Returns whether a value is a valid Email format.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isValidMobile(val) {
  return isString(val) && mobileRegex.test(val)
}

/**
 * Returns whether a value is a valid Alpha Numeric.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isValidAlphaNumeric(val) {
  return isString(val) && alphaNumeric.test(val)
}

/**
 * Returns whether a value is in multiple valid Email format.
 * valid formats: 'abc@xyz.com,xyz.abc.com', 'abc@xyz.com'
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isValidMultipleEmails(val) {
  let isValid = true
  let emailArr = []
  if (isArray(val)) {
    emailArr = [...val]
  }
  if (isString(val)) {
    emailArr = val.split(',')
  }

  emailArr.find((email) => {
    email = email.trim()
    if (!isValidEmail(email)) {
      isValid = false
    }
  })
  return isValid
}

export function isFloatValue(val) {
  return Number(val) && val % 1 !== 0
}

export const isValidMongoId = (str) => /^[0-9a-fA-F]{24}$/.test(str)
