import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Box,
  Button,
  Flex,
  Grid,
  Help,
  Text,
  Heading,
  Modal,
  Paragraph,
  Popover,
  Progress,
  Result,
  useToast,
} from '@beachfront/ui'
import { useField } from '@beachfront/ui/forms'
import { useBreakpoints } from '@beachfront/ui/hooks'
import { QuestionOutlined } from '@beachfront/ui/icons'
import { CSVLink } from 'react-csv'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import {
  getErrorMessage,
  isNotEmptyArray,
  isNotEmptyObject,
} from '../../../utils'
import { UploadS3Input } from '../../../components'
import { appInfoCSVData } from '../@constants'

import { InvalidAppInfoGrid } from '.'

const AppInfoBulkUpload = ({ dealId, isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const breakpoints = useBreakpoints()
  const toast = useToast()
  const { input: uploadValue } = useField('uploadState')
  const [loading, setLoading] = useState(false)
  const [uploadingState, setUploadingState] = useState({ state: 'never' })

  const handleUploadState = (state) => {
    setUploadingState(state)
    uploadValue.onChange(state)
  }

  const handleBulkUpload = (request) => {
    setLoading(true)

    const payload = {
      id: dealId,
      data: uploadingState.data.data,
    }

    return request(payload)
      .then(
        (res) => {
          if (res.data?.success) {
            toast.success({ title: 'App info uploaded.' })
            queryClient.invalidateQueries({
              queryKey: ['app-info-list', { id: dealId }],
            })
            setTimeout(() => onClose?.())
          } else {
            toast.error({
              title:
                res.data?.errorDetails ||
                'Unable to process app info. Please try again.',
            })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = () => {
    if (
      isNotEmptyObject(uploadingState.data.data.create) &&
      isNotEmptyObject(uploadingState.data.data.update)
    ) {
      return Promise.all([
        handleBulkUpload(api.deal.editInfo),
        handleBulkUpload(api.deal.updateInfo),
      ])
    }
    if (isNotEmptyObject(uploadingState.data.data.create)) {
      return handleBulkUpload(api.deal.updateInfo)
    }
    if (isNotEmptyObject(uploadingState.data.data.update)) {
      return handleBulkUpload(api.deal.editInfo)
    }
    toast.error({ title: 'Please upload a valid file.' })
  }

  const helpContent = (
    <Box>
      <Paragraph>
        Accepted values for status: passedIn, override, overrideIfEmpty
      </Paragraph>
      <Paragraph>
        IAB Categories: List of values should be separated by{' '}
        <Text code>|</Text>
      </Paragraph>
    </Box>
  )

  return (
    <Modal
      visible={isOpen}
      width={breakpoints.md ? '50%' : '100%'}
      closable={false}
      okText={
        isNotEmptyObject(uploadingState.data?.data?.create)
          ? 'Create'
          : 'Update'
      }
      cancelText='Close'
      onCancel={onClose}
      centered
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{
        loading,
        style: { display: uploadingState.state !== 'success' && 'none' },
      }}
      onOk={onSubmit}
    >
      <Flex justifyContent='flex-end'>
        {(uploadingState.state === 'never' ||
          uploadingState.state === 'failed') && (
          <Box ml={3} mt={1}>
            <Popover
              trigger='click'
              placement='topRight'
              content={helpContent}
              arrowPointAtCenter
            >
              <Button size='small' shape='circle' icon={<QuestionOutlined />} />
            </Popover>
          </Box>
        )}
      </Flex>
      <Box py={4} textAlign='center'>
        {(uploadingState.state === 'never' ||
          uploadingState.state === 'failed') && (
          <>
            <Heading level={4}>Upload Your CSV File Below</Heading>
            <Flex mb={3} justifyContent='center'>
              <Help status='warning'>
                The maximum number of rows should not exceed 1,000.
              </Help>
            </Flex>
            <Flex gap={2} justifyContent='center'>
              <Button type='link'>
                <CSVLink
                  data={appInfoCSVData}
                  filename='app-info-sample-file.csv'
                  uFEFF={false}
                >
                  Download Sample File
                </CSVLink>
              </Button>
              <UploadS3Input
                name='fileUrl'
                uploadType='csv'
                uploadFolder='deals/appInfoOverride/upload'
                uploadState={handleUploadState}
                progressHide={true}
                uploadAPI={api.deal.appInfoBulkUpload}
                params={{ key: dealId }}
              />
            </Flex>
            {uploadingState.state === 'failed' && (
              <Flex mt={4} justifyContent='center'>
                <Help status='error'>
                  Your file failed to upload. Please try again.
                </Help>
              </Flex>
            )}
          </>
        )}
        {uploadingState.state === 'uploading' && (
          <>
            <Heading level={4}>File Uploading In Progress</Heading>
            <Flex mt={3} justifyContent='center'>
              <Box>
                <Progress.Circle value={uploadingState.processPer || 0} />
              </Box>
            </Flex>
          </>
        )}
        {uploadingState.state === 'success' &&
          !isNotEmptyArray(uploadingState.data?.validationMessages) && (
            <>
              <Result
                status='success'
                title='Upload Successful'
                description={`Your file is successfully validated. Click on ${
                  isNotEmptyObject(uploadingState.data?.data.create)
                    ? 'Create'
                    : 'Upload'
                } to add new App Info.`}
              />
              <Flex mt={3} justifyContent='center'>
                <Help>
                  Total Valid App Info Override - {uploadingState.data?.valid}
                </Help>
              </Flex>
            </>
          )}
        {(uploadingState.state === 'error' ||
          (uploadingState.data?.success &&
            isNotEmptyArray(uploadingState.data?.validationMessages))) && (
          <>
            {uploadingState.state === 'error' && (
              <Help status='error' fontSize={3}>
                Please fix the invalid data and upload the CSV file again.
              </Help>
            )}
            <Flex
              my={4}
              p={3}
              gap={3}
              flexDirection={['column', 'column', 'row']}
              justifyContent='space-evenly'
              alignItems='center'
              borderRadius='base'
              bg='gray.1'
            >
              <Box>
                <Heading level={4}>Upload Another CSV File To Update</Heading>
                <Help status='warning'>
                  The maximum number of rows should not exceed 1,000.
                </Help>
              </Box>
              <Box>
                <UploadS3Input
                  name='fileUrl'
                  uploadType='csv'
                  uploadFolder='deals/appInfoOverride/upload'
                  uploadState={handleUploadState}
                  progressHide={true}
                  uploadAPI={api.deal.appInfoBulkUpload}
                  params={{ key: dealId }}
                />
              </Box>
            </Flex>
            <Grid columns={6} gap={3} mt={3}>
              <Box columnSpan={2}>
                <Help>Uploaded - {uploadingState.data?.total}</Help>
              </Box>
              <Box columnSpan={2}>
                <Help>Valid - {uploadingState.data?.valid}</Help>
              </Box>
              <Box columnSpan={2}>
                <Help status='error'>
                  Invalid - {uploadingState.data?.invalid}
                </Help>
              </Box>
              <Box columnSpan={uploadingState.data?.duplicates > 0 ? 2 : 3}>
                <Help>Created - {uploadingState.data?.create}</Help>
              </Box>
              <Box columnSpan={uploadingState.data?.duplicates > 0 ? 2 : 3}>
                <Help>Updated - {uploadingState.data?.update}</Help>
              </Box>
              {uploadingState.data?.duplicates > 0 && (
                <Box columnSpan={2}>
                  <Help status='warning'>
                    Duplicates - {uploadingState.data?.duplicates}
                  </Help>
                </Box>
              )}
            </Grid>
            {uploadingState.state === 'success' && (
              <Help status='warning' mt={4}>
                If you want to upload the valid entries of CSV file click on the{' '}
                {isNotEmptyObject(uploadingState.data?.data?.create)
                  ? 'Create'
                  : 'Update'}{' '}
                button or re-upload a valid CSV file.
              </Help>
            )}
          </>
        )}
      </Box>
      {isNotEmptyArray(uploadingState.data?.validationMessages) && (
        <InvalidAppInfoGrid data={uploadingState.data.validationMessages} />
      )}
    </Modal>
  )
}

AppInfoBulkUpload.propTypes = {
  dealId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default AppInfoBulkUpload
