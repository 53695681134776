import React from 'react'
import { Filter } from '@beachfront/ui'
import { TagsOutlined, SnippetsOutlined } from '@beachfront/ui/icons'

import {
  ContainsFilterPanel,
  formatStringFilterTag,
} from '../../../components/filter'

const AppInfoFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='overridedBundle'
        label='Existing Bundle'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='bundle'
        label='Bundle'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<SnippetsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
    </Filter>
  )
}

export default AppInfoFilter
