import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter, DatePicker, Select, Input } from '@beachfront/ui'

import { DATE_RANGES } from '../../../enums'

const DateFilterPanel = ({
  focusRef,
  value,
  setValue,
  operator,
  setOperator,
  setDescriptor,
  disabledDate,
  getPopupContainer,
  ...rest
}) => {
  const onOperatorChange = (op) => {
    if (op === Filter.Operator.Date.BETWEEN.key) {
      setDescriptor({
        operator: op,
        value: [moment(), moment()],
      })
    } else if (operator === Filter.Operator.Date.BETWEEN.key) {
      setDescriptor({
        operator: op,
        value: moment(),
      })
    } else {
      setOperator(op)
    }
  }

  const onValueChange = (val) => {
    if (Array.isArray(val)) {
      setValue([val[0].startOf('day'), val[1].endOf('day')])
    } else {
      setValue(val.startOf('day'))
    }
  }

  return (
    <Input.Group compact>
      <Select
        style={{ width: 150 }}
        value={operator}
        onChange={onOperatorChange}
      >
        <Select.Option value={Filter.Operator.Any.EQUALS.key}>
          Equals
        </Select.Option>
        <Select.Option value={Filter.Operator.Date.BEFORE.key}>
          Before
        </Select.Option>
        <Select.Option value={Filter.Operator.Date.AFTER.key}>
          After
        </Select.Option>
        <Select.Option value={Filter.Operator.Date.BETWEEN.key}>
          Between
        </Select.Option>
      </Select>
      {operator === Filter.Operator.Date.BETWEEN.key ? (
        <DatePicker.RangePicker
          {...rest}
          ref={focusRef}
          style={{ width: 260 }}
          value={value}
          onChange={onValueChange}
          ranges={DATE_RANGES}
          disabledDate={disabledDate}
          getPopupContainer={getPopupContainer}
        />
      ) : (
        <DatePicker
          {...rest}
          ref={focusRef}
          style={{ width: 150 }}
          value={value}
          onChange={onValueChange}
          disabledDate={disabledDate}
          getPopupContainer={getPopupContainer}
        />
      )}
    </Input.Group>
  )
}

DateFilterPanel.defaultProps = {
  format: 'MM/DD/YYYY',
  allowClear: false,
  disabledDate: (date) => date.isAfter(moment()),
  getPopupContainer: (el) => el.closest('.page-header') ?? document.body,
}

DateFilterPanel.propTypes = {
  focusRef: PropTypes.object,
  value: PropTypes.any,
  setValue: PropTypes.func,
  operator: PropTypes.any,
  setOperator: PropTypes.func,
  setDescriptor: PropTypes.func,
  disabledDate: PropTypes.func,
  getPopupContainer: PropTypes.func,
}

export default DateFilterPanel
