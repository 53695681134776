import React, { useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Flex, Box, Button, Modal, useToast } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../client-api'
import { getErrorMessage, plural } from '../../utils'
import { localFilterKey } from '../../components/filter'
import { useContentSize, useFilters, useInfiniteTable } from '../../hooks'
import {
  PageHeader,
  PageSearch,
  InfiniteTable,
  NetworkError,
} from '../../components'

import { mediaPlanColumns } from './@constants'
import { MediaPlanFilter } from './@components'

const MediaPlans = ({ admin }) => {
  const { contentHeight } = useContentSize()
  const navigate = useNavigate()
  const toast = useToast()
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const filterKey = admin
    ? localFilterKey.ADMIN_MEDIA_PLANS
    : localFilterKey.MEDIA_PLANS

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(filterKey, {
    schema: {
      createDate: 'date',
      updateDate: 'date',
    },
  })

  const params = { q: searchQuery }

  if (admin) {
    params.admin = true
  }

  const table = useInfiniteTable({
    queryKey: ['media-plans'],
    queryFn: api.mediaPlan.list,
    filters: filters.populated,
    params,
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 260,
  }

  const rowSelection = !admin
    ? {
        selectedRowKeys: selectedRowKeys,
        onChange: setSelectedRowKeys,
        columnWidth: 40,
        preserveSelectedRowKeys: true,
      }
    : null

  const columns = useMemo(() => {
    let cols = [...mediaPlanColumns]
    if (admin) {
      cols.splice(1, 0, {
        dataIndex: 'company',
        title: 'Company',
        sorter: true,
        width: 180,
      })
    }
    return cols
  }, [admin])

  const clearSelectedRowKeys = () => {
    setSelectedRowKeys([])
  }

  const onDuplicate = () => {
    Modal.confirm({
      title: 'Duplicate Media Plans',
      content: plural`Are you sure you want to duplicate ${selectedRowKeys.length} media plan[|s]?`,
      maskClosable: true,
      onOk() {
        return api.mediaPlan.duplicate({ data: selectedRowKeys }).then(
          (res) => {
            if (res.data?.success) {
              setSelectedRowKeys([])
              toast.success({ title: 'Media plans duplicated.' })
              navigate(`/mediaplan/${res.data.value}/targeting`)
            } else {
              toast.error({ title: res.data?.msg })
            }
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
          }
        )
      },
    })
  }

  const headerActions = (
    <>
      <PageSearch
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search Media Plans'
      />
      <Button onClick={() => table.refetch()} icon={<ReloadOutlined />} />
      {!admin ? (
        <Button type='primary' onClick={() => navigate('/mediaplan/create')}>
          Create Media Plan
        </Button>
      ) : null}
    </>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar
      py={2}
      height={selectedRowKeys.length ? 48 : undefined}
    >
      <Flex justifyContent='space-between'>
        <MediaPlanFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
      <InfiniteTable.BatchActions
        count={selectedRowKeys.length}
        onCancel={clearSelectedRowKeys}
      >
        <InfiniteTable.ActionButton onClick={onDuplicate}>
          Duplicate
        </InfiniteTable.ActionButton>
      </InfiniteTable.BatchActions>
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title='Media Plans' actions={headerActions} />
      <Box mt={2}>
        {!table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <InfiniteTable
            rowKey='id'
            emptyText='No Media Plans Found'
            columns={columns}
            toolbar={toolbar}
            scroll={scroll}
            rowSelection={rowSelection}
            filters={filters.populated}
            onFilter={(key) => filterRef.current?.addFilter(key)}
            {...table.props}
          />
        )}
      </Box>
    </>
  )
}

MediaPlans.propTypes = {
  admin: PropTypes.bool,
}

export default MediaPlans
