import React from 'react'
import PropTypes from 'prop-types'
import { Box, Accordion, Help } from '@beachfront/ui'

import { api } from '../../../../client-api'
import { useUserData } from '../../../../hooks'
import { VirtualSelectField, MediaListField, IncludeExcludeField } from '..'
import { isNotEmptyArray } from '../../../../utils'

import AppWebPreviewTags from './app-web-preview-tags'

const AppWebPanel = ({
  expandKeys,
  disabled,
  update,
  adminUpdate,
  values,
  adminCreate,
  initialValues,
  params,
  id,
  isPmp,
  getPopupContainer,
}) => {
  const [user] = useUserData()

  const extra = (
    <AppWebPreviewTags
      visible={!expandKeys.includes('appWeb')}
      values={values}
    />
  )

  const hasBundleAndDomain = (vals) => {
    return (
      ((update || adminUpdate) &&
        isNotEmptyArray(vals.bundleMedia?.ids) &&
        isNotEmptyArray(vals.domainMedia?.ids)) ||
      ((adminUpdate || update) &&
        isNotEmptyArray(vals.bundle) &&
        isNotEmptyArray(vals.domain))
    )
  }

  const handleBundleDomainDisability = (code) => {
    if (hasBundleAndDomain(initialValues)) {
      return false
    }
    if ((isPmp && code === 'bundle') || code === 'domain') {
      return code === 'bundle'
        ? isNotEmptyArray(values.domain)
        : isNotEmptyArray(values.bundle)
    }
    if (code === 'bundleMedia') {
      return values.domainMedia
        ? isNotEmptyArray(values.domainMedia.ids)
        : false
    }
    return values.bundleMedia ? isNotEmptyArray(values.bundleMedia.ids) : false
  }

  return (
    <Accordion.Panel value='appWeb' header='App / Web' extra={extra}>
      {isPmp &&
      !adminCreate &&
      (isNotEmptyArray(initialValues.domain) ||
        isNotEmptyArray(initialValues.bundle)) ? (
        <Box position='relative'>
          {!user.mediaPlanExclude ? (
            <Box
              position='absolute'
              height='32px'
              right='-7px'
              top='-7px'
              className='customize-radio'
            >
              <IncludeExcludeField
                name='excludeBundle'
                disabled={disabled || handleBundleDomainDisability('bundle')}
              />
            </Box>
          ) : null}

          <VirtualSelectField
            mode='multiple'
            allowClear
            request={api.common.search}
            apiParams={{ ...params, dim: 'bundle', report: false }}
            pagination={true}
            detail={id}
            values={values}
            isMediaPresent={handleBundleDomainDisability('bundle')}
            disabled={disabled || handleBundleDomainDisability('bundle')}
            initialValues={initialValues}
            getPopupContainer={getPopupContainer}
            emptyMsg='No Bundles Available'
            form={{
              name: 'bundle',
              textField: 'name',
              keyField: 'key',
              label: 'Bundle',
              placeholder: 'Select Bundle',
              selectionName: 'bundleObj',
            }}
          />
        </Box>
      ) : (
        <Box>
          <MediaListField
            values={values}
            initialValues={initialValues}
            isMediaPresent={handleBundleDomainDisability('bundleMedia')}
            disabled={disabled || handleBundleDomainDisability('bundleMedia')}
            data={{
              fieldName: 'bundleMedia',
              key: 'bundle',
              label: 'Bundle',
            }}
          />
        </Box>
      )}
      {isPmp &&
      !adminCreate &&
      (isNotEmptyArray(initialValues.domain) ||
        isNotEmptyArray(initialValues.bundle)) ? (
        <Box position='relative'>
          {!user.mediaPlanExclude ? (
            <Box
              position='absolute'
              height='32px'
              right='-7px'
              top='-7px'
              className='customize-radio'
            >
              <IncludeExcludeField
                name='excludeDomain'
                disabled={disabled || handleBundleDomainDisability('domain')}
              />
            </Box>
          ) : null}
          <VirtualSelectField
            mode='multiple'
            allowClear
            request={api.common.search}
            apiParams={{ ...params, dim: 'domain', report: false }}
            pagination={true}
            detail={id}
            values={values}
            isMediaPresent={handleBundleDomainDisability('domain')}
            disabled={disabled || handleBundleDomainDisability('domain')}
            initialValues={initialValues}
            getPopupContainer={getPopupContainer}
            emptyMsg='No Domains Available'
            form={{
              name: 'domain',
              textField: 'name',
              keyField: 'key',
              label: 'Domain',
              placeholder: 'Select Domain',
              selectionName: 'domainObj',
            }}
          />
        </Box>
      ) : (
        <Box>
          <MediaListField
            values={values}
            initialValues={initialValues}
            isMediaPresent={handleBundleDomainDisability('domainMedia')}
            disabled={disabled || handleBundleDomainDisability('domainMedia')}
            data={{
              fieldName: 'domainMedia',
              key: 'domain',
              label: 'Domain',
            }}
          />
        </Box>
      )}
      {hasBundleAndDomain(values) ? (
        <Help status='error' mb={2}>
          Please select either Domain or Bundle.
        </Help>
      ) : null}
    </Accordion.Panel>
  )
}

AppWebPanel.defaultProps = {
  expandKeys: [],
  values: {},
  update: false,
  adminUpdate: false,
  initialValues: {},
  params: {},
}

AppWebPanel.propTypes = {
  expandKeys: PropTypes.array,
  disabled: PropTypes.bool,
  update: PropTypes.bool,
  adminUpdate: PropTypes.bool,
  values: PropTypes.object,
  adminCreate: PropTypes.bool,
  initialValues: PropTypes.object,
  params: PropTypes.object,
  id: PropTypes.string,
  isPmp: PropTypes.bool,
  getPopupContainer: PropTypes.func,
}

export default AppWebPanel
