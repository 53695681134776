import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Box, NavMenu, Tooltip, Button } from '@beachfront/ui'
import styled, { keyframes } from 'styled-components'
import { MoonOutlined, SunOutlined } from '@beachfront/ui/icons'

import { isNotEmptyArray } from '../../../utils'
import { useUserData } from '../../../hooks'
import { IS_DEALS_ENGINE } from '../../../constants'
import { useThemeContext } from '../../../context'

import { Profile, AccountSwitcher } from '.'

const rotate = keyframes`
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
`

const ThemeButton = styled(Button)`
  &.ant-btn {
    font-size: 18px;
    color: ${(props) => props.theme.colors.dark.text};

    &:hover,
    &:active {
      color: ${(props) => props.theme.colors.dark.hover};
      background: ${(props) => props.theme.colors.alpha.white[2]};
    }
  }

  svg {
    animation: ${rotate} 0.3s linear;
  }
`

const TopNavBar = () => {
  const [user] = useUserData()
  const { baseTheme, setBaseTheme } = useThemeContext()

  const selectedKeys = ['2']
  const menuItems = [
    {
      key: '1',
      title: 'Ad Server',
      href: 'https://platform.beachfront.io',
    },
    {
      key: '2',
      title: 'Deals',
      href: '/',
    },
  ]

  const renderLink = ({ href, label }) => {
    return href?.startsWith('/') ? (
      <Link to={href}>{label}</Link>
    ) : (
      <a href={href}>{label}</a>
    )
  }

  const showAccountSwitcher = () => {
    if (IS_DEALS_ENGINE) {
      return false
    }
    if (user?.superLogin) {
      return true
    }
    return isNotEmptyArray(user?.access) && user?.access?.length > 1
  }

  const handleToggleTheme = () => {
    setBaseTheme(baseTheme === 'light' ? 'dark' : 'light')
  }

  return (
    <Flex
      px={3}
      width={1}
      justifyContent='space-between'
      alignItems='center'
      bg='dark.bg'
    >
      <Box>
        {IS_DEALS_ENGINE ? (
          <NavMenu
            primary
            orientation='horizontal'
            variant='dark'
            bg='transparent'
            selectedKeys={selectedKeys}
            renderLink={renderLink}
            itemHeight={32}
            items={menuItems}
          />
        ) : null}
      </Box>
      <Flex mr={3} gap={3} alignItems='center' color='dark.text'>
        <Tooltip
          title={baseTheme === 'light' ? 'Dark Mode' : 'Light Mode'}
          placement='bottom'
          getPopupContainer={(el) => el.parentNode}
        >
          <ThemeButton
            type='text'
            onClick={handleToggleTheme}
            icon={baseTheme === 'light' ? <MoonOutlined /> : <SunOutlined />}
          />
        </Tooltip>
        {showAccountSwitcher() ? <AccountSwitcher /> : null}
        <Profile />
      </Flex>
    </Flex>
  )
}

export default TopNavBar
