import React, { useState } from 'react'
import {
  Box,
  Flex,
  Grid,
  Heading,
  Help,
  Result,
  Progress,
  Button,
} from '@beachfront/ui'
import { useFormState, useField, FieldError } from '@beachfront/ui/forms'
import { useUpdateEffect } from '@beachfront/ui/hooks'
import { CSVLink } from 'react-csv'

import { api } from '../../../client-api'
import { MEDIA_LIST_TYPE } from '../../../enums'
import { isNotEmptyArray } from '../../../utils'
import { UploadS3Input } from '../../../components'
import { csvSampleData } from '../@constants'

import { InvalidMediaTable } from '.'

const MediaListUploadForm = () => {
  const { values } = useFormState()
  const { input: uploadValue } = useField('uploadState')
  const [uploadingState, setUploadingState] = useState({ state: 'never' })

  const mediaType =
    values.mediaType === MEDIA_LIST_TYPE.METRO_CODE.key
      ? 'dma'
      : values.mediaType

  const mediaTypeName =
    MEDIA_LIST_TYPE.fromKey(values.mediaType)?.name ?? values.mediaType

  useUpdateEffect(() => {
    setUploadingState({ state: 'never' })
    uploadValue.onChange({ state: 'never' })
  }, [values.mediaType])

  const handleUploadState = (state) => {
    setUploadingState(state)
    uploadValue.onChange(state)
  }

  return (
    <Flex height='100%'>
      <Box m='auto' width='100%' textAlign='center'>
        {(uploadingState.state === 'never' ||
          uploadingState.state === 'failed') && (
          <>
            <Heading level={4}>Upload Your CSV File Below</Heading>
            <Flex mb={3} justifyContent='center'>
              <Help status='warning'>
                The maximum file size should not exceed 5 MB.
              </Help>
            </Flex>
            <Flex gap={2} justifyContent='center'>
              <Button type='link'>
                <CSVLink
                  data={csvSampleData[values.mediaType] ?? []}
                  filename={values.mediaType + '-sample-file.csv'}
                  uFEFF={false}
                >
                  Download Sample File
                </CSVLink>
              </Button>
              <UploadS3Input
                name='fileUrl'
                uploadType='csv'
                uploadFolder='deals/medialist/upload'
                uploadState={handleUploadState}
                progressHide={true}
                uploadAPI={api.mediaList.uploadFile}
                params={{ key: mediaType }}
              />
            </Flex>
            <Flex justifyContent='center'>
              <FieldError mt={4} name='file' />
            </Flex>
            {uploadingState.state === 'failed' && (
              <Flex mt={4} justifyContent='center'>
                <Help status='error'>
                  {uploadingState.ed ||
                    'Your file failed to upload. Please try again.'}
                </Help>
              </Flex>
            )}
          </>
        )}
        {uploadingState.state === 'uploading' && (
          <>
            <Heading level={4}>File Uploading In Progress</Heading>
            <Flex mt={3} justifyContent='center'>
              <Box>
                <Progress.Circle value={uploadingState.p || 0} />
              </Box>
            </Flex>
          </>
        )}
        {uploadingState.state === 'success' && (
          <>
            <Result
              status='success'
              title='Upload Successful'
              description={`Your ${mediaTypeName}s are successfully validated.`}
            />
            {uploadingState.data?.total ? (
              <Flex mt={3} justifyContent='center'>
                <Help>
                  Total Valid {mediaTypeName}s - {uploadingState.data?.total}
                </Help>
              </Flex>
            ) : (
              <Box mt={3}>
                <UploadS3Input
                  name='fileUrl'
                  uploadType='csv'
                  uploadFolder='deals/medialist/upload'
                  uploadState={handleUploadState}
                  progressHide={true}
                  uploadAPI={api.mediaList.uploadFile}
                  params={{ key: mediaType }}
                />
              </Box>
            )}
            <Flex justifyContent='center'>
              <FieldError mt={4} name='file' showError />
            </Flex>
          </>
        )}
        {uploadingState.state === 'error' && (
          <>
            <Help status='error' fontSize={3}>
              Please fix the invalid {mediaTypeName}s and upload the CSV file
              again.
            </Help>
            <Grid columns={6} gap={3} mt={3}>
              <Box columnSpan={2}>
                <Help>Uploaded - {uploadingState.data?.total}</Help>
              </Box>
              <Box columnSpan={2}>
                <Help>Valid - {uploadingState.data?.valid}</Help>
              </Box>
              <Box columnSpan={2}>
                <Help status='error'>
                  Invalid - {uploadingState.data?.invalid}
                </Help>
              </Box>
            </Grid>
            <Flex
              my={3}
              p={3}
              gap={3}
              flexDirection={['column', 'column', 'row']}
              justifyContent='space-evenly'
              alignItems='center'
              borderRadius='base'
              bg='gray.1'
            >
              <Box>
                <Heading level={4}>Upload Another CSV File To Update</Heading>
                <Help status='warning'>
                  The maximum file size should not exceed 5 MB.
                </Help>
              </Box>
              <Box>
                <UploadS3Input
                  name='fileUrl'
                  uploadType='csv'
                  uploadFolder='deals/medialist/upload'
                  uploadState={handleUploadState}
                  progressHide={true}
                  uploadAPI={api.mediaList.uploadFile}
                  params={{ key: mediaType }}
                />
              </Box>
            </Flex>
            {isNotEmptyArray(uploadingState.data?.data) && (
              <InvalidMediaTable
                data={uploadingState.data.data}
                mediaType={values.mediaType}
              />
            )}
          </>
        )}
      </Box>
    </Flex>
  )
}

export default MediaListUploadForm
