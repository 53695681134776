/**
 * Returns whether a value is null.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isNull(val) {
  return val === null
}

/**
 * Returns whether a value is defined.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isDefined(val) {
  return val !== undefined
}

/**
 * Returns whether a value is undefined.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isUndefined(val) {
  return val === undefined
}

/**
 * Returns whether a value is an object.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isObject(val) {
  return typeof val === 'object' && val !== null
}

/**
 * Returns whether a value is a function.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isFunction(val) {
  return typeof val === 'function'
}

/**
 * Returns whether a value is a number.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isNumber(val) {
  return typeof val === 'number'
}

/**
 * Returns whether a value is a boolean.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isBoolean(val) {
  return typeof val === 'boolean'
}

/**
 * Returns whether a value is a string.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isString(val) {
  return typeof val === 'string'
}

/**
 * Returns whether a value is an empty string.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isEmptyString(val) {
  return isString(val) && val.length === 0
}

/**
 * Returns whether a value is a non-empty string.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isNonEmptyString(val) {
  return isString(val) && val.length !== 0
}

/**
 * Returns whether a value is an array.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isArray(val) {
  return Array.isArray(val)
}

/**
 * Creates an array with a given value as the only element, unless the value is an array.
 *
 * @param   {*} val
 * @returns {Array}
 */
export function makeArray(val) {
  return isArray(val) ? val : [val]
}

/**
 * Returns whether a value is an array with at least one element.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isNotEmptyArray(val) {
  return isArray(val) && val.length !== 0
}

/**
 * Returns whether a value is an object with at least one own property.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isNotEmptyObject(val) {
  return isObject(val) && isNotEmptyArray(Object.keys(val))
}
