import { api } from '../../client-api'
import { useServerTable } from '../tables/use-server-table'

export const useDsps = ({ filters } = {}) => {
  return useServerTable({
    queryKey: 'admin-dsps',
    queryFn: api.admin.dsp.getAll,
    filters: filters,
    schema: {
      data: 'data',
      total: 'total',
      fields: {
        createDate: 'date',
        lastUpdate: 'date',
      },
    },
    defaultPageSize: 50,
  })
}
