import React from 'react'
import {
  Flex,
  Grid,
  Button,
  Alert,
  FieldControl,
  InputNumber,
  Select,
} from '@beachfront/ui'
import { Form, Field } from '@beachfront/ui/forms'
import PropTypes from 'prop-types'

import { IS_DEALS_ENGINE } from '../../../constants'
import { MARGIN_TYPE } from '../../../enums'
import { LabelTooltip } from '../../../components'

const TargetingCpmExplorer = ({
  minCpm,
  maxCpm,
  marginType,
  marginValue,
  dealFloor,
  onMarginTypeChange,
  onMarginValueChange,
  onDealFloorChange,
}) => {
  const initialValues = {
    marginType,
    marginValue,
    dealFloor,
  }

  const isDefault =
    marginType === MARGIN_TYPE.FLAT.key && marginValue === 0 && dealFloor === 0

  const onSubmit = (values, form) => {
    const payload = {
      ...values,
      marginValue: values.marginValue || 0,
      dealFloor: values.dealFloor || 0,
    }
    form.initialize(payload)
    onMarginTypeChange?.(payload.marginType)
    onMarginValueChange?.(payload.marginValue)
    onDealFloorChange?.(payload.dealFloor)
  }

  const onReset = (form) => {
    form.reset()
    onMarginTypeChange?.(MARGIN_TYPE.FLAT.key)
    onMarginValueChange?.(0)
    onDealFloorChange?.(0)
  }

  const infoMessage = (
    <>
      This tool allows pricing adjustments to be applied for a more accurate
      request CPM estimation based on Deal pricing conditions{' '}
      {IS_DEALS_ENGINE ? '(Deal Floor)' : '(Partner Margin, Deal Floor)'}. It is
      intended for price research purposes only: the values provided in this tab
      do not impact targeting and are not saved in Media Plan.
    </>
  )

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, form, values, dirty }) => (
        <>
          <Alert mb={3} type='info' message={infoMessage} showIcon closable />
          <FieldControl
            label='Price Range of Requests Meeting Targeting Criteria'
            extra={
              <LabelTooltip
                content={
                  IS_DEALS_ENGINE
                    ? 'Prices shown correspond to minimum CPM requested by publisher(s), account for Audience Segments fee (if applicable), and are not affected by the Estimated Deal Floor value.'
                    : 'Prices shown account for Partner margin and Audience Segments fee (if applicable) and are not affected by the Estimated Deal Floor value.'
                }
                getPopupContainer={(el) => el.parentNode}
              />
            }
          >
            <Flex gap={2} width={1}>
              <InputNumber
                value={minCpm ?? 0}
                prefix='$'
                addonBefore='Min'
                precision={2}
                disabled
              />
              <InputNumber
                value={maxCpm ?? 0}
                prefix='$'
                addonBefore='Max'
                precision={2}
                disabled
              />
            </Flex>
          </FieldControl>
          <Grid columns={[1, 2]} columnGap={2}>
            {!IS_DEALS_ENGINE ? (
              <>
                <Field name='marginType' label='Partner Margin Type'>
                  <Select
                    options={MARGIN_TYPE.values().map(({ key, name }) => ({
                      value: key,
                      label: name,
                    }))}
                    onChange={() => form.change('marginValue', 0)}
                    getPopupContainer={(el) => el.parentNode}
                  />
                </Field>
                <Field
                  name='marginValue'
                  label={
                    values.marginType === MARGIN_TYPE.FLAT.key
                      ? 'Partner Margin CPM'
                      : 'Partner Margin Percentage'
                  }
                >
                  {values.marginType === MARGIN_TYPE.FLAT.key ? (
                    <InputNumber
                      prefix='$'
                      precision={2}
                      step={0.01}
                      min={0}
                      max={99.99}
                    />
                  ) : (
                    <InputNumber suffix='%' step={1} min={0} max={99.99} />
                  )}
                </Field>
              </>
            ) : null}
            <Field
              name='dealFloor'
              label='Estimated Deal Floor'
              extra={
                <LabelTooltip
                  content='CPM of Requests that are less than the Estimated Deal Floor will be adjusted to the Estimated Deal Floor value.'
                  getPopupContainer={(el) => el.parentNode}
                />
              }
            >
              <InputNumber
                prefix='$'
                precision={2}
                step={0.01}
                min={0}
                max={99.99}
              />
            </Field>
          </Grid>
          <Flex gap={2} alignItems='center'>
            <Button type='primary' disabled={!dirty} onClick={handleSubmit}>
              Apply
            </Button>
            <Button
              disabled={!dirty && isDefault}
              onClick={() => onReset(form)}
            >
              Reset
            </Button>
          </Flex>
        </>
      )}
    </Form>
  )
}

TargetingCpmExplorer.propTypes = {
  minCpm: PropTypes.number,
  maxCpm: PropTypes.number,
  marginType: PropTypes.string,
  marginValue: PropTypes.number,
  dealFloor: PropTypes.number,
  onMarginTypeChange: PropTypes.func,
  onMarginValueChange: PropTypes.func,
  onDealFloorChange: PropTypes.func,
}

export default TargetingCpmExplorer
