import http from '../http'

const ENDPOINT = 'reportingapi/'

export default {
  getList() {
    return http.get(`${ENDPOINT}all`)
  },
  createApiKey() {
    return http.get(`${ENDPOINT}create`)
  },
  saveApiKey(data) {
    return http.post(`${ENDPOINT}save`, data)
  },
  deleteApiKey(params) {
    return http.get(`${ENDPOINT}delete/${params.id}`)
  },
}
