import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@beachfront/ui'

import { SelectField } from '../../form-fields'

const SelectFilterPanel = ({ value, setValue, dataSource, label, ...rest }) => {
  return (
    <Box width={300}>
      <SelectField
        value={value}
        onChange={setValue}
        data={dataSource ?? []}
        keyField='key'
        textField='value'
        placeholder={`Select ${label}`}
        emptyMsg={`No ${label} Available`}
        showSearch
        {...rest}
      />
    </Box>
  )
}

SelectFilterPanel.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  dataSource: PropTypes.array,
  label: PropTypes.string,
}

export default SelectFilterPanel
