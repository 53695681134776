import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Flex,
  Button,
  Popconfirm,
  Switch,
  Badge,
  useToast,
} from '@beachfront/ui'
import { DeleteOutlined } from '@beachfront/ui/icons'
import { useTableQuery, useDisclosure } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { ACCOUNT_STATUS, ACCOUNT_TYPE } from '../../../enums'
import { getErrorMessage } from '../../../utils'
import { NetworkError, CommonSpinner } from '../../../components'

import { GeneralSetting, AccountSetting } from '.'

const UserDetail = ({ admin, response, accountAccess, setAccountAccess }) => {
  const toast = useToast()
  const accountModal = useDisclosure()
  const [accounts, setAccounts] = useState({})
  const [selected, setSelected] = useState('')

  if (!response.loading && accountAccess == null) {
    setAccountAccess(response.data?.accountAccess)
  }

  const table = useTableQuery({
    data: accountAccess,
  })

  useEffect(() => {
    if (admin) {
      const params = {
        p: 0,
        s: 1000,
        sort: null,
        filters: {},
      }
      api.admin.account.getAll(params).then(
        (res) => {
          const data =
            res.data?.data?.filter(
              (dt) => dt.type !== ACCOUNT_TYPE.PUBLISHER.key
            ) ?? []
          setAccounts({ data })
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
    }
  }, [])

  const onAccountChange = (accountId) => {
    setSelected(accountId)
  }

  const onAdminChange = (data, change) => {
    data.admin = change
    setAccountAccess([...accountAccess])
  }

  const onAddAccount = (val) => {
    let account = accountAccess.find((a) => a.accountId === selected)
    if (account) {
      toast.warning({ title: 'This account already exists' })
    } else {
      accountModal.close()
      setAccountAccess([
        ...accountAccess,
        {
          ...accounts.data.find((a) => a.accountId === selected),
          admin: val?.admin,
        },
      ])
    }
  }

  const onRemoveAccount = (accountId) => {
    const accountAccessCopy = [...accountAccess]
    const index = accountAccessCopy.map((e) => e.accountId).indexOf(accountId)
    if (index !== -1) {
      accountAccessCopy.splice(index, 1)
      setAccountAccess(accountAccessCopy)
    }
  }

  if (response.loading) {
    return <CommonSpinner />
  }

  if (response.error) {
    return (
      <NetworkError
        description={response.error || 'User Not Found'}
        onAction={response.refresh}
      />
    )
  }

  const tableColumns = [
    {
      dataIndex: 'name',
      title: 'Account Name',
      render: (val, rec) => (
        <Link to={`/admin/account/${rec.accountId}`}>{val}</Link>
      ),
    },
    {
      dataIndex: 'status',
      title: 'Status',
      render: (key) => {
        if (key === ACCOUNT_STATUS.ACTIVE.key) {
          return <Badge status='success' text={ACCOUNT_STATUS.ACTIVE.name} />
        }
        if (key === ACCOUNT_STATUS.INACTIVE.key) {
          return <Badge status='error' text={ACCOUNT_STATUS.INACTIVE.name} />
        }
        return null
      },
    },
    {
      dataIndex: 'type',
      title: 'Account Type',
      render: (key) => ACCOUNT_TYPE.fromKey(key)?.name,
    },
    {
      dataIndex: 'admin',
      title: 'Admin',
      render: (val, rec) => (
        <Switch
          checked={!!rec.admin}
          onChange={(change) => onAdminChange(rec, change)}
        />
      ),
    },
    {
      dataIndex: 'action',
      title: 'Action',
      render: (val, rec) => (
        <Popconfirm
          title='Remove access to this account?'
          onConfirm={() => onRemoveAccount(rec.accountId)}
          okButtonProps={{ danger: true }}
          okText='Remove'
          placement='topRight'
          arrowPointAtCenter
        >
          <Button danger size='small' icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ]

  return (
    <Flex width={[1, 1, 2 / 3]} gap={3} flexDirection='column'>
      <GeneralSetting
        userState={{ save: true }}
        update={admin}
        updateUser={admin}
        admin={admin}
        accounts={accounts.data || []}
        disableEmail
      />
      {admin ? (
        <AccountSetting
          accounts={accounts.data || []}
          loading={response.loading}
          dataSource={table.data}
          columns={tableColumns}
          onTableChange={table.setQuery}
          modalVisible={accountModal.isOpen}
          onModalOpen={accountModal.open}
          onModalCancel={accountModal.close}
          onAddAccount={onAddAccount}
          onAccountChange={onAccountChange}
        />
      ) : null}
    </Flex>
  )
}

UserDetail.defaultProps = {
  response: {},
  accountAccess: {},
  setAccountAccess: () => {},
}

UserDetail.propTypes = {
  admin: PropTypes.bool,
  response: PropTypes.any,
  accountAccess: PropTypes.any,
  setAccountAccess: PropTypes.func,
}

export default UserDetail
