import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useAppInfoList = ({ params } = {}) => {
  return useQuery({
    queryKey: ['app-info-list', params],
    queryFn: async () => {
      const res = await api.deal.appInfoList(params)
      return res.data
    },
  })
}
