import { createEnum } from '../utils'

export const MEDIA_PLAN_STATUS = createEnum({
  ENABLE: {
    name: 'Enabled',
  },
  DISABLE: {
    name: 'Disabled',
  },
})
