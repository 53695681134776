import React from 'react'
import { Button, Heading, Text, Flex } from '@beachfront/ui'
import { Link, useNavigate } from 'react-router-dom'

import BeachfrontNameBlackLogo from '../../assets/beachfront-name-black-logo.png'

const FourOhFour = () => {
  const navigate = useNavigate()
  return (
    <Flex
      gap={3}
      minHeight={300}
      height='calc(100vh - 80px)'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <Link to='/'>
        <img alt='Beachfront Media' src={BeachfrontNameBlackLogo} width={200} />
      </Link>
      <Heading level={1}>404</Heading>
      <Text>
        The page you are trying to access does not exist. Use the button below
        to get back on track.
      </Text>
      <Button type='primary' onClick={() => navigate('/', { replace: true })}>
        Return to Home
      </Button>
    </Flex>
  )
}

export default FourOhFour
