import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { useFilters, useUsers } from '../../hooks'
import { PageHeader } from '../../components'
import { localFilterKey } from '../../components/filter'

import { UserTable } from './@components'

const Users = ({ admin }) => {
  const filterKey = admin ? localFilterKey.ADMIN_USERS : localFilterKey.USER
  const [filters, setFilters] = useFilters(filterKey)
  const navigate = useNavigate()
  const users = useUsers({ admin, filters: filters.populated })

  const headerActions = (
    <>
      <Button onClick={users.refetch} icon={<ReloadOutlined />} />
      <Button type='primary' onClick={() => navigate('create')}>
        Create User
      </Button>
    </>
  )

  return (
    <>
      <PageHeader
        title={admin ? 'Admin - Users' : 'Users'}
        actions={headerActions}
      />
      <Box mt={2}>
        <UserTable
          admin={admin}
          filters={filters}
          setFilters={setFilters}
          users={users}
          loading={users.isFetching}
          error={users.error}
          onRefetch={users.refetch}
        />
      </Box>
    </>
  )
}

Users.propTypes = {
  admin: PropTypes.bool,
}

export default Users
