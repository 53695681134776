import { isNotEmptyArray, isValidMultipleEmails } from '../../../../utils'
import { ACCOUNT_TYPE, MARGIN_TYPE } from '../../../../enums'

export const validateAccountUpdateForm = (values) => {
  let errors = {}

  const showField = !(
    values.type === ACCOUNT_TYPE.BRAND.key ||
    values.type === ACCOUNT_TYPE.PUBLISHER.key
  )

  if (!values.name) {
    errors.name = 'Required'
  }

  if (values.bfmMarginEnabled) {
    if (!values.bfmMarginType) {
      errors.bfmMarginType = 'Required'
    }
    if (!values.bfmMarginValue && values.bfmMarginValue !== 0) {
      errors.bfmMarginValue = 'Required'
    }
  }

  if (
    values.bfmMarginType === MARGIN_TYPE.PERCENT.key &&
    values.bfmMarginValue > 100
  ) {
    errors.bfmMarginValue = 'Percentage must be between 0 and 100.'
  }

  validateFields(values, errors)

  if (!values.type) {
    errors.type = 'Required'
  }

  if (
    values.sharedSegment &&
    ((values.sourceProviders && values.sourceProviders.length === 0) ||
      !values.sourceProviders)
  ) {
    errors.sourceProviders =
      'Please select at least one segment provider or disable shared switch.'
  }

  if (
    !values.demoAccount &&
    values.accManagerEmails &&
    !isValidMultipleEmails(values.accManagerEmails) &&
    showField
  ) {
    errors.accManagerEmails = 'Please enter valid emails.'
  }

  if (values.type === ACCOUNT_TYPE.AGENCY.key && values.enabled) {
    if (!values.startDate) {
      errors.startDate = 'Required'
    }
    errors.spentRanges = validateTakeRate(values)
  }

  return errors
}

export const validateAccountCreateForm = (values) => {
  let errors = {}

  const showField = !(
    values.type === ACCOUNT_TYPE.BRAND.key ||
    values.type === ACCOUNT_TYPE.PUBLISHER.key
  )

  if (!values.name) {
    errors.name = 'Required'
  }

  if (
    values.bfmMarginType === MARGIN_TYPE.PERCENT.key &&
    values.bfmMarginValue > 100
  ) {
    errors.bfmMarginValue = 'Percentage must be between 0 and 100.'
  }

  validateFields(values, errors)

  if (!values.type) {
    errors.type = 'Required'
  }

  if (
    values.sharedSegment &&
    ((values.sourceProviders && values.sourceProviders.length === 0) ||
      !values.sourceProviders)
  ) {
    errors.sourceProviders =
      'Please select at least one segment provider or disable shared switch.'
  }

  if (
    !values.demoAccount &&
    values.accManagerEmails &&
    !isValidMultipleEmails(values.accManagerEmails) &&
    showField
  ) {
    errors.accManagerEmails = 'Please enter valid emails.'
  }

  if (values.bfmMarginEnabled) {
    if (!values.bfmMarginType) {
      errors.bfmMarginType = 'Required'
    }

    if (!values.bfmMarginValue) {
      errors.bfmMarginValue = 'Required'
    }
  }

  return errors
}

const validateFields = (values, errors) => {
  let link = values.link
  let advertiserIds = []
  let adomains = []
  let pubIds = []

  for (let i in link) {
    const { key } = link[i]
    if (values.type === ACCOUNT_TYPE.DSP.key) {
      let advertiserIdKey = `link-${key}-advertiserId`
      if (!values[advertiserIdKey]) {
        errors[advertiserIdKey] = 'Advertiser ID is required.'
      } else if (advertiserIds.includes(values[advertiserIdKey])) {
        errors[advertiserIdKey] = 'Please provide a unique Advertiser ID.'
      } else {
        advertiserIds.push(values[advertiserIdKey])
      }
    }

    if (values.type === ACCOUNT_TYPE.PUBLISHER.key) {
      let pubIdKey = `link-${key}-pubId`
      if (!values[pubIdKey]) {
        errors[pubIdKey] = 'Publisher ID is required.'
      } else if (pubIds.includes(values[pubIdKey])) {
        errors[pubIdKey] = 'Please provide a unique Publisher ID.'
      } else {
        pubIds.push(values[pubIdKey])
      }
    }

    if (values.type === ACCOUNT_TYPE.AGENCY.key) {
      let advertiserIdKey = `link-${key}-advertiserId`
      if (!values[advertiserIdKey]) {
        errors[advertiserIdKey] = 'Advertiser ID is required.'
      }
      let seatOrAgencyKey = `link-${key}-seatOrAgency`
      let seatAgencyIdKey = `link-${key}-seatAgencyId`
      if (!values[seatOrAgencyKey]) {
        errors[seatAgencyIdKey] = 'Select Seat ID or Agency ID.'
      } else if (!values[seatAgencyIdKey]) {
        errors[seatAgencyIdKey] = 'Seat ID / Agency ID is required.'
      }
    }

    if (values.type === ACCOUNT_TYPE.BRAND.key) {
      let adomainKey = `link-${key}-adomain`
      if (!values[adomainKey]) {
        errors[adomainKey] = 'Adomain is required.'
      } else if (adomains.includes(values[adomainKey])) {
        errors[adomainKey] = 'Please provide a unique adomain.'
      } else {
        adomains.push(values[adomainKey])
      }
    }
  }
}

const validateTakeRate = (values) => {
  let spentRangesError = []
  if (isNotEmptyArray(values.spentRanges)) {
    for (let i = 0; i < values.spentRanges.length; i++) {
      let errorObj = {}
      let currentValues = values.spentRanges[i] || {}
      if (i !== 0 && !isValidValue(currentValues.from)) {
        errorObj.from = 'Start Range is required.'
      }
      if (
        i !== values.spentRanges.length - 1 &&
        !isValidValue(currentValues.to)
      ) {
        errorObj.to = 'End Range is required.'
      }
      if (!isValidValue(currentValues.takeRate)) {
        errorObj.takeRate = 'Take Rate is required.'
      }
      if (isValidValue(currentValues.takeRate)) {
        if (currentValues.takeRate > 100) {
          errorObj.takeRate = 'Take Rate should be less than 100%.'
        }
        if (currentValues.takeRate < 1) {
          errorObj.takeRate = 'Take Rate should be greater than 0%.'
        }
      }
      if (
        isValidValue(currentValues.to) &&
        isValidValue(currentValues.from) &&
        currentValues.from >= currentValues.to
      ) {
        errorObj.to = 'End Range should be greater than Start.'
      }
      if (currentValues.from < 0) {
        errorObj.from = 'Start Range should be greater than 0.'
      }
      if (currentValues.to < 0) {
        errorObj.to = 'End Range should be greater than 0.'
      }
      if (currentValues.takeRate < 0) {
        errorObj.takeRate = 'Take Rate should be greater than 0.'
      }
      for (let j = 0; j < values.spentRanges.length; j++) {
        if (
          i !== j &&
          values.spentRanges[j] &&
          values.spentRanges[j].from &&
          values.spentRanges[j].to
        ) {
          if (
            isValidValue(currentValues.from) &&
            currentValues.from > values.spentRanges[j].from &&
            currentValues.from < values.spentRanges[j].to
          ) {
            errorObj.from = 'Please select valid Start Range.'
          }

          if (
            isValidValue(currentValues.to) &&
            currentValues.to > values.spentRanges[j].from &&
            currentValues.to < values.spentRanges[j].to
          ) {
            errorObj.to = 'Please select valid End Range.'
          }

          if (
            isValidValue(currentValues.from) &&
            isValidValue(currentValues.to) &&
            currentValues.from < values.spentRanges[j].from &&
            currentValues.to > values.spentRanges[j].to
          ) {
            errorObj.to = 'Please select valid End Range.'
          }
        }
      }
      if (i > 0) {
        if (
          values.spentRanges[i - 1] &&
          values.spentRanges[i - 1].to &&
          currentValues.from !== values.spentRanges[i - 1].to
        ) {
          errorObj.from = `Start Range should be ${
            values.spentRanges[i - 1].to
          }`
        }
      }
      spentRangesError[i] = errorObj
    }
  } else {
    spentRangesError = [
      {
        from: 'Start Range is required.',
        to: 'End Range is required.',
        takeRate: 'Take Rate is required.',
      },
    ]
  }
  return spentRangesError
}

export const isNotEmptySpentRange = (values) => {
  let isValid = true
  for (let i = 0; i < values.length; i++) {
    let currentValues = values[i] || {}
    if (
      (i !== 0 && !currentValues.from) ||
      !currentValues.to ||
      currentValues.takeRate === undefined ||
      currentValues.takeRate === null
    ) {
      isValid = false
      break
    }
  }
  return isValid
}

const isValidValue = (num) => {
  return num !== undefined && num !== null
}
