import React, { useState } from 'react'
import axios from 'axios'
import {
  Alert,
  Button,
  Flex,
  Heading,
  Layout,
  Spinner,
  Paragraph,
} from '@beachfront/ui'
import { useAsyncEffect } from '@beachfront/ui/hooks'
import { useKeycloak } from '@react-keycloak/web'
import { redirect, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import BrandLogo from './brand-logo'
import faviconSrc from '../../assets/favicon.png'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const LoginAs = () => {
  const {
    keycloak: { token, tokenParsed },
  } = useKeycloak()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState({
    active: false,
    msg: '',
    description: '',
  })

  const query = useQuery()
  const username = query.get('username')
  const redirectPath = query.get('redirect') || '/'

  // Only super users can access this page
  if (tokenParsed.type !== 'SUPER') {
    return redirect('/')
  }

  if (!username && !error.active) {
    setError({
      active: true,
      msg: 'Invalid Username',
      description:
        'A valid username must be a part of the Query string in the username key',
    })
  }

  const callSSOEndpoint = async () => {
    try {
      if (!username || !token) {
        return
      }

      const REALM_USER = '30cde393-260a-4852-9a41-4e45ef7e9c1b'
      await axios.post(
        `https://sso.beachfront.com/auth/admin/realms/BFM/users/f:${REALM_USER}:${username}/impersonation`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      )
      setIsLoading(false)
      window.sessionStorage.clear()
      window.location.href = redirectPath
    } catch (err) {
      setError({
        active: true,
        msg: 'Error',
        description: err.message,
      })
    }
  }

  useAsyncEffect(callSSOEndpoint, [token])

  return (
    <Layout>
      <Helmet>
        <title>Beachfront Media Redirect</title>
        <link rel='icon' href={faviconSrc} />
      </Helmet>
      <Layout.Sider width={230} bg='dark.bg' borderRight='split' collapsed>
        <BrandLogo />
      </Layout.Sider>
      <Layout.Header>
        <Flex alignItems='center' justifyContent='space-between' pr={4}>
          <Heading level={2} px={4} lineHeight='64px'>
            Redirecting
          </Heading>
        </Flex>
      </Layout.Header>
      <Layout.Content px={4} py={3}>
        {!error.active ? (
          <Paragraph mb={3} width={800}>
            You are currently being logged in as {username}. Your page will
            redirect once authentication is complete
          </Paragraph>
        ) : null}
        {error.active ? (
          <>
            <Alert
              description={error.description}
              message={error.msg}
              showIcon
              type='error'
            />
            <Flex mt={4}>
              <Button
                onClick={() => {
                  window.location.href = redirectPath
                }}
              >
                Return
              </Button>
            </Flex>
          </>
        ) : null}
        <Spinner show={isLoading && !error.active} />
      </Layout.Content>
    </Layout>
  )
}

export default LoginAs
