import React, { useMemo } from 'react'
import {
  Box,
  Card,
  Flex,
  Grid,
  Input,
  InputNumber,
  Radio,
  Text,
  Pipe,
} from '@beachfront/ui'
import PropTypes from 'prop-types'
import { Field, useFormState } from '@beachfront/ui/forms'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'

import { ACCOUNT_STATUS, ACCOUNT_TYPE } from '../../../../../enums'
import { api } from '../../../../../client-api'
import { SelectField } from '../../../../../components'

const CompanySetting = ({ account }) => {
  const { values } = useFormState()
  const { data: marketplaceData, isLoading } = useQuery({
    queryKey: ['marketplaces', 'admin'],
    queryFn: async () => {
      const { data } = await api.mediaPlan.marketplaces()
      return data
    },
  })

  const accessibleOptions = useMemo(() => {
    return marketplaceData?.filter(
      (mktData) =>
        !values.ownedMarketplaceIds?.includes(mktData.id) &&
        !values.customMarketplaceIds?.includes(mktData.id)
    )
  }, [marketplaceData, values.ownedMarketplaceIds, values.customMarketplaceIds])

  const ownedOptions = useMemo(() => {
    return marketplaceData?.filter(
      (mktData) =>
        !values.accessibleMarketplaceIds?.includes(mktData.id) &&
        !values.customMarketplaceIds?.includes(mktData.id)
    )
  }, [
    marketplaceData,
    values.accessibleMarketplaceIds,
    values.customMarketplaceIds,
  ])

  const customOptions = useMemo(() => {
    return marketplaceData?.filter(
      (mktData) =>
        !values.accessibleMarketplaceIds?.includes(mktData.id) &&
        !values.ownedMarketplaceIds?.includes(mktData.id)
    )
  }, [
    marketplaceData,
    values.accessibleMarketplaceIds,
    values.ownedMarketplaceIds,
  ])

  const cardExtra = (
    <Field name='status' mb={0}>
      <Radio.Group>
        <Radio value={ACCOUNT_STATUS.ACTIVE.key}>
          {ACCOUNT_STATUS.ACTIVE.name}
        </Radio>
        <Radio value={ACCOUNT_STATUS.BANNED.key}>
          {ACCOUNT_STATUS.BANNED.name}
        </Radio>
      </Radio.Group>
    </Field>
  )

  const nameExtra = (
    <Flex ml='auto' pl={3} fontSize={0}>
      {account?.data?.createDate ? (
        <Flex alignItems='center'>
          <Text strong>Created On:&nbsp;</Text>
          <Text>
            {moment(account.data.createDate).format('DD/MM/YYYY  hh:mm')}
          </Text>
        </Flex>
      ) : null}
      {account?.data?.type === ACCOUNT_TYPE.PUBLISHER.key ? (
        <Flex alignItems='center'>
          <Pipe bg='gray.5' height='100%' />
          <Text strong>Account Type:&nbsp;</Text>
          <Text>Publisher</Text>
        </Flex>
      ) : null}
    </Flex>
  )

  return (
    <Card title='Account Information' extra={cardExtra} height='100%'>
      <Grid column={2} gap={2}>
        <Box columnSpan={[24, 24, 18]}>
          <Field extra={nameExtra} name='name' label='Name'>
            <Input placeholder='Enter company name' maxLength={100} />
          </Field>
        </Box>
        <Box columnSpan={[24, 24, 6]}>
          <Field name='allowedAds' label='Allowed Ads'>
            <InputNumber max={600} min={1} />
          </Field>
        </Box>
        <Box columnSpan={[24]}>
          <Field
            name='accessibleMarketplaceIds'
            label='Select For Publishers - Accessible Marketplace(s)'
          >
            <SelectField
              showSearch
              mode='multiple'
              keyField='id'
              textField='name'
              placeholder='Select Marketplace(s)'
              data={accessibleOptions || []}
              loading={isLoading}
            />
          </Field>
        </Box>
        <Box columnSpan={[24]}>
          <Field
            name='ownedMarketplaceIds'
            label='Select For Publishers - Owned Marketplace(s)'
          >
            <SelectField
              showSearch
              mode='multiple'
              keyField='id'
              textField='name'
              placeholder='Select Marketplace(s)'
              data={ownedOptions || []}
              loading={isLoading}
            />
          </Field>
        </Box>
        <Box columnSpan={[24]}>
          <Field name='customMarketplaceIds' label='Custom Marketplace(s)'>
            <SelectField
              showSearch
              mode='multiple'
              keyField='id'
              textField='name'
              placeholder='Select Marketplace(s)'
              data={customOptions || []}
              loading={isLoading}
            />
          </Field>
        </Box>
      </Grid>
    </Card>
  )
}

CompanySetting.propTypes = {
  account: PropTypes.object,
}

export default CompanySetting
