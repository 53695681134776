import { isObject, isDefined } from './type-util'

export const queryStringToObject = (query) => {
  return Object.fromEntries(new URLSearchParams(query))
}

export const objectToQueryString = (obj) => {
  if (isObject(obj)) {
    let pairs = []

    Object.entries(obj).forEach(([key, value]) => {
      if (isDefined(value)) {
        pairs.push(key + '=' + encodeURIComponent(value))
      }
    })

    return pairs.join('&')
  }
  return ''
}
