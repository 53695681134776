import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Grid, Input, Radio } from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'

import { MEDIA_LIST_TYPE, LIST_INPUT_TYPE } from '../../../enums'
import { SelectField } from '../../../components'

const MediaListForm = ({
  mediaListId,
  count,
  showCount,
  showInputType,
  onMediaTypeChange,
  onInputTypeChange,
}) => {
  return (
    <>
      <Grid columns={1}>
        <Field name='title' label='Title'>
          <Input placeholder='Enter title' />
        </Field>
        <Field name='mediaType' label='List Type'>
          <SelectField
            name='mediaType'
            keyField='key'
            textField='value'
            placeholder='Select Type'
            data={MEDIA_LIST_TYPE.values().map(({ key, name }) => ({
              key,
              value: name,
            }))}
            disabled={!!mediaListId}
            onChange={onMediaTypeChange}
          />
        </Field>
        {showInputType ? (
          <Field name='inputType' label='Input Type' type='text' mb={0}>
            <Radio.Group size='small' onChange={onInputTypeChange}>
              {LIST_INPUT_TYPE.values().map(({ key, name }) => (
                <Radio key={key} value={key}>
                  {name}
                </Radio>
              ))}
            </Radio.Group>
          </Field>
        ) : null}
      </Grid>
      {showCount ? (
        <>
          <Divider />
          <Box
            p={2}
            fontSize={2}
            textAlign='center'
            bg='primary.1'
            color='primary.6'
            borderRadius='base'
          >
            {mediaListId ? 'Total - ' : 'Selected - '}
            {count || 0}
          </Box>
        </>
      ) : null}
    </>
  )
}

MediaListForm.defaultProps = {
  showInputType: true,
  showCount: true,
}

MediaListForm.propTypes = {
  mediaListId: PropTypes.string,
  count: PropTypes.number,
  showCount: PropTypes.bool,
  showInputType: PropTypes.bool,
  onMediaTypeChange: PropTypes.func,
  onInputTypeChange: PropTypes.func,
}

export default MediaListForm
