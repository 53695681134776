import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { ScrollArea, Box, NavMenu } from '@beachfront/ui'

import { useUserData } from '../../../hooks'
import { menuItemConfig } from '../@constants'
import { getMenuItems } from '../@utils'

const MenuBar = ({ getPopupContainer }) => {
  const location = useLocation()
  const [user] = useUserData()

  const selectedKeys = getSelectedKeys(location)
  const menuItems = getMenuItems(menuItemConfig, user)
    .map((item) => {
      return {
        key: item.route ?? item.key,
        href: item.route,
        title: item.title,
        icon: item.icon,
        children: item.submenu
          ? item.submenu.map((child) => ({
              key: child.route,
              href: child.route,
              title: child.title,
            }))
          : [],
      }
    })
    .filter((x) => x)

  const renderLink = ({ href, label }) => {
    return href?.startsWith('/') ? (
      <Link to={href}>{label}</Link>
    ) : (
      <a href={href} rel='noreferrer' target='_blank'>
        {label}
      </a>
    )
  }

  return (
    <ScrollArea mt={2} flex='auto' overScroll='contain' variant='inverted'>
      <Box py={2}>
        <NavMenu
          orientation='vertical'
          variant='dark'
          bg='transparent'
          selectedKeys={selectedKeys}
          items={menuItems}
          renderLink={renderLink}
          getPopupContainer={getPopupContainer}
        />
      </Box>
    </ScrollArea>
  )
}

const getSelectedKeys = (location) => {
  return location.pathname
    .split('/')
    .map((_, i, arr) => arr.slice(0, i + 1).join('/'))
    .filter((x) => x)
}

MenuBar.propTypes = {
  getPopupContainer: PropTypes.func,
}

export default MenuBar
