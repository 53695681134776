import React, { useMemo, useState } from 'react'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { Box, Button, Tabs, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import { useDisclosure } from '@beachfront/ui/hooks'

import { api } from '../../../client-api'
import { TIMEZONE } from '../../../enums'
import { isNotEmptyArray, getErrorMessage } from '../../../utils'
import { useFetch } from '../../../hooks'
import { PageHeader } from '../../../components'
import { validateUpdateUserForm } from '../@utils'

import { UpdatePasswordModal, UserDetail, UserHistory } from '.'

const UpdateUser = ({ admin }) => {
  const { type, userId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const passwordModal = useDisclosure()
  const [accountAccess, setAccountAccess] = useState(null)

  const response = useFetch({
    request: admin ? api.admin.user.getUserById : api.users.getUserById,
    payload: { userId },
  })

  const initialValue = useMemo(() => {
    if (response.data?.timezone === TIMEZONE.CUSTOM.key) {
      return {
        ...response.data,
        offset: String(response.data.offset),
      }
    }
    return response.data
  }, [response.data])

  const onSubmit = (values, form) => {
    const timezone = admin
      ? {
          offset:
            values.timezone === TIMEZONE.CUSTOM.key
              ? Number(values.offset)
              : '',
          timezone: values.timezone,
        }
      : {}

    const params = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      admin: values.admin,
      accountAccess: accountAccess?.map((account) => ({
        accountId: account.accountId,
        admin: !!account.admin,
      })),
      ownedMarketplaces: values.ownedMarketplaces || [],
      ...timezone,
    }

    if (admin) {
      params.dashboardUrl = values.dashboardUrl
      params.adhocUrl = values.adhocUrl
    }

    const request = admin ? api.admin.user.update : api.users.update

    return request(userId, params).then(
      () => {
        toast.success({ title: 'User updated.' })
        setTimeout(() => form.reset())
        setTimeout(() =>
          navigate({ pathname: admin ? '/admin/user' : '/users' })
        )
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const baseLocation = admin ? '/admin/user' : '/user'

  if (!type && admin) {
    return <Navigate to={`${baseLocation}/${userId}/detail`} replace />
  }

  const getPageTitle = () => {
    if (response.data) {
      return `User - ${response.data.firstName} ${response.data.lastName}`
    }
    return 'User'
  }

  const onTabChange = (key) => {
    navigate(`${baseLocation}/${userId}/${key}`)
  }

  const hasAccountAccess =
    isNotEmptyArray(response.data?.accountAccess) &&
    (!response.data.publisherAccount ||
      response.data.accountAccess.length !== 1)

  return (
    <>
      <UpdatePasswordModal
        visible={passwordModal.isOpen}
        onClose={passwordModal.close}
        userEmail={response.data?.email}
      />
      <Form
        initialValues={initialValue}
        onSubmit={onSubmit}
        validate={(values) => validateUpdateUserForm(values, admin)}
      >
        {({ handleSubmit, submitting, dirty }) => (
          <form onSubmit={handleSubmit}>
            <PageHeader
              title={getPageTitle()}
              actions={
                <>
                  {type === 'detail' && admin && hasAccountAccess ? (
                    <Button onClick={passwordModal.open}>
                      Update Password
                    </Button>
                  ) : null}
                  {type === 'detail' || !admin ? (
                    <Button
                      disabled={
                        !dirty && accountAccess === response.data?.accountAccess
                      }
                      type='primary'
                      loading={submitting}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  ) : null}
                </>
              }
            />
            <Box mt={3}>
              {admin ? (
                <Tabs activeKey={type} onChange={onTabChange} type='card'>
                  <Tabs.TabPane key='detail' tab='Detail'>
                    <UserDetail
                      admin={admin}
                      accountAccess={accountAccess}
                      setAccountAccess={setAccountAccess}
                      response={response}
                      userId={userId}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane key='history' tab='History'>
                    <UserHistory userId={userId} />
                  </Tabs.TabPane>
                </Tabs>
              ) : (
                <UserDetail
                  admin={admin}
                  accountAccess={accountAccess}
                  setAccountAccess={setAccountAccess}
                  response={response}
                  userId={userId}
                />
              )}
            </Box>
          </form>
        )}
      </Form>
    </>
  )
}

export default UpdateUser
