import { LIST_INPUT_TYPE } from '../../../enums'

export const validateMediaListForm = (values) => {
  const errors = {}

  if (values.title) {
    values.title = values.title.trim()
  }

  if (!values.title) {
    errors.title = 'Required'
  }

  if (!values.mediaType) {
    errors.mediaType = 'Required'
  }

  if (
    values.inputType === LIST_INPUT_TYPE.MANUAL.key &&
    !values.items?.length
  ) {
    errors.items = 'Please enter at least one item.'
  }

  if (values.inputType === LIST_INPUT_TYPE.UPLOAD.key) {
    if (values.uploadState?.state !== 'success') {
      errors.file = 'Please upload a valid CSV file.'
    } else if (!values.uploadState?.data?.data?.length) {
      errors.file = 'Please upload a valid CSV file with at least one item.'
    }
  }

  return errors
}
