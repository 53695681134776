import React, { useState, useEffect } from 'react'
import { useBlocker } from 'react-router-dom'
import { Modal, Button, Paragraph } from '@beachfront/ui'
import { useBeforeUnload, useDisclosure } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

const DirtyPrompt = ({ dirty, baseLocation }) => {
  const modal = useDisclosure()
  const [confirmed, setConfirmed] = useState(false)

  const handleBlockedNavigation = ({ nextLocation }) => {
    return (
      dirty &&
      (!baseLocation || !nextLocation.pathname.startsWith(baseLocation))
    )
  }

  const blocker = useBlocker(handleBlockedNavigation)
  useBeforeUnload(dirty)

  useEffect(() => {
    if (blocker.state === 'blocked' && !dirty) {
      blocker.reset()
    }
  }, [blocker, dirty])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      modal.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker])

  const onConfirm = () => {
    setConfirmed(true)
    modal.close()
  }

  const afterClose = () => {
    if (confirmed) {
      blocker.proceed?.()
    } else {
      blocker.reset?.()
    }
  }

  const footer = (
    <div>
      <Button onClick={modal.close}>Cancel</Button>
      <Button danger onClick={onConfirm}>
        Continue
      </Button>
    </div>
  )

  return (
    <Modal
      visible={modal.isOpen}
      onCancel={modal.close}
      afterClose={afterClose}
      title='Changes Are Not Saved'
      footer={footer}
    >
      <Paragraph>
        You have unsaved changes that will be lost if you leave this page.
        Continue?
      </Paragraph>
    </Modal>
  )
}

DirtyPrompt.propTypes = {
  dirty: PropTypes.bool.isRequired,
  baseLocation: PropTypes.string,
}

export default DirtyPrompt
