import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Card, Flex, Switch, Help } from '@beachfront/ui'
import { Field, useFormState, useForm } from '@beachfront/ui/forms'
import PropTypes from 'prop-types'

import { api } from '../../../../../client-api'
import { SelectField } from '../../../../../components'
import { isNotEmptyArray } from '../../../../../utils'

const SegmentSetting = ({ setSegmentProviderList }) => {
  const form = useForm()
  const { values } = useFormState()

  // TODO move this to parent component and remove effect
  const { data } = useQuery({
    queryKey: ['providers', 'all'],
    queryFn: async () => {
      const { data } = await api.segment.getAllProviders()
      return data
    },
  })

  useEffect(() => {
    if (isNotEmptyArray(data)) {
      setSegmentProviderList(data)
    }
  }, [data])

  const extra = (
    <Flex alignItems='center' gap={4}>
      <Field inline label='Uploaded' name='uploadedSegment' mb={0}>
        <Switch />
      </Field>
      <Field inline label='Shared' name='sharedSegment' mb={0}>
        <Switch />
      </Field>
    </Flex>
  )

  const updateSelectedValues = (selectedValues) => {
    const updatedValues = []
    data.forEach((node) => {
      // If the node is selected or it's a child of a selected parent, add it to the updated values
      if (
        selectedValues.includes(node.id) ||
        (node.parentProvider && selectedValues.includes(node.parentProvider))
      ) {
        updatedValues.push(node.id)
      }
    })
    form.change('sourceProviders', updatedValues)
  }

  const onChange = (newValue) => {
    updateSelectedValues(newValue)
  }

  return (
    <Card title='Segments' extra={extra} overflow='initial' height='100%'>
      {values.sharedSegment ? (
        <Field
          name='sourceProviders'
          label='Segment Provider'
          style={{ width: '100%' }}
        >
          <SelectField
            mode='multiple'
            keyField='id'
            textField='name'
            placeholder='Select segment providers'
            onChange={onChange}
            data={data || []}
            allowClear
          />
        </Field>
      ) : (
        <Flex>
          <Help>Enable a segment type to select providers</Help>
        </Flex>
      )}
    </Card>
  )
}

SegmentSetting.propTypes = {
  setSegmentProviderList: PropTypes.func,
}

export default SegmentSetting
