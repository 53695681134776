import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Card } from '@beachfront/ui'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts'

import { getChartColors } from '../../utils'

import { formatTick } from './widget-util'
import { CustomTooltip } from './custom-tooltip'
import { CustomLegend } from './custom-legend'

export const ComposedBarLineChartWidget = ({
  data,
  title,
  colors,
  cardBodyHeight,
  ...rest
}) => {
  const theme = useTheme()
  const chartColors = colors ?? getChartColors(theme.colors)

  const compData = useMemo(() => {
    if (!data) {
      return []
    }
    return data.map((dt) => {
      dt.chartData?.forEach((el) => {
        dt[el.key] = Number(el.value) || 0
      })
      return dt
    })
  }, [data])

  const units =
    compData[0]?.chartData?.map((dt) => dt.prefix ?? dt.suffix) ?? []

  return (
    <Card
      title={title}
      bodyStyle={{ height: cardBodyHeight ?? '300px' }}
      {...rest}
    >
      <ResponsiveContainer>
        <ComposedChart
          data={compData}
          margin={{ top: 15, right: 15, left: 15, bottom: 0 }}
        >
          <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
          <XAxis
            strokeWidth='1'
            dataKey='label'
            interval='preserveStartEnd'
            axisLine={{ stroke: theme.colors.gray[4] }}
            tickLine={{ stroke: theme.colors.gray[4] }}
            tick={{ fill: theme.colors.gray[6] }}
            tickMargin={8}
          />
          {compData[0]?.chartData?.map((dt) => (
            <YAxis
              key={dt.key + 'axis'}
              yAxisId={dt.key}
              width={50}
              axisLine={{ stroke: theme.colors.gray[4] }}
              tickLine={false}
              tick={{ fill: theme.colors.gray[6] }}
              tickFormatter={(val) =>
                formatTick(val, false, dt.prefix ?? dt.suffix)
              }
            />
          ))}
          <Tooltip
            isAnimationActive={false}
            cursor={{ stroke: theme.colors.gray[3] }}
            wrapperStyle={{ zIndex: 1 }}
            content={<CustomTooltip unit={units} />}
          />
          <Legend content={<CustomLegend />} />
          {compData[0]?.chartData?.map((dt, i) => (
            <Bar
              key={dt.key}
              dataKey={dt.key}
              yAxisId={dt.key}
              name={dt.name}
              type='monotone'
              fill={chartColors[i % chartColors.length]}
              barSize={25}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

ComposedBarLineChartWidget.propTypes = {
  data: PropTypes.array,
  title: PropTypes.node,
  colors: PropTypes.array,
  cardBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
