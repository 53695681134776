import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Flex, Card, Empty } from '@beachfront/ui'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Label,
} from 'recharts'

import { getChartColors } from '../../utils'
import { AxisLabel } from '../chart'

import { formatTick } from './widget-util'
import { CustomTooltip } from './custom-tooltip'

export const SimpleBarChartWidget = ({
  data,
  title,
  colors,
  colorOffset = 0,
  cardBodyHeight,
  unit,
  vertical,
  xLabel,
  xUnit,
  ...rest
}) => {
  const theme = useTheme()
  const chartColors = colors ?? getChartColors(theme.colors)
  const barColor = chartColors[colorOffset % chartColors.length]

  const formatTickVertical = (val) => {
    return xUnit ? (xUnit === '$' ? xUnit + val : val + xUnit) : val
  }

  const formatTickHorizontal = (val) => {
    if (val && val.length > 9) {
      return val.substr(0, 7) + '...'
    }
    return val
  }

  if (!data || data.length === 0) {
    return (
      <Card
        title={title}
        style={{ height: cardBodyHeight ?? '345px' }}
        {...rest}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No chart data found'
        />
      </Card>
    )
  }

  return (
    <Card title={title} style={{ height: cardBodyHeight ?? '345px' }} {...rest}>
      {vertical ? (
        <Flex width='100%' height='100%' fontFamily='secondary' fontSize={0}>
          <ResponsiveContainer>
            <BarChart
              data={data}
              layout='vertical'
              maxBarSize={30}
              margin={{ top: 15, right: 24, left: 0, bottom: 15 }}
            >
              <CartesianGrid
                vertical={true}
                horizontal={false}
                stroke={theme.colors.gray[4]}
              />
              <XAxis
                type='number'
                strokeWidth='1'
                interval='preserveStartEnd'
                axisLine={{ stroke: theme.colors.gray[4] }}
                tickLine={false}
                tick={{ fill: theme.colors.gray[6] }}
                tickFormatter={formatTickVertical}
              >
                {xLabel ? (
                  <Label
                    dy={15}
                    dx={-35}
                    value={xLabel}
                    position='centerBottom'
                  />
                ) : null}
              </XAxis>
              <YAxis
                dataKey='label'
                type='category'
                interval='preserveStartEnd'
                axisLine={{ stroke: theme.colors.gray[4] }}
                tick={{ fill: theme.colors.gray[6] }}
                tickFormatter={(val) => formatTick(val, vertical)}
                width={90}
                tickLine={false}
              />
              <Tooltip
                isAnimationActive={false}
                cursor={{ fill: theme.colors.gray[3] }}
                wrapperStyle={{ zIndex: 1 }}
                content={<CustomTooltip unit={xUnit} />}
              />
              <Bar dataKey='value' name='Value' barSize={15} fill={barColor}>
                {data.map((dt, i) => (
                  <Cell key={i} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Flex>
      ) : (
        <Flex width='100%' height='100%' fontFamily='secondary' fontSize={0}>
          <ResponsiveContainer>
            <BarChart data={data} maxBarSize={30}>
              <CartesianGrid
                vertical={false}
                horizontal={true}
                stroke={theme.colors.gray[4]}
              />
              <XAxis
                dataKey='label'
                strokeWidth='1'
                interval='preserveStartEnd'
                axisLine={{ stroke: theme.colors.gray[4] }}
                tickLine={false}
                tick={{ fill: theme.colors.gray[6] }}
                tickFormatter={formatTickHorizontal}
              />
              <YAxis
                interval={0}
                axisLine={{ stroke: theme.colors.gray[4] }}
                tickLine={false}
                tick={{ fill: theme.colors.gray[6] }}
                tickFormatter={formatTickVertical}
                label={
                  <AxisLabel
                    axisType='y'
                    x={25}
                    y={cardBodyHeight / 2 - 15}
                    width={0}
                    height={0}
                    fill={theme.colors.text}
                  >
                    {unit}
                  </AxisLabel>
                }
              />
              <Tooltip
                isAnimationActive={false}
                cursor={{ fill: theme.colors.gray[3] }}
                wrapperStyle={{ zIndex: 1 }}
                content={<CustomTooltip />}
              />
              <Bar dataKey='value' name='Value' barSize={15} fill={barColor}>
                {data.map((dt, i) => (
                  <Cell key={i} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Flex>
      )}
    </Card>
  )
}

SimpleBarChartWidget.propTypes = {
  data: PropTypes.array,
  title: PropTypes.node,
  colors: PropTypes.array,
  colorOffset: PropTypes.number,
  cardBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  vertical: PropTypes.bool,
  xLabel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xUnit: PropTypes.string,
}
