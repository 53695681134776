import React, { useMemo, useState, useRef } from 'react'

import { useSegmentProviders } from '../../../../hooks'
import { getParentProvider } from '../../@utils/boolean-segment.util'

const BooleanSegmentContext = React.createContext()

// Custom Hook to use in components
export const useBooleanSegment = () => React.useContext(BooleanSegmentContext)

const INITIAL_PAGINATION = {
  q: '',
  p: 0, // page-number
  s: 100, // page-size
}

export const BooleanSegmentProvider = ({ children }) => {
  const { providers } = useSegmentProviders()
  // When user clicks an item; it becomes active to be moved around
  const [activeItem, setActiveItem] = useState(null)
  const [expressionV2, setExpressionV2] = useState([])
  const [isExpanded, setIsExpanded] = useState(false)
  const [isHelpShown, setIsHelpShown] = useState(false)
  const [appliedProviders, setAppliedProviders] = useState(['ALL'])
  const [appliedTypes, setAppliedTypes] = useState(['ALL'])
  const [params, setParams] = useState(INITIAL_PAGINATION)
  const stateHistory = useRef(null)

  const activeParentProvider = useMemo(() => {
    const findFirstSegment = (obj) => {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          const result = findFirstSegment(obj[i])
          if (result) {
            return result
          }
        }
      } else if (typeof obj === 'object') {
        if (obj?.type === 'SEGMENT') {
          return obj
        } else {
          for (const key in obj) {
            const result = findFirstSegment(obj[key])
            if (result) {
              return result
            }
          }
        }
      }
      return null
    }

    const firstSegment = findFirstSegment(expressionV2)

    return firstSegment ? getParentProvider(firstSegment, providers) : null
  }, [expressionV2])

  return (
    <BooleanSegmentContext.Provider
      value={{
        activeItem,
        activeParentProvider,
        appliedProviders,
        appliedTypes,
        expressionV2,
        isExpanded,
        isHelpShown,
        params,
        INITIAL_PAGINATION,
        setParams,
        setAppliedProviders,
        setAppliedTypes,
        setExpressionV2,
        setActiveItem,
        setIsHelpShown,
        setIsExpanded,
        stateHistory,
      }}
    >
      {children}
    </BooleanSegmentContext.Provider>
  )
}
