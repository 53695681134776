import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@beachfront/ui'
import styled, { keyframes } from 'styled-components'
import moment from 'moment'

const MessageBubble = ({
  text,
  date,
  name,
  isSender,
  isAnimated,
  hasPrevious,
  hasNext,
}) => {
  const orientation = isSender ? 'right' : 'left'

  return (
    <Flex width='100%' justifyContent={isSender ? 'flex-end' : 'flex-start'}>
      <BubbleContainer data-animated={isAnimated}>
        {!hasPrevious ? (
          <Box
            mt={1}
            mx={1}
            fontSize={0}
            color='gray.7'
            fontWeight='bold'
            textAlign={isSender ? 'right' : 'left'}
          >
            {name}
          </Box>
        ) : null}
        <BubbleNode data-orientation={orientation} data-previous={hasPrevious}>
          {text}
        </BubbleNode>
        <Box position='relative'>
          {date ? (
            <Box
              fontSize={10}
              color='gray.6'
              textAlign={isSender ? 'right' : 'left'}
              mr={isSender ? 18 : 0}
              ml={isSender ? 0 : 18}
            >
              {moment(date).format('MMM D, YYYY [at] h:mm A')}
            </Box>
          ) : null}
          {hasNext ? (
            <Box position='absolute' top={-4} left={0} right={0}>
              <BubbleEdge data-orientation={orientation} />
            </Box>
          ) : (
            <Box position='absolute' top={0} left={0} right={0}>
              <BubbleTrail data-orientation={orientation} />
            </Box>
          )}
        </Box>
      </BubbleContainer>
    </Flex>
  )
}

const grow = keyframes`
  from {
    transform: scale(0.4);
  }
  to {
    transform: scale(1);
  }
`

const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  transform-origin: top right;

  &[data-animated='true'] {
    animation-duration: 0.3s;
    animation-name: ${grow};
  }
`

const BubbleNode = styled.div`
  max-width: 240px;
  padding: ${(p) => p.theme.space[2]}px;
  border-radius: 12px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  &[data-orientation='left'] {
    align-self: flex-start;
    border-bottom-left-radius: 2px;
    background: ${(p) => p.theme.colors.gray[3]};
    color: ${(p) => p.theme.colors.text};

    &[data-previous='true'] {
      border-top-left-radius: 2px;
    }
  }

  &[data-orientation='right'] {
    align-self: flex-end;
    border-bottom-right-radius: 2px;
    background: ${(p) => p.theme.colors.primary.base};
    color: #fff;

    &[data-previous='true'] {
      border-top-right-radius: 2px;
    }
  }
`

const BubbleEdge = styled.div`
  width: 8px;
  height: 26px;

  &::after {
    display: block;
    position: absolute;
    top: 4px;
    width: 4px;
    height: 19px;
    background: ${(p) => p.theme.colors.bg.component};
    transition: background 0.3s;
    content: '';
  }

  &[data-orientation='left'] {
    margin-right: auto;
    background: ${(p) => p.theme.colors.gray[3]};

    &::after {
      left: 4px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &[data-orientation='right'] {
    margin-left: auto;
    background: ${(p) => p.theme.colors.primary.base};

    &::after {
      right: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`

const BubbleTrail = styled.div`
  width: min-content;

  &::before {
    display: block;
    margin-top: 2px;
    border-radius: 4px;
    width: 12px;
    height: 4px;
    content: '';
  }

  &::after {
    display: block;
    margin-top: 2px;
    border-radius: 4px;
    width: 4px;
    height: 4px;
    content: '';
  }

  &[data-orientation='left'] {
    margin-right: auto;

    &::before,
    &::after {
      margin-right: auto;
      background: ${(p) => p.theme.colors.gray[3]};
    }
  }

  &[data-orientation='right'] {
    margin-left: auto;

    &::before,
    &::after {
      margin-left: auto;
      background: ${(p) => p.theme.colors.primary.base};
    }
  }
`

MessageBubble.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
  name: PropTypes.string,
  isSender: PropTypes.bool,
  isAnimated: PropTypes.bool,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
}

export default MessageBubble
