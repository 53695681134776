import React from 'react'
import styled from 'styled-components'
import { Card, Empty, Flex } from '@beachfront/ui'

import { ExpressionDragItem } from '..'
import { useBooleanSegment } from '../../boolean-segment-context'

export const StyledCard = styled(Card)`
  border: dashed 3px ${(p) => p.theme.colors.purple[3]};
  transition: border-color 0.2s ease-in-out;

  ${(p) => p.isActive && `border-color: ${p.theme.colors.primary[4]};`}
`

const ExpressionGroup = ({ group, isContent = false }) => {
  const { activeItem } = useBooleanSegment()
  const isActive = activeItem?.id === group.id

  return (
    <StyledCard
      compact
      bordered={false}
      size='small'
      isActive={isActive}
      bodyStyle={{
        minHeight: '150px',
        minWidth: '175px',
        padding: '16px',
      }}
    >
      {group.children.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='Add Set or Group'
        />
      )}
      <Flex alignItems='flex-start' gap={3}>
        {group.children.map((groupItem) => (
          <ExpressionDragItem item={groupItem} key={groupItem.id} isContent />
        ))}
      </Flex>
    </StyledCard>
  )
}

export default ExpressionGroup
