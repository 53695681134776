import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, useField } from '@beachfront/ui/forms'
import { Card, Grid, Box, Radio, Switch } from '@beachfront/ui'
import isEqual from 'lodash/isEqual'

import { LabelTooltip } from '../../../components'
import { getDealTooltip } from '../@utils'

const ConfigurationForm = ({
  values,
  superAdmin,
  disableForm,
  initialValues,
  setIsDealUpdate,
}) => {
  const { input: contentAll } = useField('contentAll')
  const { input: contentTitle } = useField('contentTitle')
  const { input: contentChannel } = useField('contentChannel')
  const { input: contentEpisode } = useField('contentEpisode')
  const { input: contentSeason } = useField('contentSeason')
  const { input: contentNetwork } = useField('contentNetwork')
  const { input: contentSeries } = useField('contentSeries')
  const disabled = !!superAdmin || disableForm

  const onAllContentSwitch = (e) => {
    contentChannel.onChange(e)
    contentEpisode.onChange(e)
    contentSeason.onChange(e)
    contentTitle.onChange(e)
    contentNetwork.onChange(e)
    contentSeries.onChange(e)
  }

  useEffect(() => {
    if (!isEqual(values, initialValues)) {
      setIsDealUpdate(true)
    } else {
      setIsDealUpdate(false)
    }
  }, [JSON.stringify(values)])

  const onContentSwitch = (e, key) => {
    const contentValues = {
      contentChannel: !!values.contentChannel,
      contentEpisode: !!values.contentEpisode,
      contentSeason: !!values.contentSeason,
      contentTitle: !!values.contentTitle,
      contentNetwork: !!values.contentNetwork,
      contentSeries: !!values.contentSeries,
    }

    contentValues[key] = e
    const allValues = Object.values(contentValues)
    contentAll.onChange(!allValues.includes(false))
  }

  return (
    <Grid gap={3}>
      <Box columnSpan={[24, 24, 12]}>
        <Card inner title='Audience'>
          <Grid gap={3} columns={2}>
            <Field
              name='audienceIp'
              label='IP'
              extra={<LabelTooltip content={getDealTooltip('ip')} />}
            >
              <Radio.Group disabled={disabled}>
                <Radio.Button value='on'>On</Radio.Button>
                <Radio.Button value='off'>Off</Radio.Button>
                <Radio.Button value='truncate'>Truncated</Radio.Button>
              </Radio.Group>
            </Field>
            <Field
              name='audienceIfa'
              label='IFA'
              extra={<LabelTooltip content={getDealTooltip('ifa')} />}
            >
              <Switch disabled={disabled} />
            </Field>
          </Grid>
        </Card>
      </Box>
      <Box columnSpan={[24, 24, 12]}>
        <Card inner title='Location'>
          <Grid gap={3} columns={2}>
            <Field
              name='location'
              label='Lat/Lng'
              extra={<LabelTooltip content={getDealTooltip('location')} />}
            >
              <Radio.Group disabled={disabled}>
                <Radio.Button value='on'>On</Radio.Button>
                <Radio.Button value='off'>Off</Radio.Button>
                <Radio.Button value='truncate'>Truncated</Radio.Button>
              </Radio.Group>
            </Field>
            <Field
              name='zipcode'
              label='Zipcode'
              extra={<LabelTooltip content={getDealTooltip('zipcode')} />}
            >
              <Switch disabled={disabled} />
            </Field>
          </Grid>
        </Card>
      </Box>
      <Box columnSpan={24}>
        <Card
          inner
          title='Content'
          extra={
            <Field name='contentAll' label='Enable All' mb={0} inline>
              <Switch
                size='small'
                onChange={onAllContentSwitch}
                disabled={disabled}
              />
            </Field>
          }
        >
          <Grid columns={[3, 6]}>
            <Field
              name='contentTitle'
              label='Title'
              extra={<LabelTooltip content={getDealTooltip('contentTitle')} />}
            >
              <Switch
                onChange={(e) => onContentSwitch(e, 'contentTitle')}
                disabled={disabled}
              />
            </Field>
            <Field
              name='contentChannel'
              label='Channel'
              extra={<LabelTooltip content={getDealTooltip('channel')} />}
            >
              <Switch
                onChange={(e) => onContentSwitch(e, 'contentChannel')}
                disabled={disabled}
              />
            </Field>
            <Field
              name='contentEpisode'
              label='Episode'
              extra={<LabelTooltip content={getDealTooltip('episode')} />}
            >
              <Switch
                onChange={(e) => onContentSwitch(e, 'contentEpisode')}
                disabled={disabled}
              />
            </Field>
            <Field
              name='contentSeason'
              label='Season'
              extra={<LabelTooltip content={getDealTooltip('season')} />}
            >
              <Switch
                onChange={(e) => onContentSwitch(e, 'contentSeason')}
                disabled={disabled}
              />
            </Field>
            <Field
              name='contentNetwork'
              label='Network'
              extra={<LabelTooltip content={getDealTooltip('network')} />}
            >
              <Switch
                onChange={(e) => onContentSwitch(e, 'contentNetwork')}
                disabled={disabled}
              />
            </Field>
            <Field
              name='contentSeries'
              label='Series'
              extra={<LabelTooltip content={getDealTooltip('series')} />}
            >
              <Switch
                onChange={(e) => onContentSwitch(e, 'contentSeries')}
                disabled={disabled}
              />
            </Field>
          </Grid>
        </Card>
      </Box>
    </Grid>
  )
}

ConfigurationForm.defaultProps = {
  setIsDealUpdate: () => {},
}

ConfigurationForm.propTypes = {
  values: PropTypes.object,
  initialValues: PropTypes.object,
  setIsDealUpdate: PropTypes.func,
  disableForm: PropTypes.bool,
  superAdmin: PropTypes.bool,
}

export default ConfigurationForm
