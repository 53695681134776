import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router'
import { Flex, Box, Button, Table, Modal, useToast } from '@beachfront/ui'
import { EditFilled, DeleteFilled } from '@beachfront/ui/icons'
import { useTableQuery, useDisclosure } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { useContentSize, useFilters, useAppInfoList } from '../../../hooks'
import { localFilterKey } from '../../../components/filter'
import { appInfoColumns } from '../@constants'

import { AppInfoFilter, AppInfoModal } from '.'

const AppInfoTable = ({ superAdmin, disableForm }) => {
  const { id } = useParams()
  const { contentHeight } = useContentSize()
  const toast = useToast()
  const editModal = useDisclosure()
  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.APP_INFO)
  const [activeRecord, setActiveRecord] = useState()

  const appInfo = useAppInfoList({
    params: { id, admin: !!superAdmin },
  })

  const table = useTableQuery({
    data: appInfo.data ?? [],
  })

  const scroll = {
    y: contentHeight - 305,
  }

  useEffect(() => {
    table.setFilters(filters.populated)
  }, [filters.populated])

  const onEdit = (record) => {
    setActiveRecord(record)
    editModal.open()
  }

  const onDelete = (record) => {
    Modal.confirm({
      title: 'Delete App Info',
      content: `Are you sure you want to delete bundle "${record.overridedBundle}"?`,
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.deal.deleteInfo({ id, bundle: record.overridedBundle }).then(
          () => {
            toast.success({ title: 'App info deleted.' })
            appInfo.refetch()
          },
          (error) => {
            toast.error({ title: 'Unable to process request.' })
            throw error
          }
        )
      },
    })
  }

  const columns = [...appInfoColumns]

  if (!superAdmin) {
    columns.push({
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 75,
      render: (val, rec) => (
        <Flex gap={2}>
          <Button
            type='link'
            size='small'
            onClick={() => onEdit(rec)}
            icon={<EditFilled />}
            disabled={disableForm}
          />
          <Button
            danger
            type='link'
            size='small'
            onClick={() => onDelete(rec)}
            icon={<DeleteFilled />}
            disabled={disableForm}
          />
        </Flex>
      ),
    })
  }

  return (
    <>
      <Box my={2}>
        <AppInfoFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Box>
      <Table
        rowKey='overridedBundle'
        emptyText='No Bundles Found'
        columns={columns}
        dataSource={table.data}
        loading={appInfo.isFetching}
        scroll={scroll}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: false,
          total: table.total,
        }}
        filters={filters.populated}
        onFilter={(key) => filterRef.current?.addFilter(key)}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
      <AppInfoModal
        title='Edit App Info'
        dealId={id}
        record={activeRecord}
        isOpen={editModal.isOpen}
        onClose={editModal.close}
      />
    </>
  )
}

AppInfoTable.propTypes = {
  superAdmin: PropTypes.bool,
  disableForm: PropTypes.bool,
}

export default AppInfoTable
