import React, { useRef, useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import {
  Flex,
  Box,
  Card,
  Checkbox,
  Button,
  Heading,
  Modal,
  Input,
  ClickAwayListener,
  useToast,
} from '@beachfront/ui'
import {
  EyeOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
} from '@beachfront/ui/icons'
import { useDisclosure } from '@beachfront/ui/hooks'

import { api } from '../../../client-api'
import { getErrorMessage, isNonEmptyString } from '../../../utils'

import style from './documentation-layout.module.scss'

const DocumentationLayout = ({
  pdf,
  refetch,
  setSelectedKeyList,
  users,
  isSelected,
  setToggle,
}) => {
  const theme = useTheme()
  const toast = useToast()
  const preview = useDisclosure()
  const previewRef = useRef(null)

  const [viewState, setViewState] = useState({
    isHovered: false,
    isEditing: false,
    updatingDocTitle: false,
    tempHeading: pdf.title,
  })

  useEffect(() => {
    const doc =
      previewRef.current?.contentDocument ||
      previewRef.current?.contentWindow.document
    if (doc) {
      const body = doc.querySelector('body')
      body.style.overflow = 'hidden'
    }
  }, [])

  const toggleSelection = (e, selectedKey) => {
    const checked = e.target.checked
    setSelectedKeyList((prevList) => {
      const updatedList = checked
        ? [...new Set([...prevList, selectedKey])]
        : prevList.filter((key) => key !== selectedKey)
      setToggle((prevState) => ({
        ...prevState,
        countToggle: prevState.countToggle + (checked ? 1 : -1),
      }))
      return updatedList
    })
  }

  const handleSaveClick = () => {
    const params = {
      key: pdf.key,
      title: viewState.tempHeading.trim(),
    }
    setViewState((prevState) => ({
      ...prevState,
      updatingDocTitle: true,
    }))
    return api.support
      .updateDocs(params)
      .then(
        (res) => {
          setViewState((prevState) => ({
            ...prevState,
            isEditing: false,
          }))
          toast.success({
            title: res.data.msg || 'Document updated successfully.',
          })
          refetch?.()
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
          throw error
        }
      )
      .finally(() => {
        setViewState((prevState) => ({
          ...prevState,
          tempHeading: pdf.title,
        }))
      })
  }

  const handleEditClick = () => {
    setViewState((prevState) => ({
      ...prevState,
      isEditing: true,
    }))
  }

  const handleClickAway = () => {
    setViewState((prevState) => ({
      ...prevState,
      isEditing: false,
      tempHeading: pdf.title,
    }))
  }

  return (
    <>
      <Card
        inner
        size='small'
        style={{
          background: isSelected
            ? theme.colors.negative[1]
            : theme.colors.gray[1],
          border: isSelected ? `2px solid ${theme.colors.negative[3]}` : 'none',
        }}
      >
        <Flex gap={3} width='100%' height='100%' flexDirection='column' mt={13}>
          <Flex gap={3} mt={2}>
            <Box
              position='relative'
              onMouseEnter={() =>
                setViewState((prevState) => ({
                  ...prevState,
                  isHovered: true,
                }))
              }
              onMouseLeave={() =>
                setViewState((prevState) => ({
                  ...prevState,
                  isHovered: false,
                }))
              }
              style={{ cursor: 'pointer', width: '105px', height: '110px' }}
            >
              <ModalContent ref={previewRef} pdf={pdf} />
              {viewState.isHovered ? (
                <Box
                  position='absolute'
                  top='0'
                  left='0'
                  right='0'
                  bottom='0'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  onClick={() => preview.open()}
                  style={{
                    backgroundColor: theme.colors.alpha.black[6],
                    backdropFilter: 'blur(5px)',
                  }}
                >
                  <EyeOutlined style={{ fontSize: '20px', color: '#fff' }} />
                </Box>
              ) : null}
            </Box>
            <Flex
              gap={2}
              width='calc(100% - 106px)'
              flexDirection='column'
              justifyContent='space-between'
            >
              {viewState.isEditing && users.superUser ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Flex gap={2} flexDirection='column'>
                    <Input
                      value={viewState.tempHeading}
                      onChange={(e) =>
                        setViewState((prevState) => ({
                          ...prevState,
                          tempHeading: e.target.value,
                        }))
                      }
                    />
                    <Flex gap={2} justifyContent='flex-end'>
                      <Button
                        type='primary'
                        size='small'
                        icon={<CheckOutlined />}
                        onClick={handleSaveClick}
                        disabled={
                          viewState.tempHeading.trim() === pdf.title ||
                          !isNonEmptyString(viewState.tempHeading.trim()) ||
                          viewState.updatingDocTitle
                        }
                      />
                      <Button
                        size='small'
                        icon={<CloseOutlined />}
                        onClick={() =>
                          setViewState((prevState) => ({
                            ...prevState,
                            isEditing: false,
                            tempHeading: pdf.title,
                          }))
                        }
                      />
                    </Flex>
                  </Flex>
                </ClickAwayListener>
              ) : (
                <Flex gap={2}>
                  <Heading
                    level={5}
                    title={pdf.title}
                    ellipsis
                    style={{
                      cursor: users.superUser ? 'pointer' : '',
                    }}
                    onClick={users.superUser ? handleEditClick : null}
                  >
                    {pdf.title}
                  </Heading>
                  {users.superUser ? (
                    <EditOutlined onClick={handleEditClick} />
                  ) : null}
                </Flex>
              )}
              <Box width='140px'>
                <Button
                  type='primary'
                  block
                  onClick={() => window.open(pdf.url, '_blank')}
                >
                  Download
                </Button>
              </Box>
            </Flex>
            {users.superUser ? (
              <Box position='absolute' top='5px' right='5px'>
                <Checkbox
                  checked={isSelected}
                  onChange={(e) => toggleSelection(e, pdf.key)}
                />
              </Box>
            ) : null}
          </Flex>
        </Flex>
      </Card>
      {preview.isOpen ? (
        <Modal
          wrapClassName={style.previewModal}
          visible={preview.isOpen}
          onCancel={() => preview.close()}
          footer={null}
          centered
        >
          <ModalContent pdf={pdf} />
        </Modal>
      ) : null}
    </>
  )
}

const ModalContent = forwardRef(({ pdf }, ref) => (
  <iframe
    ref={ref}
    src={pdf.url}
    title={pdf.title}
    width='100%'
    height='100%'
    style={{ border: 'none' }}
  />
))

ModalContent.displayName = 'ModalContent'
ModalContent.propTypes = {
  pdf: PropTypes.object,
}

DocumentationLayout.propTypes = {
  pdf: PropTypes.object,
  isSelected: PropTypes.bool,
  refetch: PropTypes.func,
  setSelectedKeyList: PropTypes.func,
  users: PropTypes.object,
  setToggle: PropTypes.func,
}

export default DocumentationLayout
