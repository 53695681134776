import { createEnum } from '../utils'

export const MEDIA_TYPE = createEnum({
  VIDEO: {
    key: 'video',
    name: 'Video',
  },
  DISPLAY: {
    key: 'display',
    name: 'Display',
  },
})
