import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Box, Button, Modal, useToast } from '@beachfront/ui'
import { DeleteFilled } from '@beachfront/ui/icons'

import { api } from '../../client-api'
import { getErrorMessage } from '../../utils'
import { useContentSize, useFilters, useInfiniteTable } from '../../hooks'
import { localFilterKey } from '../../components/filter'
import { PageHeader, InfiniteTable, NetworkError } from '../../components'

import { dealLibraryColumns } from './@constants'
import { DealFilter } from './@components'

const AdminDealLibrary = () => {
  const { contentHeight } = useContentSize()
  const navigate = useNavigate()
  const toast = useToast()

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.ADMIN_DEAL_LIBRARY, {
    schema: {
      createDate: 'date',
      updateDate: 'date',
    },
  })

  const table = useInfiniteTable({
    queryKey: ['deal-library', 'admin'],
    queryFn: api.dspDeal.admin.getLibraryDeals,
    filters: filters.populated,
    params: { featured: false },
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 260,
  }

  const onDelete = (record) => {
    Modal.confirm({
      title: 'Delete Deal',
      content: `Are you sure you want to delete deal "${record.title}"?`,
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.dspDeal.admin.deleteDeal(record.id).then(
          () => {
            toast.success({ title: 'Deal deleted.' })
            table.refetch()
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
            throw error
          }
        )
      },
    })
  }

  const columns = [
    ...dealLibraryColumns,
    {
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 45,
      render: (val, rec) => (
        <Button
          danger
          type='link'
          size='small'
          onClick={() => onDelete(rec)}
          icon={<DeleteFilled />}
        />
      ),
    },
  ]

  const headerActions = (
    <Button type='primary' onClick={() => navigate('/deal/library/create')}>
      Create Deal
    </Button>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <DealFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title='Deal Library' actions={headerActions} />
      <Box mt={2}>
        {!table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <InfiniteTable
            rowKey='id'
            emptyText='No Deals Found'
            columns={columns}
            toolbar={toolbar}
            scroll={scroll}
            filters={filters.populated}
            onFilter={(key) => filterRef.current?.addFilter(key)}
            {...table.props}
          />
        )}
      </Box>
    </>
  )
}

export default AdminDealLibrary
