import http from '../http'

const ENDPOINT = 'insight/'

export default {
  list() {
    return http.get(`${ENDPOINT}list`)
  },
  widgetDetail({ id, params, signal }) {
    return http.get(`insight/widget/detail/${id}`, { params, signal })
  },
}
