import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Tag } from '@beachfront/ui'

const AppWebPreviewTags = ({ visible, values }) => {
  if (!visible) {
    return null
  }

  const tags = []
  const bundleCount =
    values.bundle?.length ?? values.bundleMedia?.ids?.length ?? 0
  const domainCount =
    values.domain?.length ?? values.domainMedia?.ids?.length ?? 0

  if (bundleCount) {
    tags.push(
      bundleCount === 1 ? (
        <Tag
          key='bundle'
          color='processing'
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: domainCount ? '50%' : '100%',
          }}
        >
          {values.bundle?.[0] ?? values.bundleMedia.ids[0].title}
        </Tag>
      ) : (
        <Tag key='bundle' color='processing' flex='none'>
          {bundleCount} {values.bundle?.length ? 'Bundles' : 'Bundle Lists'}
        </Tag>
      )
    )
  }

  if (domainCount) {
    tags.push(
      domainCount === 1 ? (
        <Tag
          key='domain'
          color='processing'
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: bundleCount ? '50%' : '100%',
          }}
        >
          {values.domain?.[0] ?? values.domainMedia.ids[0].title}
        </Tag>
      ) : (
        <Tag key='domain' color='processing' flex='none'>
          {domainCount} {values.domain?.length ? 'Domains' : 'Domain Lists'}
        </Tag>
      )
    )
  }

  if (tags.length) {
    return (
      <Flex gap={1} maxWidth={200} justifyContent='flex-end'>
        {tags}
      </Flex>
    )
  }

  return null
}

AppWebPreviewTags.propTypes = {
  visible: PropTypes.bool,
  values: PropTypes.object,
}

export default AppWebPreviewTags
