export default {
  budget: 'Delivery Budget',
  frequency: 'Delivery Frequency',
  start: 'Delivery Start Date & Time',
  end: 'Delivery End Date & Time',
  impression: 'Delivery Impression',
  title: 'Setting Title',
  dealId: 'Setting Deal ID',
  dealDspId: 'Setting DSP',
  rate: 'Setting Floor CPM',
  marginType: 'Setting Margin Type',
  marginValue: 'Setting Margin',
  dayPartingSchedules: 'Delivery Scheduling',
}
