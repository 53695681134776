import React from 'react'
import { Backdrop, Heading, Flex, Alert } from '@beachfront/ui'
import moment from 'moment'

import { isNotEmptyObject } from '../../../utils'
import { useUserData } from '../../../hooks'

const HelpCenterHeader = () => {
  const [user] = useUserData()
  return (
    <>
      {isNotEmptyObject(user.systemMessage) &&
      moment(user.systemMessage.expiration).valueOf() > moment().valueOf() ? (
        <Alert
          message={user?.systemMessage.message}
          type={user?.systemMessage.type}
          style={{
            position: 'sticky',
            top: '40px',
            zIndex: '1',
            margin: '0 -32px',
          }}
          banner
          showIcon
          closable={true}
        />
      ) : null}
      <Flex mx={-4} position='relative' height={275}>
        <Backdrop preset='liquid' />
        <Flex
          alignItems='center'
          height='100%'
          justifyContent='center'
          left='0'
          position='absolute'
          top='0'
          width='100%'
        >
          <Heading color='white' level={1}>
            Beachfront Select Support
          </Heading>
        </Flex>
      </Flex>
    </>
  )
}

export default HelpCenterHeader
