import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Result } from '@beachfront/ui'

import { isNotEmptyArray, isNotEmptyObject } from '../../../utils'
import {
  SimpleBarChartWidget,
  DonutChartWidget,
  StackedBarChartWidget,
  MultipleBarChartWidget,
  AreaChartWidget,
  LineChartWidget,
  ComposedBarLineChartWidget,
} from '../../../components/widget'

const InsightFullViewModal = ({
  index,
  responseData,
  isOpen,
  onCancel,
  afterClose,
}) => {
  return (
    <Modal
      title={responseData?.title}
      visible={isOpen}
      onCancel={onCancel}
      afterClose={afterClose}
      width='60%'
      footer={null}
      bodyStyle={{ height: 400, overflow: 'auto' }}
      centered
    >
      <>
        {responseData?.type === 'BAR' ? (
          <>
            {isNotEmptyArray(responseData.data) ? (
              <SimpleBarChartWidget
                data={responseData.data}
                unit={responseData.unit}
                vertical={responseData.vertical}
                xUnit={responseData.xUnit}
                xLabel={responseData.xLabel}
                colorOffset={index}
                cardBodyHeight='100%'
                bordered={false}
              />
            ) : (
              <Result
                height='100%'
                status='warning'
                description='No Data Found'
              />
            )}
          </>
        ) : null}
        {responseData?.type === 'PIE' ? (
          <>
            {isNotEmptyArray(responseData.data) &&
            isValidPieData(responseData.data) ? (
              <DonutChartWidget
                data={responseData.data}
                total={responseData.total}
                totalTitle='Total Rejected Bids'
                detailLabel='Reasons'
                bodyStyle={{ height: '100%' }}
                bordered={false}
                compact
              />
            ) : (
              <Result
                height='100%'
                status='warning'
                description='No Data Found'
              />
            )}
          </>
        ) : null}
        {responseData?.type === 'STACKED_BAR' ? (
          <>
            {isNotEmptyArray(responseData.data) ? (
              <StackedBarChartWidget
                data={responseData.data}
                range={responseData.range}
                yUnit={responseData.yUnit}
                bordered={false}
                compact
              />
            ) : (
              <Result
                height='100%'
                status='warning'
                description='No Data Found'
              />
            )}
          </>
        ) : null}
        {responseData?.type === 'AREA' ? (
          <>
            {isNotEmptyObject(responseData.data) ? (
              <AreaChartWidget
                data={responseData.data}
                toolTipLabel={responseData.title}
                bordered={false}
                compact
              />
            ) : (
              <Result
                height='100%'
                status='warning'
                description='No Data Found'
              />
            )}
          </>
        ) : null}
        {responseData?.type === 'LINE' ? (
          <>
            {isNotEmptyArray(responseData.data) ? (
              <LineChartWidget
                data={responseData.data}
                bordered={false}
                compact
              />
            ) : (
              <Result
                height='100%'
                status='warning'
                description='No Data Found'
              />
            )}
          </>
        ) : null}
        {responseData?.type === 'BAR_LINE' ? (
          <>
            {isNotEmptyArray(responseData.data) ? (
              <ComposedBarLineChartWidget
                data={responseData.data}
                bordered={false}
                compact
              />
            ) : (
              <Result
                height='100%'
                status='warning'
                description='No Data Found'
              />
            )}
          </>
        ) : null}
        {responseData?.type === 'MULTIPLE_BAR' ? (
          <>
            {isNotEmptyArray(responseData.data) ? (
              <MultipleBarChartWidget
                data={responseData.data}
                range={responseData.range}
                yUnit={responseData.yUnit}
                bordered={false}
                compact
              />
            ) : (
              <Result
                height='100%'
                status='warning'
                description='No Data Found'
              />
            )}
          </>
        ) : null}
      </>
    </Modal>
  )
}

const isValidPieData = (pieData = []) => {
  for (const element of pieData) {
    if (element.value) {
      return true
    }
  }
  return false
}

InsightFullViewModal.propTypes = {
  index: PropTypes.number,
  responseData: PropTypes.object,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  afterClose: PropTypes.func,
}

export default InsightFullViewModal
