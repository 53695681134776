import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from '@beachfront/ui'

import { SegmentFilters, SegmentList } from '..'
import { useUserData } from '../../../../hooks'
import { isNonEmptyString } from '../../../../utils'
import { useBooleanSegment } from '../booleanSegment/boolean-segment-context'

// ALL API logic pulled from AudienceModal
// Look at how to clean that up a bit
const AvailableSegments = ({ pmpAvails, providers }) => {
  const [user] = useUserData()
  const isOwned = providers.some((e) => e.owned)
  const { params, setParams, INITIAL_PAGINATION } = useBooleanSegment()

  const [activeTab, setActiveTab] = useState(
    isOwned ? 'owned' : !user.sharedSegment ? 'uploaded' : 'shared'
  )

  const showSharedTab = () =>
    providers.some((e) => !e.owned && e.key !== 'ALL') && user.sharedSegment

  const onTabChange = (key) => {
    setActiveTab(key)
    setParams(
      !isNonEmptyString(params.q) && (params.p !== 0 || activeTab !== key)
        ? { ...INITIAL_PAGINATION }
        : { ...INITIAL_PAGINATION, q: params.q }
    )
  }

  const tabList = []

  if (isOwned) {
    tabList.push({ key: 'owned', tab: 'Owned', disabled: false })
  }

  if (!pmpAvails && user.uploadedSegment) {
    tabList.push({ key: 'uploaded', tab: 'Uploaded' })
  }

  if (showSharedTab()) {
    tabList.push({ key: 'shared', tab: 'Shared' })
  }

  return (
    <Card
      title='Available Segments'
      tabPlacement='right'
      tabList={tabList}
      activeTabKey={activeTab}
      onTabChange={onTabChange}
      height={552}
    >
      <SegmentFilters activeTab={activeTab} providers={providers} />
      <SegmentList activeTab={activeTab} pmpAvails={pmpAvails} />
    </Card>
  )
}

AvailableSegments.propTypes = {
  pmpAvails: PropTypes.bool,
  providers: PropTypes.array,
}

export default AvailableSegments
