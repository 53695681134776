import React from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from '@beachfront/ui'
import { KnobsOutlined } from '@beachfront/ui/icons'

const ColumnConfig = ({ columns, value, onChange, ...rest }) => {
  return (
    <Table.ColumnConfig
      columns={columns}
      value={value}
      onChange={onChange}
      {...rest}
    >
      <Button icon={<KnobsOutlined />} />
    </Table.ColumnConfig>
  )
}

ColumnConfig.propTypes = {
  columns: PropTypes.array,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
}

export default ColumnConfig
