import React from 'react'
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom'
import {
  Backdrop,
  Box,
  Button,
  Input,
  BrandCard,
  Divider,
  Flex,
} from '@beachfront/ui'
import { LockOutlined } from '@beachfront/ui/icons'
import { Form, FORM_ERROR, SubmitError, Field } from '@beachfront/ui/forms'

import BeachfrontHorizontalWhiteLogo from '../../assets/bf-logo-horizontal-white.png'
import { resolveProp, getErrorMessage } from '../../utils'
import { authService } from '../../context'

import { resetPasswordValidate } from './@utils'

const ResetPassword = () => {
  const navigate = useNavigate()
  const location = useLocation()
  if (!location.state?.email) {
    return <Navigate to='/verify-email' />
  }
  const onSubmit = (values) => {
    return authService
      .resetPassword({
        data: {
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          transactionId: location.state.transactionId,
        },
      })
      .then(
        (response) => {
          const pathname = resolveProp(
            location,
            'state.referrer.pathname',
            '/reset-success'
          )
          navigate(pathname, {
            state: {
              msg: response.data.msg,
            },
          })
        },
        (error) => {
          return { [FORM_ERROR]: getErrorMessage(error) }
        }
      )
  }

  return (
    <Backdrop preset='prism'>
      <Box width={450}>
        <Form validate={resetPasswordValidate} onSubmit={onSubmit}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <BrandCard
                logo={BeachfrontHorizontalWhiteLogo}
                fontSize={3}
                title='Reset Password'
                mode='dark'
              >
                <SubmitError mb={3} />
                <Box mb={3} fontSize={2}>
                  OTP verified successfully, please reset your password.
                </Box>
                <Field name='newPassword'>
                  <Input.Password
                    type='password'
                    size='large'
                    placeholder='New Password'
                    prefix={<LockOutlined />}
                  />
                </Field>
                <Field name='confirmPassword'>
                  <Input.Password
                    type='password'
                    size='large'
                    placeholder='Confirm Password'
                    prefix={<LockOutlined />}
                  />
                </Field>
                <Button
                  block
                  type='primary'
                  htmlType='submit'
                  size='large'
                  loading={submitting}
                >
                  Reset Password
                </Button>
                <Divider />
                <Flex alignItems='center' justifyContent='flex-end'>
                  <Link to='/login'>Back to login</Link>
                </Flex>
              </BrandCard>
            </form>
          )}
        </Form>
      </Box>
      <Box pt={3} color='white'>
        &copy; {new Date().getFullYear()} Beachfront Media LLC
      </Box>
    </Backdrop>
  )
}

export default ResetPassword
