import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Tag, Text, Label, Help } from '@beachfront/ui'
import { useField } from '@beachfront/ui/forms'
import { useTheme } from 'styled-components'
import { useDisclosure } from '@beachfront/ui/hooks'

import { isNotEmptyArray, isNotEmptyObject } from '../../../utils'

import { AttachMediaModal } from '.'

const MediaListField = ({
  data,
  values,
  initialValues,
  isMediaPresent,
  disabled,
}) => {
  const theme = useTheme()
  const { input } = useField(data.fieldName)
  const mediaListModal = useDisclosure()
  const [mediaListData, setMediaListData] = useState(null)
  const [selectedMediaList, setSelectedMediaList] = useState(
    values[data.fieldName]
  )

  const onMediaListAdd = (data) => {
    setMediaListData(data)
    mediaListModal.open()
  }

  const onCloseMediaModal = () => {
    setMediaListData(null)
  }

  const onSuccess = (callbackData) => {
    const idsData = callbackData.rowData.map((item) => {
      return {
        id: item.id,
        title: item.title || item.data?.title || 'NA',
      }
    })
    setSelectedMediaList((prevState) => ({
      ...prevState,
      ids: idsData,
    }))
    input.onChange({
      included: callbackData.radioValue === 'include',
      ids: idsData,
    })
  }

  const initialRowValues = {
    rowKeys: [],
    rowData: [],
  }

  if (isNotEmptyArray(initialValues[data.fieldName]?.ids)) {
    initialRowValues.rowKeys = initialValues[data.fieldName].ids.map(
      (item) => item.id
    )
    initialRowValues.rowData = [...initialValues[data.fieldName].ids]
  }

  return (
    <>
      <Flex justifyContent='space-between'>
        <Label>{data.label}</Label>
        {isNotEmptyArray(values[data.fieldName]?.ids) ? (
          <Text
            fontSize={1}
            color={
              values[data.fieldName]?.included
                ? 'positive.base'
                : 'negative.base'
            }
          >
            {values[data.fieldName]?.included ? 'Allow List' : 'Block List'}
          </Text>
        ) : null}
      </Flex>
      <Box
        mb={isMediaPresent ? 2 : 3}
        px='8px'
        py='6px'
        minHeight={32}
        bg={disabled ? 'bg.base' : 'bg.component'}
        border='base'
        borderRadius='base'
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        onClick={() =>
          disabled
            ? null
            : onMediaListAdd({
                key: data.key,
                title: `Attach ${data.label} Lists`,
              })
        }
      >
        <Flex gap={1} flexWrap='wrap'>
          {isNotEmptyArray(values[data.fieldName]?.ids) ? (
            values[data.fieldName].ids.map((listItem) => (
              <Tag key={listItem.id} color={theme.colors.primary.base}>
                {listItem.title}
              </Tag>
            ))
          ) : (
            <Text>All</Text>
          )}
        </Flex>
      </Box>
      {isMediaPresent ? (
        <Help status='default' mb={3}>
          Bundle and Domain can&apos;t be selected together.
        </Help>
      ) : null}
      {mediaListModal.isOpen ? (
        <AttachMediaModal
          data={data}
          mediaData={mediaListData}
          onClose={onCloseMediaModal}
          initialRowValues={initialRowValues}
          initialRadioValue={getInitialRadioValue(
            initialValues[data.fieldName]
          )}
          selectedMediaList={selectedMediaList}
          onSuccess={onSuccess}
        />
      ) : null}
    </>
  )
}

const getInitialRadioValue = (data) => {
  if (isNotEmptyObject(data)) {
    return data.included ? 'include' : 'exclude'
  }
  return 'include'
}

MediaListField.defaultProps = {
  isMediaPresent: false,
}

MediaListField.propTypes = {
  data: PropTypes.object,
  isMediaPresent: PropTypes.bool,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  disabled: PropTypes.bool,
}

export default MediaListField
