import React from 'react'
import { Line, LineChart } from 'recharts'

const TinyLineChart = ({ item, cc }) => {
  return (
    <LineChart width={70} height={30} data={item}>
      <Line
        type='monotone'
        dataKey='value'
        stroke={cc}
        strokeWidth={2}
        r={0}
        isAnimationActive={false}
      />
    </LineChart>
  )
}

export default TinyLineChart
