import React from 'react'
import PropTypes from 'prop-types'
import { Result, Button } from '@beachfront/ui'

const NetworkError = ({ buttonLabel, onAction, ...rest }) => {
  return (
    <Result py={5} status='error' {...rest}>
      {onAction ? <Button onClick={onAction}>{buttonLabel}</Button> : null}
    </Result>
  )
}

NetworkError.defaultProps = {
  description: 'No Data Available',
  buttonLabel: 'Retry',
}

NetworkError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  onAction: PropTypes.func,
}

export default NetworkError
