import { createEnum } from '../utils'

export const LIST_INPUT_TYPE = createEnum({
  UPLOAD: {
    name: 'Upload CSV',
  },
  SELECT: {
    name: 'Select From List',
  },
  MANUAL: {
    name: 'Manual Input',
  },
})
