import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Flex, Card } from '@beachfront/ui'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts'

import { getChartColors } from '../../utils'
import { AxisLabel } from '../chart'

import { formatTick } from './widget-util'
import { CustomTooltip } from './custom-tooltip'
import { CustomLegend } from './custom-legend'

export const MultipleBarChartWidget = ({
  data,
  title,
  colors,
  cardBodyHeight,
  yUnit,
  ...rest
}) => {
  const theme = useTheme()
  const chartColors = colors ?? getChartColors(theme.colors)

  const barData = useMemo(() => {
    if (!data) {
      return []
    }
    return data.map((dt) => {
      dt.chartData?.forEach((el) => {
        dt[el.key] = el.value
      })
      return dt
    })
  }, [data])

  return (
    <Card
      title={title}
      bodyStyle={{ height: cardBodyHeight ?? '300px' }}
      {...rest}
    >
      <Flex width='100%' height='100%' fontFamily='secondary' fontSize={0}>
        <ResponsiveContainer>
          <BarChart
            data={barData}
            maxBarSize={30}
            margin={{ top: 15, right: 24, left: 15, bottom: 0 }}
          >
            <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
            <XAxis
              strokeWidth='1'
              dataKey='label'
              interval='preserveStartEnd'
              axisLine={{ stroke: theme.colors.gray[4] }}
              tickLine={{ stroke: theme.colors.gray[4] }}
              tick={{ fill: theme.colors.gray[6] }}
            />
            <YAxis
              width={50}
              axisLine={{ stroke: theme.colors.gray[4] }}
              tickLine={false}
              tick={{ fill: theme.colors.gray[6] }}
              label={
                data.unit ? (
                  <AxisLabel
                    axisType='y'
                    x={25}
                    y={150}
                    width={0}
                    height={0}
                    fill={theme.colors.text}
                  >
                    {data.unit}
                  </AxisLabel>
                ) : undefined
              }
              tickFormatter={(val) => formatTick(val, false, yUnit)}
            />
            <Tooltip
              isAnimationActive={false}
              cursor={{ fill: theme.colors.gray[3] }}
              wrapperStyle={{ zIndex: 1 }}
              content={<CustomTooltip unit={yUnit} />}
            />
            <Legend content={<CustomLegend />} />
            {barData[0]?.chartData?.map((dt, i) => (
              <Bar
                key={i}
                dataKey={dt.key}
                name={dt.name}
                fill={chartColors[i % chartColors.length]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Flex>
    </Card>
  )
}

MultipleBarChartWidget.propTypes = {
  data: PropTypes.array,
  title: PropTypes.node,
  colors: PropTypes.array,
  cardBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  yUnit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}
