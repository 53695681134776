import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useSegmentProviders = ({ enabled } = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['segment-providers'],
    queryFn: async () => {
      const { data } = await api.segment.getProviderList()
      return data
    },
    staleTime: 1000 * 60 * 60,
    enabled,
  })

  return {
    providers: data || [],
    ...rest,
  }
}
