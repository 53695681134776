import React, { useRef } from 'react'
import { Flex, Table, Modal, Button, useToast } from '@beachfront/ui'
import { DeleteFilled } from '@beachfront/ui/icons'

import { api } from '../../../../client-api'
import { useContentSize, useFilters, useServerTable } from '../../../../hooks'
import { NetworkError } from '../../../../components'
import { localFilterKey } from '../../../../components/filter'
import { metaColumns } from '../@constants'
import { getErrorMessage } from '../../../../utils'

import { MetaFilter } from '.'

const MetaTable = () => {
  const { contentWidth, contentHeight } = useContentSize()
  const toast = useToast()
  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.ADMIN_META)

  const table = useServerTable({
    queryKey: 'admin-meta',
    queryFn: api.admin.meta.getAll,
    filters: filters.populated,
    schema: {
      data: 'data',
      total: 'total',
      fields: {
        created: 'date',
      },
    },
    defaultPageSize: 50,
  })

  const scroll = {
    y: contentHeight - 260,
    x: contentWidth - 300,
  }

  const onDelete = (record) => {
    Modal.confirm({
      title: 'Delete Meta',
      content: 'Are you sure you want to delete the selected meta?',
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.admin.meta.delete(record.id).then(
          () => {
            toast.success({ title: 'Meta deleted.' })
            table.refetch()
          },
          (error) => {
            toast.error({ title: 'Unable to process request.' })
            throw error
          }
        )
      },
    })
  }

  const columns = [
    ...metaColumns,
    {
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 45,
      render: (val, rec) => (
        <Button
          danger
          type='link'
          size='small'
          onClick={() => onDelete(rec)}
          icon={<DeleteFilled />}
        />
      ),
    },
  ]

  if (!table.isFetching && table.error) {
    return (
      <NetworkError
        description={getErrorMessage(table.error)}
        onAction={table.refetch}
      />
    )
  }

  const toolbar = () => (
    <Table.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <MetaFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </Table.Toolbar>
  )

  return (
    <Table
      rowKey='metaId'
      emptyText='No Meta'
      columns={columns}
      toolbar={toolbar}
      dataSource={table.data}
      loading={table.isFetching}
      pagination={{
        ...table.query.pagination,
        hideOnSinglePage: false,
        total: table.total,
      }}
      scroll={scroll}
      filters={filters.populated}
      onFilter={(key) => filterRef.current?.addFilter(key)}
      onChange={(e) => {
        table.setPagination(e.pagination)
        table.setSorter(e.sorter)
        table.setSelectedRowKeys([])
      }}
    />
  )
}

export default MetaTable
