import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../../client-api'
import {
  PageHeader,
  NetworkError,
  CommonSpinner,
  DirtyPrompt,
} from '../../../../components'
import { getErrorMessage } from '../../../../utils'
import { useFetch } from '../../../../hooks'
import { validateMetaForm } from '../@utils'

import { MetaForm } from '.'

const DetailMeta = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const metaResponse = useFetch({
    request: api.admin.meta.get,
    payload: id,
  })

  const onSubmit = (values, form) => {
    api.admin.meta.update(values).then(
      () => {
        toast.success({ title: 'Meta updated.' })
        setTimeout(() => {
          form.reset()
          navigate('..', { relative: 'path' })
        }, 300)
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  if (metaResponse.loading) {
    return <CommonSpinner />
  }

  if (metaResponse.error) {
    return (
      <NetworkError
        description={metaResponse.error || 'Detail not found'}
        onAction={metaResponse.refresh}
      />
    )
  }

  return (
    <Form
      initialValues={metaResponse.data}
      validate={validateMetaForm}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, dirty, form }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Meta - Details'
            actions={
              <>
                <Button onClick={() => form.reset()} disabled={!dirty}>
                  Reset
                </Button>
                <Button
                  type='primary'
                  loading={submitting}
                  disabled={!dirty}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3} width={[1, 1, 2 / 3]}>
            <MetaForm disable={true} />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default DetailMeta
