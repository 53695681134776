import React, { createContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@beachfront/ui'
import {
  useLocalStorage,
  useAsyncEffect,
  useCallbackRef,
} from '@beachfront/ui/hooks'

import { api } from '../client-api'
import { parseFields } from '../utils'
import { USER_KEY } from '../constants'
import { ACCOUNT_TYPE } from '../enums'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage(USER_KEY, null)
  const [loading, setLoading] = useState(true)

  useAsyncEffect(async () => {
    try {
      const res = await api.user.session()

      if (res.data) {
        parseFields(res.data, { accountType: ACCOUNT_TYPE })
        setUser(res.data)
        localStorage.setItem(USER_KEY, JSON.stringify(res.data))
      }
    } catch (err) {
      // console.error(err)
    }

    setLoading(false)
  }, [])

  const syncUser = useCallbackRef((data) => setUser({ ...user, ...data }))
  const value = useMemo(() => ({ user, syncUser }), [user, syncUser])

  if (loading) {
    return <Spinner size='large' mask />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}
