import React from 'react'

import { MEDIA_LIST_TYPE } from '../../enums'
import { localFilterKey } from '../../components/filter'

import { default as MediaLists } from './media-lists'

const BundleMediaList = () => {
  return (
    <MediaLists
      title='Media Lists - Bundles'
      mediaListType={MEDIA_LIST_TYPE.BUNDLE.key}
      filterKey={localFilterKey.BUNDLE_MEDIA_LISTS}
    />
  )
}

export default BundleMediaList
