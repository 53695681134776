import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import arrayMutators from 'final-form-arrays'
import moment from 'moment'

import { api } from '../../../client-api'
import { useReportConfig, useReportById } from '../../../hooks'
import { getErrorMessage } from '../../../utils'
import { TIMEZONE } from '../../../enums'
import {
  PageHeader,
  NetworkError,
  DirtyPrompt,
  CommonSpinner,
} from '../../../components'
import { validateReportForm } from '../@utils'

import { ReportForm } from '.'

const UpdateReport = ({ shared = false }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const toast = useToast()

  const configQuery = useReportConfig()
  const reportQuery = useReportById({
    params: { id },
  })

  const initialValues = useMemo(() => {
    const values = {}

    if (reportQuery.data) {
      Object.assign(values, reportQuery.data)

      if (values.startDate) {
        values.startDate = moment(values.startDate, 'YYYY-MM-DD')
      }

      if (values.endDate) {
        values.endDate = moment(values.endDate, 'YYYY-MM-DD')
      }

      if (values.conditions) {
        values.conditions = values.conditions.map((c) => ({
          dimension: c.dimension,
          operation: c.operation,
          value: c.value,
        }))
      }
    }
    return values
  }, [reportQuery.data])

  const onSubmit = (values, form) => {
    const payload = { ...values }

    if (values.timezone !== TIMEZONE.CUSTOM.key) {
      delete payload.offset
    }

    if (values.startDate) {
      payload.startDate = values.startDate.format('YYYY-MM-DD')
    }

    if (values.endDate) {
      payload.endDate = values.endDate.format('YYYY-MM-DD')
    }

    return api.report.update({ data: payload, id }).then((res) => {
      if (res.data?.success) {
        queryClient.invalidateQueries({ queryKey: ['reports'] })
        toast.success({ title: 'Report updated.' })
        form.initialize(values)
        setTimeout(() => navigate('..', { relative: 'path' }), 300)
      } else {
        // TODO Validate report title before form is submitted
        toast.error({ title: 'Report title already exists.' })
      }
    })
  }

  const onRefetch = () => {
    configQuery.refetch()
    reportQuery.refetch()
  }

  if (configQuery.isFetching || reportQuery.isFetching) {
    return <CommonSpinner />
  }

  if (configQuery.error || reportQuery.error) {
    const error = configQuery.error || reportQuery.error
    return (
      <NetworkError description={getErrorMessage(error)} onAction={onRefetch} />
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateReportForm}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
    >
      {({ handleSubmit, submitting, dirty }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Report - Detail'
            actions={
              <>
                <Button onClick={() => navigate('..', { relative: 'path' })}>
                  Cancel
                </Button>
                {!shared ? (
                  <Button
                    type='primary'
                    loading={submitting}
                    disabled={!dirty || submitting}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                ) : null}
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3}>
            <ReportForm config={configQuery.data} disabled={shared} />
          </Box>
        </form>
      )}
    </Form>
  )
}

UpdateReport.propTypes = {
  shared: PropTypes.bool,
}

export default UpdateReport
