import React, { useState } from 'react'
import ReactGA from 'react-ga4'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { Button, Flex, Grid, Input, Modal, Text } from '@beachfront/ui'
import { useKeyPress, useClipboard } from '@beachfront/ui/hooks'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Field, useForm, useFormState } from '@beachfront/ui/forms'
import { CopyOutlined } from '@beachfront/ui/icons'

import { api } from '../../../../client-api'
import { useSegmentProviders } from '../../../../hooks'
import { renderExpressionItem } from '../../@utils'
import { AvailableSegments } from '..'

import {
  ExpressionBuilder,
  ExpressionModalFooter,
  ExpressionModalHelp,
} from './@components'
import { useBooleanSegment } from './boolean-segment-context'

const BooleanSegment = ({ pmpAvails, formDisable }) => {
  const [isBuilderOpen, setIsBuilderOpen] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const { providers, isLoading: isProvidersLoading } = useSegmentProviders()
  const {
    expressionV2,
    isExpanded,
    setIsExpanded,
    setExpressionV2,
    setIsHelpShown,
    stateHistory,
  } = useBooleanSegment()

  const form = useForm()
  const { values } = useFormState()
  const { copy, hasCopied } = useClipboard(values?.audienceBooleanLogic?.value)

  const onSave = async () => {
    setValidationError(null)
    setIsValidating(true)

    // Convert the array to a string and submit
    const parsedString = expressionV2
      .map((item) => renderExpressionItem(item))
      .join('')

    // Validate segment and add to form
    api.booleanSegment
      .check({
        expression: parsedString,
      })
      .then((resp) => {
        if (!resp.data?.success) {
          ReactGA.event({
            category: 'audience_boolean_logic',
            action: 'validate_btn',
            value: 0,
            label: parsedString,
          })

          throw new Error(
            resp.data?.msg || 'Unknown validation error. Please try again'
          )
        }
        form.change('audienceBooleanLogic.value', parsedString)
        form.change('audienceBooleanLogic.client', expressionV2)

        setIsBuilderOpen(false)
        setIsValidating(false)
        ReactGA.event({
          category: 'audience_boolean_logic',
          action: 'validate_btn',
          value: 1,
          label: parsedString,
        })
      })
      .catch((err) => {
        setValidationError(err.toString())
        setIsValidating(false)
      })
  }

  const onModalCancel = () => {
    setIsBuilderOpen(false)
    const updatedExpression = cloneDeep(values.audienceBooleanLogic.client)
    setExpressionV2(updatedExpression)

    setValidationError(null)
    setIsHelpShown(false)

    ReactGA.event({
      category: 'audience_boolean_logic',
      action: 'cancel_btn_click',
    })
  }

  useKeyPress('e', () => setIsExpanded((prevState) => !prevState))

  return (
    <Flex flexDirection='column'>
      <Field
        mb={2}
        name='audienceBooleanLogic.value'
        label='Advanced Boolean Logic'
        extra={
          <Flex alignItems='center' gap={2}>
            <Button
              disabled={!values?.audienceBooleanLogic.value || formDisable}
              icon={<CopyOutlined />}
              size='small'
              onClick={() => copy()}
            />
            {hasCopied ? <Text color='positive.base'>Copied...</Text> : null}
          </Flex>
        }
      >
        <Input.TextArea
          readOnly
          style={{ resize: 'none', pointerEvents: 'none' }}
          rows={3}
          disabled={formDisable}
        />
      </Field>
      <Flex>
        <Button
          block
          loading={isProvidersLoading}
          disabled={formDisable}
          onClick={() => {
            const expressionClient = cloneDeep(
              values.audienceBooleanLogic?.client || []
            )
            setExpressionV2(expressionClient)
            stateHistory.current = [expressionClient]
            setIsBuilderOpen(true)
            ReactGA.event({
              category: 'audience_boolean_logic',
              action: 'modal_open',
            })
          }}
        >
          {isProvidersLoading ? 'Loading Providers' : 'Manage Expression'}
        </Button>
      </Flex>
      <Modal
        destroyOnClose
        bodyStyle={{ height: 586, overflow: 'auto' }}
        centered
        closeIcon={null}
        footer={
          <ExpressionModalFooter
            onSave={onSave}
            onCancel={onModalCancel}
            isLoading={isValidating}
            validationError={validationError}
          />
        }
        onCancel={onModalCancel}
        okText='Validate'
        visible={isBuilderOpen}
        title='Advanced Boolean Logic'
        width='85%'
      >
        <DndProvider backend={HTML5Backend}>
          <Grid
            gap={3}
            style={{
              gridTemplateColumns: isExpanded ? '0 1fr' : 'repeat(2, 1fr)',
              transition: 'grid-template-columns 0.3s ease-in-out',
            }}
          >
            <AvailableSegments pmpAvails={pmpAvails} providers={providers} />
            <ExpressionBuilder />
          </Grid>
        </DndProvider>
        <ExpressionModalHelp />
      </Modal>
    </Flex>
  )
}

BooleanSegment.propTypes = {
  pmpAvails: PropTypes.bool,
  formDisable: PropTypes.bool,
}

export default BooleanSegment
