import http from '../http'
import { IS_DEALS_ENGINE } from '../../constants'

export default {
  account: {
    getAll(data) {
      return http.post('account/get/all', data)
    },
    getAccountById({ id }) {
      return http.get(`account/get/${id}`)
    },
    getAccountDsps() {
      return http.get('account/dsp')
    },
    create(params) {
      return http.post('account/create', params)
    },
    check(params) {
      return http.get('account/check', { params })
    },
    update(id, params) {
      return http.post(`account/update/${id}`, params)
    },
  },
  audit: {
    getAll({ params, ...data }) {
      return http.post('audit/all', data, { params })
    },
  },
  user: {
    getAll(data) {
      return http.post('admin/user/get/all', data)
    },
    getUserById({ userId }) {
      return http.get(`admin/user/get/${userId}`)
    },
    create(params) {
      return http.post('admin/user/create', params)
    },
    check(params) {
      return http.get('admin/user/check', { params })
    },
    update(userId, params) {
      return http.post(`admin/user/update/${userId}`, params)
    },
    delete(params) {
      return http.post('admin/user/delete/users', params)
    },
    marketplace(id) {
      return http.get(`admin/user/marketplace/${id}`)
    },
  },
  meta: {
    getAll(data) {
      return http.post('meta/get/all', data)
    },
    create(data) {
      return http.post('meta/create', data)
    },
    update(data) {
      return http.post('meta/update', data)
    },
    get(id) {
      return http.get(`meta/get/${id}`)
    },
    delete(id) {
      return http.get(`meta/delete/${id}`)
    },
  },
  publisherMapping: {
    getAll() {
      return http.get('publishermapping/get/all')
    },
    toggle(publisherId) {
      return http.put(`/publishermapping/toggle/premium/${publisherId}`)
    },
  },
  supplyRequest: {
    getAll(data) {
      return http.post('messages/supply', data)
    },
  },
  dsp: {
    getAll(data) {
      return http.post('dsp/get/all', data)
    },
    getDspById(id) {
      return http.get(`dsp/get/${id}`)
    },
    create(params) {
      return http.post('dsp/create', params)
    },
    update(id, params) {
      return http.post(`dsp/update/${id}`, params)
    },
  },
  systemMessages: {
    get(params) {
      if (!IS_DEALS_ENGINE) {
        delete params.admin
      }
      return http.get('/systemmessages/get', { params })
    },
    create(params, data) {
      if (!IS_DEALS_ENGINE) {
        delete params.admin
      }
      return http.post('/systemmessages/create', data, { params })
    },
    update(params, id) {
      if (!IS_DEALS_ENGINE) {
        delete params.admin
      }
      return http.post(`/systemmessages/update/`, id, { params })
    },
  },
}
