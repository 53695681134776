import React from 'react'
import PropTypes from 'prop-types'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useKeycloak } from '@react-keycloak/web'
import { ToastProvider } from '@beachfront/ui'

import { api } from '../../client-api'
import { useFetch } from '../../hooks'
import { ThemeProvider, KeycloakProvider, AuthProvider } from '../../context'
import { IS_DEALS_ENGINE } from '../../constants'
import CommonSpinner from '../common-spinner'

import { AdBlockDetector, AppRoutes } from './@components'
import GlobalStyle from './global-style'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  const children = IS_DEALS_ENGINE ? (
    <KeycloakProvider>
      <AppSSOToken>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </AppSSOToken>
    </KeycloakProvider>
  ) : (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  )

  return (
    <ThemeProvider>
      <GlobalStyle />
      <ToastProvider>
        <AdBlockDetector>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </AdBlockDetector>
      </ToastProvider>
    </ThemeProvider>
  )
}

const AppSSOToken = ({ children }) => {
  const { keycloak, initialized } = useKeycloak()
  const { idTokenParsed } = keycloak
  const { account_id, account_name, email, given_name, family_name } =
    idTokenParsed ?? {}

  const payload = {}

  if (initialized && keycloak.authenticated) {
    if (account_id) {
      payload.pubId = account_id
    }
    if (account_name) {
      payload.name = account_name
    }
    if (email) {
      payload.email = email
    }
    if (given_name) {
      payload.firstName = given_name
    }
    if (family_name) {
      payload.lastName = family_name
    }
  }

  const response = useFetch({
    request: api.user.login,
    payload,
  })

  if (response.loading) {
    return <CommonSpinner />
  }

  return <>{children}</>
}

AppSSOToken.propTypes = {
  children: PropTypes.node,
}

export default App
