import React, { useState, useMemo } from 'react'
import { Accordion, Table, Box, Spinner, Empty } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { useTargetingInsight } from '../../../hooks'
import {
  isNotEmptyArray,
  getErrorMessage,
  formatCount,
  toTitleCase,
} from '../../../utils'
import { NetworkError } from '../../../components'

const TargetingInsights = ({
  widgetsData,
  isLoading,
  targetingParams,
  timezone,
}) => {
  const [expandKeys, setExpandKeys] = useState([])

  const isWidgetVisible = (widget) => {
    if (
      targetingParams.domainMedia ||
      targetingParams.bundleMedia ||
      targetingParams.bundle ||
      targetingParams.domain
    ) {
      if (widget.apiUrl?.includes('domain')) {
        return targetingParams.domainMedia || targetingParams.domain
      }
      if (widget.apiUrl?.includes('bundle')) {
        return targetingParams.bundleMedia || targetingParams.bundle
      }
    }
    return true
  }

  if (isLoading) {
    return <Spinner />
  }

  if (!widgetsData?.length) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description='No Insight Data Found'
      />
    )
  }

  return (
    <Accordion type='multiple' value={expandKeys} onValueChange={setExpandKeys}>
      {widgetsData?.map((w) => {
        const key = w.dimension
        return isWidgetVisible(w) ? (
          <Accordion.Panel
            key={key}
            value={key}
            header={String(w.value).trim()}
          >
            <InsightTable
              widget={w}
              targetingParams={targetingParams}
              timezone={timezone}
              enabled={expandKeys.includes(key)}
            />
          </Accordion.Panel>
        ) : null
      })}
    </Accordion>
  )
}

const InsightTable = ({ widget, targetingParams, timezone, enabled }) => {
  const query = useTargetingInsight({
    params: { widget, targetingParams, timezone },
    enabled,
  })

  const columns = useMemo(() => getTableColumns(query.data), [query.data])
  const rows = useMemo(() => getTableRows(query.data), [query.data])

  const table = useTableQuery({
    data: rows ?? [],
  })

  if (query.error) {
    return (
      <NetworkError
        py={3}
        description={getErrorMessage(query.error)}
        onAction={query.refetch}
      />
    )
  }

  return (
    <Box bg='bg.component'>
      <Table
        rowKey='key0'
        emptyText={query.data?.msg ?? 'No Data Found'}
        dataSource={table.data}
        columns={columns}
        loading={query.isLoading}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: table.data.length <= 25,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
    </Box>
  )
}

InsightTable.propTypes = {
  widget: PropTypes.object,
  targetingParams: PropTypes.object,
  timezone: PropTypes.string,
  enabled: PropTypes.bool,
}

const getTableColumns = (data) => {
  if (!data) {
    return []
  }
  const renderMap = {
    STRING: (val) => val || 'NA',
    NUMBER: (val) => formatCount(val),
  }
  return data.headers
    .filter((el) => el)
    .map((el, i) => ({
      dataIndex: `key${i}`,
      title: toTitleCase(el.title),
      width: el.width ?? 115,
      sorter: true,
      defaultSortOrder: el.defaultSortOrder ?? null,
      render: renderMap[el.type],
    }))
}

const getTableRows = (data) => {
  if (!data) {
    return []
  }
  return data.rows
    .filter((el) => el)
    .map((el) => {
      const record = {}
      if (isNotEmptyArray(el.data)) {
        el.data.forEach((val, i) => {
          record[`key${i}`] = val
        })
      }
      return record
    })
}

TargetingInsights.defaultProps = {
  widgetsData: [],
  targetingParams: {},
}

TargetingInsights.propTypes = {
  widgetsData: PropTypes.array,
  isLoading: PropTypes.bool,
  targetingParams: PropTypes.object,
  timezone: PropTypes.string,
}

export default TargetingInsights
