import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { authService } from '../../../context'

import { AppLayout } from '.'

const ProtectedLayout = () => {
  if (!authService.isAuthenticated()) {
    return <Navigate replace to='/' />
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  )
}

export default ProtectedLayout
