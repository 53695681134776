import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import { useTheme } from 'styled-components'
import { Flex, Box, Text, Empty } from '@beachfront/ui'

import { api } from '../../../client-api'
import { isNotEmptyArray, getErrorMessage } from '../../../utils'
import { CommonSpinner, NetworkError } from '../../../components'
import { PieChartWidget } from '../../../components/widget'

const OverallTakeRate = ({ requestPayload }) => {
  const theme = useTheme()
  const chartColors = [theme.colors.primary[6], theme.colors.primary[3]]
  const response = useOverallTakeRate(requestPayload)

  const pieData = useMemo(() => {
    const takeRate = response.data?.rows[0]?.takeRate ?? 0
    const beachfront = 100 - Number(takeRate)
    return [
      {
        label: 'To Beachfront',
        value: Number(takeRate),
        valueS: `${Number(takeRate)}%`,
      },
      {
        label: 'To Publisher',
        value: beachfront,
        valueS: `${beachfront.toFixed(2)}%`,
      },
    ]
  }, [response.data])

  if (response.isFetching) {
    return <CommonSpinner />
  }

  if (response.error) {
    return (
      <NetworkError
        description={getErrorMessage(response.error)}
        onAction={response.refetch}
      />
    )
  }

  if (!isNotEmptyArray(response.data?.rows)) {
    return (
      <Empty
        my={5}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description='No Data Found'
      />
    )
  }

  return (
    <>
      <PieChartWidget
        data={pieData}
        colors={chartColors}
        config={{ showLabel: true }}
        height={500}
        bordered={false}
      />
      <Flex gap={3} width={1} justifyContent='center'>
        {pieData.map((item, i) => (
          <Flex key={i} gap={2} alignItems='center' title={item.label}>
            <Box size={12} borderRadius='50%' bg={chartColors[i]} />
            <Text fontSize={1} fontFamily='secondary' ellipsis>
              {item.label}
            </Text>
          </Flex>
        ))}
      </Flex>
    </>
  )
}

const useOverallTakeRate = (payload) => {
  return useQuery({
    queryKey: ['overall-take-rate', payload],
    queryFn: async () => {
      const { data } = await api.realtime.transparency.overallTakeRate(payload)
      return data
    },
    staleTime: 30000,
  })
}

OverallTakeRate.propTypes = {
  requestPayload: PropTypes.object,
}

export default OverallTakeRate
