import { createEnum } from '../utils'

export const MEDIA_LIST_TYPE = createEnum({
  BUNDLE: {
    key: 'bundle',
    name: 'Bundle',
  },
  DOMAIN: {
    key: 'domain',
    name: 'Domain',
  },
  ZIP_CODE: {
    key: 'zipcode',
    name: 'Zip Code',
  },
  METRO_CODE: {
    key: 'metrocode',
    name: 'Metro Code',
  },
})
