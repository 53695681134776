import React, { useRef, useEffect } from 'react'
import { Table, Flex, Box, Button, Select } from '@beachfront/ui'
import { useCallbackRef } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

const InfiniteTable = ({
  dataSource = [],
  loading,
  total,
  pageSize,
  onPageSizeChange,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  ...rest
}) => {
  const bodyRef = useRef(null)

  const onScroll = useCallbackRef((e) => {
    if (hasNextPage && !isFetchingNextPage) {
      const { scrollHeight, clientHeight, scrollTop } = e.target
      const bottom = scrollHeight - (clientHeight + scrollTop)
      if (bottom < 5 && scrollTop > 0) {
        fetchNextPage?.()
      }
    }
  })

  useEffect(() => {
    const body = bodyRef.current
    const opt = { passive: true }
    body?.addEventListener('scroll', onScroll, opt)
    return () => body?.removeEventListener('scroll', onScroll, opt)
  }, [onScroll])

  const isEmpty = !loading && !dataSource.length
  const totalString = hasNextPage
    ? `1 - ${dataSource.length} of ${total ?? 'many'} items`
    : `Total ${dataSource.length} items`

  const footer = () => (
    <Flex justifyContent='space-between' color='text' fontSize={1} py={1}>
      <Flex gap={2}>
        <Box width={120}>
          <Select size='small' value={pageSize} onChange={onPageSizeChange}>
            {[50, 100, 250].map((val) => (
              <Select.Option key={val} value={val}>
                {`${val} / page`}
              </Select.Option>
            ))}
          </Select>
        </Box>
        {hasNextPage ? (
          <Button
            size='small'
            disabled={isFetchingNextPage}
            loading={isFetchingNextPage}
            onClick={() => fetchNextPage?.()}
          >
            Load More
          </Button>
        ) : null}
      </Flex>
      {dataSource.length ? <Box alignSelf='center'>{totalString}</Box> : null}
    </Flex>
  )

  return (
    <Table
      {...rest}
      bodyRef={bodyRef}
      dataSource={dataSource}
      loading={loading}
      footer={isEmpty ? null : footer}
      pagination={false}
    />
  )
}

InfiniteTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  fetchNextPage: PropTypes.func,
}

InfiniteTable.Toolbar = Table.Toolbar
InfiniteTable.BatchActions = Table.BatchActions
InfiniteTable.ActionButton = Table.ActionButton
InfiniteTable.ColumnConfig = Table.ColumnConfig
InfiniteTable.Summary = Table.Summary

export default InfiniteTable
