import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useAppInfoDetail = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['app-info-detail', params],
    queryFn: async () => {
      const res = await api.deal.appInfoDetail(params)
      return res.data
    },
    enabled,
  })
}
