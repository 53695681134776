import React from 'react'
import { Filter } from '@beachfront/ui'
import { MailOutlined, UserOutlined } from '@beachfront/ui/icons'

import {
  ContainsFilterPanel,
  formatStringFilterTag,
} from '../../../components/filter'

const UserFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='email'
        label='Email'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<MailOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='firstName'
        label='First Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<UserOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='lastName'
        label='Last Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<UserOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
    </Filter>
  )
}

export default UserFilter
