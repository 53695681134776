import { createEnum } from '../utils'

export const ACCOUNT_TYPE = createEnum({
  DSP: {
    name: 'DSP',
  },
  AGENCY: {
    name: 'Agency',
  },
  BRAND: {
    name: 'Brand',
  },
  PUBLISHER: {
    name: 'Publisher',
  },
  RESELLER: {
    name: 'Reseller',
  },
})
