import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter, Radio, Badge } from '@beachfront/ui'
import {
  CalendarOutlined,
  TagsOutlined,
  VideoCameraOutlined,
} from '@beachfront/ui/icons'

import { MEDIA_TYPE } from '../../../enums'
import {
  DateFilterPanel,
  ContainsFilterPanel,
  formatDateFilterTag,
  formatStringFilterTag,
} from '../../../components/filter'

const DealFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='title'
        label='Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='status'
        label='Status'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={true}
        icon={renderStatusIcon}
        formatTag={renderStatusTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            <Radio.Button value={true}>Enabled</Radio.Button>
            <Radio.Button value={false}>Disabled</Radio.Button>
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='mediaType'
        label='Media Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<VideoCameraOutlined />}
        formatTag={renderMediaTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {MEDIA_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='createDate'
        label='Created'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='updateDate'
        label='Last Updated'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
    </Filter>
  )
}

const renderStatusIcon = (desc) => {
  return desc.value ? <Badge status='success' /> : <Badge status='error' />
}

const renderStatusTag = (desc) => {
  return `Status is ${desc.value ? 'Enabled' : 'Disabled'}`
}

const renderMediaTypeTag = (desc) => {
  const name = MEDIA_TYPE.fromKey(desc.value)?.name
  return name ? `Media Type is ${name}` : 'All Media Types'
}

DealFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default DealFilter
