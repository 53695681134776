import axios from 'axios'

import { authService } from '../context'

const http = axios.create({
  baseURL: '/api/',
})

http.interceptors.response.use(
  (res) => res,
  (error) => {
    const status = error.response?.status
    const data = error.response?.data

    if (authService.isAuthenticated() && status === 401) {
      setTimeout(() => {
        authService.logout()
        window.location.reload()
      }, 1000)
      throw error
    }

    if (status === 500) {
      error.message = 'Internal server error.'
    }

    if (data?.message) {
      error.message = data.message
    }

    throw error
  }
)

export default http
