import React, { useEffect, useState } from 'react'
import { Flex, Spinner, Empty } from '@beachfront/ui'

import { useBooleanSegment } from '../booleanSegment/boolean-segment-context'
import {
  setPayloadData,
  setFilterData,
  sanitizePostData,
} from '../../@utils/segments.util'
import { api } from '../../../../client-api'
import {
  useSegmentProviders,
  useProviderTypes,
  useFetch,
} from '../../../../hooks'
import { isNotEmptyArray } from '../../../../utils'

import { SegmentItem } from './@components'

const SegmentList = ({ activeTab, pmpAvails }) => {
  const { appliedProviders, appliedTypes, params, setParams } =
    useBooleanSegment()
  const [isLoading, setIsLoading] = useState(false)
  const [segments, setSegments] = useState([])

  const { providers } = useSegmentProviders()
  const { types } = useProviderTypes(activeTab)

  const onScroll = (evt) => {
    const { target } = evt
    let bottom = target.scrollHeight - (target.clientHeight + target.scrollTop)
    if (bottom < 20 && !response.loading && response.data && response.data.hm) {
      const { p } = params
      setIsLoading(true)
      setParams({ ...params, p: p + 1 })
      //getSegmentList({ ...params, p: p + 1 }, activeTab)
    }
  }

  const payloadData = setPayloadData(activeTab, appliedProviders, providers)
  const filterData = setFilterData(activeTab, appliedTypes, types)
  // Move to Infinite Query React Query
  const response = useFetch({
    request: isNotEmptyArray(types) ? api.mediaPlan.segmentList : null,
    payload: {
      params: {
        ...params,
        q: encodeURIComponent(params.q),
        type: activeTab === 'uploaded' ? 'upload' : activeTab,
        pmp: !!pmpAvails,
      },
      data: sanitizePostData(payloadData, providers, activeTab),
      type: sanitizePostData(filterData, types, activeTab),
    },
  })

  useEffect(() => {
    if (
      response.data?.segmentList &&
      Array.isArray(response.data.segmentList)
    ) {
      if (params.p === 0) {
        setSegments(response.data.segmentList)
      } else {
        setSegments(segments.concat(response.data.segmentList))
      }

      setIsLoading(false)
    }
  }, [response.data])

  if (response.loading && !isLoading) {
    return (
      <Flex alignItems='center' justifyContent='center'>
        <Spinner size='small' />
      </Flex>
    )
  }

  return (
    <div
      onScroll={onScroll}
      style={{
        height: '412px', // Maybe a better way for this
        overflowY: 'scroll',
      }}
    >
      {segments.map((segment) => (
        <SegmentItem activeTab={activeTab} segment={segment} key={segment.id} />
      ))}
      {segments.length === 0 && !isLoading ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No Segments Found'
        />
      ) : null}
      {isLoading ? (
        <Flex alignItems='center' justifyContent='center'>
          <Spinner size='small' />
        </Flex>
      ) : null}
    </div>
  )
}

export default SegmentList
