import React from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Text } from '@beachfront/ui'
import { WarningOutlined } from '@beachfront/ui/icons'
import { useFormState } from '@beachfront/ui/forms'
import { useTheme } from 'styled-components'
import isEqual from 'lodash/isEqual'

import { useBooleanSegment } from '../../boolean-segment-context'
import { isNotEmptyArray } from '../../../../../../utils'

const ExpressionModalFooter = ({
  isLoading,
  onCancel,
  onSave,
  validationError,
}) => {
  const theme = useTheme()
  const { isHelpShown, expressionV2 } = useBooleanSegment()
  const { values } = useFormState()

  const isValidateVisible = () => {
    if (isNotEmptyArray(values?.audienceBooleanLogic.client)) {
      return isEqual(values?.audienceBooleanLogic.client, expressionV2)
    }
    if (
      isNotEmptyArray(expressionV2) &&
      !isNotEmptyArray(expressionV2[0].children)
    ) {
      return isEqual(values?.audienceBooleanLogic.client, expressionV2)
    }
    return isEqual(values?.audienceBooleanLogic.client, expressionV2)
  }

  return (
    <Flex justifyContent='flex-end'>
      {validationError ? (
        <Flex gap={2} alignItems='center' mr='auto'>
          <WarningOutlined
            style={{
              color: theme.colors.negative[3],
              fontSize: '18px',
            }}
          />
          <Text color='negative.3' textAlign='left'>
            Validation Failed: {validationError}
          </Text>
        </Flex>
      ) : null}
      <Flex alignItems='center' flex='none'>
        <Button
          disabled={isLoading || isHelpShown}
          type='secondary'
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={isHelpShown || isValidateVisible()}
          type='primary'
          onClick={onSave}
          loading={isLoading}
        >
          Validate
        </Button>
      </Flex>
    </Flex>
  )
}

ExpressionModalFooter.propTypes = {
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  validationError: PropTypes.string,
}

export default ExpressionModalFooter
