import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Menu, Text } from '@beachfront/ui'

const ProviderFilterTitle = ({
  providerFilterCategory,
  setProviderFilterCategory,
  filteredProviders,
}) => {
  const hasIris = filteredProviders.some((fp) => fp.parentProvider === 'IRIS')

  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <Text>Filter by provider</Text>
      {hasIris ? (
        <Box width='105px'>
          <Menu
            items={[
              {
                key: 'all',
                label: 'All',
              },
              {
                key: 'iris',
                label: 'Iris',
              },
            ]}
            mode='horizontal'
            onSelect={({ key }) => setProviderFilterCategory(key)}
            selectedKeys={[providerFilterCategory]}
            style={{
              lineHeight: '32px',
            }}
          />
        </Box>
      ) : null}
    </Flex>
  )
}

ProviderFilterTitle.propTypes = {
  providerFilterCategory: PropTypes.string,
  setProviderFilterCategory: PropTypes.func,
  filteredProviders: PropTypes.arrayOf(PropTypes.object),
}

export default ProviderFilterTitle
