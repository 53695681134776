import React from 'react'

import { DEAL_STATUS } from '../../../enums'

export const getDealTooltip = (type) => {
  switch (type) {
    case DEAL_STATUS.DRAFT.key:
      return <div>Inactive deal project</div>
    case DEAL_STATUS.PUBLISHED.key:
      return <div>Deal is active</div>
    case DEAL_STATUS.UNPUBLISHED.key:
      return <div>Deal is paused</div>
    case 'dealType':
      return <div>Select the deal type</div>
    case 'dealId':
      return <div>Enter a unique deal ID</div>
    case 'seat':
      return <div>Enter seat ID (Optional)</div>
    case 'floorCpm':
      return <div>Enter floor CPM in USD</div>
    case 'fixedCpm':
      return <div>Enter fixed CPM in USD</div>
    case 'dsp':
      return <div>Select the DSP for this deal</div>
    case 'mediaPlan':
      return <div>Select a media plan to attach to this deal</div>
    case 'marginType':
      return (
        <>
          <div>
            Flat CPM - If selected, you must add the CPM as a dollar amount.
          </div>
          <div>
            Percent - If selected, you must add the Margin as a percent.
          </div>
        </>
      )
    case 'margin':
      return <div>Enter margin in %</div>
    case 'budgetType':
      return (
        <>
          <div>
            Overall Budget and Daily Budget- You must add a budget in a dollar
            amount.
          </div>
          <div>Open Budget - No need to add a budget.</div>
        </>
      )
    case 'budget':
      return <div>Enter Budget in $</div>
    case 'frequencyCapping':
      return (
        <>
          <div>
            Per Day - If selected, you must add an impression per day amount.
          </div>
          <div>Unlimited - No set impression limit per day.</div>
        </>
      )
    case 'start':
      return <div>Select Start Date & Time to get data</div>
    case 'end':
      return <div>Select End Date & Time to get data</div>
    case 'ip':
      return (
        <>
          <div>On - will send IP in Bidstream Data</div>
          <div>Off - will not send IP in Bidstream Data</div>
          <div>Truncated - will send trucated IP value in Bidstream Data</div>
        </>
      )
    case 'ifa':
      return <div>Enabling IFA, will send IFA in Bidstream Data</div>
    case 'location':
      return (
        <>
          <div>On - will send Latitude & Longitude in Bidstream Data</div>
          <div>Off - will not send Latitude & Longitude in Bidstream Data</div>
          <div>
            Truncated - will send trucated Latitude & Longitude value in
            Bidstream Data
          </div>
        </>
      )
    case 'zipcode':
      return <div>Enabling Zipcode, will send Zipcode in Bidstream Data</div>
    case 'contentTitle':
      return <div>Enabling Title, will send Title in Bidstream Data</div>
    case 'channel':
      return <div>Enabling Channel, will send Channel in Bidstream Data</div>
    case 'season':
      return <div>Enabling Season, will send Season in Bidstream Data</div>
    case 'episode':
      return <div>Enabling Episode, will send Episode in Bidstream Data</div>
    case 'network':
      return <div>Enabling Network, will send Network in Bidstream Data</div>
    case 'series':
      return <div>Enabling Series, will send Series in Bidstream Data</div>
    case 'dataFees':
      return (
        <div>
          Fee charged for audience segments managed according to the media plan
          audience targeting preferences
        </div>
      )
    case 'cpm':
      return <div>Enter flat CPM in $</div>
    default:
      break
  }
}
