import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Label,
  Modal,
  Radio,
  FieldControl,
  useToast,
} from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { getErrorMessage, isNotEmptyObject } from '../../../utils'
import { InfiniteTable, NetworkError } from '../../../components'
import { localFilterKey } from '../../../components/filter'
import { useContentSize, useFilters, useInfiniteTable } from '../../../hooks'
import { attachMediaModalColumns } from '../@constants'

import AttachMediaModalFilter from './attach-media-modal-filter'

const AttachMediaModal = ({
  data,
  mediaData,
  selectedMediaList,
  onClose,
  initialRowValues,
  initialRadioValue,
  onSuccess,
}) => {
  const [filters, setFilters] = useFilters(localFilterKey.ATTACH_MEDIA_LIST)
  const toast = useToast()
  const { contentHeight } = useContentSize()

  const [radioValue, setRadioValue] = useState(initialRadioValue)
  const filterRef = useRef()

  const table = useInfiniteTable({
    queryKey: ['attach-media-list'],
    queryFn: api.mediaPlan.attachMediaList,
    filters: filters.populated,
    params: { type: data.key },
  })

  const [selectedRowKeys, setSelectedRowKeys] = useState(initialRowValues)

  const scroll = {
    x: '100%',
    y: contentHeight - 490,
  }

  const onModalClose = () => {
    setFilters([])
    onClose()
  }

  useEffect(() => {
    if (
      selectedMediaList &&
      selectedRowKeys.rowData.length !== selectedMediaList.ids.length
    ) {
      setSelectedRowKeys((prevState) => ({
        ...prevState,
        rowKeys: selectedMediaList.ids.map((el) => el.id),
        rowData: selectedMediaList.ids,
      }))
    }
  }, [JSON.stringify(selectedMediaList)])
  const rowSelection = {
    selectedRowKeys: selectedRowKeys.rowKeys,
    onChange: (key, data) => onRowSelect(key, data),
    columnWidth: 40,
  }
  const toolbar = () => (
    <InfiniteTable.Toolbar
      pb={2}
      height={selectedRowKeys.length ? 48 : undefined}
    >
      <Flex justifyContent='space-between'>
        <AttachMediaModalFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </InfiniteTable.Toolbar>
  )
  const onRowSelect = (key, data) => {
    let rowKeyData = { ...selectedRowKeys }
    let selectedZipCodeCount = data
      .map((el) => el.count)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    rowKeyData.rowKeys = key
    rowKeyData.rowData = data
    rowKeyData.totalZipCodes = selectedZipCodeCount

    setSelectedRowKeys(rowKeyData)
  }

  const onRadioChange = (e) => {
    setRadioValue(e.target.value)
  }

  const onSubmit = () => {
    if (
      selectedRowKeys.totalZipCodes &&
      selectedRowKeys.totalZipCodes > 25000
    ) {
      return toast.error({
        title:
          'Zipcode medialist cannot exceed 25000 items, please select rest of the entries and apply Allow/Block accordingly in Media Plan.',
      })
    }
    onSuccess({
      ...selectedRowKeys,
      radioValue,
    })
    onModalClose()
  }

  return (
    <Modal
      centered
      closable={false}
      visible={isNotEmptyObject(mediaData)}
      bodyStyle={{ height: contentHeight - 205, overflow: 'auto' }}
      width={650}
      cancelText='Cancel'
      okText='Update'
      onCancel={onModalClose}
      onOk={() => onSubmit()}
      title={mediaData && mediaData.title ? mediaData.title : 'Attach List'}
    >
      <Box mb={3}>
        <FieldControl label={`Add ${data.label} Lists To`}>
          <Radio.Group onChange={onRadioChange} value={radioValue}>
            <Radio value='include'>Allow List</Radio>
            <Radio value='exclude'>Block List</Radio>
          </Radio.Group>
        </FieldControl>
      </Box>
      <Flex justifyContent='space-between' mb={3}>
        <Label m='auto 0'>{`Select ${data.label} Media Lists`}</Label>
        <Flex gap={2}>
          <Button
            onClick={() => table.refetch()}
            icon={<ReloadOutlined />}
            size='small'
          />
          <Link
            to={`/medialist/${data.key === 'dma' ? 'metrocode' : data.key}/create`}
            target='_blank'
          >
            <Button type='primary' size='small'>
              Create Media List
            </Button>
          </Link>
        </Flex>
      </Flex>
      <Box>
        {!table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <InfiniteTable
            rowKey='id'
            emptyText='No Media Lists Found'
            columns={attachMediaModalColumns}
            toolbar={toolbar}
            scroll={scroll}
            rowSelection={rowSelection}
            filters={filters.populated}
            onFilter={(key) => filterRef.current?.addFilter(key)}
            filtersDisabled={false}
            {...table.props}
          />
        )}
      </Box>
    </Modal>
  )
}

AttachMediaModal.defaultProps = {
  mediaData: {},
  initialRowValues: {
    rowKeys: [],
    rowData: [],
  },
  initialRadioValue: 'include',
}

AttachMediaModal.propTypes = {
  data: PropTypes.object,
  mediaData: PropTypes.object,
  selectedMediaList: PropTypes.object,
  onClose: PropTypes.func,
  initialRowValues: PropTypes.object,
  initialRadioValue: PropTypes.string,
  onSuccess: PropTypes.func,
}

export default AttachMediaModal
