import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter, Radio, Badge } from '@beachfront/ui'
import { NumberOutlined, CalendarOutlined } from '@beachfront/ui/icons'

import {
  DateFilterPanel,
  CompareFilterPanel,
  formatDateFilterTag,
  formatNumberTag,
} from '../../../components/filter'

const MediaPlanFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='status'
        label='Status'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={true}
        icon={renderStatusIcon}
        formatTag={renderStatusTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            <Radio.Button value={true}>Enabled</Radio.Button>
            <Radio.Button value={false}>Disabled</Radio.Button>
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='noOfDealsAttched'
        label='Deals Attached'
        defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
        defaultValue={0}
        icon={<NumberOutlined />}
        formatTag={formatNumberTag}
        renderPanel={(props) => (
          <CompareFilterPanel {...props} min={0} step={1} precision={0} />
        )}
      />
      <Filter.Option
        dataIndex='createDate'
        label='Created'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='updateDate'
        label='Last Updated'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
    </Filter>
  )
}

const renderStatusIcon = (desc) => {
  return desc.value ? <Badge status='success' /> : <Badge status='error' />
}

const renderStatusTag = (desc) => {
  return `Status is ${desc.value ? 'Enabled' : 'Disabled'}`
}

MediaPlanFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default MediaPlanFilter
