import React from 'react'
import { Link } from 'react-router-dom'

import { formatDate } from '../../../utils'

export const userColumns = [
  {
    dataIndex: 'email',
    title: 'Email',
    fixed: 'left',
    width: 180,
    sorter: true,
    filter: true,
    render: (val, rec) => <Link to={`${rec.userId}`}>{val}</Link>,
  },
  {
    dataIndex: 'firstName',
    title: 'First Name',
    width: 160,
    sorter: true,
    filter: true,
  },
  {
    dataIndex: 'lastName',
    title: 'Last Name',
    width: 160,
    sorter: true,
    filter: true,
  },
  {
    dataIndex: 'createDate',
    title: 'Create Date',
    width: 150,
    sorter: true,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'lastLogin',
    title: 'Last Login',
    width: 150,
    sorter: true,
    render: (val) => formatDate(val),
  },
]
