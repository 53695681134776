export const validateSegmentForm = (values) => {
  const errors = {}

  if (values.name) {
    values.name = values.name.trim()
  }

  if (!values.name) {
    errors.name = 'Required'
  }

  if (!values.dataType) {
    errors.dataType = 'Required'
  }

  if (!values.id && !values.uploadState?.url) {
    errors.file = 'Please upload a valid CSV file.'
  }

  return errors
}
