import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Flex,
  Box,
  Card,
  DayPartsPicker,
  DatePicker,
  Radio,
  Text,
  Help,
} from '@beachfront/ui'
import { Field, useField } from '@beachfront/ui/forms'
import moment from 'moment'
import { minimalTimezoneSet } from 'compact-timezone-list'

import { LabelTooltip, SelectField } from '../../../components'
import { getDealTooltip } from '..'

const DeliveryForm = ({ values, superAdmin, disableForm }) => {
  const formDisable = !!superAdmin || disableForm
  const { input: dayPartingSchedules, meta: dayPartingMeta } = useField(
    'dayPartingSchedules'
  )

  useEffect(() => {
    const chartPostData = {}

    if (
      values.budgetType &&
      values.mediaPlans &&
      values.budget !== undefined &&
      values.budget !== null
    ) {
      if (
        values.budgetType === 'OVERALL' &&
        values.deliveryType &&
        values.start &&
        values.end &&
        values.start < values.end
      ) {
        chartPostData.budgetType = values.budgetType
        chartPostData.mediaId = values.mediaPlans
        chartPostData.budget = values.budget
        chartPostData.deliveryType = values.deliveryType

        if (values.start instanceof moment) {
          chartPostData.start = new Date(values.start.format()).getTime()
        } else {
          chartPostData.start = values.start
        }
        if (values.end instanceof moment) {
          chartPostData.end = new Date(values.end.format()).getTime()
        } else {
          chartPostData.end = values.end
        }
      }

      if (
        values.budgetType === 'DAILY' &&
        values.deliveryType === 'CUSTOM' &&
        values.start &&
        values.end &&
        values.start < values.end
      ) {
        chartPostData.budgetType = values.budgetType
        chartPostData.mediaId = values.mediaPlans
        chartPostData.budget = values.budget
        chartPostData.deliveryType = values.deliveryType

        if (values.start instanceof moment) {
          chartPostData.start = new Date(values.start.format()).getTime()
        } else {
          chartPostData.start = values.start
        }
        if (values.end instanceof moment) {
          chartPostData.end = new Date(values.end.format()).getTime()
        } else {
          chartPostData.end = values.end
        }
      }

      if (values.budgetType === 'DAILY' && values.deliveryType === 'CONTINUE') {
        chartPostData.budgetType = values.budgetType
        chartPostData.mediaId = values.mediaPlans
        chartPostData.budget = values.budget
        chartPostData.deliveryType = values.deliveryType
      }
    }
  }, [JSON.stringify(values)])

  // function changeBudget(data) {
  //   if (
  //     data.value === 'OVERALL' &&
  //     values.deliveryType !== 'DELIVERSOON' &&
  //     values.deliveryType !== 'EVENLY'
  //   ) {
  //     if (
  //       initialValues.deliveryType === 'DELIVERSOON' ||
  //       initialValues.deliveryType === 'EVENLY'
  //     ) {
  //       deliveryType.onChange(initialValues.deliveryType)
  //     } else {
  //       deliveryType.onChange('DELIVERSOON')
  //     }
  //   }
  //   if (
  //     data.value !== 'OVERALL' &&
  //     values.deliveryType !== 'CONTINUE' &&
  //     values.deliveryType !== 'CUSTOM'
  //   ) {
  //     if (
  //       initialValues.deliveryType === 'CONTINUE' ||
  //       initialValues.deliveryType === 'CUSTOM'
  //     ) {
  //       deliveryType.onChange(initialValues.deliveryType)
  //     } else {
  //       deliveryType.onChange('CONTINUE')
  //     }
  //   }
  // }

  // const onNumberChange = (e, name) => {
  //   let rate = e.target.value
  //   if (Number(rate) > 2147483647) {
  //     rate = '2147483647'
  //   }
  //   rate = rate.replace(/[^0-9]*/g, '')
  //   form.change(name, rate)
  // }

  return (
    <Flex mt={2} flexDirection='column' gap={3}>
      {/* <Card inner title='Budget Optimization'>
        <Flex gap={3}>
          <Box flexGrow={1}>
            <Field
              name='budgetType'
              label='Select Type'
              extra={<LabelTooltip content={getDealTooltip('budgetType')} />}
            >
              <Radio.Group
                onChange={(e) => changeBudget(e.target)}
                disabled={formDisable}
              >
                <Radio value='DAILY'>Daily Budget</Radio>
                <Radio value='OVERALL'>Overall Budget</Radio>
                <Radio value='OPEN'>Open Budget</Radio>
              </Radio.Group>
            </Field>
            {values?.budgetType !== 'OPEN' ? (
              <Flex>
                <Field
                  name={
                    values.budgetType === 'DAILY'
                      ? 'budgetOptionsDaily'
                      : 'budgetOptionsOverall'
                  }
                >
                  <Radio.Group
                    onChange={(e) => changeBudget(e.target)}
                    disabled={formDisable}
                  >
                    {values.budgetType === 'DAILY' ? (
                      <Radio value='BUDGET'>Budget</Radio>
                    ) : null}
                    <Radio value='IMPRESSION'>Impression</Radio>
                  </Radio.Group>
                </Field>
                {values.budgetOptionsDaily && values.budgetType === 'DAILY' ? (
                  <Field
                    name={`${
                      values?.budgetOptionsDaily === 'BUDGET'
                        ? 'budget'
                        : 'impression'
                    }`}
                  >
                    <Input
                      disabled={formDisable}
                      maxLength={10}
                      placeholder={`Enter ${
                        values?.budgetOptionsDaily === 'BUDGET'
                          ? 'Budget ($)'
                          : 'Impression'
                      }`}
                      min={0}
                      onChange={(e) =>
                        onNumberChange(
                          e,
                          values?.budgetOptionsDaily === 'BUDGET'
                            ? 'budget'
                            : 'impression'
                        )
                      }
                    />
                  </Field>
                ) : null}
                {values.budgetOptionsOverall &&
                values.budgetType === 'OVERALL' ? (
                  <Field name='impression'>
                    <Input
                      disabled={formDisable}
                      name='impression'
                      maxLength={10}
                      placeholder='Enter Impression'
                      min={0}
                      onChange={(e) => onNumberChange(e, 'impression')}
                    />
                  </Field>
                ) : null}
              </Flex>
            ) : null}
            <Flex gap={3}>
              <Field
                name='frequencyType'
                label='Frequency Capping'
                extra={
                  <LabelTooltip content={getDealTooltip('frequencyCapping')} />
                }
              >
                <Radio.Group
                  onChange={(e) => changeBudget(e.target)}
                  disabled={formDisable}
                >
                  <Radio value='DAILY'>Per Day</Radio>
                  <Radio value='UNLIMITED'>Unlimited</Radio>
                </Radio.Group>
              </Field>

              {values.frequencyType === 'DAILY' ? (
                <Flex mt={24} ml={2}>
                  <Field name='frequency'>
                    <Input
                      name='frequency'
                      min={0}
                      maxLength={10}
                      placeholder='Enter impressions'
                      disabled={formDisable}
                      onChange={(e) => onNumberChange(e, 'frequency')}
                    />
                  </Field>
                </Flex>
              ) : null}
            </Flex>
          </Box>
          <Box width={350}>
            {values.budgetType !== 'OPEN' &&
            values.budgetType !== 'OVERALL' &&
            values.budgetOptionsDaily !== 'IMPRESSION' ? (
              <Card width={350} height='100%'>
                {values.budgetType !== 'OPEN' &&
                values.budgetType !== 'OVERALL' &&
                isNotEmptyObject(postData) &&
                !!values.budget &&
                values.mediaPlans &&
                values.budgetOptionsDaily === 'BUDGET' ? (
                  <DeliveryChart
                    dealId={values.id}
                    values={values}
                    postData={postData}
                  />
                ) : null}
                {values.budgetType !== 'OPEN' &&
                values.budgetOptionsOverall &&
                values.budgetOptionsDaily !== 'IMPRESSION' &&
                !values.budget &&
                values.mediaPlans ? (
                  <EmptyChartWarning msg='Please Enter Budget to get Estimation and Data' />
                ) : null}
                {values.budgetType !== 'OPEN' && !values.mediaPlans ? (
                  <EmptyChartWarning msg='Please Select Media Plan to get Estimation and Data' />
                ) : null}
                {values.budgetType !== 'OPEN' &&
                values.mediaPlans &&
                !!values.budget &&
                !isNotEmptyObject(postData) ? (
                  <>
                    {values.budgetType === 'DAILY' &&
                    values.deliveryType === 'CUSTOM' ? (
                      <>
                        {!(values.start && values.end) &&
                        values.budgetOptionsOverall &&
                        values.budgetOptionsDaily !== 'IMPRESSION' ? (
                          <EmptyChartWarning msg='Please Select Date & Time to get Estimation and Data' />
                        ) : null}
                        {values.start &&
                        values.end &&
                        values.start > values.end &&
                        values.budgetOptionsOverall &&
                        values.budgetOptionsDaily !== 'IMPRESSION' ? (
                          <EmptyChartWarning msg='Please Select End Date greater than Start Date to get Estimation and Data' />
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}
                {values.budgetType !== 'OPEN' &&
                values.mediaPlans &&
                values.budgetOptionsDaily === 'IMPRESSION' &&
                values.budgetOptionsOverall ? (
                  <EmptyChartWarning msg='Please Enter Budget to get Estimation and Data' />
                ) : null}
              </Card>
            ) : null}
          </Box>
        </Flex>
      </Card> */}
      <Card inner title='Flight Dates'>
        {values.budgetType !== 'OVERALL' ? (
          <Grid gap={3}>
            <Box columnSpan={values.budgetType === 'OPEN' ? 12 : 16}>
              <Field name='deliveryType'>
                <Radio.Group disabled={formDisable}>
                  <Radio value='CONTINUE'>Run Deal Continuously</Radio>
                  <Radio value='CUSTOM'>Set a Start and End Date</Radio>
                </Radio.Group>
              </Field>
            </Box>
          </Grid>
        ) : null}
        <Grid gap={3}>
          {values.deliveryType === 'CUSTOM' ||
          values.budgetType === 'OVERALL' ? (
            <>
              <Box columnSpan={values.budgetType === 'OPEN' ? 6 : 8}>
                <Field
                  name='start'
                  label='Start Date & Time (in EST)'
                  extra={<LabelTooltip content={getDealTooltip('start')} />}
                >
                  <DatePicker
                    showTime={{ format: 'hh:mm a', use12Hours: true }}
                    format='DD/MM/YYYY hh:mm a'
                    allowClear={false}
                    disabled={formDisable}
                    disabledDate={(current) => {
                      return current && current < moment().startOf('day')
                    }}
                  />
                </Field>
              </Box>
              <Box columnSpan={values.budgetType === 'OPEN' ? 6 : 8}>
                <Field
                  name='end'
                  label='End Date & Time (in EST)'
                  extra={<LabelTooltip content={getDealTooltip('end')} />}
                >
                  <DatePicker
                    showTime={{ format: 'hh:mm a', use12Hours: true }}
                    format='DD/MM/YYYY hh:mm a'
                    allowClear={false}
                    showNow={
                      !!(
                        values.start &&
                        (moment(values.start).format('LLL') ===
                          moment().format('LLL') ||
                          values.start < moment())
                      )
                    }
                    disabled={formDisable}
                    disabledDate={(current) => {
                      if (!values.start) {
                        return (
                          current &&
                          (current < moment().startOf('day') ||
                            current > moment().startOf('day'))
                        )
                      }
                    }}
                  />
                </Field>
              </Box>
            </>
          ) : null}
        </Grid>
        {values.budgetType === 'OVERALL' ? (
          <Grid gap={3}>
            <Box columnSpan={16}>
              <Field label='Pacing' name='deliveryType'>
                <Radio.Group disabled={formDisable}>
                  <Radio value='DELIVERSOON'>Deliver as Soon as Possible</Radio>
                  <Radio value='EVENLY'>Deliver Evenly</Radio>
                </Radio.Group>
              </Field>
            </Box>
          </Grid>
        ) : null}
      </Card>
      <Card
        inner
        title='Dayparting'
        overflow='initial'
        extra={
          <Field name='dayPartingType' showError={false} mb={0}>
            <Radio.Group disabled={formDisable}>
              <Radio value='ALL'>All Times</Radio>
              <Radio value='CUSTOM'>Choose Times</Radio>
            </Radio.Group>
          </Field>
        }
      >
        {values.dayPartingType === 'CUSTOM' ? (
          <Grid>
            <Box columnSpan={[24, 12]}>
              <Field name='dayPartingTimezone' label='Timezone'>
                <SelectField
                  keyField='tzCode'
                  textField='label'
                  placeholder='Select a timezone'
                  data={minimalTimezoneSet}
                  disabled={formDisable}
                />
              </Field>
            </Box>
            <Box columnSpan={24}>
              <DayPartsPicker
                onChange={(vals) => dayPartingSchedules.onChange(vals)}
                value={dayPartingSchedules.value}
                disabled={disableForm}
              />
              {dayPartingMeta.error && dayPartingMeta.touched ? (
                <Box
                  style={{ minHeight: '30px' }}
                  bg='negative.1'
                  color='negative.7'
                  px={2}
                  py={1}
                  borderRadius={4}
                  mt={1}
                >
                  <Text>{dayPartingMeta.error}</Text>
                </Box>
              ) : null}

              <Text fontSize={1} mt={3}>
                * Click and drag to
                <Text italic code>
                  select/deselect
                </Text>
                multiple times and days at once.
              </Text>
            </Box>
          </Grid>
        ) : (
          <Flex>
            <Help>Enable dayparting to choose times</Help>
          </Flex>
        )}
      </Card>
    </Flex>
  )
}

DeliveryForm.defaultProps = {
  values: {},
  initialValues: {},
  form: {},
}

DeliveryForm.propTypes = {
  values: PropTypes.object,
  superAdmin: PropTypes.bool,
}

export default DeliveryForm
