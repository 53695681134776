import { createEnum } from '../utils'

export const ACCOUNT_STATUS = createEnum({
  ACTIVE: {
    name: 'Active',
  },
  INACTIVE: {
    name: 'Inactive',
  },
  BANNED: {
    name: 'Inactive',
  },
})
