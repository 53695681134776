import React from 'react'
import { Filter, Radio, Badge } from '@beachfront/ui'
import { TagsOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { ACCOUNT_STATUS, ACCOUNT_TYPE } from '../../../../enums'
import {
  ContainsFilterPanel,
  formatStringFilterTag,
} from '../../../../components/filter'

const AccountFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='name'
        label='Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='status'
        label='Status'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={renderStatusIcon}
        formatTag={renderStatusTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            <Radio.Button value={ACCOUNT_STATUS.ACTIVE.key}>
              {ACCOUNT_STATUS.ACTIVE.name}
            </Radio.Button>
            <Radio.Button value={ACCOUNT_STATUS.BANNED.key}>
              {ACCOUNT_STATUS.BANNED.name}
            </Radio.Button>
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='type'
        label='Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<TagsOutlined />}
        formatTag={renderTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {ACCOUNT_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
    </Filter>
  )
}

function renderStatusIcon(desc) {
  if (desc.value === ACCOUNT_STATUS.ACTIVE.key) {
    return <Badge status='success' />
  }
  if (desc.value === ACCOUNT_STATUS.INACTIVE.key) {
    return <Badge status='error' />
  }
  return null
}

function renderStatusTag(desc) {
  const name = ACCOUNT_STATUS.fromKey(desc.value)?.name
  return name ? `Status is ${name}` : 'Any Status'
}

function renderTypeTag(desc) {
  const name = ACCOUNT_TYPE.fromKey(desc.value)?.name
  return name ? `Type is ${name}` : 'All Types'
}

AccountFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
}

export default AccountFilter
