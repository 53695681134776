import { formatCount } from '../../../utils'

export const attachMediaColumns = [
  {
    dataIndex: 'title',
    title: 'Title',
    sorter: true,
    width: 140,
    render: (val) => val || 'NA',
  },
  {
    dataIndex: 'dailyAvails',
    title: 'Daily Avails',
    sorter: true,
    width: 140,
    render: (val) => formatCount(val),
  },
]
