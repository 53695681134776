import http from '../http'

const ENDPOINT = 'segment/'

export default {
  create(data) {
    return http.post(`${ENDPOINT}uploaded/create`, data)
  },
  update(id, data) {
    return http.post(`${ENDPOINT}uploaded/update/${id}`, data)
  },
  getUploadedList({ params, ...data }) {
    return http.post(`${ENDPOINT}list/uploaded`, data, { params })
  },
  getSharedList({ params, ...data }) {
    return http.post(`${ENDPOINT}list/shared`, data, { params })
  },
  getUploadedById({ id }) {
    return http.get(`${ENDPOINT}uploaded/get/${id}`)
  },
  getSharedById({ id }) {
    return http.get(`${ENDPOINT}shared/get/${id}`)
  },
  getActiveUploadById({ id }) {
    return http.get(`${ENDPOINT}clearvu/${id}`)
  },
  getProviderList(params) {
    // Account specific
    return http.get(`${ENDPOINT}provider`, { params })
  },
  getAllProviders() {
    // Account independent
    return http.get(`${ENDPOINT}provider/all`)
  },
  getWidgets(params) {
    return http.get('chart/segment/realtime', { params })
  },
  getUserChart(id, params) {
    return http.get(`${ENDPOINT}area/user/${id}`, { params })
  },
  getRequestChart(data, params) {
    return http.post('chart/generate/area', data, {
      params: { ...params, segment: true },
    })
  },
  uploadFile(data, params, onUploadProgress) {
    return http.post('file/upload', data, { params, onUploadProgress })
  },
}
