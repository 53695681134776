import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDebounceCallback } from '@beachfront/ui/hooks'

import { useReportFilterValues } from '../../../hooks'
import { queryStringToObject, isString } from '../../../utils'
import { SelectField } from '../../../components'

const FilterValueSelect = ({ config, disabled, value, onChange, ...rest }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const params = queryStringToObject(config.params ?? '')

  const options = config.data?.map((val) => ({
    key: val,
    value: val,
  }))

  if (Object.hasOwn(params, 'dim')) {
    params.dim = config.key
  }

  if (Object.hasOwn(params, 'search')) {
    params.search = searchQuery
  }

  const queryEnabled =
    config.url &&
    config.key !== 'dealid' &&
    (searchQuery || (config.key !== 'domain' && config.key !== 'bundle'))

  const query = useReportFilterValues({
    params: { url: config.url, ...params },
    enabled: !!queryEnabled,
  })

  const onSearch = useDebounceCallback((val) => {
    setSearchQuery(val || '')
  }, 300)

  const onSelect = (val) => {
    if (config.key === 'dealid' && isString(val)) {
      let tags = val.split(' ')
      let oldVal = value || []
      tags = [...new Set(tags.sort())]
      oldVal = [...new Set(oldVal.sort())]
      let isSame = oldVal.every((val, i) => val === tags[i])
      let newVal = isSame ? [...tags] : [...oldVal, ...tags]
      newVal = [...new Set(newVal)].filter((str) => str !== '')
      onChange(newVal)
    }
  }

  return (
    <SelectField
      {...rest}
      showSearch
      mode={config.key === 'dealid' ? 'tags' : 'multiple'}
      data={query.data || options || []}
      keyField='key'
      textField={params.dim ? 'name' : 'value'}
      placeholder='Select Filter Values'
      loading={query.isFetching}
      emptyMsg={query.isLoading ? 'Loading Data...' : 'No Data Available'}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
      onSelect={onSelect}
      disabled={disabled}
    />
  )
}

FilterValueSelect.propTypes = {
  config: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default FilterValueSelect
