import React from 'react'
import { Card, Result } from '@beachfront/ui'

import { api } from '../../../client-api'
import { useFetch } from '../../../hooks'
import { isNotEmptyArray } from '../../../utils'
import { NetworkError, CommonSpinner, Widget } from '../../../components'

const BucketBarChart = ({ requestPayload }) => {
  const response = useFetch({
    request: api.reach.bucketChart,
    payload: requestPayload,
  })

  if (response.error) {
    return (
      <NetworkError
        description={response.error || 'Something went wrong.'}
        onAction={response.refresh}
      />
    )
  }

  return (
    <Card
      title={response.data?.title ?? 'FTrackHHID Frequency'}
      bodyStyle={{ height: 390 }}
    >
      {response.loading ? (
        <CommonSpinner />
      ) : (
        <>
          {response.data?.success && isNotEmptyArray(response.data?.data) ? (
            <Widget
              type={response.data?.type ?? 'BAR'}
              data={[response.data?.data]}
              cardBodyHeight={350}
              bordered={false}
              config={{
                unit: response.data?.yLabel ?? 'y-axis',
                xLabel: response.data?.xLabel ?? 'x-axis',
                barSize: 20,
              }}
            />
          ) : (
            <Result
              height='100%'
              status='warning'
              description={
                response.data.ed || response.data.msg || 'No Data Found'
              }
            />
          )}
        </>
      )}
    </Card>
  )
}

export default BucketBarChart
