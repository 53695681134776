import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table, Text } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'

import { useContentSize } from '../../../hooks'

const ReportDataTable = ({
  rows,
  columns,
  totals,
  searchQuery,
  loading,
  emptyText,
}) => {
  const { contentHeight } = useContentSize()

  const data = useMemo(
    () => applySearch(rows, columns, searchQuery),
    [rows, columns, searchQuery]
  )

  const table = useTableQuery({
    data: data,
  })

  const tableColumns = columns.map((column, i) => {
    return {
      dataIndex: String(i),
      title: column.title,
      fixed: i === 0 ? 'left' : false,
      width: 160,
      sorter: true,
      render: (val, row) => renderCell(row, column, i),
      summary: rows.length
        ? () => (
            <Text color='heading' strong>
              {renderCell(totals, column, i)}
            </Text>
          )
        : null,
    }
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 255,
  }

  return (
    <Table
      rowKey='key'
      fixedSummary='top'
      emptyText={emptyText || 'No Report Data Available'}
      dataSource={table.data}
      loading={loading}
      columns={tableColumns}
      scroll={scroll}
      pagination={{
        ...table.query.pagination,
        hideOnSinglePage: false,
        total: table.total,
      }}
      onChange={(e) => {
        table.setPagination(e.pagination)
        table.setSorter(e.sorter)
      }}
    />
  )
}

const applySearch = (rows, columns, searchQuery) => {
  if (!searchQuery) {
    return rows
  }

  const search = searchQuery.toString().toLowerCase()

  return rows.filter((row) => {
    for (let i = 0; i < columns.length; i++) {
      if (row[i]?.toString().toLowerCase().includes(search)) {
        return true
      }
    }
    return false
  })
}

const renderCell = (row, column, index) => {
  let value = row[index]

  if (column.type === 'NUMBER' || column.type === 'DOUBLE') {
    value = Number(value).toLocaleString('en-US', { maximumFractionDigits: 2 })
  }

  return (
    <>
      {column.prefix || ''}
      {value}
      {column.suffix || ''}
    </>
  )
}

ReportDataTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  totals: PropTypes.array,
  searchQuery: PropTypes.string,
  loading: PropTypes.bool,
  emptyText: PropTypes.node,
}

export default ReportDataTable
