import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { useTheme } from 'styled-components'
import { Flex, Box, Card, Checkbox, Heading } from '@beachfront/ui'
import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import moment from 'moment'
import PropTypes from 'prop-types'

import { VisualMenuTabs } from '.'

const ChartSummary = ({ data, dateFilter }) => {
  const theme = useTheme()
  const [selectedTab, setSelectedTab] = useState({})
  const [comparePrevious, setComparePrevious] = useState(false)

  const chartData = useMemo(() => {
    if (selectedTab?.chart) {
      const { chart } = selectedTab
      return chart.values.map((dt, i) => ({
        name: chart.xAxis[i],
        value: chart.values[i],
        previousValue: chart.previousValues[i]?.value,
        previousDate: chart.previousValues[i]?.key,
      }))
    }
    return []
  }, [selectedTab])

  useEffect(() => {
    const dt = data?.[0] ?? {}
    setSelectedTab(dt)
  }, [data])

  const formatData = (number) => {
    let str = number.toString()

    if (number > 1000000000) {
      str = (number / 1000000000).toString() + 'B'
    } else if (number > 1000000) {
      str = (number / 1000000).toString() + 'M'
    } else if (number > 1000) {
      str = (number / 1000).toString() + 'K'
    }

    const prefix = selectedTab.chart?.prefix
    const suffix = selectedTab.chart?.suffix

    if (prefix) {
      str = `${prefix}${str}`
    }

    if (suffix) {
      str = `${str}${suffix}`
    }

    return str
  }

  const onSelect = (itemTitle) => {
    let selectedMetric = {}
    if (itemTitle === 'Partner Revenue') {
      selectedMetric = data.find(
        (el) => el.chart.yaxisLabel === 'Revneue In Number'
      )
    } else if (itemTitle === 'Revenue') {
      selectedMetric = data.find(
        (el) => el.chart.yaxisLabel === 'Leads In Number'
      )
    } else {
      selectedMetric = data.find((el) => el.title === itemTitle)
    }
    if (selectedMetric) {
      selectedMetric.title = itemTitle
      setSelectedTab(selectedMetric)
    }
  }
  const onComparePreviousChange = (e) => {
    setComparePrevious(e.target.checked)
  }

  return (
    <Card height={400} compact>
      <Box borderBottom='split'>
        <VisualMenuTabs
          data={data}
          selectedTab={selectedTab}
          onSelect={onSelect}
          comparePrevious={comparePrevious}
        />
      </Box>
      {selectedTab ? (
        <Box width={1} height={315} px={2}>
          <Flex justifyContent='space-between' pt={3} px={2}>
            <Heading level={5}>{selectedTab.title}</Heading>
            <Box>
              <Checkbox
                onChange={onComparePreviousChange}
                checked={comparePrevious}
              >
                Compare Previous Period
              </Checkbox>
            </Box>
          </Flex>
          <Flex width='100%' height='100%' fontFamily='secondary' fontSize={0}>
            <ResponsiveContainer>
              <LineChart
                data={chartData}
                margin={{ top: 25, right: 25, left: 0, bottom: 50 }}
              >
                <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
                <XAxis
                  dataKey='name'
                  interval='preserveStartEnd'
                  axisLine={{ stroke: theme.colors.gray[4] }}
                  tickLine={{ stroke: theme.colors.gray[4] }}
                  tick={{ fill: theme.colors.gray[6] }}
                  tickMargin={8}
                  padding={{ left: 8, right: 8 }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={{ stroke: theme.colors.gray[4] }}
                  tickMargin={8}
                  tickFormatter={formatData}
                />
                <Tooltip
                  isAnimationActive={false}
                  cursor={{ stroke: theme.colors.gray[3] }}
                  wrapperStyle={{ zIndex: 1 }}
                  content={
                    <CustomTooltip
                      selectedTab={selectedTab}
                      dateFilter={dateFilter}
                    />
                  }
                />
                <Line
                  strokeWidth={2}
                  type='monotone'
                  dataKey='value'
                  stroke={theme.colors.primary.base}
                  dot={{ fill: theme.colors.bg.component, fillOpacity: 1 }}
                  activeDot={{ stroke: theme.colors.bg.component }}
                  isAnimationActive={false}
                />
                {comparePrevious ? (
                  <Line
                    strokeWidth={2}
                    type='monotone'
                    dataKey='previousValue'
                    strokeDasharray='6 3'
                    stroke={theme.colors.primary.base}
                    dot={{
                      fill: theme.colors.bg.component,
                      fillOpacity: 1,
                      strokeDasharray: '',
                    }}
                    activeDot={{ stroke: theme.colors.bg.component }}
                    isAnimationActive={false}
                  />
                ) : null}
              </LineChart>
            </ResponsiveContainer>
          </Flex>
        </Box>
      ) : null}
    </Card>
  )
}

const CustomTooltip = ({ label, payload, active, selectedTab, dateFilter }) => {
  if ((!active && !label) || !payload || payload.length === 0) {
    return null
  }

  const prefix = selectedTab.chart?.prefix
  const suffix = selectedTab.chart?.suffix

  return (
    <Box
      p={2}
      border='base'
      borderColor='popover.border'
      bg='popover.bg'
      boxShadow='base'
      borderRadius='base'
    >
      {payload?.map(
        ({ dataKey, payload, strokeDasharray, strokeWidth, stroke }) => (
          <Fragment key={dataKey}>
            {dataKey === 'previousValue' ? (
              <Box borderTop='split' my={2} />
            ) : null}
            <Flex gap={2} alignItems='center'>
              <Box
                mt={14}
                as='svg'
                alignSelf='center'
                viewBox='0 0 16 16'
                width='16'
                height='16'
              >
                <line
                  x1='0'
                  y1='8'
                  x2='16'
                  y2='8'
                  stroke={stroke}
                  strokeDasharray={strokeDasharray}
                  strokeWidth={strokeWidth}
                />
              </Box>
              <Box pr={2}>
                <Box>
                  {getDimensionDate(payload, dataKey, dateFilter, label)}
                </Box>
                <Box fontSize={1}>
                  {prefix ? <>{`${prefix}`}</> : null}
                  {getDimensionValue(payload[dataKey])}
                  {suffix ? <>{`${suffix}`}</> : null}
                </Box>
              </Box>
            </Flex>
          </Fragment>
        )
      )}
    </Box>
  )
}

function getDimensionValue(rawValue) {
  let value = 0
  let formattedDecimalValue = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  }).format(rawValue)
  let formattedValue = new Intl.NumberFormat('en-US').format(rawValue)

  if (Number(formattedDecimalValue)) {
    value = formattedDecimalValue
  } else if (formattedValue) {
    value = formattedValue
  }

  return value
}

function getDimensionDate(data, key, dateRange, defaultDate) {
  let date

  if (key === 'value') {
    if (defaultDate.includes('/')) {
      date = defaultDate
    } else if (dateRange?.startDate === dateRange?.endDate) {
      date = moment(dateRange.startDate, 'YYYY-MM-DD').format('MM/DD')
    } else {
      date = defaultDate
    }
  }

  if (key === 'previousValue') {
    date = data.previousDate
  }

  if (defaultDate.includes(':')) {
    date = `${date} ${defaultDate}`
  }

  return date
}

CustomTooltip.propTypes = {
  label: PropTypes.string,
  payload: PropTypes.array,
  active: PropTypes.bool,
  selectedTab: PropTypes.object,
  dateFilter: PropTypes.object,
}

ChartSummary.propTypes = {
  data: PropTypes.object,
  dateFilter: PropTypes.object,
}

export default ChartSummary
