import http from '../http'

const ENDPOINT = 'realtime/'

export default {
  transparency: {
    adomain(data) {
      return http.post(`${ENDPOINT}transparency/adomain`, data)
    },

    overallTakeRate(data) {
      return http.post(`${ENDPOINT}transparency/overall-take-rate`, data)
    },

    topSpends(data) {
      return http.post('realtime/transparency/top-spends', data)
    },

    seats: {
      top(data) {
        return http.post(`${ENDPOINT}transparency/seat`, data)
      },

      publisher(data) {
        return http.post(`${ENDPOINT}transparency/seat/publisher`, data)
      },

      adomain(data) {
        return http.post(`${ENDPOINT}transparency/seat/adomain`, data)
      },
    },

    supply: {
      active(data) {
        return http.post(`${ENDPOINT}transparency/supply/active`, data)
      },

      inactive(data) {
        return http.post(`${ENDPOINT}transparency/supply/inactive`, data)
      },

      add(params) {
        return http.get(`${ENDPOINT}transparency/supply/add/publisher`, {
          params,
        })
      },
    },

    agency: {
      publisher(data) {
        return http.post(`${ENDPOINT}transparency/agency/publisher`, data)
      },

      adomain(data) {
        return http.post(`${ENDPOINT}transparency/agency/adomain`, data)
      },
    },

    brand: {
      publisher(data) {
        return http.post(`${ENDPOINT}transparency/brand/publisher`, data)
      },
    },
  },
}
