import { ACCOUNT_TYPE } from '../../../../enums'

export const buildLinkData = (values) => {
  const linkData = []
  const link = values.link

  for (let i in link) {
    const { key } = link[i]

    if (values.type === ACCOUNT_TYPE.DSP.key) {
      const advertiserIdKey = `link-${key}-advertiserId`
      linkData.push({ advertiserId: values[advertiserIdKey] })
    }

    if (values.type === ACCOUNT_TYPE.PUBLISHER.key) {
      const pubIdKey = `link-${key}-pubId`
      linkData.push({ pubId: values[pubIdKey] })
    }

    if (values.type === ACCOUNT_TYPE.AGENCY.key) {
      const advertiserIdKey = `link-${key}-advertiserId`
      const data = { advertiserId: values[advertiserIdKey] }
      const seatOrAgencyKey = `link-${key}-seatOrAgency`
      const seatAgencyIdKey = `link-${key}-seatAgencyId`

      if (values[seatOrAgencyKey] === 'seat') {
        data.seatId = values[seatAgencyIdKey]
      } else {
        data.agencyId = values[seatAgencyIdKey]
      }

      linkData.push(data)
    }

    if (values.type === ACCOUNT_TYPE.BRAND.key) {
      const adomainKey = `link-${key}-adomain`
      linkData.push({ adomain: values[adomainKey] })
    }
  }

  return linkData
}
