import React, { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import {
  Box,
  Delta,
  Flex,
  ScrollArea,
  Text,
  VisualMenu,
  Result,
} from '@beachfront/ui'
import PropTypes from 'prop-types'

import { getChartColors, isNotEmptyArray } from '../../../utils'
import { StackedBarChartWidget } from '../../../components/widget'

import { TinyLineChart, Chart } from '.'

const Detail = ({ view, data, showDiffChart, onMetricSelect }) => {
  const [selectedItem, setSelectedItem] = useState(data[0])
  const selectedKey = selectedItem.key
  const selectedIndex = data.findIndex((dt) => dt.key === selectedKey)
  const theme = useTheme()
  const chartColors = getChartColors(theme.colors)

  const onSelect = ([key]) => {
    const item = data.find((dt) => dt.key === key)
    setSelectedItem(item)
    onMetricSelect?.()
  }

  const stackedData = useMemo(() => {
    if (showDiffChart) {
      let length = data[0].chartData.length
      let totalData = []
      let x = 0
      let y = 0
      let d = []

      data.forEach((el) => {
        totalData = [...totalData, ...el.chartData]
      })

      totalData.forEach((el) => {
        let arr = []

        if (d[y] && d[y].chartData && Array.isArray(d[y].chartData)) {
          arr = [...d[y].chartData]
        }

        d[y] = { chartData: arr }
        d[y].chartData[x] = el
        d[y].label = arr[0].label

        if (y >= length - 1) {
          if (x >= data.length - 1) {
            x = 0
          } else {
            x++
          }
          y = 0
        } else {
          y++
        }
      })
      return d
    }
  }, [data])

  return (
    <Flex height='100%'>
      <Box borderRight='split'>
        <ScrollArea height={300}>
          <VisualMenu
            orientation='vertical'
            selectedKeys={view === 'STACKED' ? [] : [selectedKey]}
            onSelect={onSelect}
            width={260}
            itemHeight={70}
          >
            {data.map((dt, i) => (
              <VisualMenu.Item key={dt.key} eventKey={dt.key}>
                <Flex
                  height='100%'
                  justifyContent='space-between'
                  alignItems='center'
                  fontFamily='secondary'
                >
                  <Flex
                    height='100%'
                    flexDirection='column'
                    justifyContent='center'
                  >
                    <Flex>
                      <Text
                        my='auto'
                        textTransform='uppercase'
                        fontSize={0}
                        strong
                      >
                        {dt.title}
                      </Text>
                      {dt.percent ? (
                        <Delta
                          my='auto'
                          ml={1}
                          value={dt.percent}
                          suffix='%'
                          precision={2}
                          fontSize={11}
                        />
                      ) : null}
                    </Flex>
                    <Box>
                      <Text fontSize={4} strong>
                        {dt.value.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    </Box>
                  </Flex>
                  <TinyLineChart
                    item={dt.chartData}
                    cc={chartColors[i % chartColors.length]}
                  />
                </Flex>
              </VisualMenu.Item>
            ))}
          </VisualMenu>
        </ScrollArea>
      </Box>
      {!isNotEmptyArray(selectedItem?.chartData) && (
        <Result mx='auto' status='warning' description='No Data Found' />
      )}
      {isNotEmptyArray(selectedItem?.chartData) && (
        <Box flex='auto' hidden={view === 'STACKED'}>
          <Chart
            view={view}
            data={selectedItem.chartData}
            selectedKey={selectedKey}
            colorOffset={selectedIndex}
          />
        </Box>
      )}
      {isNotEmptyArray(stackedData) && (
        <Box flex='auto' hidden={view !== 'STACKED'}>
          <StackedBarChartWidget
            data={stackedData}
            cardBodyHeight={350}
            bordered={false}
          />
        </Box>
      )}
    </Flex>
  )
}

Detail.propTypes = {
  view: PropTypes.string,
  data: PropTypes.array,
  showDiffChart: PropTypes.bool,
  onMetricSelect: PropTypes.func,
}

export default Detail
