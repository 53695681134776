import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Flex, Box, Text, Card, Empty, ScrollArea } from '@beachfront/ui'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'

import { getChartColors, isNotEmptyArray } from '../../utils'

const RADIAN = Math.PI / 180

export const DonutChartWidget = ({
  data,
  title,
  colors,
  detailLabel,
  total,
  totalTitle,
  showTotals = true,
  ...rest
}) => {
  const theme = useTheme()
  const chartColors = colors ?? getChartColors(theme.colors)

  return (
    <Card title={title} bodyStyle={{ height: '300px' }} {...rest}>
      <Flex gap={2} height='100%' fontFamily='secondary' fontSize={0}>
        {isNotEmptyArray(data) ? (
          <>
            <Flex
              flexDirection='column'
              width={detailLabel ? '50%' : '100%'}
              heigh={total ? 'calc(100% - 50px)' : '100%'}
            >
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={data}
                    nameKey='name'
                    dataKey='value'
                    paddingAngle={0}
                    innerRadius={40}
                    outerRadius={80}
                    labelLine={false}
                    label={<CustomLabel />}
                  >
                    {data.map((dt, i) => (
                      <Cell
                        key={i}
                        fill={chartColors[i % chartColors.length]}
                        stroke={chartColors[i % chartColors.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    isAnimationActive={false}
                    wrapperStyle={{ zIndex: 1 }}
                    content={<CustomTooltip total={total} />}
                  />
                </PieChart>
              </ResponsiveContainer>
              {total && showTotals ? (
                <Text textAlign='center' whiteSpace='nowrap'>
                  {totalTitle || 'Total'}: <Text strong>{total}</Text>
                </Text>
              ) : null}
            </Flex>
            {detailLabel ? (
              <Flex gap={2} width='50%' flexDirection='column'>
                <Text width='100%' fontSize={1} color='heading' strong ellipsis>
                  {detailLabel}
                </Text>
                <ScrollArea>
                  <Flex gap={2} flexDirection='column'>
                    {data.map((dt, i) => (
                      <Flex key={i} gap={1} title={dt.name} alignItems='center'>
                        <Box
                          size={8}
                          borderRadius='50%'
                          flex='none'
                          bg={chartColors[i % chartColors.length]}
                        />
                        <Text ellipsis>{dt.name}</Text>
                      </Flex>
                    ))}
                  </Flex>
                </ScrollArea>
              </Flex>
            ) : null}
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='No chart data found'
          />
        )}
      </Flex>
    </Card>
  )
}

DonutChartWidget.propTypes = {
  data: PropTypes.array,
  title: PropTypes.node,
  colors: PropTypes.array,
  detailLabel: PropTypes.node,
  total: PropTypes.number,
  totalTitle: PropTypes.string,
  showTotals: PropTypes.bool,
}

const CustomTooltip = ({ payload, active, total }) => {
  if (!active || !payload || payload.length === 0) {
    return null
  }

  return (
    <Box
      p={2}
      border='base'
      borderColor='popover.border'
      borderRadius='base'
      boxShadow='base'
      bg='popover.bg'
    >
      <Flex gap={2} alignItems='center'>
        <Box
          size={8}
          borderRadius='50%'
          flex='none'
          bg={payload[0].payload.fill}
        />
        <Text ellipsis>{payload[0].payload.name}</Text>
      </Flex>
      <Flex flexDirection='column' alignItems='end' fontSize={1}>
        <Text strong>{payload[0].payload.value}</Text>
        <Text>{Math.round((payload[0].payload.value * 100) / total)}%</Text>
      </Flex>
    </Box>
  )
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  active: PropTypes.bool,
  total: PropTypes.number,
}

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  if (Math.round(percent * 100) < 5) {
    return null
  }

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor='middle'
      dominantBaseline='central'
      style={{ pointerEvents: 'none' }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

CustomLabel.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  percent: PropTypes.number,
}
