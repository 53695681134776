import React from 'react'
import { Result, Button } from '@beachfront/ui'

const FatalError = () => {
  return (
    <Result
      py={5}
      status='error'
      title='Something went wrong'
      description='You may refresh the page or try again later.'
    >
      <Button type='primary' onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </Result>
  )
}

export default FatalError
