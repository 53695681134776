import React, { useState } from 'react'
import { Box, Flex, Text, VisualMenu } from '@beachfront/ui'
import { ArrowUpOutlined, ArrowDownOutlined } from '@beachfront/ui/icons'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

import { isBoolean } from '../../../utils'
import { useContentSize, useSiderCollapsed, useUserData } from '../../../hooks'
import { visualMenuItems } from '../@utils'
import { ACCOUNT_TYPE } from '../../../enums'

import TinyLineChart from './tiny-line-chart'

const VisualMenuTabs = ({ data, selectedTab, onSelect, comparePrevious }) => {
  const theme = useTheme()
  const { contentWidth } = useContentSize()
  const [siderCollapsed] = useSiderCollapsed()
  const [containerWidth] = useState({
    initialCollapse: siderCollapsed,
    width: contentWidth - 216,
  })
  const [user] = useUserData()

  const getChartData = (data) => {
    return data.values.map((dt, i) => ({
      name: data.xAxis[i],
      value: data.values[i],
      previousValue: data.previousValues[i]?.value ?? 0,
    }))
  }

  const getDeltaColor = (card) => {
    if (isBoolean(card?.inc)) {
      return card.inc ? 'positive.base' : 'negative.base'
    }
    return 'warning.base'
  }

  const getCardWidth = () => {
    let parentWidth = contentWidth - 216

    if (containerWidth.initialCollapse) {
      if (!siderCollapsed) {
        parentWidth -= 113
      }
    } else {
      if (siderCollapsed) {
        parentWidth += 113
      }
    }

    return Math.floor(parentWidth / data.length)
  }

  const getVisualMenuItems = () => {
    if (user.accountType.key === ACCOUNT_TYPE.PUBLISHER.key) {
      return visualMenuItems.filter((item) => item !== 'Revenue')
    } else if (user.accountType.key !== ACCOUNT_TYPE.RESELLER.key) {
      return visualMenuItems.filter((item) => item !== 'Partner Revenue')
    } else {
      return visualMenuItems
    }
  }

  const cardWidth = getCardWidth()
  const onVisualMenuSelect = (key) => onSelect(key[0])

  return (
    <VisualMenu
      orientation='horizontal'
      selectedKeys={[selectedTab?.title]}
      onSelect={onVisualMenuSelect}
      itemWidth={cardWidth}
      height={74}
    >
      {data.map((item, i) => (
        <VisualMenu.Item
          key={i}
          eventKey={getVisualMenuItems()[i]}
          style={{ padding: '8px 10px', borderRight: theme.borders.split }}
        >
          <Box fontFamily='secondary'>
            <Flex mb={2} justifyContent='space-between' alignItems='center'>
              <Text fontSize={1} strong ellipsis>
                {getVisualMenuItems()[i]}
              </Text>
              <Box
                fontSize={0}
                color={getDeltaColor(item?.card)}
                fontFamily='secondary'
                whiteSpace='nowrap'
              >
                {isBoolean(item?.card?.inc) ? (
                  <>
                    {item.card.inc ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )}
                  </>
                ) : null}
                {item.card.diff}
              </Box>
            </Flex>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontSize={2} color='text'>
                {item.card.value} {item.card.label}
              </Text>
              <Box width={cardWidth - 100} height={25}>
                <TinyLineChart
                  data={getChartData(item.chart)}
                  margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
                  stroke={theme.colors.primary.base}
                  showPrevious={comparePrevious}
                />
              </Box>
            </Flex>
          </Box>
        </VisualMenu.Item>
      ))}
    </VisualMenu>
  )
}

VisualMenuTabs.propTypes = {
  data: PropTypes.object,
  selectedTab: PropTypes.object,
  onSelect: PropTypes.func,
  comparePrevious: PropTypes.bool,
}

export default VisualMenuTabs
