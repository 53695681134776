import React, { useState, useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { Flex, Card, Radio, Table, Button, Tooltip } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import {
  DownloadOutlined,
  LayoutRowsOutlined,
  LayoutColumnsOutlined,
} from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { useFetch } from '../../../hooks'
import { isNotEmptyArray, formatCount } from '../../../utils'
import { NetworkError } from '../../../components'
import { platformColumns } from '../@constants'

const PlatformTable = ({ requestPayload }) => {
  const [tableView, setTableView] = useState('HORIZONTAL')

  const response = useFetch({
    request: api.reach.platformTable,
    payload: requestPayload,
  })

  const scroll = { x: '100%' }

  if (tableView === 'HORIZONTAL' && response.data?.data.length > 10) {
    scroll.y = 300
  }

  const getColumnWidth = (str) => {
    if (str && str.length > 14) {
      return (str.length + 1) * 10
    }
    return 150
  }

  const formatText = (text) => {
    return text?.replace(/[\W_]/g, '-')
  }

  const tableData = useMemo(() => {
    const data = {
      HORIZONTAL: {
        columns: [...platformColumns],
        rows: [],
      },
      VERTICAL: {
        columns: [],
        rows: [],
      },
    }

    if (response.data && response.data?.data) {
      const firstColumn = platformColumns[0]
      const verticalCols = response.data.data.map((el) => {
        return {
          dataIndex: el[firstColumn.dataIndex],
          title: el[firstColumn.dataIndex],
          width: getColumnWidth(el[firstColumn.dataIndex]),
          sorter: true,
          render: (val) => formatCount(val),
        }
      })

      verticalCols.splice(0, 0, firstColumn)
      const restColumns = [...platformColumns]
      restColumns.splice(0, 1)

      const verticalRows = restColumns.map((col) => {
        const row = { name: col.title }
        response.data.data.forEach((rowItem) => {
          const key = rowItem[firstColumn.dataIndex]
          row[key] = rowItem[col.dataIndex]
        })
        return row
      })

      data.HORIZONTAL = {
        columns: [...platformColumns],
        rows: [...response.data.data],
      }

      data.VERTICAL = {
        columns: verticalCols,
        rows: verticalRows,
      }

      const csvHorizontal = {
        col: [],
        row: [],
      }

      const csvVertical = {
        col: [],
        row: [],
      }

      if (isNotEmptyArray(platformColumns)) {
        csvHorizontal.col = platformColumns.map((el) => {
          return { key: el.dataIndex, label: el.title }
        })
        if (isNotEmptyArray(response.data?.data)) {
          csvHorizontal.row = response.data.data
        }
      }

      if (isNotEmptyArray(verticalCols)) {
        csvVertical.col = verticalCols.map((el) => {
          const name = formatText(el.title)
          return { key: name, label: el.title }
        })
        if (isNotEmptyArray(verticalRows)) {
          verticalRows.forEach((item) => {
            const obj = {}
            verticalCols.forEach((el) => {
              const name = formatText(el.title)
              obj[name] = item[el.dataIndex]
            })
            csvVertical.row.push(obj)
          })
        }
      }

      data.csvHorizontal = csvHorizontal
      data.csvVertical = csvVertical
    }
    return data
  }, [response.data])

  const table = useTableQuery({
    data: tableData[tableView].rows ?? [],
  })

  if (response.error) {
    return (
      <NetworkError
        description={response.error || 'Something went wrong.'}
        onAction={response.refresh}
      />
    )
  }

  const extra = (
    <Flex gap={2}>
      <CSVLink
        data={
          tableView === 'HORIZONTAL'
            ? tableData?.csvHorizontal?.row || []
            : tableData?.csvVertical?.row || []
        }
        headers={
          tableView === 'HORIZONTAL'
            ? tableData?.csvHorizontal?.col || []
            : tableData?.csvVertical?.col || []
        }
        uFEFF={false}
        filename={`${response?.data?.title}.csv`}
      >
        <Button
          icon={<DownloadOutlined />}
          disabled={
            response.loading || !isNotEmptyArray(tableData?.csvHorizontal?.row)
          }
        >
          Export
        </Button>
      </CSVLink>
      <Radio.Group
        value={tableView}
        onChange={(e) => setTableView(e.target.value)}
      >
        <Tooltip title='Horizontal View'>
          <Radio.Button value='HORIZONTAL'>
            <LayoutRowsOutlined />
          </Radio.Button>
        </Tooltip>
        <Tooltip title='Vertical View'>
          <Radio.Button value='VERTICAL'>
            <LayoutColumnsOutlined />
          </Radio.Button>
        </Tooltip>
      </Radio.Group>
    </Flex>
  )

  return (
    <Card
      title={response.data?.title ?? ''}
      extra={extra}
      bodyStyle={{ minHeight: tableView === 'HORIZONTAL' ? 330 : 150 }}
      compact
    >
      <Table
        key={tableView}
        rowKey={(rec, index) => index}
        emptyText='No Data Available'
        dataSource={table.data}
        columns={tableData[tableView].columns}
        loading={response.loading}
        scroll={scroll}
        pagination={false}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
    </Card>
  )
}

export default PlatformTable
