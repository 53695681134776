import moment from 'moment'

import { isNotEmptyArray, isValidEmail } from '../../../utils'

export const validateReportForm = (values) => {
  const errors = {
    conditions: [],
  }

  if (values.name) {
    values.name = values.name.trim()
  }

  if (
    values.dimensions?.includes('hour') &&
    values.startDate &&
    values.endDate &&
    moment(values.endDate, 'YYYY-MM-DD').toDate().getDate() -
      moment(values.startDate, 'YYYY-MM-DD').toDate().getDate() !==
      0
  ) {
    errors.endDate = 'Date range should be within 24 hours.'
  }

  if (values.name && !values.name.match(/^[a-zA-Z\d\s]+$/)) {
    errors.name = 'No special character is allowed.'
  }

  if (!values.name) {
    errors.name = 'Required'
  }

  if (values.schedule) {
    if (values.emailList && isNotEmptyArray(values.emailList)) {
      const isValid = values.emailList.every((email) =>
        isValidEmail(email.trim())
      )
      if (!isValid) {
        errors.emailList = 'Please enter valid emails.'
      }
    }
    if (!isNotEmptyArray(values.emailList)) {
      errors.emailList = 'Please enter at least one email.'
    }
    if (!values.hour) {
      errors.hour = 'Required'
    }
    if (!values.frequency) {
      errors.frequency = 'Required'
    }
  }

  if (!values.range) {
    errors.range = 'Required'
  }

  if (!values.timezone) {
    errors.timezone = 'Required'
  }

  if (values.timezone === 'CUSTOM' && !values.offset) {
    errors.offset = 'Required'
  }

  if (
    !values.dimensions ||
    (values.dimensions && values.dimensions.length === 0)
  ) {
    errors.dimensions = 'Required'
  }

  if (
    values.dimensions &&
    values.dimensions.length > 0 &&
    values.dimensions.includes('domain')
  ) {
    if (values.dimensions.includes('bundle')) {
      errors.dimensions = 'Please select either Domain or Bundle.'
    } else if (values.dimensions.includes('appname')) {
      errors.dimensions = 'Please select either Domain or App Name.'
    }
  }

  if (
    values.dimensions &&
    values.dimensions.length > 0 &&
    values.dimensions.includes('day') &&
    values.dimensions.includes('hour')
  ) {
    errors.dimensions = 'Please select either Day or Hour.'
  }

  if (
    values.dimensions &&
    values.dimensions.length > 0 &&
    values.dimensions.includes('hour') &&
    values.range !== 'custom' &&
    values.range !== 'today' &&
    values.range !== 'yesterday'
  ) {
    errors.range =
      'Please select range Today, Yesterday or Custom in case of Hour reporting key.'
  }

  if (!values.measures || (values.measures && values.measures.length === 0)) {
    errors.measures = 'Required'
  }

  if (values.range === 'custom') {
    if (!values.startDate) {
      errors.startDate = 'Required'
    }
    if (!values.endDate) {
      errors.endDate = 'Required'
    }
  }

  values.conditions?.forEach((con, i) => {
    if (!con.dimension) {
      errors.conditions[i] = errors.conditions[i] || {}
      errors.conditions[i].dimension = 'Required'
    }
    if (!con.operation) {
      errors.conditions[i] = errors.conditions[i] || {}
      errors.conditions[i].operation = 'Required'
    }
    if (!isNotEmptyArray(con.value)) {
      errors.conditions[i] = errors.conditions[i] || {}
      errors.conditions[i].value = 'Required'
    }
  })

  return errors
}
