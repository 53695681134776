import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Flex, Card, Result, Tooltip, Button } from '@beachfront/ui'
import { ExpandOutlined, TableOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { api, http } from '../../../client-api'
import {
  isNotEmptyArray,
  isNotEmptyObject,
  queryStringToObject,
} from '../../../utils'
import {
  SimpleBarChartWidget,
  DonutChartWidget,
  StackedBarChartWidget,
  MultipleBarChartWidget,
  AreaChartWidget,
  LineChartWidget,
  ComposedBarLineChartWidget,
} from '../../../components/widget'

import { InsightFullViewModal, InsightDetailModal } from '.'

const InsightWidget = ({ index, data, params, timezone, searchQuery }) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [isFullViewOpen, setIsFullViewOpen] = useState(false)
  const [fullViewData, setFullViewData] = useState(null)

  const widgetParams = getWidgetParams(
    data.params,
    params,
    timezone,
    searchQuery
  )

  const widget = useWidgetData(data.url, widgetParams)
  const detail = useWidgetDetail(widgetParams, isDetailOpen)

  const supportsTable =
    data.title === 'Top 5 Domain' || data.title === 'Top 5 Bundle'

  if (widget.isLoading) {
    return <Card size='small' height='100%' loading />
  }

  if (widget.error) {
    return (
      <Card size='small' height='100%' title={data.title}>
        <Result
          height='100%'
          status='error'
          description='Unable to fetch data.'
        />
      </Card>
    )
  }

  if (widget.data && !widget.data.success) {
    return (
      <Card size='small' height='100%' title={widget.data.title || data.title}>
        <Result
          height='100%'
          status='warning'
          description={widget.data.ed || widget.data.msg || 'No Data Found'}
        />
      </Card>
    )
  }

  const extra = (
    <Flex gap={1}>
      {supportsTable && isNotEmptyObject(data) ? (
        <Tooltip title='View All' placement='bottom'>
          <Button
            type='text'
            size='small'
            icon={<TableOutlined />}
            onClick={() => setIsDetailOpen(true)}
          />
        </Tooltip>
      ) : null}
      {isNotEmptyObject(widget.data) ? (
        <Tooltip title='Expand' placement='bottom'>
          <Button
            type='text'
            size='small'
            icon={<ExpandOutlined />}
            onClick={() => {
              setFullViewData(widget.data)
              setIsFullViewOpen(true)
            }}
          />
        </Tooltip>
      ) : null}
    </Flex>
  )

  return (
    <Card
      size='small'
      height='100%'
      style={{ position: 'relative' }}
      title={widget.data?.title ?? data.title}
      extra={extra}
    >
      {widget.data.type === 'BAR' ? (
        <>
          {isNotEmptyArray(widget.data.data) ? (
            <SimpleBarChartWidget
              data={widget.data.data}
              unit={widget.data.unit}
              vertical={widget.data.vertical}
              xUnit={widget.data.xUnit}
              xLabel={widget.data.xLabel}
              colorOffset={index}
              cardBodyHeight={310}
              bordered={false}
              compact
            />
          ) : (
            <Result
              height='100%'
              status='warning'
              description='No Data Found'
            />
          )}
        </>
      ) : null}
      {widget.data.type === 'PIE' ? (
        <>
          {isNotEmptyArray(widget.data.data) &&
          isValidPieData(widget.data.data) ? (
            <DonutChartWidget
              data={widget.data.data}
              total={widget.data.total}
              totalTitle='Total Rejected Bids'
              detailLabel='Reasons'
              bordered={false}
              compact
            />
          ) : (
            <Result
              height='100%'
              status='warning'
              description='No Data Found'
            />
          )}
        </>
      ) : null}
      {widget.data.type === 'STACKED_BAR' ? (
        <>
          {isNotEmptyArray(widget.data.data) ? (
            <StackedBarChartWidget
              data={widget.data.data}
              range={widget.data.range}
              yUnit={widget.data.yUnit}
              bordered={false}
              compact
            />
          ) : (
            <Result
              height='100%'
              status='warning'
              description='No Data Found'
            />
          )}
        </>
      ) : null}
      {widget.data.type === 'AREA' ? (
        <>
          {isNotEmptyObject(widget.data.data) ? (
            <AreaChartWidget
              data={widget.data.data}
              toolTipLabel={widget.data.title}
              bordered={false}
              compact
            />
          ) : (
            <Result
              height='100%'
              status='warning'
              description='No Data Found'
            />
          )}
        </>
      ) : null}
      {widget.data.type === 'LINE' ? (
        <>
          {isNotEmptyArray(widget.data.data) ? (
            <LineChartWidget data={widget.data.data} bordered={false} compact />
          ) : (
            <Result
              height='100%'
              status='warning'
              description='No Data Found'
            />
          )}
        </>
      ) : null}
      {widget.data.type === 'BAR_LINE' ? (
        <>
          {isNotEmptyArray(widget.data.data) ? (
            <ComposedBarLineChartWidget
              data={widget.data.data}
              bordered={false}
              compact
            />
          ) : (
            <Result
              height='100%'
              status='warning'
              description='No Data Found'
            />
          )}
        </>
      ) : null}
      {widget.data.type === 'MULTIPLE_BAR' ? (
        <>
          {isNotEmptyArray(widget.data.data) ? (
            <MultipleBarChartWidget
              data={widget.data.data}
              range={widget.data.range}
              yUnit={widget.data.yUnit}
              bordered={false}
              compact
            />
          ) : (
            <Result
              height='100%'
              status='warning'
              description='No Data Found'
            />
          )}
        </>
      ) : null}
      {supportsTable && isNotEmptyObject(data) ? (
        <InsightDetailModal
          isOpen={isDetailOpen}
          title={data.title}
          data={detail.data}
          loading={detail.isLoading}
          onCancel={() => setIsDetailOpen(false)}
        />
      ) : null}
      <InsightFullViewModal
        isOpen={isFullViewOpen}
        index={index}
        responseData={fullViewData}
        onCancel={() => setIsFullViewOpen(false)}
        afterClose={() => setFullViewData(null)}
      />
    </Card>
  )
}

const getWidgetParams = (queryStr, params, timezone, searchQuery) => {
  const obj = queryStringToObject(queryStr)

  if (isNotEmptyObject(params)) {
    if (params.type === 'custom') {
      obj.range = params.type
      obj.startDate = params.startDate
      obj.endDate = params.endDate
    } else {
      obj.range = params.type
    }
    if (params.admin) {
      obj.admin = true
    }
    if (params.dealId) {
      obj.dealId = params.dealId
    } else {
      obj.q = encodeURIComponent(searchQuery)
    }
  }
  obj.timezone = timezone
  return obj
}

const useWidgetData = (url, params) => {
  return useQuery({
    queryKey: ['widget-data', url, params],
    queryFn: async ({ signal }) => {
      const res = await http.get(url, { params, signal })
      return res.data
    },
  })
}

const useWidgetDetail = ({ id, ...params }, enabled) => {
  return useQuery({
    queryKey: ['widget-detail', id, params],
    queryFn: async ({ signal }) => {
      const res = await api.insight.widgetDetail({ id, params, signal })
      return res.data.content.map((dt) => ({
        ...dt,
        // TODO send revenue as number from server
        revenue: Number(dt.revenue.toString().replace('$', '')),
      }))
    },
    enabled,
  })
}

const isValidPieData = (pieData = []) => {
  for (const element of pieData) {
    if (element.value) {
      return true
    }
  }
  return false
}

InsightWidget.defaultProps = {
  params: {},
  data: {},
}

InsightWidget.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  params: PropTypes.object,
  timezone: PropTypes.string,
  searchQuery: PropTypes.string,
}

export default InsightWidget
