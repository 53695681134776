import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Text, Popover } from '@beachfront/ui'
import { InfoCircleOutlined } from '@beachfront/ui/icons'
import { useTheme } from 'styled-components'

const MetricCard = ({ index, label, value, tooltip, ...rest }) => {
  const theme = useTheme()
  const cardColors = [
    theme.colors.green,
    theme.colors.gold,
    theme.colors.magenta,
    theme.colors.purple,
  ]
  const colorScale = cardColors[index % cardColors.length]

  return (
    <Flex
      p={1}
      flexDirection='column'
      border='base'
      borderRadius='base'
      borderColor={colorScale[2]}
      bg={colorScale.light}
      color={colorScale[6]}
      textAlign='center'
      position='relative'
      overflow='hidden'
      {...rest}
    >
      <Flex alignItems='flex-start' justifyContent='center' flexGrow={1}>
        <Text color='inherit' whiteSpace='wrap' fontSize={0} strong>
          {label}
          {tooltip ? (
            <Popover
              content={<Box maxWidth={220}>{tooltip}</Box>}
              placement='topRight'
              arrowPointAtCenter
            >
              <Box as='span' ml={1}>
                <InfoCircleOutlined style={{ color: colorScale.base }} />
              </Box>
            </Popover>
          ) : null}
        </Text>
      </Flex>
      <Box title={String(value)}>
        <Text my='auto' fontFamily='secondary' fontSize={1} strong>
          {value}
        </Text>
      </Box>
    </Flex>
  )
}

MetricCard.propTypes = {
  index: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.any,
  tooltip: PropTypes.string,
}

export default MetricCard
