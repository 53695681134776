import React, { useState } from 'react'
import { Flex, Card, Text, Table } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { MEDIA_LIST_TYPE } from '../../../enums'
import { useContentSize } from '../../../hooks'
import { PageSearch } from '../../../components'

const InvalidMediaTable = ({ data, mediaType }) => {
  const { contentHeight } = useContentSize()
  const [searchQuery, setSearchQuery] = useState('')

  const matchesQuery = (dt) => {
    return String(dt.title).toLowerCase().includes(searchQuery.toLowerCase())
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const table = useTableQuery({
    data: applyQuery(data ?? []),
  })

  const scroll = {
    y: contentHeight - 510,
  }

  const columns = [
    {
      dataIndex: 'title',
      title: MEDIA_LIST_TYPE.fromKey(mediaType)?.name ?? mediaType,
      sorter: true,
      render: (val) => <Text color='negative.base'>{val}</Text>,
    },
  ]

  return (
    <>
      <Flex my={3} justifyContent='flex-end'>
        <PageSearch value={searchQuery} onChange={setSearchQuery} />
      </Flex>
      <Card compact>
        <Table
          rowKey={(rec, index) => index}
          dataSource={table.data}
          columns={columns}
          scroll={scroll}
          pagination={{
            ...table.query.pagination,
            hideOnSinglePage: false,
            total: table.total,
          }}
          onChange={(e) => {
            table.setPagination(e.pagination)
            table.setSorter(e.sorter)
          }}
        />
      </Card>
    </>
  )
}

InvalidMediaTable.propTypes = {
  data: PropTypes.array,
  mediaType: PropTypes.string,
}

export default InvalidMediaTable
