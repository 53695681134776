import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from '@beachfront/ui'

import { plural } from '../../../../utils'

const AccountPreviewTags = ({ visible, values }) => {
  if (!visible) {
    return null
  }

  const includeCount = values.inventoryInfoAccIds?.length ?? 0
  const excludeCount = values.accountExcludeList?.length ?? 0

  if (includeCount) {
    return (
      <Tag color='processing' flex='none'>
        {plural`${includeCount - excludeCount} Account[|s]`}
      </Tag>
    )
  }

  return null
}

AccountPreviewTags.propTypes = {
  visible: PropTypes.bool,
  values: PropTypes.object,
}

export default AccountPreviewTags
