import React from 'react'
import PropTypes from 'prop-types'
import { Box, Switch } from '@beachfront/ui'
import { Field, useFormState } from '@beachfront/ui/forms'

import { api } from '../../../../client-api'
import { useUserData } from '../../../../hooks'
import { BooleanSegmentProvider } from '../booleanSegment/boolean-segment-context'
import VirtualSelectField from '../virtual-select-field'
import IncludeExcludeField from '../include-exclude-field'

import ManageExpression from './manage-expression/manage-expression'
import SimpleOptions from './simple-options/simple-options'

const ContentSegment = ({
  id,
  formDisable,
  initialValues,
  params,
  getPopupContainer,
}) => {
  const { values } = useFormState()
  const [user] = useUserData()

  const allowBooleanLogic = user?.featureToggles?.contentBooleanLogic

  return (
    <Box overflow='initial'>
      {allowBooleanLogic ? (
        <Box className='customize-radio'>
          <Field
            name='contentBooleanLogic.enabled'
            label='Content Targeting Logic'
            extra={<Box m='auto' />}
            inline
          >
            <Switch
              checkedContent='Advanced'
              uncheckedContent='Simple'
              disabled={!allowBooleanLogic || formDisable}
            />
          </Field>
        </Box>
      ) : null}
      {values?.contentBooleanLogic?.enabled && allowBooleanLogic ? (
        <BooleanSegmentProvider>
          <Box mb={3}>
            <ManageExpression formDisable={formDisable} params={params} />
          </Box>
        </BooleanSegmentProvider>
      ) : (
        <SimpleOptions
          formDisable={formDisable}
          id={id}
          initialValues={initialValues}
          params={params}
          getPopupContainer={getPopupContainer}
        />
      )}
      <Box position='relative'>
        {!user.mediaPlanExclude ? (
          <Box
            position='absolute'
            height='32px'
            right='-7px'
            top='-7px'
            className='customize-radio'
          >
            <IncludeExcludeField name='excludeiabCat' disabled={formDisable} />
          </Box>
        ) : null}
        <VirtualSelectField
          mode='multiple'
          allowClear
          request={api.common.iabCategory}
          apiParams={params}
          detail={id}
          values={values}
          initialValues={initialValues}
          disabled={formDisable}
          getPopupContainer={getPopupContainer}
          emptyMsg='No IAB Category Available'
          form={{
            name: 'iabCategory',
            textField: 'name',
            keyField: 'key',
            label: 'IAB Category',
            placeholder: 'Select IAB Category',
            selectionName: 'iabCategoryObj',
          }}
        />
      </Box>
      <Box position='relative'>
        {!user.mediaPlanExclude ? (
          <Box
            position='absolute'
            height='32px'
            right='-7px'
            top='-7px'
            className='customize-radio'
          >
            <IncludeExcludeField name='excludeRating' disabled={formDisable} />
          </Box>
        ) : null}
        <VirtualSelectField
          mode='multiple'
          allowClear
          detail={id}
          request={api.common.rating}
          apiParams={params}
          values={values}
          initialValues={initialValues}
          disabled={formDisable}
          getPopupContainer={getPopupContainer}
          emptyMsg='No Rating Available'
          form={{
            name: 'rating',
            keyField: 'key',
            textField: 'name',
            placeholder: 'Select Rating',
            selectionName: 'ratingObj',
            label: 'Rating',
          }}
        />
      </Box>
      {/* <Box>
        <Field
          name='includeLivestream'
          label='Livestream'
          extra={<Box m='auto' />}
          inline
        >
          <Switch disabled={formDisable} />
        </Field>
      </Box> */}
    </Box>
  )
}

ContentSegment.propTypes = {
  id: PropTypes.string,
  formDisable: PropTypes.bool,
  initialValues: PropTypes.object,
  params: PropTypes.object,
  getPopupContainer: PropTypes.func,
}

export default ContentSegment
