import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Label } from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'

import { SelectField } from '../../../components'
import { yesNoList } from '..'

const AccountGeneralForm = ({ accountFilters = {}, getPopupContainer }) => {
  return (
    <Grid gap={2} columns={['1fr 1fr', '2fr 3fr']}>
      <Label pt={1} whiteSpace='normal'>
        Sensitive Categories Allowed
      </Label>
      <Box>
        <Field name='sensitiveCatAllowed'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.sensitiveCategories || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Sensitive Ad Category: Creative Approval Required?
      </Label>
      <Box>
        <Field name='reqCreativeApproval'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Competitive Separation Required?
      </Label>
      <Box>
        <Field name='reqCompetitiveSeparation'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Content Type
      </Label>
      <Box>
        <Field name='contentType'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.contentType || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
    </Grid>
  )
}

AccountGeneralForm.propTypes = {
  accountFilters: PropTypes.object,
  getPopupContainer: PropTypes.func,
}

export default AccountGeneralForm
