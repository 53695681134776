import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Popover } from '@beachfront/ui'
import { InfoCircleOutlined } from '@beachfront/ui/icons'

const LabelTooltip = ({ width = 200, content, ...rest }) => {
  if (!content) {
    return null
  }
  return (
    <Popover
      trigger='click'
      content={<Box maxWidth={width}>{content}</Box>}
      {...rest}
    >
      <Button
        size='small'
        type='link'
        icon={<InfoCircleOutlined />}
        style={{ width: '20px', height: '20px', fontSize: '16px' }}
      />
    </Popover>
  )
}

LabelTooltip.defaultProps = {
  placement: 'topLeft',
  arrowPointAtCenter: true,
}

LabelTooltip.propTypes = {
  width: PropTypes.number,
  content: PropTypes.node,
}

export default LabelTooltip
