import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Flex, Box, Button, Card, Tabs } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../../client-api'
import { isArray, isString } from '../../../../utils'
import { PageHeader, NetworkError, CommonSpinner } from '../../../../components'
import { SegmentFieldForm, SegmentHistory } from '../../uploaded'

import { SegmentWidgets } from '.'

const SharedSegmentDetail = ({ isUpload }) => {
  const { id, providerType, tabKey = 'manage' } = useParams()
  const navigate = useNavigate()

  const backUrl = isUpload
    ? '/segment/upload'
    : `/segment/shared/${providerType}`

  const segment = useQuery({
    queryKey: ['shared-segments', { id, isUpload }],
    queryFn: async () => {
      const requestFn = isUpload
        ? api.segment.getActiveUploadById
        : api.segment.getSharedById
      const res = await requestFn({ id })
      return res.data
    },
  })

  const initialValues = useMemo(() => {
    const values = {}
    if (segment.data) {
      Object.assign(values, segment.data, {
        remoteId: segment.data.sourceSegmentId || 'NA',
        ingest: !!segment.data.ingest,
      })

      if (segment.data.price) {
        values.price = Number(segment.data.price)
          ? `${Math.round(Number(segment.data.price) * 100) / 100}`
          : 'NA'
      }

      if (isUpload && segment.data.price === null) {
        values.price = 'NA'
      }

      if (segment.data.sourceProvider) {
        if (isArray(segment.data.sourceProvider)) {
          values.sourceProvider = segment.data.sourceProvider
        } else if (isString(segment.data.sourceProvider)) {
          values.sourceProvider = [segment.data.sourceProvider]
        }
      }

      if (segment.data.remoteId) {
        values.remoteId = segment.data.remoteId
      }
    }
    return values
  }, [segment.data])

  const onTabChange = (key) => {
    return isUpload
      ? navigate(`/segment/upload/clearvu/${id}/${key}`)
      : navigate(`/segment/shared/${providerType}/${id}/${key}`)
  }

  if (segment.isFetching) {
    return <CommonSpinner />
  }

  if (segment.error) {
    return (
      <NetworkError
        description={segment.error || 'Segment Not Found'}
        buttonLabel={
          isUpload ? 'Back to Uploaded Segments' : 'Back to Shared Segments'
        }
        onAction={() => navigate(backUrl)}
      />
    )
  }

  const manageContent = (
    <>
      <Card minWidth={350}>
        <SegmentFieldForm
          isOwned={segment.data?.owned}
          isUpload={isUpload}
          isShared={true}
        />
      </Card>
      <Box flexGrow={1} overflow='auto'>
        <SegmentWidgets segmentId={id} isOwned={segment.data?.owned} />
      </Box>
    </>
  )

  return (
    <Form initialValues={initialValues} onSubmit={() => {}}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title={`Segment - ${segment.data?.name ?? ''}`}
            actions={
              <Link to={backUrl}>
                <Button>Cancel</Button>
              </Link>
            }
          />
          <Box mt={3}>
            {isUpload ? (
              <Tabs type='card' activeKey={tabKey} onChange={onTabChange}>
                <Tabs.TabPane key='manage' tab='Manage'>
                  <Flex mt={2} gap={3} height='calc(100vh - 190px)'>
                    {manageContent}
                  </Flex>
                </Tabs.TabPane>
                <Tabs.TabPane key='history' tab='History'>
                  <SegmentHistory segmentId={id} />
                </Tabs.TabPane>
              </Tabs>
            ) : (
              <Flex gap={3} height='calc(100vh - 130px)'>
                {manageContent}
              </Flex>
            )}
          </Box>
        </form>
      )}
    </Form>
  )
}

SharedSegmentDetail.propTypes = {
  isUpload: PropTypes.bool,
}

export default SharedSegmentDetail
