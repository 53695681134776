import React, { useState, useEffect } from 'react'
import { Flex, Button, Table } from '@beachfront/ui'
import { useDisclosure, useTableQuery } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { useFetch } from '../../../hooks'
import { isNotEmptyArray, formatCount } from '../../../utils'
import { PageSearch, NetworkError } from '../../../components'

import { AccountDetailModal } from '.'

const AccountList = ({ values, onAccountListChange, onDisabledChange }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [detailAccount, setDetailAccount] = useState(null)
  const detailModal = useDisclosure()

  const response = useFetch({
    request: api.mediaPlan.publisherAccountList,
    payload: { data: values },
  })

  const matchesQuery = (dt) => {
    return ['id', 'company'].some((key) =>
      String(dt[key]).toLowerCase().includes(searchQuery.toLowerCase())
    )
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const table = useTableQuery({
    data: applyQuery(response.data?.data || []),
  })

  const scroll = {
    y: isNotEmptyArray(response.data?.data) ? 384 : 432,
  }

  useEffect(() => {
    const accountList = response.data?.data ?? []
    const disabled = !accountList.length
    const eligibleIds = []

    accountList.forEach((acc) => {
      acc.eligibleApps?.forEach((app) => {
        eligibleIds.push(app.id)
      })
    })

    onAccountListChange(accountList, eligibleIds)
    onDisabledChange(disabled)
  }, [response.data])

  const openModal = (data = {}) => {
    setDetailAccount(data)
    detailModal.open()
  }

  const closeModal = () => {
    detailModal.close()
    setDetailAccount(null)
  }

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      fixed: 'left',
      width: 60,
      render: (id, rec) => (
        <Button
          size='small'
          type='link'
          onClick={() => openModal(rec)}
          style={{ marginLeft: '-8px' }}
        >
          {id}
        </Button>
      ),
    },
    {
      dataIndex: 'company',
      title: 'Company',
      width: 160,
      sorter: true,
    },
    {
      dataIndex: 'eligibleAppsCount',
      title: 'Eligible Inventory',
      width: 120,
      sorter: true,
      render: (val) => formatCount(val),
    },
    {
      dataIndex: 'noneligibleAppsCount',
      title: 'Non Eligible Inventory',
      width: 145,
      sorter: true,
      render: (val) => formatCount(val),
    },
  ]

  if (response.error) {
    return (
      <NetworkError
        description='Something went wrong.'
        onAction={response.refresh}
      />
    )
  }

  return (
    <>
      <Flex mb={2} justifyContent='flex-end'>
        <PageSearch value={searchQuery} onChange={setSearchQuery} />
      </Flex>
      <Table
        rowKey='id'
        emptyText='No Accounts Available'
        dataSource={table.data}
        columns={columns}
        loading={response.loading}
        scroll={scroll}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: false,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
      <AccountDetailModal
        title='Inventory Details'
        data={detailAccount}
        isOpen={detailModal.isOpen}
        onClose={closeModal}
      />
    </>
  )
}

AccountList.propTypes = {
  values: PropTypes.object,
  onAccountListChange: PropTypes.func,
  onDisabledChange: PropTypes.func,
}

export default AccountList
