import http from '../http'

const ENDPOINT = 'audsegboollogic'

export default {
  check(params) {
    return http.post(`${ENDPOINT}/check`, params)
  },
  cost(params) {
    return http.post('mediaplan/segment/advance/cost', params)
  },
  contentCheck(params) {
    return http.post(`${ENDPOINT}/check?contentData=true`, params)
  },
}
