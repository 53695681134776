import React, { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Flex, Card, Button, Table } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import { DownloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { useFetch } from '../../../hooks'
import { isNotEmptyArray, getErrorMessage } from '../../../utils'
import { PageSearch, NetworkError } from '../../../components'
import { demographicColumns } from '../@constants'

const DemographicTable = ({ requestPayload }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const response = useFetch({
    request: api.reach.demographicTable,
    payload: requestPayload,
  })

  const matchesQuery = (dt) => {
    return String(dt.name).toLowerCase().includes(searchQuery.toLowerCase())
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const table = useTableQuery({
    data: applyQuery(response.data?.data ?? []),
  })

  const csvData = useMemo(() => {
    let csvCol = []
    let csvRow = []

    if (isNotEmptyArray(response.data?.columns)) {
      csvCol = response.data.columns.map((el) => {
        return { label: el.name, key: el.key }
      })
      if (isNotEmptyArray(response.data?.data)) {
        csvRow = response.data.data
      }
    }

    return { csvCol, csvRow }
  }, [response.data])

  if (!response.loading && response.error) {
    return (
      <NetworkError
        description={getErrorMessage(response.error)}
        onAction={response.refresh}
      />
    )
  }

  const extra = (
    <Flex gap={2}>
      <CSVLink
        data={csvData?.csvRow}
        headers={csvData?.csvCol}
        uFEFF={false}
        filename={`${response?.data?.title}.csv`}
      >
        <Button
          icon={<DownloadOutlined />}
          disabled={response.loading || !isNotEmptyArray(csvData?.csvRow)}
        >
          Export
        </Button>
      </CSVLink>
      <PageSearch
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search Demographic Area...'
      />
    </Flex>
  )

  return (
    <Card
      title={response.data?.title ?? 'Demographic Wise Reach'}
      extra={extra}
      bodyStyle={{ minHeight: 287 }}
      compact
    >
      <Table
        rowKey='name'
        emptyText='No Data Available'
        columns={demographicColumns}
        dataSource={table.data}
        loading={response.loading}
        scroll={{ y: 300 }}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: false,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
    </Card>
  )
}

export default DemographicTable
