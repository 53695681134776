import React from 'react'
import { Filter } from '@beachfront/ui'
import { CalendarOutlined } from '@beachfront/ui/icons'

import {
  DateRangeFilterPanel,
  formatDateFilterTag,
} from '../../../components/filter'

const ReachFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='date'
        icon={<CalendarOutlined />}
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        removable={false}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateRangeFilterPanel {...props} />}
      />
    </Filter>
  )
}

export default ReachFilter
