import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga4'
import cloneDeep from 'lodash/cloneDeep'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Box, Flex, Modal } from '@beachfront/ui'
import { useFormState, useForm } from '@beachfront/ui/forms'

import SegmentOptions from '../segment-options/segment-options'
import { useBooleanSegment } from '../../booleanSegment/boolean-segment-context'
import ExpressionBuilder from '../../booleanSegment/@components/expressionBuilder/expression-builder'
import ExpressionModalFooter from '../../booleanSegment/@components/expressionModalFooter/expression-modal-footer'
import ExpressionModalHelp from '../../booleanSegment/@components/expressionModalHelp'
import { api } from '../../../../../client-api'
import { renderExpressionItem } from '../../../@utils'

const ExpressionModal = ({ isBuilderOpen, setIsBuilderOpen, params }) => {
  const form = useForm()
  const { values } = useFormState()
  const [validationError, setValidationError] = useState(null)
  const [isValidating, setIsValidating] = useState(false)
  const { expressionV2, setExpressionV2, setIsHelpShown, isExpanded } =
    useBooleanSegment()

  const onModalCancel = () => {
    setIsBuilderOpen(false)
    const updatedExpression = cloneDeep(values.contentBooleanLogic.client)
    setExpressionV2(updatedExpression)

    setValidationError(null)
    setIsHelpShown(false)

    ReactGA.event({
      category: 'content_boolean_logic',
      action: 'cancel_btn_click',
    })
  }

  const onSave = () => {
    setValidationError(null)
    setIsValidating(true)
    const parsedString = expressionV2
      .map((item) => renderExpressionItem(item))
      .join('')

    //@TODO  This will need updating once BE is complete
    api.booleanSegment
      .check({
        contentDataExpression: parsedString,
      })
      .then((resp) => {
        if (!resp.data?.success) {
          ReactGA.event({
            category: 'content_boolean_logic',
            action: 'validate_btn',
            value: 0,
            label: parsedString,
          })

          throw new Error(
            resp.data?.msg || 'Unknown validation error. Please try again'
          )
        }
        form.change('contentBooleanLogic.value', parsedString)
        form.change('contentBooleanLogic.client', expressionV2)

        setIsBuilderOpen(false)
        setIsValidating(false)
        ReactGA.event({
          category: 'content_boolean_logic',
          action: 'validate_btn',
          value: 1,
          label: parsedString,
        })
      })
      .catch((err) => {
        setValidationError(err.toString())
        setIsValidating(false)
      })
  }

  const boxStyle = {
    transition: 'width 0.3s ease-in-out',
  }

  return (
    <Modal
      destroyOnClose
      bodyStyle={{ height: 586, overflow: 'auto' }}
      centered
      closeIcon={null}
      footer={
        <ExpressionModalFooter
          onSave={onSave}
          onCancel={onModalCancel}
          isLoading={isValidating}
          validationError={validationError}
          isContent
        />
      }
      onCancel={onModalCancel}
      okText='Validate'
      visible={isBuilderOpen}
      title='Advanced Boolean Logic'
      width='85%'
    >
      <Flex gap={3} height='100%'>
        <Box width={isExpanded ? 0 : 1 / 5} style={boxStyle}>
          <SegmentOptions params={params} />
        </Box>
        <Box width={isExpanded ? 1 : 4 / 5} style={boxStyle}>
          <DndProvider backend={HTML5Backend}>
            <ExpressionBuilder isContent />
          </DndProvider>
        </Box>
      </Flex>
      <ExpressionModalHelp />
    </Modal>
  )
}

ExpressionModal.propTypes = {
  isBuilderOpen: PropTypes.bool,
  setIsBuilderOpen: PropTypes.func,
  params: PropTypes.object,
}

export default ExpressionModal
