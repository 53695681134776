import React, { useMemo } from 'react'
import { Flex, Text } from '@beachfront/ui'
import { useTheme } from 'styled-components'

import { useBooleanSegment } from '../../boolean-segment-context'

const ExpressionUnion = ({ union }) => {
  const theme = useTheme()
  const { activeItem } = useBooleanSegment()
  const isActive = activeItem?.id === union.id

  const unionMap = useMemo(() => {
    switch (union.value.toLowerCase()) {
      case 'not':
        return {
          color: 'negative',
          name: 'NOT',
        }
      case 'or':
        return {
          color: 'warning',
          name: 'OR',
        }
      default:
        return {
          color: 'green',
          name: 'AND',
        }
    }
  }, [union])

  return (
    <Flex
      alignItems='center'
      bg={isActive ? theme.colors.primary[3] : `${unionMap.color}.0`}
      isActive={isActive}
      justifyContent='center'
      border='base'
      borderColor={isActive ? theme.colors.primary[4] : `${unionMap.color}.1`}
      borderRadius='50%'
      borderWidth={2}
      height='50px'
      width='50px'
    >
      <Text fontSize={2}>{unionMap.name} </Text>
    </Flex>
  )
}

export default ExpressionUnion
