import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from '@beachfront/ui'

import { DSP_TYPE, DSP_STATUS } from '../../../../enums'
import { formatDate } from '../../../../utils'

export const dspColumns = [
  {
    dataIndex: 'name',
    title: 'Name',
    fixed: 'left',
    width: 250,
    sorter: true,
    filter: true,
    render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
  },
  {
    dataIndex: 'type',
    title: 'type',
    width: 150,
    sorter: true,
    filter: true,
    render: (key) => DSP_TYPE.fromKey(key)?.name,
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: 200,
    sorter: true,
    filter: true,
    render: (key) => {
      if (key === DSP_STATUS.ENABLED.key) {
        return <Badge status='success' text={DSP_STATUS.ENABLED.name} />
      }
      if (key === DSP_STATUS.DISABLED.key) {
        return <Badge status='error' text={DSP_STATUS.DISABLED.name} />
      }
      return null
    },
  },
  {
    dataIndex: 'seat',
    title: 'Seat',
    width: 200,
    sorter: true,
    filter: true,
    render: (val) =>
      val ? (
        <Badge status='success' text='Enabled' />
      ) : (
        <Badge status='error' text='Disabled' />
      ),
  },
  {
    dataIndex: 'createDate',
    title: 'Create Date',
    width: 150,
    sorter: true,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'lastUpdate',
    title: 'Last Update',
    width: 150,
    sorter: true,
    render: (val) => formatDate(val),
  },
]
