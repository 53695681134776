import React, { useState } from 'react'
import { Upload, Modal, useToast } from '@beachfront/ui'
import { PlusOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { getFileBase64, isArray, isString, getErrorMessage } from '../../utils'

const UploadInput = ({
  value,
  onChange,
  single,
  max,
  maximumSize,
  aspectRatio,
  format,
  msg,
  ...rest
}) => {
  if (!single && !isArray(value)) {
    value = []
  }

  const toast = useToast()
  const [previewImage, setPreviewImage] = useState(null)

  const placeholder = (
    <>
      <PlusOutlined />
      <div>Upload</div>
    </>
  )

  let uploadButton = null

  if ((single && !value) || value.length < max) {
    uploadButton = placeholder
  }

  const uploadFile = (file) => {
    if (file.type.includes(format)) {
      if (maximumSize && file.size / 1024 > maximumSize) {
        return toast.error({
          title: `Please select a file less than ${maximumSize} KB.`,
        })
      } else {
        if (aspectRatio && aspectRatio.height && aspectRatio.width) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function (e) {
            const image = new Image()
            image.src = e.target.result // set the base64 string returned from FileReader as source

            // validate the file width and height
            image.onload = function () {
              let height = this.height
              let width = this.width
              if (aspectRatio.height / aspectRatio.width === 1) {
                if (Math.abs(height - width) < 20) {
                  getFileBase64(file).then(
                    (response) => {
                      if (single) {
                        onChange(response)
                      } else {
                        onChange([...value, response])
                      }
                    },
                    (error) => {
                      toast.error({ title: getErrorMessage(error) })
                    }
                  )
                  return false
                } else {
                  toast.error({
                    title: `Please Select a file of aspect ratio 1:1.`,
                  })
                  return true
                }
              } else {
                toast.error({
                  title: `Please Select a file of aspect ratio ${aspectRatio.width}x${aspectRatio.height}.`,
                })
                return true
              }
            }
          }
        } else {
          getFileBase64(file).then(
            (response) => {
              if (single) {
                onChange(response)
              } else {
                onChange([...value, response])
              }
            },
            (error) => {
              toast.error({ title: getErrorMessage(error) })
            }
          )
          return false
        }
      }
    } else {
      toast.error({
        title: msg || `Please Select a Valid file format.`,
      })
      return true
    }
  }
  const onRemove = (file) => {
    if (single) {
      onChange('')
    } else {
      console.log(file, value)
    }
  }
  let valueProps = {
    fileList: [],
  }
  if (single) {
    if (isString(value) && value.length) {
      valueProps = {
        fileList: [
          {
            uid: '-1',
            url: value,
          },
        ],
      }
    }
  } else {
    if (isArray(value) && value.length) {
      valueProps = {
        fileList: value.map((v, i) => ({
          uid: -1 - i,
          url: v,
        })),
      }
    }
  }
  return (
    <>
      <Upload
        action='http://localhost:8080/api/uploadFile'
        listType='picture-card'
        beforeUpload={uploadFile}
        onPreview={(file) => setPreviewImage(file.url)}
        onRemove={onRemove}
        {...valueProps}
        {...rest}
      >
        {uploadButton}
      </Upload>
      <Modal
        visible={!!previewImage}
        onCancel={() => setPreviewImage(null)}
        bodyStyle={{ padding: '12px', height: 600, overflow: 'auto' }}
        width='50%'
        title='Image Preview'
        footer={null}
        centered
      >
        <img
          alt='example'
          style={{ width: '100%', height: '100%' }}
          src={previewImage}
        />
      </Modal>
    </>
  )
}

UploadInput.defaultProps = {
  max: 8,
  format: '',
}

UploadInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  single: PropTypes.bool,
  max: PropTypes.number,
  maximumSize: PropTypes.number,
  aspectRatio: PropTypes.object,
  format: PropTypes.string,
  msg: PropTypes.string,
}

export default UploadInput
