export default {
  budgetType: 'OPEN',
  budgetOptionsDaily: 'BUDGET',
  budgetOptionsOverall: 'IMPRESSION',
  selectedDsp: [],
  type: 'AUCTION',
  allPubSeats: 'All Seats Are Allowed',
  frequencyType: 'UNLIMITED',
  deliveryType: 'CONTINUE',
  deliveryType2: 'DELIVERSOON',
  audienceIp: 'on',
  location: 'on',
  contentChannel: true,
  contentEpisode: true,
  contentSeason: true,
  contentTitle: true,
  contentNetwork: true,
  contentSeries: true,
  contentAll: true,
  audienceIfa: true,
  zipcode: true,
  dayPartingType: 'ALL',
  dayPartingSchedules: [],
  dayPartingTimezone: 'America/New_York',
}
