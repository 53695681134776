import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Input,
  useToast,
  Upload,
  Flex,
  Box,
  Help,
  Label,
} from '@beachfront/ui'
import { Form, Field } from '@beachfront/ui/forms'
import { UploadOutlined } from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { isNotEmptyArray, isNotEmptyObject } from '../../../utils'
import { LabelTooltip } from '../../../components'

const UploadDocsModal = ({ uploadModal, refetch }) => {
  const toast = useToast()

  const onSubmit = (values) => {
    const formData = new FormData()
    const title = values.title.trim()
    values.pdf.fileList.forEach((fileObj) => {
      formData.append('file', fileObj.originFileObj)
    })
    formData.append('title', title)
    return api.support
      .uploadDocs(formData)
      .then((data) => {
        if (data?.data.success) {
          toast.success({
            description: data?.data.msg || 'Document uploaded successfully',
          })
          uploadModal.close()
          refetch?.()
        }
      })
      .catch((err) => {
        toast.error({
          description: err.message,
        })
      })
  }

  const uploadedFile = {
    beforeUpload: (file) => {
      const isPdf = file.type === 'application/pdf'
      if (!isPdf) {
        toast.error({
          description: `${file.name} is not a PDF file.`,
        })
        return Upload.LIST_IGNORE // Ignore file but keep it in the list for displaying the error
      }
      return false
    },
  }

  const validate = (values) => {
    const errors = {}
    if (!values.title || values.title.trim() === '') {
      errors.title = 'Required'
    }
    if (values.title) {
      values.title = values.title.trim()
    }
    if (!values.pdf || !isNotEmptyArray(values.pdf.fileList)) {
      errors.pdf = 'Required'
    }
    return errors
  }

  const handleSubmitDisable = (values, dirty) => {
    if (!dirty) {
      return true
    }
    if (isNotEmptyObject(values)) {
      if (values.title && values.title.trim() !== '') {
        return false
      }
      if (!isNotEmptyArray(values.pdf?.fileList)) {
        return true
      }
    }
    return false
  }

  return (
    <Form validate={validate} onSubmit={onSubmit}>
      {({ form, handleSubmit, submitting, dirty, values }) => (
        <Modal
          title='Upload Document'
          visible={uploadModal.isOpen}
          onCancel={() => uploadModal.close()}
          afterClose={() => form.restart()}
          cancelText='Cancel'
          okText='Submit'
          cancelButtonProps={{ disabled: submitting }}
          okButtonProps={{
            disabled: handleSubmitDisable(values, dirty),
            loading: submitting,
          }}
          onOk={handleSubmit}
          destroyOnClose
        >
          <form onSubmit={handleSubmit}>
            <Field name='title' label='Document Title'>
              <Input placeholder='Enter document title' />
            </Field>
            <Field
              name='pdf'
              render={({ input, meta }) => (
                <>
                  <Flex>
                    <Label mr={1}>Upload Document</Label>
                    <Box>
                      <LabelTooltip content='Upload one PDF at a time' />
                    </Box>
                  </Flex>
                  <Box
                    width={
                      meta.error && (meta.dirty || meta.submitFailed)
                        ? '100%'
                        : '400px'
                    }
                  >
                    <Upload
                      {...uploadedFile}
                      onChange={input.onChange}
                      maxCount='1'
                      accept='application/pdf'
                    >
                      <Button disabled={isNotEmptyArray(values.pdf?.fileList)}>
                        <UploadOutlined />
                        Upload
                      </Button>
                    </Upload>
                    {meta.error && (meta.dirty || meta.submitFailed) ? (
                      <Help status='error' fontSize={1} mt={1}>
                        {meta.error}
                      </Help>
                    ) : null}
                  </Box>
                </>
              )}
            />
          </form>
        </Modal>
      )}
    </Form>
  )
}

UploadDocsModal.propTypes = {
  uploadModal: PropTypes.object,
  refetch: PropTypes.func,
}

export default UploadDocsModal
