import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Flex, Grid, Box, Heading } from '@beachfront/ui'
import { DelayOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { useUserData } from '../../../hooks'
import { isNotEmptyArray } from '../../../utils'
import { CommonSpinner, NetworkError, PageSearch } from '../../../components'
import { Widget, CannedChartWidget } from '../../../components/widget'
import { DealFeatureList } from '../../deal/@components'

import { ChartSummary, InsightWidget } from '.'

const DashboardCharts = ({
  chartData,
  widgetData,
  params,
  timezone,
  loading,
  error,
  onRefetch,
}) => {
  const location = useLocation()
  const [user] = useUserData()
  const [searchQuery, setSearchQuery] = useState('')

  if (loading) {
    return <CommonSpinner />
  }

  if (error) {
    return (
      <NetworkError
        description='Dashboard data not available'
        onAction={onRefetch}
      />
    )
  }

  return (
    <>
      <Grid gap={3}>
        {chartData?.widgets?.map((widget = {}, i) => {
          if (widget.type === 'SUMMARY') {
            return (
              <Box key={i} columnSpan={24}>
                <ChartSummary
                  data={widget.data}
                  dateFilter={{
                    startDate: params.startDate,
                    endDate: params.endDate,
                  }}
                />
              </Box>
            )
          }
          if (widget.type === 'DEAL_CARD') {
            return (
              <Fragment key={i}>
                {user.dealAccessTemplate ? (
                  <Box columnSpan={24}>
                    <DealFeatureList timezone={timezone} showEmpty={false} />
                  </Box>
                ) : null}
              </Fragment>
            )
          }
          return (
            <Box key={i} columnSpan={widget.span ?? 12}>
              {widget.chartType === 'CANNED' ? (
                <CannedChartWidget
                  title={getWidgetTitle(widget)}
                  redirect={getWidgetRedirect(widget, location)}
                  apiUrl={widget.apiInfo?.apiUrl}
                  params={params}
                />
              ) : (
                <Widget
                  title={getWidgetTitle(widget)}
                  type={widget.chartType}
                  data={widget.data}
                  tabs={widget.tabs ?? []}
                  details={widget.details ?? []}
                />
              )}
            </Box>
          )
        })}
      </Grid>
      <Flex mt={4} gap={3} justifyContent='space-between' alignItems='center'>
        <Flex gap={3}>
          <Heading level={4} my={0} ellipsis>
            Insights
          </Heading>
          <Flex gap={1} alignItems='center' color='secondary.base'>
            <DelayOutlined />
            <Box my='auto' fontSize={1}>
              4 hr delayed
            </Box>
          </Flex>
        </Flex>
        <PageSearch
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder='Search Deals'
        />
      </Flex>
      <Box mt={3}>
        {isNotEmptyArray(widgetData) ? (
          <Grid gap={3} columns={[1, 2, 24]} autoRows='360px'>
            {widgetData.map((item, i) => (
              <Box key={i} columnSpan={[1, 1, item.span ?? 8]}>
                <InsightWidget
                  index={i}
                  data={item}
                  params={params}
                  timezone={timezone}
                  searchQuery={searchQuery}
                />
              </Box>
            ))}
          </Grid>
        ) : null}
      </Box>
    </>
  )
}

const getWidgetTitle = (widget) => {
  if (widget.title) {
    return widget.title
  }
  if (widget.data?.title) {
    return widget.data.title
  }
  if (widget.apiInfo?.apiUrl === '/dashboard/deals') {
    return 'Deals'
  }
  return ''
}

const getWidgetRedirect = (widget, location) => {
  if (widget.apiInfo?.apiUrl === '/dashboard/deals') {
    return (rec) => ({
      pathname: `/deals/self/${rec.id}`,
      search: location.search,
    })
  }
  return null
}

DashboardCharts.propTypes = {
  chartData: PropTypes.object,
  widgetData: PropTypes.array,
  params: PropTypes.object,
  timezone: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onRefetch: PropTypes.func,
}

export default DashboardCharts
