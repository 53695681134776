import React from 'react'

import { formatDate } from '../../../../utils'
import { HISTORY_ACTION } from '../../../../enums'
import { ChangeLog } from '../../../../components'

export const accountHistoryColumns = [
  {
    dataIndex: 'summary',
    title: 'Summary',
    fixed: 'left',
    sorter: true,
    filter: true,
    width: 250,
  },
  {
    dataIndex: 'action',
    title: 'Action',
    sorter: true,
    filter: true,
    width: 140,
    render: (key) => HISTORY_ACTION.fromKey(key)?.name,
  },
  {
    dataIndex: 'creatorName',
    title: 'Creator Name',
    sorter: true,
    filter: true,
    width: 150,
  },
  {
    dataIndex: 'changelogs',
    title: 'Change Log',
    width: 150,
    render: (data) => <ChangeLog data={data} />,
  },
  {
    dataIndex: 'updateDate',
    title: 'Performed',
    sorter: true,
    filter: true,
    width: 150,
    render: (val) => formatDate(val),
  },
]
