/**
 * Formats a file to base64.
 *
 * @param   {File} file
 * @returns {Promise}
 */
export function getFileBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

/**
 * Reads a file as an array buffer.
 *
 * @param   {File} file
 * @returns {Promise}
 */
export function getFileArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
