import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Card, Input, Switch } from '@beachfront/ui'
import { Field, useForm } from '@beachfront/ui/forms'

import { SelectField } from '../../../components'
import { TimezoneField } from '../../../components/form-fields'

const GeneralSetting = ({
  userState,
  update,
  updateUser,
  admin,
  accounts,
  disableEmail,
  onEmailChange,
}) => {
  const { form } = useForm()

  return (
    <Card overflow='initial'>
      {admin && !updateUser ? (
        <Field name='accountId' label='Account'>
          <SelectField
            showSearch
            keyField='accountId'
            textField='name'
            placeholder='Select Account'
            data={accounts}
            allowClear
          />
        </Field>
      ) : null}
      <Field name='email' label='Email'>
        <Input
          onChange={() => onEmailChange?.(form)}
          placeholder='Enter Email'
          maxLength={100}
          disabled={disableEmail}
        />
      </Field>
      {userState?.save ? (
        <Grid columnGap={3} columns={[1, 2]}>
          <Field name='firstName' label='First Name'>
            <Input
              placeholder='Enter First Name'
              disabled={!userState?.newUser && !update ? admin : null}
              maxLength={100}
            />
          </Field>
          <Field name='lastName' label='Last Name'>
            <Input
              placeholder='Enter Last Name'
              disabled={!userState?.newUser && !update ? admin : null}
              maxLength={100}
            />
          </Field>
          {userState?.newUser || update ? (
            <Box columnSpan={[1, 2]}>
              <TimezoneField />
            </Box>
          ) : null}
          {admin ? (
            <>
              <Field name='dashboardUrl' label='Dashboard API Override'>
                <Input
                  placeholder='Enter dashboard API override'
                  maxLength={100}
                />
              </Field>
              <Field name='adhocUrl' label='Adhoc API Override'>
                <Input placeholder='Enter adhoc API override' maxLength={100} />
              </Field>
            </>
          ) : null}
          {!update ? (
            <Box columnSpan={[1, 2]}>
              <Field name='admin' label='Admin'>
                <Switch />
              </Field>
            </Box>
          ) : null}
          {userState?.newUser || userState?.showPassword ? (
            <>
              <Field name='password' label='Password'>
                <Input
                  placeholder='Enter Password'
                  type='password'
                  maxLength={100}
                />
              </Field>
              <Field name='confirm_password' label='Confirm Password'>
                <Input
                  type='password'
                  placeholder='Enter Confirm Password'
                  maxLength={100}
                />
              </Field>
            </>
          ) : null}
        </Grid>
      ) : null}
    </Card>
  )
}

GeneralSetting.defaultProps = {
  update: false,
}

GeneralSetting.propTypes = {
  userState: PropTypes.object,
  update: PropTypes.bool,
  updateUser: PropTypes.bool,
  admin: PropTypes.bool,
  accounts: PropTypes.array,
  disableEmail: PropTypes.bool,
  onEmailChange: PropTypes.func,
}

export default GeneralSetting
