import http from '../http'

const ENDPOINT = 'user/'

export default {
  getAll(data) {
    return http.post(`${ENDPOINT}get/all`, data)
  },
  create(params) {
    return http.post(`${ENDPOINT}create`, params)
  },
  check(params) {
    return http.get(`${ENDPOINT}check`, { params })
  },
  getUserById({ userId }) {
    return http.get(`${ENDPOINT}get/${userId}`)
  },
  update(userId, params) {
    return http.post(`${ENDPOINT}update/${userId}`, params)
  },
  updatePassword(data, params) {
    return http.post(`${ENDPOINT}update/password`, data, { params })
  },
  updatePasswordUser(data, params) {
    return http.post(`${ENDPOINT}update/password/user`, data, { params })
  },
}
