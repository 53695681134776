import React from 'react'
import PropTypes from 'prop-types'
import { Filter, Input, InputNumber, Select, Debouncer } from '@beachfront/ui'

const CompareFilterPanel = ({
  focusRef,
  value,
  setValue,
  operator,
  setOperator,
  close,
  min = 0,
  max,
  step = 1,
  precision,
  allowEquals = true,
}) => {
  return (
    <Input.Group compact>
      <Select style={{ width: 150 }} value={operator} onChange={setOperator}>
        {allowEquals ? (
          <Select.Option value={Filter.Operator.Number.EQUALS.key}>
            Equals
          </Select.Option>
        ) : null}
        <Select.Option value={Filter.Operator.Number.LESS_THAN.key}>
          Less Than
        </Select.Option>
        <Select.Option value={Filter.Operator.Number.GREATER_THAN.key}>
          Greater Than
        </Select.Option>
      </Select>
      <Debouncer value={value} onChange={setValue}>
        {(props) => (
          <InputNumber
            {...props}
            style={{ width: 150 }}
            ref={focusRef}
            onPressEnter={close}
            min={min}
            max={max}
            step={step}
            precision={precision}
          />
        )}
      </Debouncer>
    </Input.Group>
  )
}

CompareFilterPanel.propTypes = {
  focusRef: PropTypes.object,
  value: PropTypes.any,
  setValue: PropTypes.func,
  operator: PropTypes.any,
  setOperator: PropTypes.func,
  close: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  precision: PropTypes.number,
  allowEquals: PropTypes.bool,
}

export default CompareFilterPanel
