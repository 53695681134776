import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FieldControl } from '@beachfront/ui'
import { useField } from '@beachfront/ui/forms'

import { isArray } from '../../../utils'
import { SelectField, LabelTooltip } from '../../../components'

const StaticSelectField = ({
  mode,
  data,
  tooltip,
  form,
  onChange,
  getPopupContainer,
  ...rest
}) => {
  const { name, textField, keyField, placeholder, selectionName, label } = form
  const { input } = useField(name)
  const { input: selectionInput } = useField(selectionName)
  const [internalValue, setInternalValue] = useState(input.value)
  const [isOpen, setIsOpen] = useState(false)

  const effectiveValue =
    isOpen && mode === 'multiple' ? internalValue : input.value

  const onInputChange = (key) => {
    if (mode === 'multiple') {
      const selected = isArray(key)
        ? data.filter((d) => key.some((k) => k === d.key))
        : []
      selectionInput.onChange(selected)
    } else {
      const selected = key
        ? data.find((d) => d.id === key || d.key === key)
        : undefined
      selectionInput.onChange(selected)
    }
    input.onChange(key)
    onChange?.(key)
  }

  const onSelectChange = (key) => {
    if (isOpen && mode === 'multiple') {
      setInternalValue(key)
    } else {
      onInputChange(key)
    }
  }

  const isEqualArray = (a, b) => {
    return JSON.stringify(a || []) === JSON.stringify(b || [])
  }

  const onDropdownVisibleChange = (open) => {
    if (mode === 'multiple') {
      if (open) {
        setInternalValue(input.value)
      } else if (!isEqualArray(internalValue, input.value)) {
        onInputChange(internalValue)
      }
    }
    setIsOpen(open)
  }

  return (
    <FieldControl
      label={label}
      extra={
        <LabelTooltip
          content={tooltip}
          getPopupContainer={getPopupContainer ?? ((el) => el.parentNode)}
        />
      }
    >
      <SelectField
        value={effectiveValue}
        mode={mode}
        data={data}
        keyField={keyField}
        textField={textField}
        placeholder={placeholder}
        onChange={onSelectChange}
        onDropdownVisibleChange={onDropdownVisibleChange}
        getPopupContainer={getPopupContainer}
        showSearch
        {...rest}
      />
    </FieldControl>
  )
}

StaticSelectField.defaultProps = {
  form: {},
}

StaticSelectField.propTypes = {
  mode: PropTypes.string,
  data: PropTypes.array,
  tooltip: PropTypes.node,
  form: PropTypes.object,
  onChange: PropTypes.func,
  getPopupContainer: PropTypes.func,
}

export default StaticSelectField
