import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@beachfront/ui'
import { Field, useForm, useFormState } from '@beachfront/ui/forms'

import { IS_DEALS_ENGINE } from '../../../../constants'
import { SEGMENT_TYPE, SEGMENT_STATUS } from '../../../../enums'
import { useUserData } from '../../../../hooks'
import { isFloatValue } from '../../../../utils'
import { SelectField } from '../../../../components'

const SegmentFieldForm = ({ isCreate, isOwned, isShared, isUpload }) => {
  const form = useForm()
  const { values } = useFormState()
  const [user] = useUserData()

  const onPriceChange = (event) => {
    let rate = event.target.value
      .replace(/[^\d.]/g, '')
      .replace(/\./, 'x')
      .replace(/\./g, '')
      .replace(/x/, '.')

    if (isFloatValue(rate)) {
      rate = rate
        .toString()
        .split('.')
        .map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
        .join('.')
    }

    form.change('price', rate)
  }

  return (
    <>
      <Field name='name' label='Name'>
        <Input.TextArea
          style={{ resize: 'none' }}
          placeholder='Enter Segment Name'
          disabled={isShared}
          autoSize
        />
      </Field>
      {isShared ? (
        <Field name='sourceProvider' label='Provider'>
          <SelectField
            mode='multiple'
            name='sourceProvider'
            keyField='id'
            textField='name'
            placeholder='Select providers'
            data={user.providers || []}
            disabled
          />
        </Field>
      ) : null}
      {!IS_DEALS_ENGINE || (isShared && !isUpload) ? (
        <Field name='price' label='Price (CPM)'>
          <Input
            prefix='$'
            placeholder='Enter Price CPM'
            onChange={onPriceChange}
            disabled={isShared}
          />
        </Field>
      ) : null}
      {!isShared ? (
        <Field name='dataType' label='Segment Type'>
          <SelectField
            name='dataType'
            keyField='key'
            textField='name'
            placeholder='Select Segment Type'
            data={SEGMENT_TYPE.values().filter((t) => t.canUpload)}
            disabled={isUpload}
          />
        </Field>
      ) : null}
      {!IS_DEALS_ENGINE ? (
        <Field name='remoteId' label='Remote ID'>
          <Input placeholder='Enter Remote ID' disabled={isShared} />
        </Field>
      ) : null}
      {isOwned && !isUpload ? (
        <Field name='ingest' label='Ingest'>
          <Input disabled />
        </Field>
      ) : null}
      {(values.uploadState?.state === 'success' || !isCreate) && !isShared ? (
        <Field name='status' label='Status'>
          <SelectField
            keyField='key'
            textField='name'
            data={SEGMENT_STATUS.values()}
            disabled
          />
        </Field>
      ) : null}
    </>
  )
}

SegmentFieldForm.propTypes = {
  isCreate: PropTypes.bool,
  isOwned: PropTypes.bool,
  isShared: PropTypes.bool,
  isUpload: PropTypes.bool,
}

export default SegmentFieldForm
