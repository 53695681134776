import React from 'react'
import styled from 'styled-components'
import { Box, Card, Empty, Flex, Tag, Text, Tooltip } from '@beachfront/ui'
import { useDrop } from 'react-dnd'

import { useBooleanSegment } from '../../boolean-segment-context'
import { getParentProvider } from '../../../../@utils/boolean-segment.util'

import { useSegmentProviders } from '../../../../../../hooks'
import { formatCurrency } from '../../../../../../utils'

export const StyledCard = styled(Card)`
  border: dashed 3px ${(p) => p.theme.colors.positive[4]};
  transition: border-color 0.2s ease-in-out;

  ${(p) => p.isActive && `border-color: ${p.theme.colors.primary[4]};`}
`

const ExpressionSet = ({ set, isContent = false }) => {
  const {
    activeItem,
    activeParentProvider,
    setExpressionV2,
    setActiveItem,
    stateHistory,
  } = useBooleanSegment()
  const { providers } = useSegmentProviders()
  const isActive = activeItem?.id === set.id

  const addSegmentToSet = (segment, expression) => {
    return expression.map((exp) => {
      if (exp.id === set.id) {
        return {
          ...exp,
          children: [
            ...exp.children,
            {
              ...segment,
              clientId: `segment-${Date.now()}`,
              type: 'SEGMENT',
            },
          ],
        }
      }

      if (exp.children && exp.children.length > 0) {
        return {
          ...exp,
          children: addSegmentToSet(
            {
              ...segment,
              clientId: `segment-${Date.now()}`,
              type: 'SEGMENT',
            },
            exp.children
          ),
        }
      }

      return exp
    })
  }

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'SEGMENT',
      drop: (item) => {
        setExpressionV2((prevState) => {
          const updatedState = addSegmentToSet(item, prevState)
          stateHistory.current.push(updatedState)
          return updatedState
        })
      },
      canDrop: (item) => {
        const isDoubled = set.children.some(
          (sg) => String(sg.id) === String(item.id)
        )
        const parentProvider = getParentProvider(item, providers)

        return (
          !isDoubled &&
          (!activeParentProvider ||
            parentProvider.toLowerCase() === activeParentProvider.toLowerCase())
        )
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [set, activeParentProvider]
  )

  const isEmpty = set.children?.length < 1
  const getTitleForSegment = (sg) => {
    if (sg.subType) {
      return (
        <Flex gap={1}>
          <Text strong>{sg.subType}:</Text>
          <span>{sg.label}</span>
        </Flex>
      )
    }

    return sg.title
  }

  const getTooltipTitle = (sg) => {
    if (sg.subType) {
      return `${sg.subType} - ${sg.label}`
    }

    return (
      <Flex flexDirection='column' gap={3}>
        {sg.id} - {sg.title}
        <Box>
          <Tag color='green'>{`${formatCurrency(sg.price)} CPM`}</Tag>
        </Box>
      </Flex>
    )
  }

  return (
    <StyledCard
      bordered={false}
      compact
      isActive={isActive}
      isOver={isOver}
      ref={drop}
      bodyStyle={{
        minHeight: '150px',
        minWidth: '175px',
        padding: '8px',
      }}
      size='small'
    >
      {isEmpty && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={isContent ? 'No Options' : 'No Segments'}
        />
      )}
      <Flex flexDirection='column' gap={2}>
        {set.children.map((sg) => (
          <Flex
            alignItems='center'
            justifyContent='space-between'
            border='dashed'
            borderRadius={4}
            borderColor={
              activeItem && activeItem?.id === sg.clientId
                ? 'primary.4'
                : 'magenta.4'
            }
            px={2}
            py={1}
            key={sg.id || sg.clientId}
            onClick={(evt) => {
              evt.stopPropagation()
              setActiveItem({ id: sg.clientId })
            }}
          >
            <Tooltip title={getTooltipTitle(sg)}>
              <Text fontSize='12px' ellipsis maxWidth='250px'>
                {getTitleForSegment(sg)}
              </Text>
            </Tooltip>
          </Flex>
        ))}
      </Flex>
    </StyledCard>
  )
}

export default ExpressionSet
