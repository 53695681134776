import { createEnum } from '../utils'

export const HISTORY_ACTION = createEnum({
  CREATE: {
    name: 'Create',
  },
  UPDATE: {
    name: 'Update',
  },
  REMOVED: {
    name: 'Remove',
  },
})
