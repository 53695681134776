import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'

import { queryStringToObject } from '../utils'

export const useDateUrlSync = ({ filters, onChange }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const dateRange = filters.find((f) => f.dataIndex === 'date')?.value
  const startDate = dateRange?.[0].format('YYYY-MM-DD')
  const endDate = dateRange?.[1].format('YYYY-MM-DD')

  useEffect(() => {
    const params = queryStringToObject(location.search)

    if (
      params.startDate &&
      params.endDate &&
      (startDate !== params.startDate || endDate !== params.endDate)
    ) {
      const value = [
        moment(params.startDate, 'YYYY-MM-DD'),
        moment(params.endDate, 'YYYY-MM-DD'),
      ]
      const dateFilter = { dataIndex: 'date', operator: 'eq', value }
      const otherFilters = filters.filter((f) => f.dataIndex !== 'date')
      onChange([dateFilter, ...otherFilters])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const pathname = `${location.pathname}?startDate=${startDate}&endDate=${endDate}`
    navigate(pathname, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])
}
