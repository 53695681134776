import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter } from '@beachfront/ui'
import {
  TagsOutlined,
  NumberOutlined,
  CalendarOutlined,
} from '@beachfront/ui/icons'

import {
  ContainsFilterPanel,
  CompareFilterPanel,
  DateFilterPanel,
  formatStringFilterTag,
  formatNumberTag,
  formatDateFilterTag,
} from '../../../components/filter'

const MediaListFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='title'
        label='Title'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='count'
        label='List Size'
        defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
        defaultValue={0}
        icon={<NumberOutlined />}
        formatTag={formatNumberTag}
        renderPanel={(props) => (
          <CompareFilterPanel {...props} min={0} step={1} />
        )}
      />
      <Filter.Option
        dataIndex='createDate'
        label='Created'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='updateDate'
        label='Last Updated'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
    </Filter>
  )
}

MediaListFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default MediaListFilter
