import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@beachfront/ui'

import { api } from '../../../client-api'
import { useServerTable } from '../../../hooks'
import { getErrorMessage } from '../../../utils'
import { NetworkError } from '../../../components'
import { realtimeColumns } from '../@constants'

import style from './realtime-auction-logs.module.scss'

import { RealtimeDetailModal } from '.'

const RealtimeAuctionLogs = ({ requestPayload }) => {
  const [auction, setAuction] = useState(null)

  const table = useServerTable({
    queryKey: 'realtime-auction-log',
    queryFn: api.auction.logs,
    payload: requestPayload,
    schema: {
      data: 'rows',
      total: 'total',
      fields: {
        timestamp: 'date',
      },
    },
    defaultSorter: { field: 'timestamp', order: 'descend' },
    defaultPageSize: 100,
    refetchInterval: 30000,
  })

  if (!table.isFetching && table.error) {
    return (
      <NetworkError
        description={getErrorMessage(table.error)}
        onAction={table.refetch}
      />
    )
  }

  return (
    <div>
      <Table
        rowKey={(rec, i) => `${rec.id}${i}`}
        rowClassName={style.animate}
        emptyText='No Auction Logs'
        scroll={{ x: '100%', y: 510 }}
        columns={realtimeColumns}
        dataSource={table.data}
        loading={table.isLoading}
        pagination={false}
        onRow={(rec) => ({ onClick: () => setAuction(rec) })}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
      <RealtimeDetailModal
        visible={!!auction}
        onCancel={() => setAuction(false)}
        config={{ title: 'Auction Detail', data: auction }}
      />
    </div>
  )
}

RealtimeAuctionLogs.propTypes = {
  requestPayload: PropTypes.object,
}

export default RealtimeAuctionLogs
