import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Box, Button, useToast } from '@beachfront/ui'
import { DownloadOutlined, ReloadOutlined } from '@beachfront/ui/icons'
import FileSaver from 'file-saver'

import { api } from '../../../client-api'
import { useReportDetail } from '../../../hooks'
import { getErrorMessage } from '../../../utils'
import { PageHeader, PageSearch, NetworkError } from '../../../components'

import { ReportDataTable } from '.'

const ReportDetail = ({ shared = false }) => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const toast = useToast()

  const [searchQuery, setSearchQuery] = useState('')
  const [isDownloading, setIsDownloading] = useState(false)

  const reportQuery = useReportDetail({
    params: { id, shared },
  })

  const onRefetch = () => {
    reportQuery.refetch()
  }

  const onDownload = () => {
    setIsDownloading(true)
    api.report
      .getDownloadFile({ id }, 'blob')
      .then((res) => {
        const type = 'application/vnd.ms-excel'
        const blob = new Blob([res.data], { type })
        const headers = res.headers['content-disposition']
        const nameArray = headers?.split('filename=') || []
        const name = nameArray[nameArray.length - 1] || 'report-detail.csv'
        FileSaver.saveAs(blob, name)
        toast.success({ title: 'Successfully downloaded.' })
      })
      .finally(() => setIsDownloading(false))
  }

  const headerActions = (
    <>
      <PageSearch
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search Report'
      />
      <Button onClick={onRefetch} icon={<ReloadOutlined />} />
      <Button
        loading={isDownloading}
        disabled={isDownloading || !reportQuery.data?.rows?.length}
        icon={<DownloadOutlined />}
        onClick={onDownload}
      >
        Download
      </Button>
      <Button
        type='primary'
        onClick={() => navigate(location.pathname + '/update')}
      >
        {shared ? 'View Report' : 'Edit Report'}
      </Button>
    </>
  )

  return (
    <>
      <PageHeader
        title={reportQuery.data?.title || ''}
        actions={headerActions}
      />
      <Box mt={3}>
        {reportQuery.error ? (
          <NetworkError
            description={getErrorMessage(reportQuery.error)}
            buttonLabel='Go to Reports'
            onAction={() => navigate('..', { relative: 'path' })}
          />
        ) : (
          <ReportDataTable
            rows={reportQuery.data?.rows || []}
            columns={reportQuery.data?.columns || []}
            totals={reportQuery.data?.totals || []}
            loading={reportQuery.isFetching}
            emptyText={reportQuery.data?.msg}
            searchQuery={searchQuery}
          />
        )}
      </Box>
    </>
  )
}

ReportDetail.propTypes = {
  shared: PropTypes.bool,
}

export default ReportDetail
