import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useReportConfig = () => {
  return useQuery({
    queryKey: ['report-config'],
    queryFn: async () => {
      const res = await api.report.getConfig()
      return res.data
    },
    staleTime: 10 * 60 * 1000,
  })
}
