import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { PageHeader } from '../../../components'

import { MetaTable } from './@components'

const Meta = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const onRefetch = () => {
    queryClient.invalidateQueries({ queryKey: ['admin-meta'] })
  }

  const headerActions = (
    <>
      <Button onClick={onRefetch} icon={<ReloadOutlined />} />
      <Button type='primary' onClick={() => navigate('/admin/meta/create')}>
        Create Meta
      </Button>
    </>
  )

  return (
    <>
      <PageHeader title='Admin - Meta' actions={headerActions} />
      <Box mt={2}>
        <MetaTable />
      </Box>
    </>
  )
}

export default Meta
