import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useInsightWidgets = () => {
  return useQuery({
    queryKey: ['insight-widgets'],
    queryFn: async () => {
      const res = await api.insight.list()
      return (
        res.data.data?.sort((a, b) => Number(a.order) - Number(b.order)) ?? []
      )
    },
  })
}
