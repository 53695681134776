import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useReportById = ({ params } = {}) => {
  return useQuery({
    queryKey: ['report', { params }],
    queryFn: async () => {
      const res = await api.report.getDataById(params)
      return res.data
    },
  })
}
