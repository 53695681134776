import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Flex, Card } from '@beachfront/ui'
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'

import { getChartColors } from '../../utils'
import { AxisLabel } from '../chart'

import { formatTick } from './widget-util'
import { CustomTooltip } from './custom-tooltip'

export const AreaChartWidget = ({
  data,
  title,
  colors,
  colorOffset = 0,
  cardBodyHeight,
  toolTipLabel,
  ...rest
}) => {
  const theme = useTheme()
  const chartColors = colors ?? getChartColors(theme.colors)
  const lineColor = chartColors[colorOffset % chartColors.length]

  const chartData = useMemo(() => {
    if (!data?.values) {
      return []
    }
    return data.values.map((dt, i) => ({
      value: dt,
      label: data.xAxis[i],
    }))
  }, [data])

  return (
    <Card
      title={title}
      bodyStyle={{ height: cardBodyHeight ?? '300px' }}
      {...rest}
    >
      <Flex width='100%' height='100%' fontFamily='secondary' fontSize={0}>
        <ResponsiveContainer>
          <AreaChart
            data={chartData}
            margin={{ top: 15, right: 15, left: 15, bottom: 0 }}
          >
            <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
            <XAxis
              dataKey='label'
              strokeWidth='1'
              interval='preserveStartEnd'
              axisLine={{ stroke: theme.colors.gray[4] }}
              tickLine={{ stroke: theme.colors.gray[4] }}
              tick={{ fill: theme.colors.gray[6] }}
              tickMargin={8}
              padding={{ left: 8, right: 8 }}
            />
            <YAxis
              width={50}
              axisLine={{ stroke: theme.colors.gray[4] }}
              tickLine={false}
              tick={{ fill: theme.colors.gray[6] }}
              label={
                data.unit ? (
                  <AxisLabel
                    axisType='y'
                    x={25}
                    y={150}
                    width={0}
                    height={0}
                    fill={theme.colors.text}
                  >
                    {data.unit}
                  </AxisLabel>
                ) : undefined
              }
              tickFormatter={(val) => formatTick(val)}
            />
            <Tooltip
              isAnimationActive={false}
              cursor={{ stroke: theme.colors.gray[3] }}
              wrapperStyle={{ zIndex: 1 }}
              content={<CustomTooltip />}
            />
            <Area
              type='monotone'
              dataKey='value'
              strokeWidth='2'
              name={toolTipLabel}
              stroke={lineColor}
              fill={lineColor}
              dot={{ fill: theme.colors.bg.component, fillOpacity: 1 }}
              activeDot={{ stroke: theme.colors.bg.component }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Flex>
    </Card>
  )
}

AreaChartWidget.propTypes = {
  data: PropTypes.array,
  title: PropTypes.node,
  colors: PropTypes.array,
  colorOffset: PropTypes.number,
  cardBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  toolTipLabel: PropTypes.string,
}
