import React from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Paragraph,
  ScrollArea,
  Table,
} from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import {
  AmpersandOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ClearOutlined,
  CloseOutlined,
  CurlyBracketsOutlined,
  DeleteOutlined,
  ExclamationOutlined,
  LineOutlined,
  NodeExpandOutlined,
  ParenthesesOutlined,
} from '@beachfront/ui/icons'

import { useBooleanSegment } from '../../boolean-segment-context'

const TABLE_COLUMNS = [
  {
    dataIndex: 'title',
    title: 'Control',
    width: 95,
  },
  {
    align: 'center',
    dataIndex: 'hotkey',
    title: 'Hotkey',
    width: 125,
  },
  {
    align: 'center',
    dataIndex: 'icon',
    render: (icon) => {
      if (!icon) {
        return
      }
      return (
        <Flex
          alignItems='center'
          border='base'
          borderRadius='50%'
          height='30px'
          justifyContent='center'
          width='30px'
          inline
        >
          {icon}
        </Flex>
      )
    },
    title: 'Icon',
    width: 75,
  },

  {
    dataIndex: 'description',
    title: 'Description',
  },
]

const BUTTON_DESCRIPTIONS = [
  {
    icon: <ParenthesesOutlined />,
    color: 'purple.4',
    description: 'A collection of one or more items joined by operators.',
    hotkey: 'g',
    expression: '()',
    title: 'Group',
  },
  {
    icon: <CurlyBracketsOutlined />,
    color: 'positive.4',
    description:
      'A collection of one or more items. Note: These items are joined by the OR (|) operator',
    expression: '{}',
    hotkey: 's',
    title: 'Set',
  },
  {
    icon: <AmpersandOutlined />,
    color: 'green',
    description: '& operator',
    expression: 'and',
    hotkey: '&',
    title: 'And',
  },
  {
    icon: <LineOutlined style={{ transform: 'rotate(90deg)' }} />,
    color: 'warning',
    description: '| operator',
    expression: 'or',
    hotkey: '|',
    title: 'Or',
  },
  {
    icon: <ExclamationOutlined />,
    color: 'negative',
    description:
      '! operator. Note: this operation requires an "AND" & or "OR" | operator Example: “&!" or "|!"',
    expression: 'not',
    hotkey: '!',
    title: 'Not',
  },
  {
    icon: <ArrowLeftOutlined />,
    description: 'Shift the active item one space to the left',
    hotkey: <>&larr;</>,
    title: 'Shift Left',
  },
  {
    icon: <ArrowRightOutlined />,
    description: 'Shift the active item one space to the right',
    hotkey: <>&rarr;</>,
    title: 'Shift Right',
  },
  {
    icon: <NodeExpandOutlined />,
    title: 'Expand',
    hotkey: 'e',
    description: 'Expands the builder section',
  },
  {
    icon: <DeleteOutlined />,
    title: 'Delete',
    hotkey: 'delete',
    description: 'Deletes the active item',
  },
  {
    icon: <ClearOutlined />,
    title: 'Clear',
    hotkey: 'c',
    description: 'Removes the entire expression',
  },
  {
    title: 'Undo',
    hotkey: 'cmd/ctrl + z',
    description: 'Undo the last operation',
  },
]

const ExpressionModalHelpStyled = styled(Box)`
  height: ${(props) => (props.isOpen ? '100%' : 0)};
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  transition: height 0.3s ease-in-out;
  z-index: 10;
`

const ExpressionModalHelp = () => {
  const { isHelpShown, setIsHelpShown } = useBooleanSegment()
  const table = useTableQuery({
    data: BUTTON_DESCRIPTIONS,
  })

  return (
    <ExpressionModalHelpStyled isOpen={isHelpShown} bg='gray.1' width='100%'>
      <Flex justifyContent='space-between' px={3} pt={3}>
        <Heading level={4}>Advanced Boolean Logic - Help</Heading>
        <Button
          icon={<CloseOutlined />}
          onClick={() => setIsHelpShown(false)}
          type='text'
        />
      </Flex>
      <Divider />

      <ScrollArea overflowY='auto' height='calc(100% - 80px)'>
        <Box width={7 / 8} px={4}>
          <Paragraph>
            The UI builder provides a user friendly way of building complex
            logic expresssion. Hotkeys provide the quickest and easiest method
            for creating these expresions. See the table below for a quick
            reference.
          </Paragraph>

          <Table
            columns={TABLE_COLUMNS}
            dataSource={table.data}
            pagination={false}
            size='small'
          />
        </Box>
      </ScrollArea>
    </ExpressionModalHelpStyled>
  )
}

export default ExpressionModalHelp
