import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, Card } from '@beachfront/ui'

import OptionPanel from './option-panel/option-panel'

const SegmentOptions = ({ params }) => {
  return (
    <Card
      title='Available Options'
      height='100%'
      className='expression-options'
    >
      <Accordion defaultValue='channel' bordered={false}>
        <OptionPanel params={params} type='channel' />
        <OptionPanel params={params} type='network' />
        <OptionPanel params={params} type='genres' />
      </Accordion>
    </Card>
  )
}

SegmentOptions.propTypes = {
  params: PropTypes.object,
}

export default SegmentOptions
