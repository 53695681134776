import http from '../http'

export default {
  create(data) {
    return http.post('report/create', data)
  },
  update({ id, data }) {
    return http.post(`report/update/${id}`, data)
  },
  delete(id) {
    return http.get(`report/delete/${id}`)
  },
  getConfig() {
    return http.get('report/config')
  },
  getTotals(params) {
    return http.get('report/summary', { params })
  },
  getList({ params, ...data }) {
    return http.post('report/list', data, { params })
  },
  getById(params) {
    return http.get('report/detail', { params })
  },
  getDataById({ id }) {
    return http.get(`report/edit/${id}`)
  },
  getDownloadFile(params, responseType) {
    return http.get(
      'report/download/report-detail.csv',
      { params },
      { responseType }
    )
  },
}
