import React from 'react'
import { Flex, Tag } from '@beachfront/ui'

export const appInfoColumns = [
  {
    dataIndex: 'overridedBundle',
    title: 'Existing Bundle',
    fixed: 'left',
    width: 180,
    sorter: true,
    filter: true,
  },
  {
    dataIndex: 'bundle',
    title: 'Bundle',
    width: 180,
    sorter: true,
    filter: true,
  },
  {
    dataIndex: 'name',
    title: 'Name',
    width: 120,
    sorter: true,
  },
  {
    dataIndex: 'domain',
    title: 'Domain',
    width: 120,
    sorter: true,
  },
  {
    dataIndex: 'appStoreUrl',
    title: 'App Store URL',
    width: 160,
    sorter: true,
  },
  {
    dataIndex: 'categories',
    title: 'IAB Categories',
    width: 150,
    sorter: false,
    render: (val) =>
      val ? (
        <Flex gap={1} flexWrap='wrap'>
          {val.split(',').map((str) => (
            <Tag key={str}>{str}</Tag>
          ))}
        </Flex>
      ) : null,
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: 120,
    sorter: true,
  },
]
