import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from '@beachfront/ui/forms'
import { Accordion, Text } from '@beachfront/ui'

import ContentSegment from '../contentSegment'

import ContentPreviewTags from './content-preview-tags'

const ContentPanel = ({
  expandKeys,
  disabled,
  values,
  initialValues,
  params,
  id,
  getPopupContainer,
}) => {
  const form = useForm()

  const isContentValid = () => {
    const { errors, submitFailed } = form.getState()
    if (errors.contentBooleanLogic?.value) {
      return !submitFailed
    }
    return true
  }

  const extra = (
    <ContentPreviewTags
      visible={!expandKeys.includes('content')}
      values={values}
    />
  )

  return (
    <Accordion.Panel
      value='content'
      header={
        <Text color={isContentValid() ? '' : 'negative.base'}>Content</Text>
      }
      extra={extra}
    >
      <ContentSegment
        id={id}
        params={params}
        formDisable={disabled}
        initialValues={initialValues}
        getPopupContainer={getPopupContainer}
      />
    </Accordion.Panel>
  )
}

ContentPanel.defaultProps = {
  expandKeys: [],
  values: {},
  initialValues: {},
  params: {},
}

ContentPanel.propTypes = {
  expandKeys: PropTypes.array,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  params: PropTypes.object,
  id: PropTypes.string,
  getPopupContainer: PropTypes.func,
}

export default ContentPanel
