import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Text } from '@beachfront/ui'

export const CustomLegend = ({ payload }) => {
  if (!payload || payload.length === 0) {
    return null
  }

  return (
    <Box mb={2} textAlign='center'>
      {payload.map((el, i) => (
        <Flex key={i} gap={1} mr={2} mb={1} alignItems='center' inline>
          <Box size={8} borderRadius='50%' flex='none' bg={el.color} />
          <Text>{el.value}</Text>
        </Flex>
      ))}
    </Box>
  )
}

CustomLegend.propTypes = {
  payload: PropTypes.array,
}
