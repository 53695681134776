import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Input } from '@beachfront/ui'

const PageSearch = ({ value, onChange, ...rest }) => {
  const [currentValue, setCurrentValue] = useState(value ?? '')

  useEffect(() => {
    if (currentValue !== value) {
      setCurrentValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (event) => {
    const val = event.target.value
    setCurrentValue(val)
    if (!val) {
      onChange?.('')
    }
  }

  const handleSearch = (value, event) => {
    const val = value.trim()
    setCurrentValue(val)
    onChange?.(val)
    event?.preventDefault()
  }

  return (
    <Box width={250} flex='none'>
      <Input.Search
        value={currentValue}
        onChange={handleChange}
        onSearch={handleSearch}
        {...rest}
      />
    </Box>
  )
}

PageSearch.defaultProps = {
  placeholder: 'Search...',
  allowClear: true,
}

PageSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default PageSearch
