import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Flex, Box, Text, Card, CompactNumber } from '@beachfront/ui'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'

export const BarChartWidget = ({
  data,
  title,
  colors,
  tabs,
  cardBodyHeight,
  unit,
  xLabel,
  ...rest
}) => {
  const theme = useTheme()
  const chartColors = colors ?? [theme.colors.primary.base]
  const [activeTabKey, setActiveTabKey] = useState(tabs?.[0]?.key ?? null)

  const tabList = tabs?.map((t) => ({ key: t.key, tab: t.value })) ?? []
  const activeTab = tabs?.find((t) => t.key === activeTabKey) ?? null
  const chartData = data[tabs?.indexOf(activeTab) ?? 0]
  const yLabelText = activeTab?.value || data.unit || unit

  const renderTickY = ({ payload, x, y, textAnchor, fill }) => {
    return (
      <text x={x} y={y} textAnchor={textAnchor} fill={fill}>
        <CompactNumber
          as='tspan'
          value={payload.value}
          fontSize={0}
          style={{ fill: theme.colors.gray[6] }}
        />
      </text>
    )
  }

  return (
    <Card
      title={title}
      bodyStyle={{ height: cardBodyHeight ?? '300px' }}
      tabList={tabList.length > 1 ? tabList : []}
      onTabChange={setActiveTabKey}
      {...rest}
    >
      <Flex width='100%' height='100%' fontFamily='secondary' fontSize={0}>
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            maxBarSize={30}
            margin={{ left: 16, right: 8, bottom: xLabel ? 16 : 0 }}
          >
            <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
            <XAxis
              dataKey='label'
              strokeWidth='1'
              interval='preserveStartEnd'
              axisLine={{ stroke: theme.colors.gray[4] }}
              tickLine={{ stroke: theme.colors.gray[4] }}
              tick={{ fill: theme.colors.gray[6] }}
              tickMargin={8}
              padding={{ right: 8 }}
              label={
                xLabel
                  ? {
                      value: xLabel,
                      position: 'bottom',
                      style: { textAnchor: 'middle' },
                    }
                  : undefined
              }
            />
            <YAxis
              axisLine={false}
              tickLine={{ stroke: theme.colors.gray[4] }}
              tickMargin={8}
              tick={renderTickY}
              label={{
                value: yLabelText,
                angle: -90,
                position: 'left',
                style: { textAnchor: 'middle' },
              }}
            />
            <Tooltip
              isAnimationActive={false}
              cursor={{ fill: theme.colors.gray[3] }}
              wrapperStyle={{ zIndex: 1 }}
              content={<CustomTooltip tab={activeTab} xLabel={xLabel} />}
            />
            <Bar dataKey='value' fill={chartColors[0]} />
          </BarChart>
        </ResponsiveContainer>
      </Flex>
    </Card>
  )
}

BarChartWidget.propTypes = {
  data: PropTypes.array,
  title: PropTypes.node,
  colors: PropTypes.array,
  tabs: PropTypes.array,
  cardBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  xLabel: PropTypes.string,
}

const CustomTooltip = ({ payload, tab, active, xLabel }) => {
  if (!active || !payload || payload.length === 0) {
    return null
  }

  const formatter = new Intl.NumberFormat('en-US')

  return (
    <Box
      p={2}
      border='base'
      borderColor='popover.border'
      borderRadius='base'
      boxShadow='base'
      bg='popover.bg'
    >
      <Box ml={3}>
        <Text>{payload[0].payload?.label}</Text>
      </Box>
      <Flex gap={2} fontSize={1} alignItems='center'>
        <Box size={8} borderRadius='50%' flex='none' bg={payload[0].fill} />
        <Text>
          {tab?.toolTipLabel || xLabel || 'Value'}
          {' : '}
          {formatter.format(payload[0].payload?.value)}
        </Text>
      </Flex>
    </Box>
  )
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  tab: PropTypes.object,
  active: PropTypes.bool,
  xLabel: PropTypes.string,
}
