export const visualMenuItems = [
  'Impressions',
  'Auctions',
  'Partner Revenue',
  'Revenue',
  'CPM',
  'Fill Rate',
  'CTR',
  'VCR',
]
