import React, { useState } from 'react'
import ReactGA from 'react-ga4'
import { Box, Button, Flex, Grid, Heading, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import { Link } from 'react-router-dom'

import { api } from '../../../client-api'
import { useUserData } from '../../../hooks'
import { isNotEmptyArray } from '../../../utils'
import { CUSTOM_FIELDS, ISSUE_TYPES, validateSupport } from '../@utils'

import { HelpCenterHeader, ContactDetails, RequestDetails } from '.'

const issueTypeOptions = Object.values(ISSUE_TYPES).map((type) => ({
  label: type.label,
  value: type.id,
}))

const CUSTOM_FIELD_ACCOUNT = `customfield_${CUSTOM_FIELDS.ACCOUNT_NAME.id}`
const CUSTOM_FIELD_SUBMITTED = `customfield_${CUSTOM_FIELDS.SUBMITTED_BY.id}`
const CUSTOM_FIELD_EMAIL = `customfield_${CUSTOM_FIELDS.CONTACT_EMAIL.id}`
const CUSTOM_FIELD_NAME = `customfield_${CUSTOM_FIELDS.CONTACT_NAME.id}`

const Support = () => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [user] = useUserData()

  const initialValues = {
    contact_email: user.email,
    type: issueTypeOptions[0].value,
  }

  const onReset = (form, values) => {
    form.reset()
    ReactGA.event({
      category: 'help_center',
      action: 'cancel_btn',
      value: 0,
      label: JSON.stringify(values),
    })
  }

  const sanitizePayload = (vals) => {
    const customFields = {
      [CUSTOM_FIELD_ACCOUNT]: vals[CUSTOM_FIELD_ACCOUNT],
      [CUSTOM_FIELD_SUBMITTED]: vals[CUSTOM_FIELD_SUBMITTED],
      [CUSTOM_FIELD_EMAIL]: vals[CUSTOM_FIELD_EMAIL],
      [CUSTOM_FIELD_NAME]: vals[CUSTOM_FIELD_NAME],
    }
    return {
      fields: {
        description: {
          content: [
            {
              content: [
                {
                  text: vals.description,
                  type: 'text',
                },
              ],
              type: 'paragraph',
            },
          ],
          type: 'doc',
          version: 1,
        },
        issuetype: {
          id: vals.type,
        },
        project: {
          id: 12678,
        },
        summary: vals.summary,
        ...customFields,
      },
    }
  }

  const onAttachment = async (files, key) => {
    const formData = new FormData()
    files.forEach((fileObj) => {
      formData.append('file', fileObj.originFileObj)
    })
    formData.append('issueId', key)
    return api.support.attachment(formData)
  }

  const onSubmit = (vals, form) => {
    setIsLoading(true)
    const params = sanitizePayload(vals)

    return api.support
      .issue(params)
      .then(({ data }) => {
        setIsLoading(false)
        if (!isNotEmptyArray(data.errorMessages)) {
          toast.success({
            duration: 8000,
            description:
              'Your ticket was created and someone from our team will reach out to discuss',
            title: `${data?.key} created`,
          })
        } else {
          toast.error({
            duration: 8000,
            description: data.errorMessages,
          })
        }
        if (vals.attachment) {
          onAttachment(vals.attachment.fileList, data?.key)
            .then(() => {})
            .catch(() => {
              toast.error({
                description:
                  'There was an in issue uploading your attachment. Contact your AM for more details',
                title: 'Attachment Error',
              })
            })
        }
        form.initialize(initialValues)
      })
      .catch(() => {
        toast.error({
          description:
            'We could not create your issue at this time. Please contact your AM for more details, or try to submit again.',
          title: 'Issue not created',
        })
        setIsLoading(false)
      })
  }

  return (
    <>
      <HelpCenterHeader />
      <Form
        initialValues={initialValues}
        validate={validateSupport}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, form, pristine, values }) => (
          <form onSubmit={(vals) => handleSubmit(vals, form)}>
            <Heading level={2} mt={4}>
              Support Request
            </Heading>
            <Grid columns={2}>
              <Box columnSpan={[2, 2, 1]}>
                <p>
                  Use the form below to submit any questions, feature requests
                  or potential bugs you have encountered.
                </p>
              </Box>
              <Flex
                alignItems='center'
                justifyContent='flex-end'
                gap={2}
                columnSpan={[2, 2, 1]}
              >
                <Link to='/helpcenter'>
                  <Button>Back</Button>
                </Link>
                <Button onClick={() => onReset(form, values)}>Reset</Button>
                <Button disabled={pristine} htmlType='submit' type='primary'>
                  Save
                </Button>
              </Flex>
            </Grid>
            <Grid columns={2} gap={3} mt={3}>
              <Box columnSpan={[2, 2, 1]}>
                <RequestDetails
                  issueTypes={issueTypeOptions}
                  loading={isLoading}
                />
              </Box>
              <Box columnSpan={[2, 2, 1]}>
                <ContactDetails loading={isLoading} />
              </Box>
            </Grid>
          </form>
        )}
      </Form>
    </>
  )
}

export default Support
