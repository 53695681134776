import { IS_DEALS_ENGINE } from '../../../constants'
import { menuItemConfig } from '../@constants'

import { getMenuItems } from './get-menu-items'

export const getDefaultRoute = (user) => {
  if (IS_DEALS_ENGINE && user?.admin) {
    return { path: '/admin/dashboard', title: 'Dashboard' }
  }

  if (user) {
    const menuItems = getMenuItems(menuItemConfig, user)
    const defaultItem = menuItems[0]?.submenu?.[0] ?? menuItems[0]
    return { path: defaultItem?.route, title: defaultItem?.title }
  }

  return { path: '/transparency', title: 'Transparency' }
}
