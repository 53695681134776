import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter } from '@beachfront/ui'
import { CalendarOutlined } from '@beachfront/ui/icons'

import {
  DateFilterPanel,
  formatDateFilterTag,
} from '../../../components/filter'

const ReportFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='lastRun'
        label='Last Run'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='createDate'
        label='Created'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
    </Filter>
  )
}

ReportFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default ReportFilter
