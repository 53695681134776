export const validateDspForm = (values) => {
  const errors = {}

  if (values.name) {
    values.name = values.name.trim()
  }

  if (values.source) {
    values.source = values.source.trim()
  }

  if (values.sourceSeat) {
    values.sourceSeat = values.sourceSeat.trim()
  }

  if (!values.name) {
    errors.name = 'Required'
  }

  if (!values.type) {
    errors.type = 'Required'
  }

  if (values.type === 'indirect' && !values.source) {
    errors.source = 'Required'
  }

  if (values.type === 'indirect' && !values.sourceSeat) {
    errors.sourceSeat = 'Required'
  }

  return errors
}
