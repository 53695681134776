import http from '../http'
import { MEDIA_LIST_TYPE } from '../../enums'

const ENDPOINT = 'medialist/'

export default {
  bundle({ params, ...data }) {
    return http.post(`${ENDPOINT}all/bundle`, data, { params })
  },
  domain({ params, ...data }) {
    return http.post(`${ENDPOINT}all/domain`, data, { params })
  },
  zipcode({ params, ...data }) {
    return http.post(`${ENDPOINT}all/zipcode`, data, { params })
  },
  metrocode({ params, ...data }) {
    return http.post(`${ENDPOINT}all/dma`, data, { params })
  },
  create(type, data) {
    const slug = type === MEDIA_LIST_TYPE.METRO_CODE.key ? 'dma' : type
    data.mediaType = slug
    return http.post(`${ENDPOINT}create/${slug}`, data)
  },
  update(type, id, data) {
    const slug = type === MEDIA_LIST_TYPE.METRO_CODE.key ? 'dma' : type
    return http.post(`${ENDPOINT}update/${slug}/${id}`, data)
  },
  delete(id) {
    return http.get(`${ENDPOINT}delete/${id}`)
  },
  deleteItem(id, itemId) {
    return http.get(`${ENDPOINT}remove/${id}/${itemId}`)
  },
  getById({ id }) {
    return http.get(`${ENDPOINT}get/${id}`)
  },
  getAttachList({ type }) {
    const slug = type === MEDIA_LIST_TYPE.METRO_CODE.key ? 'dma' : type
    return http.get(`${ENDPOINT}attach/${slug}`)
  },
  getListItems({ params, ...data }) {
    const { type, id, ...rest } = params
    const slug = type === MEDIA_LIST_TYPE.METRO_CODE.key ? 'dma' : type
    return http.post(`${ENDPOINT}get/${slug}/${id}`, data, { params: rest })
  },
  uploadFile(data, { key, ...params }) {
    return http.post(`${ENDPOINT}upload/${key}`, data, { params })
  },
}
