import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter, Radio } from '@beachfront/ui'
import {
  TagsOutlined,
  ReadOutlined,
  UserOutlined,
  CalendarOutlined,
} from '@beachfront/ui/icons'

import { HISTORY_ACTION } from '../../../enums'
import {
  ContainsFilterPanel,
  DateFilterPanel,
  formatStringFilterTag,
  formatDateFilterTag,
} from '../../../components/filter'

const DealHistoryFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='summary'
        label='Summary'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='action'
        label='Action'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<ReadOutlined />}
        formatTag={renderHistoryActionTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {HISTORY_ACTION.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='creatorName'
        label='Creator Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<UserOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='updateDate'
        label='Performed'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
    </Filter>
  )
}

const renderHistoryActionTag = (desc) => {
  const name = HISTORY_ACTION.fromKey(desc.value)?.name
  return name ? `Action is ${name}` : 'All Actions'
}

DealHistoryFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
}

export default DealHistoryFilter
