import moment from 'moment'

import { isNumber } from './type-util'

const pluralRegex = /\[([^|]*)\|([^\]]*)\]/g

/**
 * String template tag function for conditional pluralization.
 * Substitutes strings of the form "[singular|plural]" based on the value of the preceding numeric expression.
 *
 * @param   {Array} strings
 * @param   {Array} exps
 * @returns {String}
 */
export function plural(strings, ...exps) {
  const result = []
  let n = exps[0]

  for (const str of strings) {
    if (isNumber(n)) {
      result.push(str.replace(pluralRegex, n === 1 ? '$1' : '$2'))
    } else {
      result.push(str)
    }

    if (!exps.length) {
      break
    }

    n = exps.shift()
    result.push(n)
  }

  return result.join('')
}

/**
 * Formats a number into a string with fixed digits.
 *
 * @param   {Number} val        The numeric value to format.
 * @param   {Number} digits     The amount of fixed digits.
 * @returns {String}
 */
export function toFixedDigits(val, digits) {
  let result = String(val)
  digits = isNumber(digits) ? digits : 0
  val = isNumber(val) ? val : parseInt(val, 10)

  if (isNumber(val) && !isNaN(val)) {
    result = String(val)

    while (result.length < digits) {
      result = '0' + result
    }

    return result
  }

  return 'NaN'
}

/**
 * Formats seconds into a time stamp.
 *
 * @param   {Number} seconds    The number of seconds to format.
 * @returns {String}
 */
export function toTimeStamp(seconds) {
  if (isNumber(seconds)) {
    const neg = seconds < 0
    const min = Math.floor(Math.abs(seconds) / 60)
    const sec = Math.floor(Math.abs(seconds) % 60)

    return (neg ? '-' : '') + min + ':' + (sec < 10 ? '0' : '') + sec
  }
  return 'NaN'
}

export function numberFormatter(num) {
  if (num > 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B'
  }
  if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M' // convert to M for number from > 1 million
  }
  if (num > 999) {
    return (num / 1000).toFixed(1) + 'K' // convert to K for number from > 1000 < 1 million
  }
  return num // if value < 1000, nothing to do
}

export function toTitleCase(str) {
  return str
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
    .replaceAll('_', ' ')
}

/**
 * Format number with locale settings
 *
 * @param   {Number} value
 * @returns {String}
 */
export function formatCount(value) {
  if (value === undefined) {
    return ''
  }
  return value.toLocaleString()
}

/**
 * Formats number as currency
 *
 * @param   {Number} value
 * @param   {Number} precision
 * @returns {String}
 */
export function formatCurrency(value, precision = 2) {
  if (value === undefined) {
    return ''
  }
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: Number.isInteger(value) ? 0 : precision,
    maximumFractionDigits: precision,
  })
}

/**
 * Formats number as percent
 *
 * @param   {Number} value
 * @param   {Number} precision
 * @returns {String}
 */
export function formatPercent(value) {
  if (value === undefined) {
    return ''
  }
  return value.toLocaleString('en-US', { maximumFractionDigits: 2 }) + '%'
}

/**
 * Formats a date string
 *
 * @param   {String} value
 * @returns {String}
 */
export function formatDate(value) {
  const date = moment(value)
  return date.isValid() ? date.format('YYYY-MM-DD hh:mm a') : '--'
}
