import React, { useRef, useState, useEffect } from 'react'
import { Flex, Box, Text, Modal, Tabs, Table } from '@beachfront/ui'
import { ExternalLinkOutlined } from '@beachfront/ui/icons'
import { useBreakpoints, useTableQuery } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { MEDIA_TYPE } from '../../../enums'
import { useFilters } from '../../../hooks'
import { localFilterKey } from '../../../components/filter'
import { PageSearch } from '../../../components'

import { AccountDetailFilter } from '.'

const AccountDetailModal = ({ title, data, isOpen, onClose }) => {
  const [tabKey, setTabKey] = useState('eligible')
  const [searchQuery, setSearchQuery] = useState('')
  const [filters, setFilters] = useFilters(localFilterKey.ACCOUNT_INVENTORY)
  const filterRef = useRef()
  const breakpoints = useBreakpoints()

  const matchesQuery = (dt) => {
    return ['id', 'title'].some((key) =>
      String(dt[key]).toLowerCase().includes(searchQuery.toLowerCase())
    )
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const tabData =
    (tabKey === 'eligible' ? data?.eligibleApps : data?.noneligibleApps) ?? []

  const table = useTableQuery({
    data: applyQuery(tabData),
  })

  useEffect(() => {
    table.setFilters(filters.populated)
  }, [filters.populated])

  const scroll = { y: 320 }
  const emptyText =
    tabKey === 'eligible'
      ? 'No Eligible Inventory Found'
      : 'No Non Eligible Inventory Found'

  const onTabChange = (key) => {
    setTabKey(key)
    setSearchQuery('')
  }

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 200,
      render: (id, rec) => (
        <Flex gap={2} alignItems='center'>
          <Text flexGrow={1} ellipsis>
            {id}
          </Text>
          <Box
            as='a'
            width={20}
            href={rec.url}
            textAlign='right'
            target='_blank'
            rel='noopener noreferrer'
          >
            <ExternalLinkOutlined />
          </Box>
        </Flex>
      ),
    },
    {
      dataIndex: 'title',
      title: 'Name',
      width: 200,
      sorter: true,
    },
    {
      dataIndex: 'mediaType',
      title: 'Media Type',
      width: 100,
      sorter: true,
      filter: true,
      render: (key) => MEDIA_TYPE.fromKey(key.toLowerCase())?.name,
    },
  ]

  const toolbar = () => (
    <Table.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <AccountDetailFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
        <PageSearch value={searchQuery} onChange={setSearchQuery} />
      </Flex>
    </Table.Toolbar>
  )

  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={onClose}
      width={breakpoints.md ? '60%' : '100%'}
      footer={null}
      centered
      destroyOnClose
    >
      <Tabs activeKey={tabKey} onChange={onTabChange}>
        <Tabs.TabPane key='eligible' tab='Eligible Inventory' />
        <Tabs.TabPane key='ineligible' tab='Non Eligible Inventory' />
      </Tabs>
      <Table
        rowKey='id'
        emptyText={emptyText}
        dataSource={table.data}
        columns={columns}
        toolbar={toolbar}
        scroll={scroll}
        filters={filters.populated}
        onFilter={(key) => filterRef.current?.addFilter(key)}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: false,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
    </Modal>
  )
}

AccountDetailModal.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default AccountDetailModal
