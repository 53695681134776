import React, { useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Alert, Flex, Heading } from '@beachfront/ui'
import { useDocumentTitle } from '@beachfront/ui/hooks'
import styled from 'styled-components'
import moment from 'moment'

import { HOST_NAME } from '../../constants'
import { isNotEmptyObject } from '../../utils'
import { useUserData } from '../../hooks'

let alertClosed = false

const PageHeader = ({ title, extra, actions, children }) => {
  const headerRef = useRef()
  const [user] = useUserData()

  const headerTitle = isNotEmptyObject(title) ? title.props.children[0] : title
  useDocumentTitle(title ? `${HOST_NAME} - ${headerTitle}` : HOST_NAME)

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        headerRef.current.style.borderBottom =
          window.scrollY === 0 ? 'transparent' : ''
      }
    }
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const onCloseAlert = () => {
    alertClosed = true
  }

  const showAlert =
    !alertClosed &&
    isNotEmptyObject(user.systemMessage) &&
    moment(user.systemMessage?.expiration).valueOf() > moment().valueOf()

  return (
    <>
      <Flex
        ref={headerRef}
        className='page-header'
        mx={-4}
        px={4}
        pt={3}
        pb={2}
        flexDirection='column'
        justifyContent='space-between'
        position='sticky'
        top='40px'
        zIndex='1'
        borderBottom='split'
        bg='gray.0'
      >
        {showAlert ? (
          <StyledAlert
            mx={-4}
            mt={-3}
            mb={3}
            type={user.systemMessage.type}
            message={user.systemMessage.message}
            onClose={onCloseAlert}
            closable
            banner
            showIcon
          />
        ) : null}
        <Flex
          inline
          gap={2}
          justifyContent='space-between'
          alignItems='center'
          minHeight='32px'
        >
          <Flex gap={3} flexGrow={1}>
            <Heading level={2} my={0} ellipsis>
              {title}
            </Heading>
            {extra}
          </Flex>
          {actions}
        </Flex>
        {children}
      </Flex>
    </>
  )
}

const StyledAlert = styled(Alert)`
  padding-left: 54px;
  padding-right: 46px;

  & > .anticon {
    left: 32px;
  }

  & > button {
    right: 32px;
  }
`

PageHeader.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.node,
}

export default PageHeader
