import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Flex,
  Box,
  Label,
  Checkbox,
  InputNumber,
  Select,
} from '@beachfront/ui'
import { Field, useForm, useFormState } from '@beachfront/ui/forms'

import { SelectField } from '../../../components'
import { yesNoList } from '..'

const AccountVideoForm = ({ accountFilters = {}, getPopupContainer }) => {
  const form = useForm()
  const { values } = useFormState()

  const responseKeys =
    accountFilters.creativeAdDuration?.map((el) => el.key) ?? []

  const onSelectAll = (event) => {
    if (event.target.checked) {
      form.change('videoCreativeAdDuration', responseKeys)
    } else {
      form.change('videoCreativeAdDuration', [])
    }
  }

  return (
    <Grid gap={2} columns={['1fr 1fr', '2fr 3fr']}>
      <Label pt={1} whiteSpace='normal'>
        Can Publisher Target by Content Length?
      </Label>
      <Box>
        <Field name='videoContentBylength'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Accepted Creative Ad Duration + Minimum Rates
      </Label>
      <Box>
        <Box whiteSpace='nowrap' mb={1}>
          <Checkbox
            checked={isEqualArray(responseKeys, values.videoCreativeAdDuration)}
            onChange={onSelectAll}
          >
            All
          </Checkbox>
        </Box>
        <Field name='videoCreativeAdDuration'>
          <Checkbox.Group style={{ width: '100%' }}>
            {accountFilters.creativeAdDuration?.map((item) => (
              <Box key={item.key} whiteSpace='nowrap'>
                <Checkbox value={item.key}>{item.value}</Checkbox>
              </Box>
            ))}
          </Checkbox.Group>
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Minimum CPM
      </Label>
      <Flex gap={2}>
        <Field name='videoCpmOps'>
          <Select
            defaultValue='EQUAL'
            style={{ width: '120px' }}
            getPopupContainer={getPopupContainer}
          >
            <Select.Option value='EQUAL'>Equal</Select.Option>
            <Select.Option value='GREATER_THAN'>Greater than</Select.Option>
            <Select.Option value='LESS_THAN'>Less than</Select.Option>
          </Select>
        </Field>
        <Field name='videoMinCpm' style={{ width: '100%' }}>
          <InputNumber min={1} placeholder='Minimum CPM' />
        </Field>
      </Flex>
      <Label pt={1} whiteSpace='normal'>
        Foreign Language Content
      </Label>
      <Box>
        <Field name='videoForeignLanguage'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.foreignLanguageContent || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Content Length
      </Label>
      <Box>
        <Field name='videoContentLength'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.contentLength || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Break out tags to target specific bundles of content?
      </Label>
      <Box>
        <Field name='videoBundleContent'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Video Content Description
      </Label>
      <Box>
        <Field name='videoContentDesc'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.videoContentDescription || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Utilize a DAI/SSAI vendor?
      </Label>
      <Box>
        <Field name='videoUtilizedaissaiVendor'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Any Brand Blocks?
      </Label>
      <Box>
        <Field name='videoBrandBlocks'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        First Price Auction?
      </Label>
      <Box>
        <Field name='videoFirstPriceAuction'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Is Inventory 100% OTT?
      </Label>
      <Box>
        <Field name='videoOtt'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Full Episode Player?
      </Label>
      <Box>
        <Field name='videoFullEpisodePlayer'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Player Type
      </Label>
      <Box>
        <Field name='videoPlayerType'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.playerType || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Ad Positioning
      </Label>
      <Box>
        <Field name='videoAdPosition'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.adPosition || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Ad Server
      </Label>
      <Box>
        <Field name='videoAdServer'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.adServer || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        DAI/SSAI Vendor
      </Label>
      <Box>
        <Field name='videodaissaiVender'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.daissaiVendor || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Content Type
      </Label>
      <Box>
        <Field name='videoContentType'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.contentType || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
    </Grid>
  )
}

const isEqualArray = (arr1 = [], arr2 = []) => {
  return arr1.length === arr2.length && arr1.every((el) => arr2.includes(el))
}

AccountVideoForm.propTypes = {
  accountFilters: PropTypes.object,
  getPopupContainer: PropTypes.func,
}

export default AccountVideoForm
