import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, Result, Button } from '@beachfront/ui'

import ErrorLogo from '../../../assets/error-icon.png'

const detectorPromise = fetch('https://www3.doubleclick.net', {
  method: 'HEAD',
  mode: 'no-cors',
  cache: 'no-store',
})

const AdBlockDetector = ({ children }) => {
  const [adBlockDetected, setAdBlockDetected] = useState(false)

  useEffect(() => {
    detectorPromise.catch(() => setAdBlockDetected(true))
  }, [])

  if (adBlockDetected) {
    return (
      <Flex height='100%' justifyContent='center'>
        <Result
          icon={<img src={ErrorLogo} alt='Error' width={140} />}
          title='Ad Blocker Detected'
          description='Disable Ad Blocker to access this page. Use the below button after disabling Ad Blocker to get back on track.'
        >
          <Button type='primary' onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Result>
      </Flex>
    )
  }

  return children
}

AdBlockDetector.propTypes = {
  children: PropTypes.node,
}

export default AdBlockDetector
