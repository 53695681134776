export const sharedChartRanges = [
  {
    value: 'last7day',
    label: 'Last 7 Days',
  },
  {
    value: 'last15day',
    label: 'Last 15 Days',
  },
  {
    value: 'last30day',
    label: 'Last 30 Days',
  },
]
