import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useTargetingWidgets = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['targeting', 'widgets', params],
    queryFn: async ({ signal }) => {
      const { timezone, pmp, admin, mediaId } = params

      const payload = {}

      if (timezone) {
        payload.timezone = timezone
      }

      if (pmp) {
        payload.pmp = pmp
      }

      if (admin) {
        payload.admin = admin
      }

      if (admin && mediaId) {
        payload.mediaId = mediaId
      }

      const requestFn = pmp
        ? api.mediaPlan.pmpGetWidgets
        : api.mediaPlan.getWidgets

      const res = await requestFn(payload, signal)
      return res.data
    },
    staleTime: 5 * 60 * 1000,
    enabled,
  })
}
