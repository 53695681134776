import { isValidMongoId } from '../../../utils'

export const renderExpressionItem = (item, isUppercase = false) => {
  if (item.type === 'GROUP') {
    const groupContent = item.children.map((child) =>
      renderExpressionItem(child)
    )

    return `(${groupContent.join('')})`
  }

  if (item.type === 'SET') {
    const setContent = item.children
      .map((child) => {
        if (child.subType) {
          if (isValidMongoId(child.key)) {
            return `[mgenres:${child.key}]`
          } else {
            return `[${child.subType}:${child.key}]`
          }
        }
        return child.id
      })
      .join(',')
    return `{${setContent}}`
  }

  if (item.type === 'UNION') {
    const { value } = item

    if (value.toLowerCase() === 'not') {
      return '!'
    }

    return isUppercase
      ? `${item.value}`.toUpperCase()
      : item.value.toLowerCase()
  }

  return '' // Default case
}

export const recursiveDelete = (stateItem, targetId) => {
  if (stateItem.id === targetId) {
    return false
  }

  if (stateItem.children && stateItem.children.length > 0) {
    stateItem.children = stateItem.children.filter((child) =>
      recursiveDelete(child, targetId)
    )
  }

  return true
}

export const addToExpressionRecursive = (item, newSet, activeItem) => {
  if (item.id === activeItem.id && item.children) {
    return {
      ...item,
      children: [...item.children, newSet],
    }
  }

  if (item.children && item.children.length > 0) {
    return {
      ...item,
      children: item.children.map((child) =>
        addToExpressionRecursive(child, newSet, activeItem)
      ),
    }
  }
  return item
}

export const addSegmentToSet = (segment, set, expression, type = 'segment') => {
  return expression.map((exp) => {
    if (exp.id === set.id) {
      return {
        ...exp,
        children: [
          ...exp.children,
          { ...segment, clientId: `${type}-${Date.now()}`, type: 'SEGMENT' },
        ],
      }
    }

    if (exp.children && exp.children.length > 0) {
      return {
        ...exp,
        children: addSegmentToSet(
          { ...segment, clientId: `${type}-${Date.now()}`, type: 'SEGMENT' },
          set,
          exp.children,
          type
        ),
      }
    }

    return exp
  })
}

export const getParentProvider = (item, providers) => {
  const { provider } = item
  let hackedProvider = provider

  if (provider?.toLowerCase() === 'truoptik') {
    hackedProvider = 'truaudience'
  }

  const providerInfo = providers.find(
    (pv) => pv?.value?.toLowerCase() === hackedProvider?.toLowerCase()
  )

  if (!providerInfo) {
    return null
  }

  const { parentProvider } = providerInfo

  return parentProvider ?? providerInfo.value
}
