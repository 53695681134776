import { isNotEmptyArray } from '../../../utils'

export function getKeys(data, textField = 'key') {
  if (isNotEmptyArray(data)) {
    return data.map((el) => el[textField])
  }
  return []
}

export function getSegmentKeys(data) {
  if (isNotEmptyArray(data)) {
    return data.map((el) => ({
      id: el.id,
      type: 'RDS',
      source: el.source,
    }))
  }
  return []
}

export function getExcludeInitialValue(bool) {
  return bool ? 'EXCLUDE' : 'INCLUDE'
}

export function getOrAndInitialValue(bool) {
  return bool ? 'AND' : 'OR'
}
