import React from 'react'

export function renderAnchor(val) {
  const href = val.startsWith('http') ? val : '//www.' + val
  return (
    <a target='_blank' rel='noopener noreferrer' href={href}>
      {val}
    </a>
  )
}
