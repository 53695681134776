export default [
  [
    'Status ( Acceptable Values: passedIn, override, overrideIfEmpty )',
    'Existing Bundle',
    'Bundle',
    'Name',
    'Domain',
    'App Store URL',
    'IAB Categories ( List of values separated by | )',
  ],
]
