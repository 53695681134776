export const advertiserIdCell = {
  title: 'Advertiser ID',
  dataIndex: 'advertiserId',
  width: 300,
  editable: true,
}

export const publisherIdCell = {
  title: 'IO Account ID',
  dataIndex: 'pubId',
  width: 300,
  editable: true,
}

export const seatAgencyIdCell = {
  title: 'Seat ID / Agency ID',
  dataIndex: 'seatAgencyId',
  width: 300,
  editable: true,
}

export const adomainCell = {
  title: 'Adomain',
  dataIndex: 'adomain',
  width: 300,
  editable: true,
}
