import { useReducer, useState, useEffect } from 'react'

import { resolveProp, parseFields, getErrorMessage } from '../utils'

/**
 * Uses a fetch request and returns the loading state, error state, and response data of the request.
 *
 * A new fetch will be initiated when:
 * - The request function reference changes.
 * - The properties of the payload change.
 *
 * WARNING:
 * Using an inline function for the request will cause an infinite fetch loop because a new function reference is
 * created on every render. To avoid this, the request function must be memoized, or defined outside the body of
 * the component.
 *
 * @example
 * <code>
 *     let response = useFetch({
 *         request: api.entity.findDistinctById,
 *         payload: { id: 42 },
 *         schema: {
 *             data: 'path.to.response.data',
 *             total: 'path.to.response.total',
 *             fields: {
 *                 someDateField: 'date',
 *                 someUnixField: 'unix'
 *             }
 *         }
 *     });
 * </code>
 *
 * @param   {Object}   props
 * @param   {Function} props.request          Makes a request with the given payload as an argument. Must return a promise.
 * @param   {Object}   [props.payload]        The payload to send with the request.
 * @param   {Object}   [props.schema]
 * @param   {Object}   [props.schema.data]    The path to the data property on the response object.
 * @param   {String}   [props.schema.total]   The path to the total property on the response object.
 * @param   {Object}   [props.schema.fields]  Optionally maps each data field to a type (date, unix).
 * @returns {{
 *     payload: Object,
 *     data: Object,
 *     total: Number,
 *     error: Object,
 *     loading: Boolean,
 *     refresh: function()
 * }}
 */
export const useFetch = (props) => {
  const { request, payload, schema = {} } = props
  const initialState = {
    data: null,
    error: null,
    msg: null,
    loading: true,
    total: null,
    payload,
  }

  const [cacheBreaker, setCacheBreaker] = useState()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    let didCancel = false

    if (request) {
      dispatch({ loading: true, payload })

      request(payload)
        .then((response) => {
          if (didCancel) {
            return
          }

          const data = schema.data
            ? resolveProp(response.data, schema.data, null)
            : response.data
          const total = schema.total
            ? resolveProp(response.data, schema.total, 0)
            : null
          const title = schema.title
            ? resolveProp(response.data, schema.title, null)
            : null

          parseFields(data, schema.fields)
          dispatch({
            data,
            total,
            error: null,
            loading: false,
            msg: response.data.msg,
            title,
          })
        })
        .catch((error) => {
          dispatch({
            error: getErrorMessage(error),
            data: null,
            total: null,
            loading: false,
          })
        })
    } else {
      dispatch({ loading: false, payload })
    }
    return () => {
      didCancel = true
    }
  }, [request, JSON.stringify(payload), cacheBreaker])

  const refresh = () => setCacheBreaker(Date.now())

  return { ...state, refresh }
}

/**
 * Reducer for the fetch state.
 *
 * @param   {Object} state
 * @param   {Object} action
 * @returns {Object}
 */
const reducer = (state, action) => {
  return { ...state, ...action }
}
