import React from 'react'
import { Filter, Radio, Badge } from '@beachfront/ui'
import { TagsOutlined } from '@beachfront/ui/icons'

import { DSP_TYPE, DSP_STATUS } from '../../../../enums'
import {
  ContainsFilterPanel,
  formatStringFilterTag,
} from '../../../../components/filter'

const DspFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='name'
        label='Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='type'
        label='Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<TagsOutlined />}
        formatTag={renderTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {DSP_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='status'
        label='Status'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={renderStatusIcon}
        formatTag={renderStatusTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {DSP_STATUS.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='seat'
        label='Seat'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={true}
        icon={renderSeatIcon}
        formatTag={renderSeatTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            <Radio.Button value={true}>Enabled</Radio.Button>
            <Radio.Button value={false}>Disabled</Radio.Button>
          </Radio.Group>
        )}
      />
    </Filter>
  )
}

function renderTypeTag(desc) {
  const name = DSP_TYPE.fromKey(desc.value)?.name
  return name ? `Type is ${name}` : 'All Types'
}

function renderStatusTag(desc) {
  const name = DSP_STATUS.fromKey(desc.value)?.name
  return name ? `Status is ${name}` : 'Any Status'
}

function renderStatusIcon(desc) {
  if (desc.value === DSP_STATUS.ENABLED.key) {
    return <Badge status='success' />
  }
  if (desc.value === DSP_STATUS.DISABLED.key) {
    return <Badge status='error' />
  }
  return null
}

function renderSeatIcon(desc) {
  return desc.value ? <Badge status='success' /> : <Badge status='error' />
}

function renderSeatTag(desc) {
  return `Seat is ${desc.value ? 'Enabled' : 'Disabled'}`
}

export default DspFilter
