import { MEDIA_PLAN_STATUS, PLATFORM } from '../../enums'
import {
  isNotEmptyArray,
  isNotEmptyObject,
  isNumber,
  isString,
  isValidMongoId,
} from '../../utils'
import { getKeys, getSegmentKeys } from '../media-plan'

export const createAvailsPostPayload = (values) => {
  const bundleIds = values.bundleMedia?.ids || []
  const isMongoBundleId = bundleIds.some((el) => isValidMongoId(el.id))
  const bundleList =
    isMongoBundleId && isNotEmptyArray(bundleIds)
      ? {
          ...values.bundleMedia,
          ids: getKeys(bundleIds, 'id'),
        }
      : {
          ids: values.bundle,
          included: values.excludeBundle === 'INCLUDE',
        }
  const domainIds = values.domainMedia?.ids || []
  const isMongoDomainId = domainIds.some((el) => isValidMongoId(el.id))
  const domainList =
    isMongoDomainId && isNotEmptyArray(domainIds)
      ? {
          ...values.domainMedia,
          ids: getKeys(domainIds, 'id'),
        }
      : {
          ids: values.domain,
          included: values.excludeDomain === 'INCLUDE',
        }
  const targeting = {
    platform: values.platform.includes(PLATFORM.ALL.key)
      ? [PLATFORM.ALL.key]
      : values.platform,
    country: values.country || [],
    state: [],
    dma: [],
    includeLivestream: values.includeLivestream,
    excludeCountry: values.excludeCountry === 'EXCLUDE',
    excludeState: values.excludeState === 'EXCLUDE',
    excludeDma: values.excludeDma === 'EXCLUDE',
    excludeChannel: values.excludeChannel === 'EXCLUDE',
    excludeNetwork: values.excludeNetwork === 'EXCLUDE',
    excludeGenres: values.excludeGenres === 'EXCLUDE',
    excludeiabCat: values.excludeiabCat === 'EXCLUDE',
    excludeRating: values.excludeRating === 'EXCLUDE',
    excludeApp: values.excludeApp === 'EXCLUDE',
  }

  if (isNotEmptyArray(values.accountExcludeList)) {
    targeting.excludeAccIds = true
  }
  if (values.buyers) {
    targeting.buyerId = values.buyers
  }
  if (isNotEmptyArray(values.channel)) {
    targeting.channel = values.channel
  }
  if (isNotEmptyArray(values.network)) {
    targeting.network = values.network
  }
  if (isNotEmptyArray(values.genres)) {
    targeting.masterListGenres = values.genres.filter(
      (item) => isString(item) && isValidMongoId(item)
    )
    targeting.genres = values.genres.filter((item) => isNumber(item))
  }

  if (isNotEmptyArray(values.country) && values.country.includes('US')) {
    if (isNotEmptyArray(values.dma)) {
      targeting.dma = values.dma
    }
    if (isNotEmptyArray(values.state)) {
      targeting.state = values.state
    }
  }

  if (isNotEmptyArray(values.iabCategory)) {
    targeting.iabCategory = values.iabCategory
  }

  if (isNotEmptyArray(values.app)) {
    targeting.app = values.app
  }

  if (isNotEmptyArray(values.rating)) {
    targeting.rating = values.rating
  }

  const payload = {
    title: values.title,
    status: values.status === MEDIA_PLAN_STATUS.ENABLE.key,
    targeting: targeting,
    domainMedia: isNotEmptyArray(domainList.ids) ? domainList : null,
    bundleMedia: isNotEmptyArray(bundleList.ids) ? bundleList : null,
    zipMedia: null,
    dmaMedia: null,
    accSettingsReq: isNotEmptyObject(values.inventoryInfoAcc)
      ? createAccountSettingsPayload(values.inventoryInfoAcc)
      : null,
    includeSegments: isNotEmptyArray(values.includeSegments)
      ? getSegmentKeys(values.includeSegments)
      : [],
    excludeSegments: isNotEmptyArray(values.excludeSegments)
      ? getSegmentKeys(values.excludeSegments)
      : [],
    includeSegmentAnd: values.includeSegmentAnd === 'AND',
    mediaType: isNotEmptyArray(values.mediaType) ? values.mediaType[0] : null,
    buyerId: values.buyers,
    contentBooleanLogic: values.contentBooleanLogic,
    audienceBooleanLogic: values.audienceBooleanLogic,
  }

  if (values.country?.includes('US') || values.country?.includes('CA')) {
    if (values.zipMedia?.ids?.length) {
      payload.zipMedia = {
        ...values.zipMedia,
        ids: getKeys(values.zipMedia.ids, 'id'),
      }
    }
    if (values.dmaMedia?.ids?.length) {
      payload.dmaMedia = {
        ...values.dmaMedia,
        ids: getKeys(values.dmaMedia.ids, 'id'),
      }
    }
  }

  if (isNotEmptyArray(values.accountExcludeList)) {
    payload.inventoryInfoAccIds = values.accountExcludeList
  }

  return payload
}

export const createAccountSettingsPayload = (val) => {
  const payload = {}

  if (val.reqCreativeApproval === 'YES') {
    payload.reqCreativeApproval = 1
  } else if (val.reqCreativeApproval === 'NO') {
    payload.reqCreativeApproval = 0
  }

  if (val.reqCompetitiveSeparation === 'YES') {
    payload.reqCompetitiveSeparation = 1
  } else if (val.reqCompetitiveSeparation === 'NO') {
    payload.reqCompetitiveSeparation = 0
  }

  if (val.videoContentBylength === 'YES') {
    payload.videoContentBylength = 1
  } else if (val.videoContentBylength === 'NO') {
    payload.videoContentBylength = 0
  }

  if (val.videoBundleContent === 'YES') {
    payload.videoBundleContent = 1
  } else if (val.videoBundleContent === 'NO') {
    payload.videoBundleContent = 0
  }

  if (val.videoUtilizeVendor === 'YES') {
    payload.videoUtilizeVendor = 1
  } else if (val.videoUtilizeVendor === 'NO') {
    payload.videoUtilizeVendor = 0
  }

  if (val.videoBrandBlocks === 'YES') {
    payload.videoBrandBlocks = 1
  } else if (val.videoBrandBlocks === 'NO') {
    payload.videoBrandBlocks = 0
  }

  if (val.displayBundleContent === 'YES') {
    payload.displayBundleContent = 1
  } else if (val.displayBundleContent === 'NO') {
    payload.displayBundleContent = 0
  }

  if (val.displayBrandBlocks === 'YES') {
    payload.displayBrandBlocks = 1
  } else if (val.displayBrandBlocks === 'NO') {
    payload.displayBrandBlocks = 0
  }

  if (val.displayFirstPriceAuction === 'YES') {
    payload.displayFirstPriceAuction = 1
  } else if (val.displayFirstPriceAuction === 'NO') {
    payload.displayFirstPriceAuction = 0
  }

  if (val.displayamp === 'YES') {
    payload.displayamp = 1
  } else if (val.displayamp === 'NO') {
    payload.displayamp = 0
  }

  if (val.videoUtilizedaissaiVendor === 'YES') {
    payload.videoUtilizedaissaiVendor = 1
  } else if (val.videoUtilizedaissaiVendor === 'NO') {
    payload.videoUtilizedaissaiVendor = 0
  }

  if (val.videoFirstPriceAuction === 'YES') {
    payload.videoFirstPriceAuction = 1
  } else if (val.videoFirstPriceAuction === 'NO') {
    payload.videoFirstPriceAuction = 0
  }

  if (val.videoFullEpisodePlayer === 'YES') {
    payload.videoFullEpisodePlayer = 1
  } else if (val.videoFullEpisodePlayer === 'NO') {
    payload.videoFullEpisodePlayer = 0
  }

  if (val.videoOtt === 'YES') {
    payload.videoOtt = 1
  } else if (val.videoOtt === 'NO') {
    payload.videoOtt = 0
  }

  if (val.displayBundleBreakTag === 'YES') {
    payload.displayBundleBreakTag = 1
  } else if (val.displayBundleBreakTag === 'NO') {
    payload.displayBundleBreakTag = 0
  }

  if (
    isNotEmptyArray(val.displayAdServer) &&
    val.displayAdServer.includes('Other') &&
    val.displayOtherAdServer
  ) {
    payload.displayOtherAdServer = val.displayOtherAdServer
  }

  if (isNotEmptyArray(val.videoPlayerType)) {
    payload.videoPlayerType = val.videoPlayerType
  }

  if (isNotEmptyArray(val.videoContentType)) {
    payload.videoContentType = val.videoContentType
  }

  if (isNotEmptyArray(val.videodaissaiVender)) {
    payload.videodaissaiVender = val.videodaissaiVender
  }

  if (isNotEmptyArray(val.videoAdServer)) {
    payload.videoAdServer = val.videoAdServer
  }

  if (isNotEmptyArray(val.videoAdPosition)) {
    payload.videoAdPosition = val.videoAdPosition
  }

  if (isNotEmptyArray(val.sensitiveCatAllowed)) {
    payload.sensitiveCatAllowed = val.sensitiveCatAllowed
  }

  if (isNotEmptyArray(val.contentType)) {
    payload.contentType = val.contentType
  }

  if (isNotEmptyArray(val.displayForeignLanguage)) {
    payload.displayForeignLanguage = val.displayForeignLanguage
  }

  if (isNotEmptyArray(val.displayAdServer)) {
    payload.displayAdServer = val.displayAdServer
  }

  if (isNotEmptyArray(val.displaycontentType)) {
    payload.displaycontentType = val.displaycontentType
  }

  if (isNotEmptyArray(val.videoCreativeAdDuration)) {
    payload.videoCreativeAdDuration = val.videoCreativeAdDuration
  }

  if (isNotEmptyArray(val.videoForeignLanguage)) {
    payload.videoForeignLanguage = val.videoForeignLanguage
  }

  if (isNotEmptyArray(val.videoContentLength)) {
    payload.videoContentLength = val.videoContentLength
  }

  if (isNotEmptyArray(val.videoContentDesc)) {
    payload.videoContentDesc = val.videoContentDesc
  }

  if (val.videoMinCpm) {
    const cpm = val.videoMinCpm
    const fixed = Number(cpm).toFixed(1)
    payload.videoCpmObj = { minCpm: fixed, ops: val.videoCpmOps }
  }

  if (val.displayMinCpm) {
    const cpm = val.displayMinCpm
    const fixed = Number(cpm).toFixed(1)
    payload.displayCpmObj = { minCpm: fixed, ops: val.displayCpmOps }
  }

  return payload
}
