export const validateSystemMessages = (vals) => {
  const errors = {}

  if (!vals.message) {
    errors.message = 'Required'
  }

  if (!vals.expiration) {
    errors.expiration = 'Required'
  }

  return errors
}
