import React, { useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Grid, Box, Button, Card, Tabs, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../../../client-api'
import { getErrorMessage } from '../../../../utils'
import {
  PageHeader,
  NetworkError,
  CommonSpinner,
  DirtyPrompt,
} from '../../../../components'
import { validateSegmentForm } from '../@utils'

import { SegmentFieldForm, SegmentUploadForm, SegmentHistory } from '.'

const UpdateSegment = () => {
  const { id, tabKey = 'manage' } = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const segment = useQuery({
    queryKey: ['uploaded-segments', id],
    queryFn: async () => {
      const res = await api.segment.getUploadedById({ id })
      return res.data
    },
  })

  const initialValues = useMemo(() => {
    const values = { id }
    if (segment.data) {
      Object.assign(values, segment.data)
    }
    return values
  }, [segment.data])

  const onSubmit = (values) => {
    const payload = {
      name: values.name,
      dataType: values.dataType,
      status: values.status,
      remoteId: values.remoteId,
      price: values.price,
    }

    if (values?.uploadState?.url) {
      payload.fileUrl = values.uploadState.url
    }

    return api.segment.update(id, payload).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'Segment updated.' })
          setTimeout(() => segment.refetch(), 100)
        } else {
          toast.error({
            title:
              res.data?.msg ||
              res.data.errorDetails ||
              'Unable to update this segment. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const onTabChange = (key) => {
    return navigate(`/segment/upload/${id}/${key}`)
  }

  if (segment.error) {
    return (
      <NetworkError
        description={segment.error || 'Segment Not Found'}
        buttonLabel='Back to Uploaded Segments'
        onAction={() => navigate('/segment/upload')}
      />
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateSegmentForm}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, dirty }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title={`Edit Segment - ${segment.data?.name ?? ''}`}
            actions={
              <>
                {tabKey === 'manage' ? (
                  <Button
                    onClick={() => segment.refetch()}
                    icon={<ReloadOutlined />}
                    style={{ flex: 'none' }}
                  />
                ) : null}
                <Link to='/segment/upload'>
                  <Button>Cancel</Button>
                </Link>
                {tabKey === 'manage' ? (
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={submitting}
                    disabled={!dirty}
                  >
                    Update
                  </Button>
                ) : null}
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3}>
            <Tabs type='card' activeKey={tabKey} onChange={onTabChange}>
              <Tabs.TabPane key='manage' tab='Manage'>
                <Box mt={2} height={['auto', 'auto', 'calc(100vh - 190px)']}>
                  {segment.isFetching ? (
                    <CommonSpinner />
                  ) : (
                    <Grid gap={3} columns={[1, 1, '350px 1fr']} height='100%'>
                      <Card>
                        <SegmentFieldForm isUpload={true} />
                      </Card>
                      <Card>
                        <SegmentUploadForm />
                      </Card>
                    </Grid>
                  )}
                </Box>
              </Tabs.TabPane>
              <Tabs.TabPane key='history' tab='History'>
                <SegmentHistory segmentId={id} />
              </Tabs.TabPane>
            </Tabs>
          </Box>
        </form>
      )}
    </Form>
  )
}

export default UpdateSegment
