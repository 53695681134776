import { Filter } from '@beachfront/ui'

import { toTitleCase } from '../../../utils'

/**
 * Formats a tag for string filters.
 *
 * @param   {Object} desc   The filter descriptor.
 * @param   {Object} opt    The filter option.
 * @returns {String}
 */
export const formatStringFilterTag = (desc, opt) => {
  const value = String(desc.value).substring(0, 8)
  const ellipsis = String(desc.value).length > 8 ? '...' : ''

  if (desc.operator === Filter.Operator.Any.EQUALS.key) {
    return `${opt.label} equals "${value + ellipsis}"`
  }

  if (desc.operator === Filter.Operator.String.CONTAINS.key) {
    return `${opt.label} contains "${value + ellipsis}"`
  }

  if (desc.operator === Filter.Operator.String.STARTS_WITH.key) {
    return `${opt.label} starts with "${value + ellipsis}"`
  }

  if (desc.operator) {
    return `${opt.label} ${toTitleCase(desc.operator)} "${value + ellipsis}"`
  }

  return opt.label
}
