import React, { useRef } from 'react'
import { Flex, Table, Modal, Button, useToast } from '@beachfront/ui'
import { DeleteFilled } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { useContentSize } from '../../../hooks'
import { getErrorMessage, plural } from '../../../utils'
import { NetworkError } from '../../../components'
import { userColumns } from '../@constants'

import { UserFilter } from '.'

const UserTable = ({
  admin,
  filters,
  setFilters,
  users,
  loading,
  error,
  onRefetch,
}) => {
  const { contentWidth, contentHeight } = useContentSize()
  const toast = useToast()
  const filterRef = useRef()
  const scroll = {
    y: contentHeight - 260,
    x: contentWidth - 300,
  }

  const onDelete = (record) => {
    const ids =
      users.selectedRowKeys.length > 0 ? users.selectedRowKeys : [record.userId]
    Modal.confirm({
      title: 'Delete User',
      content: admin
        ? plural`Are you sure you want to delete ${ids.length} user[|s]?`
        : 'Are you sure you want to delete the selected user?',
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        const request = admin
          ? api.admin.user.delete(ids)
          : api.user.delete(record.userId)
        return request.then(
          () => {
            toast.success({ title: admin ? 'Users deleted.' : 'User deleted.' })
            users.setSelectedRowKeys([])
            users.refetch()
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
            throw error
          }
        )
      },
    })
  }

  const columns = [
    ...userColumns,
    {
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 45,
      render: (val, rec) => (
        <Button
          danger
          type='link'
          size='small'
          onClick={() => onDelete(rec)}
          icon={<DeleteFilled />}
        />
      ),
    },
  ]

  if (admin) {
    columns.splice(3, 0, {
      dataIndex: 'accounts',
      title: 'Accounts',
      width: 180,
    })
  }

  if (!loading && error) {
    return (
      <NetworkError description={getErrorMessage(error)} onAction={onRefetch} />
    )
  }

  const rowSelection = admin
    ? {
        selectedRowKeys: users.selectedRowKeys,
        onChange: users.setSelectedRowKeys,
        columnWidth: 40,
        preserveSelectedRowKeys: true,
      }
    : null

  const toolbar = () => (
    <Table.Toolbar
      py={2}
      height={users.selectedRowKeys.length ? 48 : undefined}
    >
      <Flex justifyContent='space-between'>
        <UserFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
      <Table.BatchActions
        count={users.selectedRowKeys.length}
        onCancel={users.clearSelectedRowKeys}
      >
        {admin ? (
          <Table.ActionButton onClick={() => onDelete()}>
            Delete
          </Table.ActionButton>
        ) : null}
      </Table.BatchActions>
    </Table.Toolbar>
  )

  return (
    <Table
      rowKey='userId'
      emptyText='No Users'
      columns={columns}
      toolbar={toolbar}
      dataSource={users.data}
      loading={loading}
      rowSelection={rowSelection}
      pagination={{
        ...users.query.pagination,
        hideOnSinglePage: false,
        total: users.total,
      }}
      scroll={scroll}
      filters={filters.populated}
      onFilter={(key) => filterRef.current?.addFilter(key)}
      onChange={(e) => {
        users.setPagination(e.pagination)
        users.setSorter(e.sorter)
      }}
    />
  )
}

UserTable.propTypes = {
  admin: PropTypes.bool,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  users: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onRefetch: PropTypes.func,
}

export default UserTable
