import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@beachfront/ui'

import { FunnelChartWidget } from './funnel-chart-widget'
import { PieChartWidget } from './pie-chart-widget'
import { DonutChartWidget } from './donut-chart-widget'
import { BarChartWidget } from './bar-chart-widget'
import { SimpleBarChartWidget } from './simple-bar-chart-widget'
import { StackedBarChartWidget } from './stacked-bar-chart-widget'
import { MultipleBarChartWidget } from './multiple-bar-chart-widget'
import { AreaChartWidget } from './area-chart-widget'
import { LineChartWidget } from './line-chart-widget'
import { ComposedBarLineChartWidget } from './composed-bar-line-chart-widget'

const Widget = ({
  type,
  title,
  data,
  details,
  tabs,
  loading,
  cardBodyHeight,
  config,
  axisConfig,
  ...rest
}) => {
  return (
    <Box width='100%'>
      {type === 'FUNNEL' ? (
        <FunnelChartWidget
          title={title}
          data={data}
          loading={loading}
          cardBodyHeight={cardBodyHeight}
          {...rest}
        />
      ) : null}
      {type === 'BAR' ? (
        <BarChartWidget
          title={title}
          data={data}
          tabs={tabs}
          cardBodyHeight={cardBodyHeight}
          config={config}
          {...rest}
        />
      ) : null}
      {type === 'BAR_WITH_NO_TABS' ? (
        <SimpleBarChartWidget
          title={title}
          data={data?.data}
          unit={data?.unit}
          cardBodyHeight={cardBodyHeight}
          vertical={data?.vertical}
          xUnit={data.xUnit}
          xLabel={data.xLabel}
          {...rest}
        />
      ) : null}
      {type === 'AREA' ? (
        <AreaChartWidget
          title={title}
          data={data}
          cardBodyHeight={cardBodyHeight}
          toolTipLabel={config?.toolTipLabel}
          {...rest}
        />
      ) : null}
      {type === 'PIE' ? (
        <PieChartWidget
          title={title}
          data={data}
          details={details}
          cardBodyHeight={cardBodyHeight}
          config={config}
          {...rest}
        />
      ) : null}
      {type === 'PIE_OUTER' ? (
        <DonutChartWidget
          title={title}
          data={data}
          cardBodyHeight={cardBodyHeight}
          showTotals={!config?.noShowTotals}
          total={config?.total}
          totalTitle={config?.totalTitle}
          detailLabel={config?.detailLabel}
          {...rest}
        />
      ) : null}
      {type === 'STACKED_BAR' ? (
        <StackedBarChartWidget
          title={title}
          data={data}
          cardBodyHeight={cardBodyHeight}
          yUnit={config?.yUnit}
          range={config?.range}
          {...rest}
        />
      ) : null}
      {type === 'LINE' ? (
        <LineChartWidget
          title={title}
          data={data}
          cardBodyHeight={cardBodyHeight}
          axisConfig={axisConfig}
          {...rest}
        />
      ) : null}
      {type === 'MULTIPLE_BAR' ? (
        <MultipleBarChartWidget
          title={title}
          data={data}
          cardBodyHeight={cardBodyHeight}
          yUnit={config?.yUnit}
          {...rest}
        />
      ) : null}
      {type === 'BAR_LINE' ? (
        <ComposedBarLineChartWidget
          title={title}
          data={data}
          cardBodyHeight={cardBodyHeight}
          {...rest}
        />
      ) : null}
    </Box>
  )
}

Widget.propTypes = {
  type: PropTypes.string,
  title: PropTypes.node,
  data: PropTypes.object,
  details: PropTypes.array,
  tabs: PropTypes.array,
  loading: PropTypes.bool,
  cardBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  config: PropTypes.object,
  axisConfig: PropTypes.object,
}

export default Widget
