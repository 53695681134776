import React, { useRef, useState, useMemo } from 'react'
import { Grid, Flex, Box, Button, Card, Menu, Modal } from '@beachfront/ui'
import { Form, FormSpy } from '@beachfront/ui/forms'

import { api } from '../../../client-api'
import { useFetch } from '../../../hooks'
import { isNotEmptyArray, isNotEmptyObject } from '../../../utils'
import { NetworkError, CommonSpinner } from '../../../components'
import { accountTabs } from '..'

import {
  AccountGeneralForm,
  AccountDisplayForm,
  AccountVideoForm,
  AccountList,
} from '.'

const AccountModal = ({
  isOpen,
  onClose,
  onSuccess,
  inventoryInfoAcc,
  onSelectedAccountsChange,
}) => {
  const [allAccountIds, setAllAccountIds] = useState([])
  const [eligibleIds, setEligibleIds] = useState([])
  const [accountValues, setAccountValues] = useState(null)
  const [disabled, setDisabled] = useState(false)

  const onAccountListChange = (accountList, eligibleIds) => {
    const ids = accountList?.map((acc) => acc.id) ?? []
    setAllAccountIds(ids)
    setEligibleIds(eligibleIds)
    onSelectedAccountsChange(accountList)
  }

  const onSubmit = () => {
    onSuccess?.(allAccountIds, accountValues, eligibleIds)
    onClose?.()
  }

  const headerTitle = (
    <Flex justifyContent='space-between'>
      <Box my='auto'>Manage Accounts</Box>
      <Flex gap={2}>
        <Button onClick={onClose}>Cancel</Button>
        <Button type='primary' disabled={disabled} onClick={onSubmit}>
          Submit
        </Button>
      </Flex>
    </Flex>
  )

  return (
    <Modal
      centered
      width='90%'
      visible={isOpen}
      title={headerTitle}
      closable={false}
      footer={null}
      bodyStyle={{ height: 600, overflow: 'auto' }}
    >
      <AccountForm
        inventoryInfoAcc={inventoryInfoAcc}
        onFormChange={setAccountValues}
        onAccountListChange={onAccountListChange}
        onDisabledChange={setDisabled}
      />
    </Modal>
  )
}

const AccountForm = ({
  inventoryInfoAcc,
  onFormChange,
  onAccountListChange,
  onDisabledChange,
}) => {
  const [activeTabKey, setActiveTabKey] = useState(accountTabs[0].key)
  const ref = useRef(null)

  const accountFilters = useFetch({
    request: api.mediaPlan.accountFilters,
  })

  const initialValues = useMemo(() => {
    const values = {
      cpmOps: 'EQUAL',
      videoCpmOps: 'EQUAL',
      displayCpmOps: 'EQUAL',
    }

    if (isNotEmptyObject(inventoryInfoAcc)) {
      Object.assign(values, inventoryInfoAcc)
    }

    const newVideoMinCpm = values.videoMinCpm
    const newDisplayMinCpm = values.displayMinCpm
    const newVideoCpmOps = values.videoCpmOps || null
    const newDisplayCpmOps = values.displayCpmOps || null

    if (
      values.displayBrandBlocks !== null &&
      !isNaN(values.displayBrandBlocks)
    ) {
      values.displayBrandBlocks = values.displayBrandBlocks === 1 ? 'YES' : 'NO'
    }

    if (
      values.displayBundleBreakTag !== null &&
      !isNaN(values.displayBundleBreakTag)
    ) {
      values.displayBundleBreakTag =
        values.displayBundleBreakTag === 1 ? 'YES' : 'NO'
    }

    if (
      values.displayFirstPriceAuction !== null &&
      !isNaN(values.displayFirstPriceAuction)
    ) {
      values.displayFirstPriceAuction =
        values.displayFirstPriceAuction === 1 ? 'YES' : 'NO'
    }

    if (values.displayamp !== null && !isNaN(values.displayamp)) {
      values.displayamp = values.displayamp === 1 ? 'YES' : 'NO'
    }

    if (
      values.reqCompetitiveSeparation !== null &&
      !isNaN(values.reqCompetitiveSeparation)
    ) {
      values.reqCompetitiveSeparation =
        values.reqCompetitiveSeparation === 1 ? 'YES' : 'NO'
    }

    if (
      values.reqCreativeApproval !== null &&
      !isNaN(values.reqCreativeApproval)
    ) {
      values.reqCreativeApproval =
        values.reqCreativeApproval === 1 ? 'YES' : 'NO'
    }

    if (values.videoBrandBlocks !== null && !isNaN(values.videoBrandBlocks)) {
      values.videoBrandBlocks = values.videoBrandBlocks === 1 ? 'YES' : 'NO'
    }

    if (
      values.videoBundleContent !== null &&
      !isNaN(values.videoBundleContent)
    ) {
      values.videoBundleContent = values.videoBundleContent === 1 ? 'YES' : 'NO'
    }

    if (
      values.videoContentBylength !== null &&
      !isNaN(values.videoContentBylength)
    ) {
      values.videoContentBylength =
        values.videoContentBylength === 1 ? 'YES' : 'NO'
    }

    if (
      values.videoFirstPriceAuction !== null &&
      !isNaN(values.videoFirstPriceAuction)
    ) {
      values.videoFirstPriceAuction =
        values.videoFirstPriceAuction === 1 ? 'YES' : 'NO'
    }

    if (
      values.videoFullEpisodePlayer !== null &&
      !isNaN(values.videoFullEpisodePlayer)
    ) {
      values.videoFullEpisodePlayer =
        values.videoFullEpisodePlayer === 1 ? 'YES' : 'NO'
    }

    if (values.videoOtt !== null && !isNaN(values.videoOtt)) {
      values.videoOtt = values.videoOtt === 1 ? 'YES' : 'NO'
    }

    if (
      values.videoUtilizedaissaiVendor !== null &&
      !isNaN(values.videoUtilizedaissaiVendor)
    ) {
      values.videoUtilizedaissaiVendor =
        values.videoUtilizedaissaiVendor === 1 ? 'YES' : 'NO'
    }

    if (values.videoCpmObj !== null && isNotEmptyObject(values.videoCpmObj)) {
      values.videoCpmOps = values.videoCpmObj.ops || 'EQUAL'
      values.videoMinCpm = Number(values.videoCpmObj?.minCpm)
    }

    if (!isNaN(newVideoMinCpm)) {
      values.videoMinCpm = Number(newVideoMinCpm)
    }

    if (newVideoCpmOps) {
      values.videoCpmOps = newVideoCpmOps
    }

    if (
      values.displayCpmObj !== null &&
      isNotEmptyObject(values.displayCpmObj)
    ) {
      values.displayCpmOps = values.displayCpmObj.ops || 'EQUAL'
      values.displayMinCpm = Number(values.displayCpmObj?.minCpm)
    }

    if (!isNaN(newDisplayMinCpm)) {
      values.displayMinCpm = Number(newDisplayMinCpm)
    }

    if (newDisplayCpmOps) {
      values.displayCpmOps = newDisplayCpmOps
    }

    return values
  }, [inventoryInfoAcc])

  const onMenuSelect = ({ key }) => {
    ref.current
      ?.querySelector(`#${key}`)
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  const onScroll = (event) => {
    if (ref.current) {
      const generalElementHeight =
        ref.current.querySelector('#general').clientHeight
      const videoElementHeight =
        ref.current.querySelector('#videoInventory').clientHeight

      let activeTab = 'general'
      if (event.target.scrollTop > generalElementHeight + videoElementHeight) {
        activeTab = 'displayInventory'
      } else if (event.target.scrollTop > generalElementHeight - 10) {
        activeTab = 'videoInventory'
      }
      if (activeTabKey !== activeTab) {
        setActiveTabKey(activeTab)
      }
    }
  }

  if (accountFilters.loading) {
    return <CommonSpinner />
  }

  if (accountFilters.error || !accountFilters.data?.success) {
    return (
      <NetworkError
        description={
          accountFilters.error ||
          accountFilters.data?.msg ||
          'Account filter data not found'
        }
      />
    )
  }

  return (
    <Flex width={1} height='100%'>
      <Form initialValues={initialValues} onSubmit={() => null}>
        {({ values }) => (
          <Grid gap={3} columns={2} rows='1fr auto' minWidth={800}>
            <Box>
              <Card compact>
                <Menu
                  mode='horizontal'
                  selectedKeys={[activeTabKey]}
                  onSelect={onMenuSelect}
                >
                  {accountTabs.map(({ key, value }) => (
                    <Menu.Item key={key}>{value}</Menu.Item>
                  ))}
                </Menu>
              </Card>
            </Box>
            <Box rowSpan={2} overflow='auto' position='relative'>
              <Card title='Accounts / Publisher Direct' size='small'>
                <AccountList
                  values={getPostValues(values)}
                  onAccountListChange={onAccountListChange}
                  onDisabledChange={onDisabledChange}
                  getPopupContainer={() => ref.current}
                />
              </Card>
            </Box>
            <Box
              ref={ref}
              onScroll={onScroll}
              overflow='auto'
              position='relative'
            >
              <Card id='general' title='General' size='small' mb={3}>
                <AccountGeneralForm
                  accountFilters={accountFilters.data}
                  getPopupContainer={() => ref.current}
                />
              </Card>
              <Card
                id='videoInventory'
                title='Video Inventory'
                size='small'
                mb={3}
              >
                <AccountVideoForm
                  accountFilters={accountFilters.data}
                  getPopupContainer={() => ref.current}
                />
              </Card>
              <Card
                id='displayInventory'
                title='Display Inventory'
                size='small'
              >
                <AccountDisplayForm
                  accountFilters={accountFilters.data}
                  getPopupContainer={() => ref.current}
                />
              </Card>
            </Box>
            <FormSpy
              onChange={({ values }) => {
                setTimeout(() => onFormChange(values))
              }}
            />
          </Grid>
        )}
      </Form>
    </Flex>
  )
}

const getPostValues = (val) => {
  const payload = {}

  if (val['reqCreativeApproval'] === 'YES') {
    payload['reqCreativeApproval'] = 1
  } else if (val['reqCreativeApproval'] === 'NO') {
    payload['reqCreativeApproval'] = 0
  }

  if (val['reqCompetitiveSeparation'] === 'YES') {
    payload['reqCompetitiveSeparation'] = 1
  } else if (val['reqCompetitiveSeparation'] === 'NO') {
    payload['reqCompetitiveSeparation'] = 0
  }

  if (val['videoContentBylength'] === 'YES') {
    payload['videoContentBylength'] = 1
  } else if (val['videoContentBylength'] === 'NO') {
    payload['videoContentBylength'] = 0
  }

  if (val['videoBundleContent'] === 'YES') {
    payload['videoBundleContent'] = 1
  } else if (val['videoBundleContent'] === 'NO') {
    payload['videoBundleContent'] = 0
  }

  if (val['videoUtilizeVendor'] === 'YES') {
    payload['videoUtilizeVendor'] = 1
  } else if (val['videoUtilizeVendor'] === 'NO') {
    payload['videoUtilizeVendor'] = 0
  }

  if (val['videoBrandBlocks'] === 'YES') {
    payload['videoBrandBlocks'] = 1
  } else if (val['videoBrandBlocks'] === 'NO') {
    payload['videoBrandBlocks'] = 0
  }

  if (val['displayBundleContent'] === 'YES') {
    payload['displayBundleContent'] = 1
  } else if (val['displayBundleContent'] === 'NO') {
    payload['displayBundleContent'] = 0
  }

  if (val['displayBrandBlocks'] === 'YES') {
    payload['displayBrandBlocks'] = 1
  } else if (val['displayBrandBlocks'] === 'NO') {
    payload['displayBrandBlocks'] = 0
  }

  if (val['displayFirstPriceAuction'] === 'YES') {
    payload['displayFirstPriceAuction'] = 1
  } else if (val['displayFirstPriceAuction'] === 'NO') {
    payload['displayFirstPriceAuction'] = 0
  }

  if (val['displayamp'] === 'YES') {
    payload['displayamp'] = 1
  } else if (val['displayamp'] === 'NO') {
    payload['displayamp'] = 0
  }

  if (val['videoUtilizedaissaiVendor'] === 'YES') {
    payload['videoUtilizedaissaiVendor'] = 1
  } else if (val['videoUtilizedaissaiVendor'] === 'NO') {
    payload['videoUtilizedaissaiVendor'] = 0
  }

  if (val['videoFirstPriceAuction'] === 'YES') {
    payload['videoFirstPriceAuction'] = 1
  } else if (val['videoFirstPriceAuction'] === 'NO') {
    payload['videoFirstPriceAuction'] = 0
  }

  if (val['videoFullEpisodePlayer'] === 'YES') {
    payload['videoFullEpisodePlayer'] = 1
  } else if (val['videoFullEpisodePlayer'] === 'NO') {
    payload['videoFullEpisodePlayer'] = 0
  }

  if (val['videoOtt'] === 'YES') {
    payload['videoOtt'] = 1
  } else if (val['videoOtt'] === 'NO') {
    payload['videoOtt'] = 0
  }

  if (val['displayBundleBreakTag'] === 'YES') {
    payload['displayBundleBreakTag'] = 1
  } else if (val['displayBundleBreakTag'] === 'NO') {
    payload['displayBundleBreakTag'] = 0
  }

  if (
    isNotEmptyArray(val.displayAdServer) &&
    val.displayAdServer.includes('Other') &&
    val['displayOtherAdServer']
  ) {
    payload['displayOtherAdServer'] = val['displayOtherAdServer']
  }

  if (isNotEmptyArray(val['videoPlayerType'])) {
    payload['videoPlayerType'] = val['videoPlayerType']
  }

  if (isNotEmptyArray(val['videoContentType'])) {
    payload['videoContentType'] = val['videoContentType']
  }

  if (isNotEmptyArray(val['videodaissaiVender'])) {
    payload['videodaissaiVender'] = val['videodaissaiVender']
  }

  if (isNotEmptyArray(val['videoAdServer'])) {
    payload['videoAdServer'] = val['videoAdServer']
  }

  if (isNotEmptyArray(val['videoAdPosition'])) {
    payload['videoAdPosition'] = val['videoAdPosition']
  }

  if (isNotEmptyArray(val['sensitiveCatAllowed'])) {
    payload['sensitiveCatAllowed'] = val['sensitiveCatAllowed']
  }

  if (isNotEmptyArray(val['contentType'])) {
    payload['contentType'] = val['contentType']
  }

  if (isNotEmptyArray(val['displayForeignLanguage'])) {
    payload['displayForeignLanguage'] = val['displayForeignLanguage']
  }

  if (isNotEmptyArray(val['displayAdServer'])) {
    payload['displayAdServer'] = val['displayAdServer']
  }

  if (isNotEmptyArray(val['displaycontentType'])) {
    payload['displaycontentType'] = val['displaycontentType']
  }

  if (isNotEmptyArray(val['videoCreativeAdDuration'])) {
    payload['videoCreativeAdDuration'] = val['videoCreativeAdDuration']
  }

  if (isNotEmptyArray(val['videoForeignLanguage'])) {
    payload['videoForeignLanguage'] = val['videoForeignLanguage']
  }

  if (isNotEmptyArray(val['videoContentLength'])) {
    payload['videoContentLength'] = val['videoContentLength']
  }

  if (isNotEmptyArray(val['videoContentDesc'])) {
    payload['videoContentDesc'] = val['videoContentDesc']
  }

  if (val['videoMinCpm']) {
    payload['videoCpmObj'] = {
      minCpm: Number(val['videoMinCpm']).toFixed(1),
      ops: val['videoCpmOps'],
    }
  }

  if (val['displayMinCpm']) {
    payload['displayCpmObj'] = {
      minCpm: Number(val['displayMinCpm']).toFixed(1),
      ops: val['displayCpmOps'],
    }
  }

  return payload
}

export default AccountModal
