import { Filter } from '@beachfront/ui'
import React from 'react'
import PropTypes from 'prop-types'
import { NumberOutlined, TagsOutlined } from '@beachfront/ui/icons'

import {
  CompareFilterPanel,
  ContainsFilterPanel,
  formatNumberTag,
  formatStringFilterTag,
} from '../../../components/filter'

const AttachMediaModalFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='title'
        label='Title'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='count'
        label='List Size'
        defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
        defaultValue={0}
        icon={<NumberOutlined />}
        formatTag={formatNumberTag}
        renderPanel={(props) => (
          <CompareFilterPanel {...props} min={0} step={1} precision={0} />
        )}
      />
    </Filter>
  )
}

AttachMediaModalFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default AttachMediaModalFilter
