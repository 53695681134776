/**
 * Gets the list of chart colors from the theme color data.
 *
 * @param   {Object} colors
 * @returns {Array}
 */
export function getChartColors(colors) {
  return [
    // colors.primary.base,
    //colors.green[2],
    colors.gold[3],
    colors.purple[2],
    colors.orange[4],
    colors.geekblue[7],
    // colors.red[3],
    colors.geekblue[4],
    colors.blue[2],
    // colors.green[0],
    colors.gold[1],
    colors.purple[1],
    colors.magenta[2],
    colors.cyan[2],
    // colors.red[1],
    colors.magenta[0],
    colors.geekblue[2],
    colors.blue[0],
    colors.yellow[5],
  ]
}
