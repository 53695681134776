import http from '../http'

const ENDPOINT = 'mediaplan/'

export default {
  marketplaces() {
    return http.get('marketplace/custom')
  },
  list({ params, ...data }) {
    return http.post(`${ENDPOINT}all`, data, { params })
  },
  create(data) {
    return http.post(`${ENDPOINT}create`, data)
  },
  duplicate(data) {
    return http.post(`${ENDPOINT}duplicate`, data)
  },
  delete(id) {
    return http.get(`${ENDPOINT}delete/${id}`)
  },
  getById({ id, params }) {
    return http.get(`${ENDPOINT}get/${id}`, { params })
  },
  attachDealsById({ id, params }) {
    return http.get(`${ENDPOINT}deals/${id}`, { params })
  },
  attachMediaList({ params, ...data }) {
    return http.post(`${ENDPOINT}list/${params.type}`, data)
  },
  getTypeList({ params }) {
    return http.get(`${ENDPOINT}segment/datatype`, { params })
  },
  segmentList({ params, ...data }) {
    return http.post(`${ENDPOINT}list/audience`, data, { params })
  },
  segmentCost(data) {
    return http.post(`${ENDPOINT}segment/cost`, data)
  },
  availsDataList(data) {
    return http.post(`${ENDPOINT}audience/avails`, data)
  },
  update(id, data) {
    return http.post(`${ENDPOINT}update/${id}`, data)
  },
  segmentUpdate(id, data) {
    return http.post(`${ENDPOINT}add/segment/${id}`, data)
  },
  getDayChart(data, params, signal) {
    return http.post('chart/generate/area', data, { params, signal })
  },
  getCpmChart(data, params, signal) {
    return http.post('chart/generate/cpm', data, { params, signal })
  },
  getWidgets(params, signal) {
    return http.get('chart/realtime', { params, signal })
  },
  pmpGetWidgets(params, signal) {
    return http.get('chart/pmp/realtime', { params, signal })
  },
  // getTableDetail({ params, data, type }) {
  //   return http.post(`chart/detail/${type}`, data, { params }) // TODO remove from backend
  // },
  pmpChartDownload(data, params, responseType) {
    return http.post('chart/pmp/download', data, { params, responseType })
  },
  exportExcelReport(data, params) {
    return http.post('chart/pmp/excel/mail', data, { params })
  },
  accountFilters() {
    return http.get(`${ENDPOINT}account/inventory/filter`)
  },
  accountList(data) {
    return http.post(`${ENDPOINT}inventory/info/account`, data)
  },
  publisherAccountList({ data }) {
    return http.post(`${ENDPOINT}inventory/info`, data)
  },
}
