import { isNotEmptyArray } from '../../../utils'
const selectedProviderListData = (providers) =>
  providers
    .filter((providerItem) => providerItem.key !== 'ALL' && !providerItem.owned)
    .map((providerItem) => providerItem.key)

export const sanitizePostData = (applied, providers, activeTab) => {
  if (applied.includes('ALL')) {
    return providers
      .filter((pv) =>
        activeTab === 'shared'
          ? !pv.owned && pv.key !== 'ALL'
          : pv.key !== 'ALL'
      )
      .map((pv) => pv.key)
  }

  return applied
}

export const setPayloadData = (activeTab, appliedProvider, providers) => {
  const localProviders = isNotEmptyArray(appliedProvider)
    ? appliedProvider
    : selectedProviderListData

  let filtered = localProviders

  if (localProviders.includes('ALL')) {
    filtered = providers
      .filter((prov) => {
        if (activeTab === 'owned') {
          return prov.owned && !prov.key !== 'ALL'
        }

        if (activeTab === 'shared') {
          return !prov.owned && prov.key !== 'ALL'
        }

        return false
      })
      .map((pv) => pv.key)
  }

  return filtered
}

export const setFilterData = (activeTab, appliedTypes, types) => {
  let isPresent = appliedTypes.some(
    (items) => items === 'BID_REQUEST' || items === 'IRIS_ID'
  )
  if (isNotEmptyArray(appliedTypes)) {
    if (
      activeTab === 'uploaded' &&
      appliedTypes.includes('BID_REQUEST') &&
      appliedTypes.includes('IRIS_ID') &&
      appliedTypes.length < 3
    ) {
      return types
        .filter(
          (item) =>
            item.key !== 'ALL' &&
            item.key !== 'BID_REQUEST' &&
            item.key !== 'IRIS_ID'
        )
        .map((items) => items.key)
    } else if (
      activeTab === 'uploaded' &&
      (appliedTypes.includes('BID_REQUEST') ||
        appliedTypes.includes('IRIS_ID')) &&
      appliedTypes.length < 2
    ) {
      return types
        .filter(
          (item) =>
            item.key !== 'ALL' &&
            item.key !== 'BID_REQUEST' &&
            item.key !== 'IRIS_ID'
        )
        .map((items) => items.key)
    }
    return activeTab === 'uploaded' && isPresent
      ? appliedTypes.filter(
          (items) => items !== 'BID_REQUEST' && items !== 'IRIS_ID'
        )
      : appliedTypes
  } else {
    return types.filter((item) => item.key !== 'ALL').map((items) => items.key)
  }
}
