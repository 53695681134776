import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from '@beachfront/ui/forms'
import { Accordion, Text } from '@beachfront/ui'

import { ManageAudienceSection } from '..'

import AudiencePreviewTags from './audience-preview-tags'

const AudiencePanel = ({
  expandKeys,
  disabled,
  values,
  id,
  isPmp,
  isDsp,
  adminCreate,
}) => {
  const form = useForm()

  const isAudienceValid = () => {
    const { errors, submitFailed } = form.getState()
    if (errors.audienceBooleanLogic?.value) {
      return !submitFailed
    }
    return true
  }

  const extra = (
    <AudiencePreviewTags
      visible={!expandKeys.includes('audience')}
      values={values}
    />
  )

  return (
    <Accordion.Panel
      value='audience'
      header={
        <Text color={isAudienceValid() ? '' : 'negative.base'}>Audience</Text>
      }
      extra={extra}
    >
      <ManageAudienceSection
        id={id}
        values={values}
        formDisable={disabled}
        pmpAvails={isPmp}
        dspDeal={isDsp}
        adminCreate={adminCreate}
      />
    </Accordion.Panel>
  )
}

AudiencePanel.defaultProps = {
  expandKeys: [],
  values: {},
  params: {},
}

AudiencePanel.propTypes = {
  expandKeys: PropTypes.array,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  id: PropTypes.string,
  isPmp: PropTypes.bool,
  isDsp: PropTypes.bool,
  adminCreate: PropTypes.bool,
}

export default AudiencePanel
