export const validatePasswordForm = (values, hasPassword) => {
  const errors = {}

  if (hasPassword && !values.oldPassword) {
    errors.oldPassword = 'Required'
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required'
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required'
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'New password and confirm password should match'
  }

  if (
    hasPassword &&
    values.newPassword &&
    values.newPassword === values.oldPassword
  ) {
    errors.newPassword = "New password can't be the same as old password"
  }

  if (values.newPassword && values.newPassword.length < 6) {
    errors.newPassword = 'Password should have a minimum of 6 characters'
  }

  if (values.confirmPassword && values.confirmPassword.length < 6) {
    errors.confirmPassword = 'Password should have a minimum of 6 characters'
  }

  if (
    values.newPassword &&
    (values.newPassword.charAt(0) === ' ' ||
      values.newPassword.charAt(values.newPassword.length - 1) === ' ')
  ) {
    errors.newPassword =
      'Spaces are not allowed at the beginning and end of the password'
  }

  if (
    values.confirmPassword &&
    (values.confirmPassword.charAt(0) === ' ' ||
      values.confirmPassword.charAt(values.confirmPassword.length - 1) === ' ')
  ) {
    errors.confirmPassword =
      'Spaces are not allowed at the beginning and end of the password'
  }
  return errors
}
