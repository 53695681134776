import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import { Flex, Card, Button } from '@beachfront/ui'
import { DownloadOutlined } from '@beachfront/ui/icons'
import { useTableQuery } from '@beachfront/ui/hooks'

import { http } from '../../client-api'
import { formatCount, formatCurrency, formatPercent } from '../../utils'
import { useInfiniteTable, useColumnConfig } from '../../hooks'
import { InfiniteTable, ColumnConfig, DynamicBadge } from '..'

export const CannedChartWidget = ({
  title,
  apiUrl,
  params,
  loading,
  redirect,
}) => {
  const table = useInfiniteTable({
    queryKey: ['canned-chart', apiUrl],
    queryFn: (data) => http.post(apiUrl, data, { params }),
    params,
    staleTime: 60000,
    enabled: !!apiUrl,
  })

  const tableQuery = useTableQuery({
    data: table.dataSource,
  })

  const scroll = { x: '100%', y: 354 }

  const { csvCols, csvRows } = useMemo(
    () => generateCsvData(table.data),
    [table.data]
  )

  const columns = useMemo(
    () => getTableColumns(table.data, redirect),
    [table.data, redirect]
  )

  const [columnConfig, setColumnConfig] = useColumnConfig(columns)

  const extra = (
    <Flex gap={2}>
      <CSVLink
        data={csvRows}
        headers={csvCols}
        uFEFF={false}
        filename='dashboard-deals.csv'
      >
        <Button icon={<DownloadOutlined />} disabled={csvRows.length === 0}>
          Export
        </Button>
      </CSVLink>
      <ColumnConfig
        columns={columns}
        value={columnConfig}
        onChange={setColumnConfig}
      />
    </Flex>
  )

  return (
    <Card title={title} extra={extra} loading={loading}>
      <InfiniteTable
        rowKey='id'
        emptyText={table.data?.pages[0]?.msg ?? 'No Data Found'}
        columns={columns}
        scroll={scroll}
        columnConfig={columnConfig}
        onColumnConfigChange={setColumnConfig}
        {...table.props}
        dataSource={tableQuery.data}
        onChange={(e) => {
          tableQuery.setSorter(e.sorter)
        }}
      />
    </Card>
  )
}

const getTableColumns = (data, redirect) => {
  if (!data?.pages[0]?.columns) {
    return []
  }

  const renderLink = (val, rec) => (
    <Link to={redirect(rec)} target='_blank'>
      {val || 'NA'}
    </Link>
  )

  const renderBadge = (val) => <DynamicBadge data={val} inverted />

  const getRender = (col, i) => {
    if (i === 0 && redirect) {
      return renderLink
    }
    if (col.type === 'STATUS') {
      return renderBadge
    }
    if (col.prefix === '$') {
      return (val) => formatCurrency(val)
    }
    if (col.suffix === '%') {
      return (val) => formatPercent(val)
    }
    if (col.type === 'NUMBER') {
      return (val) => formatCount(val)
    }
    return (val) => val || 'NA'
  }

  const getTitle = (col) => {
    if (col.key === 'spend') {
      return {
        title: 'Revenue',
        width: 150,
      }
    }

    if (col.key === 'revenue') {
      return {
        title: 'Partner Revenue',
        width: 180,
      }
    }

    return {
      title: col.name,
      width: col.width ?? 115,
    }
  }

  return data.pages[0].columns
    .filter((col) => col)
    .map((col, i) => ({
      dataIndex: col.key,
      ...getTitle(col),
      sorter: col.type !== 'STATUS',
      configurable: i > 0,
      fixed: i === 0 ? 'left' : false,
      render: getRender(col, i),
    }))
}

const generateCsvData = (data) => {
  const columns = data?.pages[0]?.columns ?? []
  const rows = data?.pages[0]?.rows ?? []

  const csvCols = columns.map((col) => {
    return { label: col.name, key: col.key }
  })

  const csvRows = rows.map((row) => {
    const obj = {}
    columns.forEach((col) => {
      if (col.type === 'STATUS') {
        obj[col.key] = row.data?.[col.key]?.hint
      } else {
        obj[col.key] = row.data?.[col.key] ?? 'NA'
      }
    })
    return obj
  })

  return { csvCols, csvRows }
}

CannedChartWidget.propTypes = {
  title: PropTypes.node,
  apiUrl: PropTypes.string,
  params: PropTypes.object,
  loading: PropTypes.bool,
  redirect: PropTypes.func,
}
