import React from 'react'
import {
  ToolFilled,
  BulbFilled,
  LockFilled,
  FileTextFilled,
} from '@beachfront/ui/icons'

export const helpConfig = [
  {
    id: 'jirasupport',
    heading: 'Support Request',
    color: 'green',
    subColor: 3,
    icon: <ToolFilled />,
  },
  {
    id: 'docs',
    heading: 'Documentation',
    color: 'orange',
    subColor: 3,
    icon: <BulbFilled />,
  },
  {
    id: 'policy',
    heading: 'Privacy Policy',
    color: 'geekblue',
    subColor: 3,
    icon: <LockFilled />,
  },
  {
    id: 'tos',
    heading: 'Terms of Service',
    color: 'cyan',
    subColor: 3,
    icon: <FileTextFilled />,
  },
]
