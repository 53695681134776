import http from '../http'

const ENDPOINT = 'deal/'

export default {
  list({ params, ...data }) {
    return http.post(`${ENDPOINT}all`, data, { params })
  },
  getMediaPlans() {
    return http.get('deal/search/media')
  },
  download({ data, params, responseType }) {
    return http.post(`${ENDPOINT}download/csv`, data, { params, responseType })
  },
  getPublisherDsp(params) {
    return http.get(`${ENDPOINT}dsp/seat`, { params })
  },
  create(data, params) {
    return http.post(`${ENDPOINT}create`, data, { params })
  },
  getDealById({ id, params }) {
    return http.get(`${ENDPOINT}get/${id}`, { params })
  },
  getComments({ id }) {
    return http.get(`${ENDPOINT}comments/${id}`)
  },
  postComment({ id, data }) {
    return http.post(`${ENDPOINT}comments/add/${id}`, data)
  },
  update(id, data) {
    return http.post(`${ENDPOINT}update/${id}`, data)
  },
  delete(id) {
    return http.get(`${ENDPOINT}delete/${id}`)
  },
  budgetChart({ data }) {
    return http.post(`${ENDPOINT}chart`, data)
  },
  appInfoBulkUpload(data, { key, ...params }) {
    return http.post(`${ENDPOINT}upload/appinfo/${key}`, data, { params })
  },
  updateInfo({ id, data }) {
    if (Object.hasOwn(data, 'create')) {
      data = data.create
    }
    return http.post(`${ENDPOINT}update/appinfo/${id}`, data)
  },
  editInfo({ id, data }) {
    if (Object.hasOwn(data, 'update')) {
      data = data.update
    }
    return http.post(`${ENDPOINT}edit/appinfo/${id}`, data)
  },
  deleteInfo({ id, bundle }) {
    return http.get(`${ENDPOINT}delete/appinfo/${id}/${bundle}`)
  },
  appInfoList({ id, ...params }) {
    return http.get(`${ENDPOINT}get/all/appinfo/${id}`, { params })
  },
  appInfoDetail({ id, bundle }) {
    return http.get(`${ENDPOINT}get/appinfo/${id}/${bundle}`)
  },
  generateId() {
    return http.get(`${ENDPOINT}generate/id`)
  },
  getCostByMediaPlan({ id }) {
    return http.get(`mediaplan/cost/${id}`)
  },
  publish({ id, params }) {
    return http.get(`${ENDPOINT}publish/${id}`, { params })
  },
  updateStatus({ id, params }) {
    return http.get(`${ENDPOINT}updatestatus/${id}`, { params })
  },
  bulk: {
    unpublish({ data }) {
      return http.post(`${ENDPOINT}bulk/unpublish`, { data })
    },
  },
  library: {
    admin: {
      create(data) {
        return http.post(`${ENDPOINT}library/create`, data)
      },
      list({ params, ...data }) {
        return http.post(`${ENDPOINT}library/all`, data, { params })
      },
      getDealById({ id, params }) {
        return http.get(`${ENDPOINT}library/get/${id}`, { params })
      },
      update(id, data) {
        return http.post(`${ENDPOINT}library/update/${id}`, data)
      },
    },
    list({ params, ...data }) {
      return http.post(`${ENDPOINT}template/all`, data, { params })
    },
    featuredList({ params, data }) {
      return http.post(`${ENDPOINT}template/featured/all`, data, { params })
    },
  },
}
