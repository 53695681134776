import React, { useState, useMemo } from 'react'
import { Flex, Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { useFilters, useDateUrlSync } from '../../hooks'
import { DATE_RANGES } from '../../enums'
import { localFilterKey } from '../../components/filter'
import { PageHeader } from '../../components'

import {
  ReachFilter,
  ChartDetail,
  BucketBarChart,
  PlatformTable,
  DemographicTable,
} from './@components'

const Reach = () => {
  const [cacheKey, setCacheKey] = useState(() => Math.random())
  const [filters, setFilters] = useFilters(localFilterKey.FFID_REACH, {
    initialState: [
      { dataIndex: 'date', operator: 'eq', value: DATE_RANGES['Last 7 Days'] },
    ],
    schema: { date: 'date' },
  })

  useDateUrlSync({
    filters: filters.populated,
    onChange: setFilters,
  })

  const requestPayload = useMemo(
    () => getRequestPayload(filters.populated),
    [filters.populated]
  )

  const headerActions = (
    <Button
      onClick={() => setCacheKey(Math.random())}
      icon={<ReloadOutlined />}
    />
  )

  return (
    <>
      <PageHeader title='FTrackHHID Reach' actions={headerActions}>
        <Box mt={3}>
          <ReachFilter filters={filters.all} setFilters={setFilters} />
        </Box>
      </PageHeader>
      <ReachCharts key={cacheKey} requestPayload={requestPayload} />
    </>
  )
}

const ReachCharts = ({ requestPayload }) => {
  return (
    <Flex mt={2} gap={3} flexDirection='column'>
      <ChartDetail requestPayload={requestPayload} />
      <BucketBarChart requestPayload={requestPayload} />
      <PlatformTable requestPayload={requestPayload} />
      <DemographicTable requestPayload={requestPayload} />
    </Flex>
  )
}

const getRequestPayload = (filters) => {
  const dateRange = filters.find((f) => f.dataIndex === 'date')?.value

  return {
    params: {
      range: 'custom',
      startDate: dateRange?.[0].format('YYYY-MM-DD'),
      endDate: dateRange?.[1].format('YYYY-MM-DD'),
    },
  }
}

export default Reach
