import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Backdrop,
  Box,
  Button,
  Input,
  BrandCard,
  Divider,
  Flex,
} from '@beachfront/ui'
import { UserOutlined, LockOutlined } from '@beachfront/ui/icons'
import { Form, FORM_ERROR, SubmitError, Field } from '@beachfront/ui/forms'
import { useOnlineStatus } from '@beachfront/ui/hooks'

import BeachfrontHorizontalWhiteLogo from '../../assets/bf-logo-horizontal-white.png'
import { resolveProp, getErrorMessage, parseFields } from '../../utils'
import { authService } from '../../context'
import { USER_KEY } from '../../constants'
import { ACCOUNT_TYPE } from '../../enums'

import { loginValidate } from './@utils'

const Login = () => {
  const [success, setSuccess] = useState(false)
  const onlineStatus = useOnlineStatus()
  const location = useLocation()

  const initialValues = {
    email: resolveProp(location, 'state.user.username'),
    password: resolveProp(location, 'state.user.password'),
    remember: false,
  }

  const onSubmit = (values) => {
    setSuccess(true)
    if (onlineStatus) {
      const payloadData = {
        email: values.email,
        password: values.password,
        remember: values.remember,
      }

      return authService.login(payloadData).then(
        (session) => {
          if (session.data?.success) {
            parseFields(session.data, { accountType: ACCOUNT_TYPE })
            localStorage.setItem(USER_KEY, JSON.stringify(session.data))
            window.location.replace('/')
          } else {
            setSuccess(false)
            return {
              [FORM_ERROR]:
                session?.response?.data?.message ?? session?.data?.errorDetails,
            }
          }
        },
        (error) => {
          setSuccess(false)
          return { [FORM_ERROR]: getErrorMessage(error) }
        }
      )
    } else {
      setSuccess(false)
      return { [FORM_ERROR]: 'Check your internet connection and try again' }
    }
  }

  return (
    <Backdrop preset='prism'>
      <Box width={450}>
        <Form
          initialValues={initialValues}
          validate={loginValidate}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <BrandCard
                logo={BeachfrontHorizontalWhiteLogo}
                fontSize={3}
                title='Sign In'
                mode='dark'
              >
                <SubmitError mb={3} />
                <Box mb={3} fontSize={2}>
                  Access your dashboard by logging in below.
                </Box>
                <Field name='email'>
                  <Input
                    size='large'
                    placeholder='Email'
                    prefix={<UserOutlined />}
                  />
                </Field>
                <Field name='password'>
                  <Input.Password
                    size='large'
                    placeholder='Password'
                    prefix={<LockOutlined />}
                  />
                </Field>
                <Button
                  type='primary'
                  htmlType='submit'
                  size='large'
                  loading={submitting}
                  disabled={success}
                  block
                >
                  Login
                </Button>
                <Divider />
                <Flex alignItems='center' justifyContent='flex-end'>
                  <Link to='/verify-email'>Forgot password?</Link>
                </Flex>
              </BrandCard>
            </form>
          )}
        </Form>
      </Box>
      <Box pt={3} color='white'>
        &copy; {new Date().getFullYear()} Beachfront Media LLC
      </Box>
    </Backdrop>
  )
}

export default Login
