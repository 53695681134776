import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Tag } from '@beachfront/ui'

import { plural } from '../../../../utils'

const GeoPreviewTags = ({ visible, values }) => {
  if (!visible) {
    return null
  }

  const tags = []
  const countryCount = values.countryObj?.length ?? 0
  const stateCount = values.stateObj?.length ?? 0
  const zipCount = (values.zip?.length || values.zipMedia?.ids?.length) ?? 0
  const metroCount = (values.dma?.length || values.dmaMedia?.ids?.length) ?? 0

  if (countryCount) {
    tags.push(
      countryCount === 1 ? (
        <Tag
          key='country'
          color='processing'
          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {values.countryObj[0].name}
        </Tag>
      ) : (
        <Tag key='country' color='processing'>
          {countryCount} Countries
        </Tag>
      )
    )

    if (stateCount) {
      tags.push(
        stateCount === 1 ? (
          <Tag
            key='state'
            color='processing'
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {values.stateObj[0].name}
          </Tag>
        ) : (
          <Tag key='state' color='processing'>
            {stateCount} States
          </Tag>
        )
      )
    }

    if (zipCount) {
      tags.push(
        <Tag key='zip' color='processing' flex='none'>
          {plural`${zipCount} Zip Code[|s]`}
        </Tag>
      )
    }

    if (metroCount) {
      tags.push(
        <Tag key='metro' color='processing' flex='none'>
          {plural`${metroCount} Metro Code[|s]`}
        </Tag>
      )
    }
  }

  if (tags.length) {
    const max = 1
    return (
      <Flex gap={1} maxWidth={200} justifyContent='flex-end'>
        {tags.slice(0, max)}
        {tags.length > max ? (
          <Tag color='processing' flex='none'>
            +{tags.length - max}
          </Tag>
        ) : null}
      </Flex>
    )
  }

  return null
}

GeoPreviewTags.propTypes = {
  visible: PropTypes.bool,
  values: PropTypes.object,
}

export default GeoPreviewTags
