import React from 'react'
import { Link } from 'react-router-dom'

import { SEGMENT_TYPE } from '../../../../enums'
import { formatCount, formatDate } from '../../../../utils'

export const segmentColumns = [
  {
    dataIndex: 'name',
    title: 'Name',
    fixed: 'left',
    sorter: true,
    width: 250,
    render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
  },
  {
    dataIndex: 'sourceSegmentId',
    title: 'Remote ID',
    sorter: true,
    width: 190,
    render: (val) => val || 'NA',
  },
  {
    dataIndex: 'dataType',
    title: 'Segment Type',
    sorter: true,
    filter: true,
    width: 140,
    render: (key) => SEGMENT_TYPE.fromKey(key)?.name ?? key,
  },
  {
    dataIndex: 'dataCount',
    title: 'Users',
    sorter: true,
    width: 140,
    render: (val) => (val === null ? 'NA' : formatCount(val)),
  },
  {
    dataIndex: 'price',
    title: 'Price (CPM)',
    sorter: true,
    filter: true,
    width: 140,
  },
  {
    dataIndex: 'updatedDate',
    title: 'Last Refreshed',
    sorter: true,
    width: 180,
    render: (val) => formatDate(val),
  },
]
