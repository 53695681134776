import React from 'react'

import { PERMISSION, USER_ROLE } from '../../../enums'
import { Dashboard } from '../../../pages/dashboard'
import { AuctionLog } from '../../../pages/auction-log'
import {
  AvailsExplorer,
  CreateAvails,
  EditAvails,
} from '../../../pages/avails-explorer'
import {
  CreateDeal,
  Deal,
  DealDetail,
  DealLibrary,
  DealLibraryDetail,
} from '../../../pages/deal'
import {
  BundleMediaList,
  DomainMediaList,
  MetroCodeMediaList,
  ZipCodeMediaList,
  CreateMediaList,
  UpdateMediaList,
} from '../../../pages/media-list'
import {
  MediaPlans,
  CreateMediaPlan,
  MediaPlanDetail,
} from '../../../pages/media-plan'
import {
  UploadedSegments,
  SharedSegments,
  SharedSegmentDetail,
  CreateSegment,
  UpdateSegment,
} from '../../../pages/segment'
import { Transparency } from '../../../pages/transparency'
import { Users, CreateUser, UpdateUser } from '../../../pages/users'
import {
  Accounts,
  CreateAccount,
  UpdateAccount,
  Dsp,
  DspCreate,
  DspDetail,
  Meta,
  CreateMeta,
  DetailMeta,
  RestrictedPublishers,
  SupplyRequest,
  SystemMessages,
} from '../../../pages/admin'
import {
  FeaturedDeals,
  AdminDealLibrary,
  LibraryCreate,
  LibraryDetail,
} from '../../../pages/dsp-deal'
import { ApiKey } from '../../../pages/settings'
import {
  OwnedReports,
  SharedReports,
  CreateReport,
  UpdateReport,
  ReportDetail,
} from '../../../pages/report'
import { Reach } from '../../../pages/reach'
import { HelpCenter, Documentation, Support } from '../../../pages/support'

export const routeConfig = [
  {
    path: '/transparency',
    element: <Transparency />,
    exact: true,
    permissionKey: PERMISSION.TRANSPARENCY.key,
    roles: [],
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    exact: true,
    permissionKey: PERMISSION.DASHBOARD.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/admin/account',
    element: <Accounts />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/account/create',
    element: <CreateAccount />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/account/:accountId/:type?',
    element: <UpdateAccount />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/user',
    element: <Users key='admin' admin />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/user/create',
    element: <CreateUser key='admin' admin />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/user/:userId/:type?',
    element: <UpdateUser key='admin' admin />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/meta',
    element: <Meta />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/meta/create',
    element: <CreateMeta />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/meta/:id',
    element: <DetailMeta />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/supplyrequest',
    element: <SupplyRequest />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/dsp',
    element: <Dsp />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/dsp/create',
    element: <DspCreate />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/dsp/:id',
    element: <DspDetail />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key],
  },
  {
    path: '/admin/system-messages',
    element: <SystemMessages />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key, USER_ROLE.ADMIN.key],
  },
  {
    path: '/admin/restricted-publishers',
    element: <RestrictedPublishers />,
    exact: true,
    permissionKey: PERMISSION.ADMIN.key,
    roles: [USER_ROLE.SUPER.key, USER_ROLE.ADMIN.key],
  },
  {
    path: '/admin/dashboard',
    element: <Dashboard key='admin' admin />,
    exact: true,
    permissionKey: PERMISSION.DEALS_ADMIN.key,
  },
  {
    path: '/admin/deals',
    element: <Deal key='admin' admin />,
    exact: true,
    permissionKey: PERMISSION.DEALS_ADMIN.key,
  },
  {
    path: '/admin/deals/:id/:type?',
    element: <DealDetail key='admin' admin />,
    exact: true,
    permissionKey: PERMISSION.DEALS_ADMIN.key,
  },
  {
    path: '/admin/mediaplan',
    element: <MediaPlans key='admin' admin />,
    exact: true,
    permissionKey: PERMISSION.DEALS_ADMIN.key,
  },
  {
    path: '/admin/mediaplan/:id/:type?',
    element: <MediaPlanDetail key='admin' admin />,
    exact: true,
    permissionKey: PERMISSION.DEALS_ADMIN.key,
  },
  {
    path: '/admin/avails-explorer',
    element: <AvailsExplorer />,
    exact: true,
    permissionKey: PERMISSION.DEALS_ADMIN.key,
  },
  {
    path: '/admin/avails-explorer/create',
    element: <CreateAvails />,
    exact: true,
    permissionKey: PERMISSION.DEALS_ADMIN.key,
  },
  {
    path: '/admin/avails-explorer/:id',
    element: <EditAvails />,
    exact: true,
    permissionKey: PERMISSION.DEALS_ADMIN.key,
  },
  {
    path: '/reach',
    element: <Reach />,
    exact: true,
    permissionKey: PERMISSION.REACH.key,
    roles: [],
  },
  {
    path: '/auctionlog',
    element: <AuctionLog />,
    exact: true,
    permissionKey: PERMISSION.AUCTION_LOG.key,
    roles: [],
  },
  {
    path: '/users',
    element: <Users />,
    exact: true,
    permissionKey: PERMISSION.USERS.key,
    roles: [USER_ROLE.ADMIN.key],
  },
  {
    path: '/users/create',
    element: <CreateUser />,
    exact: true,
    permissionKey: PERMISSION.USERS.key,
    roles: [USER_ROLE.ADMIN.key],
  },
  {
    path: '/users/:userId',
    element: <UpdateUser />,
    exact: true,
    permissionKey: PERMISSION.USERS.key,
    roles: [USER_ROLE.ADMIN.key],
  },
  {
    path: '/deal/library',
    element: <AdminDealLibrary />,
    exact: true,
    permissionKey: PERMISSION.DEAL.key,
    roles: [],
  },
  {
    path: '/deal/library/create/:type?',
    element: <LibraryCreate />,
    exact: true,
    permissionKey: PERMISSION.DEAL.key,
    roles: [],
  },
  {
    path: '/deal/library/:id/:type?',
    element: <LibraryDetail />,
    exact: true,
    permissionKey: PERMISSION.DEAL.key,
    roles: [],
  },
  {
    path: '/deal/featured',
    element: <FeaturedDeals />,
    exact: true,
    permissionKey: PERMISSION.DEAL.key,
    roles: [],
  },
  {
    path: '/deal/featured/create/:type?',
    element: <LibraryCreate />,
    exact: true,
    permissionKey: PERMISSION.DEAL.key,
    roles: [],
  },
  {
    path: '/deal/featured/:id/:type?',
    element: <LibraryDetail />,
    exact: true,
    permissionKey: PERMISSION.DEAL.key,
    roles: [],
  },
  {
    path: '/deals/self',
    element: <Deal />,
    exact: true,
    permissionKey: PERMISSION.DEALS.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/deals/self/create/:type?',
    element: <CreateDeal />,
    exact: true,
    permissionKey: PERMISSION.DEALS.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/deals/self/template/:id/:type?',
    element: <CreateDeal />,
    exact: true,
    permissionKey: PERMISSION.DEALS.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/deals/self/:id/:type?',
    element: <DealDetail />,
    exact: true,
    permissionKey: PERMISSION.DEALS.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/deals/library',
    element: <DealLibrary />,
    exact: true,
    permissionKey: PERMISSION.DEALS.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/deals/library/:id',
    element: <DealLibraryDetail />,
    exact: true,
    permissionKey: PERMISSION.DEALS.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/mediaplan',
    element: <MediaPlans />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_PLAN.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/mediaplan/create/:type?',
    element: <CreateMediaPlan />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_PLAN.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/mediaplan/:id/:type?',
    element: <MediaPlanDetail />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_PLAN.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/segment/upload',
    element: <UploadedSegments />,
    exact: true,
    permissionKey: PERMISSION.AUDIENCE_SEGMENT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/segment/upload/create',
    element: <CreateSegment />,
    exact: true,
    permissionKey: PERMISSION.AUDIENCE_SEGMENT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/segment/upload/:id/:tabKey?',
    element: <UpdateSegment />,
    exact: true,
    permissionKey: PERMISSION.AUDIENCE_SEGMENT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/segment/upload/clearvu/:id/:tabKey?',
    element: <SharedSegmentDetail isUpload />,
    exact: true,
    permissionKey: PERMISSION.AUDIENCE_SEGMENT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/segment/shared/:providerType?',
    element: <SharedSegments />,
    exact: true,
    permissionKey: PERMISSION.AUDIENCE_SEGMENT_SHARED.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/segment/shared/:providerType/:id/:tabKey?',
    element: <SharedSegmentDetail />,
    exact: true,
    permissionKey: PERMISSION.AUDIENCE_SEGMENT_SHARED.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/medialist/bundle',
    element: <BundleMediaList />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_LIST.key,
    roles: [],
  },
  {
    path: '/medialist/domain',
    element: <DomainMediaList />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_LIST.key,
    roles: [],
  },
  {
    path: '/medialist/zipcode',
    element: <ZipCodeMediaList />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_LIST.key,
    roles: [],
  },
  {
    path: '/medialist/metrocode',
    element: <MetroCodeMediaList />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_LIST.key,
    roles: [],
  },
  {
    path: '/medialist/:mediaType/create',
    element: <CreateMediaList />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_LIST.key,
    roles: [],
  },
  {
    path: '/medialist/:mediaType/upload',
    element: <CreateMediaList isUpload />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_LIST.key,
    roles: [],
  },
  {
    path: '/medialist/:mediaType/:id/:tabKey?',
    element: <UpdateMediaList />,
    exact: true,
    permissionKey: PERMISSION.MEDIA_LIST.key,
    roles: [],
  },
  {
    path: '/reports/owned',
    element: <OwnedReports />,
    exact: true,
    permissionKey: PERMISSION.REPORT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/reports/shared',
    element: <SharedReports />,
    exact: true,
    permissionKey: PERMISSION.REPORT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/reports/owned/create',
    element: <CreateReport />,
    exact: true,
    permissionKey: PERMISSION.REPORT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/reports/owned/:id/update',
    element: <UpdateReport />,
    exact: true,
    permissionKey: PERMISSION.REPORT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/reports/shared/:id/update',
    element: <UpdateReport shared />,
    exact: true,
    permissionKey: PERMISSION.REPORT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/reports/owned/:id',
    element: <ReportDetail />,
    exact: true,
    permissionKey: PERMISSION.REPORT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/reports/shared/:id',
    element: <ReportDetail shared />,
    exact: true,
    permissionKey: PERMISSION.REPORT.key,
    roles: [USER_ROLE.AGENCY.key],
  },
  {
    path: '/setting/apikey',
    element: <ApiKey />,
    exact: true,
    permissionKey: PERMISSION.API_KEY.key,
    roles: [],
  },
  {
    path: '/helpcenter',
    element: <HelpCenter />,
    exact: true,
    permissionKey: PERMISSION.SUPPORT.key,
    roles: [],
  },
  {
    path: '/helpcenter/jirasupport',
    element: <Support />,
    exact: true,
    permissionKey: PERMISSION.SUPPORT.key,
    roles: [],
  },
  {
    path: '/helpcenter/docs',
    element: <Documentation />,
    exact: true,
    permissionKey: PERMISSION.SUPPORT.key,
    roles: [],
  },
]
