import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@beachfront/ui'

import { PLATFORM, MEDIA_TYPE } from '../../../enums'
import { formatCount, formatCurrency } from '../../../utils'

export const dealLibraryColumns = [
  {
    dataIndex: 'title',
    title: 'Name',
    fixed: 'left',
    sorter: true,
    width: 250,
    render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
  },
  {
    dataIndex: 'platforms',
    title: 'Platforms',
    width: 180,
    render: (keys) => (
      <>{keys.map((key) => PLATFORM.fromKey(key)?.name ?? key).join(', ')}</>
    ),
  },
  {
    dataIndex: 'mediaType',
    title: 'Media Type',
    sorter: true,
    filter: true,
    width: 140,
    render: (key) => MEDIA_TYPE.fromKey(key.toLowerCase())?.name,
  },
  {
    dataIndex: 'dailyAvails',
    title: 'Daily Avails',
    sorter: true,
    filter: true,
    width: 120,
    render: (val) => (val === null ? 'NA' : formatCount(val)),
  },
  // {
  //   dataIndex: 'cpm',
  //   title: 'CPM',
  //   sorter: true,
  //   filter: true,
  //   width: 120,
  //   render: (val) => (val === null ? 'NA' : formatCurrency(val)),
  // },
  {
    key: 'action',
    align: 'center',
    fixed: 'right',
    width: 110,
    render: (val, rec) => (
      <Link to={`/deals/self/template/${rec.id}`}>
        <Button size='small'>Use Template</Button>
      </Link>
    ),
  },
]
