import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Flex } from '@beachfront/ui'
import {
  ResponsiveContainer,
  BarChart as BaseBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'

import { CustomTooltip } from '../widget/custom-tooltip'
import { formatTick } from '../widget/widget-util'

import AxisLabel from './axis-label'

const BarChart = ({
  data,
  plotProps,
  tooltipLabel,
  xDataKey,
  yDataKey,
  yDomain,
  yAxisLabel,
}) => {
  const theme = useTheme()

  return (
    <Flex
      width='100%'
      fontFamily='secondary'
      fontSize={0}
      style={{ height: plotProps.height }}
    >
      <ResponsiveContainer>
        <BaseBarChart data={data} margin={{ top: 10, right: 25, left: 10 }}>
          <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
          <XAxis
            dataKey={xDataKey ?? 'x'}
            strokeWidth='1'
            interval='preserveStartEnd'
            axisLine={{ stroke: theme.colors.gray[4] }}
            tickLine={{ stroke: theme.colors.gray[4] }}
            tick={{ fill: theme.colors.gray[6] }}
            tickMargin={8}
            padding={{ left: 8, right: 8 }}
          />
          <YAxis
            domain={yDomain ?? ['auto', 'auto']}
            axisLine={false}
            tickLine={false}
            tick={{ fill: theme.colors.gray[6] }}
            tickFormatter={(val) => formatTick(val)}
            label={
              yAxisLabel ? (
                <AxisLabel
                  axisType='y'
                  x={10}
                  y={120}
                  width={0}
                  height={0}
                  fill={theme.colors.text}
                >
                  {yAxisLabel}
                </AxisLabel>
              ) : undefined
            }
          />
          <Tooltip
            cursor={{ fill: theme.colors.gray[3] }}
            wrapperStyle={{ zIndex: 1 }}
            content={<CustomTooltip />}
            isAnimationActive={false}
          />
          <Bar
            dataKey={yDataKey ?? 'y'}
            name={tooltipLabel}
            fill={theme.colors.primary.base}
            barSize={16}
            isAnimationActive={false}
          />
        </BaseBarChart>
      </ResponsiveContainer>
    </Flex>
  )
}

BarChart.propTypes = {
  data: PropTypes.array,
  plotProps: PropTypes.object,
  tooltipLabel: PropTypes.string,
  xDataKey: PropTypes.any,
  yDataKey: PropTypes.any,
  yDomain: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  yAxisLabel: PropTypes.node,
}

export default BarChart
