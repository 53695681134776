import { IS_DEALS_ENGINE } from '../../../constants'
import { ACCOUNT_TYPE } from '../../../enums'
import { isNotEmptyArray, isNotEmptyObject } from '../../../utils'

export const getMenuItems = (menus, userData) => {
  let newMenus = [...menus]
  const userKey = userData?.accountType?.key || userData?.accountType

  if (
    userKey === ACCOUNT_TYPE.RESELLER.key ||
    userKey === ACCOUNT_TYPE.PUBLISHER.key
  ) {
    const index = newMenus.findIndex((e) => e.key === 'transparency')
    newMenus.splice(index, 1)
  }

  if (!userData?.superUser) {
    const index = newMenus.findIndex((e) => e.key === 'deal')
    newMenus.splice(index, 1)
  }

  if (!userData?.sharedSegment && !userData.uploadedSegment) {
    newMenus = newMenus.filter((el) => el.key !== 'segment')
  }

  if (!userData?.ftrackhhidReach) {
    newMenus = newMenus.filter((el) => el.key !== 'reach')
  }

  if (!userData?.sharedSegment) {
    const index = newMenus.findIndex((e) => e.key === 'segment')
    const segmentMenu = {
      ...newMenus[index],
      submenu: [newMenus[index]?.submenu[0]],
    }
    newMenus[index] = segmentMenu
  }

  if (!userData.uploadedSegment) {
    const index = newMenus.findIndex((e) => e.key === 'segment')
    const segmentMenu = {
      ...newMenus[index],
      submenu: [newMenus[index]?.submenu[1]],
    }
    newMenus[index] = segmentMenu
  }

  if (!userData?.dealAccessTemplate) {
    const index = newMenus.findIndex((e) => e.key === 'deals')
    const dealMenu = {
      ...newMenus[index],
      submenu: [newMenus[index]?.submenu[0]],
    }
    newMenus[index] = dealMenu
  }

  if (IS_DEALS_ENGINE) {
    newMenus = newMenus.filter((el) => !['report', 'users'].includes(el.key))
  }

  if (!IS_DEALS_ENGINE || !userData?.admin) {
    newMenus = newMenus.filter((el) => el.key !== 'deals-admin')
  }

  if (isNotEmptyObject(userData)) {
    newMenus = newMenus.filter((menu) =>
      isNotEmptyArray(menu.roles) ? hasAccess(userData, ...menu.roles) : true
    )
  }

  return removeSingleSubMenu(newMenus)
}

const removeSingleSubMenu = (data) => {
  return data.map((item) => {
    let menu = { ...item }
    if (menu?.submenu?.length === 1) {
      menu = { ...menu, route: item.submenu?.[0]?.route }
      delete menu.submenu
    }
    return menu
  })
}

const hasAccess = (user, ...roles) => {
  return !!roles.find((r) => user.roles.includes(r.key))
}
