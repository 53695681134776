import http from '../http'

const ENDPOINT = 'help/'
const ENDPOINTDOCS = 'helpcenter/'

export default {
  attachment(params) {
    return http.post(`${ENDPOINT}addattachment`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  issue(params) {
    return http.post(`${ENDPOINT}createissue`, params)
  },
  pdfDocs() {
    return http.get(`${ENDPOINTDOCS}documentation/get`)
  },
  uploadDocs(params) {
    return http.post(`${ENDPOINTDOCS}documentation/upload`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  updateDocs(params) {
    return http.post(`${ENDPOINTDOCS}documentation/update`, params)
  },
  deleteDocs(params) {
    return http.post(`${ENDPOINTDOCS}documentation/delete`, params)
  },
}
