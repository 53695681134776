import React from 'react'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga4'

import './index.module.scss?global'
import { App } from './components'

const container = document.getElementById('app')
const root = createRoot(container)

ReactGA.initialize('G-99BQ8YYWEL')
root.render(<App />)
