import React, { useMemo } from 'react'
import {
  Grid,
  Flex,
  Box,
  Card,
  Input,
  Switch,
  DatePicker,
} from '@beachfront/ui'
import { Field, useFormState } from '@beachfront/ui/forms'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import moment from 'moment'

import { SelectField, LabelTooltip } from '../../../components'
import { TimezoneField } from '../../../components/form-fields'
import { TIMEZONE } from '../../../enums'
import { reportDayRanges, reportHourRanges } from '../@constants'
import { getReportTooltip } from '../@utils'

import { ReportFilterField } from '.'

const ReportForm = ({ config, disabled }) => {
  const { values } = useFormState()

  const hoursOfDay = useMemo(() => {
    const freq = config?.frequency?.find((f) => f.key === values.frequency)
    return freq?.hours || []
  }, [config, values])

  const disabledStartDate = (date) => {
    if (!date) {
      return false
    }
    if (values.endDate) {
      return date > values.endDate || date >= moment().endOf('day')
    }
    return date > moment().endOf('day')
  }

  const disabledEndDate = (date) => {
    if (!date) {
      return false
    }
    if (values.startDate) {
      return date < values.startDate || date >= moment().endOf('day')
    }
    return date > moment().endOf('day')
  }

  return (
    <>
      <Card overflow='initial'>
        <Grid columns={6} columnGap={3}>
          <Box columnSpan={[6, 3]}>
            <Field name='name' label='Report Name'>
              <Input
                placeholder='Enter name'
                maxLength={100}
                disabled={disabled}
              />
            </Field>
          </Box>
          <Flex gap={4} columnSpan={6}>
            <Field
              name='shared'
              label='Shared'
              extra={<LabelTooltip content={getReportTooltip('shared')} />}
            >
              <Switch disabled={disabled} />
            </Field>
            <Field
              name='schedule'
              label='Schedule'
              extra={<LabelTooltip content={getReportTooltip('schedule')} />}
            >
              <Switch disabled={disabled} />
            </Field>
          </Flex>
        </Grid>
        {values.schedule ? (
          <Grid columns={6} columnGap={3}>
            <Box columnSpan={[3, 2]}>
              <Field
                name='frequency'
                label='Frequency'
                extra={<LabelTooltip content={getReportTooltip('frequency')} />}
              >
                <SelectField
                  showSearch
                  data={config?.frequency || []}
                  keyField='key'
                  textField='value'
                  placeholder='Select Frequency'
                  disabled={disabled}
                />
              </Field>
            </Box>
            <Box columnSpan={[3, 2]}>
              <Field
                name='hour'
                label='Hour of Day'
                extra={<LabelTooltip content={getReportTooltip('hours')} />}
              >
                <SelectField
                  showSearch
                  data={hoursOfDay}
                  keyField='key'
                  textField='value'
                  placeholder='Select Hour of Day'
                  disabled={disabled}
                />
              </Field>
            </Box>
            <Box columnSpan={[6, 2]}>
              <Field
                name='emailList'
                label='Emails'
                extra={<LabelTooltip content={getReportTooltip('email')} />}
                isEqual={isEqual}
              >
                <SelectField
                  mode='tags'
                  data={[]}
                  keyField='key'
                  textField='value'
                  placeholder='Enter Emails'
                  disabled={disabled}
                />
              </Field>
            </Box>
          </Grid>
        ) : null}
        <Grid columns={2} columnGap={3}>
          <Box columnSpan={[2, 1]}>
            <Field
              name='dimensions'
              label='Report Keys'
              extra={<LabelTooltip content={getReportTooltip('dimensions')} />}
              isEqual={isEqual}
            >
              <SelectField
                showSearch
                mode='multiple'
                data={config?.dimensions || null}
                keyField='key'
                textField='value'
                placeholder='Select Report Keys'
                disabled={disabled}
              />
            </Field>
          </Box>
        </Grid>
        <Grid columns={2} columnGap={3}>
          <Box columnSpan={[2, 1]}>
            <Field
              name='measures'
              label='Metrics'
              extra={<LabelTooltip content={getReportTooltip('measures')} />}
              isEqual={isEqual}
            >
              <SelectField
                showSearch
                mode='multiple'
                data={config?.measures || null}
                keyField='key'
                textField='value'
                placeholder='Select Metrics'
                disabled={disabled}
              />
            </Field>
          </Box>
        </Grid>
        <Grid columns={4} columnGap={3}>
          <Box columnSpan={[4, 1]}>
            <Field name='range' label='Date Range'>
              <SelectField
                keyField='key'
                textField='value'
                placeholder='Select Date Range'
                disabled={disabled}
                data={
                  values.dimensions?.includes('hour')
                    ? reportHourRanges
                    : reportDayRanges
                }
              />
            </Field>
          </Box>
          {values.range === 'custom' ? (
            <>
              <Box columnSpan={[4, 1]}>
                <Field name='startDate' label='Start Date'>
                  <DatePicker
                    format='MM/DD/YYYY'
                    disabled={disabled}
                    placeholder='Select Start Date'
                    disabledDate={disabledStartDate}
                  />
                </Field>
              </Box>
              <Box columnSpan={[4, 1]}>
                <Field name='endDate' label='End Date'>
                  <DatePicker
                    format='MM/DD/YYYY'
                    placeholder='Select End Date'
                    disabled={disabled}
                    disabledDate={disabledEndDate}
                  />
                </Field>
              </Box>
            </>
          ) : null}
        </Grid>
        <Grid columns={4} columnGap={3}>
          <Flex
            columnSpan={
              values.timezone === TIMEZONE.CUSTOM.key ? [4, 2] : [4, 1]
            }
          >
            <TimezoneField
              tooltip='Report will show data according to the timezone selected'
              disabled={disabled}
            />
          </Flex>
        </Grid>
      </Card>
      {config?.filters?.length ? (
        <Box mt={3}>
          <ReportFilterField
            filters={config.filters}
            operations={config.operations}
            disabled={disabled}
          />
        </Box>
      ) : null}
    </>
  )
}

ReportForm.propTypes = {
  config: PropTypes.object,
  disabled: PropTypes.bool,
}

export default ReportForm
