import { isNumber, isString, roundWithSignificance } from '../../utils'

export const formatTick = (value, vertical, unit) => {
  let str = value

  if (isString(value) && vertical) {
    if (value.length <= 10) {
      str = value
    } else {
      str = value.substring(0, 10) + '...'
    }
  } else if (isNumber(value)) {
    const kl = roundWithSignificance(value / 1000)
    const ml = roundWithSignificance(kl / 1000)
    const bl = roundWithSignificance(ml / 1000)
    const tl = roundWithSignificance(bl / 1000)

    if (tl >= 1) {
      str = tl + 'T'
    } else if (bl >= 1) {
      str = bl + 'B'
    } else if (ml >= 1) {
      str = ml + 'M'
    } else if (kl >= 1) {
      str = kl + 'K'
    } else {
      str = roundWithSignificance(value).toString()
    }
  }

  if (unit) {
    if (unit === '$') {
      str = unit + str
    } else {
      str = str + unit
    }
  }

  return str
}
