import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from '@beachfront/ui'

import { SEGMENT_TYPE, SEGMENT_STATUS } from '../../../../enums'
import { formatCount, formatDate } from '../../../../utils'

export const segmentColumns = [
  {
    dataIndex: 'name',
    title: 'Name',
    fixed: 'left',
    sorter: true,
    width: 250,
    render: (val, rec) =>
      rec.rdsId && rec.status === SEGMENT_STATUS.ACTIVE.key ? (
        <Link to={`clearvu/${rec.id}`}>{val}</Link>
      ) : (
        <Link to={`${rec.id}`}>{val}</Link>
      ),
  },
  {
    dataIndex: 'remoteId',
    title: 'Remote ID',
    sorter: true,
    width: 150,
    render: (val) => val || 'NA',
  },
  {
    dataIndex: 'status',
    title: 'Status',
    sorter: true,
    filter: true,
    width: 140,
    render: (key) => {
      if (key === SEGMENT_STATUS.ACTIVE.key) {
        return <Badge status='success' text={SEGMENT_STATUS.ACTIVE.name} />
      }
      if (key === SEGMENT_STATUS.VALIDATION_FAILED.key) {
        return (
          <Badge status='error' text={SEGMENT_STATUS.VALIDATION_FAILED.name} />
        )
      }
      return (
        <Badge status='processing' text={SEGMENT_STATUS.fromKey(key)?.name} />
      )
    },
  },
  {
    dataIndex: 'dataType',
    title: 'Segment Type',
    sorter: true,
    filter: true,
    width: 140,
    render: (key) => SEGMENT_TYPE.fromKey(key)?.name ?? key,
  },
  {
    dataIndex: 'users',
    title: 'Users',
    width: 140,
    render: (val) => (val === null ? 'NA' : formatCount(val)),
  },
  {
    dataIndex: 'price',
    title: 'Price (CPM)',
    sorter: true,
    filter: true,
    width: 140,
  },
  {
    dataIndex: 'createDate',
    title: 'Uploaded',
    sorter: true,
    filter: true,
    width: 180,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'updateDate',
    title: 'Last Updated',
    sorter: true,
    filter: true,
    width: 180,
    render: (val) => formatDate(val),
  },
]
