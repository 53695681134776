import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Flex, Grid, Text } from '@beachfront/ui'
import { useField } from '@beachfront/ui/forms'
import { useDisclosure } from '@beachfront/ui/hooks'

import { isNotEmptyArray } from '../../../utils'

import { AccountModal, ExcludeAccountsModal } from '.'

const ManageAccountSection = ({
  formDisable,
  selectedAccounts,
  onSelectedAccountsChange,
}) => {
  const { input: inventoryInfoAccIds } = useField('inventoryInfoAccIds')
  const { input: inventoryInfoAcc } = useField('inventoryInfoAcc')
  const { input: appInfoIds } = useField('appInfoIds')
  const { input: accountExcludeList } = useField('accountExcludeList')
  const accountModal = useDisclosure()
  const excludeModal = useDisclosure()

  const onUpdateAccounts = (accountIds, accountValues, eligibleIds) => {
    inventoryInfoAccIds.onChange(accountIds)
    inventoryInfoAcc.onChange(accountValues)
    appInfoIds.onChange(eligibleIds)
    accountExcludeList.onChange([])
  }

  const onUpdateExcludeAccounts = (data) => {
    accountExcludeList.onChange(data)
  }

  const handleClick = () => {
    accountModal.open()
  }

  return (
    <Box>
      <Flex mb={2} justifyContent='flex-end'>
        <Button size='small' disabled={formDisable} onClick={handleClick}>
          Manage Accounts
        </Button>
      </Flex>
      <Grid>
        <Box columnSpan={24}>
          <CountBox disabled={formDisable}>
            {isNotEmptyArray(inventoryInfoAccIds.value) ? (
              <>
                <Text color='primary.base' mr={1} strong>
                  {inventoryInfoAccIds.value.length}
                </Text>
                <Text>
                  {`${
                    inventoryInfoAccIds.value.length === 1
                      ? 'Account'
                      : 'Accounts'
                  } Available`}
                </Text>
              </>
            ) : (
              <Text>No Accounts Available</Text>
            )}
          </CountBox>
          {isNotEmptyArray(inventoryInfoAccIds.value) ? (
            <>
              <Flex mb={2} justifyContent='flex-end'>
                <Button
                  size='small'
                  disabled={formDisable}
                  onClick={() => excludeModal.open()}
                >
                  Exclude Accounts
                </Button>
              </Flex>
              <CountBox disabled={formDisable}>
                {isNotEmptyArray(accountExcludeList.value) ? (
                  <>
                    <Text color='primary.base' mr={1} strong>
                      {accountExcludeList.value.length}
                    </Text>
                    <Text>
                      {`${
                        accountExcludeList.value.length === 1
                          ? 'Account'
                          : 'Accounts'
                      } Excluded`}
                    </Text>
                  </>
                ) : (
                  <Text>No Accounts Excluded</Text>
                )}
              </CountBox>
            </>
          ) : null}
        </Box>
      </Grid>
      <AccountModal
        isOpen={accountModal.isOpen}
        onClose={() => accountModal.close()}
        onSuccess={onUpdateAccounts}
        inventoryInfoAcc={inventoryInfoAcc.value}
        onSelectedAccountsChange={onSelectedAccountsChange}
      />
      <ExcludeAccountsModal
        isOpen={excludeModal.isOpen}
        onClose={() => excludeModal.close()}
        onSuccess={onUpdateExcludeAccounts}
        selectedAccounts={selectedAccounts}
        accountExcludeList={accountExcludeList.value}
      />
    </Box>
  )
}

ManageAccountSection.propTypes = {
  formDisable: PropTypes.bool,
  selectedAccounts: PropTypes.array,
  onSelectedAccountsChange: PropTypes.func,
}

const CountBox = ({ disabled, children }) => {
  return (
    <Flex
      mb={3}
      px='8px'
      py='6px'
      minHeight={32}
      bg='gray.3'
      borderRadius='base'
      alignItems='center'
      cursor={disabled ? 'not-allowed' : 'initial'}
    >
      {children}
    </Flex>
  )
}

CountBox.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
}

export default ManageAccountSection
