import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Card, Button, Help } from '@beachfront/ui'
import { DeleteFilled, PlusOutlined } from '@beachfront/ui/icons'
import { Field, useForm } from '@beachfront/ui/forms'
import { useFieldArray } from 'react-final-form-arrays'
import isEqual from 'lodash/isEqual'

import { SelectField, LabelTooltip } from '../../../components'
import { getReportTooltip } from '../@utils'

import { FilterValueSelect } from '.'

const ReportFilterField = ({ filters, operations, disabled }) => {
  const form = useForm()
  const { fields } = useFieldArray('conditions', { isEqual })

  const getUnselectedDimensions = (index) => {
    const excludeKeys = fields.value.slice(0, index).map((c) => c.dimension)
    return filters?.filter((f) => !excludeKeys.includes(f.key)) || []
  }

  const getFilterConfig = (index) => {
    const key = fields.value[index]?.dimension
    return filters?.find((f) => f.key === key) || {}
  }

  const onDimensionChange = (index) => {
    form.change(`conditions.${index}.value`, [])
  }

  const onAddFilter = () => {
    fields.push({ dimension: null, operation: null, value: [] })
  }

  const canAdd = () => {
    if (!fields.value?.length) {
      return true
    }
    const i = fields.value.length - 1
    return !!(
      fields.value[i]?.dimension &&
      fields.value[i]?.operation &&
      fields.value[i]?.value?.length
    )
  }

  const extra = !disabled ? (
    <Button icon={<PlusOutlined />} onClick={onAddFilter} disabled={!canAdd()}>
      Add Filter
    </Button>
  ) : null

  return (
    <Card title='Report Filters' overflow='initial' extra={extra}>
      {fields
        .map((key, index) => (
          <Flex
            key={key}
            flexDirection={['column', 'row']}
            gap={[0, 3]}
            mb={[3, 0]}
          >
            <Box width={[1, 1 / 3]}>
              <Field
                name={`${key}.dimension`}
                label='Dimension'
                extra={<LabelTooltip content={getReportTooltip('filter')} />}
              >
                <SelectField
                  keyField='key'
                  textField='name'
                  disabled={index < fields.length - 1 || disabled}
                  placeholder='Select Dimension'
                  data={getUnselectedDimensions(index)}
                  onChange={() => onDimensionChange(index)}
                />
              </Field>
            </Box>
            <Box width={[1, 1 / 3]}>
              <Field
                name={`${key}.operation`}
                label='Operation'
                extra={<LabelTooltip content={getReportTooltip('operation')} />}
              >
                <SelectField
                  data={operations || []}
                  keyField='key'
                  textField='value'
                  placeholder='Select Operation'
                  disabled={disabled}
                />
              </Field>
            </Box>
            <Box width={[1, 1 / 3]}>
              <Field
                name={`${key}.value`}
                label='Values'
                extra={
                  <LabelTooltip
                    content={getReportTooltip(
                      'value',
                      fields.value[index].dimension
                    )}
                  />
                }
                isEqual={isEqual}
              >
                <FilterValueSelect
                  config={getFilterConfig(index)}
                  disabled={disabled}
                />
              </Field>
            </Box>
            {!disabled ? (
              <Box mt={[0, 24]} alignSelf={['flex-end', 'auto']}>
                <Button
                  danger
                  icon={<DeleteFilled />}
                  onClick={() => fields.remove(index)}
                />
              </Box>
            ) : null}
          </Flex>
        ))
        .reverse()}
      {!fields.length ? (
        <Flex>
          <Help>
            {disabled
              ? 'This report has no filters applied'
              : 'Add a filter to select dimensions and values'}
          </Help>
        </Flex>
      ) : null}
    </Card>
  )
}

ReportFilterField.propTypes = {
  filters: PropTypes.array,
  operations: PropTypes.array,
  disabled: PropTypes.bool,
}

export default ReportFilterField
