import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter } from '@beachfront/ui'
import {
  TagsOutlined,
  UserOutlined,
  CalendarOutlined,
} from '@beachfront/ui/icons'

import {
  ContainsFilterPanel,
  DateFilterPanel,
  formatStringFilterTag,
  formatDateFilterTag,
} from '../../components/filter'

const AvailsFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='title'
        label='Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='creatorName'
        label='Creator Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<UserOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='createDate'
        label='Created'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
    </Filter>
  )
}

AvailsFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
}

export default AvailsFilter
