import React from 'react'
import PropTypes from 'prop-types'
import { Box, Accordion } from '@beachfront/ui'

import { api } from '../../../../client-api'

import { ACCOUNT_TYPE } from '../../../../enums'
import { useUserData } from '../../../../hooks'
import { VirtualSelectField, IncludeExcludeField } from '..'

import PublisherBuyerPreviewTags from './publisher-buyer-preview-tags'

const PublisherBuyerPanel = ({
  expandKeys,
  disabled,
  values,
  initialValues,
  params,
  id,

  getPopupContainer,
}) => {
  const [user] = useUserData()

  const getHeader = () => {
    const labels = []
    if (user.accountType.key !== ACCOUNT_TYPE.PUBLISHER.key) {
      labels.push('Publisher')
    }

    return labels.join(' / ')
  }

  if (!getHeader()) {
    return null
  }

  const extra = (
    <PublisherBuyerPreviewTags
      visible={!expandKeys.includes('pubBuyer')}
      values={values}
    />
  )

  return (
    <Accordion.Panel value='pubBuyer' header={getHeader()} extra={extra}>
      {user.accountType.key !== ACCOUNT_TYPE.PUBLISHER.key ? (
        <Box position='relative'>
          {!user.mediaPlanExclude ? (
            <Box
              position='absolute'
              height='32px'
              right='-7px'
              top='-7px'
              className='customize-radio'
            >
              <IncludeExcludeField name='excludeUserId' disabled={disabled} />
            </Box>
          ) : null}
          <VirtualSelectField
            mode='multiple'
            allowClear
            detail={id}
            request={api.common.publishers}
            apiParams={params}
            values={values}
            initialValues={initialValues}
            disabled={disabled}
            getPopupContainer={getPopupContainer}
            emptyMsg='No Publishers Available'
            form={{
              name: 'publishers',
              keyField: 'key',
              textField: 'name',
              placeholder: 'Select Publisher',
              selectionName: 'publishersObj',
              label: 'Publisher',
            }}
          />
        </Box>
      ) : null}
    </Accordion.Panel>
  )
}

PublisherBuyerPanel.defaultProps = {
  expandKeys: [],
  values: {},
  initialValues: {},
  params: {},
}

PublisherBuyerPanel.propTypes = {
  expandKeys: PropTypes.array,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  params: PropTypes.object,
  id: PropTypes.string,
  isPmp: PropTypes.bool,
  isDeal: PropTypes.bool,
  getPopupContainer: PropTypes.func,
}

export default PublisherBuyerPanel
