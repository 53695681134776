import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { PageHeader } from '../../../components'
import { useDsps, useFilters } from '../../../hooks'
import { localFilterKey } from '../../../components/filter'

import { DspTable } from './@components'

const Dsp = () => {
  const navigate = useNavigate()
  const [filters, setFilters] = useFilters(localFilterKey.ADMIN_DSP)
  const dsps = useDsps({ filters: filters.populated })

  const headerActions = (
    <>
      <Button onClick={dsps.refetch} icon={<ReloadOutlined />} />
      <Button type='primary' onClick={() => navigate('/admin/dsp/create')}>
        Create DSP
      </Button>
    </>
  )

  return (
    <>
      <PageHeader title='Admin - DSP' actions={headerActions} />
      <Box mt={2}>
        <DspTable
          dsp={dsps}
          filters={filters}
          setFilters={setFilters}
          loading={dsps.isFetching}
          error={dsps.error}
          onRefetch={dsps.refetch}
        />
      </Box>
    </>
  )
}

export default Dsp
