import React, { useState, useRef, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Flex, Box, Button, Tabs, Badge } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { isNotEmptyArray, getErrorMessage } from '../../../utils'
import { localFilterKey } from '../../../components/filter'
import {
  useContentSize,
  useUserData,
  useSegmentProviders,
  useFilters,
  useInfiniteTable,
} from '../../../hooks'
import {
  PageHeader,
  PageSearch,
  InfiniteTable,
  NetworkError,
  CommonSpinner,
} from '../../../components'

import { segmentColumns } from './@constants'
import { SegmentFilter } from './@components'

const SharedSegments = () => {
  const { contentHeight } = useContentSize()
  const { providerType } = useParams()
  const navigate = useNavigate()
  const [user] = useUserData()
  const [searchQuery, setSearchQuery] = useState('')

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.SHARED_SEGMENTS, {
    schema: {
      updatedDate: 'date',
    },
  })

  const { providers, isLoading } = useSegmentProviders({
    enabled: !!user.sharedSegment,
  })

  const isAll = providerType === 'ALL'
  const isOwned = !!providers.find((p) => p.key === providerType)?.owned

  const activeFilters = filters.populated
    .filter((f) => (isAll ? true : f.dataIndex !== 'sourceProvider'))
    .filter((f) =>
      isOwned ? f.dataIndex !== 'price' : f.dataIndex !== 'ingest'
    )

  const table = useInfiniteTable({
    queryKey: ['shared-segments'],
    queryFn: api.segment.getSharedList,
    filters: activeFilters,
    params: { provider: providerType, q: searchQuery },
    enabled: !!providerType,
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 307,
  }

  const columns = useMemo(() => {
    let cols = [...segmentColumns]
    if (isAll) {
      cols.splice(2, 0, {
        dataIndex: 'sourceProvider',
        title: 'Provider',
        sorter: true,
        filter: true,
        width: 140,
        render: (key) => providers.find((p) => p.key === key)?.value ?? key,
      })
    } else if (isOwned) {
      cols.splice(4, 1, {
        dataIndex: 'ingest',
        title: 'Injest',
        sorter: true,
        filter: true,
        width: 100,
        render: (val) =>
          val ? (
            <Badge status='success' text='True' />
          ) : (
            <Badge status='warning' text='False' />
          ),
      })
    }
    return cols
  }, [providers, isAll, isOwned])

  const onTabChange = (key) => {
    navigate(`/segment/shared/${key}`)
  }

  if (isNotEmptyArray(providers) && !providerType) {
    const path = user.sharedSegment
      ? `/segment/shared/${providers[0]?.key}`
      : '/segment/shared'
    return <Navigate to={path} replace />
  }

  const headerActions = (
    <>
      <PageSearch
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search Segments'
      />
      <Button onClick={() => table.refetch()} icon={<ReloadOutlined />} />
    </>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <SegmentFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
          providers={providers}
          isAll={isAll}
          isOwned={isOwned}
        />
      </Flex>
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title='Shared Segments' actions={headerActions} />
      <Box mt={2}>
        {isNotEmptyArray(providers) ? (
          <Tabs activeKey={providerType} onChange={onTabChange} type='card'>
            {providers.map((p) => (
              <Tabs.TabPane key={p.key} tab={p.value} />
            ))}
          </Tabs>
        ) : null}
        {isLoading ? (
          <CommonSpinner />
        ) : !table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <InfiniteTable
            rowKey='id'
            emptyText='No Shared Segments Found'
            columns={columns}
            toolbar={toolbar}
            scroll={scroll}
            filters={filters.populated}
            onFilter={(key) => filterRef.current?.addFilter(key)}
            {...table.props}
          />
        )}
      </Box>
    </>
  )
}

export default SharedSegments
