import React from 'react'
import PropTypes from 'prop-types'

const AxisLabel = ({ axisType, x, y, width, height, fill, children }) => {
  const isVert = axisType === 'y'
  const cx = isVert ? x : x + width / 2
  const cy = isVert ? height / 2 + y : y + height + 10
  const rot = isVert ? `270 ${cx} ${cy}` : 0
  return (
    <text
      x={cx}
      y={cy}
      transform={`rotate(${rot})`}
      textAnchor='middle'
      fill={fill}
      style={{ letterSpacing: 0 }}
    >
      {children}
    </text>
  )
}

AxisLabel.propTypes = {
  axisType: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  children: PropTypes.node,
}

export default AxisLabel
