import moment from 'moment'
import { useMemo } from 'react'
import { useSessionStorage } from '@beachfront/ui/hooks'

import { isString, isUndefined } from '../../utils/type-util'
import { useUserData } from '../use-user-data'

export const useFilters = (key, options = {}) => {
  const { initialState, schema = {} } = options
  const [user] = useUserData()
  const storageKey = `${user.accountId}:${key}`

  const [allFilters, setFilters] = useSessionStorage(storageKey, (filters) => {
    if (Array.isArray(filters)) {
      return filters.map((el) => {
        const desc = { ...el }

        if (schema[desc.dataIndex] === 'date') {
          if (Array.isArray(desc.value)) {
            const [start, end] = desc.value
            desc.value = [moment(start), moment(end)]
          } else {
            desc.value = moment(desc.value)
          }
        }

        return desc
      })
    }
    return initialState ?? []
  })

  const populatedFilters = useMemo(() => {
    return allFilters.filter((f) => {
      if (isUndefined(f.value)) {
        return false
      }
      if (Array.isArray(f.value) || isString(f.value)) {
        return f.value.length > 0
      }
      return true
    })
  }, [allFilters])

  return [{ all: allFilters, populated: populatedFilters }, setFilters]
}
