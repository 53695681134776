import { MEDIA_LIST_TYPE } from '../../../enums'

export const csvSampleData = {
  [MEDIA_LIST_TYPE.BUNDLE.key]: [
    ['com.roku.newsy'],
    ['com.foxsports.videogo'],
    ['com.cw.seed.android'],
    ['comcast.xite'],
    ['com.narvii.amino.master'],
  ],
  [MEDIA_LIST_TYPE.DOMAIN.key]: [
    ['abcnews4.com'],
    ['aboutyournaturalhealth.com'],
    ['abovetopsecret.com'],
    ['ad4game.com'],
    ['adfox.ru'],
  ],
  [MEDIA_LIST_TYPE.ZIP_CODE.key]: [
    ['30303'],
    ['32301'],
    ['19901'],
    ['36104'],
    ['99801'],
  ],
  [MEDIA_LIST_TYPE.METRO_CODE.key]: [
    ['512'],
    ['513'],
    ['514'],
    ['515'],
    ['516'],
  ],
}
