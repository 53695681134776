import React, { useState, useEffect } from 'react'
import { Flex, Button, Modal, Table } from '@beachfront/ui'
import {
  useDisclosure,
  useBreakpoints,
  useTableQuery,
} from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { isNotEmptyArray, formatCount } from '../../../utils'
import { PageSearch } from '../../../components'

import AccountDetailModal from './account-detail-modal'

const ExcludeAccountsModal = ({
  isOpen,
  onClose,
  onSuccess,
  selectedAccounts,
  accountExcludeList,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [detailAccount, setDetailAccount] = useState(null)
  const detailModal = useDisclosure()
  const breakpoints = useBreakpoints()

  const matchesQuery = (dt) => {
    return ['id', 'company'].some((key) =>
      String(dt[key]).toLowerCase().includes(searchQuery.toLowerCase())
    )
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const table = useTableQuery({
    data: applyQuery(selectedAccounts ?? []),
  })

  useEffect(() => {
    if (isNotEmptyArray(accountExcludeList)) {
      table.setSelectedRowKeys(accountExcludeList)
    }
  }, [accountExcludeList, isOpen])

  const onSubmit = () => {
    onSuccess?.(table.selectedRowKeys)
    onClose?.()
  }

  const openModal = (data = {}) => {
    setDetailAccount(data)
    detailModal.open()
  }

  const closeModal = () => {
    detailModal.close()
    setDetailAccount(null)
  }

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      fixed: 'left',
      width: 60,
      render: (id, rec) => (
        <Button
          size='small'
          type='link'
          onClick={() => openModal(rec)}
          style={{ marginLeft: '-8px' }}
        >
          {id}
        </Button>
      ),
    },
    {
      dataIndex: 'company',
      title: 'Company',
      width: 160,
      sorter: true,
    },
    {
      dataIndex: 'eligibleAppsCount',
      title: 'Eligible Inventory',
      width: 120,
      sorter: true,
      render: (val) => formatCount(val),
    },
    {
      dataIndex: 'noneligibleAppsCount',
      title: 'Non Eligible Inventory',
      width: 145,
      sorter: true,
      render: (val) => formatCount(val),
    },
  ]

  const scroll = { y: 360 }

  const rowSelection = {
    selectedRowKeys: table.selectedRowKeys,
    onChange: table.setSelectedRowKeys,
    columnWidth: 40,
    preserveSelectedRowKeys: true,
  }

  return (
    <Modal
      title='Exclude Accounts'
      visible={isOpen}
      width={breakpoints.md ? '60%' : '100%'}
      okText='Submit'
      onOk={onSubmit}
      onCancel={onClose}
      centered
    >
      <Flex mb={2} justifyContent='flex-end'>
        <PageSearch value={searchQuery} onChange={setSearchQuery} />
      </Flex>
      <Table
        rowKey='id'
        dataSource={table.data}
        columns={columns}
        scroll={scroll}
        rowSelection={rowSelection}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: false,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
      <AccountDetailModal
        title='Inventory Details'
        data={detailAccount}
        isOpen={detailModal.isOpen}
        onClose={closeModal}
      />
    </Modal>
  )
}

ExcludeAccountsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  selectedAccounts: PropTypes.array,
  accountExcludeList: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default ExcludeAccountsModal
