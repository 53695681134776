export const ISSUE_TYPES = {
  BILLING: {
    description: `Question about licensing or billing?`,
    id: '10401', // Corresponds to service request
    label: 'Licensing and Billing',
  },
  IMPROVEMENT: {
    description: `See a place we can do better? We're all ears.`,
    id: '4',
    label: 'Suggest Improvement',
  },
  QUESTION: {
    description: `Don't see what you're looking for? Ask here.`,
    id: '10471',
    label: 'Ask a question',
  },
  SUPPORT: {
    description: `Need help with installation, configuring or troubleshooting?`,
    id: '10400',
    label: 'Technical Support',
  },
}

export const CUSTOM_FIELDS = {
  ACCOUNT_NAME: {
    label: 'Account name',
    id: '10984',
  },
  CONTACT_EMAIL: {
    id: '10987',
    label: 'Contact email',
  },
  CONTACT_NAME: {
    id: '10986',
    label: 'Contact name',
  },
  SUBMITTED_BY: {
    id: '10985',
    label: 'Submitted',
  },
}
