import React from 'react'
import { Filter } from '@beachfront/ui'
import { TagsOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import {
  ContainsFilterPanel,
  formatStringFilterTag,
} from '../../../components/filter'

const AccountFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='name'
        label='Account Name'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TagsOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
    </Filter>
  )
}

AccountFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
}

export default AccountFilter
