import React from 'react'
import PropTypes from 'prop-types'
import { Card, Input, Grid } from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'

import { LabelTooltip } from '../../../components'
import { useUserData } from '../../../hooks'
import { CUSTOM_FIELDS } from '../@utils'

const ContactDetails = ({ loading }) => {
  const [user] = useUserData()
  return (
    <Card height='100%' loading={loading} title='Contact Details'>
      <Grid gap={3} columns={1}>
        <Field
          name={`customfield_${CUSTOM_FIELDS.CONTACT_NAME.id}`}
          label='Name'
          extra={
            <LabelTooltip content='Contact name will be notified of progress updates.' />
          }
        >
          <Input placeholder='Enter name' />
        </Field>
        <Field
          name={`customfield_${CUSTOM_FIELDS.CONTACT_EMAIL.id}`}
          label='Email'
        >
          <Input placeholder='Enter email' />
        </Field>
      </Grid>
      <p>
        The following information will be submitted with this form. Select
        account users information is used to ensure validity of requests and
        accountability. It cannot be modified.
      </p>
      <Field
        name={`customfield_${CUSTOM_FIELDS.SUBMITTED_BY.id}`}
        initialValue={user.email}
        label='Submitted By'
        disabled
      >
        <Input disabled />
      </Field>
      <Field
        name={`customfield_${CUSTOM_FIELDS.ACCOUNT_NAME.id}`}
        initialValue={user.accountName}
        label='Account Name'
        disabled
      >
        <Input disabled />
      </Field>
    </Card>
  )
}

ContactDetails.propTypes = {
  loading: PropTypes.bool,
}

export default ContactDetails
