import http from '../http'

export default {
  getAudit({ params, ...data }) {
    return http.post('audit/all', data, { params })
  },
  countries(params) {
    return http.get('target/country', { params })
  },
  app(params) {
    return http.get('target/app', { params })
  },
  chartDownload(data, params, responseType) {
    return http.post(`chart/download`, data, { params, responseType })
  },
  dmaSearch(params) {
    return http.get('target/dma', { params })
  },
  rating(params) {
    return http.get('target/rating', { params })
  },
  marketplaces() {
    return http.get('target/marketplace')
  },
  states({ countryCode, ...params }) {
    return http.post('target/state', countryCode, { params })
  },
  iabCategory(params) {
    return http.get('target/iabcategory', { params })
  },
  channel() {
    return http.get('target/channel')
  },
  network() {
    return http.get('target/network')
  },
  genres(params) {
    return http.get('target/genre', { params })
  },
  publishers(params) {
    return http.get('target/publisher', { params })
  },
  buyers() {
    return http.get('target/buyer')
  },
  zipCode(params) {
    let apiParams = { ...params }
    delete apiParams.stateCode
    return http.get(`target/zip/${params.stateCode}`, { params: apiParams })
  },
  search(params) {
    let apiParams = { ...params, search: params?.q || 'act' }
    delete apiParams.pmp
    delete apiParams.q
    return http.get('target/search', { params: apiParams })
  },
  saveAvails(data) {
    return http.post('avails/save', data)
  },
  getAllAvails({ params, ...data }) {
    return http.post('avails/all', data, { params })
  },
  getAvailById({ id, params }) {
    return http.get(`avails/get/${id}`, { params })
  },
  updateAvails(id, data) {
    return http.post(`avails/update/${id}`, data)
  },
  updateSegmentAvails(id, data) {
    return http.post(`avails/add/segment/${id}`, data)
  },
}
