import React from 'react'
import { Filter } from '@beachfront/ui'
import {
  TeamOutlined,
  IdcardOutlined,
  UserOutlined,
} from '@beachfront/ui/icons'

import {
  ContainsFilterPanel,
  formatStringFilterTag,
} from '../../../../components/filter'

const SupplyFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='pub'
        label='Publisher'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TeamOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='account'
        label='Account'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<IdcardOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='user'
        label='User'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<UserOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
    </Filter>
  )
}

export default SupplyFilter
