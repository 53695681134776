import React, { useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Box,
  Grid,
  Input,
  Modal,
  Radio,
  Spinner,
  useToast,
} from '@beachfront/ui'
import { Field, Form } from '@beachfront/ui/forms'
import { useBreakpoints } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { useIabCategories, useAppInfoDetail } from '../../../hooks'
import { SelectField } from '../../../components'
import {
  isEmptyString,
  isNotEmptyObject,
  getErrorMessage,
} from '../../../utils'
import { validateAppInfoForm } from '../@utils'

const AppInfoModal = ({ title, dealId, record, isOpen, onClose }) => {
  const toast = useToast()
  const breakpoints = useBreakpoints()
  const queryClient = useQueryClient()

  const categoryQuery = useIabCategories({
    enabled: isOpen,
  })

  const detailQuery = useAppInfoDetail({
    params: { id: dealId, bundle: record?.overridedBundle },
    enabled: isOpen && !!record,
  })

  const initialValues = useMemo(() => {
    const data = {
      status: 'passedIn',
    }

    if (isNotEmptyObject(detailQuery.data)) {
      const iabcat = []
      const iabcatCategories =
        detailQuery.data?.iabcat?.map((item) => {
          iabcat.push(item.key)
          const obj = { ...item, name: item.value }
          delete obj.value
          return obj
        }) ?? []

      Object.assign(data, {
        ...detailQuery.data,
        iabcat,
        iabcatCategories,
      })
    }

    return data
  }, [detailQuery.data])

  const onSubmit = (values, form) => {
    const dataSet = { ...values }
    delete dataSet.overridedBundle
    delete dataSet.iabcatCategories

    if (isEmptyString(dataSet.name)) {
      delete dataSet.name
    }

    if (isEmptyString(dataSet.domain)) {
      delete dataSet.domain
    }

    if (isEmptyString(dataSet.appStoreUrl)) {
      delete dataSet.appStoreUrl
    }

    const request = record ? api.deal.editInfo : api.deal.updateInfo
    const payload = {
      id: dealId,
      data: { [values.overridedBundle]: dataSet },
    }

    return request(payload).then(
      (res) => {
        if (res.data?.success) {
          toast.success({
            title: record ? 'App info updated.' : 'App info created.',
          })

          if (record) {
            form.initialize(values)
          } else {
            form.restart()
          }

          queryClient.invalidateQueries({
            queryKey: ['app-info-list', { id: dealId }],
          })
          setTimeout(() => onClose?.())
        } else {
          toast.error({
            title:
              res.data?.msg || 'Unable to process app info. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateAppInfoForm}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, dirty, form, values }) => (
        <Modal
          title={title}
          visible={isOpen}
          width={breakpoints.md ? '50%' : '100%'}
          cancelButtonProps={{ disabled: submitting }}
          okButtonProps={{ loading: submitting, disabled: !dirty }}
          okText={record ? 'Update' : 'Create'}
          afterClose={() => form.restart()}
          onCancel={onClose}
          onOk={handleSubmit}
        >
          <form onSubmit={handleSubmit}>
            <Spinner show={detailQuery.isFetching} mask />
            <Field name='status'>
              <Radio.Group>
                <Radio.Button value='passedIn'>Passed In</Radio.Button>
                <Radio.Button value='override'>Override</Radio.Button>
                <Radio.Button value='overrideIfEmpty'>
                  Override If Empty
                </Radio.Button>
              </Radio.Group>
            </Field>
            <Grid columnGap={3} columns={2}>
              <Box columnSpan={2}>
                <Field name='overridedBundle' label='Existing Bundle'>
                  <Input
                    placeholder='Enter Existing Bundle Name'
                    disabled={!!record}
                    onChange={(e) =>
                      form.change('overridedBundle', e?.target?.value?.trim())
                    }
                  />
                </Field>
              </Box>
              <Box columnSpan={1}>
                <Field name='bundle' label='Bundle'>
                  <Input
                    placeholder='Enter Bundle Name'
                    onChange={(e) =>
                      form.change('bundle', e?.target?.value?.trim())
                    }
                  />
                </Field>
              </Box>
              <Box columnSpan={1}>
                <Field name='name' label='Name'>
                  <Input
                    placeholder='Enter Name'
                    onChange={(e) =>
                      form.change('name', e?.target?.value?.trimStart())
                    }
                  />
                </Field>
              </Box>
              <Box columnSpan={1}>
                <Field name='domain' label='Domain'>
                  <Input
                    placeholder='Enter Domain Name'
                    onChange={(e) =>
                      form.change('domain', e?.target?.value?.trimStart())
                    }
                  />
                </Field>
              </Box>
              <Box columnSpan={1}>
                <Field name='appStoreUrl' label='App Store URL'>
                  <Input
                    placeholder='Enter App Store URL'
                    onChange={(e) =>
                      form.change('appStoreUrl', e?.target?.value?.trim())
                    }
                  />
                </Field>
              </Box>
              <Box columnSpan={2}>
                <Field name='iabcat' label='IAB Categories'>
                  <SelectField
                    allowClear
                    mode='multiple'
                    keyField='key'
                    textField='name'
                    placeholder='Select IAB Categories'
                    data={categoryQuery.data ?? values.iabcatCategories}
                    loading={categoryQuery.isLoading}
                  />
                </Field>
              </Box>
            </Grid>
          </form>
        </Modal>
      )}
    </Form>
  )
}

AppInfoModal.propTypes = {
  title: PropTypes.string,
  dealId: PropTypes.string,
  record: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default AppInfoModal
