import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Divider } from '@beachfront/ui'

import { api } from '../../../client-api'
import { useFetch } from '../../../hooks'
import { omit } from '../../../utils'
import { CommonSpinner } from '../../../components'
import { BarChart } from '../../../components/chart'

import style from './delivery-form.module.scss'

import { EmptyChartWarning } from '.'

const DeliveryChart = ({ postData }) => {
  const params = omit(postData, ['start', 'end'])

  if (Object.hasOwn(postData, 'start') && Object.hasOwn(postData, 'end')) {
    postData.startDate = postData.start
    postData.endDate = postData.end
  }

  const chartResponse = useFetch({
    request: api.deal.budgetChart,
    payload: {
      data: params,
    },
  })

  const budgetChart = useMemo(() => {
    if (chartResponse.data) {
      return {
        ...chartResponse.data,
        barChart: buildChartData(chartResponse.data.chart),
      }
    }
    return {}
  }, [chartResponse.data])

  let barData = []
  let maxDomain

  const buildChartData = (data) => {
    if (data) {
      data.xAxis.forEach((d, i) => {
        barData.push({
          label: d,
          Impressions: data.yAxis[i],
        })
      })
      let numbers = []
      data.yAxis.forEach((d, i) => {
        if (i < data.yAxis.length) {
          numbers.push(d)
        }
      })
      maxDomain = Math.max.apply(null, numbers)
    }
    return barData
  }

  return (
    <>
      {chartResponse.loading ? (
        <CommonSpinner />
      ) : chartResponse.data?.success ? (
        <>
          {budgetChart.top ? (
            <>
              <div className={style.box}>
                <div dangerouslySetInnerHTML={{ __html: budgetChart.top }} />
              </div>
              <Divider />
            </>
          ) : null}
          {chartResponse.data?.chart ? (
            <>
              <Heading level={5}>{chartResponse.data.chart.title}</Heading>
              <Box>
                <BarChart
                  data={budgetChart.barChart}
                  yDomain={[0, maxDomain]}
                  xDataKey='label'
                  yDataKey='Impressions'
                  plotProps={{ height: 225 }}
                />
              </Box>
            </>
          ) : null}
          {budgetChart.bottom ? (
            <>
              <Divider />
              <div className={style.box}>
                <div dangerouslySetInnerHTML={{ __html: budgetChart.bottom }} />
              </div>
            </>
          ) : null}
        </>
      ) : (
        <EmptyChartWarning
          msg={chartResponse.data?.msg || 'No Data Available'}
        />
      )}
    </>
  )
}

DeliveryChart.propTypes = {
  postData: PropTypes.object,
}

export default DeliveryChart
