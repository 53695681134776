import React from 'react'
import {
  Grid,
  Box,
  Card,
  DatePicker,
  Input,
  Select,
  Switch,
} from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'
import PropTypes from 'prop-types'
import moment from 'moment'

const SystemMessageForm = ({ onEnableChange }) => {
  return (
    <Card overflow='initial'>
      <Grid columnGap={3} columns={2}>
        <Box columnSpan={2}>
          <Field name='message' label='Message'>
            <Input.TextArea rows={3} placeholder='Enter Message' />
          </Field>
        </Box>
        <Box columnSpan={[2, 1]}>
          <Field label='Type' name='type'>
            <Select>
              <Select.Option value='info'>Information</Select.Option>
              <Select.Option value='success'>Success</Select.Option>
              <Select.Option value='warning'>Warning</Select.Option>
              <Select.Option value='error'>Severe</Select.Option>
            </Select>
          </Field>
        </Box>
        <Box columnSpan={[2, 1]}>
          <Field label='Expiration' name='expiration'>
            <DatePicker
              disabledDate={(current) => {
                const customDate = moment().format('YYYY-MM-DD')
                return current && current < moment(customDate, 'YYYY-MM-DD')
              }}
              showTime={{ format: 'hh:mm a', use12Hours: true }}
              format='MM/DD/YYYY hh:mm a'
            />
          </Field>
        </Box>
        <Box columnSpan={2}>
          <Field label='Enabled' name='enabled'>
            <Switch onChange={onEnableChange} />
          </Field>
        </Box>
      </Grid>
    </Card>
  )
}

SystemMessageForm.propTypes = {
  onEnableChange: PropTypes.func,
}

export default SystemMessageForm
