import React, { useContext } from 'react'
import { Box, Layout } from '@beachfront/ui'
import styled from 'styled-components'

const FooterLink = styled.a`
  color: inherit;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: underline;
  }
`

const MenuFooter = () => {
  const { siderCollapsed } = useContext(Layout.SiderContext)

  return (
    <Box
      as='footer'
      py={3}
      fontSize={10}
      fontFamily='secondary'
      textAlign='center'
      color='dark.text'
      opacity={0.8}
    >
      {!siderCollapsed ? (
        <FooterLink
          href='http://www.beachfront.com'
          rel='noreferrer'
          target='_blank'
        >
          &copy; {new Date().getFullYear()} Beachfront Media LLC
        </FooterLink>
      ) : null}
      <div>v{APP_VERSION}</div>
    </Box>
  )
}

export default MenuFooter
