import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'
import { parseFields } from '../../utils'

export const useSupplyRequests = () => {
  return useQuery({
    queryKey: ['admin-supply-requests'],
    queryFn: async () => {
      const res = await api.admin.supplyRequest.getAll({
        p: 0,
        s: 500,
      })
      const data = parseFields(res.data?.data ?? [], {
        requestDate: 'date',
      })
      data.forEach((dt) => {
        dt.pub = dt.pub ?? ''
        dt.account = dt.account ?? ''
        dt.user = dt.user ?? ''
      })
      return data
    },
  })
}
