import http from '../http'

const ENDPOINT = 'reach/'

export default {
  platformChart({ params }) {
    return http.get(`${ENDPOINT}platform/chart`, { params })
  },
  bucketChart({ params }) {
    return http.get(`${ENDPOINT}bar/chart`, { params })
  },
  platformTable({ params }) {
    return http.get(`${ENDPOINT}platform/list`, { params })
  },
  demographicTable({ params }) {
    return http.get(`${ENDPOINT}dma/chart`, { params })
  },
}
