import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Tag } from '@beachfront/ui'

const PublisherBuyerPreviewTags = ({ visible, values }) => {
  if (!visible) {
    return null
  }

  const tags = []
  const publisherCount = values.publishersObj?.length ?? 0
  const buyerCount = values.buyer?.key ? 1 : 0

  if (publisherCount) {
    tags.push(
      publisherCount === 1 ? (
        <Tag
          key='publisher'
          color='processing'
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: buyerCount ? '50%' : '100%',
          }}
        >
          {values.publishersObj[0].name}
        </Tag>
      ) : (
        <Tag key='publisher' color='processing' flex='none'>
          {publisherCount} Publishers
        </Tag>
      )
    )
  }

  if (buyerCount) {
    tags.push(
      buyerCount === 1 ? (
        <Tag
          key='buyer'
          color='processing'
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: publisherCount ? '50%' : '100%',
          }}
        >
          {values.buyer?.name}
        </Tag>
      ) : (
        <Tag key='buyer' color='processing' flex='none'>
          {buyerCount} Buyers
        </Tag>
      )
    )
  }

  if (tags.length) {
    return (
      <Flex gap={1} maxWidth={200} justifyContent='flex-end'>
        {tags}
      </Flex>
    )
  }

  return null
}

PublisherBuyerPreviewTags.propTypes = {
  visible: PropTypes.bool,
  values: PropTypes.object,
}

export default PublisherBuyerPreviewTags
