import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Menu, Avatar, Flex, Text } from '@beachfront/ui'
import { useBreakpoints, useDisclosure } from '@beachfront/ui/hooks'

import { authService } from '../../../context'
import { IS_DEALS_ENGINE } from '../../../constants'
import { ACCOUNT_TYPE } from '../../../enums'
import { useUserData } from '../../../hooks'
import { UpdatePasswordModal } from '../../../pages/users'

const Profile = () => {
  const [user] = useUserData()
  const { email, firstName, lastName } = user

  const passwordModal = useDisclosure()
  const navigate = useNavigate()
  const breakpoints = useBreakpoints()

  const firstChar = firstName && firstName.charAt(0).toUpperCase()
  const lastChar = lastName && lastName.charAt(0).toUpperCase()
  const emailChar = email && email.charAt(0).toUpperCase()
  const shortName = firstChar + lastChar || firstChar || emailChar || 'U'

  const isApiKeyEnabled = () => {
    const userKey = user?.accountType?.key || user?.accountType
    return userKey === ACCOUNT_TYPE.RESELLER.key && user?.admin
  }

  const onLogout = async () => {
    await authService.logout()
    sessionStorage.clear()
    navigate('/')
  }

  const menu = (
    <Menu theme='dark'>
      {isApiKeyEnabled() ? (
        <Menu.Item onClick={() => navigate('/setting/apikey')}>
          API Keys
        </Menu.Item>
      ) : null}
      {!IS_DEALS_ENGINE ? (
        <Menu.Item onClick={passwordModal.open}>Update Password</Menu.Item>
      ) : null}
      <Menu.Item onClick={onLogout}>Sign Out</Menu.Item>
    </Menu>
  )

  return (
    <>
      <UpdatePasswordModal
        visible={passwordModal.isOpen}
        onClose={passwordModal.close}
        hasPassword
      />
      <Dropdown
        placement='bottomRight'
        overlay={menu}
        overlayStyle={{ minWidth: 0 }}
        trigger={['click']}
        getPopupContainer={(el) => el.parentNode}
      >
        <Flex height='40px' alignItems='center' cursor='pointer'>
          <Avatar
            shape='square'
            bg='gray.5'
            size='small'
            fallback={shortName}
          />
          {breakpoints.md ? (
            <Text fontSize={0} ml={2} color='dark.text' ellipsis>
              {email}
            </Text>
          ) : null}
        </Flex>
      </Dropdown>
    </>
  )
}

export default Profile
