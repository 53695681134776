import { createEnum } from '../utils'

export const DSP_STATUS = createEnum({
  ENABLED: {
    name: 'Enabled',
  },
  DISABLED: {
    name: 'Disabled',
  },
})
