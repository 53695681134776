import React, { useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../client-api'
import { TIMEZONE } from '../../enums'
import { CommonSpinner, PageHeader } from '../../components'
import {
  isNotEmptyArray,
  isNotEmptyObject,
  getErrorMessage,
  isValidMongoId,
} from '../../utils'
import {
  DownloadButton,
  TargetingForm,
  validateForm,
  getExcludeInitialValue,
  getKeys,
  getOrAndInitialValue,
} from '../media-plan'

import {
  createAvailsPostPayload,
  createAccountSettingsPayload,
} from './avails-util'

const EditAvails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)
  const [availsDsp, setAvailsDsp] = useState('')
  const [dataCost, setDataCost] = useState(0)
  const [downloadEnabled, setDownloadEnabled] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const response = useQuery({
    queryKey: ['avails-explorer', id],
    queryFn: async () => {
      const res = await api.common.getAvailById({ id })
      const avail = res.data

      if (isNotEmptyObject(res.data?.accSettingsReq)) {
        const data = createAccountSettingsPayload(res.data.accSettingsReq)
        const accResponse = await api.mediaPlan.publisherAccountList({ data })
        const accounts = accResponse.data?.data
        return { avail, accounts }
      }

      return { avail, accounts: [] }
    },
  })

  const initialValues = useMemo(() => {
    const { avail, accounts } = response.data ?? {}
    const values = {}

    if (avail) {
      Object.assign(values, avail, {
        contentBooleanLogic: avail.contentBooleanLogic ?? {
          enabled: false,
          client: [],
          value: '',
        },
        audienceBooleanLogic: avail.audienceBooleanLogic ?? {
          enabled: false,
          client: [],
          value: '',
        },
        includeLivestream: avail.includeLivestream,
        mediaType: avail.mediaType ? [avail.mediaType] : [],
        status: avail.status ? 'ENABLE' : 'DISABLE',
        country: avail.country ? getKeys(avail.country) : [],
        countryObj: avail.country ? avail.country : [],
        genres: avail.genres ? getKeys(avail.genres) : [],
        genresObj: avail.genres ? avail.genres : [],
        network: isNotEmptyArray(avail.network) ? getKeys(avail.network) : [],
        networkObj: isNotEmptyArray(avail.network) ? avail.network : [],
        channel: isNotEmptyArray(avail.channel) ? getKeys(avail.channel) : [],
        rating: isNotEmptyArray(avail.rating) ? getKeys(avail.rating) : [],
        ratingObj: isNotEmptyArray(avail.rating) ? avail.rating : [],
        channelObj: isNotEmptyArray(avail.channel) ? avail.channel : [],
        iabCategory: avail.iabCategory ? getKeys(avail.iabCategory) : [],
        iabCategoryObj: avail.iabCategory ? avail.iabCategory : [],
        state: avail.state ? getKeys(avail.state) : [],
        stateObj: avail.state ? avail.state : [],
        dma: avail.dma ? getKeys(avail.dma, 'dmaCode') : [],
        dmaObj: avail.dma ? avail.dma : [],
        app: avail.country ? getKeys(avail.app) : [],
        buyers: isNotEmptyObject(avail.buyer) ? avail.buyer.key : {},
        appObj: avail.country ? avail.app : [],
        accountExcludeList: avail.inventoryInfoAccIds
          ? avail.inventoryInfoAccIds
          : [],
        excludeCountry: getExcludeInitialValue(avail.excludeCountry),
        excludeDma: getExcludeInitialValue(avail.excludeDma),
        excludeState: getExcludeInitialValue(avail.excludeState),
        excludeiabCat: getExcludeInitialValue(avail.excludeiabCat),
        excludeNetwork: getExcludeInitialValue(avail.excludeNetwork),
        excludeChannel: getExcludeInitialValue(avail.excludeChannel),
        excludeRating: getExcludeInitialValue(avail.excludeRating),
        excludeAccIds: getExcludeInitialValue(avail.excludeAccIds),
        excludeGenres: getExcludeInitialValue(avail.excludeGenres),
        excludeApp: getExcludeInitialValue(avail.excludeApp),
        includeSegmentAnd: getOrAndInitialValue(avail.includeSegmentAnd),
        excludeSegmentAnd: getOrAndInitialValue(true),
        inventoryInfoAcc: avail.accSettingsReq,
        inventoryInfoAccIds: accounts.map((acc) => acc.id),
        appInfoIds: [],
        excludeDomain: 'INCLUDE',
        excludeBundle: 'INCLUDE',
        excludezip: 'INCLUDE',
      })
      const isBundleMediaPresent = avail.bundleMedia
        ? avail.bundleMedia.ids.some((el) => isValidMongoId(el.id))
        : null
      const isDomainMediaPresent = avail.domainMedia
        ? avail.domainMedia.ids.some((el) => isValidMongoId(el.id))
        : null
      if (isNotEmptyObject(avail.bundleMedia) && !isBundleMediaPresent) {
        const { ids, included } = avail.bundleMedia
        values.excludeBundle = included ? 'INCLUDE' : 'EXCLUDE'
        values.bundle = getKeys(ids, 'id')
      }
      if (isNotEmptyObject(avail.domainMedia) && !isDomainMediaPresent) {
        const { ids, included } = avail.domainMedia
        values.excludeDomain = included ? 'INCLUDE' : 'EXCLUDE'
        values.domain = getKeys(ids, 'id')
        values.domainMedia = null
      }
      if (isNotEmptyObject(avail.zipMedia)) {
        const { ids, included } = avail.zipMedia
        const isMongoZipId = ids.some((el) => isValidMongoId(el.id))
        values.excludezip = included ? 'INCLUDE' : 'EXCLUDE'
        if (!isMongoZipId) {
          values.zip = getKeys(ids, 'id')
        }
      }
      accounts.forEach((acc) => {
        acc.eligibleApps?.forEach((app) => {
          values.appInfoIds.push(app.id)
        })
      })
    }
    return values
  }, [response.data])

  const onSubmit = (values) => {
    const payload = createAvailsPostPayload(values)
    return api.common.updateAvails(id, payload).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'Avails explore updated.' })
          navigate('/admin/avails-explorer')
        } else {
          toast.error({
            title:
              res.data?.msg ||
              res.data.errorDetails ||
              'Unable to update avails explore. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  if (response.isLoading) {
    return <CommonSpinner />
  }

  return (
    <Form
      initialValues={initialValues}
      validate={(values) => validateForm({ values })}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, dirty, submitting }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Edit Avails'
            actions={
              <>
                {/* <DownloadButton
                  values={values}
                  dataCost={dataCost}
                  timezone={timezone}
                  disabled={!downloadEnabled}
                  onDownloadStart={() => setIsDownloading(true)}
                  onDownloadComplete={() => setIsDownloading(false)}
                /> */}
                <Link to='/admin/avails-explorer'>
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={!dirty || isDownloading}
                  loading={submitting}
                >
                  Update
                </Button>
              </>
            }
          />
          <Box mt={3} overflow='auto'>
            <TargetingForm
              availsDsp={availsDsp}
              adminUpdate={true}
              pmp={true}
              onCostChange={setDataCost}
              params={{ pmp: true }}
              id={id}
              values={values}
              initialValues={initialValues}
              accountData={response.data?.accounts}
              timezone={timezone}
              onTimezoneChange={setTimezone}
              onChartFetch={setDownloadEnabled}
              onAvailsDspChange={setAvailsDsp}
            />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default EditAvails
