import { createEnum } from '../utils'

export const META_TYPE = createEnum({
  ADOMAIN: {
    name: 'Adomain',
  },
  SEAT: {
    name: 'Seat',
  },
  AGENCY: {
    name: 'Agency',
  },
  ACCOUNT: {
    name: 'Account',
  },
})
