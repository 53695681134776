import React, { useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import ReactGA from 'react-ga4'
import { Button, Flex, Tooltip } from '@beachfront/ui'
import {
  AmpersandOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ClearOutlined,
  CurlyBracketsOutlined,
  DeleteOutlined,
  ExclamationOutlined,
  LineOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  ParenthesesOutlined,
  UndoOutlined,
} from '@beachfront/ui/icons'
import { useKeyPress, useHotKeys } from '@beachfront/ui/hooks'

import { useBooleanSegment } from '../../boolean-segment-context'
import { addToExpressionRecursive } from '../../../../@utils/boolean-segment.util'

const ExpressionControls = ({ resetValidation }) => {
  const {
    activeItem,

    expressionV2,
    isExpanded,
    setExpressionV2,
    setActiveItem,
    setIsExpanded,
    stateHistory,
  } = useBooleanSegment()

  const isDisabled = useMemo(() => {
    const isGroupActiveOrNull = activeItem && activeItem.type !== 'GROUP'

    return {
      group: isGroupActiveOrNull,
      set: isGroupActiveOrNull,
      union: isGroupActiveOrNull,
    }
  }, [activeItem])

  const getTypeForExpression = (type) => {
    if (type === 'SET') {
      return {
        id: `set-${Date.now()}`,
        type: 'SET',
        children: [],
      }
    }

    if (type === 'GROUP') {
      return {
        id: `group-${Date.now()}`,
        type: 'GROUP',
        children: [],
      }
    }

    return {
      id: `union-${Date.now()}`,
      type: 'UNION',
      value: type,
    }
  }

  const onAddToExpression = (type = 'SET') => {
    let updatedExpression = null

    if (activeItem?.type === 'SET') {
      return null
    }

    setExpressionV2((prevState) => {
      const obj = getTypeForExpression(type)
      if (!activeItem) {
        const newItem = [...prevState, obj]
        stateHistory.current.push(newItem)
        return newItem
      }

      updatedExpression = prevState.map((item) =>
        addToExpressionRecursive(item, obj, activeItem)
      )
      stateHistory.current.push(updatedExpression)
      return updatedExpression
    })
  }

  const shiftItem = (array, direction = 'right') => {
    if (!activeItem?.id) {
      return array
    }

    const activeIndex = array.findIndex((item) => item.id === activeItem.id)

    // If the active item is not found at the current level, recursively search in the children.
    if (activeIndex === -1) {
      return array.map((item) => {
        if (Array.isArray(item.children)) {
          return {
            ...item,
            children: shiftItem(item.children, direction),
          }
        } else {
          return item
        }
      })
    } else {
      const newState = [...array]
      const limit = direction === 'right' ? newState.length - 1 : 0
      const key = direction === 'right' ? activeIndex + 1 : activeIndex - 1

      if (activeIndex === limit) {
        return array
      }

      const temp = newState[activeIndex]
      newState[activeIndex] = newState[key]
      newState[key] = temp

      return newState
    }
  }

  const onShift = (direction = 'right') => {
    setExpressionV2((prevState) => {
      const newState = shiftItem(prevState, direction)
      stateHistory.current.push(newState)

      return newState
    })
  }

  const undoExpressionChange = () => {
    if (stateHistory.current.length > 0) {
      stateHistory.current.pop()
      const nextState = cloneDeep(
        stateHistory.current[stateHistory.current.length - 1]
      )

      setExpressionV2(nextState || [])
    }
  }

  // Controls
  useKeyPress('g', () => onAddToExpression('GROUP'))
  useKeyPress('s', () => onAddToExpression('SET'))
  useKeyPress('!', () => onAddToExpression('not'))
  useKeyPress('&', () => onAddToExpression('and'))
  useKeyPress('|', () => onAddToExpression('or'))
  useKeyPress('e', () => setIsExpanded((prevState) => !prevState))

  //Re-Order
  useKeyPress('ArrowRight', onShift)
  useKeyPress('ArrowLeft', () => onShift('left'))
  // Delete/REmove Items
  const deleteActiveItem = () => {
    if (!activeItem) {
      return
    }

    setExpressionV2((prevState) => {
      const updatedState = prevState
        .map((item) => recursiveDelete(item))
        .filter(Boolean)

      stateHistory.current.push(updatedState)
      return updatedState
    })

    setActiveItem(null)
  }
  useKeyPress('Backspace', deleteActiveItem)
  useKeyPress('Delete', deleteActiveItem)

  const recursiveDelete = (stateItem) => {
    if (
      stateItem.id === activeItem?.id ||
      stateItem.clientId === activeItem?.id
    ) {
      return null
    }

    if (stateItem.children && stateItem.children.length > 0) {
      const newChildren = stateItem.children
        .map((child) => recursiveDelete(child))
        .filter(Boolean)
      return { ...stateItem, children: newChildren }
    }

    return stateItem
  }

  const clearExpression = () => {
    setExpressionV2([])
    setActiveItem(null)

    ReactGA.event({
      category: 'audience_boolean_logic',
      action: 'clear_expression',
    })
  }

  // Master Reset Hotkey
  useKeyPress('c', clearExpression)
  useHotKeys([
    {
      keyPattern: 'meta+z',
      onKeyPress: () => undoExpressionChange(),
    },
  ])

  return (
    <Flex
      bg='gray.1'
      borderBottom='split'
      gap={2}
      mt={-3}
      mx={-3}
      p={3}
      justifyContent='space-between'
      className='expression-controls'
    >
      <Flex gap={2}>
        <Tooltip title={isExpanded ? 'Collapse' : 'Expand'} placement='bottom'>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'audience_boolean_logic',
                action: 'expand',
                value: Number(!isExpanded),
              })
              setIsExpanded((prevState) => !prevState)
            }}
            shape='circle'
            icon={
              isExpanded ? <NodeExpandOutlined /> : <NodeCollapseOutlined />
            }
          />
        </Tooltip>
        <Tooltip title='Shift Left' placement='bottom'>
          <Button
            onClick={() => onShift('left')}
            shape='circle'
            icon={<ArrowLeftOutlined />}
            disabled={!activeItem}
          />
        </Tooltip>
        <Tooltip title='Shift Right' placement='bottom'>
          <Button
            onClick={() => onShift()}
            shape='circle'
            icon={<ArrowRightOutlined />}
            disabled={!activeItem}
          />
        </Tooltip>
      </Flex>
      <Flex gap={2}>
        <Tooltip title='New Group' placement='bottom'>
          <Button
            onClick={() => onAddToExpression('GROUP')}
            shape='circle'
            icon={<ParenthesesOutlined />}
            disabled={isDisabled.group}
          />
        </Tooltip>
        <Tooltip title='New Set' placement='bottom'>
          <Button
            icon={<CurlyBracketsOutlined />}
            onClick={() => onAddToExpression('SET')}
            shape='circle'
            title='New Set'
            disabled={isDisabled.set}
          />
        </Tooltip>
        <Tooltip title='AND' placement='bottom'>
          <Button
            icon={<AmpersandOutlined />}
            shape='circle'
            onClick={() => onAddToExpression('and')}
            title='And'
            disabled={isDisabled.union}
          />
        </Tooltip>
        <Tooltip title='OR' placement='bottom'>
          <Button
            icon={<LineOutlined />}
            shape='circle'
            onClick={() => onAddToExpression('or')}
            title='OR'
            disabled={isDisabled.union}
            style={{
              transform: 'rotate(90deg)',
            }}
          />
        </Tooltip>
        <Tooltip title='NOT' placement='bottom'>
          <Button
            icon={<ExclamationOutlined />}
            shape='circle'
            onClick={() => onAddToExpression('not')}
            disabled={isDisabled.union}
          />
        </Tooltip>
      </Flex>
      <Flex gap={2}>
        <Tooltip title='Undo Last Action' placement='bottom'>
          <Button
            onClick={undoExpressionChange}
            shape='circle'
            icon={<UndoOutlined />}
            title='Undo Last Action'
            disabled={stateHistory?.current?.length === 0}
          />
        </Tooltip>
        <Tooltip title='Delete Active Item' placement='bottom'>
          <Button
            onClick={deleteActiveItem}
            shape='circle'
            icon={<DeleteOutlined />}
            title='Delete Active Item'
            disabled={!activeItem || expressionV2.length < 1}
          />
        </Tooltip>
        <Tooltip title='Clear Expression' placement='bottom'>
          <Button
            onClick={clearExpression}
            shape='circle'
            icon={<ClearOutlined />}
            title='Clear Expression'
            disabled={expressionV2.length < 1}
          />
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export default ExpressionControls
