import React from 'react'
import { Button, Text, Tag } from '@beachfront/ui'
import { PlusOutlined } from '@beachfront/ui/icons'

import { formatCurrency, formatPercent, formatDate } from '../../../utils'

export const realtimeColumns = [
  {
    key: 'expand',
    title: '',
    width: 40,
    render: () => <Button size='small' icon={<PlusOutlined />} />,
  },
  {
    dataIndex: 'pub',
    title: 'Publisher',
    width: 150,
  },
  {
    dataIndex: 'timestamp',
    title: 'Timestamp',
    width: 90,
    render: (date) => (date.isValid() ? date.format('hh:mm A') : '--'),
  },
  {
    dataIndex: 'win',
    title: 'Result',
    width: 60,
    render: (val) =>
      val ? <Tag color='success'>WON</Tag> : <Tag color='error'>LOST</Tag>,
  },
  {
    dataIndex: 'clearPrice',
    title: 'Clearing Price',
    width: 80,
    render: (val) => val || 'Pending',
  },
]

export const detailRealtimeColumns = [
  {
    dataIndex: 'id',
    title: 'ID',
    width: 220,
    render: (val) =>
      val ? (
        <Tag color='processing' ghost>
          #{val}
        </Tag>
      ) : (
        'NA'
      ),
  },
  {
    dataIndex: 'pub',
    title: 'Publisher',
    width: 150,
  },
  {
    dataIndex: 'timestamp',
    title: 'Timestamp',
    width: 30,
    sorter: true,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'win',
    title: 'Result',
    width: 60,
    sorter: true,
    render: (val) =>
      val ? <Tag color='success'>WON</Tag> : <Tag color='error'>LOST</Tag>,
  },
  {
    dataIndex: 'clearPrice',
    title: 'Clearing Price',
    width: 110,
    sorter: true,
    render: (val) => val || 'Pending',
  },
  {
    dataIndex: 'bidCount',
    title: 'Bidders',
    width: 110,
    render: (val) => val || 'NA',
  },
  {
    dataIndex: 'bidDensity',
    title: 'Density',
    width: 110,
    render: (val) => <Tag color='blue'>{val}</Tag>,
  },
]

export const spendColumns = [
  {
    dataIndex: 'takeRate',
    title: 'Take Rate',
    width: 100,
    sorter: true,
    render: (val) => <Text color='positive.base'>{formatPercent(val)}</Text>,
  },
  {
    dataIndex: 'revenuegross',
    title: 'Spend',
    width: 100,
    sorter: true,
    defaultSortOrder: 'descend',
    render: (val) => formatCurrency(val),
  },
]

export const expandedSpendColumns = [
  ...spendColumns,
  {
    dataIndex: 'revenue',
    title: '$ To Pub',
    width: 100,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
  {
    dataIndex: 'cpmGross',
    title: 'CPM Spent',
    width: 100,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
  {
    dataIndex: 'cpm',
    title: 'CPM To Pub',
    width: 100,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
  {
    dataIndex: 'auction_cpm_gross',
    title: 'Win Rate',
    width: 100,
    sorter: true,
    render: (val) => formatPercent(val),
  },
  {
    dataIndex: 'bidFloor',
    title: 'Bid Rate',
    width: 100,
    sorter: true,
    render: (val) => formatPercent(val),
  },
]

export const activeSupplyColumn = [
  {
    dataIndex: 'name',
    title: 'Publisher',
    width: 200,
  },
  {
    dataIndex: 'revenuegross',
    title: 'Spend',
    width: 100,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
  {
    dataIndex: 'revenue',
    title: '$ To Pub',
    width: 100,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
  {
    dataIndex: 'takeRate',
    title: 'Take Rate',
    width: 100,
    sorter: true,
    render: (val) => <Text color='positive.base'>{formatPercent(val)}</Text>,
  },
  {
    dataIndex: 'cpmGross',
    title: 'CPM Spent',
    width: 100,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
  {
    dataIndex: 'cpm',
    title: 'CPM To Pub',
    width: 100,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
]

export const inactiveSupplyColumn = [
  {
    dataIndex: 'name',
    title: 'Publisher',
    width: 180,
  },
  {
    dataIndex: 'videoFloor',
    title: 'Average Video Floor',
    width: 180,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
  {
    dataIndex: 'displayFloor',
    title: 'Average Display Floor',
    width: 180,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
]

export const topSpendColumns = [
  {
    dataIndex: 'takeRate',
    title: 'Take Rate',
    width: 100,
    sorter: true,
    render: (val) => formatPercent(val),
  },
  {
    dataIndex: 'revenuegross',
    title: 'Spend',
    width: 100,
    sorter: true,
    render: (val) => formatCurrency(val),
  },
]
