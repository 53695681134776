import React from 'react'
import PropTypes from 'prop-types'
import { Filter, Radio } from '@beachfront/ui'
import { VideoCameraOutlined } from '@beachfront/ui/icons'

import { MEDIA_TYPE } from '../../../enums'

const AccountDetailFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='mediaType'
        label='Media Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<VideoCameraOutlined />}
        formatTag={renderMediaTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {MEDIA_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key.toUpperCase()}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
    </Filter>
  )
}

function renderMediaTypeTag(desc) {
  const name = MEDIA_TYPE.fromKey(desc.value.toLowerCase())?.name
  return name ? `Media Type is ${name}` : 'All Media Types'
}

AccountDetailFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default AccountDetailFilter
