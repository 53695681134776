import React, { useEffect, useState } from 'react'
import { Box, Button, useToast } from '@beachfront/ui'
import { useNavigate } from 'react-router-dom'
import { Form } from '@beachfront/ui/forms'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { ACCOUNT_TYPE, TIMEZONE } from '../../../enums'
import { useUserData } from '../../../hooks'
import { isNotEmptyArray, getErrorMessage } from '../../../utils'
import { PageHeader, DirtyPrompt } from '../../../components'
import { validateCreateUserForm } from '../@utils'

import { GeneralSetting } from '.'

const CreateUser = ({ admin }) => {
  const navigate = useNavigate()
  const toast = useToast()
  const [user] = useUserData()
  const [userState, setUserState] = useState(null)
  const [accounts, setAccounts] = useState({})

  useEffect(() => {
    if (admin) {
      let params = {
        p: 0,
        s: 1000,
        sort: null,
        filters: {},
      }
      api.admin.account.getAll(params).then(
        (res) => {
          let data = res.data && res.data.data ? res.data.data : []
          if (isNotEmptyArray(data)) {
            data = data.filter((dt) => dt.type !== ACCOUNT_TYPE.PUBLISHER.key)
          }
          setAccounts({ data })
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
    }
  }, [])

  const onSubmit = (values, form) => {
    if (userState?.save) {
      let params = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        offset:
          values.timezone === TIMEZONE.CUSTOM.key ? Number(values.offset) : '',
        timezone: values.timezone,
        password: values.password,
        accountId: admin ? values.accountId : null,
        admin: !!values.admin,
        accountAdmin: !!user.admin,
      }

      if (admin) {
        params.dashboardUrl = values.dashboardUrl
        params.adhocUrl = values.adhocUrl
      }

      const request = admin ? api.admin.user.create : api.users.create
      return request(params).then(
        (res) => {
          if (userState?.newUser || userState?.save) {
            toast.success({ title: 'User updated.' })
          } else {
            toast.error({
              title: res.data.msg || 'User add to the selected account.',
            })
          }
          setTimeout(() => {
            form.reset()
            navigate('..', { relative: 'path' })
          }, 300)
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
    } else {
      let params = {
        email: values.email,
        accountId: admin ? values.accountId : '',
      }

      const request = admin ? api.admin.user.check : api.users.check

      return request(params).then(
        (res) => {
          let data = res.data
          if (data?.newUser || data?.showPassword) {
            setUserState((prevState) => ({
              ...prevState,
              newUser: data.newUser,
              showPassword: data.showPassword,
            }))
          }
          if (data.canCreate) {
            setUserState((prevState) => ({ ...prevState, save: true }))
            if (data.newUser) {
              toast.success({
                title: data.msg || 'User created. Please proceed to save.',
              })
            } else {
              toast.success({
                title: data.msg || 'User exists. Please proceed to save.',
              })
              form.change('firstName', data.firstName)
              form.change('lastName', data.lastName)
            }
          } else {
            toast.error({
              title: data.msg || 'Please contact admin.',
            })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
    }
  }

  const onEmailChange = (form) => {
    if (userState?.save) {
      setUserState(null)
      form.batch(() => {
        form.change('firstName', '')
        form.change('lastName', '')
      })
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ offset: '0' }}
      validate={(values) =>
        validateCreateUserForm(
          values,
          userState?.newUser,
          userState?.showPassword,
          admin
        )
      }
    >
      {({ handleSubmit, submitting, dirty, form }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Create User'
            actions={
              <>
                <Button onClick={() => form.reset()} disabled={!dirty}>
                  Reset
                </Button>
                <Button
                  type='primary'
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3} width={[1, 1, 2 / 3]}>
            <GeneralSetting
              userState={userState}
              admin={admin}
              accounts={accounts.data || []}
              onEmailChange={onEmailChange}
            />
          </Box>
        </form>
      )}
    </Form>
  )
}

CreateUser.propTypes = {
  admin: PropTypes.bool,
}

export default CreateUser
