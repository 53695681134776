import moment from 'moment'
import { Filter } from '@beachfront/ui'

import { DATE_RANGES } from '../../../enums'

/**
 * Formats a tag for date filters.
 *
 * @param   {Object} desc   The filter descriptor.
 * @param   {Object} opt    The filter option.
 * @returns {String}
 */
export const formatDateFilterTag = (desc, opt) => {
  if (Array.isArray(desc.value)) {
    const [start, end] = desc.value
    const range = Object.entries(DATE_RANGES).find(([, range]) => {
      const [from, to] = range ?? []
      return from.isSame(start, 'd') && to.isSame(end, 'd')
    })
    const name = range ? range[0] : 'Custom Dates'
    const operatorStr = range ? ' ' : ' between '
    return opt.label ? `${opt.label}${operatorStr}${name}` : name
  }

  const date = moment(desc.value)

  if (!date.isValid()) {
    return opt.label
  }

  const dateStr = date.format('MM/DD/YYYY')

  if (desc.operator === Filter.Operator.Any.EQUALS.key) {
    return `${opt.label} on ${dateStr}`
  }

  if (desc.operator === Filter.Operator.Date.BEFORE.key) {
    return `${opt.label} before ${dateStr}`
  }

  if (desc.operator === Filter.Operator.Date.AFTER.key) {
    return `${opt.label} after ${dateStr}`
  }

  return opt.label
}
