import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useSegmentWidgets = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['segment', 'widgets', params],
    queryFn: async () => {
      const { dateRange } = params
      const res = await api.segment.getWidgets({ range: dateRange })
      return res.data
    },
    staleTime: 60000,
    enabled,
  })
}
