import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import ReactGA from 'react-ga4'
import { Button, Flex, Input, Text } from '@beachfront/ui'
import { Field, useFormState } from '@beachfront/ui/forms'
import { useClipboard } from '@beachfront/ui/hooks'
import { CopyOutlined } from '@beachfront/ui/icons'

import ExpressionModal from '../expression-modal/expression-modal'
import { useBooleanSegment } from '../../booleanSegment/boolean-segment-context'

const ManageExpression = ({ params, formDisable }) => {
  const { values } = useFormState()
  const { copy, hasCopied } = useClipboard(values?.contentBooleanLogic?.value)
  const { setExpressionV2, stateHistory } = useBooleanSegment()
  const [isBuilderOpen, setIsBuilderOpen] = useState(false)

  return (
    <Flex flexDirection='column'>
      <Field
        name='contentBooleanLogic.value'
        label='Advanced Boolean Logic'
        extra={
          <Flex alignItems='center' gap={2}>
            <Button
              disabled={!values?.contentBooleanLogic.value || formDisable}
              icon={<CopyOutlined />}
              size='small'
              onClick={() => copy()}
            />
            {hasCopied ? <Text color='positive.base'>Copied...</Text> : null}
          </Flex>
        }
      >
        <Input.TextArea
          readOnly
          style={{ resize: 'none', pointerEvents: 'none' }}
          rows={3}
          disabled={formDisable}
        />
      </Field>
      <Button
        disabled={formDisable}
        onClick={() => {
          const expressionClient = cloneDeep(
            values.contentBooleanLogic?.client || []
          )
          setExpressionV2(expressionClient)
          stateHistory.current = [expressionClient]
          setIsBuilderOpen(true)
          ReactGA.event({
            category: 'content_boolean_logic',
            action: 'modal_open',
          })
        }}
      >
        Manage Expression
      </Button>
      <ExpressionModal
        isBuilderOpen={isBuilderOpen}
        setIsBuilderOpen={setIsBuilderOpen}
        params={params}
      />
    </Flex>
  )
}

ManageExpression.propTypes = {
  params: PropTypes.object,
  formDisable: PropTypes.bool,
}

export default ManageExpression
