import React, { useRef, useMemo } from 'react'
import { Box, Flex, Button, Table } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../client-api'
import { useContentSize, useFilters, useServerTable } from '../../hooks'
import { DATE_RANGES, MEDIA_TYPE } from '../../enums'
import { PageHeader, NetworkError } from '../../components'
import { localFilterKey } from '../../components/filter'

import { auctionLogColumns } from './@constants'
import { AuctionLogFilter, AuctionLogDetail, ExportModal } from './@components'

const AuctionLog = () => {
  const { contentWidth, contentHeight } = useContentSize()
  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.AUCTION_LOG, {
    initialState: [
      { dataIndex: 'date', operator: 'eq', value: DATE_RANGES['Today'] },
    ],
    schema: { date: 'date' },
  })

  const otherFilters = filters.populated.filter(
    (f) => !['date', 'mediaType', 'platform'].includes(f.dataIndex)
  )

  const requestPayload = useMemo(
    () => getRequestPayload(filters.populated),
    [filters.populated]
  )

  const table = useServerTable({
    queryKey: 'auction-log',
    queryFn: api.auction.logs,
    filters: otherFilters,
    payload: requestPayload,
    schema: {
      data: 'rows',
      total: 'total',
      fields: {
        timestamp: 'date',
      },
    },
    defaultSorter: { field: 'timestamp', order: 'descend' },
    defaultPageSize: 50,
    staleTime: 30000,
  })

  const scroll = {
    y: contentHeight - 260,
    x: contentWidth - 300,
  }

  if (!table.isFetching && table.error) {
    return (
      <NetworkError
        description='Something went wrong.'
        onAction={table.refetch}
      />
    )
  }

  const headerActions = (
    <>
      <ExportModal
        query={table.query}
        filters={table.serverFilters}
        payload={requestPayload}
      />
      <Button onClick={() => table.refetch()} icon={<ReloadOutlined />} />
    </>
  )

  const toolbar = () => (
    <Table.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <AuctionLogFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </Table.Toolbar>
  )

  const expandedRowRender = (props) => (
    <Box px={3}>
      <AuctionLogDetail {...props} />
    </Box>
  )

  return (
    <>
      <PageHeader title='Auction Log' actions={headerActions} />
      <Box mt={2}>
        <Table
          rowKey='id'
          emptyText='No Auction Logs'
          columns={auctionLogColumns}
          toolbar={toolbar}
          dataSource={table.data}
          loading={table.isFetching}
          pagination={{
            ...table.query.pagination,
            hideOnSinglePage: false,
            total: table.total,
          }}
          expandable={{ expandedRowRender }}
          scroll={scroll}
          filters={filters.populated}
          onFilter={(key) => filterRef.current?.addFilter(key)}
          onChange={(e) => {
            table.setPagination(e.pagination)
            table.setSorter(e.sorter)
          }}
        />
      </Box>
    </>
  )
}

const getRequestPayload = (filters) => {
  const dateRange = filters.find((f) => f.dataIndex === 'date')?.value
  const mediaType = filters.find((f) => f.dataIndex === 'mediaType')?.value
  const platform = filters.find((f) => f.dataIndex === 'platform')?.value

  const formatMediaType = (type) => {
    if (type === MEDIA_TYPE.VIDEO.key) {
      return '0'
    }
    if (type === MEDIA_TYPE.DISPLAY.key) {
      return '1'
    }
    return type
  }

  return {
    dateRange: {
      range: 'custom',
      startDate: dateRange?.[0].format('YYYY-MM-DD'),
      endDate: dateRange?.[1].format('YYYY-MM-DD'),
    },
    mediaType: formatMediaType(mediaType) ?? '',
    platform: platform ?? '',
  }
}

export default AuctionLog
