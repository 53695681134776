import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from '@beachfront/ui'

import { ACCOUNT_STATUS, ACCOUNT_TYPE, MARGIN_TYPE } from '../../../../enums'

export const accountColumns = [
  {
    dataIndex: 'name',
    title: 'Name',
    fixed: 'left',
    width: 250,
    sorter: true,
    filter: true,
    render: (val, rec) => <Link to={`${rec.accountId}`}>{val}</Link>,
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: 200,
    sorter: true,
    filter: true,
    render: (key) => {
      if (key === ACCOUNT_STATUS.ACTIVE.key) {
        return <Badge status='success' text={ACCOUNT_STATUS.ACTIVE.name} />
      }
      if (key === ACCOUNT_STATUS.INACTIVE.key) {
        return <Badge status='error' text={ACCOUNT_STATUS.INACTIVE.name} />
      }
      return null
    },
  },
  {
    dataIndex: 'type',
    title: 'Type',
    width: 200,
    sorter: true,
    filter: true,
    render: (key) => ACCOUNT_TYPE.fromKey(key)?.name,
  },
  {
    dataIndex: 'bfmMarginValue',
    title: 'Margin',
    width: 140,
    render: (val, rec) => {
      if (!rec.bfmMarginEnabled || !rec.bfmMarginType) {
        return '--'
      }
      if (rec.bfmMarginType === MARGIN_TYPE.FLAT.key) {
        return '$' + val
      }
      if (rec.bfmMarginType === MARGIN_TYPE.PERCENT.key) {
        return val + '%'
      }
      return val
    },
  },
  {
    dataIndex: 'demoAccount',
    title: 'Demo',
    width: 140,
    render: (val) => (val ? 'Yes' : 'No'),
  },
]
