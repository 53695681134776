import { isUndefined, isNull, isObject, isArray } from './type-util'

/**
 * Resolves an object property from a string representing the path in dot notation.
 *
 * @param   {Object} source
 * @param   {String} path
 * @param   {*}      [defaultValue]
 * @returns {*}
 */
export function resolveProp(source, path, defaultValue) {
  let parts = isArray(path) ? path.slice() : String(path).split('.')
  let prop = parts.shift()
  let result = source

  while (prop) {
    if (isUndefined(result[prop]) || isNull(result[prop])) {
      return defaultValue
    } else {
      result = result[prop]
    }

    prop = parts.shift()
  }

  return result
}

/**
 * Deep merges a target object by copying the values of all enumerable own properties from
 * one or more source objects to the target object. Properties in the target object will
 * be overwritten by properties in the sources if they have the same key. Sources are applied
 * from left to right in the arguments list.
 *
 * @param   {Object}    target      The target object.
 * @param   {...Object} [sources]   One or more source objects to merge.
 * @returns {Object}
 */
export function merge(target, ...sources) {
  sources.forEach((source) => {
    for (let key in source) {
      if (Object.hasOwn(source, key)) {
        if (
          isObject(target[key]) &&
          !isArray(target[key]) &&
          isObject(source[key]) &&
          !isArray(source[key])
        ) {
          target[key] = merge({}, target[key], source[key])
        } else {
          target[key] = source[key]
        }
      }
    }
  })

  return target
}

/**
 * Gets the error message from a response.
 *
 * @param   {Object} error
 * @returns {String}
 */
export function getErrorMessage(error) {
  if (error.response?.data?.errors?.length) {
    return error.response.data.errors.map((e) => e.defaultMessage).join('\n')
  }
  return error.message ?? 'Something went wrong. Please try again.'
}

/**
 * Creates a unique ID.
 *
 * @returns {String}
 */
export function getUniqueId() {
  return Math.random().toString(36).substring(2)
}

/**
 * Creates a shallow copy of an object excluding the given keys.
 *
 * @param   {Object} obj
 * @param   {Array}  keys
 * @returns {Object}
 */
export function omit(obj, keys) {
  const result = { ...obj }
  keys.forEach((key) => {
    delete result[key]
  })
  return result
}
