import React from 'react'
import { Box } from '@beachfront/ui'

export const getReportTooltip = (type, dimension) => {
  switch (type) {
    case 'shared':
      return <Box>Enable to share the report</Box>
    case 'schedule':
      return <Box>Enable to schedule the report</Box>
    case 'frequency':
      return <Box>Select the report schedule frequency</Box>
    case 'hours':
      return <Box>Select the hour of day to receive the report</Box>
    case 'email':
      return <Box>Enter the emails to receive the report</Box>
    case 'dimensions':
      return <Box>Select multiple report keys</Box>
    case 'measures':
      return <Box>Select multiple metrics</Box>
    case 'filter':
      return <Box>Select a dimension to filter the report</Box>
    case 'operation':
      return <Box>Select operation to apply filter</Box>
    case 'value':
      return (
        <Box>
          {dimension === 'dealid'
            ? 'Enter multiple values separated by spaces'
            : 'Select multiple values to apply filter'}
        </Box>
      )
    default:
      break
  }
}
