import React from 'react'

import { Card, Flex, Switch } from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'

const ToggleSetting = () => {
  return (
    <Card title='Feature Toggles' height='100%'>
      <Flex flexDirection='column'>
        <Field
          inline
          name='featureToggles.audienceBooleanLogic'
          label='Boolean Logic'
        >
          <Switch />
        </Field>
        <Field
          inline
          name='featureToggles.contentBooleanLogic'
          label='Content Boolean Logic'
        >
          <Switch />
        </Field>
        <Field
          inline
          name='featureToggles.appOverrideAccess'
          label='App Override'
        >
          <Switch />
        </Field>
        <Field
          inline
          name='featureToggles.bidstreamDataConfiguration'
          label='Bidstream Configuration'
        >
          <Switch />
        </Field>
      </Flex>
    </Card>
  )
}

export default ToggleSetting
