import React from 'react'
import { Box, Heading, Flex } from '@beachfront/ui'

import { HelpCenterCard, HelpCenterHeader } from './@components'
import { helpConfig } from './@utils'

const HelpCenter = () => {
  return (
    <>
      <HelpCenterHeader />
      <Flex mt={4} justifyContent='center'>
        <Heading m={0} level={2}>
          How can we help?
        </Heading>
      </Flex>
      <Flex justifyContent='center' mt={4}>
        <Flex
          flexWrap='wrap'
          justifyContent='center'
          gap={2}
          width={['100%', '74%']}
        >
          {helpConfig.map((cf) => (
            <Box
              key={cf.id}
              boxSizing='border-box'
              flexBasis={{
                base: '100%',
                sm: '100%',
                md: 'calc(74% - 12px)',
                lg: 'calc(50% - 12px)',
              }}
              maxWidth={{
                base: '100%',
                sm: '100%',
                md: 'calc(74% - 12px)',
                lg: 'calc(50% - 12px)',
              }}
            >
              <HelpCenterCard {...cf} />
            </Box>
          ))}
        </Flex>
      </Flex>
    </>
  )
}

export default HelpCenter
