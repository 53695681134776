import { useEffect, useRef } from 'react'
import { Box, Flex, Switch, Table, useToast } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import RestrictedPublishersFilter from './restricted-publishers-filter/restricted-publishers-filter'
import { api } from '../../../client-api'
import { PageHeader } from '../../../components'
import { localFilterKey } from '../../../components/filter'
import { useFilters } from '../../../hooks'

const RestrictedPublishers = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [filters, setFilters] = useFilters(localFilterKey.RESTRICTED_PUBLISHERS)
  const filterRef = useRef()
  const { data: publishersData, isLoading } = useQuery({
    queryKey: ['restricted-publishers'],
    queryFn: async () => {
      const { data } = await api.admin.publisherMapping.getAll()
      return data
    },
  })

  const table = useTableQuery({
    data: publishersData?.data || [],
    defaultSorter: {
      field: 'company',
      order: 'ascend',
    },
  })

  const COLUMNS = [
    {
      dataIndex: 'company',
      title: 'Company',
      sorter: true,
      filter: true,
    },
    {
      align: 'right',
      dataIndex: 'premium',
      filter: true,
      title: 'Premium',
      width: 125,
      render: (val, { publisherId }) => (
        <Switch
          checked={val}
          onChange={() => putMutation.mutate(publisherId)}
          size='small'
        />
      ),
    },
  ]

  const putMutation = useMutation({
    mutationFn: (publisherId) => {
      return api.admin.publisherMapping.toggle(publisherId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['restricted-publishers'] })
    },
    onError: (error, text) => {
      toast.error({
        title: 'Cannot process the request at this time.',
      })
    },
  })

  const toolbar = () => (
    <Table.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <RestrictedPublishersFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </Table.Toolbar>
  )

  useEffect(() => {
    table.setFilters(filters.populated)
  }, [filters.populated])

  return (
    <>
      <PageHeader title='Admin - Restricted Publishers' />
      <Box mt={2}>
        <Table
          columns={COLUMNS}
          dataSource={table.data}
          emptyText='No Publishers'
          filters={filters.populated}
          loading={isLoading}
          onChange={(e) => {
            table.setPagination(e.pagination)
            table.setSorter(e.sorter)
            table.setSelectedRowKeys([])
          }}
          onFilter={(key) => filterRef.current?.addFilter(key)}
          pagination={{
            ...table.query.pagination,
            hideOnSinglePage: false,
            total: table.total,
          }}
          rowKey='publisherId'
          toolbar={toolbar}
        />
      </Box>
    </>
  )
}

export default RestrictedPublishers
