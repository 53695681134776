import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import { useQuery } from '@tanstack/react-query'

import { api } from '../../../client-api'
import { isNotEmptyObject, getErrorMessage } from '../../../utils'
import { PageHeader, CommonSpinner } from '../../../components'

import { SystemMessageForm } from './@components'
import { validateSystemMessages } from './@utils'

let INITIAL_VALUES = {
  platform: import.meta.env.MODE.toUpperCase(),
  expiration: moment(),
  message: '',
  enabled: false,
  type: 'info',
}

const SystemMessages = () => {
  const toast = useToast()
  const [isEnable, setEnable] = useState(true)

  const params = {
    platform: import.meta.env.MODE.toUpperCase(),
    admin: true,
  }

  const {
    data: configData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['system-messages', params],
    queryFn: async () => {
      const { data } = await api.admin.systemMessages.get(params)
      return data
    },
  })

  // Refetch if a flag is set in sessionStorage after reload
  useEffect(() => {
    if (sessionStorage.getItem('reloadAndFetch')) {
      refetch()
      sessionStorage.removeItem('reloadAndFetch')
    }
  }, [refetch])

  INITIAL_VALUES = useMemo(() => {
    let values = { ...INITIAL_VALUES }
    if (isNotEmptyObject(configData)) {
      values.enabled = configData.enabled
      values.message = configData.message ?? ''
      values.expiration = configData.expiration
        ? moment(configData.expiration, 'YYYY-MM-DD HH:mm:ss')
        : moment()
      values.platform =
        configData.platform ?? import.meta.env.MODE.toUpperCase()
      values.type = configData.type ?? 'info'
    }
    setEnable(values.enabled)
    return values
  }, [configData])

  const onSubmit = (vals) => {
    const params = {
      ...vals,
      expiration: moment(vals.expiration).format('YYYY-MM-DD HH:mm:ss'),
    }

    const data = configData?.messageExists
      ? { id: configData?.id, admin: true }
      : { admin: true }

    const request = configData?.messageExists
      ? api.admin.systemMessages.update(data, params)
      : api.admin.systemMessages.create(data, params)

    return request.then(
      (res) => {
        toast.success({
          title:
            res.data.msg ||
            (configData?.messageExists
              ? 'System message updated successfully'
              : 'System message saved successfully'),
        })
        sessionStorage.setItem('reloadAndFetch', 'true')
        window.location.reload()
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  return (
    <Form
      initialValues={INITIAL_VALUES}
      validate={validateSystemMessages}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, dirty, submitting }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Admin - System Messages'
            actions={
              <>
                <Button
                  disabled={
                    !dirty ||
                    submitting ||
                    (!configData?.messageExists && !isEnable)
                  }
                  loading={submitting}
                  htmlType='submit'
                  type='primary'
                >
                  {configData?.messageExists ? 'Update' : 'Save'}
                </Button>
              </>
            }
          />
          {isLoading ? (
            <CommonSpinner />
          ) : (
            <Box mt={3} width={[1, 1, 2 / 3]}>
              <SystemMessageForm onEnableChange={setEnable} />
            </Box>
          )}
        </form>
      )}
    </Form>
  )
}

export default SystemMessages
