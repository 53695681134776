import React from 'react'

import { MEDIA_LIST_TYPE } from '../../enums'
import { localFilterKey } from '../../components/filter'

import { default as MediaLists } from './media-lists'

const DomainMediaList = () => {
  return (
    <MediaLists
      title='Media Lists - Domains'
      mediaListType={MEDIA_LIST_TYPE.DOMAIN.key}
      filterKey={localFilterKey.DOMAIN_MEDIA_LISTS}
    />
  )
}

export default DomainMediaList
