import React from 'react'
import PropTypes from 'prop-types'

import { SelectField } from '../form-fields'
import { TIMEZONE } from '../../enums'

const ALL_TIMEZONES = [
  TIMEZONE.UTC,
  TIMEZONE.EST,
  TIMEZONE.CST,
  TIMEZONE.MST,
  TIMEZONE.PST,
]

const TimezoneSelect = ({ data, value, onChange, ...rest }) => {
  return (
    <SelectField
      data={data ?? ALL_TIMEZONES}
      value={value}
      onChange={onChange}
      keyField='key'
      textField='name'
      placeholder='Select a timezone'
      {...rest}
    />
  )
}

TimezoneSelect.propTypes = {
  data: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default TimezoneSelect
