import { useQuery } from '@tanstack/react-query'

import { http } from '../../client-api'

export const useTargetingInsight = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['targeting', 'insight', params],
    queryFn: async () => {
      const { widget, targetingParams, timezone } = params
      const url = widget.apiUrl
      const payload = String(url).includes('timezone=') ? {} : { timezone }
      const request =
        widget.methodType === 'POST'
          ? http.post(url, targetingParams, { params: payload })
          : http.get(url, { params: payload })

      const res = await request
      return res.data?.data
    },
    staleTime: 60000,
    enabled,
  })
}
