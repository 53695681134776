import React, { useEffect, useState } from 'react'
import { Box, Flex, FieldControl, Input, Text } from '@beachfront/ui'

import { PopoverFilter } from '..'
import ProviderFilterTitle from '../provider-filter-title/provider-filter-title'
import { useBooleanSegment } from '../booleanSegment/boolean-segment-context'
import { useProviderTypes } from '../../../../hooks'

const SegmentFilters = ({ activeTab, providers }) => {
  const [searchValue, setSearchValue] = useState('')
  const [providerFilterCategory, setProviderFilterCategory] = useState('all')
  const {
    activeParentProvider,
    appliedProviders,
    appliedTypes,
    params,
    setAppliedTypes,
    setAppliedProviders,
    setParams,
  } = useBooleanSegment()

  const [hasError, setHasError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { types, isFetching: isTypeFetching } = useProviderTypes(activeTab)
  const filteredProviders = providers
    .filter((provider) => {
      if (providerFilterCategory.toLowerCase() === 'all') {
        return true
      }

      return (
        providerFilterCategory.toLowerCase() ===
        provider.parentProvider?.toLowerCase()
      )
    })
    .filter((provider) => {
      if (provider.key === 'ALL') {
        return true
      }

      if (activeTab === 'owned') {
        return provider.owned
      }

      if (activeTab === 'shared') {
        return !provider.owned
      }

      return false
    })

  const onSearch = (val) => {
    // REset fields
    setHasError(false)
    setErrorMsg('')

    const trimmed = val.trim()

    setParams({ ...params, q: trimmed, p: 0 })
  }

  useEffect(() => {
    setAppliedProviders(['ALL'])
  }, [activeTab])

  return (
    <Flex gap={2} mb={3}>
      <Box flexGrow={1}>
        <FieldControl
          validateStatus={hasError ? 'error' : null}
          help={hasError ? errorMsg : null}
          mb={0}
        >
          <Input.Search
            allowClear
            onChange={(evt) => {
              setSearchValue(evt.target.value)
            }}
            onSearch={(val) => onSearch(val)}
            placeholder='Search Segments'
            value={searchValue}
          />
        </FieldControl>
      </Box>
      {providers.length > 0 ? (
        <PopoverFilter
          activeParent={activeParentProvider}
          applied={appliedProviders}
          onApply={setAppliedProviders}
          label='Provider'
          providers={filteredProviders}
          title={
            <ProviderFilterTitle
              providerFilterCategory={providerFilterCategory}
              setProviderFilterCategory={setProviderFilterCategory}
              filteredProviders={filteredProviders}
            />
          }
        />
      ) : null}
      <PopoverFilter
        applied={appliedTypes}
        activeTab={activeTab}
        label='Type'
        onApply={setAppliedTypes}
        isLoading={isTypeFetching}
        providers={types}
        title={
          <Flex>
            <Text>Filter by Type</Text>
          </Flex>
        }
      />
    </Flex>
  )
}

export default SegmentFilters
