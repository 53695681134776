import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter, Radio, Box } from '@beachfront/ui'
import {
  TagsOutlined,
  DollarCircleOutlined,
  CalendarOutlined,
} from '@beachfront/ui/icons'

import { SEGMENT_TYPE, SEGMENT_STATUS } from '../../../../enums'
import { SelectField } from '../../../../components'
import {
  CompareFilterPanel,
  DateFilterPanel,
  formatNumberTag,
  formatDateFilterTag,
} from '../../../../components/filter'

const SegmentFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='status'
        label='Status'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<TagsOutlined />}
        formatTag={renderStatusTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {SEGMENT_STATUS.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='dataType'
        label='Segment Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<TagsOutlined />}
        formatTag={renderSegmentTypeTag}
        renderPanel={({ value, setValue }) => (
          <Box width={300}>
            <SelectField
              value={value}
              onChange={setValue}
              options={SEGMENT_TYPE.values().map(({ key, name }) => ({
                value: key,
                label: name,
              }))}
              placeholder='Select Segment Type'
              optionFilterProp='label'
              showSearch
            />
          </Box>
        )}
      />
      <Filter.Option
        dataIndex='price'
        label='Price (CPM)'
        defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
        defaultValue={0}
        icon={<DollarCircleOutlined />}
        formatTag={formatNumberTag}
        renderPanel={(props) => (
          <CompareFilterPanel
            {...props}
            allowEquals={false}
            min={0}
            step={0.01}
          />
        )}
      />
      <Filter.Option
        dataIndex='createDate'
        label='Uploaded'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='updateDate'
        label='Last Updated'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
    </Filter>
  )
}

const renderStatusTag = (desc) => {
  const name = SEGMENT_STATUS.fromKey(desc.value)?.name
  return name ? `Status is ${name}` : 'Any Status'
}

const renderSegmentTypeTag = (desc) => {
  const name = SEGMENT_TYPE.fromKey(desc.value)?.name
  return name ? `Segment Type is ${name}` : 'All Segment Types'
}

SegmentFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default SegmentFilter
