import React from 'react'
import PropTypes from 'prop-types'
import {
  Flex,
  Box,
  Text,
  Card,
  ScrollArea,
  Empty,
  Progress,
} from '@beachfront/ui'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'
import { useTheme } from 'styled-components'

import {
  getChartColors,
  isNotEmptyArray,
  roundWithPrecision,
} from '../../utils'

const RADIAN = Math.PI / 180

export const PieChartWidget = ({
  data,
  title,
  colors,
  details,
  config,
  cardBodyHeight,
  ...rest
}) => {
  const theme = useTheme()
  const chartColors = colors ?? getChartColors(theme.colors)

  return (
    <Card
      title={title}
      bodyStyle={{ height: cardBodyHeight ?? '300px' }}
      {...rest}
    >
      {isNotEmptyArray(data) ? (
        <Flex gap={2} height='100%' fontFamily='secondary'>
          <Box width={details ? '45%' : '100%'}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={data}
                  nameKey='label'
                  dataKey='value'
                  paddingAngle={0}
                  labelLine={false}
                  label={config?.showLabel ? <CustomLabel /> : undefined}
                  isAnimationActive={false}
                >
                  {data.map((dt, i) => (
                    <Cell
                      key={i}
                      fill={chartColors[i % chartColors.length]}
                      stroke={chartColors[i % chartColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  isAnimationActive={false}
                  wrapperStyle={{ zIndex: 1 }}
                  content={<CustomTooltip />}
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>
          {details ? (
            <ScrollArea width='55%'>
              <Flex gap={1} flexDirection='column'>
                {details.map((chart, i) => (
                  <DetailBox
                    key={i}
                    data={chart}
                    color={chartColors[i % chartColors.length]}
                  />
                ))}
              </Flex>
            </ScrollArea>
          ) : null}
        </Flex>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No chart data found'
        />
      )}
    </Card>
  )
}

PieChartWidget.propTypes = {
  data: PropTypes.array,
  title: PropTypes.node,
  colors: PropTypes.array,
  details: PropTypes.array,
  config: PropTypes.object,
  cardBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const CustomTooltip = ({ payload, active }) => {
  if (!active || !payload || payload.length === 0) {
    return null
  }

  return (
    <Box
      p={2}
      border='base'
      borderColor='popover.border'
      borderRadius='base'
      boxShadow='base'
      bg='popover.bg'
    >
      <Flex gap={2} fontSize={1} alignItems='center'>
        <Box
          size={8}
          borderRadius='50%'
          flex='none'
          bg={payload[0].payload?.fill}
        />
        <Text>
          {payload[0].name}
          {' : '}
          {payload[0].payload?.valueS}
        </Text>
      </Flex>
    </Box>
  )
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  active: PropTypes.bool,
}

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
      style={{ pointerEvents: 'none' }}
    >
      {`${roundWithPrecision(percent * 100, 2)}%`}
    </text>
  )
}

CustomLabel.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  percent: PropTypes.number,
}

const DetailBox = ({ data, color }) => {
  const percent = roundWithPrecision(Math.min(data.progress, 100), 2)

  return (
    <Flex
      px={2}
      py={1}
      gap={2}
      flexDirection='column'
      width='100%'
      borderRadius='base'
      bg='gray.1'
    >
      <Flex gap={2} fontSize={1} alignItems='center'>
        <Box size={8} borderRadius='50%' flex='none' bg={color} />
        <Text title={data.label} ellipsis>
          {data.label}
        </Text>
        <Text ml='auto' title={data.valueS} ellipsis>
          {data.valueS}
        </Text>
      </Flex>
      <Progress
        value={percent}
        indicatorColor={color}
        trackColor='gray.3'
        size='small'
        showInfo
      />
    </Flex>
  )
}

DetailBox.propTypes = {
  data: PropTypes.object,
  color: PropTypes.string,
}
