export default [
  {
    path: 'dashboard',
    title: 'Dashboard',
    key: 'dashboard',
  },
  {
    path: 'general',
    title: 'Settings',
    key: 'general',
  },
  {
    path: 'delivery',
    title: 'Delivery',
    key: 'delivery',
  },
  {
    path: 'configuration',
    title: 'Bidstream Data Configuration',
    key: 'configuration',
  },
  {
    path: 'appinfooverride',
    title: 'App Info Override',
    key: 'appinfooverride',
  },
  {
    path: 'history',
    title: 'History',
    key: 'history',
  },
]
