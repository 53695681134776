import React from 'react'
import { Flex, Box, Button, InputNumber, Modal, useToast } from '@beachfront/ui'
import { DownloadOutlined } from '@beachfront/ui/icons'
import { Form, Field } from '@beachfront/ui/forms'
import { useDisclosure } from '@beachfront/ui/hooks'

import { api } from '../../../client-api'
import { isNotEmptyObject } from '../../../utils'

const ExportModal = ({ query, filters, payload }) => {
  const toast = useToast()
  const modal = useDisclosure()

  const initialValues = {
    rows: 10,
  }

  const validate = (values) => {
    const errors = {}

    if (!values.rows) {
      errors.rows = 'Required'
    }

    if (values.rows < 10) {
      errors.rows = 'The number of rows should be greater than or equal to 10.'
    }

    if (values.rows > 5000) {
      errors.rows = 'The number of rows should be less than or equal to 5000.'
    }

    return errors
  }

  const downloadFile = (data) => {
    const csvContent = 'data:text/csv;charset=utf-8,' + data
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'auction-log.csv')
    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link)
  }

  const onSubmit = (values) => {
    return api.auction
      .export({
        p: query.pagination.current - 1,
        s: values.rows,
        sort: query.sorter?.field ? query.sorter : null,
        filters: filters,
        ...payload,
      })
      .then(
        (res) => {
          downloadFile(res.data)
          toast.success({ title: 'Successfully downloaded.' })
          modal.close()
        },
        () => {
          toast.error({ title: 'Unable to export data. Please contact admin.' })
        }
      )
  }

  return (
    <>
      <Button
        icon={<DownloadOutlined />}
        disabled={!isNotEmptyObject(query)}
        onClick={modal.open}
      >
        Export
      </Button>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (
          <Modal
            centered
            title='CSV Export'
            width='540px'
            visible={modal.isOpen}
            onCancel={modal.close}
            cancelButtonProps={{ disabled: submitting }}
            okButtonProps={{ loading: submitting }}
            okText='Submit'
            onOk={handleSubmit}
          >
            <Flex gap={2} minHeight={100} flexDirection='column'>
              <Box>Please enter the number of rows you want to export.</Box>
              <Field name='rows' mb={0}>
                <InputNumber min={0} />
              </Field>
            </Flex>
          </Modal>
        )}
      </Form>
    </>
  )
}

export default ExportModal
