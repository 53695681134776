import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Avatar, Badge } from '@beachfront/ui'
import { AuditOutlined } from '@beachfront/ui/icons'

import { MEDIA_TYPE } from '../../../enums'
import { formatDate } from '../../../utils'

export const featuredDealColumns = [
  {
    dataIndex: 'title',
    title: 'Name',
    fixed: 'left',
    sorter: true,
    filter: true,
    width: 250,
    render: (val, rec) => (
      <Flex gap={2} width={1} alignItems='center'>
        <Avatar
          src={rec.icon}
          fallback={<AuditOutlined />}
          alt='Featured Deal Icon'
          bg='transparent'
          color='gray.5'
          shape='square'
          size={40}
        />
        <Link to={`${rec.id}`}>{val}</Link>
      </Flex>
    ),
  },
  {
    dataIndex: 'status',
    title: 'Status',
    sorter: true,
    filter: true,
    width: 140,
    render: (val) =>
      val ? (
        <Badge status='success' text='Enabled' />
      ) : (
        <Badge status='error' text='Disabled' />
      ),
  },
  {
    dataIndex: 'mediaType',
    title: 'Media Type',
    sorter: true,
    filter: true,
    width: 140,
    render: (key) => MEDIA_TYPE.fromKey(key.toLowerCase())?.name,
  },
  {
    dataIndex: 'createDate',
    title: 'Created',
    sorter: true,
    filter: true,
    configurable: true,
    width: 200,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'updateDate',
    title: 'Last Updated',
    sorter: true,
    filter: true,
    configurable: true,
    width: 200,
    render: (val) => formatDate(val),
  },
]
