import React, { useState, useRef } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Flex, Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../client-api'
import { getErrorMessage } from '../../utils'
import { useContentSize, useFilters, useInfiniteTable } from '../../hooks'
import { localFilterKey } from '../../components/filter'
import {
  PageHeader,
  PageSearch,
  InfiniteTable,
  NetworkError,
} from '../../components'

import { dealLibraryColumns } from './@constants'
import {
  DealLibraryFilter,
  DealFeatureFilter,
  DealFeatureList,
} from './@components'

const DealLibrary = () => {
  const { contentHeight } = useContentSize()
  const queryClient = useQueryClient()
  const [searchQuery, setSearchQuery] = useState('')

  const [featureFilters, setFeatureFilters] = useFilters(
    localFilterKey.DEAL_LIBRARY_FEATURED
  )

  const filterRef = useRef()
  const [tableFilters, setTableFilters] = useFilters(
    localFilterKey.DEAL_LIBRARY
  )

  const table = useInfiniteTable({
    queryKey: ['deal-library'],
    queryFn: api.deal.library.list,
    filters: tableFilters.populated,
    params: {
      range: 'today',
      q: searchQuery,
    },
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 260,
  }

  const onRefetch = () => {
    table.refetch()
    queryClient.invalidateQueries({ queryKey: ['featured-deals'] })
  }

  const headerActions = (
    <>
      <PageSearch value={searchQuery} onChange={setSearchQuery} />
      <Button onClick={onRefetch} icon={<ReloadOutlined />} />
    </>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <DealLibraryFilter
          filterRef={filterRef}
          filters={tableFilters.all}
          setFilters={setTableFilters}
        />
      </Flex>
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title='Deal Library' actions={headerActions} />
      <Box my={3}>
        <DealFeatureFilter
          filters={featureFilters.all}
          setFilters={setFeatureFilters}
        />
      </Box>
      <Box mb={4}>
        <DealFeatureList
          filters={featureFilters.populated}
          searchQuery={searchQuery}
        />
      </Box>
      {!table.isFetching && table.error ? (
        <NetworkError
          description={getErrorMessage(table.error)}
          onAction={table.refetch}
        />
      ) : (
        <InfiniteTable
          rowKey='id'
          emptyText='No Deals Found'
          columns={dealLibraryColumns}
          toolbar={toolbar}
          scroll={scroll}
          filters={tableFilters.populated}
          onFilter={(key) => filterRef.current?.addFilter(key)}
          {...table.props}
        />
      )}
    </>
  )
}

export default DealLibrary
