import React from 'react'
import { Link } from 'react-router-dom'
import { Result, Button } from '@beachfront/ui'
import PropTypes from 'prop-types'

import { useUserData } from '../../hooks'
import { getDefaultRoute } from '../app'
import { isNonEmptyString } from '../../utils'

const PermissionError = ({ customPath, customTitle }) => {
  const [user] = useUserData()
  const { path, title } = getDefaultRoute(user)

  return (
    <Result
      py={5}
      status='error'
      title='Permission Error'
      description="You don't have permission to access this page."
    >
      <Link to={isNonEmptyString(customPath) ? customPath : path}>
        <Button>
          Go to {isNonEmptyString(customTitle) ? customTitle : title}
        </Button>
      </Link>
    </Result>
  )
}

PermissionError.defaultProps = {
  customPath: '',
  customTitle: '',
}

PermissionError.propTypes = {
  customPath: PropTypes.string,
  customTitle: PropTypes.string,
}

export default PermissionError
