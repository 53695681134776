import React from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { Flex, Button } from '@beachfront/ui'
import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
} from '@beachfront/ui/icons'

const ActionColumnHeader = ({ columns, data, expanded, onExpandChange }) => {
  return (
    <Flex gap={2} justifyContent='flex-end'>
      {!expanded ? (
        <CSVLink
          data={data}
          filename={`${columns[0].title}.csv`}
          headers={getCsvHeader(columns)}
          uFEFF={false}
        >
          <Button
            size='small'
            icon={<DownloadOutlined />}
            style={{ flex: 'none' }}
          />
        </CSVLink>
      ) : null}
      <Button
        size='small'
        onClick={onExpandChange}
        icon={expanded ? <LeftOutlined /> : <RightOutlined />}
        style={{ flex: 'none' }}
      />
    </Flex>
  )
}

const getCsvHeader = (cols) => {
  return cols.map((col) => ({ key: col.dataIndex, label: col.title }))
}

ActionColumnHeader.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
}

export default ActionColumnHeader
