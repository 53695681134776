import React from 'react'
import PropTypes from 'prop-types'
import { Field } from '@beachfront/ui/forms'
import { Radio } from '@beachfront/ui'

const IncludeExcludeField = ({ name, disabled, onChange }) => {
  return (
    <Field name={name} mb={0}>
      <Radio.Group disabled={disabled} onChange={onChange}>
        <Radio value='INCLUDE'>Include</Radio>
        <Radio value='EXCLUDE'>Exclude</Radio>
      </Radio.Group>
    </Field>
  )
}

IncludeExcludeField.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default IncludeExcludeField
