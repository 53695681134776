import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Text } from '@beachfront/ui'

export const CustomTooltip = ({ payload, active, label, unit }) => {
  if (!active || !payload || payload.length === 0) {
    return null
  }

  const formatValue = (dt, index) => {
    const opt = Number.isInteger(Number(dt.value))
      ? {}
      : { minimumFractionDigits: 2, maximumFractionDigits: 2 }

    const currentUnit = Array.isArray(unit) ? unit[index] : unit
    let str = new Intl.NumberFormat('en-US', opt).format(dt.value)

    if (currentUnit) {
      if (currentUnit === '$') {
        str = currentUnit + str
      } else {
        str = str + currentUnit
      }
    }

    if (dt.prefix) {
      str = dt.prefix + ' ' + str
    } else if (dt.suffix) {
      str = str + ' ' + dt.suffix
    }

    return str
  }

  return (
    <Box
      p={2}
      border='base'
      borderColor='popover.border'
      borderRadius='base'
      boxShadow='base'
      bg='popover.bg'
      style={{ maxWidth: 240 }}
    >
      <Box ml={3}>
        <Text width={1} overflowWrap='break-word'>
          {label}
        </Text>
      </Box>
      {payload.map((dt, i) => (
        <Flex key={dt.dataKey} gap={2} fontSize={1} alignItems='center'>
          <Box
            size={8}
            borderRadius='50%'
            flex='none'
            bg={dt.stroke ?? dt.fill}
          />
          <Text>
            {`${dt.name} : `}
            {formatValue(dt, i)}
          </Text>
        </Flex>
      ))}
    </Box>
  )
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  active: PropTypes.bool,
  label: PropTypes.node,
  unit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}
