/**
 * Rounds a value to the given precision.
 *
 * @param   {Number} value
 * @param   {Number} precision
 * @returns {Number}
 */
export function roundWithPrecision(value, precision) {
  const p = 10 ** precision
  return Math.round((value + Number.EPSILON) * p) / p
}

/**
 * Rounds a value with a variable number of significant figures.
 *
 * @param   {Number} value
 * @returns {Number}
 */
export const roundWithSignificance = (value) => {
  if (value < 10) {
    return roundWithPrecision(value, 2)
  }
  if (value < 100) {
    return roundWithPrecision(value, 1)
  }
  return roundWithPrecision(value, 0)
}
