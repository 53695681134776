import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@beachfront/ui'
import { useFormState } from '@beachfront/ui/forms'

import { api } from '../../../../../client-api'
import { useUserData } from '../../../../../hooks'
import { VirtualSelectField, IncludeExcludeField } from '../..'

const SimpleOptions = ({
  id,
  formDisable,
  initialValues,
  params,
  getPopupContainer,
}) => {
  const [user] = useUserData()
  const { values } = useFormState()

  const sharedProps = {
    position: 'absolute',
    height: '32px',
    right: '-7px',
    top: '-7px',
    className: 'customize-radio',
  }

  return (
    <Grid columns={1}>
      <Box position='relative'>
        {!user.mediaPlanExclude ? (
          <Box {...sharedProps}>
            <IncludeExcludeField name='excludeChannel' disabled={formDisable} />
          </Box>
        ) : null}
        <VirtualSelectField
          mode='multiple'
          allowClear
          detail={id}
          request={api.common.channel}
          apiParams={params}
          values={values}
          initialValues={initialValues}
          disabled={formDisable}
          getPopupContainer={getPopupContainer}
          emptyMsg='No channel Available'
          form={{
            name: 'channel',
            keyField: 'key',
            textField: 'name',
            placeholder: 'Select Channel',
            selectionName: 'channelObj',
            label: 'Channel',
          }}
        />
      </Box>
      <Box position='relative'>
        {!user.mediaPlanExclude ? (
          <Box {...sharedProps}>
            <IncludeExcludeField name='excludeNetwork' disabled={formDisable} />
          </Box>
        ) : null}
        <VirtualSelectField
          mode='multiple'
          allowClear
          detail={id}
          request={api.common.network}
          apiParams={params}
          values={values}
          initialValues={initialValues}
          disabled={formDisable}
          getPopupContainer={getPopupContainer}
          emptyMsg='No Network Available'
          form={{
            name: 'network',
            keyField: 'key',
            textField: 'name',
            placeholder: 'Select Network',
            selectionName: 'networkObj',
            label: 'Network',
          }}
        />
      </Box>
      <Box position='relative'>
        {!user.mediaPlanExclude ? (
          <Box {...sharedProps}>
            <IncludeExcludeField name='excludeGenres' disabled={formDisable} />
          </Box>
        ) : null}
        <VirtualSelectField
          mode='multiple'
          allowClear
          detail={id}
          hasMultiOption={true}
          request={api.common.genres}
          apiParams={params}
          values={values}
          initialValues={initialValues}
          disabled={formDisable}
          getPopupContainer={getPopupContainer}
          emptyMsg='No Genre Available'
          form={{
            name: 'genres',
            keyField: 'key',
            textField: 'name',
            placeholder: 'Select Genre',
            selectionName: 'genresObj',
            label: 'Genre',
          }}
        />
      </Box>
    </Grid>
  )
}

SimpleOptions.propTypes = {
  id: PropTypes.string,
  formDisable: PropTypes.bool,
  initialValues: PropTypes.object,
  params: PropTypes.object,
  getPopupContainer: PropTypes.func,
}

export default SimpleOptions
