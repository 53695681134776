import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Button, Flex, Input, Modal, useToast } from '@beachfront/ui'
import { Field, Form } from '@beachfront/ui/forms'
import { LockOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { useUserData } from '../../../hooks'
import { getErrorMessage } from '../../../utils'
import { validatePasswordForm } from '../@utils'

const UpdatePasswordModal = ({ visible, hasPassword, userEmail, onClose }) => {
  const [user] = useUserData()
  const toast = useToast()
  const location = useLocation()

  const onSubmit = (values) => {
    const path = location.pathname.toString().split('/')
    const pathId = path[path.length - 2]
    const params = { id: hasPassword ? user.userId : pathId }

    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    }

    const request = hasPassword
      ? api.users.updatePasswordUser
      : api.users.updatePassword

    return request(data, params).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'Password updated.' })
          onClose?.()
        } else {
          toast.error({
            title:
              res.data?.msg || 'Unable to change password. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const getTitle = () => {
    if (hasPassword) {
      return 'Update Your Password'
    }
    if (userEmail) {
      return `Update Password for "${userEmail}"`
    }
    return 'Update Password'
  }

  return (
    <Modal
      title={getTitle()}
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      destroyOnClose
    >
      <Form
        onSubmit={onSubmit}
        validate={(values) => validatePasswordForm(values, hasPassword)}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box width={470}>
              <>
                {hasPassword ? (
                  <Field name='oldPassword' label='Enter Old Password'>
                    <Input.Password
                      type='password'
                      size='large'
                      placeholder='Enter Old Password'
                      prefix={<LockOutlined />}
                    />
                  </Field>
                ) : null}
                <Field name='newPassword' label='Enter New Password'>
                  <Input.Password
                    type='password'
                    size='large'
                    placeholder='Enter New Password'
                    prefix={<LockOutlined />}
                  />
                </Field>
                <Field name='confirmPassword' label='Confirm New Password'>
                  <Input.Password
                    type='password'
                    size='large'
                    placeholder='Confirm New Password'
                    prefix={<LockOutlined />}
                  />
                </Field>
                <Flex mt={4} justifyContent='center'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={submitting}
                    disabled={!values.newPassword || !values.confirmPassword}
                  >
                    Update
                  </Button>
                </Flex>
              </>
            </Box>
          </form>
        )}
      </Form>
    </Modal>
  )
}

UpdatePasswordModal.defaultProps = {
  hasPassword: false,
}

UpdatePasswordModal.propTypes = {
  visible: PropTypes.bool,
  hasPassword: PropTypes.bool,
  userEmail: PropTypes.string,
  onClose: PropTypes.func,
}

export default UpdatePasswordModal
