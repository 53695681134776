import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'
import { getUniqueId } from '../../utils'

export const useReportDetail = ({ params } = {}) => {
  return useQuery({
    queryKey: ['report-detail', params],
    queryFn: async () => {
      const [report, totals] = await Promise.all([
        api.report.getById(params),
        api.report.getTotals(params),
      ])
      return {
        title: report.data?.title,
        msg: report.data?.msg,
        rows: report.data?.rows?.map((r) => ({ ...r, key: getUniqueId() })),
        columns: report.data?.columns,
        totals: totals.data,
      }
    },
  })
}
