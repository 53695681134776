import React from 'react'
import { Filter, Radio } from '@beachfront/ui'
import { GlobalOutlined, TagsOutlined } from '@beachfront/ui/icons'

import { META_TYPE } from '../../../../enums'
import {
  ContainsFilterPanel,
  formatStringFilterTag,
} from '../../../../components/filter'

const MetaFilter = ({ filterRef, filters, setFilters }) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='metaId'
        label='Meta ID'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<GlobalOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='type'
        label='Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<TagsOutlined />}
        formatTag={renderTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {META_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
    </Filter>
  )
}

function renderTypeTag(desc) {
  const name = META_TYPE.fromKey(desc.value)?.name
  return name ? `Type is ${name}` : 'All Types'
}

export default MetaFilter
