export const csvHeaders = [{ label: 'IP', key: 'ip' }]

export const csvData = [
  { ip: '74.215.42.230' },
  { ip: '174.233.156.249' },
  { ip: '72.133.32.203' },
  { ip: '73.120.246.95' },
  { ip: '24.74.19.180' },
  { ip: '68.40.53.193' },
  { ip: '173.18.236.148' },
  { ip: '174.192.146.164' },
  { ip: '45.49.77.2' },
  { ip: '174.198.208.253' },
  { ip: '173.63.204.184' },
  { ip: '75.32.197.224' },
  { ip: '108.48.72.242' },
  { ip: '174.198.132.193' },
  { ip: '73.137.75.127' },
  { ip: '75.110.131.171' },
]
