import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Flex, Popover, Tag } from '@beachfront/ui'
import { FilterFilled } from '@beachfront/ui/icons'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import { setFilterData } from '../../@utils/segments.util'
import { useBooleanSegment } from '../booleanSegment/boolean-segment-context'

// This is abstracted from existing Media Plan.  Once approved; we should replace in Available Segment
const PopoverFilter = ({
  activeParent = null,
  applied = [],
  activeTab,
  isLoading = false,
  label,
  onApply,
  providers = [],
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(applied)
  const { appliedTypes, appliedProviders, params, setParams } =
    useBooleanSegment()
  const filterData = setFilterData(activeTab, appliedTypes, providers)
  const payloadData = setFilterData(activeTab, appliedProviders, providers)
  const popRef = useRef()
  const contentRef = useRef()
  const titleRef = useRef()
  const onClickHandler = (key) => {
    const updatedKeys = [...selected]

    // IF all is clicked; it takes precedence and we stop
    if (key === 'ALL') {
      setSelected(['ALL'])

      return
    }

    // Remove ALL from selected if anything else is clicked
    const removeAll = updatedKeys.findIndex((uk) => uk === 'ALL')
    if (removeAll > -1) {
      updatedKeys.splice(removeAll, 1)
    }

    // Normal tag logic
    const existingIndex = updatedKeys.findIndex((uk) => uk === key)
    if (existingIndex > -1) {
      updatedKeys.splice(existingIndex, 1)
    } else {
      updatedKeys.push(key)
    }

    // If all are deselected; we stay with ALL

    if (updatedKeys.length === 0) {
      updatedKeys.push('ALL')
    }

    setSelected(updatedKeys)
  }

  const isTagDisabled = (provider) => {
    const { parentProvider, value } = provider
    if (!activeParent || value.toLowerCase() === 'all') {
      return false
    }

    if (
      parentProvider &&
      parentProvider.toLowerCase() !== activeParent.toLowerCase()
    ) {
      return true
    }

    if (!parentProvider && value.toLowerCase() !== activeParent.toLowerCase()) {
      return true
    }

    return false
  }

  useEffect(() => {
    setSelected(applied)
  }, [applied])
  const onApplyLocal = () => {
    if (
      params.p > 0 &&
      ((label === 'Type' && !isEqual(selected, filterData)) ||
        (label === 'Provider' && !isEqual(selected, payloadData)))
    ) {
      setParams({ ...params, p: 0 })
    }

    setIsOpen(false)
    onApply(selected)
  }

  const content = () => (
    <Flex gap={2} flexDirection='column' ref={contentRef}>
      <Flex flexWrap='wrap' gap={2}>
        {providers.map((provider) => (
          <Tag.CheckableTag
            checked={selected.includes(provider.key)}
            key={provider.key}
            onClick={() => {
              if (!isTagDisabled(provider)) {
                onClickHandler(provider.key)
              }
            }}
            disabled={isTagDisabled(provider)}
          >
            {provider.value}
          </Tag.CheckableTag>
        ))}
      </Flex>
      <Flex gap={2} mt={2} pt={2}>
        <Button size='small' type='primary' onClick={onApplyLocal}>
          Apply
        </Button>
        <Button
          onClick={() => {
            setIsOpen(false)
            setSelected(applied)
          }}
          size='small'
        >
          Close
        </Button>
      </Flex>
    </Flex>
  )

  //const title = <Box color='heading' fontSize={1}>{`Filter By ${label}`}</Box>

  const count = useMemo(() => {
    if (applied.length === 1 && applied[0] === 'ALL') {
      return 'ALL'
    } else if (activeTab === 'uploaded') {
      return filterData.length
    } else {
      return applied.length
    }
  }, [applied, filterData])

  useEffect(() => {
    const onOutsideClickHandler = (evt) => {
      if (
        contentRef.current &&
        !contentRef.current.contains(evt.target) &&
        titleRef.current &&
        !titleRef.current.contains(evt.target)
      ) {
        setIsOpen(false)
      }
    }

    window.addEventListener('mousedown', onOutsideClickHandler)

    return () => {
      window.removeEventListener('mousedown', onOutsideClickHandler)
    }
  })
  return (
    <Popover
      content={content}
      overlayStyle={{ width: '500px' }}
      placement='bottomLeft'
      title={<div ref={titleRef}>{title}</div>}
      trigger='click'
      visible={isOpen}
      ref={popRef}
    >
      <Button
        icon={<FilterFilled />}
        onClick={() => setIsOpen(true)}
        loading={isLoading}
      >
        {label}: {count}
      </Button>
    </Popover>
  )
}

PopoverFilter.propTypes = {
  applied: PropTypes.array,
  activeTab: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onApply: PropTypes.func,
  providers: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.null]),
}

export default PopoverFilter
