import React from 'react'

import { MEDIA_LIST_TYPE } from '../../enums'
import { localFilterKey } from '../../components/filter'

import { default as MediaLists } from './media-lists'

const MetroCodeMediaList = () => {
  return (
    <MediaLists
      title='Media Lists - Metro Codes'
      mediaListType={MEDIA_LIST_TYPE.METRO_CODE.key}
      filterKey={localFilterKey.METRO_CODE_MEDIA_LISTS}
    />
  )
}

export default MetroCodeMediaList
