import React from 'react'
import { Box } from '@beachfront/ui'

export const getDspTooltip = (type) => {
  switch (type) {
    case 'title':
      return (
        <>
          <Box>Enter DSP Name</Box>
        </>
      )
    case 'status':
      return (
        <>
          <Box>
            Enabled - If enable it will show at the time of deal create.
          </Box>
          <Box>
            Disabled - If disabled it will not show at the time of deal create.
          </Box>
        </>
      )
    case 'type':
      return (
        <>
          <Box>Direct - No need to add a source and a source seat.</Box>
          <Box>Indirect - You must add a source and a source seat.</Box>
        </>
      )
    case 'source':
      return (
        <>
          <Box>Enter source for this buyer</Box>
        </>
      )
    case 'sourceSeat':
      return (
        <>
          <Box>Enter source seat for this buyer</Box>
        </>
      )
    case 'seat':
      return (
        <>
          <Box>
            If enabled an option will display to enter the seat at the time of
            deal create
          </Box>
        </>
      )
    case 'endPointUrl':
      return (
        <>
          <Box>Enter DSP End point url</Box>
        </>
      )
    default:
      break
  }
}
