import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'
import { IS_DEALS_ENGINE } from '../../constants'

export const useTargetingChart = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['targeting', 'chart', params],
    queryFn: async ({ signal }) => {
      const {
        chartType,
        timezone,
        pmp,
        admin,
        mediaId,
        marginType,
        marginValue,
        dealFloor,
        dataCost = 0,
        targetingParams,
      } = params

      const payload = {
        dataCost,
        estimatedDealFloor: dealFloor,
      }

      if (!IS_DEALS_ENGINE) {
        payload.dealMarginType = marginType
        payload.dealMarginValue = marginValue
      }

      if (timezone) {
        payload.timezone = timezone
      }

      if (pmp) {
        payload.pmp = pmp
      }

      if (admin) {
        payload.admin = admin
      }

      if (admin && mediaId) {
        payload.mediaId = mediaId
      }

      const requestFn =
        chartType === 'cpm'
          ? api.mediaPlan.getCpmChart
          : api.mediaPlan.getDayChart

      const res = await requestFn(targetingParams, payload, signal)
      return res.data
    },
    staleTime: 5 * 60 * 1000,
    enabled,
  })
}
