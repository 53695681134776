import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Table, Tag } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'

import { api } from '../../../client-api'
import { getErrorMessage } from '../../../utils'
import { ACCOUNT_TYPE } from '../../../enums'
import { NetworkError } from '../../../components'
import { expandedSpendColumns, spendColumns } from '../@constants'

import { ActionColumnHeader } from '.'

const SeatsTable = ({
  requestPayload,
  accountType,
  expanded,
  onExpandChange,
}) => {
  const response = useQuery({
    queryKey: ['transparency-seats', requestPayload, expanded, accountType],
    queryFn: async () => {
      const request = getRequest(accountType)
      const { data } = await request({ ...requestPayload, expended: expanded })
      return data
    },
    staleTime: 30000,
  })

  const table = useTableQuery({
    data: response.data?.rows ?? [],
    defaultPageSize: 10,
    defaultSorter: {
      field: 'revenuegross',
      order: 'descend',
    },
  })

  const scroll = {
    x: '100%',
    y: expanded ? 435 : accountType === ACCOUNT_TYPE.BRAND.key ? 570 : 250,
  }

  const firstColumn =
    accountType === ACCOUNT_TYPE.DSP.key
      ? {
          dataIndex: 'seatId',
          title: 'Top Spend : Seats',
          width: 180,
          sorter: true,
          render: (val) => {
            if (!val) {
              return 'NA'
            }
            return (
              <Tag
                width={75}
                color='processing'
                title={val}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                #{val}
              </Tag>
            )
          },
        }
      : {
          dataIndex: 'name',
          title: 'Top Spend : Publishers',
          width: 180,
          sorter: true,
        }

  const columns = [
    firstColumn,
    ...(expanded ? expandedSpendColumns : spendColumns),
    {
      key: 'action',
      width: expanded ? 40 : 72,
      title: (
        <ActionColumnHeader
          columns={[firstColumn, ...spendColumns]}
          data={response.data?.rows ?? []}
          expanded={expanded}
          onExpandChange={onExpandChange}
        />
      ),
    },
  ]

  if (!response.isFetching && response.error) {
    return (
      <NetworkError
        description={getErrorMessage(response.error)}
        onAction={response.refetch}
      />
    )
  }

  return (
    <Table
      rowKey='_index'
      emptyText='No Data Available'
      columns={columns}
      dataSource={table.data}
      loading={response.isFetching}
      scroll={scroll}
      pagination={false}
      onChange={(e) => {
        table.setPagination(e.pagination)
        table.setSorter(e.sorter)
      }}
    />
  )
}

const getRequest = (account) => {
  if (account === ACCOUNT_TYPE.AGENCY.key) {
    return api.realtime.transparency.agency.publisher
  }
  if (account === ACCOUNT_TYPE.BRAND.key) {
    return api.realtime.transparency.brand.publisher
  }
  return api.realtime.transparency.seats.top
}

export default SeatsTable
