import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Filter, Radio } from '@beachfront/ui'
import {
  CalendarOutlined,
  IdcardOutlined,
  TeamOutlined,
  VideoCameraOutlined,
  DesktopOutlined,
  CrownOutlined,
  DollarCircleOutlined,
  NumberOutlined,
  GlobalOutlined,
  MapOutlined,
  ExclamationCircleOutlined,
} from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { useUserData } from '../../../hooks'
import { IS_DEALS_ENGINE } from '../../../constants'
import { ACCOUNT_TYPE, MEDIA_TYPE, PLATFORM } from '../../../enums'
import {
  DateRangeFilterPanel,
  StaticSearchPanel,
  CompareFilterPanel,
  ContainsFilterPanel,
  SelectFilterPanel,
  formatDateFilterTag,
  formatStringFilterTag,
  formatNumberTag,
} from '../../../components/filter'

const AuctionLogFilter = ({ filterRef, filters, setFilters }) => {
  const [user] = useUserData()
  const accountType = user.accountType.key

  const publishers = useQuery({
    queryKey: ['auction-publishers'],
    queryFn: async () => {
      const { data } = await api.auction.publishers()
      return data
    },
    enabled: !IS_DEALS_ENGINE,
  })

  const failedReasons = useQuery({
    queryKey: ['failed-reasons'],
    queryFn: async () => {
      const { data } = await api.auction.failedReasons()
      return data
    },
    enabled: !IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key,
  })

  return (
    <Filter
      ref={filterRef}
      value={filters}
      onChange={setFilters}
      showSearch={!IS_DEALS_ENGINE}
    >
      <Filter.Option
        dataIndex='date'
        icon={<CalendarOutlined />}
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        removable={false}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateRangeFilterPanel {...props} />}
      />
      {!IS_DEALS_ENGINE ? (
        <Filter.Option
          dataIndex='id'
          label='ID'
          defaultOperator={Filter.Operator.Any.EQUALS.key}
          icon={<IdcardOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <StaticSearchPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE ? (
        <Filter.Option
          dataIndex='pubId'
          label='Publisher'
          defaultOperator={Filter.Operator.Any.EQUALS.key}
          icon={<TeamOutlined />}
          formatTag={renderPublisherTag(publishers.data)}
          renderPanel={(props) => (
            <SelectFilterPanel
              {...props}
              dataSource={publishers.data}
              loading={publishers.isFetching}
              label='Publisher'
              textField='name'
            />
          )}
        />
      ) : null}
      <Filter.Option
        dataIndex='mediaType'
        label='Media Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<VideoCameraOutlined />}
        formatTag={renderMediaTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {MEDIA_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='platform'
        label='Platform'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<DesktopOutlined />}
        formatTag={renderPlatformTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {PLATFORM.values().map(({ key, name }) =>
              key !== PLATFORM.ALL.key ? (
                <Radio.Button key={key} value={key}>
                  {name}
                </Radio.Button>
              ) : null
            )}
          </Radio.Group>
        )}
      />
      {!IS_DEALS_ENGINE ? (
        <Filter.Option
          dataIndex='win'
          label='Result'
          defaultOperator={Filter.Operator.Any.EQUALS.key}
          defaultValue={true}
          icon={<CrownOutlined />}
          formatTag={renderResultTag}
          renderPanel={({ value, setValue }) => (
            <Radio.Group value={value} onChange={setValue}>
              <Radio.Button value={true}>Won</Radio.Button>
              <Radio.Button value={false}>Lost</Radio.Button>
            </Radio.Group>
          )}
        />
      ) : null}
      {!IS_DEALS_ENGINE ? (
        <Filter.Option
          dataIndex='clearPrice'
          label='Clearing Price'
          defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
          defaultValue={0}
          icon={<DollarCircleOutlined />}
          formatTag={formatNumberTag}
          renderPanel={(props) => (
            <CompareFilterPanel {...props} min={0} step={0.01} />
          )}
        />
      ) : null}
      {!IS_DEALS_ENGINE ? (
        <Filter.Option
          dataIndex='bidCount'
          label='Bidders'
          defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
          defaultValue={0}
          icon={<NumberOutlined />}
          formatTag={formatNumberTag}
          renderPanel={(props) => (
            <CompareFilterPanel {...props} min={0} step={1} />
          )}
        />
      ) : null}
      {!IS_DEALS_ENGINE ? (
        <Filter.Option
          dataIndex='bidDensity'
          label='Density'
          defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
          defaultValue={0}
          icon={<NumberOutlined />}
          formatTag={formatNumberTag}
          renderPanel={(props) => (
            <CompareFilterPanel {...props} min={0} step={1} />
          )}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='bidData.adomain'
          label='Adomain'
          defaultOperator={Filter.Operator.String.CONTAINS.key}
          icon={<GlobalOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <ContainsFilterPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='bidData.seatId'
          label='Seat ID'
          defaultOperator={Filter.Operator.String.CONTAINS.key}
          icon={<IdcardOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <ContainsFilterPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE ? (
        <Filter.Option
          dataIndex='bidData.dealId'
          label='Deal ID'
          defaultOperator={Filter.Operator.String.CONTAINS.key}
          icon={<IdcardOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <ContainsFilterPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='bidData.advertiserId'
          label='Advertiser ID'
          defaultOperator={Filter.Operator.String.CONTAINS.key}
          icon={<IdcardOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <ContainsFilterPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='bidData.floor'
          label='Bid Floor'
          defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
          defaultValue={0}
          icon={<DollarCircleOutlined />}
          formatTag={formatNumberTag}
          renderPanel={(props) => (
            <CompareFilterPanel {...props} min={0} step={0.01} />
          )}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='bidData.bidPlacedRate'
          label='Bid Rate'
          defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
          defaultValue={0}
          icon={<DollarCircleOutlined />}
          formatTag={formatNumberTag}
          renderPanel={(props) => (
            <CompareFilterPanel {...props} min={0} step={0.01} />
          )}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='margin'
          label='Take Rate'
          defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
          defaultValue={0}
          icon={<NumberOutlined />}
          formatTag={formatNumberTag}
          renderPanel={(props) => (
            <CompareFilterPanel {...props} min={0} step={1} />
          )}
        />
      ) : null}
      {!IS_DEALS_ENGINE ? (
        <Filter.Option
          dataIndex='ipaddress'
          label='IP Address'
          defaultOperator={Filter.Operator.Any.EQUALS.key}
          icon={<GlobalOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <StaticSearchPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='country'
          label='Country'
          defaultOperator={Filter.Operator.String.CONTAINS.key}
          icon={<MapOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <ContainsFilterPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='domain'
          label='Page URL'
          defaultOperator={Filter.Operator.String.CONTAINS.key}
          icon={<GlobalOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <ContainsFilterPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='ua'
          label='User Agent'
          defaultOperator={Filter.Operator.String.CONTAINS.key}
          icon={<GlobalOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <ContainsFilterPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='hostname'
          label='Host Name'
          defaultOperator={Filter.Operator.String.CONTAINS.key}
          icon={<GlobalOutlined />}
          formatTag={formatStringFilterTag}
          renderPanel={(props) => <ContainsFilterPanel {...props} />}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='responseTime'
          label='Response Time'
          defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
          defaultValue={0}
          icon={<NumberOutlined />}
          formatTag={formatNumberTag}
          renderPanel={(props) => (
            <CompareFilterPanel {...props} min={0} step={1} />
          )}
        />
      ) : null}
      {!IS_DEALS_ENGINE && accountType !== ACCOUNT_TYPE.RESELLER.key ? (
        <Filter.Option
          dataIndex='bidData.fr'
          label='Failed Reason'
          defaultOperator={Filter.Operator.Any.EQUALS.key}
          icon={<ExclamationCircleOutlined />}
          formatTag={renderFailedReasonTag(failedReasons.data)}
          renderPanel={(props) => (
            <SelectFilterPanel
              {...props}
              dataSource={failedReasons.data}
              loading={failedReasons.isFetching}
              label='Failed Reason'
              keyField='id'
              textField='name'
            />
          )}
        />
      ) : null}
    </Filter>
  )
}

function renderPublisherTag(data) {
  return (desc) => {
    const name = data?.find((el) => el.key === desc.value)?.name ?? '...'
    return desc.value ? `Publisher is "${name ?? '...'}"` : 'All Publishers'
  }
}

function renderFailedReasonTag(data) {
  return (desc) => {
    const name = data?.find((el) => el.id === desc.value)?.name
    const maxLength = 32
    const text = String(name).substring(0, maxLength)
    const ellipsis = String(name).length > maxLength ? '...' : ''
    return name ? `Failed Reason is "${text}${ellipsis}"` : 'All Failed Reasons'
  }
}

function renderMediaTypeTag(desc) {
  const name = MEDIA_TYPE.fromKey(desc.value)?.name
  return name ? `Media Type is ${name}` : 'All Media Types'
}

function renderPlatformTag(desc) {
  const name = PLATFORM.fromKey(desc.value)?.name
  return name ? `Platform is ${name}` : 'All Platforms'
}

function renderResultTag(desc) {
  return desc.value ? 'Auction Won' : 'Auction Lost'
}

export default AuctionLogFilter
