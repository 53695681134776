import React from 'react'
import { Box, Tag } from '@beachfront/ui'
import { CrownFilled } from '@beachfront/ui/icons'

import { isValidUrl } from '../../../utils'
import { renderAnchor } from '../@utils'

export const leftDetailColumn = [
  {
    dataIndex: 'key',
    title: 'Auction Details',
    width: '25%',
  },
  {
    dataIndex: 'value',
    render: (val) => (isValidUrl(val) ? renderAnchor(val) : val),
  },
]

export const rightDetailColumn = [
  {
    dataIndex: 'key',
    title: 'Auction Details',
    width: '35%',
  },
  {
    dataIndex: 'value',
    render: (val) => (isValidUrl(val) ? renderAnchor(val) : val),
  },
]

export const bidDetailColumns = [
  {
    dataIndex: 'advertiserId',
    title: 'DSP',
  },
  {
    dataIndex: 'seatId',
    title: 'Seat ID',
    render: (val) => val || <Tag>NA</Tag>,
  },
  {
    dataIndex: 'adomain',
    title: 'Adomain',
    render: (val) => val || <Tag>NA</Tag>,
  },
  {
    dataIndex: 'dealId',
    title: 'Deal ID',
    render: (val) => val || <Tag>NA</Tag>,
  },
  {
    dataIndex: 'bidFloor',
    title: 'Bid Floor',
    render: (val) => (val ? '$' + val : <Tag>NA</Tag>),
  },
  {
    dataIndex: 'bidPlacedRate',
    title: 'Bid Rate',
    render: (val) => (val ? '$' + val : <Tag>NA</Tag>),
  },
  {
    dataIndex: 'margin',
    title: 'Take Rate',
    render: (val) => (val ? val + '%' : <Tag>NA</Tag>),
  },
  {
    dataIndex: 'responsetime',
    title: 'Response Time',
    render: (val) => (val ? val + ' ms' : <Tag>NA</Tag>),
  },
  {
    dataIndex: 'userMatch',
    title: 'User Match',
    render: (val) => (val ? <Tag>TRUE</Tag> : <Tag>FALSE</Tag>),
  },
  {
    dataIndex: 'at',
    title: 'Type',
  },
  {
    dataIndex: 'fr',
    title: 'Failed Reason',
    render: (val, rec) =>
      rec.won ? (
        <Box color='gold.4'>
          <CrownFilled />
        </Box>
      ) : (
        val
      ),
  },
]
