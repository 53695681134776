import { formatDate } from '../../../../utils'

export const supplyColumns = [
  {
    dataIndex: 'pub',
    title: 'Publisher',
    width: 250,
    sorter: true,
    filter: true,
    render: (val) => val || '--',
  },
  {
    dataIndex: 'account',
    title: 'Account',
    width: 150,
    sorter: true,
    filter: true,
    render: (val) => val || '--',
  },
  {
    dataIndex: 'user',
    title: 'User',
    width: 200,
    sorter: true,
    filter: true,
    render: (val) => val || '--',
  },
  {
    dataIndex: 'requestDate',
    title: 'Request Date',
    width: 200,
    sorter: true,
    defaultSortOrder: 'descend',
    render: (val) => formatDate(val),
  },
]
