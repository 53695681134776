import http from '../http'

export default {
  admin: {
    createDeal(data) {
      return http.post('deal/library/create', data)
    },
    getLibraryDeals({ params, ...data }) {
      return http.post('deal/library/all', data, { params })
    },
    getDealById({ id, params }) {
      return http.get(`deal/library/get/${id}`, { params })
    },
    updateDeal(id, data) {
      return http.post(`deal/library/update/${id}`, data)
    },
    deleteDeal(id) {
      return http.get(`deal/library/remove/${id}`)
    },
    createMediaPlan(data) {
      return http.post('mediaplan/create', data)
    },
    getMediaById({ id }) {
      return http.get(`mediaplan/get/${id}`)
    },
    updateMediaPlan(id, params) {
      return http.post(`mediaplan/update/${id}`, params)
    },
  },
  list({ params, ...data }) {
    return http.post('deal/template/all', data, { params })
  },
  featuredList({ params, data }) {
    return http.post('deal/template/featured/all', data, { params })
  },
}
