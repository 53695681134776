import React, { useRef } from 'react'
import { Flex, Table } from '@beachfront/ui'
import PropTypes from 'prop-types'

import { useContentSize } from '../../../../hooks'
import { NetworkError } from '../../../../components'
import { dspColumns } from '../@constants'
import { getErrorMessage } from '../../../../utils'

import { DspFilter } from '.'

const DspTable = ({ dsp, loading, filters, setFilters, error, onRefetch }) => {
  const { contentWidth, contentHeight } = useContentSize()
  const filterRef = useRef()

  const scroll = {
    y: contentHeight - 260,
    x: contentWidth - 300,
  }

  if (!loading && error) {
    return (
      <NetworkError description={getErrorMessage(error)} onAction={onRefetch} />
    )
  }

  const toolbar = () => (
    <Table.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <DspFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </Table.Toolbar>
  )

  return (
    <Table
      rowKey='id'
      emptyText='No DSPs'
      columns={dspColumns}
      toolbar={toolbar}
      dataSource={dsp.data}
      loading={loading}
      pagination={{
        ...dsp.query.pagination,
        hideOnSinglePage: false,
        total: dsp.total,
      }}
      scroll={scroll}
      filters={filters.populated}
      onFilter={(key) => filterRef.current?.addFilter(key)}
      onChange={(e) => {
        dsp.setPagination(e.pagination)
        dsp.setSorter(e.sorter)
        dsp.setSelectedRowKeys([])
      }}
    />
  )
}

DspTable.propTypes = {
  dsp: PropTypes.object,
  loading: PropTypes.bool,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  error: PropTypes.object,
  onRefetch: PropTypes.func,
}

export default DspTable
