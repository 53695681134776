import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Box, Button } from '@beachfront/ui'
import { ReloadOutlined, UploadOutlined } from '@beachfront/ui/icons'

import { api } from '../../../client-api'
import { getErrorMessage } from '../../../utils'
import { localFilterKey } from '../../../components/filter'
import { useContentSize, useFilters, useInfiniteTable } from '../../../hooks'
import {
  PageHeader,
  PageSearch,
  InfiniteTable,
  NetworkError,
} from '../../../components'

import { segmentColumns } from './@constants'
import { SegmentFilter } from './@components'

const UploadedSegments = () => {
  const { contentHeight } = useContentSize()
  const [searchQuery, setSearchQuery] = useState('')

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.UPLOADED_SEGMENTS, {
    schema: {
      createDate: 'date',
      updateDate: 'date',
    },
  })

  const table = useInfiniteTable({
    queryKey: ['uploaded-segments'],
    queryFn: api.segment.getUploadedList,
    filters: filters.populated,
    params: { q: searchQuery },
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 260,
  }

  const headerActions = (
    <>
      <PageSearch
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search Segments'
      />
      <Button onClick={() => table.refetch()} icon={<ReloadOutlined />} />
      <Link to='create'>
        <Button type='primary' icon={<UploadOutlined />}>
          Upload Segment
        </Button>
      </Link>
    </>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <SegmentFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title='Uploaded Segments' actions={headerActions} />
      <Box mt={2}>
        {!table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <InfiniteTable
            rowKey='id'
            emptyText='No Uploaded Segments Found'
            columns={segmentColumns}
            toolbar={toolbar}
            scroll={scroll}
            filters={filters.populated}
            onFilter={(key) => filterRef.current?.addFilter(key)}
            {...table.props}
          />
        )}
      </Box>
    </>
  )
}

export default UploadedSegments
