export const accountTabs = [
  {
    key: 'general',
    value: 'General',
  },
  {
    key: 'videoInventory',
    value: 'Video Inventory',
  },
  {
    key: 'displayInventory',
    value: 'Display Inventory',
  },
]

export const yesNoList = [
  {
    key: 'YES',
    value: 'Yes',
  },
  {
    key: 'NO',
    value: 'No',
  },
]
