import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Flex, Label, Radio, Switch } from '@beachfront/ui'
import { Field, useFormState } from '@beachfront/ui/forms'

import { LinkDataField } from '../link-data-field'
import { ACCOUNT_TYPE } from '../../../../../enums'
import { SelectField } from '../../../../../components'

const OptionsSetting = ({ create, account, onAccountTypeChange }) => {
  const { values } = useFormState()

  const showField =
    values.type !== ACCOUNT_TYPE.BRAND.key &&
    values.type !== ACCOUNT_TYPE.PUBLISHER.key

  const showExtra = account?.data?.type !== ACCOUNT_TYPE.PUBLISHER.key || create

  const extra = (
    <Field name='type' mb={0}>
      <Radio.Group onChange={onAccountTypeChange}>
        {[
          ACCOUNT_TYPE.DSP,
          ACCOUNT_TYPE.AGENCY,
          ACCOUNT_TYPE.BRAND,
          ACCOUNT_TYPE.RESELLER,
        ].map(({ key, name }) => (
          <Radio key={key} value={key}>
            {name}
          </Radio>
        ))}
      </Radio.Group>
    </Field>
  )

  return (
    <Card title='Options' extra={showExtra ? extra : null} overflow='initial'>
      <Flex
        alignItems='center'
        flexWrap='wrap'
        gap={4}
        mb={3}
        mt={-3}
        mx={-3}
        p={3}
        bg='alpha.0'
        borderBottom='split'
      >
        <Field inline name='demoAccount' label='Demo' mb={0}>
          <Switch defaultChecked={values.type === ACCOUNT_TYPE.RESELLER.key} />
        </Field>
        {showField ? (
          <Field inline name='dealManagement' label='Deals' mb={0}>
            <Switch />
          </Field>
        ) : null}
        {values.dealManagement && showField ? (
          <Field inline name='dealAccessTemplate' label='Deal Library' mb={0}>
            <Switch />
          </Field>
        ) : null}
        {showField ? (
          <Field inline name='ftrackhhidReach' label='FFID Reach' mb={0}>
            <Switch />
          </Field>
        ) : null}
      </Flex>
      <Flex flexDirection='column' gap={2}>
        {!values.demoAccount && showField ? (
          <Field name='accManagerEmails' label='Account Manager Emails'>
            <SelectField
              dropdownStyle={{ display: 'none' }}
              data={[]}
              mode='tags'
              keyField='id'
              textField='name'
              placeholder='Enter Emails'
              allowClear
            />
          </Field>
        ) : null}
      </Flex>
      {values.type !== ACCOUNT_TYPE.RESELLER.key ? (
        <Flex>
          <Box columnSpan={24}>
            <Label mb={2}>Link Data</Label>
            <LinkDataField type={values.type} name='link' />
          </Box>
        </Flex>
      ) : null}
    </Card>
  )
}

OptionsSetting.propTypes = {
  create: PropTypes.bool,
  account: PropTypes.object,
  onAccountTypeChange: PropTypes.func,
}

export default OptionsSetting
