import React from 'react'

import {
  Login,
  ResetPassword,
  VerifyEmail,
  VerifyOtp,
  ResetPasswordSuccess,
} from '../../../pages/auth'

export const authRouteConfig = [
  {
    path: '/',
    element: <Login />,
    exact: true,
  },
  {
    path: '/login',
    element: <Login />,
    exact: true,
  },
  {
    path: '/verify-email',
    element: <VerifyEmail />,
    exact: true,
  },
  {
    path: '/verify-otp',
    element: <VerifyOtp />,
    exact: true,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    exact: true,
  },
  {
    path: '/reset-success',
    element: <ResetPasswordSuccess />,
    exact: true,
  },
]
