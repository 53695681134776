import { Filter } from '@beachfront/ui'

import { toTitleCase } from '../../../utils'

/**
 * Formats a tag for number filters.
 *
 * @param   {Object} desc   The filter descriptor.
 * @param   {Object} opt    The filter option.
 * @returns {String}
 */
export const formatNumberTag = (desc, opt) => {
  if (desc.operator === Filter.Operator.Number.EQUALS.key) {
    return `${opt.label} equals ${desc.value}`
  }

  if (desc.operator === Filter.Operator.Number.LESS_THAN.key) {
    return `${opt.label} less than ${desc.value}`
  }

  if (desc.operator === Filter.Operator.Number.GREATER_THAN.key) {
    return `${opt.label} greater than ${desc.value}`
  }

  if (desc.operator) {
    return `${opt.label} ${toTitleCase(desc.operator)} "${desc.value}"`
  }

  return opt.label
}
