export const loginValidate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Username is required.'
  }

  if (!values.password) {
    errors.password = 'Password is required.'
  }

  return errors
}

export const emailValidate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Email is required.'
  }

  return errors
}

export const otpValidate = (values) => {
  const errors = {}

  if (!values.otp) {
    errors.otp = 'OTP is required.'
  }

  if (values.otp && values.otp.length !== values.otpLength) {
    errors.otp = 'Enter a valid OTP.'
  }

  return errors
}

export const resetPasswordValidate = (values) => {
  const errors = {}

  if (!values.newPassword) {
    errors.email = 'New Password is required.'
  }

  if (!values.confirmPassword) {
    errors.email = 'Confirm Password is required.'
  }

  return errors
}
