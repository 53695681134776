import React from 'react'
import { Link } from 'react-router-dom'

import { DEAL_TYPE, MARGIN_TYPE } from '../../../enums'
import { formatCurrency, formatDate } from '../../../utils'
import { DynamicBadge } from '../../../components'

export const dealColumns = [
  {
    dataIndex: 'title',
    title: 'Name',
    fixed: 'left',
    sorter: true,
    width: 250,
    render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
  },
  {
    title: 'Status',
    dataIndex: 'dealStatus',
    sorter: false,
    filter: true,
    configurable: true,
    width: 100,
    render: (val) => <DynamicBadge data={val} />,
  },
  {
    dataIndex: 'pacing',
    title: 'Pacing',
    sorter: false,
    configurable: true,
    width: 100,
    render: (val) => <DynamicBadge data={val} inverted />,
  },
  {
    dataIndex: 'dealId',
    title: 'Deal ID',
    sorter: true,
    filter: true,
    configurable: true,
    width: 150,
  },
  {
    dataIndex: 'type',
    title: 'Deal Type',
    sorter: true,
    filter: true,
    configurable: true,
    width: 140,
    render: (key) => DEAL_TYPE.fromKey(key)?.name,
  },
  {
    dataIndex: 'dealDspId',
    title: 'DSP',
    sorter: false,
    configurable: true,
    width: 200,
  },
  {
    dataIndex: 'seat',
    title: 'Seat',
    sorter: false,
    configurable: true,
    width: 200,
  },
  {
    dataIndex: 'marginType',
    title: 'Margin Type',
    sorter: true,
    filter: true,
    configurable: true,
    width: 200,
    render: (key) => MARGIN_TYPE.fromKey(key)?.name || 'NA',
  },
  {
    dataIndex: 'marginValue',
    title: 'Margin Value',
    sorter: false,
    configurable: true,
    width: 200,
    render: (val) => (val === null ? 'NA' : val),
  },
  {
    dataIndex: 'auctions',
    title: 'Auctions',
    sorter: true,
    configurable: true,
    width: 120,
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    sorter: true,
    configurable: true,
    width: 100,
    render: (val) => formatCurrency(val),
  },
  {
    dataIndex: 'revenue',
    title: 'Revenue',
    sortDirections: ['ascend'],
    sorter: true,
    configurable: true,
    width: 120,
    render: (val) => (val === null ? 'NA' : formatCurrency(val)),
  },
  {
    dataIndex: 'spend',
    title: 'Spend',
    sortDirections: ['ascend'],
    sorter: true,
    configurable: true,
    width: 120,
    render: (val) => (val === null ? 'NA' : formatCurrency(val)),
  },
  {
    dataIndex: 'cpm',
    title: 'CPM',
    sortDirections: ['ascend'],
    sorter: true,
    configurable: true,
    width: 100,
    render: (val) => (val === null ? 'NA' : formatCurrency(val)),
  },
  {
    dataIndex: 'wins',
    title: 'Wins',
    sortDirections: ['ascend'],
    sorter: true,
    configurable: true,
    width: 100,
    render: (val) => (val === null ? 'NA' : val),
  },
  {
    dataIndex: 'createTime',
    title: 'Created',
    sorter: true,
    filter: true,
    configurable: true,
    width: 200,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'lastUpdate',
    title: 'Last Updated',
    sorter: true,
    filter: true,
    configurable: true,
    width: 200,
    render: (val) => formatDate(val),
  },
]
