import { IS_DEALS_ENGINE } from '../../../constants'
import { PERMISSION, ACCOUNT_TYPE } from '../../../enums'
import { isNotEmptyArray } from '../../../utils'

export const hasPermission = (route, user) => {
  const userRoles = user?.roles ?? []
  const userKey = user?.accountType?.key ?? user?.accountType

  if (
    isNotEmptyArray(route.roles) &&
    !userRoles.some((r) => route.roles.includes(r))
  ) {
    return false
  }

  if (
    route.permissionKey === PERMISSION.TRANSPARENCY.key &&
    (userKey === ACCOUNT_TYPE.RESELLER.key ||
      userKey === ACCOUNT_TYPE.PUBLISHER.key)
  ) {
    return false
  }

  if (route.permissionKey === PERMISSION.DEAL.key && !user?.superUser) {
    return false
  }

  if (route.permissionKey === PERMISSION.REACH.key && !user?.ftrackhhidReach) {
    return false
  }

  if (
    route.permissionKey === PERMISSION.DEALS.key &&
    !user?.dealAccessTemplate &&
    route.path?.includes('/deals/library')
  ) {
    return false
  }

  if (
    route.permissionKey === PERMISSION.AUDIENCE_SEGMENT.key &&
    !user?.uploadedSegment
  ) {
    return false
  }

  if (
    route.permissionKey === PERMISSION.AUDIENCE_SEGMENT_SHARED.key &&
    !user?.sharedSegment
  ) {
    return false
  }

  if (
    route.permissionKey === PERMISSION.API_KEY.key &&
    userKey !== ACCOUNT_TYPE.RESELLER.key
  ) {
    return false
  }

  if (
    [PERMISSION.REPORT.key, PERMISSION.USERS.key].includes(
      route.permissionKey
    ) &&
    IS_DEALS_ENGINE
  ) {
    return false
  }

  if (
    route.permissionKey === PERMISSION.DEALS_ADMIN.key &&
    (!IS_DEALS_ENGINE || !user?.admin)
  ) {
    return false
  }

  return true
}
