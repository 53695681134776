import React from 'react'
import { ResponsiveContainer, LineChart, Line } from 'recharts'

function TinyLineChart({ data, margin, stroke, showPrevious }) {
  return (
    <ResponsiveContainer>
      <LineChart data={data} margin={margin ?? {}}>
        <Line
          type='monotone'
          dataKey='value'
          stroke={stroke}
          strokeWidth='2'
          dot={false}
          isAnimationActive={false}
        />
        {showPrevious && (
          <Line
            type='monotone'
            dataKey='previousValue'
            stroke={stroke}
            strokeWidth='1'
            dot={false}
            isAnimationActive={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default TinyLineChart
