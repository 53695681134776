import React, { useRef } from 'react'
import { Flex, Table } from '@beachfront/ui'
import PropTypes from 'prop-types'

import { useContentSize } from '../../../../hooks'
import { NetworkError } from '../../../../components'
import { accountColumns } from '../@constants'
import { getErrorMessage } from '../../../../utils'

import { AccountFilter } from '.'

const AccountTable = ({
  accounts,
  loading,
  filters,
  setFilters,
  error,
  onRefetch,
}) => {
  const { contentWidth, contentHeight } = useContentSize()
  const filterRef = useRef()
  const scroll = {
    y: contentHeight - 260,
    x: contentWidth - 300,
  }

  if (!loading && error) {
    return (
      <NetworkError description={getErrorMessage(error)} onAction={onRefetch} />
    )
  }

  const toolbar = () => (
    <Table.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <AccountFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </Table.Toolbar>
  )

  return (
    <Table
      rowKey='accountId'
      emptyText='No Accounts'
      columns={accountColumns}
      toolbar={toolbar}
      dataSource={accounts.data}
      loading={accounts.isFetching}
      pagination={{
        ...accounts.query.pagination,
        hideOnSinglePage: false,
        total: accounts.total,
      }}
      scroll={scroll}
      filters={filters.populated}
      onFilter={(key) => filterRef.current?.addFilter(key)}
      onChange={(e) => {
        accounts.setPagination(e.pagination)
        accounts.setSorter(e.sorter)
        accounts.setSelectedRowKeys([])
      }}
    />
  )
}

AccountTable.propTypes = {
  accounts: PropTypes.object,
  loading: PropTypes.bool,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  error: PropTypes.object,
  onRefetch: PropTypes.func,
}

export default AccountTable
