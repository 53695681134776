import React from 'react'
import { Filter, Radio } from '@beachfront/ui'
import { StarOutlined, TeamOutlined } from '@beachfront/ui/icons'

import {
  ContainsFilterPanel,
  formatStringFilterTag,
} from '../../../../components/filter'

const RestrictedPublishersFilter = ({ filterRef, filters, setFilters }) => {
  const renderPremiumTag = ({ value }) => {
    return `Premium: ${value.toString()}`
  }

  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='company'
        label='Company'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<TeamOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='premium'
        label='Premium'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<StarOutlined />}
        formatTag={renderPremiumTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            <Radio.Button key='false' value={false}>
              FALSE
            </Radio.Button>
            <Radio.Button key='true' value={true}>
              TRUE
            </Radio.Button>
          </Radio.Group>
        )}
      />
    </Filter>
  )
}

export default RestrictedPublishersFilter
