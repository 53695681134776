import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, Modal, Table } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'

import { ACCOUNT_TYPE } from '../../../enums'
import { useUserData } from '../../../hooks'
import { formatCount, formatCurrency } from '../../../utils'
import { PageSearch } from '../../../components'

const InsightDetailModal = ({ title, data, loading, isOpen, onCancel }) => {
  const [user] = useUserData()
  const [searchQuery, setSearchQuery] = useState('')

  const isAgencyAccount = user.accountType?.key === ACCOUNT_TYPE.AGENCY.key
  const isDspAccount = user.accountType?.key === ACCOUNT_TYPE.DSP.key

  const matchesQuery = (dt) => {
    return String(dt.title).toLowerCase().includes(searchQuery.toLowerCase())
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const table = useTableQuery({
    data: applyQuery(data ?? []),
    defaultSorter: {
      field: 'impression',
      order: 'descend',
    },
  })

  const columns = [
    {
      dataIndex: 'title',
      title: 'Title',
      width: 240,
      sorter: true,
      render: (val) => val || 'NA',
    },
    {
      dataIndex: 'impression',
      title: 'Impression',
      width: 160,
      sorter: true,
      defaultSortOrder: 'descend',
      render: (val) => formatCount(val),
    },
    {
      dataIndex: 'revenue',
      title: isAgencyAccount || isDspAccount ? 'Spend' : 'Revenue',
      width: 160,
      sorter: true,
      render: (val) => formatCurrency(val),
    },
  ]

  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={onCancel}
      width='60%'
      footer={null}
      bodyStyle={{ height: 600, overflow: 'auto' }}
      centered
    >
      <Flex mb={3} justifyContent='flex-end'>
        <PageSearch value={searchQuery} onChange={setSearchQuery} />
      </Flex>
      <Table
        rowKey='title'
        dataSource={table.data}
        columns={columns}
        loading={loading}
        scroll={{ y: 390 }}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: false,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
    </Modal>
  )
}

InsightDetailModal.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default InsightDetailModal
