import React from 'react'
import {
  Box,
  Button,
  Text,
  DatePicker,
  Flex,
  Grid,
  InputNumber,
  Radio,
  Switch,
  useToast,
} from '@beachfront/ui'
import { PlusOutlined, DeleteOutlined } from '@beachfront/ui/icons'
import { Field, useForm, useFormState } from '@beachfront/ui/forms'

import { isNotEmptyArray, isNumber } from '../../../../../utils'
import { isNotEmptySpentRange } from '../../@utils'

const AccountTakeRate = () => {
  const toast = useToast()
  const form = useForm()
  const { values } = useFormState()

  const onAddMore = (spentformValues) => {
    if (isNotEmptyArray(spentformValues)) {
      const validValues = isNotEmptySpentRange(spentformValues)
      if (validValues) {
        const lastEnd = spentformValues[spentformValues.length - 1]?.to
        const newSpentRanges = [...spentformValues, { from: lastEnd, to: null }]
        form.batch(() => {
          form.change('spentRanges', newSpentRanges)
        })
      } else {
        toast.error({
          title: 'Please Enter all valid values to Add More Ranges.',
        })
      }
    }
  }

  const onEndChange = (number, spentformValues, currentIndex) => {
    if (isNumber(number)) {
      const newSpentRanges = [...spentformValues]
      newSpentRanges[currentIndex].to = number
      newSpentRanges[currentIndex + 1] = { from: number, to: null }
      form.batch(() => {
        form.change('spentRanges', newSpentRanges)
      })
    }
  }

  const onRemove = (spentformValues, removeIndex) => {
    if (isNotEmptyArray(spentformValues)) {
      const newSpentRanges = [...spentformValues]
      newSpentRanges.splice(removeIndex, 1)
      form.batch(() => {
        form.change('spentRanges', newSpentRanges)
      })
    }
  }

  return (
    <Box mt={2}>
      <Grid gap={2}>
        <Box colspan={24}>
          <Field name='enabled' label='Take Rate'>
            <Switch />
          </Field>
        </Box>
      </Grid>
      {!values.enabled ? (
        <>
          <Grid gap={2}>
            <Box columnSpan={6}>
              <Field name='spentType' label='Spent Type'>
                <Radio.Group defaultValue='MONTHLY'>
                  <Radio value='MONTHLY'>Monthly</Radio>
                  <Radio value='YEARLY'>Yearly</Radio>
                </Radio.Group>
              </Field>
            </Box>
            <Box columnSpan={8}>
              <Field name='startDate' label='Start Date'>
                <DatePicker placeholder='Select date' format='MM/DD/YYYY' />
              </Field>
            </Box>
          </Grid>
          <Text mb={2} fontSize={1} strong>
            Take Rate (%) for different ranges
          </Text>
          <Grid gap={2}>
            <Box columnSpan={24}>
              {values.spentRanges?.map((item, index) => (
                <Grid key={index}>
                  <Box mr={2} style={{ width: 150 }}>
                    <Field
                      name={`spentRanges.${index}.from`}
                      label='Start Range'
                    >
                      <InputNumber
                        min={0}
                        placeholder='Start'
                        disabled={index === 0}
                      />
                    </Field>
                  </Box>
                  <Box mr={2} style={{ width: 150 }}>
                    <Field name={`spentRanges.${index}.to`} label='End Range'>
                      <InputNumber
                        min={0}
                        placeholder='Infinite'
                        onChange={(e) =>
                          onEndChange(e, values.spentRanges, index)
                        }
                      />
                    </Field>
                  </Box>
                  <Box mr={2} style={{ width: 150 }}>
                    <Field
                      name={`spentRanges.${index}.takeRate`}
                      label='Take Rate (%)'
                    >
                      <InputNumber min={0} placeholder='Take Rate (%)' />
                    </Field>
                  </Box>
                  {index !== 0 ? (
                    <Flex mt={25} columnSpan={2}>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => onRemove(values.spentRanges, index)}
                      />
                    </Flex>
                  ) : null}
                </Grid>
              ))}
              <Grid gap={2}>
                <Box columnSpan={24}>
                  <Button
                    type='link'
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => onAddMore(values.spentRanges)}
                  >
                    Add More
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </>
      ) : null}
    </Box>
  )
}

export default AccountTakeRate
