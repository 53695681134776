import React from 'react'
import { Tag } from '@beachfront/ui'

import { IS_DEALS_ENGINE } from '../../../constants'
import { formatDate } from '../../../utils'

export const auctionLogColumns = [
  {
    dataIndex: 'id',
    title: 'ID',
    fixed: 'left',
    width: 220,
    filter: !IS_DEALS_ENGINE,
    render: (val) => (
      <Tag color='processing' ghost>
        #{val}
      </Tag>
    ),
  },
  {
    dataIndex: 'mediaType',
    title: 'Media Type',
    width: 120,
    filter: true,
  },
  {
    dataIndex: 'platform',
    title: 'Platform',
    width: 120,
    sorter: true,
    filter: true,
  },
  {
    dataIndex: 'ipAddress',
    title: 'IP Address',
    width: 110,
  },
  {
    dataIndex: 'timestamp',
    title: 'Timestamp',
    width: 140,
    sorter: true,
    defaultSortOrder: 'descend',
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'clearPrice',
    title: 'Clearing Price',
    width: 150,
    sorter: true,
    filter: !IS_DEALS_ENGINE,
    render: (val) => val || 'Pending',
  },
  {
    dataIndex: 'bidCount',
    title: 'Bidders',
    width: 110,
    sorter: true,
    filter: !IS_DEALS_ENGINE,
    render: (val) => val || 'NA',
  },
  {
    dataIndex: 'bidDensity',
    title: 'Density',
    width: 110,
    sorter: true,
    filter: !IS_DEALS_ENGINE,
    render: (val) => <Tag color='blue'>{val}</Tag>,
  },
]

if (!IS_DEALS_ENGINE) {
  auctionLogColumns.splice(1, 0, {
    dataIndex: 'pubId',
    title: 'Publisher',
    width: 150,
    filter: true,
    render: (val, rec) => rec.pub,
  })

  auctionLogColumns.splice(6, 0, {
    dataIndex: 'win',
    title: 'Result',
    width: 110,
    sorter: true,
    filter: true,
    render: (val) =>
      val ? <Tag color='success'>WON</Tag> : <Tag color='error'>LOST</Tag>,
  })
}

if (IS_DEALS_ENGINE) {
  auctionLogColumns.splice(1, 0, {
    dataIndex: 'inventory',
    title: 'Inventory',
    width: 150,
  })

  auctionLogColumns.splice(6, 0, {
    dataIndex: 'responseTime',
    title: 'Response Time',
    width: 140,
    sorter: true,
  })
}
