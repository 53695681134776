import React from 'react'
import PropTypes from 'prop-types'
import { Filter, Box, Radio, Badge } from '@beachfront/ui'
import { TagsOutlined, DollarCircleOutlined } from '@beachfront/ui/icons'

import { SEGMENT_TYPE } from '../../../../enums'
import { SelectField } from '../../../../components'
import {
  CompareFilterPanel,
  formatNumberTag,
} from '../../../../components/filter'

const SegmentFilter = ({
  filterRef,
  filters,
  setFilters,
  providers,
  isAll,
  isOwned,
}) => {
  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      {isAll ? (
        <Filter.Option
          dataIndex='sourceProvider'
          label='Provider'
          defaultOperator={Filter.Operator.Any.EQUALS.key}
          icon={<TagsOutlined />}
          formatTag={renderProviderTag(providers)}
          renderPanel={({ value, setValue }) => (
            <Box width={300}>
              <SelectField
                value={value}
                onChange={setValue}
                options={providers
                  .filter((p) => p.key !== 'ALL')
                  .map(({ key, value }) => ({
                    value: key,
                    label: value,
                  }))}
                placeholder='Select Provider'
                optionFilterProp='label'
                showSearch
              />
            </Box>
          )}
        />
      ) : null}
      <Filter.Option
        dataIndex='dataType'
        label='Segment Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<TagsOutlined />}
        formatTag={renderSegmentTypeTag}
        renderPanel={({ value, setValue }) => (
          <Box width={300}>
            <SelectField
              value={value}
              onChange={setValue}
              options={SEGMENT_TYPE.values().map(({ key, name }) => ({
                value: key,
                label: name,
              }))}
              placeholder='Select Segment Type'
              optionFilterProp='label'
              showSearch
            />
          </Box>
        )}
      />
      {isOwned ? (
        <Filter.Option
          dataIndex='ingest'
          label='Ingest'
          defaultOperator={Filter.Operator.Any.EQUALS.key}
          defaultValue={true}
          icon={renderInjestIcon}
          formatTag={renderInjestTag}
          renderPanel={({ value, setValue }) => (
            <Radio.Group value={value} onChange={setValue}>
              <Radio.Button value={true}>True</Radio.Button>
              <Radio.Button value={false}>False</Radio.Button>
            </Radio.Group>
          )}
        />
      ) : (
        <Filter.Option
          dataIndex='price'
          label='Price (CPM)'
          defaultOperator={Filter.Operator.Number.GREATER_THAN.key}
          defaultValue={0}
          icon={<DollarCircleOutlined />}
          formatTag={formatNumberTag}
          renderPanel={(props) => (
            <CompareFilterPanel
              {...props}
              allowEquals={false}
              min={0}
              step={0.01}
            />
          )}
        />
      )}
    </Filter>
  )
}

const renderProviderTag = (data) => {
  return (desc) => {
    const name = data?.find((el) => el.key === desc.value)?.value
    return name ? `Provider is ${name}` : 'All Providers'
  }
}

const renderSegmentTypeTag = (desc) => {
  const name = SEGMENT_TYPE.fromKey(desc.value)?.name
  return name ? `Segment Type is ${name}` : 'All Segment Types'
}

const renderInjestIcon = (desc) => {
  return desc.value ? <Badge status='success' /> : <Badge status='warning' />
}

const renderInjestTag = (desc) => {
  return desc.value ? 'Injest is True' : 'Injest is False'
}

SegmentFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
  providers: PropTypes.array,
  isAll: PropTypes.bool,
  isOwned: PropTypes.bool,
}

export default SegmentFilter
