import React, { useEffect } from 'react'
import { Box, Grid, Input } from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import { MEDIA_PLAN_STATUS } from '../../../enums'
import { omit } from '../../../utils'
import { SelectField, UploadInput } from '../../../components'

import { AttachDealTable } from '.'

const SettingForm = ({
  id,
  params,
  values,
  initialValues,
  setIsMediaPlanUpdate,
  superAdmin,
  dspDeal,
  adminFeatureDeal,
  disableForm,
}) => {
  const formDisabled = !!superAdmin || disableForm

  // TODO Tech Debt: the form library should manage the dirty state
  useEffect(() => {
    const keys = ['includeSegments', 'excludeSegments', 'includeSegmentAnd']
    const newValues = omit(values, keys)
    const newInitialValues = omit(initialValues, keys)

    if (!isEqual(newValues, newInitialValues)) {
      setIsMediaPlanUpdate(true)
    } else {
      setIsMediaPlanUpdate(false)
    }
  }, [JSON.stringify(values)])

  return (
    <Box>
      <Grid gap={3}>
        <Box columnSpan={12}>
          <Field
            name='title'
            label={adminFeatureDeal ? 'Name' : 'Media Plan Name'}
          >
            <Input.TextArea
              placeholder='Enter name'
              onPressEnter={(e) => e.preventDefault()}
              autoSize={{ minRows: 1, maxRows: 3 }}
              disabled={formDisabled}
            />
          </Field>
        </Box>
        <Box columnSpan={6}>
          <Field
            name='status'
            label={adminFeatureDeal ? 'Status' : 'Media Plan Status'}
          >
            <SelectField
              name='status'
              keyField='key'
              textField='name'
              data={MEDIA_PLAN_STATUS.values()}
              disabled={formDisabled}
            />
          </Field>
        </Box>
      </Grid>
      {adminFeatureDeal ? (
        <Grid gap={3}>
          <Box columnSpan={12}>
            <Field name='desc' label='Description'>
              <Input.TextArea
                rows={5}
                maxLength={500}
                placeholder='Enter description'
                showCount
              />
            </Field>
          </Box>
          <Box columnSpan={4}>
            <Field name='icon' label='Icon'>
              <UploadInput
                accept='image/png'
                single={true}
                maximumSize={100}
                aspectRatio={{ height: 150, width: 150 }}
              />
            </Field>
          </Box>
        </Grid>
      ) : null}
      {id && !dspDeal ? (
        <Box mt={3}>
          <AttachDealTable id={id} params={params} superAdmin={superAdmin} />
        </Box>
      ) : null}
    </Box>
  )
}

SettingForm.defaultProps = {
  params: {},
  values: {},
  initialValues: {},
  setIsMediaPlanUpdate: () => {},
}

SettingForm.propTypes = {
  id: PropTypes.string,
  params: PropTypes.object,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  setIsMediaPlanUpdate: PropTypes.func,
  superAdmin: PropTypes.bool,
  dspDeal: PropTypes.bool,
  adminFeatureDeal: PropTypes.bool,
  disableForm: PropTypes.bool,
}

export default SettingForm
