import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Card, Button, Modal, Switch, Table } from '@beachfront/ui'
import { Field, useFormState } from '@beachfront/ui/forms'
import { PlusOutlined } from '@beachfront/ui/icons'

import { SelectField } from '../../../components'

const AccountSetting = ({
  accounts,
  loading,
  dataSource,
  columns,
  onTableChange,
  modalVisible,
  onModalOpen,
  onModalCancel,
  onAddAccount,
  onAccountChange,
}) => {
  const { values } = useFormState()

  return (
    <Card
      title='Accounts'
      overflow='initial'
      extra={
        <Button icon={<PlusOutlined />} onClick={onModalOpen}>
          Add Account
        </Button>
      }
    >
      <Box width={1}>
        <Table
          size='small'
          columns={columns}
          loading={loading}
          dataSource={dataSource}
          onChange={onTableChange}
          rowKey={(r) => r.accountId}
          pagination={false}
          bordered
        />
      </Box>
      <Modal
        title='Add Account'
        visible={modalVisible}
        onCancel={onModalCancel}
        footer={false}
      >
        <Field name='accountName' label='Account'>
          <SelectField
            showSearch
            keyField='accountId'
            textField='name'
            placeholder='Select Account'
            data={accounts ?? []}
            onChange={onAccountChange}
          />
        </Field>
        <Field name='admin' label='Admin'>
          <Switch />
        </Field>
        <Flex gap={2} justifyContent='flex-end'>
          <Button onClick={onModalCancel}>Cancel</Button>
          <Button
            type='primary'
            disabled={!values.accountName}
            onClick={() => onAddAccount?.(values)}
          >
            Submit
          </Button>
        </Flex>
      </Modal>
    </Card>
  )
}

AccountSetting.defaultProps = {
  modalVisible: false,
}

AccountSetting.propTypes = {
  accounts: PropTypes.array,
  loading: PropTypes.bool,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  onTableChange: PropTypes.func,
  modalVisible: PropTypes.bool,
  onModalOpen: PropTypes.func,
  onModalCancel: PropTypes.func,
  onAddAccount: PropTypes.func,
  onAccountChange: PropTypes.func,
}

export default AccountSetting
