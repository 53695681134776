import React from 'react'
import {
  RouterProvider,
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { Spinner } from '@beachfront/ui'

import { useUserData } from '../../../hooks'
import { authRouteConfig, routeConfig } from '../@constants'
import { hasPermission } from '../@utils'
import { FatalError, PermissionError } from '../../error-handlers'
import FourOhFour from '../../../pages/four-oh-four'
import LoginAs from '../../../pages/login-as/login-as'

import { ProtectedLayout, AuthRoute } from '.'

const AppRoutes = () => {
  const [user] = useUserData()

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' errorElement={<FatalError />}>
        <Route path='/impersonate' exact element={<LoginAs />} />
        {authRouteConfig.map((r) => (
          <Route key={r.path} element={<AuthRoute />}>
            <Route path={r.path} exact={r.exact} element={r.element} />
          </Route>
        ))}
        {routeConfig.map((r) => (
          <Route key={r.path} element={<ProtectedLayout />}>
            <Route
              path={r.path}
              exact={r.exact}
              element={hasPermission(r, user) ? r.element : <PermissionError />}
              errorElement={<FatalError />}
            />
            <Route path='*' element={<FourOhFour />} />
          </Route>
        ))}
        <Route path='/' element={<Navigate to='/dashboard' />} />
      </Route>
    )
  )

  return (
    <RouterProvider
      router={router}
      fallbackElement={<Spinner size='large' mask />}
    />
  )
}

export default AppRoutes
