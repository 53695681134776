import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Flex,
  Grid,
  Tag,
  Text,
  Label,
  Switch,
} from '@beachfront/ui'
import { Field, useField } from '@beachfront/ui/forms'
import { useDisclosure } from '@beachfront/ui/hooks'
import { useTheme } from 'styled-components'

import { useUserData } from '../../../hooks'
import { isNotEmptyArray } from '../../../utils'

import { BooleanSegmentProvider } from './booleanSegment/boolean-segment-context'

import { AudienceModal, BooleanSegment } from '.'

const ManageAudienceSection = ({
  values,
  id,
  formDisable,
  pmpAvails,
  dspDeal,
  adminCreate,
}) => {
  const theme = useTheme()
  const audienceModal = useDisclosure()
  const [user] = useUserData()
  const { input: includeSegments } = useField('includeSegments')
  const { input: excludeSegments } = useField('excludeSegments')

  const allowBooleanLogic = user?.featureToggles?.audienceBooleanLogic

  const onManageAudience = () => {
    audienceModal.open()
  }

  const onModalClose = () => {
    audienceModal.close()
  }

  const onSuccessCallback = ({ include, exclude }) => {
    includeSegments.onChange(include)
    excludeSegments.onChange(exclude)
  }

  return (
    <Box overflow='initial'>
      {allowBooleanLogic ? (
        <Box className='customize-radio'>
          <Field
            name='audienceBooleanLogic.enabled'
            label='Audience Targeting Logic'
            extra={<Box m='auto' />}
            inline
          >
            <Switch
              checkedContent='Advanced'
              uncheckedContent='Simple'
              disabled={formDisable}
            />
          </Field>
        </Box>
      ) : null}
      {values.audienceBooleanLogic?.enabled && allowBooleanLogic ? (
        <BooleanSegmentProvider>
          <BooleanSegment pmpAvails={pmpAvails} formDisable={formDisable} />
        </BooleanSegmentProvider>
      ) : (
        <Grid columns={1}>
          <Box>
            <Flex justifyContent='space-between'>
              <Label>Included Segments</Label>
            </Flex>
            <Box
              mb={3}
              px='8px'
              py='6px'
              minHeight={32}
              bg='gray.3'
              borderRadius='base'
              cursor={formDisable ? 'not-allowed' : 'pointer'}
            >
              <Flex gap={1} flexWrap='wrap'>
                {isNotEmptyArray(values.includeSegments)
                  ? values.includeSegments.map((listItem) => (
                      <Tag
                        key={listItem.id}
                        color={theme.colors.primary.base}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {listItem.title}
                      </Tag>
                    ))
                  : null}
                {!isNotEmptyArray(values.includeSegments) ? (
                  <Text>No Segments Included</Text>
                ) : null}
              </Flex>
            </Box>
          </Box>
          <Box>
            <Flex justifyContent='space-between'>
              <Label>Excluded Segments</Label>
            </Flex>
            <Box
              mb={3}
              px='8px'
              py='6px'
              minHeight={32}
              bg='gray.3'
              borderRadius='base'
              cursor={formDisable ? 'not-allowed' : 'pointer'}
            >
              <Flex gap={1} flexWrap='wrap'>
                {isNotEmptyArray(values.excludeSegments)
                  ? values.excludeSegments.map((listItem) => (
                      <Tag
                        key={listItem.id}
                        color={theme.colors.primary.base}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {listItem.title}
                      </Tag>
                    ))
                  : null}
                {!isNotEmptyArray(values.excludeSegments) ? (
                  <Text>No Segments Excluded</Text>
                ) : null}
              </Flex>
            </Box>
          </Box>
          <Box>
            <Button disabled={formDisable} onClick={onManageAudience} block>
              Manage Audience
            </Button>
          </Box>
        </Grid>
      )}
      {audienceModal.isOpen ? (
        <AudienceModal
          values={values}
          id={id}
          dspDeal={dspDeal}
          pmpAvails={pmpAvails}
          adminCreate={adminCreate}
          showModal={audienceModal.isOpen}
          onModalClose={onModalClose}
          onSuccessCallback={onSuccessCallback}
        />
      ) : null}
    </Box>
  )
}

ManageAudienceSection.propTypes = {
  values: PropTypes.object,
  id: PropTypes.string,
  formDisable: PropTypes.bool,
  pmpAvails: PropTypes.bool,
  dspDeal: PropTypes.bool,
  adminCreate: PropTypes.bool,
}

export default ManageAudienceSection
