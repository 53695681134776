import React from 'react'
import PropTypes from 'prop-types'
import { Box, Input } from '@beachfront/ui'
import { SearchOutlined } from '@beachfront/ui/icons'

const StaticSearchPanel = ({ focusRef, value, setValue }) => {
  return (
    <Box width={300}>
      <Input
        ref={focusRef}
        value={value}
        onChange={setValue}
        placeholder='Search'
        suffix={<SearchOutlined />}
      />
    </Box>
  )
}

StaticSearchPanel.propTypes = {
  focusRef: PropTypes.object,
  value: PropTypes.any,
  setValue: PropTypes.func,
}

export default StaticSearchPanel
