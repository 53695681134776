import React from 'react'
import PropTypes from 'prop-types'
import { Box, Modal, Table } from '@beachfront/ui'

import { AuctionLogDetail } from '../../auction-log'
import { detailRealtimeColumns } from '../@constants'

const RealtimeDetailModal = ({ visible, config, onCancel }) => {
  return (
    <Modal
      title={config.title}
      visible={visible}
      bodyStyle={{ height: '550px', overflow: 'auto' }}
      width='80%'
      maxWidth={500}
      onCancel={onCancel}
      footer={null}
    >
      {visible ? (
        <>
          <Table
            bordered
            size='middle'
            rowKey={(rec) => rec.id}
            dataSource={[config?.data]}
            columns={detailRealtimeColumns}
            pagination={false}
            scroll={{ x: '100%' }}
          />
          <Box py={2}>
            <AuctionLogDetail id={config?.data?.id} />
          </Box>
        </>
      ) : null}
    </Modal>
  )
}

RealtimeDetailModal.propTypes = {
  visible: PropTypes.bool,
  config: PropTypes.object,
  onCancel: PropTypes.bool,
}

export default RealtimeDetailModal
