import React from 'react'
import { Card, Text, Table } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { useContentSize } from '../../../hooks'

const InvalidAppInfoGrid = ({ data }) => {
  const { contentHeight } = useContentSize()

  const table = useTableQuery({
    data: data?.map((dt) => ({ title: dt })) ?? [],
  })

  const scroll = {
    y: contentHeight - 600,
  }

  const columns = [
    {
      dataIndex: 'title',
      title: 'errors',
      sorter: true,
      render: (val) => <Text color='negative.base'>{val}</Text>,
    },
  ]

  return (
    <Card compact>
      <Table
        rowKey={(rec, index) => index}
        dataSource={table.data}
        columns={columns}
        scroll={scroll}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: false,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
    </Card>
  )
}

InvalidAppInfoGrid.propTypes = {
  data: PropTypes.array,
}

export default InvalidAppInfoGrid
