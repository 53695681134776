import React from 'react'
import PropTypes from 'prop-types'
import Keycloak from 'keycloak-js'
import { ReactKeycloakProvider } from '@react-keycloak/web'

import { TOKEN_KEY } from '../constants'
import { CommonSpinner } from '../components'

export const keycloak = new Keycloak({
  url: 'https://sso.beachfront.com/auth',
  realm: 'BFM',
  clientId: 'clearvufe',
})

export const KeycloakProvider = ({ children }) => {
  const onEvent = (event) => {
    switch (event) {
      case 'onReady':
        if (!keycloak.authenticated) {
          keycloak.login()
        }
        break

      case 'onTokenExpired':
        keycloak.updateToken()
        break

      case 'onAuthRefreshSuccess':
      case 'onAuthSuccess':
        window.accessToken = keycloak.token
        break

      case 'onInitError':
      case 'onAuthLogout':
        keycloak.logout({ redirectUri: window.location.origin })
        break

      default:
        break
    }

    localStorage.setItem(TOKEN_KEY, JSON.stringify(keycloak.token))
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ pkceMethod: 'S256' }}
      LoadingComponent={<CommonSpinner />}
      onEvent={onEvent}
    >
      {children}
    </ReactKeycloakProvider>
  )
}

KeycloakProvider.propTypes = {
  children: PropTypes.node,
}
