import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Card, Skeleton, Spinner, Select } from '@beachfront/ui'

import { isNotEmptyArray } from '../../../../utils'
import { useSegmentChart, useSegmentWidgets } from '../../../../hooks'
import { MetricCard, NetworkError } from '../../../../components'
import { AreaChart } from '../../../../components/chart'
import { sharedChartRanges } from '../@constants'

import { SegmentInsights } from '.'

const SegmentWidgets = ({ segmentId, isOwned }) => {
  const [chartType, setChartType] = useState('avails')
  const [dateRange, setDateRange] = useState(sharedChartRanges[0].value)

  const chartQuery = useSegmentChart({
    params: { segmentId, chartType, dateRange },
  })

  const widgetsQuery = useSegmentWidgets({
    params: { dateRange },
  })

  const metrics = chartQuery.data?.graph?.summary
  const chart = chartQuery.data?.graph

  const tabList = [{ key: 'avails', tab: 'Daily Requests' }]

  if (isOwned) {
    tabList.push({ key: 'users', tab: 'Daily Users' })
  }

  const getMetricTooltip = (metric) => {
    if (metric.msg === 'Monthly Avails' || metric.msg === 'Daily Avails') {
      return 'The avails data is approximate'
    }
    return ''
  }

  const tabBarExtra = (
    <Box width={140}>
      <Select
        size='small'
        value={dateRange}
        onChange={setDateRange}
        options={sharedChartRanges}
        getPopupContainer={(el) => el.parentNode}
      />
    </Box>
  )

  return (
    <Box>
      {chartQuery.isFetching ? (
        <Skeleton mb={3} height={50} />
      ) : isNotEmptyArray(metrics) ? (
        <Grid mb={3} gap={2} columns='repeat(auto-fit, minmax(150px, 1fr))'>
          {metrics.map((m, i) => (
            <MetricCard
              key={i}
              index={i}
              label={m.msg}
              value={m.data}
              tooltip={getMetricTooltip(m)}
            />
          ))}
        </Grid>
      ) : null}
      <Card
        minHeight={280}
        tabList={tabList}
        activeTabKey={chartType}
        onTabChange={setChartType}
        tabBarExtraContent={tabBarExtra}
        bodyStyle={{ position: 'relative' }}
      >
        {chartQuery.isFetching ? (
          <Spinner mask />
        ) : chart ? (
          <AreaChart
            plotProps={{ height: 250 }}
            data={chart.data}
            yAxisLabel={chart.yAxisLabel}
            toolTipLabel={chart.toolTipLabel}
          />
        ) : (
          <NetworkError
            description={
              chartQuery.data?.msg || 'No enough data to build chart'
            }
          />
        )}
      </Card>
      <Card mt={3} tabList={[{ key: 'insights', tab: 'Insights' }]}>
        <SegmentInsights
          segmentId={segmentId}
          widgetsData={widgetsQuery.data}
          isLoading={widgetsQuery.isFetching}
        />
      </Card>
    </Box>
  )
}

SegmentWidgets.propTypes = {
  segmentId: PropTypes.string,
  isOwned: PropTypes.bool,
}

export default SegmentWidgets
