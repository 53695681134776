import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from '@beachfront/ui'

import { MEDIA_TYPE } from '../../../enums'
import { formatDate } from '../../../utils'

export const dealLibraryColumns = [
  {
    dataIndex: 'title',
    title: 'Name',
    fixed: 'left',
    sorter: true,
    filter: true,
    width: 250,
    render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
  },
  {
    dataIndex: 'status',
    title: 'Status',
    sorter: true,
    filter: true,
    width: 140,
    render: (val) =>
      val ? (
        <Badge status='success' text='Enabled' />
      ) : (
        <Badge status='error' text='Disabled' />
      ),
  },
  {
    dataIndex: 'mediaType',
    title: 'Media Type',
    sorter: true,
    filter: true,
    width: 140,
    render: (key) => MEDIA_TYPE.fromKey(key.toLowerCase())?.name,
  },
  {
    dataIndex: 'createDate',
    title: 'Created',
    sorter: true,
    filter: true,
    configurable: true,
    width: 200,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'updateDate',
    title: 'Last Updated',
    sorter: true,
    filter: true,
    configurable: true,
    width: 200,
    render: (val) => formatDate(val),
  },
]
