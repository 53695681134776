import React, { useMemo } from 'react'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { useTheme } from 'styled-components'
import { Box, Table, Empty } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import { api } from '../../../client-api'
import { isNotEmptyArray, getErrorMessage } from '../../../utils'
import { NetworkError, CommonSpinner } from '../../../components'
import { CustomTooltip } from '../../../components/widget/custom-tooltip'
import { topSpendColumns } from '../@constants'

const DATE_FORMATS = {
  DAY: {
    full: 'D-MMM-YYYY',
    small: 'D MMM',
  },
  HOUR: {
    full: 'YYYY-MM-DD HH:mm',
    small: 'HH:00',
  },
}

const TopSpend = ({ requestPayload }) => {
  const theme = useTheme()
  const response = useTopSpend(requestPayload)

  const hourly = response.data?.details.hourly === 'true'
  const fieldName = hourly ? 'hour' : 'day'
  const fieldFormat = hourly ? DATE_FORMATS.HOUR : DATE_FORMATS.DAY

  const chartData = useMemo(() => {
    if (response.data?.rows) {
      return response.data.rows
        .map((row) => ({
          ...row,
          time: moment(row[fieldName]).format(fieldFormat.small),
        }))
        .sort(
          (a, b) => moment(a[fieldName]).unix() - moment(b[fieldName]).unix()
        )
    }
    return []
  }, [response.data, fieldName, fieldFormat])

  const table = useTableQuery({
    data: chartData,
    defaultPageSize: 25,
  })

  const columns = [
    {
      dataIndex: fieldName,
      title: 'Top Spend',
      className: 'key',
      width: 200,
      sorter: true,
    },
    ...topSpendColumns,
  ]

  if (response.isFetching) {
    return <CommonSpinner />
  }

  if (response.error) {
    return (
      <NetworkError
        description={getErrorMessage(response.error)}
        onAction={response.refetch}
      />
    )
  }

  const formatCurrency = (val) => `$${val}`
  const formatPercent = (val) => `${val}%`

  return (
    <Box height='100%'>
      <Box height={255}>
        {isNotEmptyArray(response.data?.rows) ? (
          <ResponsiveContainer width='100%' height='100%'>
            <LineChart
              width={500}
              height={235}
              data={chartData}
              margin={{
                top: 15,
                right: 30,
                left: 20,
                bottom: 15,
              }}
            >
              <CartesianGrid vertical={false} stroke={theme.colors.gray[4]} />
              <XAxis
                dataKey='time'
                strokeWidth='1'
                interval='preserveStartEnd'
                axisLine={{ stroke: theme.colors.gray[4] }}
                tickLine={{ stroke: theme.colors.gray[4] }}
                tick={{ fill: theme.colors.gray[6] }}
                tickMargin={8}
                padding={{ left: 8, right: 8 }}
              />
              <YAxis
                yAxisId='left'
                tickFormatter={formatCurrency}
                axisLine={false}
                tickLine={false}
                tick={{ fill: theme.colors.gray[6] }}
              />
              <YAxis
                yAxisId='right'
                orientation='right'
                tickFormatter={formatPercent}
                axisLine={false}
                tickLine={false}
                tick={{ fill: theme.colors.gray[6] }}
              />
              <Tooltip
                isAnimationActive={false}
                cursor={{ stroke: theme.colors.gray[3] }}
                wrapperStyle={{ zIndex: 1 }}
                content={<CustomTooltip unit={['$', '%']} />}
              />
              <Line
                yAxisId='left'
                type='monotone'
                dataKey='revenuegross'
                strokeWidth='2'
                stroke={theme.colors.primary[6]}
                dot={{ fill: theme.colors.bg.component, fillOpacity: 1 }}
                activeDot={{ stroke: theme.colors.bg.component }}
              />
              <Line
                yAxisId='right'
                type='monotone'
                dataKey='takeRate'
                strokeWidth='2'
                stroke={theme.colors.primary[3]}
                dot={{ fill: theme.colors.bg.component, fillOpacity: 1 }}
                activeDot={{ stroke: theme.colors.bg.component }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='No Chart Data Found'
          />
        )}
      </Box>
      <Box style={{ height: 'calc(100% - 255px)' }}>
        <Table
          rowKey='_index'
          emptyText='No Data Available'
          columns={columns}
          dataSource={table.data}
          loading={response.isFetching}
          scroll={{ y: 232 }}
          pagination={{
            ...table.query.pagination,
            hideOnSinglePage: true,
            total: table.total,
          }}
          onChange={(e) => {
            table.setPagination(e.pagination)
            table.setSorter(e.sorter)
          }}
        />
      </Box>
    </Box>
  )
}

const useTopSpend = (payload) => {
  return useQuery({
    queryKey: ['top-spend', payload],
    queryFn: async () => {
      const { data } = await api.realtime.transparency.topSpends(payload)
      return data
    },
    staleTime: 30000,
  })
}

export default TopSpend
