import React, { useState } from 'react'
import { Flex, Grid, Box, Card, Button, Empty } from '@beachfront/ui'
import { DownOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { isNotEmptyArray } from '../../../utils'
import { useFeaturedDeals } from '../../../hooks'
import { CommonSpinner, NetworkError } from '../../../components'

import { DealFeatureCard } from '.'

const DealFeatureList = ({ filters, timezone, searchQuery, showEmpty }) => {
  const stepCount = 6
  const [maxCount, setMaxCount] = useState(stepCount)

  const mediaType = filters?.find((f) => f.dataIndex === 'mediaType')?.value
  const platform = filters?.find((f) => f.dataIndex === 'platform')?.value
  const deals = useFeaturedDeals({
    params: { mediaType, platform, timezone, searchQuery },
  })

  if (
    !deals.isFetching &&
    !deals.error &&
    !showEmpty &&
    !isNotEmptyArray(deals.data)
  ) {
    return null
  }

  return (
    <Card title='Featured Deals'>
      {deals.isFetching ? (
        <Box height={291}>
          <CommonSpinner />
        </Box>
      ) : deals.error ? (
        <Box height={291}>
          <NetworkError
            description={
              deals.error ?? 'Your request was not processed. Please try again.'
            }
            buttonLabel='Refresh'
            onAction={deals.refetch}
          />
        </Box>
      ) : isNotEmptyArray(deals.data) ? (
        <>
          <Grid columns='repeat(auto-fill, minmax(308px, 1fr))' gap={3}>
            {deals.data.slice(0, maxCount).map((deal) => (
              <DealFeatureCard key={deal.id} deal={deal} />
            ))}
          </Grid>
          {maxCount < deals.data.length ? (
            <Flex mt={3} justifyContent='center'>
              <Button
                type='text'
                icon={<DownOutlined />}
                onClick={() => setMaxCount((c) => c + stepCount)}
              >
                Show More
              </Button>
            </Flex>
          ) : null}
        </>
      ) : (
        <Flex height={291} justifyContent='center' alignItems='center'>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='No Featured Deals Found'
          />
        </Flex>
      )}
    </Card>
  )
}

DealFeatureList.defaultProps = {
  showEmpty: true,
}

DealFeatureList.propTypes = {
  filters: PropTypes.array,
  timezone: PropTypes.string,
  searchQuery: PropTypes.string,
  showEmpty: PropTypes.bool,
}

export default DealFeatureList
