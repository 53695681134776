import { createEnum } from '../utils'

export const DSP_TYPE = createEnum({
  DIRECT: {
    key: 'direct',
    name: 'Direct',
  },
  INDIRECT: {
    key: 'indirect',
    name: 'Indirect',
  },
})
