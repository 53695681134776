import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FieldControl, Help } from '@beachfront/ui'
import { useDebounceCallback } from '@beachfront/ui/hooks'
import { useField } from '@beachfront/ui/forms'

import { useFetch } from '../../../hooks'
import { isArray, isNotEmptyArray } from '../../../utils'
import { SelectField, LabelTooltip } from '../../../components'

const VirtualSelectField = ({
  mode,
  values,
  initialValues,
  request,
  isMediaPresent,
  apiParams,
  pagination,
  initialPageSize,
  form,
  tooltip,
  detail,
  hasMultiOption,
  hideOnNoValues,
  onChange,
  getPopupContainer,
  ...rest
}) => {
  const inintialParams = { ...apiParams, q: '' }
  const initialPagination = {
    p: 0, // page number
    s: initialPageSize, // page size
  }

  if (pagination) {
    Object.assign(inintialParams, initialPagination)
  }

  const [params, setParams] = useState(inintialParams)
  const [responseData, setResponseData] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const { name, textField, keyField, placeholder, selectionName, label } = form
  const { input } = useField(name)
  const { input: selectionInput } = useField(selectionName)
  const [internalValue, setInternalValue] = useState(input.value)

  const effectiveValue =
    isOpen && mode === 'multiple' ? internalValue : input.value

  const response = useFetch({
    request,
    payload: params,
  })

  const onSearch = useDebounceCallback((val) => {
    setParams((p) => {
      const params = { ...p, q: val }
      if (val && pagination) {
        Object.assign(params, initialPagination)
      }
      return params
    })
  }, 300)

  const onPopupScroll = (e) => {
    const bottom =
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
    if (bottom < 20 && !response.loading && response.data?.hm && pagination) {
      const { p } = params
      setParams({ ...params, p: p + 1 })
    }
  }

  const onInputChange = (key) => {
    if (mode === 'multiple') {
      const selected = isArray(key)
        ? responseData.filter((d) => key.some((k) => k === d.key))
        : []
      selectionInput.onChange(selected)
    } else {
      const selected = key
        ? responseData.find((d) => d.id === key || d.key === key)
        : undefined
      selectionInput.onChange(selected)
    }
    input.onChange(key)
    onChange?.(key)
  }

  const onSelectChange = (key) => {
    if (isOpen && mode === 'multiple') {
      setInternalValue(key)
    } else {
      onInputChange(key)
    }
  }

  const isEqualArray = (a, b) => {
    return JSON.stringify(a || []) === JSON.stringify(b || [])
  }

  const onDropdownVisibleChange = (open) => {
    if (mode === 'multiple') {
      if (open) {
        setInternalValue(input.value)
      } else if (!isEqualArray(internalValue, input.value)) {
        onInputChange(internalValue)
      }
    }
    if (!open) {
      setParams((p) => ({ ...p, q: '' }))
    }
    setIsOpen(open)
  }

  useEffect(() => {
    let list = [...responseData]

    if (pagination) {
      if (
        detail &&
        response.loading &&
        mode === 'multiple' &&
        isNotEmptyArray(values[name]) &&
        isNotEmptyArray(values[selectionName])
      ) {
        list = values[selectionName]
      }
      if (!response.loading) {
        const existingList = [...list]
        let responseList = []

        if (isNotEmptyArray(response.data)) {
          responseList = [...response.data]
        } else if (isArray(response.data.data)) {
          responseList = [...response.data.data]
        }
        let existingElement = responseList.filter(
          (a) => !existingList.some((b) => a[keyField] === b[keyField])
        )
        list = [...existingList, ...existingElement]
      }
    } else {
      if (
        detail &&
        response.loading &&
        mode === 'multiple' &&
        isNotEmptyArray(values[name]) &&
        isNotEmptyArray(values[selectionName])
      ) {
        list = values[selectionName]
      }
      if (!response.loading) {
        if (isArray(response.data)) {
          list = response.data
        } else if (isArray(response.data.data)) {
          list = response.data.data
        } else if (isNotEmptyArray(initialValues[selectionName])) {
          list = initialValues[selectionName]
        } else {
          list = []
        }
      }
    }
    setResponseData(list)
  }, [
    JSON.stringify(response.data),
    JSON.stringify(values),
    JSON.stringify(initialValues[selectionName]),
  ])

  if (hideOnNoValues && (!responseData || !isNotEmptyArray(responseData))) {
    return null
  }

  return (
    <>
      <FieldControl
        label={label}
        extra={
          <LabelTooltip
            content={tooltip}
            getPopupContainer={getPopupContainer ?? ((el) => el.parentNode)}
          />
        }
      >
        <SelectField
          {...input}
          value={effectiveValue}
          mode={mode}
          data={responseData || []}
          loading={response.loading}
          keyField={keyField}
          textField={textField}
          placeholder={placeholder}
          hasMultiOption={hasMultiOption}
          open={isOpen}
          onSearch={onSearch}
          onPopupScroll={onPopupScroll}
          onChange={onSelectChange}
          onDropdownVisibleChange={onDropdownVisibleChange}
          getPopupContainer={getPopupContainer}
          showSearch
          {...rest}
        />
      </FieldControl>
      {isMediaPresent ? (
        <Help status='default' mb={3} mt={-2}>
          Bundle and Domain can&apos;t be selected together.
        </Help>
      ) : null}
    </>
  )
}

VirtualSelectField.defaultProps = {
  form: {},
  initialValues: {},
  initialPageSize: 100,
  isMediaPresent: false,
}

VirtualSelectField.propTypes = {
  mode: PropTypes.string,
  values: PropTypes.object,
  isMediaPresent: PropTypes.bool,
  initialValues: PropTypes.object,
  request: PropTypes.func,
  apiParams: PropTypes.object,
  pagination: PropTypes.bool,
  initialPageSize: PropTypes.number,
  form: PropTypes.object,
  tooltip: PropTypes.node,
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasMultiOption: PropTypes.bool,
  hideOnNoValues: PropTypes.bool,
  onChange: PropTypes.func,
  getPopupContainer: PropTypes.func,
}

export default VirtualSelectField
