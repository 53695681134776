import http from '../http'

const ENDPOINT = 'auction/'

export default {
  logs({ p, s, ...payload }) {
    const params = { p, s }
    return http.post(`${ENDPOINT}logs/get`, payload, { params })
  },

  export({ p, s, ...payload }) {
    const params = { p, s }
    return http.post(`${ENDPOINT}logs/download/auctionlog.csv`, payload, {
      params,
    })
  },

  bids(params) {
    return http.get(`${ENDPOINT}logs/getById`, { params })
  },

  publishers() {
    return http.get(`${ENDPOINT}logs/get/publishers`)
  },

  failedReasons() {
    return http.get(`${ENDPOINT}logs/get/failed-reasons`)
  },
}
