import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Table } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { useContentSize } from '../../../hooks'
import { PageSearch } from '../../../components'

const AttachDealTable = ({ id, params, superAdmin }) => {
  const { contentHeight } = useContentSize()
  const [searchQuery, setSearchQuery] = useState('')

  if (params) {
    delete params.id
  }

  const { data: attachedDealsData, isLoading } = useQuery({
    queryKey: ['mediaplan', 'deals', id],
    queryFn: async () => {
      const { data } = await api.mediaPlan.attachDealsById({ id })
      return data
    },
  })

  const matchesQuery = (dt) => {
    return ['title', 'dealId'].some((key) =>
      String(dt[key]).toLowerCase().includes(searchQuery.toLowerCase())
    )
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const table = useTableQuery({
    data: applyQuery(attachedDealsData?.content || []),
  })

  const scroll = {
    y: contentHeight - 450,
  }

  const dealPath = superAdmin ? '/admin/deals' : '/deals/self'

  const columns = [
    {
      dataIndex: 'title',
      title: 'Title',
      width: 200,
      sorter: true,
      render: (val, rec) => (
        <Link to={`${dealPath}/${rec.id}`} target='_blank'>
          {val}
        </Link>
      ),
    },
    {
      dataIndex: 'dealId',
      title: 'Deal ID',
      width: 160,
      sorter: true,
    },
    {
      dataIndex: 'attatchedOn',
      title: 'Attached On',
      width: 160,
      sorter: true,
      render: (val) => moment(val).format('YYYY-MM-DD hh:mm a'),
    },
  ]

  const extra = <PageSearch value={searchQuery} onChange={setSearchQuery} />

  return (
    <Card title='Attached Deals' extra={extra}>
      <Table
        emptyText='No Attached Deals Found'
        dataSource={table.data}
        columns={columns}
        loading={isLoading}
        scroll={scroll}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: false,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
    </Card>
  )
}

AttachDealTable.defaultProps = {
  params: {},
}

AttachDealTable.propTypes = {
  id: PropTypes.string,
  params: PropTypes.object,
  superAdmin: PropTypes.bool,
}

export default AttachDealTable
