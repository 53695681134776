import React, { useState, useRef, useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  Grid,
  Flex,
  Box,
  Card,
  Button,
  Tooltip,
  Tabs,
  Modal,
  useToast,
} from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import {
  ReloadOutlined,
  EditOutlined,
  DeleteFilled,
} from '@beachfront/ui/icons'
import { useDisclosure } from '@beachfront/ui/hooks'

import { api } from '../../../client-api'
import { MEDIA_LIST_TYPE } from '../../../enums'
import { formatDate, getErrorMessage } from '../../../utils'
import { useContentSize, useFilters, useInfiniteTable } from '../../../hooks'
import { localFilterKey } from '../../../components/filter'
import {
  PageHeader,
  PageSearch,
  DirtyPrompt,
  NetworkError,
  CommonSpinner,
  InfiniteTable,
} from '../../../components'
import FourOhFour from '../../four-oh-four'
import { validateMediaListForm } from '../@utils'

import {
  MediaListForm,
  MediaListItemFilter,
  MediaListItemModal,
  MediaListHistory,
} from '.'

const UpdateMediaList = () => {
  const { mediaType, id, tabKey = 'manage' } = useParams()
  const { contentHeight } = useContentSize()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToast()
  const itemModal = useDisclosure()
  const [searchQuery, setSearchQuery] = useState('')

  const filterRef = useRef()
  const filterKey = `${localFilterKey.MEDIA_LIST_ITEMS}-${id}`
  const [filters, setFilters] = useFilters(filterKey, {
    schema: {
      createDate: 'date',
    },
  })

  const mediaList = useQuery({
    queryKey: ['media-list', id],
    queryFn: async () => {
      const res = await api.mediaList.getById({ id })
      return res.data
    },
  })

  const table = useInfiniteTable({
    queryKey: ['media-list-items', id],
    queryFn: api.mediaList.getListItems,
    filters: filters.populated,
    params: {
      id: id,
      type: mediaType,
      q: searchQuery,
    },
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 358,
  }

  const initialValues = useMemo(() => {
    const data = {}
    if (mediaList.data) {
      Object.assign(data, mediaList.data, {
        mediaType: mediaList.data.mediaType || mediaType,
      })
    }
    return data
  }, [mediaList.data, mediaType])

  const onSubmit = (values) => {
    const payload = {
      title: values.title,
    }

    return api.mediaList.update(values.mediaType, id, payload).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'Media list updated.' })
          mediaList.refetch()
        } else {
          toast.error({
            title:
              res.data?.msg ||
              res.data.errorDetails ||
              'Unable to update this media list. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const onDelete = () => {
    Modal.confirm({
      title: 'Delete Media List',
      content: `Are you sure you want to delete media list "${mediaList.data?.title}"?`,
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.mediaList.delete(id).then(
          () => {
            toast.success({ title: 'Media list deleted.' })
            navigate(`/medialist/${mediaType}`)
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
            throw error
          }
        )
      },
    })
  }

  const onDeleteItem = (record) => {
    Modal.confirm({
      title: 'Delete List Item',
      content: `Are you sure you want to delete "${record.title}"?`,
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.mediaList.deleteItem(id, record.id).then(
          () => {
            queryClient.invalidateQueries({
              queryKey: ['media-list-items', id],
            })
            mediaList.refetch()
            toast.success({ title: 'List item deleted.' })
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
            throw error
          }
        )
      },
    })
  }

  const onRefetch = () => {
    mediaList.refetch()
    table.refetch()
  }

  const onTabChange = (key) => {
    return navigate(`/medialist/${mediaType}/${id}/${key}`)
  }

  const columns = [
    {
      dataIndex: 'title',
      title: MEDIA_LIST_TYPE.fromKey(mediaType)?.name,
      sorter: true,
      width: 250,
    },
    {
      dataIndex: 'createDate',
      title: 'Created',
      sorter: true,
      filter: true,
      width: 200,
      render: (val) => formatDate(val),
    },
    {
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 45,
      render: (val, rec) => (
        <Button
          danger
          type='link'
          size='small'
          onClick={() => onDeleteItem(rec)}
          icon={<DeleteFilled />}
        />
      ),
    },
  ]

  if (!MEDIA_LIST_TYPE.includes(mediaType)) {
    return <FourOhFour />
  }

  if (mediaList.error) {
    return (
      <NetworkError
        description='Media List Not Found'
        buttonLabel='Back to Media Lists'
        onAction={() => navigate(`/medialist/${mediaType}`)}
      />
    )
  }

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <MediaListItemFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
        <Tooltip title='Edit Items' placement='topRight' arrowPointAtCenter>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={() => itemModal.open()}
            style={{ flex: 'none' }}
          />
        </Tooltip>
      </Flex>
    </InfiniteTable.Toolbar>
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validateMediaListForm}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, submitting, dirty }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title={`Edit Media List - ${mediaList.data?.title ?? ''}`}
            actions={
              <>
                {tabKey === 'manage' ? (
                  <>
                    <PageSearch
                      value={searchQuery}
                      onChange={setSearchQuery}
                      placeholder='Search List Items'
                    />
                    <Button
                      onClick={onRefetch}
                      icon={<ReloadOutlined />}
                      style={{ flex: 'none' }}
                    />
                  </>
                ) : null}
                <Link to={`/medialist/${mediaType}`}>
                  <Button>Cancel</Button>
                </Link>
                <Button onClick={onDelete} danger>
                  Delete
                </Button>
                {tabKey === 'manage' ? (
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={!dirty}
                    loading={submitting}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                ) : null}
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3}>
            <Tabs type='card' activeKey={tabKey} onChange={onTabChange}>
              <Tabs.TabPane key='manage' tab='Manage'>
                <Box mt={2} height={['auto', 'auto', 'calc(100vh - 190px)']}>
                  {mediaList.isFetching ? (
                    <CommonSpinner />
                  ) : (
                    <Grid gap={3} columns={[1, 1, '380px 1fr']} height='100%'>
                      <Card>
                        <MediaListForm
                          mediaListId={id}
                          count={values.count}
                          showInputType={false}
                        />
                      </Card>
                      <Card>
                        {!table.isFetching && table.error ? (
                          <NetworkError
                            description={getErrorMessage(table.error)}
                            onAction={table.refetch}
                          />
                        ) : (
                          <InfiniteTable
                            rowKey='id'
                            emptyText='No List Items Found'
                            columns={columns}
                            toolbar={toolbar}
                            scroll={scroll}
                            filters={filters.populated}
                            onFilter={(key) =>
                              filterRef.current?.addFilter(key)
                            }
                            {...table.props}
                          />
                        )}
                      </Card>
                    </Grid>
                  )}
                  <MediaListItemModal
                    listId={id}
                    mediaType={mediaType}
                    isOpen={itemModal.isOpen}
                    onClose={itemModal.close}
                    onSuccess={onRefetch}
                  />
                </Box>
              </Tabs.TabPane>
              <Tabs.TabPane key='history' tab='History'>
                <MediaListHistory mediaListId={id} />
              </Tabs.TabPane>
            </Tabs>
          </Box>
        </form>
      )}
    </Form>
  )
}

export default UpdateMediaList
