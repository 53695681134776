import { formatCount } from '../../../utils'

export const platformColumns = [
  {
    dataIndex: 'name',
    title: '',
    fixed: 'left',
    width: 160,
  },
  {
    dataIndex: 'ftrack',
    title: 'FTrackHHID',
    width: 140,
    sorter: true,
    render: (val) => formatCount(val),
  },
  {
    dataIndex: 'ip',
    title: 'IP',
    width: 140,
    sorter: true,
    render: (val) => formatCount(val),
  },
  {
    dataIndex: 'ifa',
    title: 'IFA',
    width: 140,
    sorter: true,
    render: (val) => formatCount(val),
  },
]

export const demographicColumns = [
  {
    dataIndex: 'name',
    title: 'Demographic Area',
    fixed: 'left',
    width: 220,
    sorter: true,
  },
  {
    dataIndex: 'ftrack',
    title: 'FTrackHHID',
    width: 120,
    sorter: true,
    render: (val) => formatCount(val),
  },
  {
    dataIndex: 'ip',
    title: 'IP',
    width: 120,
    sorter: true,
    render: (val) => formatCount(val),
  },
  {
    dataIndex: 'ifa',
    title: 'IFA',
    width: 120,
    sorter: true,
    render: (val) => formatCount(val),
  },
]
