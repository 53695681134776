import React, { useState } from 'react'
import { Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

import { TIMEZONE, DATE_RANGES } from '../../enums'
import {
  useDashboardCharts,
  useInsightWidgets,
  useFilters,
  useDateUrlSync,
} from '../../hooks'
import { PageHeader, TimezoneSelect } from '../../components'
import { localFilterKey } from '../../components/filter'

import { DashboardCharts, DashboardFilter } from './@components'

const Dashboard = ({ admin }) => {
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)

  const storageKey = admin
    ? localFilterKey.DASHBOARD_ADMIN
    : localFilterKey.DASHBOARD

  const [filters, setFilters] = useFilters(storageKey, {
    initialState: [
      { dataIndex: 'date', operator: 'eq', value: DATE_RANGES['Last 7 Days'] },
    ],
    schema: { date: 'date' },
  })

  useDateUrlSync({
    filters: filters.populated,
    onChange: setFilters,
  })

  const params = getRequestParams(filters.populated, timezone)

  if (admin) {
    params.admin = true
  }

  const dashboards = useDashboardCharts(params)
  const widgets = useInsightWidgets()

  const onRefetch = () => {
    dashboards.refetch()
    widgets.refetch()
  }

  const headerActions = (
    <>
      <Box minWidth={160}>
        <TimezoneSelect value={timezone} onChange={setTimezone} />
      </Box>
      <Button onClick={onRefetch} icon={<ReloadOutlined />} />
    </>
  )

  return (
    <>
      <PageHeader title='Dashboard' actions={headerActions}>
        <Box mt={3}>
          <DashboardFilter filters={filters.all} setFilters={setFilters} />
        </Box>
      </PageHeader>
      <Box mt={3}>
        <DashboardCharts
          chartData={dashboards.data}
          widgetData={widgets.data}
          params={params}
          timezone={timezone}
          loading={dashboards.isFetching || widgets.isFetching}
          error={dashboards.error || widgets.error}
          onRefetch={onRefetch}
        />
      </Box>
    </>
  )
}

const getRequestParams = (filters, timezone) => {
  const dateRange = filters.find((f) => f.dataIndex === 'date')?.value

  return {
    type: 'custom',
    startDate: dateRange?.[0].format('YYYY-MM-DD'),
    endDate: dateRange?.[1].format('YYYY-MM-DD'),
    today: dateRange ? dateRange[1].isSame(moment(), 'day') : false,
    timezone: timezone,
    previous: true,
  }
}

Dashboard.defaultProps = {
  admin: false,
}

Dashboard.propTypes = {
  admin: PropTypes.bool,
}

export default Dashboard
