import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { useAccounts, useFilters } from '../../../hooks'
import { PageHeader } from '../../../components'
import { localFilterKey } from '../../../components/filter'

import { AccountTable } from './@components'

const Accounts = () => {
  const [filters, setFilters] = useFilters(localFilterKey.ADMIN_ACCOUNT)
  const navigate = useNavigate()
  const accounts = useAccounts({ filters: filters.populated })

  const headerActions = (
    <>
      <Button onClick={accounts.refetch} icon={<ReloadOutlined />} />
      <Button type='primary' onClick={() => navigate('/admin/account/create')}>
        Create Account
      </Button>
    </>
  )

  return (
    <>
      <PageHeader title='Admin - Accounts' actions={headerActions} />
      <Box mt={2}>
        <AccountTable
          accounts={accounts}
          filters={filters}
          setFilters={setFilters}
          loading={accounts.isFetching}
          error={accounts.error}
          onRefetch={accounts.refetch}
        />
      </Box>
    </>
  )
}

export default Accounts
