import React from 'react'
import { Box, Flex, Text, Table, Empty } from '@beachfront/ui'
import styled from 'styled-components'

import { api } from '../../../client-api'
import { useFetch } from '../../../hooks'
import { isNotEmptyArray } from '../../../utils'
import { NetworkError, CommonSpinner } from '../../../components'
import {
  leftDetailColumn,
  rightDetailColumn,
  bidDetailColumns,
} from '../@constants'
import { getDetailRowData } from '../@utils'

const StyledTable = styled(Table)`
  .auction-won-row {
    background: ${(p) => p.theme.colors.gold.light} !important;

    td {
      background: inherit !important;
    }
  }
`

const AuctionLogDetail = ({ id }) => {
  const response = useFetch({
    request: api.auction.bids,
    payload: { id },
    schema: {
      data: '',
    },
  })

  const leftTableRows = getDetailRowData(response.data?.details)
  const rightTableRows = getDetailRowData(response.data?.rightDetails)

  if (response.loading) {
    return (
      <Box minHeight={200}>
        <CommonSpinner />
      </Box>
    )
  }

  if (response.error) {
    return (
      <NetworkError
        description={response.error || 'Detail not found'}
        onAction={response.refresh}
      />
    )
  }

  return (
    <Box minHeight={200}>
      <Text mb={3} fontSize={2} strong>
        Details
      </Text>
      <Flex width={1} gap={2}>
        <Box
          height={225}
          width='60%'
          border='split'
          borderRadius='base'
          bg='bg.component'
          overflow='auto'
        >
          {isNotEmptyArray(leftTableRows) ? (
            <Table
              size='small'
              columns={leftDetailColumn}
              dataSource={leftTableRows}
              showHeader={false}
              pagination={false}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='Detail not found'
            />
          )}
        </Box>
        <Box
          height={225}
          width='40%'
          border='split'
          borderRadius='base'
          bg='bg.component'
          overflow='auto'
        >
          {isNotEmptyArray(rightTableRows) ? (
            <Table
              size='small'
              columns={rightDetailColumn}
              dataSource={rightTableRows}
              showHeader={false}
              pagination={false}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='Detail not found'
            />
          )}
        </Box>
      </Flex>
      <Text my={3} fontSize={2} strong>
        Bids Data
      </Text>
      <Box width={1} border='split' borderRadius='base' bg='bg.component'>
        <StyledTable
          rowKey={(r, i) => i}
          rowClassName={(rec) => (rec.won ? 'auction-won-row' : '')}
          emptyText='Bids data not found'
          sticky={{ offsetHeader: -16 }}
          dataSource={response.data?.rows ?? []}
          columns={bidDetailColumns}
          pagination={false}
        />
      </Box>
    </Box>
  )
}

export default AuctionLogDetail
