import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@beachfront/ui'

import { api } from '../../../../../client-api'
import { getErrorMessage } from '../../../../../utils'
import {
  useContentSize,
  useFilters,
  useInfiniteTable,
} from '../../../../../hooks'
import { localFilterKey } from '../../../../../components/filter'
import { InfiniteTable, NetworkError } from '../../../../../components'
import { accountHistoryColumns } from '../../@constants'

import { AccountHistoryFilter } from '.'

const AccountHistory = ({ accountId }) => {
  const { contentWidth, contentHeight } = useContentSize()

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.ACCOUNT_HISTORY, {
    schema: {
      createDate: 'date',
    },
  })

  const table = useInfiniteTable({
    queryKey: ['account-history', accountId],
    queryFn: api.admin.audit.getAll,
    filters: filters.populated,
    params: { id: accountId, s: true, q: '' },
  })

  const scroll = {
    x: contentWidth - 300,
    y: contentHeight - 323,
  }

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <AccountHistoryFilter
        filterRef={filterRef}
        filters={filters.all}
        setFilters={setFilters}
      />
    </InfiniteTable.Toolbar>
  )

  return (
    <Box mt={2}>
      {!table.isFetching && table.error ? (
        <NetworkError
          description={getErrorMessage(table.error)}
          onAction={table.refetch}
        />
      ) : (
        <InfiniteTable
          rowKey='id'
          emptyText='No History Found'
          columns={accountHistoryColumns}
          toolbar={toolbar}
          scroll={scroll}
          filters={filters.populated}
          onFilter={(key) => filterRef.current?.addFilter(key)}
          {...table.props}
        />
      )}
    </Box>
  )
}

AccountHistory.propTypes = {
  accountId: PropTypes.string,
}

export default AccountHistory
