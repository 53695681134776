import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Flex,
  Box,
  Label,
  Input,
  InputNumber,
  Select,
} from '@beachfront/ui'
import { Field, useFormState } from '@beachfront/ui/forms'

import { isNotEmptyArray } from '../../../utils'
import { SelectField } from '../../../components'
import { yesNoList } from '..'

const AccountDisplayForm = ({ accountFilters = {}, getPopupContainer }) => {
  const { values } = useFormState()

  const getAdServerOptions = () => {
    const adServerOptions = accountFilters.adServer ?? []

    if (isNotEmptyArray(values.displayAdServer)) {
      const includesOther = values.displayAdServer.includes('Other')
      return adServerOptions.map((dt) => ({
        ...dt,
        disabled: includesOther ? dt.key !== 'Other' : dt.key === 'Other',
      }))
    }

    return adServerOptions
  }

  return (
    <Grid gap={2} columns={['1fr 1fr', '2fr 3fr']}>
      <Label pt={1} whiteSpace='normal'>
        Foreign Language Content
      </Label>
      <Box>
        <Field name='displayForeignLanguage'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.foreignLanguageContent || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Break out tags to target specific bundles of content?
      </Label>
      <Box>
        <Field name='displayBundleBreakTag'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Any Brand Blocks?
      </Label>
      <Box>
        <Field name='displayBrandBlocks'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        First Price Auction?
      </Label>
      <Box>
        <Field name='displayFirstPriceAuction'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Minimum CPM
      </Label>
      <Flex gap={2}>
        <Field name='displayCpmOps'>
          <Select
            defaultValue='EQUAL'
            style={{ width: '120px' }}
            getPopupContainer={getPopupContainer}
          >
            <Select.Option value='EQUAL'>Equal</Select.Option>
            <Select.Option value='GREATER_THAN'>Greater than</Select.Option>
            <Select.Option value='LESS_THAN'>Less than</Select.Option>
          </Select>
        </Field>
        <Field name='displayMinCpm' style={{ width: '100%' }}>
          <InputNumber min={1} placeholder='Minimum CPM' />
        </Field>
      </Flex>
      <Label pt={1} whiteSpace='normal'>
        Is this AMP Inventory?
      </Label>
      <Box>
        <Field name='displayamp'>
          <SelectField
            allowClear
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={yesNoList}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      <Label pt={1} whiteSpace='normal'>
        Ad Server
      </Label>
      <Box>
        <Field name='displayAdServer'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={getAdServerOptions()}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
      {values.displayAdServer?.includes('Other') ? (
        <>
          <Label pt={1} whiteSpace='normal'>
            Other Ad Server
          </Label>
          <Box>
            <Field name='displayOtherAdServer'>
              <Input placeholder='Enter a value' />
            </Field>
          </Box>
        </>
      ) : null}
      <Label pt={1} whiteSpace='normal'>
        Content Type
      </Label>
      <Box>
        <Field name='displaycontentType'>
          <SelectField
            allowClear
            mode='multiple'
            keyField='key'
            textField='value'
            placeholder='Select an option'
            data={accountFilters.contentType || []}
            getPopupContainer={getPopupContainer}
          />
        </Field>
      </Box>
    </Grid>
  )
}

AccountDisplayForm.propTypes = {
  accountFilters: PropTypes.object,
  getPopupContainer: PropTypes.func,
}

export default AccountDisplayForm
