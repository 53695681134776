import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import { Flex, Box, Button, Modal, useToast } from '@beachfront/ui'
import { ReloadOutlined, DeleteFilled } from '@beachfront/ui/icons'

import { api } from '../../client-api'
import { getErrorMessage } from '../../utils'
import { useContentSize, useFilters, useInfiniteTable } from '../../hooks'
import { localFilterKey } from '../../components/filter'
import {
  PageHeader,
  PageSearch,
  InfiniteTable,
  NetworkError,
} from '../../components'

import { reportColumns } from './@constants'
import { ReportFilter } from './@components'

const OwnedReports = () => {
  const { contentHeight } = useContentSize()
  const navigate = useNavigate()
  const toast = useToast()
  const [searchQuery, setSearchQuery] = useState('')

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.OWNED_REPORTS, {
    schema: {
      lastRun: 'date',
      createDate: 'date',
    },
  })

  const table = useInfiniteTable({
    queryKey: ['reports', 'owned'],
    queryFn: api.report.getList,
    filters: filters.populated,
    params: { shared: false, q: searchQuery },
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 260,
  }

  const onDelete = (record) => {
    Modal.confirm({
      title: 'Delete Report',
      content: `Are you sure you want to delete report "${record.name}"?`,
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.report.delete(record.id).then(
          () => {
            toast.success({ title: 'Report deleted.' })
            table.refetch()
          },
          (error) => {
            toast.error({ title: 'Unable to process request.' })
            throw error
          }
        )
      },
    })
  }

  const columns = [
    ...reportColumns.filter((c) => c.dataIndex !== 'owner'),
    {
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 45,
      render: (val, rec) => (
        <Button
          danger
          type='link'
          size='small'
          onClick={() => onDelete(rec)}
          icon={<DeleteFilled />}
        />
      ),
    },
  ]

  const headerActions = (
    <>
      <PageSearch
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search Reports'
      />
      <Button onClick={() => table.refetch()} icon={<ReloadOutlined />} />
      <Button type='primary' onClick={() => navigate('/reports/owned/create')}>
        Create Report
      </Button>
    </>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <ReportFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title='Owned Reports' actions={headerActions} />
      <Box mt={2}>
        {!table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <InfiniteTable
            rowKey='id'
            emptyText='No Reports Found'
            columns={columns}
            toolbar={toolbar}
            scroll={scroll}
            filters={filters.populated}
            onFilter={(key) => filterRef.current?.addFilter(key)}
            {...table.props}
          />
        )}
      </Box>
    </>
  )
}

export default OwnedReports
