import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useSegmentChart = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['segment', 'chart', params],
    queryFn: async () => {
      const { segmentId, chartType, dateRange } = params

      const requestData = {
        segmentIds: segmentId ? [String(segmentId)] : undefined,
      }

      const request =
        chartType === 'avails'
          ? api.segment.getRequestChart(requestData, { range: dateRange })
          : api.segment.getUserChart(segmentId, { range: dateRange })

      const res = await request
      return res.data
    },
    staleTime: 60000,
    enabled,
  })
}
