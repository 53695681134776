import React from 'react'
import { Box, Input, Radio } from '@beachfront/ui'
import { Field } from '@beachfront/ui/forms'

const EditableCell = ({ editable, dataIndex, record, children, ...rest }) => {
  if (!editable) {
    return <td {...rest}>{children}</td>
  }

  const radioKey = `link-${record.key}-seatOrAgency`
  const inputKey = `link-${record.key}-${dataIndex}`

  return (
    <td {...rest}>
      <Box>
        {dataIndex === 'seatAgencyId' && (
          <Field name={radioKey} mb={0}>
            <Radio.Group>
              <Radio value='seat'>Seat ID</Radio>
              <Radio value='agency'>Agency ID</Radio>
            </Radio.Group>
          </Field>
        )}
      </Box>
      <Field name={inputKey}>
        <Input disabled={dataIndex === 'pubId'} />
      </Field>
    </td>
  )
}

export default EditableCell
