import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Result } from '@beachfront/ui'
import { BarChartOutlined } from '@beachfront/ui/icons'

const EmptyChartWarning = ({ msg }) => {
  return (
    <Flex height='100%' width='100%'>
      <Result m='auto' icon={<BarChartOutlined />} description={msg} />
    </Flex>
  )
}

EmptyChartWarning.propTypes = {
  msg: PropTypes.string,
}

export default EmptyChartWarning
