import { isValidEmail } from '../../../utils'

import { CUSTOM_FIELDS } from './support-enums'

export const validateSupport = (vals) => {
  const errors = {}
  const email = vals[`customfield_${CUSTOM_FIELDS.CONTACT_EMAIL.id}`]
  const name = vals[`customfield_${CUSTOM_FIELDS.CONTACT_NAME.id}`]

  if (!vals.summary) {
    errors.summary = 'Required'
  }

  if (vals.summary && vals.summary.length > 255) {
    errors.summary = `Summary can't exceed 255 characters.`
  }

  if (name && name.length > 255) {
    errors[`customfield_${CUSTOM_FIELDS.CONTACT_NAME.id}`] =
      `Name can't exceed 255 characters.`
  }

  if (!vals.description) {
    errors.description = 'Required'
  }

  if (vals.description && vals.description.length > 30000) {
    errors.description = `Description can't exceed 30000 characters.`
  }

  if (!vals[`customfield_${CUSTOM_FIELDS.CONTACT_NAME.id}`]) {
    errors[`customfield_${CUSTOM_FIELDS.CONTACT_NAME.id}`] = 'Required'
  }

  if (!email) {
    errors[`customfield_${CUSTOM_FIELDS.CONTACT_EMAIL.id}`] = 'Required'
  }

  if (email) {
    if (!isValidEmail(email)) {
      errors[`customfield_${CUSTOM_FIELDS.CONTACT_EMAIL.id}`] = 'Invalid email'
    } else if (email.length > 255) {
      errors[`customfield_${CUSTOM_FIELDS.CONTACT_EMAIL.id}`] =
        `Email can't exceed 255 characters.`
    }
  }

  return errors
}
