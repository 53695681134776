import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const usePdfDocs = () => {
  return useQuery({
    queryKey: ['pdf-docs'],
    queryFn: async () => {
      const res = await api.support.pdfDocs()
      return res.data
    },
    staleTime: 60000,
  })
}
