import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Filter, Radio } from '@beachfront/ui'
import {
  CalendarOutlined,
  TagsOutlined,
  IdcardOutlined,
} from '@beachfront/ui/icons'

import { DEAL_STATUS, DEAL_TYPE, MARGIN_TYPE } from '../../../enums'
import {
  DateRangeFilterPanel,
  DateFilterPanel,
  ContainsFilterPanel,
  formatDateFilterTag,
  formatStringFilterTag,
} from '../../../components/filter'
import { SelectField } from '../../../components'

const DealFilter = ({ filterRef, filters, setFilters, hideStatus }) => {
  const validateDateRange = ([start, end]) => {
    if (
      end.diff(start, 'days') > 92 ||
      moment().diff(start, 'month', true) > 12
    ) {
      return 'Date range is limited to 92 days within the last 12 months. Please select a valid date range.'
    }
  }

  const disabledDate = (date) => {
    return date.isAfter(moment()) || date < moment().add(-12, 'months')
  }

  return (
    <Filter ref={filterRef} value={filters} onChange={setFilters}>
      <Filter.Option
        dataIndex='date'
        icon={<CalendarOutlined />}
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        removable={false}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => (
          <DateRangeFilterPanel
            {...props}
            validate={validateDateRange}
            disabledDate={disabledDate}
          />
        )}
      />
      {!hideStatus ? (
        <Filter.Option
          dataIndex='dealStatus'
          label='Status'
          defaultOperator='IN'
          icon={<TagsOutlined />}
          formatTag={renderStatusTag}
          renderPanel={({ value, setValue }) => {
            const renderedValue = value ? value.split(',') : []
            return (
              <SelectField
                mode='multiple'
                value={renderedValue}
                onChange={(val) => {
                  setValue(val.join(','))
                }}
                data={DEAL_STATUS.values()}
                keyField='key'
                placeholder='Select Status'
                textField='name'
              />
            )
          }}
        />
      ) : null}

      <Filter.Option
        dataIndex='dealId'
        label='Deal ID'
        defaultOperator={Filter.Operator.String.CONTAINS.key}
        icon={<IdcardOutlined />}
        formatTag={formatStringFilterTag}
        renderPanel={(props) => <ContainsFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='type'
        label='Deal Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<TagsOutlined />}
        formatTag={renderDealTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {DEAL_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='marginType'
        label='Margin Type'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        icon={<TagsOutlined />}
        formatTag={renderMarginTypeTag}
        renderPanel={({ value, setValue }) => (
          <Radio.Group value={value} onChange={setValue}>
            {MARGIN_TYPE.values().map(({ key, name }) => (
              <Radio.Button key={key} value={key}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      <Filter.Option
        dataIndex='createTime'
        label='Created'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
      <Filter.Option
        dataIndex='lastUpdate'
        label='Last Updated'
        defaultOperator={Filter.Operator.Any.EQUALS.key}
        defaultValue={moment()}
        icon={<CalendarOutlined />}
        formatTag={formatDateFilterTag}
        renderPanel={(props) => <DateFilterPanel {...props} />}
      />
    </Filter>
  )
}

const renderStatusTag = (desc) => {
  if (!desc.value) {
    return 'Status is : Any Status'
  }

  const names = desc.value
    .split(',')
    .map((val) => DEAL_STATUS.fromKey(val)?.name)
  return `Status : ${names.join(', ')}`
}

const renderDealTypeTag = (desc) => {
  const name = DEAL_TYPE.fromKey(desc.value)?.name
  return name ? `Deal Type is ${name}` : 'All Deal Types'
}

const renderMarginTypeTag = (desc) => {
  const name = MARGIN_TYPE.fromKey(desc.value)?.name
  return name ? `Margin Type is ${name}` : 'All Margin Types'
}

DealFilter.propTypes = {
  filterRef: PropTypes.object,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
}

export default DealFilter
