import React, { useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Box, Button } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../client-api'
import { TIMEZONE } from '../../../enums'
import { useFetch, useUserData } from '../../../hooks'
import { PageHeader, NetworkError, CommonSpinner } from '../../../components'
import {
  getExcludeInitialValue,
  getKeys,
  getOrAndInitialValue,
  TargetingForm,
} from '../../media-plan'
import { isNotEmptyArray } from '../../../utils'

const DealLibraryDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [user] = useUserData()
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)
  const [availsDsp, setAvailsDsp] = useState('')
  const hasContentBooleanLogic = user?.featureToggles?.contentBooleanLogic
  const response = useFetch({
    request: api.deal.library.admin.getDealById,
    payload: { id },
  })

  const initialValues = useMemo(() => {
    let getData = {}
    if (response.data) {
      getData = {
        ...response.data,
        audienceBooleanLogic: response.data.audienceBooleanLogic ?? {
          enabled: false,
          client: [],
          value: '',
        },
        contentBooleanLogic: response.data.contentBooleanLogic ?? {
          enabled: !hasContentBooleanLogic
            ? false
            : response?.data?.contentBooleanLogic?.enabled || false,
          client: [],
          value: '',
        },
        mediaType: response.data.mediaType ? [response.data.mediaType] : [],
        status: response.data.status ? 'ENABLE' : 'DISABLE',
        country: response.data.country ? getKeys(response.data.country) : [],
        countryObj: response.data.country ? response.data.country : [],
        genres: isNotEmptyArray(response.data.genres)
          ? getKeys(response.data.genres)
          : [],
        network: isNotEmptyArray(response.data.network)
          ? getKeys(response.data.network)
          : [],
        channel: isNotEmptyArray(response.data.channel)
          ? getKeys(response.data.channel)
          : [],
        rating: isNotEmptyArray(response.data.rating)
          ? getKeys(response.data.rating)
          : [],
        genresObj: isNotEmptyArray(response.data.genres)
          ? response.data.genres
          : [],
        networkObj: isNotEmptyArray(response.data.network)
          ? response.data.network
          : [],
        channelObj: isNotEmptyArray(response.data.channel)
          ? response.data.channel
          : [],
        ratingObj: isNotEmptyArray(response.data.rating)
          ? response.data.rating
          : [],
        iabCategory: response.data.iabCategory
          ? getKeys(response.data.iabCategory)
          : [],
        iabCategoryObj: response.data.iabCategory
          ? response.data.iabCategory
          : [],
        state: response.data.state ? getKeys(response.data.state) : [],
        stateObj: response.data.state ? response.data.state : [],
        publishers: response.data.publishers
          ? getKeys(response.data.publishers)
          : [],
        publishersObj: response.data.publishers ? response.data.publishers : [],
        dma: response.data.dma ? getKeys(response.data.dma, 'dmaCode') : [],
        dmaObj: response.data.dma ? response.data.dma : [],
        app: response.data.country ? getKeys(response.data.app) : [],
        appObj: response.data.country ? response.data.app : [],
        excludeCountry: getExcludeInitialValue(response.data.excludeCountry),
        excludeDma: getExcludeInitialValue(response.data.excludeDma),
        excludeState: getExcludeInitialValue(response.data.excludeState),
        excludeiabCat: getExcludeInitialValue(response.data.excludeiabCat),
        excludeNetwork: getExcludeInitialValue(response.data.excludeNetwork),
        excludeChannel: getExcludeInitialValue(response.data.excludeChannel),
        excludeRating: getExcludeInitialValue(response.data.excludeRating),
        excludeGenres: getExcludeInitialValue(response.data.excludeGenres),
        excludeApp: getExcludeInitialValue(response.data.excludeApp),
        includeSegmentAnd: getOrAndInitialValue(
          response.data.includeSegmentAnd
        ),
        excludeSegmentAnd: getOrAndInitialValue(true),
        excludeUserId: getExcludeInitialValue(response.data.excludeUserId),
      }
    }
    return getData
  }, [response.data])

  if (response.error) {
    return (
      <NetworkError
        description={response.error || 'Media plan not found'}
        buttonLabel='Back to Media List'
        onAction={() => navigate('')}
      />
    )
  }

  if (response.loading) {
    return <CommonSpinner />
  }

  return (
    <Form initialValues={initialValues} onSubmit={() => {}}>
      {({ values }) => (
        <form style={{ height: '100%' }}>
          <PageHeader
            title='Deal Library - Detail'
            actions={
              <>
                <Link to='/deals/library'>
                  <Button>Cancel</Button>
                </Link>
                <Link to={`/deals/self/template/${id}`}>
                  <Button type='primary'>Use Template</Button>
                </Link>
              </>
            }
          />
          <Box mt={3}>
            <TargetingForm
              values={values}
              isDeal={true}
              superAdmin={true}
              dld={true}
              timezone={timezone}
              onTimezoneChange={setTimezone}
              availsDsp={availsDsp}
              onAvailsDspChange={setAvailsDsp}
            />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default DealLibraryDetail
