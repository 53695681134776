import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from '@beachfront/ui'

import { plural } from '../../../../utils'

const AudiencePreviewTags = ({ visible, values }) => {
  if (!visible) {
    return null
  }

  if (
    values.audienceBooleanLogic?.enabled &&
    values.audienceBooleanLogic?.value
  ) {
    return (
      <Tag color='processing' flex='none'>
        Advanced Logic
      </Tag>
    )
  }

  const includeCount = values.includeSegments?.length ?? 0
  const excludeCount = values.excludeSegments?.length ?? 0

  if (!values.audienceBooleanLogic?.enabled && (includeCount || excludeCount)) {
    return (
      <Tag color='processing' flex='none'>
        {plural`${includeCount + excludeCount} Segment[|s]`}
      </Tag>
    )
  }

  return null
}

AudiencePreviewTags.propTypes = {
  visible: PropTypes.bool,
  values: PropTypes.object,
}

export default AudiencePreviewTags
