import React, { useMemo, useState } from 'react'
import { Box, Button, Tabs, useToast } from '@beachfront/ui'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form } from '@beachfront/ui/forms'
import moment from 'moment'
import isEqual from 'lodash/isEqual'

import { api } from '../../../../../client-api'
import { useFetch } from '../../../../../hooks'
import { ACCOUNT_TYPE } from '../../../../../enums'
import {
  getUniqueId,
  isNotEmptyArray,
  getErrorMessage,
} from '../../../../../utils'
import {
  PageHeader,
  NetworkError,
  CommonSpinner,
} from '../../../../../components'
import {
  validateAccountUpdateForm,
  buildLinkData,
  filterSourceProviders,
} from '../../@utils'
import { AccountForm } from '..'

import { AccountTakeRate, AccountHistory } from '.'

const UpdateAccount = () => {
  const { type = 'settings', accountId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const [segmentProviderList, setSegmentProviderList] = useState([])

  const account = useFetch({
    request: api.admin.account.getAccountById,
    payload: { id: accountId },
  })

  const dsps = useFetch({
    request: api.admin.account.getAccountDsps,
  })

  const initialValue = useMemo(() => {
    if (account.data) {
      let links = []
      let seatOrAgency = {}
      for (let link of account.data.linkData) {
        const id = getUniqueId()
        let type = account.data.type
        if (
          type === ACCOUNT_TYPE.DSP.key ||
          type === ACCOUNT_TYPE.RESELLER.key
        ) {
          seatOrAgency[`link-${id}-advertiserId`] = link.advertiserId
        }
        if (type === ACCOUNT_TYPE.PUBLISHER.key) {
          seatOrAgency[`link-${id}-pubId`] = link.pubId
        }
        if (type === ACCOUNT_TYPE.AGENCY.key) {
          seatOrAgency[`link-${id}-advertiserId`] = link.advertiserId
          seatOrAgency[`link-${id}-seatAgencyId`] = link.seatId || link.agencyId
        }

        if (type === ACCOUNT_TYPE.BRAND.key) {
          seatOrAgency[`link-${id}-adomain`] = link.adomain
        }

        let soa = 'seat'
        if (account.data.type === ACCOUNT_TYPE.AGENCY.key) {
          if (link.agencyId) {
            soa = 'agency'
          }
        }
        seatOrAgency[`link-${id}-seatOrAgency`] = soa
        links.push({
          key: id,
          ...link,
        })
      }

      let takeRateObj = {
        spentType: 'MONTHLY',
        spentRanges: [{ from: 0, to: null }],
      }
      if (account.data && account.data.enabled) {
        takeRateObj.enabled = account.data.enabled
        takeRateObj.spentType = account.data.spentType
        if (isNotEmptyArray(account.data.spentRanges)) {
          const ranges = account.data.spentRanges?.map((val) => ({
            ...val,
            takeRate: Math.round(val.takeRate * 10000) / 100,
          }))
          takeRateObj.spentRanges = ranges
        }
        if (account.data.startDate) {
          takeRateObj.startDate = moment(account.data.startDate, 'DD/MM/YYYY')
        }
      }
      if (account.data.bfmMarginEnabled) {
        account.data.bfmMarginValue = String(account.data.bfmMarginValue)
      }

      return {
        ...account.data,
        link: links,
        ...seatOrAgency,
        ...takeRateObj,
      }
    }
  }, [account.data])

  const onSubmit = ({ sharedSegment, ...values }) => {
    if (values.sourceProviders) {
      values.sourceProviders = filterSourceProviders(
        values.sourceProviders,
        segmentProviderList
      )
    }
    values.shared = sharedSegment
    const result = {
      ...values,
      linkData: buildLinkData(values),
      accManagerEmails: values.demoAccount ? [] : values.accManagerEmails,
      sourceProviders: values.shared ? values.sourceProviders : [],
      enabled: !!values.enabled,
    }

    if (!values.bfmMarginEnabled) {
      result.bfmMarginType = null
      result.bfmMarginValue = 0
    }

    if (values.type === ACCOUNT_TYPE.AGENCY.key) {
      if (values.enabled) {
        const ranges = values.spentRanges?.map((val) => ({
          ...val,
          takeRate: Math.round(val.takeRate * 100) / 10000,
        }))
        result.spentRanges = ranges
        result.spentType = values.spentType
        result.startDate = values.startDate.format('DD/MM/YYYY')
      } else {
        result.spentRanges = []
        result.spentType = null
        result.startDate = null
      }
    }

    return api.admin.account.update(accountId, result).then(
      () => {
        toast.success({ title: 'Account updated.' })
        account.refresh()
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const onAccountTypeChange = (e, form, values) => {
    if (
      e.target.value !== ACCOUNT_TYPE.RESELLER.key &&
      !isNotEmptyArray(values?.link)
    ) {
      const linkData = [
        {
          key: accountId,
          advertiserId: '',
          seatAgencyId: '',
          seatOrAgency: 'seat',
          adomain: '',
        },
      ]
      form.change('link', linkData)
    }
  }

  const getPageTitle = () => {
    if (account.data) {
      return `Account - ${account.data.name}`
    }
    return 'Account'
  }

  const onTabChange = (key) => {
    navigate(`/admin/account/${accountId}/${key}`)
  }

  if (account.loading) {
    return <CommonSpinner />
  }

  if (account.error) {
    return (
      <NetworkError
        description={getErrorMessage(account.error)}
        onAction={account.refresh}
      />
    )
  }

  return (
    <Form
      initialValues={initialValue}
      onSubmit={onSubmit}
      validate={validateAccountUpdateForm}
    >
      {({ handleSubmit, submitting, form, values }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title={getPageTitle()}
            actions={
              <>
                <Link to='/admin/account'>
                  <Button>Cancel</Button>
                </Link>
                {type !== 'history' ? (
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isEqual(initialValue, values)}
                    loading={submitting}
                  >
                    Save
                  </Button>
                ) : null}
              </>
            }
          />
          <Box mt={3}>
            <Tabs activeKey={type} onChange={onTabChange} type='card'>
              <Tabs.TabPane key='settings' tab='Settings'>
                <AccountForm
                  account={account}
                  dsps={dsps}
                  setSegmentProviderList={setSegmentProviderList}
                  onAccountTypeChange={(e) =>
                    onAccountTypeChange(e, form, values)
                  }
                />
              </Tabs.TabPane>
              {values.type === ACCOUNT_TYPE.AGENCY.key ? (
                <Tabs.TabPane key='takerate' tab='Take Rate'>
                  <AccountTakeRate />
                </Tabs.TabPane>
              ) : null}
              <Tabs.TabPane key='history' tab='History'>
                <AccountHistory accountId={accountId} />
              </Tabs.TabPane>
            </Tabs>
          </Box>
        </form>
      )}
    </Form>
  )
}

export default UpdateAccount
