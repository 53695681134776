import { api } from '../client-api'
import { IS_DEALS_ENGINE, USER_KEY, TOKEN_KEY } from '../constants'

import { keycloak } from './keycloak-provider'

export const authService = {
  isAuthenticated() {
    try {
      const user = localStorage.getItem(USER_KEY)
      const token = IS_DEALS_ENGINE ? localStorage.getItem(TOKEN_KEY) : true
      return !!JSON.parse(user) && !!token
    } catch {
      return false
    }
  },

  login(data) {
    return api.user.login(data)
  },

  verifyEmail({ data }) {
    return api.user.verifyEmail({ data })
  },

  verifyOtp({ data }) {
    return api.user.verifyOtp({ data })
  },

  resendOtp({ data }) {
    return api.user.resendOtp({ data })
  },

  resetPassword({ data }) {
    return api.user.resetPassword({ data })
  },

  logout() {
    const baseTheme = localStorage.getItem('baseTheme')
    localStorage.clear()
    sessionStorage.clear()
    if (baseTheme !== null) {
      localStorage.setItem('baseTheme', baseTheme)
    }
    return Promise.all([
      api.user.logout(),
      IS_DEALS_ENGINE &&
        keycloak.logout({
          redirectUri: window.location.origin,
        }),
    ])
  },
}
