import React from 'react'
import { Link } from 'react-router-dom'

import { formatCount, formatDate } from '../../../utils'

export const mediaListColumns = [
  {
    dataIndex: 'title',
    title: 'Title',
    fixed: 'left',
    sorter: true,
    filter: true,
    width: 250,
    render: (val, rec) => <Link to={`${rec.id}`}>{val}</Link>,
  },
  {
    dataIndex: 'count',
    title: 'List Size',
    sorter: true,
    filter: true,
    width: 140,
    render: (val) => formatCount(val),
  },
  {
    dataIndex: 'createDate',
    title: 'Created',
    sorter: true,
    filter: true,
    width: 200,
    render: (val) => formatDate(val),
  },
  {
    dataIndex: 'updateDate',
    title: 'Last Updated',
    sorter: true,
    filter: true,
    width: 200,
    render: (val) => formatDate(val),
  },
]
