import { createEnum } from '../utils'

export const DEAL_STATUS = createEnum({
  SENT_FOR_APPROVAL: {
    name: 'Send For Approval',
    action: '',
  },
  DRAFT: {
    name: 'Draft',
    action: 'Publish',
  },
  UNPUBLISHED: {
    name: 'Unpublished',
    action: 'Publish',
  },
  PUBLISHED: {
    name: 'Published',
    action: 'Unpublish',
  },
  INACTIVE: {
    name: 'Inactive',
    action: '',
  },
  CHANGES_REQUESTED: {
    name: 'Request For Changes',
    action: 'ChangesRequested',
  },
  APPROVED: {
    name: 'Approved',
    action: 'Approved',
  },
})

export const NEXT_ACTION_STATUS = createEnum({
  SENT_FOR_APPROVAL: {
    name: 'Send For Approval',
  },
  DRAFT: {
    name: 'Draft',
  },
  UNPUBLISHED: {
    name: 'Unpublish',
  },
  PUBLISHED: {
    name: 'Publish',
  },
  CHANGES_REQUESTED: {
    name: 'Request Changes',
  },
  APPROVED: {
    name: 'Approved',
  },
})
