import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useApiKeys = () => {
  return useQuery({
    queryKey: ['api-keys'],
    queryFn: async () => {
      const res = await api.apiKey.getList()
      return res.data
    },
  })
}
