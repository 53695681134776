import React, { useRef, useEffect } from 'react'
import { Flex, Table } from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'

import { useContentSize, useFilters } from '../../../../hooks'
import { NetworkError } from '../../../../components'
import { localFilterKey } from '../../../../components/filter'
import { supplyColumns } from '../@constants'

import { SupplyFilter } from '.'

const SupplyTable = ({ data, loading, error, onRefetch }) => {
  const { contentWidth, contentHeight } = useContentSize()
  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.SUPPLY_REQUEST)

  const table = useTableQuery({
    data: data ?? [],
    defaultSorter: {
      field: 'requestDate',
      order: 'descend',
    },
  })

  const scroll = {
    y: contentHeight - 260,
    x: contentWidth - 300,
  }

  useEffect(() => {
    table.setFilters(filters.populated)
  }, [filters.populated])

  if (!loading && error) {
    return (
      <NetworkError description='Something went wrong.' onAction={onRefetch} />
    )
  }

  const toolbar = () => (
    <Table.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <SupplyFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </Table.Toolbar>
  )

  return (
    <Table
      rowKey='id'
      emptyText='No Supply Requests'
      columns={supplyColumns}
      toolbar={toolbar}
      dataSource={table.data}
      loading={loading}
      pagination={{
        ...table.query.pagination,
        hideOnSinglePage: false,
        total: table.total,
      }}
      scroll={scroll}
      filters={filters.populated}
      onFilter={(key) => filterRef.current?.addFilter(key)}
      onChange={(e) => {
        table.setPagination(e.pagination)
        table.setSorter(e.sorter)
        table.setSelectedRowKeys([])
      }}
    />
  )
}

export default SupplyTable
