import { isValidEmail } from '../../../utils'

const isValidUrl = (val) => {
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  return urlRegex.test(val)
}

export const validateCreateUserForm = (
  values,
  newUser,
  showPassword,
  admin
) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Required'
  }

  if (values.email && !isValidEmail(values.email)) {
    errors.email = 'Username is not a valid email'
  }

  if (newUser || showPassword) {
    if (values.firstName) {
      values.firstName = values.firstName.trim()
    }

    if (values.lastName) {
      values.lastName = values.lastName.trim()
    }

    if (!values.firstName) {
      errors.firstName = 'Required'
    }

    if (!values.lastName) {
      errors.lastName = 'Required'
    }

    if (!values.password) {
      errors.password = 'Required'
    }

    if (!values.confirm_password) {
      errors.confirm_password = 'Please confirm your password'
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = 'Passwords do not match'
    } else if (values.password.length < 6) {
      errors.confirm_password = 'Passwords should have min 6 characters'
    }

    if (admin) {
      if (values.dashboardUrl && !isValidUrl(values.dashboardUrl)) {
        errors.dashboardUrl = 'Please enter valid URL'
      }

      if (values.adhocUrl && !isValidUrl(values.adhocUrl)) {
        errors.adhocUrl = 'Please enter valid URL'
      }
    }
  }

  if (!values.timezone && newUser) {
    errors.timezone = 'Required'
  }

  if (admin && !values.accountId) {
    errors.accountId = 'Required'
  }

  return errors
}

export const validateUpdateUserForm = (values, admin) => {
  const errors = {}

  if (values.firstName) {
    values.firstName = values.firstName.trim()
  }

  if (values.lastName) {
    values.lastName = values.lastName.trim()
  }

  if (!values.firstName) {
    errors.firstName = 'Required'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  }

  if (admin) {
    if (!values.timezone) {
      errors.timezone = 'Required'
    }

    if (values.dashboardUrl && !isValidUrl(values.dashboardUrl)) {
      errors.dashboardUrl = 'Please enter valid URL'
    }

    if (values.adhocUrl && !isValidUrl(values.adhocUrl)) {
      errors.adhocUrl = 'Please enter valid URL'
    }
  }

  if (values.password) {
    if (!values.confirm_password) {
      errors.confirm_password = 'Please confirm your password'
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = 'Passwords do not match'
    } else if (values.password.length < 6) {
      errors.confirm_password = 'Passwords should have min 6 characters'
    }
  }

  return errors
}
