import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Box, DatePicker, Modal } from '@beachfront/ui'

import { DATE_RANGES } from '../../../enums'

const DateRangeFilterPanel = ({
  focusRef,
  value,
  setValue,
  selected,
  validate,
  disabledDate,
  getPopupContainer,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (selected && !open) {
      setOpen(true)
    }
  }, [selected])

  const onChange = ([start, end]) => {
    const range = [start.startOf('day'), end.endOf('day')]
    const error = validate?.(range)

    if (error) {
      return Modal.error({
        title: 'Invalid Date Range',
        content: error,
        maskClosable: true,
      })
    }

    setValue(range)
  }

  return (
    <Box width={300}>
      <DatePicker.RangePicker
        {...rest}
        ref={focusRef}
        value={value}
        onChange={onChange}
        open={open}
        onOpenChange={setOpen}
        ranges={DATE_RANGES}
        disabledDate={disabledDate}
        getPopupContainer={getPopupContainer}
      />
    </Box>
  )
}

DateRangeFilterPanel.defaultProps = {
  format: 'MM/DD/YYYY',
  allowClear: false,
  disabledDate: (date) => date.isAfter(moment()),
  getPopupContainer: (el) => el.closest('.page-header') ?? document.body,
}

DateRangeFilterPanel.propTypes = {
  focusRef: PropTypes.object,
  value: PropTypes.any,
  setValue: PropTypes.func,
  selected: PropTypes.bool,
  validate: PropTypes.func,
  disabledDate: PropTypes.func,
  getPopupContainer: PropTypes.func,
}

export default DateRangeFilterPanel
