export const validateAppInfoForm = (values) => {
  const errors = {}

  if (!values.bundle) {
    errors.bundle = 'Please enter bundle name'
  }

  if (values.bundle && !isValidBundle(values.bundle)) {
    errors.bundle = 'Please enter valid bundle name'
  }

  if (!values.overridedBundle) {
    errors.overridedBundle = 'Please enter existing bundle name'
  }

  if (values.overridedBundle && !isValidBundle(values.overridedBundle)) {
    errors.overridedBundle = 'Please enter valid existing bundle name'
  }

  return errors
}

const isValidBundle = (values) => {
  if (values[0] === '.' || values[values.length - 1] === '.') {
    return false
  }

  if (values && !values.split('.').every((el) => el)) {
    return false
  }

  return true
}
