export const dealTabs = [
  {
    path: 'settings',
    title: 'Settings',
    key: 'settings',
  },
  {
    path: 'targeting',
    title: 'Targeting',
    key: 'targeting',
  },
]
