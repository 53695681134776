import { createEnum } from '../utils'

export const SEGMENT_TYPE = createEnum({
  COOKIE: {
    name: 'COOKIE',
    canUpload: true,
  },
  IDFA: {
    name: 'IDFA',
    canUpload: true,
  },
  GAID: {
    name: 'GAID',
    canUpload: true,
  },
  IP: {
    name: 'IP',
    canUpload: true,
  },
  IDL: {
    name: 'Ramp ID',
    canUpload: true,
  },
  RIDA: {
    name: 'RIDA',
    canUpload: true,
  },
  AAID: {
    name: 'AAID',
    canUpload: true,
  },
  AFAI: {
    name: 'AFAI',
    canUpload: true,
  },
  MSAI: {
    name: 'MSAI',
    canUpload: true,
  },
  DPID: {
    name: 'DPID',
    canUpload: true,
  },
  ADTV: {
    name: 'ADTV',
    canUpload: true,
  },
  APTV: {
    name: 'APTV',
    canUpload: true,
  },
  IRIS_ID: {
    name: 'Iris ID',
    canUpload: false,
  },
  BID_REQUEST: {
    name: 'Bid Request',
    canUpload: false,
  },
  MIX: {
    name: 'Mix',
    canUpload: false,
  },
})
