import React, { useState } from 'react'
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Input,
  Help,
  useToast,
} from '@beachfront/ui'
import { Form, Field } from '@beachfront/ui/forms'
import { DownloadOutlined } from '@beachfront/ui/icons'
import { useDisclosure } from '@beachfront/ui/hooks'
import FileSaver from 'file-saver'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { getErrorMessage } from '../../../utils'
import { getTargetingParams } from '../@utils'

const DownloadButton = ({
  values,
  isMediaPlan,
  dataCost,
  timezone,
  admin,
  disabled,
  pdfTitle,
  onDownloadStart,
  onDownloadComplete,
}) => {
  const toast = useToast()
  const [isDownloading, setIsDownloading] = useState(false)
  const xlsModal = useDisclosure()

  const onDownloadPdf = (values) => {
    setIsDownloading(true)
    onDownloadStart?.()
    const targetingParams = getTargetingParams(values, {
      pmp: true,
      publishers: true,
    })
    if (isMediaPlan) {
      targetingParams.mediaPlanTitle = values.title
    }
    const params = {
      type: 'pdf',
      dataCost: dataCost ?? 0,
    }

    if (admin) {
      params.admin = true
    }

    if (timezone) {
      params.timezone = timezone
    }

    const request = isMediaPlan
      ? api.common.chartDownload(targetingParams, params, 'blob')
      : api.mediaPlan.pmpChartDownload(targetingParams, params, 'blob')

    request
      .then((res) => {
        if (res.data) {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const title = isMediaPlan ? `${pdfTitle}.pdf` : 'avails-explorer.pdf'
          toast.success({ title: 'Successfully downloaded.' })
          FileSaver.saveAs(blob, title)
        } else {
          toast.error({ title: 'Download Error' })
        }
      })
      .catch((error) => {
        toast.error({ title: getErrorMessage(error) })
      })
      .finally(() => {
        setIsDownloading(false)
        onDownloadComplete?.()
      })
  }

  const onDownloadXls = (values, formValues) => {
    setIsDownloading(true)
    onDownloadStart?.()

    if (formValues.exportName) {
      formValues.exportName = formValues.exportName.trim()
    }

    const targetingParams = getTargetingParams(values, { pmp: true })
    if (isMediaPlan) {
      targetingParams.mediaPlanTitle = values.title
    }
    const payload = {
      ...targetingParams,
      ...formValues,
    }
    const params = {
      type: 'xls',
      timezone,
      dataCost: dataCost ?? 0,
      mediaPlan: isMediaPlan,
    }
    xlsModal.close()
    return api.mediaPlan
      .exportExcelReport(payload, params, 'blob')
      .then(
        (res) => {
          if (res.data) {
            toast.success({
              title:
                'You will receive this excel file in your email after it is processed.',
            })
          } else {
            toast.error({ title: 'Download Error' })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
      .finally(() => {
        setIsDownloading(false)
        onDownloadComplete?.()
      })
  }

  const menu = (
    <Menu>
      <Menu.Item key='pdf' onClick={() => onDownloadPdf(values)}>
        PDF
      </Menu.Item>
      <Menu.Item key='xls' onClick={() => xlsModal.open()}>
        XLS
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown
        overlay={menu}
        disabled={isDownloading || disabled}
        trigger={['click']}
      >
        <Button icon={<DownloadOutlined />} loading={isDownloading}>
          Download
        </Button>
      </Dropdown>
      <XlsModal
        isOpen={xlsModal.isOpen}
        onSubmit={(formValues) => onDownloadXls(values, formValues)}
        onCancel={() => xlsModal.close()}
        isMediaPlan={isMediaPlan}
      />
    </>
  )
}

DownloadButton.defaultProps = {
  isMediaPlan: false,
  admin: false,
}

DownloadButton.propTypes = {
  isMediaPlan: PropTypes.bool,
  timezone: PropTypes.string,
  values: PropTypes.object,
  admin: PropTypes.bool,
  dataCost: PropTypes.number,
  disabled: PropTypes.bool,
  pdfTitle: PropTypes.string,
  onDownloadStart: PropTypes.func,
  onDownloadComplete: PropTypes.func,
}

const XlsModal = ({ isOpen, isMediaPlan, onSubmit, onCancel }) => {
  const validate = (values) => {
    const errors = {}

    if (values.exportName) {
      values.exportName = values.exportName.trim()
    }

    if (!values.exportName) {
      errors.exportName = 'Required'
    }

    return errors
  }

  return (
    <Form validate={validate} onSubmit={onSubmit}>
      {({ form, handleSubmit, submitting }) => (
        <Modal
          title={`Export ${isMediaPlan ? 'Media Plan' : 'Avail Explorer'}`}
          visible={isOpen}
          onCancel={onCancel}
          afterClose={() => form.restart()}
          cancelText='Cancel'
          okText='Submit'
          cancelButtonProps={{ disabled: submitting }}
          okButtonProps={{ loading: submitting }}
          onOk={handleSubmit}
          destroyOnClose
        >
          <form onSubmit={handleSubmit}>
            <Field name='exportName' label='Report Title'>
              <Input placeholder='Enter report title' />
            </Field>
            <Help>Exported excel file will be saved with the above title.</Help>
          </form>
        </Modal>
      )}
    </Form>
  )
}

XlsModal.propTypes = {
  isOpen: PropTypes.bool,
  isMediaPlan: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default DownloadButton
