import React from 'react'
import { Card, Flex, Box, Input, Select, Switch, Help } from '@beachfront/ui'
import { Field, useForm, useFormState } from '@beachfront/ui/forms'

import { MARGIN_TYPE } from '../../../../../enums'
import { isFloatValue } from '../../../../../utils'

const MarginSetting = () => {
  const form = useForm()
  const { values } = useFormState()

  const extra = (
    <Flex alignItems='center' gap={2}>
      <Field inline label='BFM' name='bfmMarginEnabled' mb={0}>
        <Switch />
      </Field>
    </Flex>
  )

  const handleMarginValue = (event) => {
    let rate = event.target.value
      .replace(/[^\d.]/g, '')
      .replace(/\./, 'x')
      .replace(/\./g, '')
      .replace(/x/, '.')
    if (isFloatValue(rate)) {
      rate = rate
        .toString()
        .split('.')
        .map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
        .join('.')
    }
    form.change('bfmMarginValue', rate)
  }

  return (
    <Card extra={extra} title='Margins' height='100%'>
      {values.bfmMarginEnabled ? (
        <Flex justifyContent={'space-between'} gap={3} flexWrap='wrap'>
          <Box flexGrow={1}>
            <Field label='BFM Margin Type' name='bfmMarginType'>
              <Select
                options={MARGIN_TYPE.values().map(({ key, name }) => ({
                  value: key,
                  label: name,
                }))}
              />
            </Field>
          </Box>
          <Box flexGrow={1}>
            <Field label='BFM Margin Value' name='bfmMarginValue'>
              <Input
                placeholder={
                  values.bfmMarginType === MARGIN_TYPE.FLAT.key
                    ? 'Enter flat margin'
                    : 'Enter margin in %'
                }
                onChange={handleMarginValue}
                addonBefore={
                  values.bfmMarginType === MARGIN_TYPE.FLAT.key ? '$' : ''
                }
                addonAfter={
                  values.bfmMarginType === MARGIN_TYPE.PERCENT.key ? '%' : ''
                }
              />
            </Field>
          </Box>
        </Flex>
      ) : (
        <Flex>
          <Help>No margins are currently active for this account</Help>
        </Flex>
      )}
    </Card>
  )
}

export default MarginSetting
