import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Text, Card } from '@beachfront/ui'
import { useTheme } from 'styled-components'

export const FunnelChartWidget = ({
  data,
  title,
  colors,
  cardBodyHeight,
  ...rest
}) => {
  const theme = useTheme()
  const chartColors = colors ?? [
    theme.colors.primary[7],
    theme.colors.primary[6],
    theme.colors.primary[5],
    theme.colors.primary[4],
    theme.colors.primary[3],
  ]

  return (
    <Card
      title={title}
      bodyStyle={{ height: cardBodyHeight ?? '300px' }}
      {...rest}
    >
      {data?.length > 0 ? (
        <Flex
          width='100%'
          height='100%'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          {data.map((d, i) => (
            <Box key={i} position='relative' width={`calc(100% - ${i * 30}px)`}>
              <Box
                borderTop={`60px solid ${chartColors[i % chartColors.length]}`}
                borderX={data.length - 1 === i ? '' : '15px solid transparent'}
              />
              <Flex
                flexDirection='column'
                px={3}
                width='100%'
                position='absolute'
                top='calc(50% - 21px)'
                color='white'
                textAlign='center'
                fontFamily='secondary'
              >
                <Text fontSize={2} ellipsis>
                  {d.name}
                </Text>
                <Text fontSize={1} ellipsis>
                  {d.value}
                </Text>
              </Flex>
            </Box>
          ))}
        </Flex>
      ) : null}
    </Card>
  )
}

FunnelChartWidget.propTypes = {
  data: PropTypes.array,
  title: PropTypes.node,
  colors: PropTypes.array,
  cardBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
