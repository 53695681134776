import { useMemo, useReducer } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Filter } from '@beachfront/ui'
import { useUpdateEffect } from '@beachfront/ui/hooks'

import {
  makeArray,
  isObject,
  isEmptyString,
  resolveProp,
  parseFields,
} from '../../utils'

const defaultOperator = 'EQUALS'

const operatorMap = {
  [Filter.Operator.Any.EQUALS.key]: 'EQUALS',
  [Filter.Operator.String.CONTAINS.key]: 'CONTAINS',
  [Filter.Operator.String.STARTS_WITH.key]: 'STARTS_WITH',
  [Filter.Operator.Date.BEFORE.key]: 'BEFORE',
  [Filter.Operator.Date.AFTER.key]: 'AFTER',
  [Filter.Operator.Number.LESS_THAN.key]: 'LESS_THAN',
  [Filter.Operator.Number.GREATER_THAN.key]: 'GREATER_THAN',
}

/**
 * Uses a data source for table components with server-side paging, sorting, and filtering.
 *
 * @param {Object} options
 * @returns {{
 *     data: Array,
 *     total: Number,
 *     isError: Object,
 *     isLoading: Boolean,
 *     query: { pagination: Object, sorter: Object },
 *     serverFilters: Object,
 *     setPagination: function(*),
 *     setSorter: function(*),
 *     selectedRowKeys: Array,
 *     setSelectedRowKeys: function(*),
 *     clearSelectedRowKeys: function(),
 *     refetch: function()
 * }}
 */
export function useServerTable(options) {
  const {
    queryKey,
    queryFn,
    payload,
    filters = [],
    schema = {},
    defaultPageSize,
    defaultSorter,
    ...opt
  } = options

  const [state, dispatch] = useReducer(
    reduceState,
    { defaultPageSize, defaultSorter },
    initState
  )

  const serverFilters = useMemo(
    () => formatFilter(filters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filters)]
  )

  const { query, selectedRowKeys } = state
  const { data, refetch, ...rest } = useQuery({
    queryKey: [...makeArray(queryKey), payload, query],
    queryFn: async () => {
      const res = await queryFn({
        p: query.pagination.current - 1,
        s: query.pagination.pageSize,
        sort: formatSorter(query.sorter),
        filters: serverFilters,
        ...payload,
      })
      const data = resolveProp(res.data, schema.data, [])
      const total = resolveProp(res.data, schema.total, 0)
      parseFields(data, schema.fields)
      return { data, total }
    },
    ...opt,
  })

  useUpdateEffect(() => {
    if (query.pagination.current === 1) {
      refetch()
    } else {
      dispatch({ query: { pagination: { current: 1 } } })
    }
  }, [serverFilters])

  const setPagination = (pagination) => {
    dispatch({ query: { pagination } })
  }

  const setSorter = (sorter) => {
    dispatch({ query: { sorter } })
  }

  const setSelectedRowKeys = (keys) => {
    dispatch({ selectedRowKeys: keys })
  }

  const clearSelectedRowKeys = () => {
    dispatch({ selectedRowKeys: [] })
  }

  return {
    ...data,
    ...rest,
    refetch,
    query,
    serverFilters,
    setPagination,
    setSorter,
    selectedRowKeys,
    setSelectedRowKeys,
    clearSelectedRowKeys,
  }
}

/**
 * Initializer the table source state.
 *
 * @param   {Number} props.defaultPageSize
 * @param   {Object} props.defaultSorter
 * @returns {Object}
 */
function initState({ defaultPageSize, defaultSorter }) {
  return {
    selectedRowKeys: [],
    query: {
      pagination: {
        current: 1,
        pageSize: Number(defaultPageSize || 25),
        showSizeChanger: true,
        pageSizeOptions: ['25', '50', '100', '200'],
        showTotal: (total, range) =>
          `${range[0]} - ${range[1]} of ${total} items`,
      },
      sorter: defaultSorter ?? {},
    },
  }
}

/**
 * Reducer for the table source state.
 *
 * @param   {Object} state
 * @param   {Object} action
 * @returns {Object}
 */
function reduceState(state, action) {
  if (action.query) {
    return {
      ...state,
      ...action,
      query: {
        ...state.query,
        ...action.query,
        pagination: {
          ...state.query?.pagination,
          ...action.query?.pagination,
        },
      },
    }
  }

  return {
    ...state,
    ...action,
  }
}

/**
 * Formats a sort query.
 *
 * @param   {Object} sorter
 * @returns {Object || null}
 */
function formatSorter(sorter) {
  const { field, order } = sorter

  if (order && field && field.length > 0) {
    return {
      field: Array.isArray(field) ? field[0] : field,
      order,
    }
  }

  return null
}

/**
 * Formats a filter query.
 *
 * @param   {Array} filters
 * @returns {Object}
 */
function formatFilter(filters) {
  const result = {}

  filters.forEach((desc) => {
    if (!isEmptyString(desc.value)) {
      result[desc.dataIndex] = [
        {
          operator: isObject(desc)
            ? operatorMap[desc.operator]
            : defaultOperator,
          value: isObject(desc) ? desc.value : desc,
        },
      ]
    }
  })

  return result
}
