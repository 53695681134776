import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@beachfront/ui'

import { api } from '../../../../client-api'
import { getErrorMessage } from '../../../../utils'
import { useContentSize, useFilters, useInfiniteTable } from '../../../../hooks'
import { localFilterKey } from '../../../../components/filter'
import { InfiniteTable, NetworkError } from '../../../../components'
import { segmentHistoryColumns } from '../@constants'

import { SegmentHistoryFilter } from '.'

const SegmentHistory = ({ segmentId }) => {
  const { contentWidth, contentHeight } = useContentSize()

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(localFilterKey.SEGMENT_HISTORY, {
    schema: {
      createDate: 'date',
    },
  })

  const table = useInfiniteTable({
    queryKey: ['segment-history', segmentId],
    queryFn: api.common.getAudit,
    filters: filters.populated,
    params: { id: segmentId, q: '' },
  })

  const scroll = {
    x: contentWidth - 300,
    y: contentHeight - 323,
  }

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <SegmentHistoryFilter
        filterRef={filterRef}
        filters={filters.all}
        setFilters={setFilters}
      />
    </InfiniteTable.Toolbar>
  )

  return (
    <Box mt={2}>
      {!table.isFetching && table.error ? (
        <NetworkError
          description={getErrorMessage(table.error)}
          onAction={table.refetch}
        />
      ) : (
        <InfiniteTable
          rowKey='id'
          emptyText='No History Found'
          columns={segmentHistoryColumns}
          toolbar={toolbar}
          scroll={scroll}
          filters={filters.populated}
          onFilter={(key) => filterRef.current?.addFilter(key)}
          {...table.props}
        />
      )}
    </Box>
  )
}

SegmentHistory.propTypes = {
  segmentId: PropTypes.string,
}

export default SegmentHistory
