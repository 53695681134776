import React from 'react'
import PropTypes from 'prop-types'
import { Accordion } from '@beachfront/ui'

import { ManageAccountSection } from '..'

import AccountPreviewTags from './account-preview-tags'

const AccountPanel = ({
  expandKeys,
  disabled,
  values,
  selectedAccounts,
  onSelectedAccountsChange,
}) => {
  const extra = (
    <AccountPreviewTags
      visible={!expandKeys.includes('accounts')}
      values={values}
    />
  )

  return (
    <Accordion.Panel value='accounts' header='Accounts' extra={extra}>
      <ManageAccountSection
        selectedAccounts={selectedAccounts}
        onSelectedAccountsChange={onSelectedAccountsChange}
        formDisable={disabled}
      />
    </Accordion.Panel>
  )
}

AccountPanel.defaultProps = {
  expandKeys: [],
  values: {},
}

AccountPanel.propTypes = {
  expandKeys: PropTypes.array,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  selectedAccounts: PropTypes.array,
  onSelectedAccountsChange: PropTypes.func,
}

export default AccountPanel
