import React from 'react'
import { Box, Grid, Flex, Heading } from '@beachfront/ui'
import PropTypes from 'prop-types'
import { DelayOutlined } from '@beachfront/ui/icons'

import {
  useFilters,
  useDateUrlSync,
  useInsightWidgets,
  useDashboardCharts,
} from '../../../hooks'
import { DATE_RANGES } from '../../../enums'
import { isNotEmptyArray } from '../../../utils'
import { localFilterKey } from '../../../components/filter'
import { NetworkError, Widget, CommonSpinner } from '../../../components'
import { ChartSummary } from '../../dashboard'
import { InsightWidget } from '../../dashboard/@components'

import { DealDashboardFilter } from '.'

const DealDashboard = ({ dealId, superAdmin, timezone }) => {
  const [filters, setFilters] = useFilters(localFilterKey.DEAL_DASHBOARD, {
    initialState: [
      { dataIndex: 'date', operator: 'eq', value: DATE_RANGES['Today'] },
    ],
    schema: { date: 'date' },
  })

  useDateUrlSync({
    filters: filters.populated,
    onChange: setFilters,
  })

  const params = getRequestParams(filters.populated, dealId, timezone)

  if (superAdmin) {
    params.admin = true
  }

  const dashboards = useDashboardCharts(params)
  const widgets = useInsightWidgets()

  const onRefetch = () => {
    dashboards.refetch()
    widgets.refetch()
  }

  if (dashboards.isFetching) {
    return <CommonSpinner />
  }

  if (dashboards.error) {
    return (
      <NetworkError
        description={dashboards.error || 'Dashboard data not available'}
        onAction={onRefetch}
      />
    )
  }

  return (
    <>
      <Flex justifyContent='space-between'>
        <Box my={2}>
          <DealDashboardFilter filters={filters.all} setFilters={setFilters} />
        </Box>
      </Flex>
      <Grid gap={3}>
        {isNotEmptyArray(dashboards.data.widgets)
          ? dashboards.data.widgets?.map((widget, i) => {
              switch (widget.type) {
                case 'SUMMARY':
                  return (
                    <Box key={i} columnSpan={24}>
                      <ChartSummary
                        data={widget.data}
                        dateFilter={{
                          startDate: params.startDate,
                          endDate: params.endDate,
                        }}
                      />
                    </Box>
                  )
                case 'DEAL_CARD':
                  return null
                default:
                  return (
                    <Box key={i} columnSpan={widget?.span ? widget.span : 12}>
                      <Widget
                        title={getWidgetTitle(widget)}
                        type={widget?.chartType}
                        data={widget?.data}
                        tabs={widget?.tabs ? widget.tabs : []}
                        details={widget?.details ? widget.details : []}
                        loading={dashboards.isLoading}
                      />
                    </Box>
                  )
              }
            })
          : null}
      </Grid>
      <Flex mt={4} gap={3} justifyContent='space-between' alignItems='center'>
        <Flex gap={3}>
          <Heading level={4} my={0} ellipsis>
            Insights
          </Heading>
          <Flex gap={1} alignItems='center' color='secondary.base'>
            <DelayOutlined />
            <Box my='auto' fontSize={1}>
              4 hr delayed
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Box mt={3}>
        {isNotEmptyArray(widgets.data) ? (
          <Grid gap={3} columns={[1, 2, 24]} autoRows='360px'>
            {widgets.data
              .filter((item) => item.title !== 'Top 10 Deals')
              .map((item, i) => (
                <Box key={i} columnSpan={[1, 1, item.span ?? 8]}>
                  <InsightWidget
                    index={i}
                    data={item}
                    params={params}
                    timezone={timezone}
                  />
                </Box>
              ))}
          </Grid>
        ) : null}
      </Box>
    </>
  )
}

const getRequestParams = (filters, dealId, timezone) => {
  const dateRange = filters.find((f) => f.dataIndex === 'date')?.value

  return {
    dealId: dealId,
    type: 'custom',
    startDate: dateRange?.[0].format('YYYY-MM-DD'),
    endDate: dateRange?.[1].format('YYYY-MM-DD'),
    timezone: timezone,
    previous: true,
  }
}

const getWidgetTitle = (widget = {}) => {
  if (widget.title) {
    return widget.title
  }
  if (widget.data?.title) {
    return widget.data.title
  }
  return ''
}

DealDashboard.propTypes = {
  dealId: PropTypes.string,
  timezone: PropTypes.string,
  superAdmin: PropTypes.bool,
}

export default DealDashboard
